import { CountryLocaleNode, SearchEngineFragment } from 'Ghql';
import { AIKeywordNode } from './useAIOverviewConfig';

// Converts a boolean to either 'True' or 'False'. To comply with server-generated reports
const toPythonicBoolean = (val?: boolean): string => {
  if (val === true) {
    return 'True';
  }
  if (val === false) {
    return 'False';
  }
  return '';
};

const getCompareChange = (
  value: number | null,
  compareValue: number | null,
  invert: boolean, // if Rank, lower numbers are better
  round = false, // if CTR, we round to 3 digits
): string => {
  if (
    value === null ||
    compareValue === null ||
    value === undefined ||
    compareValue === undefined
  ) {
    return '';
  }
  let res = value - compareValue;
  if (invert) {
    res = -1 * res;
  }
  if (round) {
    return res.toFixed(3);
  }
  return `${res}`;
};

export const GetAIOverviewTableCsvData =
  (
    series: AIKeywordNode[],
    searchEngines: SearchEngineFragment[],
    countryLocales: CountryLocaleNode[],
  ) =>
  () => {
    const header =
      'Keyword,"Search engine",Desktop,Mobile,Country,Date,Rank,"Compare date","Compare rank","Compare change +/-",URL,"CTR","Compare CTR","CTR +/-","CTR Max","AI Overview text","AI Overview URLs","AI Overview SERP feature owned"\n';
    let i: number;
    let rows = '';

    for (i = 0; i < series.length; i++) {
      /** Keyword */
      rows += `"${series[i].keyword}"`;
      /** Search Engine */
      rows += `,${searchEngines.find((e) => e.id === series[i].searchEngineId)?.name}`;
      /** Desktop */
      rows += `,${toPythonicBoolean(series[i].searchTypeId === 1)}`;
      /** Mobile */
      rows += `,${toPythonicBoolean(series[i].searchTypeId === 2)}`;
      /** Country, e.g. "en-GB" */
      let countryCode = '';
      const cl = countryLocales.find((cln) => cln.id === `${series[i].countrylocaleId}`);
      if (cl) {
        countryCode = `${cl.localeShort}-${cl.countryCode}`.toLowerCase();
      }
      rows += `,"${countryCode}"`;
      /** Date */
      rows += `,${series[i].searchDate.split('T')[0]}`;
      /** Rank */
      rows += `,${series[i].rankValue || 'Not in top 100'}`;
      /** Compare Date */
      rows += `,${series[i].searchDatetimeCompare.split('T')[0]}`;
      /** Compare Rank */
      rows += `,${series[i].rankCompare || 'Not in top 100'}`;
      /** Compare Change */
      rows += `,${getCompareChange(series[i].rankValue, series[i].rankCompare, true)}`;
      /** Url */
      rows += `,${series[i].highestRankingPage || ''}`;
      /** CTR */
      rows += `,${(series[i].dynamicCtrValue ?? 0).toFixed(3)}`;
      /** Compare CTR */
      rows += `,${(series[i].dynamicCtrCompare ?? 0).toFixed(3)}`;
      /** CTR +/- */
      rows += `,${getCompareChange(series[i].dynamicCtrValue, series[i].dynamicCtrCompare, false, true)}`;
      /** CTR Max */
      rows += `,${(series[i].dynamicCtrMax ?? 0).toFixed(3)}`;
      /** AI Overview text */
      rows += `,"${series[i].aiOverviewText.replaceAll('"', '\'')}"`;
      /** First AI Overview URLs */
      const [firstUrl = '', ...restUrls] = series[i].aiOverviewUrls || [];
      rows += `,"${firstUrl}"`;
      /** AI Overview SERP feature owned */
      rows += `,${toPythonicBoolean(series[i].serpAiOverviewOwned)}`;
      rows += '\n';
      /** Extra AI Overview URL rows */
      restUrls.forEach((u) => (rows += `,,,,,,,,,,,,,,,,"${u}",\n`));
    }

    return header + rows;
  };
