import { useMemo } from 'react';
import { Flex } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { KeywordNode, useUpdateStaticTagMutation } from 'Ghql';
import { extractFormErrors } from 'Hooks/Graphql/helpers';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import useKeyword from 'Hooks/useKeyword';
import toast from 'Hooks/useToast';
import { useTreeViewStore } from 'Pages/Domains/TreeView/support/treeViewStore';
import { UNCATEGORIZED_FOLDER_ID } from 'Pages/Keywords/Groupings/EditMode/components/DeleteFolderModal';
import { useGroupingStore } from 'Pages/Keywords/Groupings/support/groupingsStore';
import { useInvalidateKeywordCache } from 'Utilities/Graphql/invalidateCache';
import { t, tct } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import AccText from 'Components/Text/AccText';


interface FormValues {
  tag: string;
  keywords: string[];
  folder: string;
}

type Props = {
  initialValues?: {
    folder?: string;
    tag?: string;
    keywords?: KeywordNode[];
  };
  domainId?: string;
};

const CreateOrEditStaticTagModal = (props: Props) => {
  const { initialValues } = props;
  const isEdit = Boolean(initialValues?.tag);
  const domainIdFromHook = useDomainId();
  const domainId = props.domainId || domainIdFromHook;

  const { hideModal } = useModal();

  const [updateStaticTag] = useUpdateStaticTagMutation();
  const { updateKeywordsTags: addStaticTag } = useKeyword();
  const groupingStore = useGroupingStore();
  const treeViewStore = useTreeViewStore();
  const invalidateKeywordCache = useInvalidateKeywordCache();

  const handleSubmit = async (values: FormValues) => {
    const { tag, keywords: keywordIdStrings, folder } = values;

    if (!keywordIdStrings || !tag || !domainId) return;

    let res;
    if (isEdit) {
      res = await updateStaticTag({
        variables: {
          input: {
            oldTag: initialValues?.tag as string,
            newTag: tag,
            oldFolderPath: initialValues?.folder as string,
            newFolderPath: folder,
            oldKeywordIds: initialValues?.keywords?.map((keyword) => keyword.id) as string[],
            newKeywordIds: keywordIdStrings,
            domainId: parseInt(domainId),
          },
        },
      });
    } else {
      res = await addStaticTag({
        keywords: keywordIdStrings.map((keyword) => parseInt(keyword)),
        folderId: folder,
        tags: [tag],
        remove: false,
      });
    }

    const errors = extractFormErrors(res);
    if (errors) {
      return errors;
    }

    invalidateKeywordCache();
    await Promise.all([groupingStore.refetch(false), treeViewStore.refetch(false)]);
    hideModal();
    toast.success(isEdit ? t('Successfully edited static tag!') : t('New static tag was added!'));
  };

  const memoizedInitialValues = useMemo(() => {
    return {
      tag: initialValues?.tag || '',
      keywords: initialValues?.keywords?.map((x) => x.id) || [],
      folder: initialValues?.folder || UNCATEGORIZED_FOLDER_ID,
    };
  }, [initialValues?.tag, initialValues?.keywords, initialValues?.folder]);

  return (
    <ModalBorder title={isEdit ? t('Edit Static Tag') : t('Create Static Tag')} onClose={hideModal}>
      <Form initialValues={memoizedInitialValues} onSubmit={handleSubmit}>
        {(formRenderProps) => {
          const { submitting, invalid, pristine } = formRenderProps;

          return (
            <Flex direction="column" gap={'md'}>
              <Field.TextInput
                name="tag"
                required
                label={t('Tag')}
                placeholder={t('Enter tag here')}
                validate={[Validator.required]}
                autoFocus={true}
              />
              <Field.FolderInput
                name="folder"
                required
                label={t('Folder')}
                placeholder={t('Select folder')}
                validate={[Validator.required]}
              />
              { initialValues?.keywords?.length && initialValues?.keywords?.length > 1000 ? // When there are more than 500 keywords, we show a text instead of the input, as it is slow otherwise, and not desirable to edit from this modal, the user should use the keyword list for bulk tag editing
                (<AccText>
                  {
                    tct('This tag has [keywords] associated keywords.', {keywords: initialValues?.keywords?.length})
                  }
                  </AccText>
                ) :
                (<Field.KeywordsInput
                name="keywords"
                required
                label={t('Keywords')}
                placeholder={t('Type to search for keywords')}
                fullWidth
                validate={[Validator.array]}
                valueOptions={initialValues?.keywords?.map((keyword) => ({
                  value: keyword.id.toString(),
                  label: keyword.keyword,
                  ...keyword,
                }))}
                domainId={domainId}
              />)
              }
              <Form.Errors />
              <ModalFooter
                primaryButtonSlot={
                  <AccButton
                    type="submit"
                    disabled={invalid || submitting || pristine}
                    variant="primary"
                    loading={submitting}
                  >
                    {isEdit ? t('Save') : t('Create')}
                  </AccButton>
                }
                secondaryButtonSlot={
                  <AccButton variant="tertiary" onClick={hideModal}>
                    {t('Cancel')}
                  </AccButton>
                }
              />
            </Flex>
          );
        }}
      </Form>
    </ModalBorder>
  );
};

export default CreateOrEditStaticTagModal;
