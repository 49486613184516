import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Highcharts from 'highcharts';
import sortBy from 'lodash/sortBy';
import { COLUMN_CHART_HEIGHT } from 'Components/Chart/ColumnChart/support/constants';
import { DEFAULT_CHART_CONFIG } from 'Components/Chart/support/constants';
import { DomainCountFragment, UrlCountBucketFragment } from 'Ghql';
import { ChartButtonTheme } from 'Components/Chart/support/buttonStyleConfig';
import { t } from 'Utilities/i18n';

const isAiOverviewArticleCountArray = (
  items: (UrlCountBucketFragment | DomainCountFragment)[],
): items is UrlCountBucketFragment[] => {
  return items.length > 0 && 'urlBucket' in items[0];
};

/** Provide two colors to generate a gradient */
type GradientStops = [string, string];

const useSeries = (
  items: UrlCountBucketFragment[] | DomainCountFragment[],
  title: string,
  color: GradientStops | string,
) => {
  return useMemo(() => {
    const data: Highcharts.PointOptionsObject[] = isAiOverviewArticleCountArray(items)
      ? sortBy(items, 'count')?.map((el) => ({ x: el.urlBucket || 0, y: el.count || 0 }))
      : items.map((el, index) => ({ x: index, y: el.count || 0, label: el.domain }));
    const result = [
      {
        name: title,
        id: title,
        color: Array.isArray(color)
          ? {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, color[0]],
                [1, color[1]],
              ],
            }
          : color,
        borderColor: 'transparent',
        type: 'column',
        data,
      },
    ];

    return result;
  }, [items, title, color]);
};

type ArticleCountConfigProps = {
  data: UrlCountBucketFragment[] | DomainCountFragment[];
  color: GradientStops | string;
  title: string;
  yAxisLabel: string;
  xAxisLabel?: string;
  height?: number;
};

export const useAIOBarChartConfig = (props: ArticleCountConfigProps): Highcharts.Options => {
  const intl = useIntl();
  const { data, color, title, height, yAxisLabel, xAxisLabel } = props;

  const chart: Highcharts.ChartOptions = useMemo(
    () => ({
      inverted: false,
      height: height || COLUMN_CHART_HEIGHT,
      type: 'column',
      backgroundColor: 'transparent',
      marginTop: 5,
      marginRight: 5,
      marginBottom: 75,
      marginLeft: 75,
      colorCount: 6,
      zoomType: 'x',
      zooming: {
        resetButton: {
          position: {
            // align: 'right', // by default
            // verticalAlign: 'top', // by default
            x: 0,
            y: -5,
          },
          //style the button similar to AccButton secondary
          theme: ChartButtonTheme,
        },
      },
    }),
    [height],
  );

  const series = useSeries(data, title, color);

  // Extract the labels for the x-axis
  const categories =
    series[0]?.data[0] && 'label' in series[0].data[0]
      ? series[0].data?.map((point) => point.label || '')
      : undefined;

  const plotOptions: Highcharts.PlotOptions = {
    column: {
      stacking: undefined,
      pointWidth: 10,
      borderRadius: 2,
    },
    series: {
      animation: true,
    },
  };

  const yAxis: Highcharts.YAxisOptions | Highcharts.YAxisOptions[] = [
    {
      min: 0,
      top: '0%',
      allowDecimals: false,
      title: {
        text: yAxisLabel,
      },
      labels: {
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'none',
        },
      },
    },
  ];

  const xAxis: Highcharts.XAxisOptions = {
    title: {
      text: xAxisLabel,
    },
    labels: {
      style: {
        fontSize: '12px',
      },
    },
    tickInterval: 1,
    categories,
  };

  const tooltip: Highcharts.TooltipOptions = {
    shared: true,
    shadow: false,
    backgroundColor: '#FFFFFF',
    borderColor: '#E0E0E7',
    borderRadius: 0,
    borderWidth: 1,
    padding: 1,
    useHTML: true,
    headerFormat:
      '<div class="chart-tooltip-table"><div class="chart-tooltip-table-tr"><div class="chart-tooltip-table-th">{point.key.label}</div></div>',

    pointFormatter() {
      return `<div class="chart-tooltip-table-tr">
                  <div class="chart-tooltip-table-td">
                      <span class="chart-tooltip-bullet" style="color: ${this.color};">●</span> ${
                        this.series.name
                      }
                  </div>
                  <div class="chart-tooltip-table-td chart-tooltip-table-right" style="text-align: right;">
                      ${intl.formatNumber(this.y || 0)} 
                  </div>
              </div>`;
    },

    footerFormat: '</div>',
    xDateFormat: '%b %e, %Y',
    hideDelay: 5,
  };

  return {
    chart,
    plotOptions,
    series: series as Highcharts.SeriesColumnOptions[],
    xAxis,
    yAxis,
    tooltip,
    legend: {
      enabled: false,
    },
    lang: {
      noData: t('No data for the selected filters'),
    },
    ...DEFAULT_CHART_CONFIG,
  };
};
