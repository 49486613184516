import { useAiOverviewUrlCountQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { useFakeAIOverviewDomainCountData } from '../TopDomainsChart/useFakeAIOverviewDomainCountData';
import { UrlChart, UrlListItemData } from '../UrlChart';

export const TopUrlsChart = () => {
  const filters = useFilters();
  const { data, loading } = useAiOverviewUrlCountQuery({
    variables: { filters, stripped: true },
    skip: !filters.length,
  });

  const series = data?.charts?.aiOverviewUrlCount?.filter(notEmpty) || [];

  const dataAvailable = !!series?.length;
  const fakeData = useFakeAIOverviewDomainCountData();

  const dataSeries: UrlListItemData[] = dataAvailable ? series : fakeData;

  return (
    <UrlChart
      showMissingDataOverlay={!dataAvailable && !loading}
      data={dataSeries}
      loading={loading}
      chartTitle={t('Top URLs')}
      chartTitleTooltip={t(
        'The table shows the top URLs displayed in the AI Overview SERPs for this domain.',
      )}
    />
  );
};
