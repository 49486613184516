import { useAiOverviewDomainCountQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { UrlChart, UrlListItemData } from '../UrlChart';
import { useFakeAIOverviewDomainCountData } from './useFakeAIOverviewDomainCountData';

export const TopDomainsChart = () => {
  const filters = useFilters();
  const { data, loading } = useAiOverviewDomainCountQuery({
    variables: { filters },
    skip: !filters.length,
  });

  const series = data?.charts?.aiOverviewDomainCount?.filter(notEmpty) || [];

  const dataAvailable = !!series?.length;

  const fakeData = useFakeAIOverviewDomainCountData();
  const dataSeries: UrlListItemData[] = dataAvailable ? series : fakeData;

  return (
    <UrlChart
      showMissingDataOverlay={!dataAvailable && !loading}
      data={dataSeries}
      loading={loading}
      chartTitle={t('Top Domains found in AI Overview')}
      chartTitleTooltip={t(
        'The table highlights the top domains most frequently appearing in AI Overview SERPs for this domain.',
      )}
    />
  );
};
