import { Link, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { Divider, Flex } from '@mantine/core';
import isEmpty from 'lodash/isEmpty';
import AccAlert from 'Components/AccAlert/AccAlert';
import AccButton from 'Components/AccButton/AccButton';
import AccessControl from 'Components/AccessControl';
import FormatNumber from 'Components/FormatNumber';
import AccText from 'Components/Text/AccText';
import { usePlanExpiredPreviousPlanQuery, usePlanExpiredReActivateMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useUser } from 'Hooks/data/user/useUser';
import toast from 'Hooks/useToast';
import useUserPermission from 'Hooks/useUserPermission';
import { t, tct } from 'Utilities/i18n';
import { redirectToRoot } from 'Utilities/underdash';
import ExpiredPageComponent from '../expiredPageComponent/ExpiredPageComponent';

const PlanExpired = () => {
  const { data: previousPlanData, loading } = usePlanExpiredPreviousPlanQuery();
  const [reactivatePlan, { loading: loadingReactivate }] = usePlanExpiredReActivateMutation();

  const user = useUser();
  const { isOpen, show } = useIntercom();

  const { showModal } = useModal();
  const navigate = useNavigate();

  const { isAdmin } = useUserPermission();

  const isLoading = loading || loadingReactivate;

  const organization = previousPlanData?.user?.organization;

  const isFreePlan = organization?.previousPlan?.isFree;

  const showBookMeeting =
    user.organization &&
    user.organization.salesManager &&
    user.organization.salesManager.meetingLink;

  const handleOpenBookMeeting = () => {
    if (user?.organization?.salesManager?.meetingLink) {
      const newWin = window.open(user.organization.salesManager.meetingLink, '_blank');
      newWin?.focus();
    }
  };

  const handleRetry = () => {
    const unexpectedErrorMessage = t(
      'We were unable to re-active your plan. Please contact support.',
    );
    return reactivatePlan().then(
      ({ data, errors }) => {
        if (!isEmpty(errors)) {
          toast.error(unexpectedErrorMessage);
          return;
        }

        if (data?.reactivate?.success) {
          toast.success(t('Your payment was successful.'));
          redirectToRoot();
        } else {
          toast.error(data?.reactivate?.error || unexpectedErrorMessage);
        }
      },
      () => {
        toast.error(unexpectedErrorMessage);
      },
    );
  };

  const MainContent = () => (
    <>
      {organization?.previousPlan?.name && (
        <AccAlert my="sm" severity="warning">
          {t('Your %s plan has expired.', organization.previousPlan.name)}
        </AccAlert>
      )}
      {isAdmin ? (
        <AccText>
          {isFreePlan
            ? t('Your free plan has expired. You can choose a new plan using the options below. ')
            : tct(
                'You can either re-active your plan at the current rate ([price] every [months] months), or choose a new plan using the options below. ',
                {
                  price: (
                    <FormatNumber currency={organization?.previousPlan?.currency || ''}>
                      {organization?.previousPlan?.calculateFuturePrice ||
                        organization?.previousPlan?.calculatePrice}
                    </FormatNumber>
                  ),
                  months: organization?.previousPlan?.billingCycleInMonths,
                },
              )}
          {tct(
            'You can also update your payment details [paymentMethodLink:here] or billing info [billingInfoLink:here].',
            {
              billingInfoLink: (
                <Link to="/account/billing" state={{ subscriptionTab: 'billingInfo' }} />
              ),
              paymentMethodLink: (
                <Link to="/account/billing" state={{ subscriptionTab: 'paymentMethod' }} />
              ),
            },
          )}
        </AccText>
      ) : (
        <AccText>{t('Please contact an organization admin to reactivate your account.')}</AccText>
      )}
      <AccText>
        {tct(
          'If you have any questions or would like to discuss your plan, please contact us through our [support] located in the lower left corner.',
          { support: <a onClick={() => !isOpen && show()}>{t('support function')}</a> },
        )}
      </AccText>
      <Divider color="gray.2" />
      <AccessControl withAdminPermission>
        <Flex columnGap="xxs">
          <AccButton
            variant="primary"
            mr={8}
            onClick={() => navigate('/billing/package/select')}
            disabled={isLoading}
          >
            {t('Choose a new plan')}
          </AccButton>
          {showBookMeeting && (
            <AccButton
              variant="primary"
              mr={8}
              onClick={handleOpenBookMeeting}
              disabled={isLoading}
            >
              {t('Book a demo')}
            </AccButton>
          )}

          <AccButton
            variant="secondary"
            mr={8}
            onClick={() =>
              showModal({
                modalType: 'Invoices',
                modalProps: {
                  organizationId: organization?.id,
                },
              })
            }
            disabled={isLoading}
          >
            {t('Show invoices')}
          </AccButton>

          {!isFreePlan && (
            <AccButton ml="auto" variant="primary" disabled={isLoading} onClick={handleRetry}>
              {t('Re-activate plan')}
            </AccButton>
          )}
        </Flex>
      </AccessControl>
    </>
  );

  return (
    <ExpiredPageComponent
      title={t('Your Current Plan Has Expired')}
      mainContent={<MainContent />}
    />
  );
};

export default PlanExpired;
