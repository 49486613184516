import { Menu } from '@mantine/core';
import {
  IconDotsVertical,
  IconFileDownload,
  IconPhotoDown,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';
import FileSaver from 'file-saver';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { elmToImage } from 'Components/ExportButton/index';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import styles from 'Pages/Keywords/Overview/components/KebabMenu/kebab-menu.module.scss';
import { t } from 'Utilities/i18n/index';
import variablesModule from 'css/base/variables.module.scss';

type Props = {
  title: string;
  csvFilename?: string;
  pngContent?: (() => unknown) | null;
  addCompetitor?: boolean;
  getCsvData?: () => string | Promise<string | null>;
  refetch?: () => void;
  boardControls?: any;
};

const KebabIcon = () => {
  return <IconDotsVertical style={{ fill: variablesModule.gray6 }} size={20} />;
};

async function downloadAsCsv(getCsvData: () => string | Promise<string | null>, title: string) {
  const csvData = await getCsvData(); // Resolves sync or async data

  if (!csvData) {
    console.warn('No CSV data available.');
    return undefined;
  }

  return () => {
    const csvBlob = new Blob([csvData], {
      type: 'text/csv;charset=utf-8;',
    });

    FileSaver.saveAs(csvBlob, `${title}.csv`);
  };
}

const KebabMenu = ({
  title,
  csvFilename = title,
  pngContent,
  addCompetitor = false,
  refetch,
  getCsvData,
  boardControls,
}: Props) => {
  const { showModal } = useModal();
  const domainId = useDomainId();
  const addCompetitorModal = () =>
    showModal({
      modalType: 'BuildCompetitor',
      modalTheme: 'light',
      modalProps: {
        domainId,
        refresh: () => {
          setTimeout(() => refetch?.(), 8000); // Try to refetch after 8 seconds
          setTimeout(() => refetch?.(), 12000); // Try to refetch after 12 seconds
        },
      },
    });

  return (
    <Menu shadow="md" position={'bottom-end'}>
      <AccTooltip tooltip={t('More options')}>
        <Menu.Target>
          <AccActionIcon className={styles.kebabSelected} data-no-dnd="true" variant="subtle">
            <KebabIcon />
          </AccActionIcon>
        </Menu.Target>
      </AccTooltip>

      <Menu.Dropdown data-no-dnd="true">
        <Menu.Label>{title}</Menu.Label>
        <Menu.Divider />
        {pngContent ? (
          <Menu.Item
            leftSection={<IconPhotoDown size={14} />}
            onClick={() => elmToImage(pngContent, undefined, title)}
          >
            {t('Download Image')}
          </Menu.Item>
        ) : null}
        {getCsvData && (
          <Menu.Item
            leftSection={<IconFileDownload size={14} />}
            onClick={async () => {
              const downloadFn = await downloadAsCsv(getCsvData, csvFilename);
              if (downloadFn) {
                downloadFn();
              }
            }}
          >
            {t('Download CSV')}
          </Menu.Item>
        )}
        {addCompetitor && (
          <Menu.Item leftSection={<IconPlus size={14} />} onClick={addCompetitorModal}>
            {t('Add Competitor')}
          </Menu.Item>
        )}
        {boardControls?.removeChart && (
          <Menu.Item
            leftSection={<IconTrash size={14} />}
            onClick={() => boardControls.removeChart()}
          >
            {t('Remove from Dashboard')}
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export default KebabMenu;
