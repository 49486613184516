import { useCallback, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import moment from 'moment';
import { DataTable, TableSize } from 'Components/DataTable';
import { formatDisplayNumber } from 'Components/FormatNumber/formatNumberHelper';
import { EARLIEST, LATEST } from 'Components/PeriodFilter/model';
import { RankingDistributionNeighborlyDocument, RankingDistributionNeighborlyQuery } from 'Ghql';
import { useFiltersWithHash, useSpecificFilter } from 'Hooks';
import { percentageDifference } from 'Pages/Keywords/Overview/components/HistoryCharts/LocalPackRankNeighborly/table';
import { useGroupViewInfo } from 'Pages/Keywords/Overview/hooks/useGroupViewInfo';
import { FilterAttribute } from 'Types/Filter';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n/index';
import DatePickerCellRenderer from '../support/DatePickerCellRenderer';
import DiffCellRenderer from '../support/DiffCellRenderer';

const getRankingDistributionNeighborlyColumns = () => {
  return [
    {
      id: 'date',
      title: t('Date'),
      width: 150,
      cellRenderer: ({ record }) => (
        <DatePickerCellRenderer
          onDateChange={record.onDateChange}
          date={record.firstColumn}
          minDate={record.minDate}
          maxDate={record.maxDate}
        />
      ),
    },
    {
      id: 'rank1',
      title: t('Rank 1'),
      width: 85,
      cellRenderer: ({ record }) => <DiffCellRenderer content={record.rank1} />,
      onHeaderCell: () => ({ reverseDirection: true }),
    },
    {
      id: 'rank2To5',
      title: t('Rank 2-5'),
      width: 85,
      cellRenderer: ({ record }) => <DiffCellRenderer content={record.rank2To5} />,
      onHeaderCell: () => ({ reverseDirection: true }),
    },
    {
      id: 'rank6To10',
      title: t('Rank 6-10'),
      width: 85,
      cellRenderer: ({ record }) => <DiffCellRenderer content={record.rank6To10} />,
      onHeaderCell: () => ({ reverseDirection: true }),
    },
    {
      id: 'rank1To10',
      title: t('Rank 1-10'),
      width: 85,
      cellRenderer: ({ record }) => <DiffCellRenderer content={record.rank1To10} />,
      onHeaderCell: () => ({ reverseDirection: true }),
    },
    {
      id: 'rank11To20',
      title: t('Rank 11-20'),
      width: 85,
      cellRenderer: ({ record }) => <DiffCellRenderer content={record.rank11To20} />,
      onHeaderCell: () => ({ reverseDirection: true }),
    },
    {
      id: 'rank21To100',
      title: t('Rank 21-100'),
      width: 85,
      cellRenderer: ({ record }) => <DiffCellRenderer content={record.rank21To100} />,
      onHeaderCell: () => ({ reverseDirection: true }),
    },
    {
      id: 'nonRanking',
      title: t('Not ranking'),
      width: 85,
      cellRenderer: ({ record }) => <DiffCellRenderer content={record.nonRanking} />,
      onHeaderCell: () => ({ reverseDirection: true }),
    },
  ];
};

export const RankingDistributionNeighborlyTable = () => {
  const columns = getRankingDistributionNeighborlyColumns();

  const [filters, filtersHash] = useFiltersWithHash();
  const client = useApolloClient();
  const { isEmptyGroup } = useGroupViewInfo(filters);
  const periodFilter = useSpecificFilter(FilterAttribute.PERIOD);
  const [customCompareDate, setCustomCompareDate] = useState<string | null>(null);
  const [customLatestDate, setCustomLatestDate] = useState<string | null>(null);

  const periodFilterValue = JSON.parse((periodFilter?.value as string) ?? '[]');
  const isLatest = periodFilterValue[1] === LATEST;
  const isInitial = periodFilterValue[0] === EARLIEST;

  // WARNING: For some reason, pulling this logic out in a custom hook causes the table not to respond
  // to filter changes (only responds on every second change then).. So please keep this inline
  const fetchData = useCallback(async () => {
    return client
      .query<RankingDistributionNeighborlyQuery>({
        query: RankingDistributionNeighborlyDocument,
        variables: {
          filters,
        },
      })
      .then((e) => {
        if (isEmptyGroup) {
          return {
            data: [],
            length: 0,
          };
        }
        const rankingDistributionData = e.data.graphs.rankingDistributionNeighborly;

        const absoluteMaxDate =
          Object.keys(rankingDistributionData)[Object.keys(rankingDistributionData).length - 1];
        const latestDate = customLatestDate
          ? customLatestDate
          : isLatest
          ? absoluteMaxDate
          : periodFilterValue[1];
        const value = rankingDistributionData[latestDate];

        const absoluteMinDate = Object.keys(rankingDistributionData)[0];
        const compareDate = customCompareDate
          ? customCompareDate
          : isInitial
          ? absoluteMinDate
          : periodFilterValue[0];
        const compareValue = rankingDistributionData[compareDate];

        return {
          data: [
            {
              firstColumn: compareDate,
              rank1: formatDisplayNumber(compareValue.rank1),
              rank2To5: formatDisplayNumber(compareValue.rank2To5),
              rank6To10: formatDisplayNumber(compareValue.rank6To10),
              rank1To10: formatDisplayNumber(compareValue.rank1To10),
              rank11To20: formatDisplayNumber(compareValue.rank11To20),
              rank21To100: formatDisplayNumber(compareValue.rank21To100),
              nonRanking: formatDisplayNumber(compareValue.nonRanking),
              minDate: new Date(absoluteMinDate),
              maxDate: moment(latestDate).subtract(1, 'd').toDate(),
              onDateChange: setCustomCompareDate,
            },
            {
              firstColumn: latestDate,
              rank1: formatDisplayNumber(value.rank1),
              rank2To5: formatDisplayNumber(value.rank2To5),
              rank6To10: formatDisplayNumber(value.rank6To10),
              rank1To10: formatDisplayNumber(value.rank1To10),
              rank11To20: formatDisplayNumber(value.rank11To20),
              rank21To100: formatDisplayNumber(value.rank21To100),
              nonRanking: formatDisplayNumber(value.nonRanking),
              minDate: moment(compareDate).add(1, 'd').toDate(),
              maxDate: new Date(absoluteMaxDate),
              onDateChange: setCustomLatestDate,
            },
            {
              firstColumn: t('% Difference'),
              rank1: {
                value: `${percentageDifference(value.rank1, compareValue.rank1)}%`,
                diff: value.rank1 - compareValue.rank1,
              },

              rank2To5: {
                value: `${percentageDifference(value.rank2To5, compareValue.rank2To5)}%`,
                diff: value.rank2To5 - compareValue.rank2To5,
              },
              rank6To10: {
                value: `${percentageDifference(value.rank6To10, compareValue.rank6To10)}%`,
                diff: value.rank6To10 - compareValue.rank6To10,
              },
              rank1To10: {
                value: `${percentageDifference(value.rank1To10, compareValue.rank1To10)}%`,
                diff: value.rank1To10 - compareValue.rank1To10,
              },
              rank11To20: {
                value: `${percentageDifference(value.rank11To20, compareValue.rank11To20)}%`,
                diff: value.rank11To20 - compareValue.rank11To20,
              },
              rank21To100: {
                value: `${percentageDifference(value.rank21To100, compareValue.rank21To100)}%`,
                diff: value.rank21To100 - compareValue.rank21To100,
              },
              nonRanking: {
                value: `${percentageDifference(value.nonRanking, compareValue.nonRanking)}%`,
                diff: value.nonRanking - compareValue.nonRanking,
              },
            },
            {
              firstColumn: t('# Difference'),
              rank1: {
                value: formatDisplayNumber(value.rank1 - compareValue.rank1),
                diff: value.rank1 - compareValue.rank1,
              },
              rank2To5: {
                value: formatDisplayNumber(value.rank2To5 - compareValue.rank2To5),
                diff: value.rank2To5 - compareValue.rank2To5,
              },
              rank6To10: {
                value: formatDisplayNumber(value.rank6To10 - compareValue.rank6To10),
                diff: value.rank6To10 - compareValue.rank6To10,
              },
              rank1To10: {
                value: formatDisplayNumber(value.rank1To10 - compareValue.rank1To10),
                diff: value.rank1To10 - compareValue.rank1To10,
              },
              rank11To20: {
                value: formatDisplayNumber(value.rank11To20 - compareValue.rank11To20),
                diff: value.rank11To20 - compareValue.rank11To20,
              },
              rank21To100: {
                value: formatDisplayNumber(value.rank21To100 - compareValue.rank21To100),
                diff: value.rank21To100 - compareValue.rank21To100,
              },
              nonRanking: {
                value: formatDisplayNumber(value.nonRanking - compareValue.nonRanking),
                diff: value.nonRanking - compareValue.nonRanking,
              },
            },
          ],
          length: 4,
        };
      });
  }, [filtersHash, client, customLatestDate, customCompareDate]);

  return (
    <DataTable
      tableId={TableIDs.RANKING_DISTRIBUTION_NEIGHBORLY}
      columns={columns}
      fetchData={fetchData}
      pageSize={4}
      viewMode={TableSize.DEFAULT}
      defaultOrdering={undefined}
      pagination={false}
      dataKey={`${customCompareDate}-${customLatestDate}-${filtersHash}`} // trigger table re-render when dates change
      skipClearStoreOnUnmount
    />
  );
};
