import React, { useMemo } from 'react';
import { ChartNote, GetNotesChart } from 'Components/Chart/Notes/support/types';
import { ChartPopover } from 'Components/Chart/support/components/ChartPopover/ChartPopover';
import { ChartPopoverStore } from 'Components/Chart/support/components/ChartPopover/ChartPopoverStore';
import { ChartNotePopoverContent } from './components/ChartNotePopoverContent';
import { handleNotesClick, withNotesChart } from './support/helpers';

export { useNotesSelect, useQueryNotes } from './support/hooks';
export type { ChartNote } from './support/types';
/**
 * @description
 * Used to add notes to the chart:
 * - update highcharts config and add/modify all required configurations
 * - add event handler to container that open/close popover
 * - element included in chart component render method - show popover in portal
 *
 * @example
 * ```tsx
 *     const { withNotes, handleNotesClick, tooltipElement } = getNotesModifiers(
 *       () => this.chart?.getChart(),
 *       this.props.notes,
 *     );
 *     return (
 *       <div onClick={handleNotesClick}>
 *         <ReactStockedChart config={withNotes(this.getChartConfig())} />
 *       </div>
 *     )
 * ```
 */
export const useNotesModifiers = (
  getChart: GetNotesChart,
  notes: ChartNote[] | null,
  config: { isRankDistribution?: boolean, showCtrNotes?: boolean; showSearchVolumeNotes?: boolean } = { isRankDistribution: false, showCtrNotes: false, showSearchVolumeNotes: false },
) => {
  const store = useMemo(() => new ChartPopoverStore(), []);

  return {
    withNotes: withNotesChart(getChart, notes ?? [], config),
    handleNotesClick: handleNotesClick(getChart, store, notes ?? []),
    tooltipElement: <ChartPopover store={store} content={ChartNotePopoverContent} />,
  };
};

export const NotesModifier = (props: {
  children: React.FC<
    React.PropsWithChildren<{
      handleNotesClick: any;
      withNotes: <T = unknown>(a: T) => T;
    }>
  >;
  getChart: GetNotesChart;
  notes: ChartNote[];
  config: { isRankDistribution?: boolean , showCtrNotes?: boolean, showSearchVolumeNotes?: boolean};
}) => {
  const { tooltipElement, ...modifiers } = useNotesModifiers(
    props.getChart,
    props.notes,
    props.config,
  );
  return (
    <>
      {props.children({
        handleNotesClick: modifiers.handleNotesClick,
        withNotes: modifiers.withNotes,
      })}
      {tooltipElement}
    </>
  );
};

NotesModifier.defaultProps = {
  config: { isRankDistribution: false },
};
