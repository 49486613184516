import React, { useMemo } from 'react';
import { Box, Center, Group } from '@mantine/core';
import { IconFold, IconFoldDown } from '@tabler/icons-react';
import cn from 'classnames';
import isNil from 'lodash/isNil';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { OnHeaderCellProps } from 'Components/DataTable/types';
import FormatNumber, { FormatNumberAuto } from 'Components/FormatNumber/new';
import { useTableSetting } from 'Components/Modal/Content/TableSettingsModal/support/config';
import { useStateColumns } from 'Components/Modal/Content/TableSettingsModal/support/hooks/useStateColumns';
import { withScrollOptimize } from 'Components/Table/VirtualizedTable';
import { QueryOrder, SORTING_TYPE } from 'Constants';
import { FolderMetric } from 'Ghql';
import { useIsPercentage, useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { CellRendererProps } from 'Pages/Domains/TreeView/useTreeViewTableConfig';
import { useFoldersGraph } from 'Pages/Keywords/Groupings/HistoryGraph/foldersGraph';
import { FolderType, FolderTypeWithTreeInfo } from 'Pages/Keywords/Groupings/groupingTypes';
import {
  FolderColumnID,
  NaverFolderColumnBlacklist,
} from 'Pages/Keywords/Groupings/support/constants';
import { TableSearchInput } from 'Pages/SiteMapping/components/TreeTable/components/TableSearchInput';
import { DomainTypeChoices } from 'Query';
import { TableIDs } from 'Types/Table';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';
import AboveTheFoldIcon from 'icons/abovethefold.svg';
import HeaderColumn from '../components/HeaderColumn';
import CompetitorPositionCell from '../components/cells/CompetitorPositionCell';
import { DeviceCell } from '../components/cells/DeviceCell';
import { DiscoveredKeywordsCell } from '../components/cells/DiscoveredKeywordsCell';
import { FolderDescriptionCell } from '../components/cells/FolderDescriptionCell';
import FoldersMaxCtrCell from '../components/cells/FoldersMaxCtrCell';
import KeywordsCell from '../components/cells/KeywordsCell';
import PathCell from '../components/cells/PathCell';
import RankingDistributionCell from '../components/cells/RankingDistributionCell';
import SearchIntentCell from '../components/cells/SearchIntentCell';
import ValueAndIndicatorCell from '../components/cells/ValueAndIndicatorCell';
import WinnersOrLosersCell from '../components/cells/WinnersOrLosersCell';

export type BaseCellProps = {
  classes: Record<string, string>;
  rootNode: FolderTypeWithTreeInfo;
  className?: string;
};

export type FolderCellProps = BaseCellProps & {
  isOpen: boolean;
  isHovered?: boolean;
  isSelected?: boolean;
  onToggle: any;
  hideConnectors?: string[];
  expandDataKey: string;
  updateSelectedNode: (
    node: FolderType,
    event: React.MouseEvent | React.KeyboardEvent | React.ChangeEvent,
  ) => void;
  onDragEnd?: (event: any, text?) => void;
};

// eslint-disable-next-line import/no-unused-modules
export type TableColumnConfig<T extends FolderCellProps | CellRendererProps = FolderCellProps> = {
  id: string;
  title?: string;
  orderBy?: string;
  orderByChange?: string;
  width?: string;
  withChange?: boolean;
  withCurrency?: boolean;
  cellRenderer: (props: T) => React.ReactNode;
  sticky?: boolean;
  /** Header cell horizontal alignment. Defaults to `end` */
  justify?: 'start' | 'end';
  /**Pass additional parameters for rendering the header Cell */
  onHeaderCell?: () => Pick<
    OnHeaderCellProps,
    'ordering' | 'tooltip' | 'menuTitle' | 'reverseDirection'
  > & {
    orderingChange?: {
      defaultOrder: QueryOrder;
      orderBy: string;
      /** Sort by description used for the menu.
       *
       * Defaults to `Ascending` and `Descending`
       */
      sortingKey?: SORTING_TYPE;
    };
  };
};

function useFolderTableSettings() {
  const { selector, columns } = useTableSetting(TableIDs.FOLDERS);
  const tableSettings = useStateColumns(selector);

  return useMemo(() => {
    if (isNil(tableSettings)) {
      return columns
        ?.filter((column) => !column.tableSetting?.defaultHide)
        ?.map((column) => column.id) as string[];
    }
    return tableSettings;
  }, [tableSettings]);
}

export const CellModalLink = ({
  rootNode,
  className,
  defaultTab,
  children,
  disabled,
  tooltip,
}: {
  rootNode: any;
  className?: string;
  defaultTab: string;
  children: JSX.Element;
  disabled?: boolean;
  tooltip?: string;
}) => {
  const showGraph = useFoldersGraph(
    () => (rootNode ? [rootNode] : undefined),
    defaultTab,
    rootNode?.isDomainsComparison ? 'DomainsComparison' : 'FoldersGraph',
  );
  return (
    <AccTooltip tooltip={tooltip ? tooltip : t('Show history')}>
      <button
        className={cn(className, 'table-link-like')}
        key={`${rootNode.id}-modal-link-${defaultTab}`}
        disabled={disabled}
        onClick={
          !disabled
            ? (event) => {
                event.stopPropagation();
                showGraph();
              }
            : undefined
        }
      >
        {children}
      </button>
    </AccTooltip>
  );
};

const useFoldersTableColumnsConfig = (): TableColumnConfig[] => {
  const tableSettings = useFolderTableSettings();
  const { domainInfo } = useQueryDomainInfo();
  const isPercentage = useIsPercentage();
  const isNaver = domainInfo?.domainType === DomainTypeChoices.A_7;
  const isDemoDomain = !!domainInfo?.isDemoDomain;
  const tableConfig: TableColumnConfig[] = useMemo(
    () => [
      {
        id: FolderColumnID.CHECKBOX,
        width: '45px',
        cellRenderer: () => null,
        sticky: true,
      },
      {
        id: FolderColumnID.FOLDER,
        title: t('Folder'),
        onHeaderCell: () => ({
          tooltip: t('Folder'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'folder',
            sortingKey: SORTING_TYPE.alphabetical,
          },
        }),
        width: 'minmax(320px, 4fr)',
        cellRenderer: (props) => (
          <PathCell key={FolderColumnID.FOLDER} {...props} isDemoDomain={isDemoDomain} />
        ),
        sticky: true,
      },
      {
        id: FolderColumnID.DESCRIPTION,
        title: t('Description'),
        width: 'minmax(182px, 1fr)',
        cellRenderer: (props) => {
          return <FolderDescriptionCell {...props} key={FolderColumnID.DESCRIPTION} />;
        },
      },
      {
        id: FolderColumnID.DEVICE,
        title: t('Device'),
        onHeaderCell: () => ({
          tooltip: t('Distribution of devices'),
          menuTitle: t('Share of mobile searches'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'mobile_percentage',
            sortingKey: SORTING_TYPE.numerical,
          },
        }),
        width: 'minmax(162px, 1fr)',
        cellRenderer: (props) => {
          return <DeviceCell {...props} key={FolderColumnID.DEVICE} />;
        },
      },
      {
        id: FolderColumnID.KEYWORDS,
        title: t('Keywords'),
        onHeaderCell: () => ({
          tooltip: t('Keywords'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'keywords',
            sortingKey: SORTING_TYPE.numerical,
          },
          menuTitle: t('Number of Keywords'),
        }),
        width: 'minmax(142px, 0.7fr)',
        cellRenderer: (props) => {
          return <KeywordsCell {...props} key={FolderColumnID.KEYWORDS} />;
        },
      },
      {
        id: FolderColumnID.DISCOVERED_KEYWORDS,
        title: t('Disc. keywords'),
        onHeaderCell: () => ({
          tooltip: t('Discovered keywords'),
          menuTitle: t('Discovered keywords'),
        }),
        width: 'minmax(158px, 0.8fr)',
        cellRenderer: (props) => {
          return <DiscoveredKeywordsCell {...props} key={FolderColumnID.DISCOVERED_KEYWORDS} />;
        },
      },
      {
        id: FolderColumnID.DYNAMIC_COMPETITOR_POSITION,
        title: t('Market position'),
        onHeaderCell: () => ({
          tooltip: t('Market position'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'dynamic_competitor_position',
            sortingKey: SORTING_TYPE.rank,
          },
        }),
        width: 'minmax(198px, 1fr)',
        cellRenderer: (props) => (
          <CompetitorPositionCell {...props} key={FolderColumnID.DYNAMIC_COMPETITOR_POSITION} />
        ),
      },
      {
        id: FolderColumnID.ORGANIC_TRAFFIC,
        title: t('AI SoV'),
        onHeaderCell: () => ({
          tooltip: t('AI Share of Voice'),
          menuTitle: t('AI Share of Voice'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'ai_share_of_voice',
            sortingKey: SORTING_TYPE.numerical,
          },
          orderingChange: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'ai_share_of_voice_change',
            sortingKey: SORTING_TYPE.numerical,
          },
        }),
        width: 'minmax(178px, 0.7fr)',
        cellRenderer: (props) => {
          return (
            <ValueAndIndicatorCell
              className={props.classes.rightTextAlign}
              key={FolderColumnID.ORGANIC_TRAFFIC}
              defaultTab={FolderMetric.EstimatedVisits}
              rootNode={props.rootNode}
              disabled={isNil(props.rootNode.ai_share_of_voice)}
              beforeValue={props.rootNode.ai_share_of_voice_compare}
              value={props.rootNode.ai_share_of_voice}
              maxValue={props.rootNode.max_ai_share_of_voice}
            >
              <AccTooltip tooltip={t('Show history for AI Share of Voice')}>
                <span>
                  <FormatNumber
                    value={props.rootNode.ai_share_of_voice}
                    maximumFractionDigits={1}
                    minimumFractionDigits={0}
                  />
                </span>
              </AccTooltip>
            </ValueAndIndicatorCell>
          );
        },
      },
      {
        id: FolderColumnID.SHARE_OF_VOICE,
        title: t('SoV'),
        onHeaderCell: () => ({
          tooltip: t('Share of Voice'),
          menuTitle: t('Share of Voice'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'share_of_voice_value',
            sortingKey: SORTING_TYPE.numerical,
          },
          orderingChange: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'share_of_voice_change',
            sortingKey: SORTING_TYPE.numerical,
          },
        }),
        width: 'minmax(178px, 0.8fr)',
        cellRenderer: (props) => (
          <ValueAndIndicatorCell
            className={props.classes.rightTextAlign}
            key={FolderColumnID.SHARE_OF_VOICE}
            defaultTab={FolderMetric.ShareOfVoice}
            rootNode={props.rootNode}
            disabled={isNil(props.rootNode.share_of_voice_value)}
            beforeValue={props.rootNode.share_of_voice_value_compare}
            value={props.rootNode.share_of_voice_value}
            maxValue={props.rootNode.max_share_of_voice}
            indicatorStyle={isPercentage ? 'percent' : 'decimal'}
          >
            <AccTooltip tooltip={t('Show history for Share of Voice')}>
              <span>
                <FormatNumberAuto
                  value={props.rootNode.share_of_voice_value}
                  maximumFractionDigits={1}
                  minimumFractionDigits={1}
                />
              </span>
            </AccTooltip>
          </ValueAndIndicatorCell>
        ),
      },
      {
        id: FolderColumnID.AVG_RANK_VALUE,
        title: t('Avg. rank'),
        onHeaderCell: () => ({
          tooltip: t('Average Rank'),
          menuTitle: t('Average Rank'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'avg_rank',
            sortingKey: SORTING_TYPE.rank,
          },
          orderingChange: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'avg_rank_change',
            sortingKey: SORTING_TYPE.rank,
          },
        }),
        width: 'minmax(148px, 0.7fr)',
        cellRenderer: (props) => (
          <ValueAndIndicatorCell
            key={FolderColumnID.AVG_RANK_VALUE}
            defaultTab={FolderMetric.AvgRank}
            rootNode={props.rootNode}
            className={props.classes.rightTextAlign}
            disabled={!props.rootNode.avg_rank}
            beforeValue={props.rootNode.avg_rank_compare || null}
            value={props.rootNode.avg_rank}
            negate={true}
            maxDecimals={1}
          >
            {props.rootNode.avg_rank !== 0 ? (
              <AccTooltip tooltip={t('Show history for average rank')}>
                <span>
                  <FormatNumber
                    value={props.rootNode.avg_rank}
                    minimumFractionDigits={1}
                    maximumFractionDigits={1}
                  />
                </span>
              </AccTooltip>
            ) : (
              t('Not in top 100')
            )}
          </ValueAndIndicatorCell>
        ),
      },
      {
        id: FolderColumnID.AVG_BASE_RANK,
        title: t('Avg. base rank'),
        onHeaderCell: () => ({
          tooltip: t('Average base rank'),
          menuTitle: t('Average base rank'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'avg_base_rank',
            sortingKey: SORTING_TYPE.rank,
          },
          orderingChange: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'avg_base_rank_change',
            sortingKey: SORTING_TYPE.rank,
          },
        }),
        width: 'minmax(178px, 0.7fr)',
        cellRenderer: (props) => (
          <ValueAndIndicatorCell
            key={FolderColumnID.AVG_BASE_RANK}
            defaultTab={FolderMetric.AvgBaseRank}
            rootNode={props.rootNode}
            className={props.classes.rightTextAlign}
            disabled={!props.rootNode.avg_base_rank}
            beforeValue={props.rootNode.avg_base_rank_compare || null}
            value={props.rootNode.avg_base_rank}
            negate={true}
            maxDecimals={1}
          >
            {props.rootNode.avg_base_rank !== 0 ? (
              <AccTooltip tooltip={t('Show history for average base rank')}>
                <span>
                  <FormatNumber
                    value={props.rootNode.avg_base_rank}
                    minimumFractionDigits={1}
                    maximumFractionDigits={1}
                  />
                </span>
              </AccTooltip>
            ) : (
              t('Not in top 100')
            )}
          </ValueAndIndicatorCell>
        ),
      },
      {
        id: FolderColumnID.SEARCH_VOLUME,
        title: t('Searches'),
        onHeaderCell: () => ({
          tooltip: t('Search Volume'),
          menuTitle: t('Search Volume'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'search_volume_value',
            sortingKey: SORTING_TYPE.numerical,
          },
        }),
        width: 'minmax(138px, 1fr)',
        cellRenderer: (props) => {
          return (
            <CellModalLink
              className={props.classes.rightTextAlign}
              key={FolderColumnID.SEARCH_VOLUME}
              defaultTab={FolderMetric.SearchVolume}
              rootNode={props.rootNode}
              disabled={isNil(props.rootNode.search_volume_value)}
              tooltip={t('Show history for search volume')}
            >
              <FormatNumber
                value={props.rootNode.search_volume_value}
                maximumFractionDigits={1}
                minimumFractionDigits={0}
              />
            </CellModalLink>
          );
        },
      },

      {
        id: FolderColumnID.AI_TRAFFIC_VALUE,
        title: t('AI Traf. val'),
        onHeaderCell: () => ({
          tooltip: t('AI Traffic Value'),
          menuTitle: t('AI Traffic Value'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'ai_traffic_value',
            sortingKey: SORTING_TYPE.numerical,
          },
          orderingChange: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'ai_traffic_value_change',
            sortingKey: SORTING_TYPE.numerical,
          },
        }),
        withCurrency: true,
        width: 'minmax(186px, 0.8fr)',
        cellRenderer: (props) => (
          <ValueAndIndicatorCell
            defaultTab={FolderMetric.AiTrafficValue}
            rootNode={props.rootNode}
            className={props.classes.rightTextAlign}
            key={FolderColumnID.AI_TRAFFIC_VALUE}
            disabled={!props.rootNode.ai_traffic_value}
            beforeValue={props.rootNode.ai_traffic_value_compare}
            value={props.rootNode.ai_traffic_value}
            maxValue={props.rootNode.max_ai_traffic_value}
          >
            <AccTooltip tooltip={t('Show history for AI Traffic value')}>
              <span>
                <FormatNumber
                  value={props.rootNode.ai_traffic_value}
                  maximumFractionDigits={1}
                  minimumFractionDigits={1}
                />
              </span>
            </AccTooltip>
          </ValueAndIndicatorCell>
        ),
      },

      {
        id: FolderColumnID.COST_VALUE,
        title: t('Traf. val'),
        onHeaderCell: () => ({
          tooltip: t('Traffic Value'),
          menuTitle: t('Traffic Value'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'traffic_value',
            sortingKey: SORTING_TYPE.numerical,
          },
          orderingChange: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'traffic_value_change',
            sortingKey: SORTING_TYPE.numerical,
          },
        }),
        withCurrency: true,
        width: 'minmax(172px, 0.8fr)',
        cellRenderer: (props) => (
          <ValueAndIndicatorCell
            defaultTab={FolderMetric.TrafficValue}
            rootNode={props.rootNode}
            className={props.classes.rightTextAlign}
            key={FolderColumnID.COST_VALUE}
            disabled={!props.rootNode.traffic_value}
            beforeValue={props.rootNode.traffic_value_compare}
            value={props.rootNode.traffic_value}
            maxValue={props.rootNode.max_traffic_value}
          >
            <FormatNumber
              value={props.rootNode.traffic_value}
              maximumFractionDigits={1}
              minimumFractionDigits={1}
            />
          </ValueAndIndicatorCell>
        ),
      },

      {
        id: FolderColumnID.DYNAMIC_CTR,
        title: t('Avg. CTR'),
        onHeaderCell: () => ({
          tooltip: t('Average Click-through Rate'),
          menuTitle: t('Average Click-through Rate'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'dynamic_ctr_value',
            sortingKey: SORTING_TYPE.numerical,
          },
          orderingChange: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'dynamic_ctr_change',
            sortingKey: SORTING_TYPE.numerical,
          },
        }),
        width: 'minmax(178px, 0.7fr)',
        cellRenderer: (props) => (
          <ValueAndIndicatorCell
            className={props.classes.rightTextAlign}
            defaultTab={FolderMetric.AvgCtr}
            key={FolderColumnID.DYNAMIC_CTR}
            rootNode={props.rootNode}
            disabled={isNil(props.rootNode.dynamic_ctr_value)}
            beforeValue={props.rootNode.dynamic_ctr_compare}
            value={props.rootNode.dynamic_ctr_value}
            indicatorStyle="percent"
            maxValue={props.rootNode.dynamic_ctr_max_value}
          >
            <AccTooltip tooltip={t('Show history for average CTR')}>
              <span>
                <FormatNumber
                  value={props.rootNode.dynamic_ctr_value}
                  style="percent"
                  maximumFractionDigits={1}
                  minimumFractionDigits={1}
                />
              </span>
            </AccTooltip>
          </ValueAndIndicatorCell>
        ),
      },
      {
        id: FolderColumnID.DYNAMIC_CTR_MAX,
        title: t('Avg. Max CTR'),
        onHeaderCell: () => ({
          tooltip: t('Average Max Click-through Rate'),
          menuTitle: t('Average Max CTR'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'dynamic_ctr_max_value',
            sortingKey: SORTING_TYPE.rank,
          },
        }),
        width: 'minmax(152px, 0.7fr)',
        cellRenderer: (props) => (
          <FoldersMaxCtrCell
            key={FolderColumnID.DYNAMIC_CTR_MAX}
            maxCtr={props.rootNode.dynamic_ctr_max_value}
            path={props.rootNode.path}
          />
        ),
      },
      {
        id: FolderColumnID.INTENT,
        title: t('Intent'),
        width: 'minmax(120px, 1fr)',
        cellRenderer: (props) => <SearchIntentCell {...props} key={FolderColumnID.INTENT} />,
      },

      {
        id: FolderColumnID.WINNERS,
        title: t('Winners'),
        onHeaderCell: () => ({
          tooltip: t('Winners'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'winners',
            sortingKey: SORTING_TYPE.amount,
          },
        }),
        width: 'minmax(120px, 1fr)',
        cellRenderer: (props) => {
          return (
            <WinnersOrLosersCell props={props} cellType={'winners'} key={FolderColumnID.WINNERS} />
          );
        },
      },
      {
        id: FolderColumnID.LOSERS,
        title: t('Losers'),
        onHeaderCell: () => ({
          tooltip: t('Losers'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'losers',
            sortingKey: SORTING_TYPE.amount,
          },
        }),
        width: 'minmax(120px, 1fr)',
        cellRenderer: (props) => {
          return (
            <WinnersOrLosersCell props={props} cellType={'losers'} key={FolderColumnID.LOSERS} />
          );
        },
      },

      {
        id: FolderColumnID.RANKING_DISTRIBUTION,
        title: t('Rank dist.'),
        width: 'minmax(120px, 0.5fr)',
        cellRenderer: (props) => {
          return <RankingDistributionCell key={FolderColumnID.RANKING_DISTRIBUTION} {...props} />;
        },
      },
      {
        id: FolderColumnID.ABOVE_THE_FOLD,
        title: t('Above the Fold'),
        onHeaderCell: () => ({
          tooltip: t('Above the Fold'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: 'above_the_fold',
            sortingKey: SORTING_TYPE.numerical,
          },
        }),
        width: 'minmax(138px, 0.8fr)',
        cellRenderer: (props) => (
          <CellModalLink
            defaultTab={FolderMetric.AboveTheFold}
            rootNode={props.rootNode}
            className={props.classes.rightTextAlign}
            key={FolderColumnID.ABOVE_THE_FOLD}
            disabled={isNil(props.rootNode.above_the_fold)}
            tooltip={t('Show history for Above the fold')}
          >
            <Group gap="xxs">
              <FormatNumber value={props.rootNode.above_the_fold} />
              <AccFastIcon
                fit="contain"
                src={AboveTheFoldIcon}
                color="#f89537"
                tooltip={t('Above the fold')}
                size={16}
              />
            </Group>
          </CellModalLink>
        ),
      },
    ],
    [isDemoDomain, isPercentage],
  );
  return useMemo(() => {
    return tableConfig
      .filter((column) => (tableSettings as string[])?.includes(column.id))
      .filter(
        (column) => !(isNaver && NaverFolderColumnBlacklist.includes(column.id as FolderColumnID)),
      )
      .map((e, index) => {
        // 1 priority cell, set number of cells that has high priority or Infinity to re-render full row
        if (index > 1) {
          return {
            ...e,
            cellRenderer: withScrollOptimize(e.cellRenderer),
          };
        }
        return e;
      });
  }, [tableSettings]);
};

const RowTemplate = (props) => {
  const columnConfigs = useFoldersTableColumnsConfig();

  return columnConfigs.map((config) => {
    const CellRenderer: any = config.cellRenderer ?? (() => null);
    return <CellRenderer {...props} key={config.id} />;
  });
};

const Header = React.memo(
  ({
    submitSearch,
    className,
    activeSearch,
    setOrderBy,
    order,
    orderBy,
    handleExpandOrCollapseAllNodes,
    expandNodes,
  }: any) => {
    const { displayCurrency } = useDisplayCurrency();
    const columnConfigs = useFoldersTableColumnsConfig();
    const gridTableRows = `${columnConfigs.map((c) => c.width).join(' ')}`;
    return (
      <Box className={className} style={{ display: 'grid', gridTemplateColumns: gridTableRows }}>
        {handleExpandOrCollapseAllNodes ? (
          <Center style={{ position: 'sticky', left: 0, zIndex: 3, paddingInline: 0 }}>
            <AccTooltip tooltip={expandNodes ? t('Expand all') : t('Collapse all')}>
              <AccActionIcon onClick={handleExpandOrCollapseAllNodes}>
                {expandNodes ? <IconFoldDown size={18} /> : <IconFold size={18} />}
              </AccActionIcon>
            </AccTooltip>
          </Center>
        ) : (
          <div
            style={{ position: 'sticky', left: 0, zIndex: 3, width: '45px', paddingInline: 0 }}
          />
        )}
        {columnConfigs.slice(1, columnConfigs.length + 1).map((header, index) => (
          <HeaderColumn
            searchFieldSlot={
              index === 0 ? (
                <TableSearchInput submitSearch={submitSearch} activeSearch={activeSearch} />
              ) : undefined
            }
            key={`${header.title}-${header.id}`}
            sticky={index === 0}
            header={header}
            content={`${header.title} ${
              header.withCurrency && displayCurrency ? `(${displayCurrency})` : ''
            }`}
            setOrderBy={setOrderBy}
            order={order}
            orderBy={orderBy}
          />
        ))}
      </Box>
    );
  },
  propsIsEqualComparison,
);
Header.displayName = 'Header';

export function useFoldersTableConfig() {
  const headerConfig = useFoldersTableColumnsConfig();

  const gridTableRows = `${headerConfig.map((c) => c.width).join(' ')}`;

  return {
    Header,
    RowTemplate,
    gridTableRows,
  };
}
