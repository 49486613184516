import ErrorBoundary from 'Components/ErrorBoundary';

import KpiBox, { KpiBoxSizeProps } from 'Pages/Keywords/Overview/components/KpiBar/KpiBox';
import { KPI_NAMES } from 'Pages/Keywords/Overview/components/KpiBar/index';
import { t } from 'Utilities/i18n';
import { KpiBarQueryProps } from './useKpiBarQueryWithDisplayCurrency';

const AiOverviewKpiBox = ({
  size,
  kpiBarQueryProps,
  draggable,
}: KpiBoxSizeProps & { kpiBarQueryProps: KpiBarQueryProps }) => {
  const { data, loading } = kpiBarQueryProps;

  return (
    <ErrorBoundary>
      <KpiBox
        id={KPI_NAMES.AI_OVERVIEW}
        size={size}
        title={t('AI Overview (owned)')}
        loading={loading}
        beforeValue={undefined}
        value={loading ? '-' : data?.kpis?.allKpis?.keywordsWithAiOverviewOwned || '-'}
        maxValue={data?.kpis?.allKpis?.keywordsWithAiOverview}
        draggable={draggable}
        progressTooltipPrefix={t('Owned: ')}
      />
    </ErrorBoundary>
  );
};

export default AiOverviewKpiBox;
