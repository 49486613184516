import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { TableFetchDataCallBack } from 'Components/DataTable';
import {
  ColumnOrderingState,
  TableFetchDataCallbackParams,
  TablePagination,
} from 'Components/DataTable/types';
import {
  getEnabledColumnQueriesByTable,
  getIsValidOrdering,
} from 'Components/Modal/Content/TableSettingsModal/support/helpers';
import { CountByCountryNode, KeydisKeywordsDocument, PaginatedTableKeywordsDocument } from 'Ghql';
import type { TableKeywordNode } from 'Ghql/customTypes';
import { extractValidationError } from 'Hooks/Graphql/helpers';
import toast from 'Hooks/useToast';
import { WithKeywordTableExpandChildren } from 'Pages/Keywords/Table/support/types';
import { keydisKeywords, keydisKeywords_keydisKeywords_keywords } from 'Query/types';
import { filtersSelector } from 'Selectors/FiltersSelector';
import { FilterAttribute, FilterBase } from 'Types/Filter';
import { GetState } from 'Types/Store';
import { TableID, TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { DEFAULT_KEYDIS_ORDERING, DEFAULT_KEYWORD_ORDERING } from './constants';

const getNextPaginationInfo = (pagination: any, totalDataLength: number) => {
  if (totalDataLength <= pagination.stopIndex) {
    return { hasNext: false };
  }
  return {
    hasNext: true,
    nextPagination: {
      ...pagination,
      startIndex: pagination.stopIndex,
      stopIndex: Math.min(
        pagination.stopIndex + (pagination.stopIndex - pagination.startIndex),
        totalDataLength,
      ),
      page: pagination.page + 1,
    },
  };
};

const hasFilter = (filters: FilterBase[], filterName: string) =>
  filters.find((filter: FilterBase) => filter.attribute === filterName) !== undefined;

export const formatKeyword = <T extends Partial<TableKeywordNode>>(
  keywordItem: T,
): T & WithKeywordTableExpandChildren & { [key: string]: unknown } => {
  const result: T & WithKeywordTableExpandChildren = {
    id: keywordItem?.keyword,
    ...keywordItem,
  };

  if (keywordItem?.extraRanks) {
    result.expandChildren = keywordItem?.extraRanks?.map((el) => ({
      highestRankingPage: el?.[1],
      rankValue: el?.[0],
      isSubRow: true,
    }));
  }
  return result;
};

const fetchKeywords = async ({
  tableName,
  ordering,
  pagination,
  getState,
  query,
  force,
  country,
  formatFilters,
  displayCurrency,
}: {
  tableName: TableID;
  ordering: ColumnOrderingState;
  pagination: TablePagination;
  getState: GetState;
  query: ApolloClient<object>['query'];
  force: boolean;
  country: string | undefined;
  formatFilters: ((filters: FilterBase[]) => FilterBase[]) | undefined;
  displayCurrency: string | undefined;
}) => {
  const state = getState();

  const filters: FilterBase[] = formatFilters
    ? formatFilters(filtersSelector(state))
    : filtersSelector(state);
  // Domain filter can be missing. E.g. in the case where the user opens the keyword list and immediately clicks the dashboard view.
  // In this case fetching the data is no longer relevant, and the query would trigger a validation error from the backend.
  // Same goes for the period filter, e.g. if the user quickly switches to the notes tab.
  if (
    !(
      hasFilter(filters, FilterAttribute.DOMAINS) ||
      hasFilter(filters, FilterAttribute.FREE_TEXT_DOMAIN)
    ) ||
    !hasFilter(filters, FilterAttribute.PERIOD) ||
    (hasFilter(filters, FilterAttribute.FREE_TEXT_DOMAIN) && !country)
  ) {
    return { data: [], length: 0, skip: true };
  }

  // Display currency can be missing, e.g. if user performs a hard refresh (ctrl+F5) while on the keyword list
  if (!displayCurrency) {
    return { data: [], length: 0, skip: true };
  }

  const enabledProperties = getEnabledColumnQueriesByTable(tableName)(getState);

  try {
    const result: { data: keydisKeywords } | any = await query({
      fetchPolicy: force ? 'network-only' : 'cache-first',
      query:
        tableName === TableIDs.KEYWORDS ? PaginatedTableKeywordsDocument : KeydisKeywordsDocument,
      variables: {
        ...(TableIDs.KEYWORDS
          ? { withAiOverviewUrls: false, withAiOverviewText: false, ...enabledProperties }
          : { enabledProperties }),
        filters,
        pagination,
        ordering,
        country,
        displayCurrency,
      },
    });

    if (tableName === TableIDs.KEYWORDS) {
      return {
        data: result?.data?.tableKeywords?.keywords?.map((e) => formatKeyword(e)),
        length: result?.data?.tableKeywords?.pagination?.numResults,
      };
    }
    return {
      data: result.data.keydisKeywords?.keywords
        ? result.data.keydisKeywords?.keywords?.map((e) => formatKeyword(e))
        : [],
      length: result?.data?.keydisKeywords?.pagination?.numResults ?? 0,
    };
  } catch (e) {
    const validationError = extractValidationError((e as any)?.message);
    const errorMsg = validationError || t('Failed to fetch keywords data');
    toast.error(errorMsg, { autoClose: validationError === undefined });
    throw new Error(errorMsg);
  }
};

export const useFetchKeywords = (
  tableName: TableID,
  displayCurrency: string | undefined,
  selectedCountry?: CountByCountryNode | null,
  formatFilters?: (filters: FilterBase[]) => FilterBase[],
): TableFetchDataCallBack<keydisKeywords_keydisKeywords_keywords> => {
  const client = useApolloClient();
  const store = useStore();

  return useCallback(
    async ({ ordering, pagination, force = false, tableStore }: TableFetchDataCallbackParams) => {
      try {
        const isKeyDis =
          tableName === TableIDs.KEYWORD_DISCOVERY || tableName === TableIDs.RESEARCH;
        // since we can load order from localStorage - it possible that its doesn't match current columns
        const isValidOrdering = getIsValidOrdering(tableName, store.getState, ordering?.orderBy);

        const resultOrdering = isValidOrdering
          ? ordering
          : isKeyDis
          ? DEFAULT_KEYDIS_ORDERING
          : DEFAULT_KEYWORD_ORDERING;
        const result = await fetchKeywords({
          tableName,
          ordering: resultOrdering,
          pagination,
          getState: store.getState,
          query: client.query,
          force,
          formatFilters,
          country: selectedCountry?.country || undefined, // getKeydisCountryFilterValue(selectedCountry)
          displayCurrency,
        });
        if (!isValidOrdering) {
          tableStore?.resetOrdering();
        }
        // Preload next page for better UX
        setTimeout(() => {
          const { hasNext, nextPagination } = getNextPaginationInfo(
            pagination,
            result?.length ?? 0,
          );
          if (hasNext) {
            fetchKeywords({
              tableName,
              ordering: resultOrdering,
              pagination: nextPagination,
              getState: store.getState,
              query: client.query,
              force,
              formatFilters,
              country: selectedCountry?.country || undefined,
              displayCurrency,
            });
          }
        }, 1200);
        return result;
      } catch (e) {
        console.error(e);
        throw new Error('FAILED');
      }
    },
    [selectedCountry, client, store, formatFilters, displayCurrency, tableName],
  );
};
