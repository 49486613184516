import { useRef } from 'react';
import { Box } from '@mantine/core';
import { ChartLoader } from 'Components/Chart/ChartLoader/ChartLoader';
import { ReactHighchartsChart } from 'Components/Chart/ReactHighcarts';
import Watermark from 'Components/Watermark';
import { useAiOverviewUrlCountBucketQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { MissingDataOverlay } from 'Pages/Keywords/Overview/MissingDataOverlay';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer';
import { colorScheme } from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { useAIOBarChartConfig } from '../useAIOBarChartConfig';
import { getUrlCountCsvData } from './getUrlCountCsvData';
import { useFakeAIOverviewUrlCountData } from './useFakeAIOverviewUrlCountData';

const CHART_HEIGHT = 388;

export const UrlCountChart = () => {
  const filters = useFilters();
  const chartRef = useRef<HTMLDivElement>(null);
  const { data, loading } = useAiOverviewUrlCountBucketQuery({
    variables: { filters },
    skip: !filters.length,
  });

  const dataAvailable = !!data?.charts?.aiOverviewUrlCountBucket?.length;

  const fakeData = useFakeAIOverviewUrlCountData();
  const aiOverviewArticleCount = dataAvailable
    ? data?.charts?.aiOverviewUrlCountBucket?.filter(notEmpty) || []
    : fakeData;

  const chartConfig = useAIOBarChartConfig({
    data: aiOverviewArticleCount,
    color: [colorScheme.graphs.graphBlueColors[3], colorScheme.graphs.graphBlueColors[1]],
    title: t('Number of keywords'),
    height: 430,
    yAxisLabel: t('Number of keywords'),
    xAxisLabel: t('Number of URLs in AI Overview'),
  });

  return (
    <ChartContainer
      title={t('Distribution of URLs in AI Overviews')}
      chartSeries={aiOverviewArticleCount}
      chartRef={chartRef}
      minHeight={520}
      getCsvData={getUrlCountCsvData}
      boardControls={{
        removeChart: null,
        disabledDnd: true,
      }}
      tooltip={[
        t(
          'The graph illustrates the distribution of URLs across AI Overview SERPs for this domain, highlighting the frequency of AI Overviews with varying URL counts.',
        ),
      ]}
    >
      <div style={{ position: 'relative' }}>
        {!dataAvailable && !loading && (
          <MissingDataOverlay extraBlurry type="noAIOverviews" isEmptyGroup={false} />
        )}
        <ChartLoader
          loaderType="column"
          loading={loading}
          containerBoxProps={{ h: CHART_HEIGHT + 20, mb: 10 }}
        >
          <Box pos="relative" ref={chartRef} bg="white">
            <Watermark big={true} offset />
            <ReactHighchartsChart config={chartConfig} />
          </Box>
        </ChartLoader>
      </div>
    </ChartContainer>
  );
};
