import cn from 'classnames';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import styles from 'Components/Table/TableRow/ActionsCell/actionsCell.module.scss';
import { getDisabledDemoText } from 'Constants/messages';
import { useCompareToIsLatest } from 'Hooks/data/filter/useFilters';
import { RefreshIcon } from 'Pages/Keywords/Table/Icon/Icons';
import { EventName, ExtraProps, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n/index';
import styleVariables from 'css/base/variables.module.scss';
import IconSearch from 'icons/IconSearch.svg';
import LoadingIcon from 'icons/loading.svg?inline';
import { SEARCH_TYPE_NAVER, SearchEngineIds } from '../../Modal/Content/AddSearchEngine/types';
import Cell from './HelperComponents/AccuCell';

export type ActionsCellProps = {
  value: {
    data: {
      id: number;
      historyUrl: string;
      updatingKeyword: boolean;
      domainId: number;
      keyword: string;
      searchEngineId?: number;
    };
    loadingData: boolean;
  };
  setData: (...args: Array<any>) => any;
  updateRowData: (...args: Array<any>) => any;
  onRefreshKeyword: any; // id, rowNode
  isDemoDomain?: boolean;
  // Controlled in table settings and passed as additional parameters
  actionSerp?: boolean;
  actionRefresh?: boolean;
  hideRefresh?: boolean;
};

type ButtonProps = {
  onClick: (MouseEvent: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isViewingLatestData?: boolean;
  isDemoDomain?: boolean;
};

const KeywordUpdateButton = ({ onClick, isViewingLatestData, isDemoDomain }: ButtonProps) => {
  const disabled = !isViewingLatestData || isDemoDomain;
  const { snorlax4: iconColor, gray4: disabledIconColor } = styleVariables;
  return (
    <AccTooltip
      tooltip={
        isDemoDomain
          ? getDisabledDemoText()
          : isViewingLatestData
          ? t('Refresh keyword')
          : t('You can only refresh keywords when viewing the latest data')
      }
      placement="top-start"
      disable={disabled}
    >
      <button
        className={styles.iconButton}
        disabled={disabled}
        onClick={disabled ? undefined : onClick}
        aria-label={
          isDemoDomain
            ? getDisabledDemoText()
            : isViewingLatestData
            ? t('Refresh keyword')
            : t('You can only refresh keywords when viewing the latest data')
        }
      >
        <AccFastIcon size={20} color={disabled ? disabledIconColor : iconColor} src={RefreshIcon} />
      </button>
    </AccTooltip>
  );
};

export const ViewSERPButton = ({
  historyUrl,
  trackingData,
}: {
  historyUrl?: string | null;
  trackingData?: Record<string, any>;
}) => {
  const trackEvent = useMixpanel();

  const { snorlax4: iconColor, gray4: disabledIconColor } = styleVariables;

  return (
    <AccTooltip
      tooltip={historyUrl ? t('Show SERP result') : t('SERP is no longer available for this date')}
      placement="top-start"
      disable={!historyUrl || false}
    >
      <button
        className={styles.iconButton}
        disabled={!historyUrl || false}
        onClick={() => {
          if (!historyUrl) return;
          window.open(historyUrl);
          trackEvent(EventName.ShowSerpResult, trackingData);
        }}
        aria-label={
          historyUrl ? t('Show SERP result') : t('SERP is no longer available for this date')
        }
      >
        <AccFastIcon
          size={20}
          color={historyUrl ? iconColor : disabledIconColor}
          src={IconSearch}
        />
      </button>
    </AccTooltip>
  );
};

const ActionsCell = (props: ActionsCellProps) => {
  const {
    value: { data, loadingData },
    onRefreshKeyword,
    updateRowData,
    isDemoDomain,
    actionSerp,
    actionRefresh,
    hideRefresh,
  } = props;
  const trackEvent = useMixpanel();
  const isViewingLatestData = useCompareToIsLatest();

  if (loadingData) return null;

  const { updatingKeyword, id, historyUrl } = data;
  const trackingContext: ExtraProps = { ID: id, domainId: data?.domainId, Keyword: data?.keyword };
  const hideRefreshBtn =
    data?.searchEngineId === SearchEngineIds[SEARCH_TYPE_NAVER] || !actionRefresh || !!hideRefresh;

  return (
    <Cell className={cn('icon-button-wrapper', styles.actionsCell)}>
      {actionSerp && <ViewSERPButton historyUrl={historyUrl} trackingData={trackingContext} />}
      {!hideRefreshBtn && (
        <div className={cn('refresh-action')}>
          {updatingKeyword ? (
            <LoadingIcon className="loading-spinner" />
          ) : (
            <KeywordUpdateButton
              isViewingLatestData={isViewingLatestData}
              isDemoDomain={isDemoDomain}
              onClick={() =>
                onRefreshKeyword(id).then(() => {
                  updateRowData({
                    updatingKeyword: true,
                  });
                  trackEvent(EventName.RefreshKeywordSingle, trackingContext);
                })
              }
            />
          )}
        </div>
      )}
    </Cell>
  );
};

export default ActionsCell;
