import { useMemo, useRef, useState } from 'react';
import IntersectionVisible from 'react-intersection-visible';
import { IconCurrencyDollar } from '@tabler/icons-react';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { t } from 'Utilities/i18n';
import ConnectingIcon from 'icons/connecting.svg?inline';
import DomainSettingsIcon from 'icons/domain-settings.svg?inline';
import { DisplaySettings } from './DisplaySettings';
import DomainSettingsDivider from './DomainSettingsDivider';
import { ConnectToAnalytics } from './connectToAnalytics';
import { ConnectToBigQuery } from './connectToBigQuery';
import { ConnectToGSC } from './connectToGSC';
import { GeneralMenuDetails } from './general';
import styles from './menu.module.scss';

const SettingsMenu = (): JSX.Element => {
  const { domainInfo } = useQueryDomainInfo();
  const [selectedIdx, setSelectedIdx] = useState(0);
  const generalRef = useRef<HTMLDivElement>(null);
  const taggingRef = useRef<HTMLDivElement>(null);
  const connectToAnalyticsRef = useRef<HTMLDivElement>(null);
  const ConnectToGSCRef = useRef<HTMLDivElement>(null);
  const currencyRef = useRef<HTMLDivElement>(null);

  const sectionRefs = {
    general: generalRef,
    connectToAnalytics: connectToAnalyticsRef,
    connectToGSC: ConnectToGSCRef,
    currency: currencyRef,
    tagging: taggingRef,
  };

  const intersectionRootMargin = `${-window.innerHeight / Object.keys(sectionRefs).length}px`;
  const handleIntersect = (id: number, isIntersecting: boolean) => {
    isIntersecting && setSelectedIdx(id);
  };

  const items = useMemo(
    () => [
      {
        idx: 0,
        id: 'general',
        text: t('General'),
        link: '/settings',
        active: selectedIdx === 0,
        icon: <DomainSettingsIcon className={styles.icon} />,
        component: <GeneralMenuDetails />,
      },
      {
        idx: 1,
        id: 'connectToAnalytics',
        text: t('Analytics'),
        active: selectedIdx === 1,
        icon: <ConnectingIcon className={styles.icon} />,
        component: <ConnectToAnalytics />,
        hidden: domainInfo?.youtubeChannelName,
      },
      {
        idx: 2,
        id: 'connectToGSC',
        text: t('Search Console'),
        active: selectedIdx === 2,
        icon: <ConnectingIcon className={styles.icon} />,
        component: <ConnectToGSC />,
        hidden: domainInfo?.youtubeChannelName,
      },
      {
        idx: 3,
        id: 'connectToBigQuery',
        text: t('BigQuery'),
        active: selectedIdx === 3,
        icon: <ConnectingIcon className={styles.icon} />,
        component: <ConnectToBigQuery />,
      },
      {
        idx: 4,
        id: 'currency',
        text: t('Display Settings'),
        active: selectedIdx === 4,
        icon: (
          <IconCurrencyDollar
            enableBackground={'123'}
            id="icon-currency-dollar"
            className={styles.icon}
          />
        ),
        component: <DisplaySettings />,
      },
    ],
    [selectedIdx],
  );

  const detailPanel = items.map(
    (item, index) =>
      !item.hidden && (
        <IntersectionVisible
          key={item.idx}
          options={{ rootMargin: intersectionRootMargin }}
          onIntersect={([{ isIntersecting }]) => {
            handleIntersect(item.idx, isIntersecting);
          }}
        >
          <section
            ref={sectionRefs[item.id]}
            key={item.id}
            id={item.id}
            onMouseOver={() => setSelectedIdx(item.idx)}
          >
            {item.component}
            {index + 1 !== items.length && <DomainSettingsDivider />}
          </section>
        </IntersectionVisible>
      ),
  );

  return <div>{detailPanel}</div>;
};

export default SettingsMenu;
