import React from 'react';
import set from 'lodash/set';
import sortBy from 'lodash/sortBy';
import throttle from 'lodash/throttle';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { ChartNote, GetNotesChart, NotesChart } from 'Components/Chart/Notes/support/types';
import { ChartPopoverStore } from 'Components/Chart/support/components/ChartPopover/ChartPopoverStore';
import { DEFAULT_BACKEND_DATE_FORMAT } from 'Constants';
import { t, tct } from 'Utilities/i18n';
import GoogleIcon from 'icons/google-note.svg';
import GroupNotesIcon from 'icons/note-multiple.svg';
import NotesIcon from 'icons/note-single.svg';
import AccuRankerIconGray from 'icons/logo-brand-gray.svg';
import {
  NOTES_SERIES_CLASS,
  NOTES_SERIES_ID,
  NOTE_SIZE,
  NOTE_TYPE_GOOGLE,
  NOTE_TYPE_USER,
  NOTE_TYPE_ACCURANKER,
} from './constants';

const getIsNotesSeries = (e) => {
  return e?.userOptions?.id?.toLowerCase() === NOTES_SERIES_ID;
};

const getIsNotesTooltip = (e) => {
  return getIsNotesSeries(e?.series);
};

const getIsNoteTarget = (target: EventTarget): target is SVGElement => {
  return (
    (target as SVGElement)?.tagName?.toLowerCase() === 'image' &&
    !!(target as SVGElement)?.closest(`.${NOTES_SERIES_CLASS}`)
  );
};

const getNotesSeries = (getChart: GetNotesChart) => {
  return getChart()?.series?.find(getIsNotesSeries);
};

const getRange = (getChart: GetNotesChart) => {
  const chart = getChart();
  const data = chart.series?.[0]?.processedXData;
  if (!data) {
    return NOTE_SIZE;
  }
  const dataMin = data?.[0];
  const dataMax = data?.[data?.length - 1];

  const diff = moment(new Date(dataMin)).diff(new Date(dataMax), 'days');

  const daysRange = Math.abs(diff) / (chart.chartWidth / NOTE_SIZE);

  return Math.max(Math.round(daysRange), 0);
};

export const handleNotesClick =
  (getChart: GetNotesChart, store: ChartPopoverStore, notes: ChartNote[]) =>
  (event: React.MouseEvent<HTMLElement | SVGElement>) => {
    const element = event.target;

    if (element && getIsNoteTarget(element)) {
      try {
        const item = (
          Array.from(
            element?.closest(`.${NOTES_SERIES_CLASS}`)?.querySelectorAll?.('image') ?? [],
          ).find((e) => e === element) as any
        )?.point?.options;
        if (item) {
          store.togglePopover(element, {
            note: notes.find((note) => note.id === item?.id) || item,
            relatedNotes: item?.relatedNotes,
          });
          event.stopPropagation();
        }
      } catch (e) {
        console.error('Failed to handle note click', e);
      }
      return;
    }
  };

const CTR = 'ctr';
const SEARCH_VOLUME = 'searchVolume';

const getSystemNotes = () => {
  return [
    {
      id: 'googleKwPlannerUpdateSep2023',
      x: moment('2023-09-06', DEFAULT_BACKEND_DATE_FORMAT).toDate(),
      note: tct(
        'Change in Google Keyword Planner.[br][br]Google has made major changes in their[br]tallying of search volume. [br][br][readMore]',
        {
          br: <br />,
          readMore: (
            <a
              href="https://www.accuranker.com/blog/recent-major-update-to-google-s-search-volume-algorithm/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Read more')}
            </a>
          ),
        },
      ),
      createdAt: '2023-09-06',
      keywords: undefined,
      type: NOTE_TYPE_GOOGLE,
      relevantFor: SEARCH_VOLUME,
    },
    {
      id: 'ctrUpdateJan2025',
      x: moment('2025-01-14', DEFAULT_BACKEND_DATE_FORMAT).toDate(),
      note: tct(
        'Major AI CTR model update.[br][br]AccuRanker\'s AI CTR model now incorporates 40 additional features, e.g. AI Overview.[br][br]This gives much more accurate CTR estimates than before.',
        {
          br: <br />,
        },
      ),
      createdAt: '2025-01-14',
      keywords: undefined,
      type: NOTE_TYPE_ACCURANKER,
      relevantFor: CTR,
    },
  ];
};

const getNotesSeriesData = (notes: ChartNote[], getChart: GetNotesChart, config: {showCtrNotes?: boolean, showSearchVolumeNotes?: boolean}) => {
  if (!getChart?.()) {
    return [];
  }

  const allNotes = getSystemNotes().filter(
    (e) =>
      (config?.showCtrNotes && e?.relevantFor === CTR) ||
      (config?.showSearchVolumeNotes && e?.relevantFor === SEARCH_VOLUME)
  ).concat(notes);

  const resultNotes = sortBy(
    allNotes.map(({ createdAt, ...e }: any) => ({
      id: e.id,
      type: e?.type || NOTE_TYPE_USER,
      x: moment(createdAt, DEFAULT_BACKEND_DATE_FORMAT).toDate(),
      ...e,
      createdAt,
    })),
    (e) => e.x?.getTime(),
  ).filter((e) => e?.x);

  const range = getRange(getChart);
  const getIsInRange = (a, b) => {
    return Math.abs(moment(a).diff(b, 'days')) <= range;
  };

  const result: any[] = [];

  resultNotes.map((e) => {
    const relatedNotes = uniqBy(
      resultNotes.filter((el) => getIsInRange(el.x, e.x)),
      'id',
    );
    const isGroup = relatedNotes.length > 1;

    if (result.find((el) => el.id === e.id || el?.relatedNotes?.find((elm) => elm.id === e.id))) {
      return;
    }

    const isTypeGoogle =
      e?.type === NOTE_TYPE_GOOGLE || relatedNotes.find((x) => x.type === NOTE_TYPE_GOOGLE);

    const isTypeAccuranker = e?.type === NOTE_TYPE_ACCURANKER || relatedNotes.find((x) => x.type === NOTE_TYPE_ACCURANKER);

    const Icon = isTypeGoogle ? GoogleIcon : isTypeAccuranker ? AccuRankerIconGray :  isGroup ? GroupNotesIcon : NotesIcon;


    result.push({
      ...e,
      x: e?.x?.getTime(),
      y: 0,
      id: e?.id,
      createdAt: e?.createdAt,
      marker: { symbol: `url(${Icon})` },
      relatedNotes: isGroup ? relatedNotes : null,
    });
  });

  return result;
};

const updateNotesData = (getChart: GetNotesChart, notes: ChartNote[], config: {showCtrNotes?: boolean, showSearchVolumeNotes?: boolean}) => {
  if (!notes) {
    return;
  }
  return getNotesSeries(getChart)?.update(
    {
      data: getNotesSeriesData(notes, getChart, config),
    },
    true,
  );
};

const getNotesConfig = (getChart: GetNotesChart, notes: ChartNote[], config: {showCtrNotes?: boolean, showSearchVolumeNotes?: boolean}): any => {
  const data = getNotesSeriesData(notes, getChart, config);

  return {
    id: NOTES_SERIES_ID,
    name: t('Notes'),
    yAxis: 1,
    type: 'scatter',
    zIndex: 1000,
    marker: {
      enabled: true,
      enabledThreshold: 0,
      symbol: `url(${NotesIcon})`,
      radius: 40,
    },
    title: ' ',
    lineWidth: 0,
    data,
    tooltip: {
      stickOnContact: false,
      enabled: false,
    },
    showInLegend: false,
    events: {
      mouseOver: () => {
        // prevent glitch with tooltip not being deleted (refresh|destroy|update cause lags on large data)
        getChart()?.tooltip?.hide(0);
      },
    },
    turboThreshold: data.length,
  };
};

export const withNotesChart =
  (
    getChart: GetNotesChart,
    notes: ChartNote[],
    config: { isRankDistribution?: boolean, showCtrNotes?: boolean, showSearchVolumeNotes?:boolean } = { isRankDistribution: false, showCtrNotes: false, showSearchVolumeNotes: false },
  ) =>
  (chart: NotesChart) => {
    const chartConfig = { ...chart };
    set(chartConfig, 'chart.events.load', function (this: any) {
      chart?.events?.load?.call(this);
      updateNotesData(getChart, notes, config);
    });

    const pointClick = chart?.plotOptions?.series?.point?.events?.click;
    if (pointClick) {
      set(chartConfig, 'plotOptions.series.point.events.click', function (this: any, event: any) {
        if (getIsNoteTarget(event.target) || getIsNotesTooltip(event?.point)) {
          return;
        }
        return pointClick?.call(this, event);
      });
    }

    const prevSetExtremes = chart.xAxis?.events?.setExtremes;
    const throttledNotesUpdate = throttle(() => updateNotesData(getChart, notes, config), 300);
    set(chartConfig, 'xAxis.events.setExtremes', function (this: any) {
      prevSetExtremes?.call(this);
      // timout required to receive proper processedXData
      // https://accuranker.myjetbrains.com/youtrack/issue/ARR-2573
      setTimeout(() => throttledNotesUpdate(), 0);
    });

    if (chartConfig.yAxis.length === 1) {
      if (config?.isRankDistribution) {
        chartConfig.yAxis[0].top = '20%';
      } else {
        chartConfig.yAxis[0].height = '100%';
      }
      chartConfig.yAxis = [
        ...(chartConfig.yAxis || []),
        {
          top: config?.isRankDistribution ? '-5%' : '0',
          height: '15%',
          title: null,
          offset: 0,
          visible: false,
        },
      ];
    }

    if (chartConfig?.series?.length) {
      chartConfig.series = [...(chartConfig.series || []), getNotesConfig(getChart, notes, config)];
    }

    const formatter = chart?.tooltip?.formatter;
    set(chartConfig, 'tooltip.formatter', function (this: any) {
      if (getIsNotesTooltip(this)) {
        return false;
      }
      return formatter?.call(this);
    });

    set(chartConfig, 'series.states', { inactive: { enabled: false } });
    chartConfig.plotOptions = {
      ...chartConfig.plotOptions,
      scatter: {
        className: NOTES_SERIES_CLASS,
        animation: false,
        stickyTracking: true,
        tooltip: {
          enabled: false,
        },
      },
      series: {
        ...chartConfig.plotOptions?.series,
        states: { inactive: { enabled: false } },
      },
    };

    chartConfig.chart.marginBottom = config.isRankDistribution ? 52 : 20;
    chartConfig.chart.marginTop = config.isRankDistribution ? 5 : 25;
    set(chartConfig, 'xAxis.labels.y', config.isRankDistribution ? 52 : 20);

    return chartConfig;
  };
