import { useState } from 'react';
import { useLocation } from 'react-router';
import { IconCreditCard, IconReceipt, IconShoppingBag } from '@tabler/icons-react';
import Tabs from 'Components/AccTabs/Tabs';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import useUserPermission from 'Hooks/useUserPermission';
import { t } from 'Utilities/i18n';
import BillingOverview from '../Overview';
import BillingInfo from '../UpdatePaymentInfo';
import PaymentMethod from '../UpdatePaymentMethod';
import { useOrganizationInfo } from 'Selectors/OrganizationSelector';
import { BillingPaymentContactPaymentMethodChoices } from 'Ghql';

const Subscription = () => {
  const { isAdmin } = useUserPermission();
  const { organization } = useOrganizationInfo();
  const isInvoiceCustomer =
    organization?.paymentcontact?.paymentMethod === BillingPaymentContactPaymentMethodChoices.A_1;
  const location = useLocation();
  const defaultTab: string | undefined = location.state?.subscriptionTab;

  const [activeTab, setActiveTab] = useState<string | null>(defaultTab || 'overview');

  return (
    <>
      <Breadcrumbs />

      <Tabs value={activeTab} onChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab value="overview" leftSection={<IconShoppingBag />}>
            {t('Overview')}
          </Tabs.Tab>
          <Tabs.Tab value="billingInfo" leftSection={<IconReceipt />}>
            {t('Billing Info')}
          </Tabs.Tab>
          {!isInvoiceCustomer && (
            <Tabs.Tab value="paymentMethod" leftSection={<IconCreditCard />}>
              {t('Payment Method')}
            </Tabs.Tab>
          )}
        </Tabs.List>

        <Tabs.Panel value="overview" pt="xs">
          <BillingOverview />
        </Tabs.Panel>

        <Tabs.Panel value="billingInfo" pt="xs">
          <BillingInfo />
        </Tabs.Panel>

        {!isInvoiceCustomer && (
          <Tabs.Panel value="paymentMethod" pt="xs">
            <PaymentMethod isOrgAdmin={isAdmin || false} />
          </Tabs.Panel>
        )}
      </Tabs>
    </>
  );
};

export default Subscription;
