import { useIntl } from 'react-intl';
import { Progress, useMantineTheme } from '@mantine/core';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { intlFormatNumber } from 'Components/FormatNumber/helpers';
import { t } from 'Utilities/i18n';

const PercentageOfMaxCircular = ({
  value,
  maxValue,
  isRank,
  isKeywords,
  isPercentages,
  large,
  tooltipPrefix,
}: {
  value: number | undefined;
  maxValue: number;
  isRank?: boolean;
  isKeywords?: boolean;
  isPercentages?: boolean;
  large?: boolean;
  tooltipPrefix?: string;
}) => {
  const theme = useMantineTheme();
  const intl = useIntl();

  const numberValue = value || 0;

  let percentage = isRank
    ? Math.round(100 - (100 * (numberValue - maxValue)) / (100 - maxValue))
    : Math.round((100 * numberValue) / maxValue);

  // we do not want to show 100% if not exactly 100%
  if (percentage === 100 && value !== maxValue) {
    percentage = 99;
  }

  const color = theme.colors.snorlax[5];

  const valueInTooltip = isPercentages
    ? `${(numberValue * 100).toFixed(1)}%`
    : intlFormatNumber(intl)({ value, maximumFractionDigits: 1 });
  const maxValueInTooltip = isPercentages
    ? `${(maxValue * 100).toFixed(1)}%`
    : intlFormatNumber(intl)({ value: maxValue, maximumFractionDigits: 1 });

  return (
    <AccTooltip
      tooltip={
        typeof value === 'number'
          ? `${tooltipPrefix || ''}${valueInTooltip} ${t('out of')} ${maxValueInTooltip}${
              isKeywords ? ` ${t('discovered keywords')}` : ''
            } (${percentage}%)`
          : undefined
      }
    >
      <Progress.Root w="100%" m={large ? -8 : 0}>
        <Progress.Section color={color} value={percentage} />
      </Progress.Root>
    </AccTooltip>
  );
};

export default PercentageOfMaxCircular;
