import { MouseEvent, memo, useState } from 'react';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { AccDropdownMenu, DropdownItem } from 'Components/AccDropdownMenu';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { useDynamicTagRulesetFromTagLazyQuery } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import useUserPermission from 'Hooks/useUserPermission';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';
import styles from './accTag.module.scss';
import { AccFastIcon } from 'Components/AccFastIcon';
import filterIcon from 'icons/filter.svg';
import { useMantineTheme } from '@mantine/core';

export type AccTagProps = {
  tag?: string;
  dynamic?: boolean;
  fontSize?: string;
  maxWidth?: string;
  onTagRemoveClick?: (tag: string, e?: MouseEvent<HTMLButtonElement>) => void;
  onTagClick?: (tag: string) => void;
  tooltipText?: string;
  isClickable?: boolean;
  withinPortal?: boolean;
  withinPopover?: boolean;
};

const AccTag = (props: AccTagProps) => {
  const {
    tag = '',
    dynamic,
    fontSize = '11px',
    maxWidth = props.maxWidth ? props.maxWidth : '100px',
    onTagRemoveClick,
    onTagClick,
    tooltipText,
    isClickable = false,
    withinPortal,
    withinPopover,
  } = props;

  const { colors, primaryShade } = useMantineTheme();
  const primarySnorlax = colors.snorlax[primaryShade as number];

  const clickable = onTagClick !== undefined || isClickable;

  const [menuOpen, setMenuOpen] = useState(false);
  const { userHasWriteAccess } = useUserPermission();

  const { showModal } = useModal();

  const domainId = useDomainId() as string;
  const [getDynamicTagData] = useDynamicTagRulesetFromTagLazyQuery();

  const handleEditDynamicTag = async () => {
    const { data } = await getDynamicTagData({
      variables: { domainId: parseInt(domainId), tag },
      fetchPolicy: 'network-only',
    });
    const dynamicTagData = data?.dynamicTagRulesetFromTag;

    showModal({
      modalType: 'CreateOrEditDynamicTag',
      modalTheme: 'light',
      modalProps: {
        isEdit: true,
        initialValues: {
          id: dynamicTagData?.id,
          folder: dynamicTagData?.folderPath,
          tag: dynamicTagData?.tag,
          rawRuleset: dynamicTagData?.rawRuleset,
        },
        updatedAt: dynamicTagData?.updatedAt,
        createdBy: dynamicTagData?.createdBy,
      },
    });
  };
  const dropdownItems: DropdownItem[] = [
    {
      label: t('Add as Filter'),
      icon: (
        <AccFastIcon
          src={filterIcon}
          size={16}
          color={primarySnorlax}
          className={styles.iconFilter}
        />
      ),
      onSelect: () => onTagClick?.(tag),
    },
  ];

  if (userHasWriteAccess) {
    if (dynamic) {
      dropdownItems.push({
        label: t('Edit Dynamic Tag'),
        icon: <IconEdit />,
        onSelect: () => {
          handleEditDynamicTag();
        },
      });
    } else {
      dropdownItems.push({
        label: t('Remove Tag'),
        icon: <IconTrash />,
        onSelect: () => onTagRemoveClick?.(tag),
      });
    }
  }

  return (
    <AccDropdownMenu
      //withinPortal can be overridden by the prop if the tag is rendered within another portal
      withinPortal={withinPortal ?? true}
      items={dropdownItems}
      position="bottom-start"
      offset={4}
      disabled={!clickable}
      label={tag}
      onChange={() => setMenuOpen(!menuOpen)}
    >
      <div
        className={styles.tag}
        style={{ maxWidth, fontSize }}
        data-clickable={clickable || null}
        data-dynamic={dynamic || null}
        data-popover={withinPopover || null}
      >
        <AccTooltip tooltip={tooltipText} disable={!tooltipText || menuOpen}>
          <span className={styles.label}>{tag}</span>
        </AccTooltip>
      </div>
    </AccDropdownMenu>
  );
};

export default memo(AccTag, propsIsEqualComparison);
