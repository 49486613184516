import { MantineThemeOverride } from '@mantine/core';
import variablesModule from './../base/variables.module.scss';

export const mantineTheme: MantineThemeOverride = {
  fontFamily: variablesModule.fontFamilyBase,
  fontFamilyMonospace: variablesModule.fontFamilyMonospace,
  fontSizes: {
    xs: variablesModule.fontSizeXs,
    sm: variablesModule.fontSizeSm,
    md: variablesModule.fontSizeMd,
    lg: variablesModule.fontSizeLg,
    xl: variablesModule.fontSizeXl,
  },
  headings: {
    fontWeight: 'normal',
    fontFamily: variablesModule.fontFamilyHeading,
    sizes: {
      h1: {
        fontSize: '32px',
        fontWeight: '400',
      },
      h2: {
        fontSize: '24px',
        fontWeight: '400',
      },
      h3: {
        fontSize: '18px',
        fontWeight: '600',
      },
      h4: {
        fontSize: '16px',
        fontWeight: '600',
      },
      h5: {
        fontSize: '14px',
        fontWeight: '600',
      },
    },
  },
  colors: {
    //all color-arrays should go from light to dark where color[0] is the lightest and color[9] the darkest
    orange: [
      //orange 0 - #ffefe0
      variablesModule.orange0,
      //orange 1 - #ffd9b4
      variablesModule.orange1,
      //orange 2 - #ffc48a
      variablesModule.orange2,
      //orange 3 - #fdae60
      variablesModule.orange3,
      //orange 4 - #f89734 hsl(30deg 93% 59%) This is the AccuRanker Logo color
      variablesModule.orange4,
      //orange 5 - #f37f1a
      variablesModule.orange5,
      //orange 6 - #df6710
      variablesModule.orange6,
      //orange 7 - #be5311
      variablesModule.orange7,
      //orange 8 - #9f4112
      variablesModule.orange8,
      //orange 9 - #813211
      variablesModule.orange9,
    ],
    gray: [
      //gray 0 - #f2f5f8
      variablesModule.gray0,
      //gray 1 - #e0e9ee
      variablesModule.gray1,
      //gray 2 - #cdd7de - hsl(205deg 20% 84%)
      variablesModule.gray2,
      //gray 3 - #b8c2cb - hsl(208deg 15% 76%)
      variablesModule.gray3,
      //gray 4 - #a6b0b7 - hsl(205deg 11% 68%)
      variablesModule.gray4,
      variablesModule.gray5,
      variablesModule.gray6,
      variablesModule.gray7,
      variablesModule.gray8,
      variablesModule.gray9,
    ],
    blue: [
      // light blue
      variablesModule.blue0,
      variablesModule.blue1,
      //blue:
      variablesModule.blue2,
      variablesModule.blue3,
      variablesModule.blue4,
      //dark blue:
      variablesModule.blue5,
      variablesModule.blue6,
      variablesModule.blue7,
      //extra-dark blue
      variablesModule.blue8,
      variablesModule.blue9,
    ],
    green: [
      //brand-color green - #a6cb52 rgb(166 203 82) hsl(78deg 54% 56%);
      variablesModule.green0,
      variablesModule.green1,
      variablesModule.green2,
      //brand-color green dark - #95b647 rgb(149 182 71) hsl(78deg 44% 50%);
      variablesModule.green3,
      variablesModule.green4,
      variablesModule.green5,
      // green - #4FAC22 rgb(79 172 34) hsla(100, 67%, 40%, 1)
      variablesModule.green6,
      variablesModule.green7,
      variablesModule.green8,
      variablesModule.green9,
    ],
    red: [
      variablesModule.red0,
      variablesModule.red1,
      variablesModule.red2,
      variablesModule.red3,
      variablesModule.red4,
      variablesModule.red5,
      variablesModule.red6,
      variablesModule.red7,
      variablesModule.red8,
      variablesModule.red9,
    ],
    violet: [
      variablesModule.violet0,
      variablesModule.violet1,
      variablesModule.violet2,
      variablesModule.violet3,
      variablesModule.violet4,
      variablesModule.violet5,
      variablesModule.violet6,
      variablesModule.violet7,
      variablesModule.violet8,
      variablesModule.violet9,
    ],
    snorlax: [
      variablesModule.snorlax0,
      variablesModule.snorlax1,
      variablesModule.snorlax2,
      variablesModule.snorlax3,
      variablesModule.snorlax4,
      variablesModule.snorlax5,
      variablesModule.snorlax6,
      variablesModule.snorlax7,
      variablesModule.snorlax8,
      variablesModule.snorlax9,
    ],
    yellow: [
      variablesModule.yellow0,
      variablesModule.yellow1,
      variablesModule.yellow2,
      variablesModule.yellow3,
      variablesModule.yellow4,
      variablesModule.yellow5,
      variablesModule.yellow6,
      variablesModule.yellow7,
      variablesModule.yellow8,
      variablesModule.yellow9,
    ],
  },

  primaryColor: 'orange',
  primaryShade: 4,
  components: {
    Table: {
      defaultProps: {
        fontSize: variablesModule.fontSizeMd,
      },
    },
    TabsTab: {
      defaultProps: {
        p: '8px 16px',
      },
    },
    Divider: {
      defaultProps: {
        color: variablesModule.gray2,
      },
    },
    Checkbox: {
      defaultProps: {
        color: 'snorlax',
      },
    },
  },
  spacing: {
    xxs: variablesModule.spacingXxs,
    xs: variablesModule.spacingXs,
    sm: variablesModule.spacingSm,
    md: variablesModule.spacingMd,
    lg: variablesModule.spacingLg,
    xl: variablesModule.spacingXl,
    xxl: variablesModule.spacingXxl,
  },
  radius: {
    xs: variablesModule.borderRadiusXs,
    sm: variablesModule.borderRadiusSm,
    def: variablesModule.borderRadiusDefault, //default radius - default is used internally by Mantine library
    md: variablesModule.borderRadiusMd,
    lg: variablesModule.borderRadiusLg,
    xl: variablesModule.borderRadiusXl,
  },
  // type MantineThemeOther defined in src/global.d.ts
  other: {
    gutter: variablesModule.gutter,
    gutterSm: variablesModule.gutterSm,
    brandColor: variablesModule.orange4,
    borderLight: variablesModule.gray1,
    textPrimary: variablesModule.gray9,
    textInactive: variablesModule.gray5,
    textDisabled: variablesModule.gray3,
    iconInactive: variablesModule.gray4,
    icon: variablesModule.gray7,
    increase: variablesModule.increase,
    decrease: variablesModule.decrease,
    warning: variablesModule.warning,
  },
};
