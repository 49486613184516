import { UrlCountBucketFragment } from 'Ghql';

export const getUrlCountCsvData = (series: UrlCountBucketFragment[]) => () => {
  const header =
    'Articles in AI Overview, Amount of keywords with this number of AI Overview Urls' + '\n';
  let i: number;
  let rows = '';
  for (i = 0; i < series.length; i++) {
    rows += `"${series[i].urlBucket}"`;
    rows += `,${series[i].count}`;
    rows += '\n';
  }

  return header + rows;
};
