import * as React from 'react';
import FiltersEditorComponent from 'Components/Filters/FiltersEditor';
import type { FiltersEditor } from 'Components/Filters/FiltersEditor';
import type { FilterGroup } from 'Types/Filter';
import type { FilterSet } from 'Types/FilterSet';

export type FiltersEditorProps = {
  filterGroup: FilterGroup;
  filterSet: FilterSet;
  filtersEditor: FiltersEditor;
};

function withFiltersEditor<InputProps extends {}>(
  Component: React.ComponentType<React.PropsWithChildren<InputProps & FiltersEditorProps>>,
): React.ComponentType<React.PropsWithChildren<InputProps>> {
  return function FiltersEditorWrapper(props: InputProps) {
    return (
      <FiltersEditorComponent>
        {Component && <Component {...(props as InputProps & FiltersEditorProps)} />}
      </FiltersEditorComponent>
    );
  };
}

export default withFiltersEditor;
