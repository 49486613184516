import { Box } from '@mantine/core';
import getFilterData from 'Components/Filters/getFilterData';
import styles from './filterTabs.module.scss';
import { AccFastIcon } from 'Components/AccFastIcon';
import filterIcon from 'icons/filter.svg';

const FilterTabsItem = (props) => {
  const getTabName = (filterAttribute: string | undefined) => {
    const { title } = getFilterData(filterAttribute) || {};
    return title;
  };

  let isActive = false;

  if (props.filterAttribute && props.currentActiveFilters) {
    isActive =
      props.currentActiveFilters.filter((f) => f.attribute === props.filterAttribute).length > 0;
  }

  return (
    <div
      onClick={() => props.onClick(props.index + 1)}
      className={`${styles.tabsFilter} ${styles.item}`}
      data-selected={props.currentTab === props.index + 1}
    >
      {getTabName(props.filterAttribute)}
      {isActive ? (
        <Box ml="xs">
          <AccFastIcon src={filterIcon} size={16} color="currentColor" />
        </Box>
      ) : null}
    </div>
  );
};

export default FilterTabsItem;
