import moment from 'moment';

type ListItemData = {
  color: string;
  data: { x: number; y: number }[];
  name: string;
};

export const getHistoricalAIOverviewData = (series: ListItemData[]) => () => {
  const header = `Date, ${series[0].name}, ${series[1].name}` + '\n';
  let i: number;
  let rows = '';
  for (i = 0; i < series[0].data.length; i++) {
    rows += `${moment.unix(series[0].data[i].x / 1000).format('YYYY-MM-DD')}`;
    rows += `,${series[0].data[i].y}`;
    rows += `,${series[1].data[i].y}`;
    rows += '\n';
  }

  return header + rows;
};
