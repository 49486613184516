import { UrlCountBucketFragment } from 'Ghql';

export const useFakeAIOverviewUrlCountData = (): UrlCountBucketFragment[] => {
  return [
    {
      count: 4,
      urlBucket: 1,
    },
    {
      count: 6,
      urlBucket: 2,
    },
    {
      count: 5,
      urlBucket: 3,
    },
    {
      count: 7,
      urlBucket: 4,
    },
    {
      count: 4,
      urlBucket: 5,
    },
    {
      count: 1,
      urlBucket: 6,
    },
  ];
};
