import { useCallback, useEffect, useRef, useState } from 'react';
import { Stack } from '@mantine/core';
import { IconCirclePlus } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import { FormErrors } from 'Components/Forms/FormErrors';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import RequestBigQueryFeature from 'Components/RequestBigQueryFeature';
import AccText from 'Components/Text/AccText';
import { useBigQueryAccountsQuery } from 'Ghql';
import useHasBigQueryFeature from 'Hooks/data/feature/useHasBigQueryFeature';
import { EventName, TrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t, tct } from 'Utilities/i18n/index';
import validation from 'Utilities/validation';

type Props = {
  accountId?: string;
  onSubmit: (...args: Array<any>) => any;
  onAdd: (...args: Array<any>) => any;
  onRemove: (...args: Array<any>) => any;
  onCancel: (...args: Array<any>) => any;
  submitting?: boolean;
};

type FormValues = { connectionId: string; datasetName: string };

const SelectBigQueryAccount = (props: Props) => {
  const hasBigQueryFeature = useHasBigQueryFeature();
  const { accountId, onSubmit, onAdd, onRemove, onCancel, submitting } = props;

  const [bqAccount, setBqAccount] = useState<{ label; value }>();
  const { data, loading } = useBigQueryAccountsQuery({
    fetchPolicy: 'network-only',
  });
  const bigQueryConnections = data?.user?.organization?.googleBigqueryConnections;
  const currentAccount = bigQueryConnections?.find(
    (connection) => accountId && accountId === connection?.id,
  );

  if (bigQueryConnections?.find((connection) => accountId && accountId === connection?.id)) {
    setBqAccount({
      label: currentAccount?.description,
      value: currentAccount?.id,
    });
  }

  const trackEvent = useMixpanel();

  const trackClick = useCallback(
    (eventName: string) => {
      const extraProps = {
        Variant: eventName,
        TrackingKey: TrackingKey.SelectBigQueryAccount,
      };
      trackEvent(EventName.ConnectToBigQuery, extraProps);
    },
    [trackEvent],
  );

  const eventTracked = useRef(false);

  useEffect(() => {
    if (eventTracked.current) {
      return;
    }
    trackClick('Open');
    eventTracked.current = true;
  }, [trackClick]);

  const options: { value: string; label: string }[] = loading
    ? []
    : bigQueryConnections?.map((item) => {
        const { id, description } = item || {};
        return {
          value: id || '',
          label: description || t('Google BigQuery (%s)', id),
        };
      }) || [];

  if (!hasBigQueryFeature) {
    return <RequestBigQueryFeature />;
  }

  if (options?.length || loading) {
    return (
      <Form<FormValues> onSubmit={onSubmit} subscription={{ invalid: true, submitErrors: true }}>
        {({ invalid, submitErrors }) => (
          <>
            <Stack gap="lg">
              <FormErrors errors={submitErrors} />
              <Field.TextInput
                name="datasetName"
                label={t('Dataset name')}
                placeholder={t('Only letters and underscores. Start and end with letter.')}
                helpText={t(
                  'Enter the name of the BigQuery dataset for this domain. If you have already created the dataset yourself, make sure it matches the dataset within your project.',
                )}
                validate={[validation.required, validation.datasetName]}
                disabled={submitting}
                required
              />
              <div>
                <Field.Select
                  name="connectionId"
                  id="connectionId"
                  type="text"
                  label={t('Select the Google BigQuery connection you want to use.')}
                  placeholder={''}
                  disabled={loading || submitting}
                  isLoading={loading}
                  options={options}
                  validate={validation.required}
                  useFirstOptionAsDefault
                />
                <AccButton
                  variant="tertiary"
                  onClick={onAdd}
                  leftSection={<IconCirclePlus size={18} />}
                >
                  {t('Add new connection')}
                </AccButton>
              </div>
            </Stack>

            {accountId != null && (
              <div className="alert alert-warning">
                <div className="form-label">
                  {tct(
                    'This domain is already connected to [account] Google BigQuery Account, you can connect to another account above or disconnect [link:here].',
                    {
                      account: <strong>{bqAccount ? bqAccount.label : null}</strong>,
                      link: <a style={{ color: '#F89537' }} onClick={onRemove} className="link" />,
                    },
                  )}
                </div>
              </div>
            )}
            <ModalFooter
              primaryButtonSlot={
                <AccButton
                  variant="primary"
                  type="submit"
                  disabled={loading || invalid || submitting}
                  loading={submitting}
                >
                  {t('Select connection')}
                </AccButton>
              }
              secondaryButtonSlot={
                <AccButton variant="tertiary" onClick={onCancel}>
                  {t('Cancel')}
                </AccButton>
              }
            />
          </>
        )}
      </Form>
    );
  }

  return (
    <div>
      <AccText>
        {t('We could not find a Google BigQuery Connection, please set one up first.')}
      </AccText>
      <ModalFooter
        primaryButtonSlot={
          <AccButton variant="primary" onClick={onAdd}>
            {t('Connect a Google BigQuery account')}
          </AccButton>
        }
        secondaryButtonSlot={
          <AccButton
            variant="tertiary"
            onClick={() => {
              onCancel();
              trackClick('Close');
            }}
          >
            {t('Cancel')}
          </AccButton>
        }
      />
    </div>
  );
};

export default SelectBigQueryAccount;
