import { useState } from 'react';

/**
 * Clipboard hook based on Mantine use-clipboard hook.
 *
 * see {@link https://mantine.dev/hooks/use-clipboard/ Mantine.dev use-clipboard}.
 *
 * Includes additional functionality to also work in dev environment */
export const useClipboard = ({ timeout = 2000 }: { timeout?: number }) => {
  const isSecureContext = window.location.protocol.toLowerCase().includes('https');
  const [error, setError] = useState<Error | null>(null);
  const [copied, setCopied] = useState(false);
  const [copyTimeout, setCopyTimeout] = useState<number | null>(null);

  const handleCopyResult = (value: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    window.clearTimeout(copyTimeout!);
    setCopyTimeout(window.setTimeout(() => setCopied(false), timeout));
    setCopied(value);
  };

  /** Copy any value to the clipboard - optionally pass the clickevent to refocus the clicked element after the value is copied */
  function copy(valueToCopy: any, e?: React.MouseEvent) {
    if ('clipboard' in navigator && isSecureContext) {
      navigator.clipboard
        .writeText(valueToCopy)
        .then(() => handleCopyResult(true))
        .catch((err) => setError(err));
      return;
    }

    const target = e?.currentTarget;
    // Create a temporary textarea element
    const textarea = document.createElement('textarea');

    // Set its value to the text you want to copy
    textarea.value = valueToCopy;

    // Make the textarea invisible to the user
    textarea.style.position = 'fixed'; // Avoid scrolling to bottom
    textarea.style.opacity = '0';

    // Append the textarea to the body
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();
    textarea.setSelectionRange(0, textarea.value.length);

    // Execute the copy command
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        handleCopyResult(true);
        console.info('Text copied to clipboard:', valueToCopy);
      } else {
        console.error('Failed to copy text');
      }
    } catch (err) {
      console.error('Error in copying text: ', err);
    }

    // Remove the temporary textarea
    document.body.removeChild(textarea);
    // return focus if event target is available
    target instanceof HTMLElement && target.focus();
  }

  const reset = () => {
    setCopied(false);
    setError(null);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    window.clearTimeout(copyTimeout!);
  };

  //return fallback for dev environments
  return { copy, reset, error, copied };
};
