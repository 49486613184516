import { useCallback, useMemo } from 'react';
import UrlWithStatusCell from 'Components/AccuTable/CellRenderer/CellUrlWithStatus/CellUrlWithStatus';
import { withKeywordCellLoader } from 'Components/AccuTable/CellRenderer/HelperComponents/CellLoaders/withKeywordCellLoader';
import ActionsCell, { type ActionsCellProps } from 'Components/AccuTable/CellRenderer/actions';
import CountryCell from 'Components/AccuTable/CellRenderer/countryCell';
import CTRCell from 'Components/AccuTable/CellRenderer/ctr';
import CtrChangeCell from 'Components/AccuTable/CellRenderer/ctrChange';
import MaxCTRCell from 'Components/AccuTable/CellRenderer/ctrMax';
import RankCell, { getRankLoadingTooltip } from 'Components/AccuTable/CellRenderer/rank';
import RankChangeCell from 'Components/AccuTable/CellRenderer/rankChange';
import SearchTypeEngineCell from 'Components/AccuTable/CellRenderer/searchTypeEngine';
import { ColumnType, QueryOrder, WithRowContext, withRowContext } from 'Components/DataTable';
import { FixedType } from 'Components/DataTable/table-core/interface';
import AccText from 'Components/Text/AccText';
import { SORTING_TYPE } from 'Constants';
import {
  CountryLocaleNode,
  MetaDataCountrylocaleFragment,
  PaginatedTableKeywordsDocument,
  SearchEngineFragment,
  SearchTypeFragment,
  useCountrylocalesQuery,
  usePaginatedTableKeywordsLazyQuery,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useLanguage } from 'Hooks/data/domain/useQueryDomainInfo';
import { useMetaData } from 'Hooks/data/user/useMetaData';
import { ShowLandingPageModalParams } from 'Hooks/useKeyword';
import { IconContainer } from 'Pages/Keywords/Table/support/IconContainer/IconContainer';
import { KeywordColumnID, KeywordSortOption } from 'Pages/Keywords/Table/support/constants';
import { AIOverviewFilter, FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { formatDate, slugify } from 'Utilities/format';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import DevicesIcon from 'icons/devices.svg?inline';
import { AIOverviewCell } from './AIOverviewCell';
import KebabMenu from 'Pages/Keywords/Overview/components/KebabMenu';
import { useFilters } from 'Hooks';
import { GetAIOverviewTableCsvData } from './GetAIOverviewTableCsvData';
import toast from 'Hooks/useToast';
import { ENABLED_AI_OVERVIEW_TABLE_COLUMNS } from './useFetchAIOverviewData';

export type AIKeywordNode = {
  countrylocaleId: number;
  displayName: string;
  domain: string;
  domainId: number;
  dynamicCtrCompare: number | null;
  dynamicCtrMax: number;
  dynamicCtrValue: number;
  enableAutocorrect: boolean;
  hasExtraRanks: boolean;
  historyUrl: string;
  id: number;
  ignoreFeaturedSnippet: boolean;
  ignoreInShareOfVoice: boolean;
  ignoreLocalResults: boolean;
  highestRankingPage: string | null;
  index: number;
  keyword: string;
  location: string;
  noScrapeOnCompareDate: boolean;
  noSearchVolumeForLocation: boolean;
  preferredLandingPageId: number | null;
  rankId: number;
  searchDate: string;
  searchDatetime: string;
  searchDatetimeCompare: string;
  searchEngineId: number;
  searchTypeId: number;
  starred: boolean;
  tags: [];
  topDomain: string;
  updatingKeyword: boolean;
  aiOverviewText: string;
  aiOverviewUrls: string[];
  serpAiOverview: boolean;
  serpAiOverviewOwned: boolean;
  rankValue: number | null;
  rankCompare: number | null;
};

interface ReturnType {
  columns: ColumnType<AIKeywordNode>[];
}

type AIOverviewConfigProps = {
  isDemoDomain: boolean | undefined;
  displayCurrency: string;
  domain?: string;
};

export const useAIOverviewConfig = ({
  isDemoDomain,
  displayCurrency,
  domain,
}: AIOverviewConfigProps): ReturnType => {
  const metaData = useMetaData();
  const countryLocales = useMemo(
    () => metaData?.countrylocales?.filter(notEmpty) || [],
    [metaData?.countrylocales],
  );
  const language = useLanguage();

  const { showModal } = useModal();

  const showLandingPageModal = useCallback(
    (params: ShowLandingPageModalParams) => {
      const {
        keywords,
        domainId,
        isAllSelected,
        preferredLandingPageId,
        highestRankingPage,
        filters,
      } = params;
      showModal({
        modalType: 'LandingPage',
        modalTheme: 'light',
        modalProps: {
          keywords,
          domainId,
          isAllSelected,
          preferredLandingPageId,
          highestRankingPage,
          filters,
        },
      });
    },
    [showModal],
  );

  const [getKeywords] = usePaginatedTableKeywordsLazyQuery();
  const { data: countryLocalesData } = useCountrylocalesQuery();
  const currentFilters = useFilters();

  const handleFetchCSVData = useCallback(async () => {
    const aiOverviewFilter: AIOverviewFilter = {
      attribute: FilterAttribute.HAS_AI_OVERVIEW,
      type: FilterValueType.BOOL,
      comparison: FilterComparison.EQ,
      value: true,
    };

    const filters = [...currentFilters, aiOverviewFilter];

    const dataResponse = await getKeywords({
      query: PaginatedTableKeywordsDocument,
      variables: {
        ...ENABLED_AI_OVERVIEW_TABLE_COLUMNS,
        filters,
        pagination: { startIndex: 0, stopIndex: 1000000, page: 1, results: 0 },
        ordering: { order: 'ASC', orderBy: 'keyword' },
        displayCurrency,
      },
    }).then((response) => {
      if (response.data) {
        const tableKeywords: AIKeywordNode[] = response.data.tableKeywords?.keywords;
        const CSVdataString = GetAIOverviewTableCsvData(
          tableKeywords,
          metaData?.searchEngines?.filter(notEmpty) || [],
          (countryLocalesData?.countrylocales?.filter(notEmpty) as CountryLocaleNode[]) || [],
        )();
        return CSVdataString;
      }
      if (response.error) {
        toast.error(t('Woops! Something went wrong when generating data for the CSV export'));
      }
      return null;
    });
    return dataResponse;
  }, [currentFilters, displayCurrency, getKeywords]);

  const columns: ColumnType<
    Partial<AIKeywordNode>,
    {
      countryLocales: MetaDataCountrylocaleFragment[];
      searchTypes: SearchTypeFragment[] | undefined;
      searchEngines: SearchEngineFragment[] | undefined;
      cellClassName: string;
    }
  >[] = useMemo(
    () => [
      {
        id: KeywordColumnID.KEYWORD,
        combineRow: KeywordColumnID.SEARCH_TYPE,
        title: t('Keyword'),
        onHeaderCell: () => ({
          tooltip: t('Keyword'),
          filter: {
            filterAttributes: [
              FilterAttribute.KEYWORD,
              FilterAttribute.MULTIPLE_KEYWORDS,
              FilterAttribute.TAGS,
              FilterAttribute.KEYWORD_OPTIONS,
            ],
            filterTooltip: t('Add filter for Keyword'),
            filterId: 'keyword-filter',
            hasLargeFilter: true,
          },
          ordering: {
            defaultOrder: QueryOrder.ASC,
            orderBy: KeywordSortOption.KEYWORD,
            sortingKey: SORTING_TYPE.alphabetical,
          },
        }),
        width: 250,
        fixed: 'left' as FixedType,
        flex: true,
        cellRenderer: ({ value }) => {
          return <AccText size="sm">{value.data.keyword}</AccText>;
        },
      },
      {
        id: KeywordColumnID.SEARCH_TYPE,
        title: (
          <IconContainer size="md">
            <DevicesIcon />
          </IconContainer>
        ),
        onHeaderCell: () => ({
          tooltip: t('Search Type'),
          filter: {
            filterAttributes: [FilterAttribute.SEARCH_ENGINE, FilterAttribute.SEARCH_TYPE],
            filterTooltip: t('Add filter for Search Type'),
          },
          menuTitle: t('Search Type'),
        }),
        width: 64,
        fixed: 'left' as FixedType,
        cellRenderer: SearchTypeEngineCell,
        cellRendererParams: {
          searchTypes: metaData?.searchTypes,
          searchEngines: metaData?.searchEngines,
          cellClassName: 'vertical-alignment-top',
        },
      },
      {
        id: KeywordColumnID.COUNTRY,
        title: t(' '),
        onHeaderCell: () => ({
          tooltip: t('Country'),
          filter: {
            filterAttributes: [FilterAttribute.COUNTRY_LOCALE],
            filterTooltip: t('Add filter for Country Locale'),
          },
          menuTitle: t('Country'),
        }),
        width: 38,
        cellRenderer: CountryCell,
        cellRendererParams: {
          countryLocales,
        },
      },
      {
        id: KeywordColumnID.RANK,
        combineRow: KeywordColumnID.RANK_CHANGE,
        title: t('Rank'),
        onHeaderCell: () => ({
          tooltip: t('Rank'),
          ordering: {
            defaultOrder: QueryOrder.ASC,
            orderBy: KeywordSortOption.RANK,
            sortingKey: SORTING_TYPE.rank,
          },
          filter: {
            filterAttributes: [FilterAttribute.RANK, FilterAttribute.HAS_EXTRA_RANKS],
            filterTooltip: t('Add filter for Rank'),
            hasMediumFilter: true,
            filterLabel: t('Rank column filters'),
          },
          reverseDirection: true,
        }),
        width: 85,
        cellRenderer: withKeywordCellLoader(
          withRowContext(RankCell as React.ComponentType<React.PropsWithChildren<WithRowContext>>),
          {
            overlayOnly: true,
            getTooltip: getRankLoadingTooltip,
          },
        ),
      },
      {
        id: KeywordColumnID.RANK_CHANGE,
        title: t('+/-'),
        onHeaderCell: () => ({
          tooltip: t('Change in rank between the two compared dates'),
          ordering: {
            defaultOrder: QueryOrder.ASC,
            orderBy: KeywordSortOption.RANK_CHANGE,
            sortingKey: SORTING_TYPE.numerical,
          },
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.RANK_CHANGE],
            filterTooltip: t('Add filter for Rank'),
            hasMediumFilter: true,
            filterLabel: t('Rank column filters'),
          },
          menuTitle: t('Rank change'),
        }),
        width: 56,
        cellRenderer: withKeywordCellLoader(RankChangeCell, {
          getTooltip: getRankLoadingTooltip,
        }),
      },
      {
        id: KeywordColumnID.URL,
        flex: true,
        title: t('URL'),
        onHeaderCell: () => ({
          tooltip: t('URL'),
          filter: {
            filterAttributes: [
              FilterAttribute.HIGHEST_RANKING_PAGE,
              FilterAttribute.HIGHEST_RANKING_PAGE_MATCH,
            ],
            hasMediumFilter: true,
            filterTooltip: t('Add filter for URL'),
          },
          ordering: {
            defaultOrder: QueryOrder.ASC,
            orderBy: KeywordSortOption.URL,
            sortingKey: SORTING_TYPE.alphabetical,
          },
        }),
        width: 240,
        cellRenderer: withKeywordCellLoader(UrlWithStatusCell, {
          getTooltip: () => t('Refreshing rank'),
        }),
        cellRendererParams: {
          isDemoDomain,
          onClick: (
            id: number,
            _domainId: number,
            highestRankingPage: string | undefined,
            preferredLandingPageId: number | undefined,
          ) => {
            showLandingPageModal({
              keywords: [id],
              domainId: _domainId,
              isAllSelected: false,
              highestRankingPage,
              preferredLandingPageId,
            });
          },
        },
      },
      {
        id: KeywordColumnID.DYNAMIC_CTR,
        combineRow: KeywordColumnID.DYNAMIC_CTR_CHANGE,
        title: t('CTR'),
        onHeaderCell: () => ({
          tooltip: t('Click-through Rate'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: KeywordSortOption.DYNAMIC_CTR,
            sortingKey: SORTING_TYPE.numerical,
          },
          filter: {
            filterAttributes: [FilterAttribute.DYNAMIC_CTR],
            filterTooltip: t('Add filter for CTR'),
          },
          reverseDirection: true,
          menuTitle: t('Click-through Rate'),
        }),
        width: 60,
        cellRenderer: withKeywordCellLoader(CTRCell, { overlayOnly: true }),
      },
      {
        id: KeywordColumnID.DYNAMIC_CTR_CHANGE,
        combineRow: KeywordColumnID.DYNAMIC_CTR_MAX,
        title: t('+/-'),
        onHeaderCell: () => ({
          tooltip: t('Change in ctr between the two compared dates'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: KeywordSortOption.DYNAMIC_CTR_CHANGE,
            sortingKey: SORTING_TYPE.numerical,
          },
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.DYNAMIC_CTR_CHANGE],
            filterTooltip: t('Add filter for CTR'),
          },
          menuTitle: t('Change in CTR'),
        }),
        width: 60,
        cellRenderer: withKeywordCellLoader(CtrChangeCell, { overlayOnly: true }),
      },
      {
        id: KeywordColumnID.DYNAMIC_CTR_MAX,
        combineRow: KeywordColumnID.DYNAMIC_CTR_POTENTIAL,
        title: t('Max'),
        onHeaderCell: () => ({
          tooltip: t('Max Click-through Rate'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: KeywordSortOption.DYNAMIC_CTR_MAX,
            sortingKey: SORTING_TYPE.numerical,
          },
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.DYNAMIC_CTR_MAX],
            filterTooltip: t('Add filter for CTR'),
          },
          menuTitle: t('Max Click-through Rate'),
        }),
        width: language === 'da' ? 60 : 55,
        cellRenderer: withKeywordCellLoader(MaxCTRCell, {
          dependsOnSearchVolume: false,
          onlyGoogle: true,
        }),
      },
      {
        id: KeywordColumnID.AI_OVERVIEW,
        title: t('AI Overview'),
        flex: true,
        width: 240,
        cellRenderer: AIOverviewCell,
      },
      {
        id: KeywordColumnID.ACTIONS,
        /** Render context menu in this column header */
        title: (
          <KebabMenu
            title={t('AI Overview data')}
            getCsvData={handleFetchCSVData}
            csvFilename={`AccuRanker_AI_Overview_${slugify(domain)}_${formatDate(new Date())}`}
          />
        ),
        width: 44,
        fixed: 'right' as FixedType,
        cellRenderer: (props) => {
          return ActionsCell({ ...(props as unknown as ActionsCellProps), actionSerp: true });
        },
        cellRendererParams: {
          isDemoDomain,
          hideRefresh: true,
          actionSerp: true,
        },
      },
    ],
    [
      metaData?.searchTypes,
      metaData?.searchEngines,
      countryLocales,
      isDemoDomain,
      language,
      handleFetchCSVData,
      domain,
      showLandingPageModal,
    ],
  );

  return { columns };
};
