import { MouseEventHandler } from 'react';
import cn from 'classnames';
import styles from './filterButton.module.scss';
import { AccFastIcon } from 'Components/AccFastIcon';
import filterIcon from 'icons/filter.svg';
import { useMantineTheme } from '@mantine/core';

type Props = {
  activeFilter: boolean;
  filterId?: string;
  filterContent?: (activeFilter?: boolean) => JSX.Element;
  filterClassName?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const FilterBtn = ({
  activeFilter,
  filterId,
  filterContent,
  filterClassName,
  onClick,
}: Props) => {
  const { other } = useMantineTheme();
  return (
    <button
      className={cn(styles.columnFilterBtn, filterClassName)}
      id={filterId}
      data-active={activeFilter || undefined}
      data-filterbutton={true}
      onClick={onClick}
    >
      {filterContent?.(activeFilter) ?? <AccFastIcon src={filterIcon} size={16} color={other.icon} />}
    </button>
  );
};
