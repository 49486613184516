import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { TableFetchDataCallBack } from 'Components/DataTable';
import { TableFetchDataCallbackParams } from 'Components/DataTable/types';
import { PaginatedTableKeywordsDocument, usePaginatedTableKeywordsLazyQuery } from 'Ghql';
import { formatKeyword } from 'Pages/Keywords/Table/hooks/keyword/useTableKeyword';
import { selectCurrentFilters } from 'Selectors/FilterSelector';
import { AIOverviewFilter, FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';

export const ENABLED_AI_OVERVIEW_TABLE_COLUMNS = {
  withBaseRank: true,
  withCountrylocaleId: true,
  withDescription: true,
  withDisplayName: true,
  withDomain: true,
  withDomainId: true,
  withDynamicCtr: true,
  withDynamicTags: true,
  withEnableAutocorrect: true,
  withExtraRanks: true,
  withHasExtraRanks: true,
  withHighestRankingPage: true,
  withHistoryUrl: true,
  withId: true,
  withIgnoreFeaturedSnippet: true,
  withIgnoreInShareOfVoice: true,
  withIgnoreLocalResults: true,
  withIgnoredDomain: true,
  withLandingPageId: true,
  withLocation: true,
  withPreferredLandingPageId: true,
  withRankCompare: true,
  withRankId: true,
  withRankValue: true,
  withKeyword: true,
  withSearchEngineId: true,
  withStarred: true,
  withTags: true,
  withTitle: true,
  withTopDomain: true,
  withUpdatingKeyword: true,
  withSerp: true,
  withSearchTypeId: true,
  withAboveTheFold: false,
  withAiSearchVolume: false,
  withAiTrafficValue: false,
  withAiTrafficValueCompare: false,
  withAnalyticsPotential: false,
  withAnalyticsVisitors: false,
  withAvgCostPerClick: false,
  withBaseRankCompare: false,
  withCompetition: false,
  withDateAdded: false,
  withIgnoredDomains: false,
  withKeywordDescription: false,
  withLocalPackRank: false,
  withLocalPackRankCompare: false,
  withMaxAiSov: false,
  withMaxAiTrafficValue: false,
  withMaxRank: false,
  withMaxSov: false,
  withMaxTrafficValue: false,
  withOrganicTraffic: false,
  withOrganicTrafficCompare: false,
  withPixelsFromTop: false,
  withPixelsFromTopCompare: false,
  withSearchDate: false,
  withSearchIntent: false,
  withSearchVolumeValue: false,
  withShareOfVoiceCompare: false,
  withShareOfVoiceCpcCompare: false,
  withShareOfVoiceCpcValue: false,
  withShareOfVoiceIsPercentage: false,
  withShareOfVoicePercentageValue: false,
  withShareOfVoicePercentageValueCompare: false,
  withShareOfVoiceValue: false,
  withValidForSearchVolume: false,
  withAiOverviewUrls: true,
  withAiOverviewText: true,
};

export const useFetchAIOverviewData = ({
  displayCurrency,
}: {
  displayCurrency: string;
}): TableFetchDataCallBack => {
  const store = useStore();
  const [getKeywords] = usePaginatedTableKeywordsLazyQuery();

  return useCallback(
    async ({ ordering, pagination, force = false }: TableFetchDataCallbackParams) => {
      const currentFilters = selectCurrentFilters(store.getState());
      const aiOverviewFilter: AIOverviewFilter = {
        attribute: FilterAttribute.HAS_AI_OVERVIEW,
        type: FilterValueType.BOOL,
        comparison: FilterComparison.EQ,
        value: true,
      };

      const filters = [...currentFilters, aiOverviewFilter];

      const { data } = await getKeywords({
        fetchPolicy: force ? 'network-only' : 'cache-first',
        query: PaginatedTableKeywordsDocument,
        variables: {
          ...ENABLED_AI_OVERVIEW_TABLE_COLUMNS,
          filters,
          pagination,
          ordering,
          displayCurrency,
        },
      });
      return {
        data: data?.tableKeywords?.keywords?.map((e) => formatKeyword(e)),
        length: data?.tableKeywords?.pagination?.numResults,
      };
    },
    [store, displayCurrency, getKeywords],
  );
};
