import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@mantine/core';
import { LineChart } from 'Components/Chart/LineChart';
import { chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import { useHistoryChartsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { MissingDataOverlay } from 'Pages/Keywords/Overview/MissingDataOverlay';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer';
import { colorScheme } from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { getHistoricalAIOverviewData } from './GetHistoricalAIOverviewData';

type HistoricalKeywordCount = {
  aiOverview: number;
  aiOverviewOwned: number;
  date: number;
  keywords: number;
};

export const HistoricalKeywordCountChart = () => {
  const chartRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const filters = useFilters();
  const [updateKey, setUpdateKey] = useState(0);

  useEffect(() => {
    setUpdateKey((prev) => prev + 1);
  }, [filters]);

  const { data, loading } = useHistoryChartsQuery({
    variables: { filters, charts: ['ai_overview', 'ai_overview_owned'], name: 'historyCharts' },
    skip: !filters.length,
  });
  const overviewHistoryGraphs: HistoricalKeywordCount[] = data?.graphs?.overviewHistoryGraphs || [];

  const hasAIOverview = overviewHistoryGraphs.some((graph) => graph.aiOverview > 0);

  const dataSeries = [
    {
      name: t('Keywords including AIO'),
      data: overviewHistoryGraphs.map((point) => ({
        x: point.date,
        y: point.aiOverview || 0,
      })),
      color: colorScheme.graphs.colors.darkBlue,
    },
    {
      name: t('Keywords including AIO - owned'),
      data: overviewHistoryGraphs.map((point) => ({
        x: point.date,
        y: point.aiOverviewOwned || 0,
      })),
      color: colorScheme.graphs.primaryDomain,
    },
  ];

  const chartLabel = t('Historical Keyword Count with AI Overview');

  const fileName = `Chart ${chartLabel}`;

  return (
    <ChartContainer
      title={chartLabel}
      chartRef={chartRef}
      minHeight={520}
      getCsvData={getHistoricalAIOverviewData}
      chartSeries={dataSeries}
      boardControls={{
        removeChart: null,
        disabledDnd: true,
      }}
      tooltip={[
        t(
          'The graph illustrates the number of keywords including AI Overview SERPs over time, along with the distribution of owned AI Overview SERPs.',
        ),
      ]}
    >
      <Box pos="relative" ref={chartRef}>
        {!hasAIOverview && !loading && (
          <MissingDataOverlay extraBlurry type="noAIOverviews" isEmptyGroup={false} />
        )}
        <LineChart
          key={updateKey}
          isLoading={loading}
          series={dataSeries}
          isRank={false}
          exportButton={false}
          placeLegendBelowChart
          multiLineNavigator
          fileName={fileName}
          enableRangeSelector
          interactiveLegend={true}
          yAxisReversed={false}
          tooltip={{
            type: chartTooltipTypes.MULTI,
            customNumFormatter: (num: number) =>
              intl?.formatNumber(num, {
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }),
          }}
        />
      </Box>
    </ChartContainer>
  );
};
