import { FC, ReactElement, ReactNode, forwardRef, useEffect, useRef, useState } from 'react';
import { Box, Flex, Menu, Popover, useMantineTheme } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { IconCheck, IconDotsVertical } from '@tabler/icons-react';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { QueryOrder } from 'Components/DataTable/constants';
import { ColumnFilter } from 'Components/DataTable/types';
import getFilterData from 'Components/Filters/getFilterData';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { useFilters } from 'Hooks';
import { FilterBase } from 'Types/Filter';
import { Ordering } from 'Types/Sort';
import { getSortingIcon, getSortingText } from 'Utilities/getSortingInfo';
import { t } from 'Utilities/i18n';
import { TableFilter } from './components/TableFilter/TableFilter';
import FilterTabs from './components/TableFilter/components/FilterTabs/filterTabs';
import filterIcon from 'icons/filter.svg';
import { AccFastIcon } from 'Components/AccFastIcon';

const HeaderValue = ({
  headerContent,
  tooltip,
}: {
  headerContent: ReactNode;
  tooltip?: string;
}): JSX.Element | null => {
  if (typeof headerContent === 'string') {
    return (
      <AccTooltip tooltip={tooltip}>
        <AccTitle type="h5" pl={5} h="100%" size={11.2}>
          {headerContent}
        </AccTitle>
      </AccTooltip>
    );
  }
  if (typeof headerContent === 'number' || typeof headerContent === 'boolean') {
    return <>{headerContent}</>;
  }
  return (headerContent as JSX.Element) || null;
};

type PolymorphicProps<T extends React.ElementType> = {
  // eslint-disable-next-line react/no-unused-prop-types
  /** Render the component as a table-header (th) in DataTables and div in Virtualized Tables */
  as?: T;
  // eslint-disable-next-line react/no-unused-prop-types
  children?: React.ReactNode;
} & React.ComponentPropsWithoutRef<T> & {
    // eslint-disable-next-line react/no-unused-prop-types
    ref?: React.Ref<React.ElementRef<T>>;
  };

// Define the Polymorphic Component Type
type PolymorphicComponent = <T extends React.ElementType = 'th'>(
  props: PolymorphicProps<T>,
) => React.ReactElement | null;

// eslint-disable-next-line react/display-name
const PolyComponent: PolymorphicComponent = forwardRef(
  <T extends React.ElementType = 'th'>(
    { as, children, ...props }: PolymorphicProps<T>,
    ref: React.Ref<React.ElementRef<T>>,
  ) => {
    const Component = as || 'th';
    return (
      <Component {...props} ref={ref}>
        {children}
      </Component>
    );
  },
);

(PolyComponent as FC).displayName = 'PolyMorphicComponent';

type AccHeaderCellClassNames = {
  root: string;
  container: string;
  content: string;
  text: string;
  popover: string;
  iconWrapper: string;
  filterIconWrapper: string;
  dropdownMenu: string;
};

interface AccTableHeaderCellProps {
  /** Indicates if this cell has active ordering */
  activeOrdering: boolean;
  /** Indicates if this cell has active ordering on the change ordering value */
  activeChangeOrdering?: boolean;
  order?: QueryOrder;
  onOrderChange?: (
    event: React.MouseEvent<HTMLButtonElement>,
    specificOrder?: QueryOrder,
    resetToDefault?: boolean,
    /** Order by the change value */
    useChangeOrdering?: boolean,
  ) => void;
  headerContent?: ReactNode;
  tooltip?: string;
  filter?: ColumnFilter;
  ordering?: Ordering;
  orderingChange?: Ordering;
  reverseDirection?: boolean;
  disabledMenu?: boolean;
  menuTitle?: string;
  CustomMenuItem?: () => JSX.Element;
  classNames?: Partial<AccHeaderCellClassNames>;
  style?: React.CSSProperties;
  searchFieldSlot?: ReactElement;
}

export const AccTableHeaderCell = ({
  activeOrdering,
  activeChangeOrdering,
  order,
  onOrderChange,
  headerContent,
  tooltip,
  filter,
  ordering,
  orderingChange,
  reverseDirection,
  disabledMenu,
  menuTitle,
  CustomMenuItem,
  classNames = {},
  style,
  as = 'th',
  searchFieldSlot,
}: AccTableHeaderCellProps & PolymorphicProps<'th' | 'div'>) => {
  const hasOrderingConfig = !!ordering?.orderBy;
  const hasOrderingChangeConfig = !!orderingChange?.orderBy;
  /** Indicates if the header cell has filter configuration */
  const hasFilterConfig = !!filter;
  const filters = useFilters();
  const isDesc = order === QueryOrder.DESC;

  const { colors, primaryShade } = useMantineTheme();
  const primarySnorlax = colors.snorlax[primaryShade as number];

  const [menuOpened, setMenuOpened] = useState(false);
  const [filterPopoverOpened, setFilterPopoverOpened] = useState(false);
  const [popoverFilterAttribute, setPopoverFilterAttribute] = useState<
    FilterBase['attribute'] | undefined
  >(filter?.filterAttributes[0]);
  // useClickOutside only works with useState, not useRef
  const [dropdown, setDropdown] = useState<HTMLDivElement | null>(null);
  const [filterButton, setFilterButton] = useState<HTMLDivElement | null>(null);
  const headerCellRef = useRef<HTMLDivElement | null>(null);
  const [activeFilters, setActiveFilters] = useState<FilterBase[]>([]);
  const showFilterButton = !!activeFilters.length || filter?.filterVisible;

  const getTabName = (filterAttribute: string | undefined) => {
    const { title } = getFilterData(filterAttribute) || {};
    return title;
  };

  useEffect(() => {
    if (filter?.filterAttributes && filters) {
      setActiveFilters(
        filters.filter(Boolean).filter((f) => filter?.filterAttributes.includes(f.attribute)),
      );
    }
  }, [filter?.filterAttributes, filters]);

  useClickOutside(() => setFilterPopoverOpened(false), null, [dropdown, filterButton]);

  return (
    <Popover
      opened={filterPopoverOpened}
      disabled={menuOpened || !hasFilterConfig}
      withinPortal
      position={'bottom-start'}
      classNames={{ dropdown: classNames.popover }}
    >
      <Popover.Target>
        <PolyComponent className={classNames.root} style={style} as={as}>
          <AccTooltip tooltip={tooltip} disable={menuOpened}>
            <Flex
              align={'center'}
              direction={reverseDirection ? 'row-reverse' : 'row'}
              className={classNames.container}
            >
              <Flex
                align={'center'}
                ref={headerCellRef}
                className={classNames.content}
                mr={reverseDirection ? -7 : undefined}
                ml={!reverseDirection ? -7 : undefined}
                onClick={() => {
                  if (!menuOpened) {
                    setMenuOpened((isOpened) => !isOpened);
                  }
                }}
              >
                <Flex ml={reverseDirection ? 'auto' : undefined}>
                  <div className={classNames.text}>
                    <HeaderValue headerContent={headerContent} tooltip={tooltip} />
                  </div>
                </Flex>
                {activeChangeOrdering && (
                  <div style={{ whiteSpace: 'pre' }} className={classNames.text}>
                    {' (+/-) '}
                  </div>
                )}
                {hasFilterConfig && showFilterButton && (
                  <div ref={setFilterButton} className={classNames.filterIconWrapper}>
                    <TableFilter
                      filter={filter}
                      onClick={(e) => {
                        e.stopPropagation();
                        setFilterPopoverOpened(!filterPopoverOpened);
                      }}
                    />
                  </div>
                )}
                {hasOrderingConfig &&
                  (activeOrdering || activeChangeOrdering) &&
                  getSortingIcon(isDesc, hasOrderingConfig, ordering)}
                {searchFieldSlot && (
                  <Box mt={-3} mb={-3}>
                    {searchFieldSlot}
                  </Box>
                )}
                {(hasOrderingConfig || hasFilterConfig) && !disabledMenu && (
                  <Menu
                    shadow="md"
                    width={hasOrderingChangeConfig ? 224 : 208}
                    position={'bottom-start'}
                    opened={menuOpened}
                    transitionProps={{ duration: 0 }}
                    onChange={() => {
                      //timeout used to make sure this event is fired after the click event
                      setTimeout(() => setMenuOpened((isOpened) => !isOpened), 0);
                    }}
                    offset={4}
                    //pointerup event is used to make this event fire after the click event
                    clickOutsideEvents={['pointerup']}
                  >
                    <Menu.Target>
                      <button
                        className={classNames.iconWrapper}
                        data-reverse-direction={!!reverseDirection}
                        onClick={(e) => {
                          // Prevents the event from bubbling up to the header cell onClick
                          e.stopPropagation();
                        }}
                      >
                        <IconDotsVertical size={16} />
                      </button>
                    </Menu.Target>
                    <Menu.Dropdown
                      className={classNames.dropdownMenu}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {(headerContent || menuTitle) && (
                        <>
                          <Menu.Label>
                            <AccText variant="label">{menuTitle || headerContent}</AccText>
                          </Menu.Label>
                          <Menu.Divider />
                        </>
                      )}

                      {hasFilterConfig && (
                        <>
                          <Menu.Label>{t('Filter by')}</Menu.Label>
                          {filter.filterAttributes.map((filterAttribute) => (
                            <Menu.Item
                              key={filterAttribute}
                              leftSection={
                                <AccFastIcon src={filterIcon} size={16} color={primarySnorlax} />
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                setFilterPopoverOpened(true);
                                setPopoverFilterAttribute(filterAttribute);
                              }}
                            >
                              {getTabName(filterAttribute)}
                            </Menu.Item>
                          ))}
                        </>
                      )}
                      {hasOrderingConfig && (
                        <>
                          {hasFilterConfig && <Menu.Divider />}
                          <Menu.Label>{t('Sort by')}</Menu.Label>
                          <Menu.Item
                            leftSection={getSortingIcon(false, hasOrderingConfig, ordering)}
                            onClick={(event) => {
                              onOrderChange?.(event, QueryOrder.ASC);
                            }}
                          >
                            <Flex justify={'space-between'}>
                              {getSortingText(true, hasOrderingConfig, ordering)}
                              {activeOrdering && !isDesc ? <IconCheck size={16} /> : null}
                            </Flex>
                          </Menu.Item>
                          <Menu.Item
                            leftSection={getSortingIcon(true, hasOrderingConfig, ordering)}
                            onClick={(event) => {
                              onOrderChange?.(event, QueryOrder.DESC);
                            }}
                          >
                            <Flex justify={'space-between'}>
                              {getSortingText(false, hasOrderingConfig, ordering)}
                              {activeOrdering && isDesc ? <IconCheck size={16} /> : null}
                            </Flex>
                          </Menu.Item>
                          {activeOrdering && (
                            <Menu.Item onClick={(event) => onOrderChange?.(event, undefined, true)}>
                              {t('Unsort')}
                            </Menu.Item>
                          )}
                        </>
                      )}
                      {hasOrderingChangeConfig && (
                        <>
                          {hasFilterConfig && <Menu.Divider />}
                          <Menu.Label>{`${t('Sort by change')} (+/-)`}</Menu.Label>
                          <Menu.Item
                            leftSection={getSortingIcon(
                              false,
                              hasOrderingChangeConfig,
                              orderingChange,
                            )}
                            onClick={(event) => {
                              onOrderChange?.(event, QueryOrder.ASC, undefined, true);
                            }}
                          >
                            <Flex justify={'space-between'}>
                              {`${getSortingText(
                                true,
                                hasOrderingChangeConfig,
                                orderingChange,
                              )} +/-`}
                              {activeChangeOrdering && !isDesc ? <IconCheck size={16} /> : null}
                            </Flex>
                          </Menu.Item>
                          <Menu.Item
                            leftSection={getSortingIcon(
                              true,
                              hasOrderingChangeConfig,
                              orderingChange,
                            )}
                            onClick={(event) => {
                              onOrderChange?.(event, QueryOrder.DESC, undefined, true);
                            }}
                          >
                            <Flex justify={'space-between'}>
                              {`${getSortingText(
                                false,
                                hasOrderingChangeConfig,
                                orderingChange,
                              )}  +/-`}
                              {activeChangeOrdering && isDesc ? <IconCheck size={16} /> : null}
                            </Flex>
                          </Menu.Item>
                          {activeChangeOrdering && (
                            <Menu.Item onClick={(event) => onOrderChange?.(event, undefined, true)}>
                              {t('Unsort')}
                            </Menu.Item>
                          )}
                        </>
                      )}
                      {CustomMenuItem && <CustomMenuItem />}
                    </Menu.Dropdown>
                  </Menu>
                )}
              </Flex>
            </Flex>
          </AccTooltip>
        </PolyComponent>
      </Popover.Target>
      <Popover.Dropdown ref={setDropdown} maw={640}>
        {hasFilterConfig && popoverFilterAttribute && (
          <FilterTabs
            initialTab={filter.filterAttributes.indexOf(popoverFilterAttribute) + 1}
            filterAttributes={filter.filterAttributes}
            currentActiveFilters={activeFilters}
            onClose={() => setFilterPopoverOpened(false)}
            onSetFilter={() => setFilterPopoverOpened(false)}
          />
        )}
      </Popover.Dropdown>
    </Popover>
  );
};
