import { Link } from 'react-router-dom';
import { Box } from '@mantine/core';
import { Field } from 'redux-form';
import { tct } from 'Utilities/i18n';
import LoadingSpinner from '../../../LoadingSpinner';
import { SubdomainFieldInput } from './components/SubdomainFieldInput';
import { useSettingsPageLink, useSubDomainFilterData } from './support/hooks';

const SubdomainEditor = () => {
  const { loading, subDomainDisabled } = useSubDomainFilterData();
  const settingsLink = useSettingsPageLink();

  if (loading) {
    return (
      <Box style={{ textAlign: 'center' }} m="xs">
        <LoadingSpinner />{' '}
      </Box>
    );
  }

  if (subDomainDisabled) {
    return (
      <Box m="xs">
        {tct(
          'Subdomains not enabled on this domain. To enable subdomains go to:[br][br][link:Settings] > Edit domain > Show advanced settings > Include subdomains',
          {
            link: <Link to={settingsLink} />,
            br: <br />,
          },
        )}
      </Box>
    );
  }
  return (
    <>
      <Field name="value" component={SubdomainFieldInput} />
    </>
  );
};

export default SubdomainEditor;
