import { useEffect, useState } from 'react';
import type { Components } from 'react-markdown';
import { Box, Flex, Grid, Group, Loader, Popover, ScrollArea, Space } from '@mantine/core';
import { IconCopy } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccButton from 'Components/AccButton/AccButton';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { DomainFavicon } from 'Components/DomainFavicon';
import ErrorBoundary from 'Components/ErrorBoundary';
import Icon from 'Components/Icon/new';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { useClipboard } from 'Hooks';
import { t } from 'Utilities/i18n';
import variables from 'css/base/variables.module.scss';
import IconAiOverview from 'icons/ai_overview.svg?inline';
import styles from './AIOverviewCell.module.scss';

const getAIOverviewLabel = (aiOverviewText: string) => {
  const replacements = ['"', '*', '==', '|'];
  replacements.forEach((replacement) => {
    aiOverviewText = aiOverviewText.replaceAll(replacement, '');
  });
  return `${aiOverviewText.slice(0, 100)}...`;
};

export const MarkdownHighlightPart = ({ childMarkdown }: { childMarkdown: string }) => {
  /** Get highlighted markdown parts inside ==highlighted== tags. */
  const getHighlightRegex = /==([\s\S]*?)==/g;
  const textContent = childMarkdown;
  const parts = textContent.split(getHighlightRegex);

  return (
    <>
      {/* eslint-disable-next-line no-confusing-arrow */}
      {parts.map((part, i) =>
        i % 2 === 1 ? (
          <mark key={part} className={styles.highlight}>
            {part}
          </mark>
        ) : (
          part
        ),
      )}
    </>
  );
};

const MarkdownWithHighlights = ({ aiOverviewText }: { aiOverviewText: string }) => {
  const [dependencies, setDependencies] = useState<{
    ReactMarkdown: any;
    remarkGfm: any;
  } | null>(null);

  //Lazy load dependencies
  useEffect(() => {
    let isLoaded = false;
    const loadDependencies = async () => {
      const [ReactMarkdown, remarkGfm] = await Promise.all([
        import('react-markdown').then((module) => module.default),
        import('remark-gfm').then((module) => module.default),
      ]);
      setDependencies({ ReactMarkdown, remarkGfm });
    };

    if (!isLoaded) {
      loadDependencies();
      isLoaded = true;
    }
  }, []);

  if (!dependencies) {
    return (
      <Flex w="100%" align="center" justify="center" mih={268}>
        <Loader type="dots" color="gray" />
      </Flex>
    );
  }

  const { ReactMarkdown, remarkGfm } = dependencies;

  /** ReactMarkDown does not support markdown for highlights natively.
   * This component will get all markdown parts for highlights.
   *
   * Example:
   * "Some text ==with highlight== here"
   * where `with highlight` will be rendered inside `<mark>` tags.
   */
  const components: Components = {
    p: ({ children }) => {
      if (children && typeof children === 'string') {
        return <MarkdownHighlightPart childMarkdown={children} />;
      }
      if (children && Array.isArray(children)) {
        return children.map((child) => {
          if (typeof child === 'string') {
            return <MarkdownHighlightPart key={child} childMarkdown={child} />;
          }
          return child;
        }) as unknown as JSX.Element;
      }
      return children as string;
    },
    /** Add target: `_blank` to anchor tags */
    a: ({ children, href }) => {
      return (
        <a href={href} target="_blank" rel="noreferrer">
          {children}
        </a>
      );
    },
  };

  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={components}>
      {aiOverviewText}
    </ReactMarkdown>
  );
};

type AIOverviewInfoProps = {
  aiOverviewText: string;
  aiOverviewUrls: string[] | undefined;
  withinPortal?: boolean;
  owned: boolean;
};

function removeWWW(url: string): string {
  if (url.startsWith('www.')) {
    return url.substring(4);
  }
  return url;
}

const splitArray = (strings: string[] | undefined) => {
  if (!strings || !strings.length) {
    return undefined;
  }
  const domains = strings.map((url) => {
    const domainUrl = new URL(url);
    const host = removeWWW(domainUrl.host);
    return { url, host };
  });

  const midpoint = Math.ceil(domains.length / 2);
  const firstHalf = domains.slice(0, midpoint);
  const secondHalf = domains.slice(midpoint);

  return [firstHalf, secondHalf];
};

const AIOverviewInfo = ({
  children,
  withinPortal = true,
  aiOverviewText,
  aiOverviewUrls,
  owned,
}: React.PropsWithChildren<AIOverviewInfoProps>) => {
  const [renderPopover, setRenderPopover] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const clipboard = useClipboard({ timeout: 1000 });

  if (!renderPopover) {
    // We do not want to render whats inside the popover before its necessary.
    // So we do it only when hovering popover target. In mantine v6 this will be supported out of the box by mantine.
    return <div onMouseEnter={() => setRenderPopover(true)}>{children}</div>;
  }

  const domains = splitArray(aiOverviewUrls);

  // Please note the innermost div is necessary, deleting it will ruin the popover!.
  return (
    <Popover
      width={432}
      shadow="xs"
      zIndex={1000}
      onChange={() => setPopoverOpen(!popoverOpen)}
      withinPortal={withinPortal}
      returnFocus
      trapFocus
    >
      <Popover.Target>
        <div className="popover-trigger" data-open={popoverOpen}>
          {children}
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Flex direction="column" gap={6} miw={400}>
          <ErrorBoundary
            fallback={({ resetError }) => (
              <Flex align={'center'} justify="flex-start" direction="column" mih={120} role="alert">
                <AccTitle type="h3" my={16} size={14}>
                  {t('An error occurred while getting details about this SERP feature.')}
                </AccTitle>
                <AccButton
                  onClick={() => {
                    setRenderPopover(false);
                    setTimeout(() => {
                      resetError;
                    }, 100);
                  }}
                >
                  {t('Close')}
                </AccButton>
              </Flex>
            )}
          >
            <Flex direction="column" gap={6} className={styles.summary}>
              <Group gap={4}>
                <AccText fw={600}>{`${t('AI Overview')} ${
                  owned ? `(${t('owned')})` : ''
                }`}</AccText>
                <AccTooltip
                  old
                  tooltip={clipboard.copied ? t('Copied!') : t('Copy the AI Overview text')}
                >
                  <AccActionIcon
                    variant="subtle"
                    onClick={(e) => clipboard.copy(aiOverviewText, e)}
                  >
                    <IconCopy size={16} />
                  </AccActionIcon>
                </AccTooltip>
              </Group>
              <div className={styles.markdownContainer}>
                <ScrollArea.Autosize
                  mah={300}
                  type="hover"
                  scrollHideDelay={300}
                  offsetScrollbars
                  scrollbars="y"
                  classNames={{
                    viewport: styles.scrollareaViewport,
                    scrollbar: styles.scrollbar,
                  }}
                >
                  <Box mb={32}>
                    <MarkdownWithHighlights aiOverviewText={aiOverviewText} />
                  </Box>
                </ScrollArea.Autosize>
              </div>
            </Flex>
            {domains && !!aiOverviewUrls?.length ? (
              <Flex direction="column" gap={6}>
                <AccText fw={600} mb={-8}>
                  {t('Listed domains')}
                </AccText>
                <Grid columns={2} gutter={0}>
                  <Grid.Col span={{ lg: 1 }} miw={182} p={4}>
                    {domains[0].map((domain) => (
                      <Group
                        key={domain.url}
                        className={styles.linkGroup}
                        mih={28}
                        gap={'xs'}
                        mb={4}
                        maw={182}
                        wrap={'nowrap'}
                      >
                        <DomainFavicon domain={domain.url} />
                        <AccText
                          size="sm"
                          fw={'normal'}
                          c={'blue'}
                          ta="start"
                          href={domain.url}
                          target="_blank"
                          overflowEllipsis
                          noOverflowTooltip
                          nowrap
                          truncate={true}
                          linkClassName={styles.link}
                          w={156}
                          miw={156}
                        >
                          {domain.host}
                        </AccText>
                        <AccTooltip
                          old
                          tooltip={clipboard.copied ? t('Copied!') : t('Copy the URL')}
                        >
                          <AccActionIcon
                            ml="auto"
                            variant="subtle"
                            color="snorlax"
                            onClick={(event) => {
                              clipboard.copy(domain.url, event);
                            }}
                          >
                            <IconCopy size={16} />
                          </AccActionIcon>
                        </AccTooltip>
                      </Group>
                    ))}
                  </Grid.Col>
                  <Grid.Col span={{ lg: 1 }} miw={182} p={4}>
                    {domains[1].map((domain) => (
                      <Group
                        key={domain.url}
                        className={styles.linkGroup}
                        mih={28}
                        gap={'xs'}
                        mb={4}
                        maw={182}
                        wrap={'nowrap'}
                      >
                        <DomainFavicon domain={domain.url} />
                        <AccText
                          size="sm"
                          fw={'normal'}
                          c={'blue'}
                          ta="start"
                          href={domain.url}
                          target="_blank"
                          overflowEllipsis
                          noOverflowTooltip
                          nowrap
                          truncate={true}
                          linkClassName={styles.link}
                          w={156}
                          miw={156}
                        >
                          {domain.host}
                        </AccText>
                        <AccTooltip
                          old
                          tooltip={clipboard.copied ? t('Copied!') : t('Copy the URL')}
                        >
                          <AccActionIcon
                            ml="auto"
                            variant="subtle"
                            color="snorlax"
                            onClick={(event) => clipboard.copy(domain.url, event)}
                          >
                            <IconCopy size={16} />
                          </AccActionIcon>
                        </AccTooltip>
                      </Group>
                    ))}
                  </Grid.Col>
                </Grid>
              </Flex>
            ) : (
              <Space />
            )}
          </ErrorBoundary>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

type AIOverviewCellProps = {
  value: {
    data: {
      aiOverviewText?: string;
      aiOverviewUrls?: string[];
      serpAiOverview?: boolean;
      serpAiOverviewOwned?: boolean;
      historyUrl?: string;
    };
    loadingData?: boolean;
  };
};

export const AIOverviewCell = ({ value }: AIOverviewCellProps) => {
  const { data } = value || {};

  if (!data.serpAiOverview || !data.aiOverviewText) {
    return <></>;
  }
  const isOwned = !!data.serpAiOverviewOwned;
  const iconColor = isOwned ? variables.orange4 : variables.snorlax4;

  return (
    <AIOverviewInfo
      aiOverviewText={data.aiOverviewText}
      aiOverviewUrls={data.aiOverviewUrls}
      owned={isOwned}
    >
      <button className={styles.aiOverviewButton}>
        <Flex gap={'xs'} w="100%" align="center">
          <Icon width={16} height={16} color={iconColor} className={styles.aiOverviewIcon}>
            <IconAiOverview />
          </Icon>

          <AccText size="sm" fw={600} c={variables.snorlax4} overflowEllipsis>
            {getAIOverviewLabel(data.aiOverviewText)}
          </AccText>
        </Flex>
      </button>
    </AIOverviewInfo>
  );
};
