import { UrlListItemData } from '../UrlChart';

export const useFakeAIOverviewDomainCountData = (): UrlListItemData[] => {
  return [
    {
      count: 4,
      url: 'adidas.co.uk',
    },
    {
      count: 6,
      url: 'amazon.com',
    },
    {
      count: 5,
      url: 'google.com',
    },
    {
      count: 7,
      url: 'garmin.com',
    },
  ];
};
