import { ReactElement } from 'react';
import cn from 'classnames';
import { AccTableHeaderCell } from 'Components/AccTableHeaderCell';
import { QueryOrder } from 'Components/DataTable';
import tableStyles from 'Components/Table/VirtualizedTable/virtualizedTable.module.scss';
import { HomeTreeViewDefaultOrder } from 'Pages/Domains/TreeView/support/constants';
import { CellRendererProps } from 'Pages/Domains/TreeView/useTreeViewTableConfig';
import styles from 'css/modules/treeTableHeader.module.scss';
import { FolderCellProps, TableColumnConfig } from '../utils/useFoldersTableConfig';

type Props = {
  header: TableColumnConfig<FolderCellProps> | TableColumnConfig<CellRendererProps>;
  content: string;
  setOrderBy: (orderBy?: string, order?: QueryOrder) => void;
  orderBy: string;
  order: QueryOrder;
  searchFieldSlot?: ReactElement;
  sticky?: boolean;
  withCollapseAll?: boolean;
};
const HeaderColumn = (props: Props) => {
  const {
    content,
    header,
    setOrderBy,
    orderBy,
    order,
    searchFieldSlot,
    sticky,
    withCollapseAll = true,
  } = props;

  const onOrderChange = (
    _event: React.MouseEvent<HTMLButtonElement>,
    specificOrder?: QueryOrder,
    resetToDefault?: boolean,
    useChangeOrdering?: boolean,
  ) => {
    if (resetToDefault) {
      setOrderBy(HomeTreeViewDefaultOrder.orderBy, HomeTreeViewDefaultOrder.order);
      return;
    }
    if (useChangeOrdering) {
      setOrderBy(header.onHeaderCell?.().orderingChange?.orderBy, specificOrder);
      return;
    }
    setOrderBy(header.onHeaderCell?.().ordering?.orderBy, specificOrder);
  };

  const headerCellConfig = header.onHeaderCell?.();
  const headerCellOrdering = headerCellConfig?.ordering;
  const headerCellOrderingChange = headerCellConfig?.orderingChange;
  const activeOrdering = orderBy === headerCellOrdering?.orderBy;
  const activeChangeOrdering = orderBy === headerCellConfig?.orderingChange?.orderBy;

  const rootClass = cn(styles.headerCell, {
    [tableStyles.tableScrollShadowLeftItem]: sticky,
    [styles.sticky]: sticky,
    [styles.joinFirstColumns]: !withCollapseAll,
  });

  return (
    <AccTableHeaderCell
      activeOrdering={activeOrdering}
      activeChangeOrdering={activeChangeOrdering}
      order={order}
      onOrderChange={onOrderChange}
      headerContent={content}
      tooltip={headerCellConfig?.tooltip}
      ordering={headerCellOrdering}
      orderingChange={headerCellOrderingChange}
      reverseDirection={headerCellConfig?.reverseDirection}
      disabledMenu={false}
      menuTitle={headerCellConfig?.menuTitle}
      as="div"
      searchFieldSlot={searchFieldSlot}
      classNames={{
        root: rootClass,
        container: styles.headerCellContainer,
        content: styles.headerCellContent,
        iconWrapper: styles.contextIcon,
        dropdownMenu: styles.contextMenu,
      }}
    />
  );
};

export default HeaderColumn;
