import { useSelector } from 'react-redux';
import {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  IconBrandSafari,
  IconBrowserCheck,
  IconChess,
  IconDeviceDesktopAnalytics,
  IconFocus2,
  IconListSearch,
  IconNotes,
  IconSettings,
  IconTags,
  IconTournament,
} from '@tabler/icons-react';
import { linkToPageWithDomains } from 'Components/Filters/LinkToDomain';
import Icon from 'Components/Icon/new';
import useUserPermission from 'Hooks/useUserPermission';
import { DOMAINS } from 'Pages/Layout/ActionsMenu/support/constants';
import { specificFilterSelectorValue } from 'Selectors/SpecificFilterSelector';
import {
  EMPTY_FILTER_SET,
  FilterSet,
  KEYDIS_FILTER_SET,
  KEYWORDS_FILTER_SET,
  NOTES_FILTER_SET,
  SITE_MAPPING_FILTER_SET,
  TAG_CLOUD_FILTER_SET,
} from 'Types/FilterSet';
import { t } from 'Utilities/i18n';
import IconAiOverviewOutline from 'icons/ai_overview_outline.svg?inline';

interface KeywordNavItem {
  label: string;
  link: string;
  key: string;
  filterSet: FilterSet;
  icon?: () => JSX.Element;
  testid?: string;
}

export const pathToLabelMap = () => ({
  '/keywords/overview': t('Dashboard'),
  '/keywords/list': t('Keywords'),
  '/keywords/ai-overview': t('AI Overview'),
  '/keywords/keyword_discovery': t('Discovery'),
  '/keywords/tags': t('Tag Cloud'),
  '/keywords/sitemapping': t('Site Explorer'),
  '/keywords/competitors': t('Competitors'),
  '/keywords/landing-pages': t('Landing Pages'),
  '/keywords/serp': t('SERP Analysis'),
  '/notes': t('Notes'),
  '/settings': t('Settings'),
});

export const useKeywordNavItems = (includeAll?: boolean) => {
  const domainsFilterSelector = specificFilterSelectorValue(DOMAINS);
  const domains = useSelector(domainsFilterSelector) as string[];
  const { userHasWriteAccess } = useUserPermission();
  const labelMap = pathToLabelMap();

  const keywordNavItems: KeywordNavItem[] = [
    {
      label: labelMap['/keywords/overview'],
      key: '/keywords/overview',
      link: linkToPageWithDomains('/keywords/overview', domains, KEYWORDS_FILTER_SET),
      filterSet: KEYWORDS_FILTER_SET,
      icon: () => <IconDeviceDesktopAnalytics />,
    },
    {
      label: labelMap['/keywords/list'],
      key: '/keywords/list',
      link: linkToPageWithDomains('/keywords/list', domains, KEYWORDS_FILTER_SET),
      filterSet: KEYWORDS_FILTER_SET,
      icon: () => <IconListSearch />,
      testid: 'tab-keywords',
    },
  ];

  if (domains?.length === 1 || includeAll) {
    keywordNavItems.push(
      {
        label: labelMap['/keywords/keyword_discovery'],
        key: '/keywords/keyword_discovery',
        link: linkToPageWithDomains('/keywords/keyword_discovery', domains, KEYDIS_FILTER_SET),
        filterSet: KEYDIS_FILTER_SET,
        icon: () => <IconBrandSafari />,
      },
      {
        label: labelMap['/keywords/tags'],
        key: '/keywords/tags',
        link: linkToPageWithDomains('/keywords/tags', domains, TAG_CLOUD_FILTER_SET),
        filterSet: TAG_CLOUD_FILTER_SET,
        icon: () => <IconTags />,
      },
      {
        label: labelMap['/keywords/sitemapping'],
        key: '/keywords/sitemapping',
        link: linkToPageWithDomains('/keywords/sitemapping', domains, SITE_MAPPING_FILTER_SET),
        filterSet: SITE_MAPPING_FILTER_SET,
        icon: () => <IconTournament style={{ transform: 'rotate(180deg)' }} />,
      },
      {
        label: labelMap['/keywords/competitors'],
        key: '/keywords/competitors',
        link: linkToPageWithDomains('/keywords/competitors', domains, KEYWORDS_FILTER_SET),
        filterSet: KEYWORDS_FILTER_SET,
        icon: () => <IconChess />,
      },
      {
        label: labelMap['/keywords/landing-pages'],
        key: '/keywords/landing-pages',
        link: linkToPageWithDomains('/keywords/landing-pages', domains, KEYWORDS_FILTER_SET),
        filterSet: KEYWORDS_FILTER_SET,
        icon: () => <IconFocus2 />,
      },
      {
        label: labelMap['/keywords/serp'],
        key: '/keywords/serp',
        link: linkToPageWithDomains('/keywords/serp', domains, KEYWORDS_FILTER_SET),
        filterSet: KEYWORDS_FILTER_SET,
        icon: () => <IconBrowserCheck />,
      },
      {
        label: labelMap['/keywords/ai-overview'],
        key: '/keywords/ai-overview',
        link: linkToPageWithDomains('/keywords/ai-overview', domains, KEYDIS_FILTER_SET),
        filterSet: KEYWORDS_FILTER_SET,
        icon: () => (
          <Icon width={16} height={16}>
            <IconAiOverviewOutline />
          </Icon>
        ),
      },
      {
        label: labelMap['/notes'],
        key: '/notes',
        link: linkToPageWithDomains('/notes', domains, NOTES_FILTER_SET),
        filterSet: NOTES_FILTER_SET,
        icon: () => <IconNotes />,
      },
    );

    if (userHasWriteAccess) {
      keywordNavItems.push({
        label: labelMap['/settings'],
        key: '/settings',
        link: linkToPageWithDomains('/settings', domains, EMPTY_FILTER_SET),
        filterSet: EMPTY_FILTER_SET,
        icon: () => <IconSettings />,
      });
    }
  }

  return keywordNavItems;
};
