import { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { Center, Flex } from '@mantine/core';
import isEmpty from 'lodash/isEmpty';
import AccButton from 'Components/AccButton/AccButton';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import { Form } from 'Components/Fields';
import { Gutter, GutterSm } from 'Components/Gutter';
import {
  EnableOveruseModal,
  RequestEnableOveruseModal,
  RequestUpgradePlanModal,
  UpgradePlanModal,
} from 'Components/OveruseConfirmation';
import SimpleModal from 'Components/SimpleModal';
import AccText from 'Components/Text/AccText';
import {
  EnableOveruseArea,
  KeywordOveruseCode,
  useTotalRankCountLazyQuery,
  useTransferMultiAccountDomainMutation,
} from 'Ghql';
import toast from 'Hooks/useToast';
import { t, tct } from 'Utilities/i18n';
import TransferMultiAccountDomainForm from './TransferMultiAccountDomainForm/TransferMultiAccountDomainForm';
import styles from './TransferMultiAccountDomainForm/transfer-multi-account-domain-form.module.scss';
import { useFieldValue } from 'Hooks/form/useFieldValue';
import HelpTextPopover from 'Components/HelpTextPopover/HelpTextPopover';

type DomainAction = 'copy' | 'move';

type DomainActionConfirmationModalProps = {
  domainAction: DomainAction;
  opened: boolean;
  setOpened: (opened: boolean) => void;
  onConfirm: (values: any, copy: boolean) => Promise<void>;
};

const DomainActionConfirmationModal = ({
  domainAction,
  opened,
  setOpened,
  onConfirm,
}: DomainActionConfirmationModalProps) => {
  const form = useForm();
  const formValues = form.getState().values;

  return (
    <SimpleModal
      opened={opened}
      setOpened={setOpened}
      title={domainAction === 'move' ? t('Move domain?') : t('Copy domain?')}
    >
      <AccText nowrap>
        {tct(
          'Are you sure you want to [action] the domain [domain] to the group [group] on the [organization] account?',
          {
            action: domainAction === 'copy' ? t('copy') : t('transfer'),
            domain: <strong>{formValues?.domain_from?.label}</strong>,
            group: <strong>{formValues?.client_to?.label}</strong>,
            organization: <strong>{formValues?.account_to?.label}</strong>,
          },
        )}
      </AccText>
      <Gutter />
      <SimpleModal.Footer>
        <AccButton
          variant="tertiary"
          onClick={() => {
            setOpened(false);
          }}
        >
          {t('Cancel')}
        </AccButton>
        <AccButton
          variant="primary"
          onClick={async () => {
            setOpened(false);
            await onConfirm(formValues, domainAction === 'copy');
          }}
        >
          {t('OK')}
        </AccButton>
      </SimpleModal.Footer>
    </SimpleModal>
  );
};

const useCopyDomainEnabledForDomain = () => {
  const [fetchTotalRankCount] = useTotalRankCountLazyQuery();
  const [enabled, setEnabled] = useState<boolean | 'loading'>(false);
  const domainFrom = useFieldValue('domain_from');
  const id: string | undefined = domainFrom.value;
  const MAX_RANK_COUNT = 5_000_000; // somewhat arbitrary number, but shouldn't be too high

  useEffect(() => {
    (async () => {
      if (!id) {
        return false;
      }
      setEnabled('loading');

      const { data } = await fetchTotalRankCount({
        variables: {
          id,
        },
      });

      if (data!.domain!.totalRankCount! <= MAX_RANK_COUNT) {
        setEnabled(true);
      } else {
        setEnabled(false);
      }
    })();
  }, [domainFrom.value]);

  return enabled;
};

type CopyDomainButtonProps = {
  errors: any;
  setDomainAction: (action: DomainAction) => void;
  setConfirmActionOpened: (opened: boolean) => void;
};
const CopyDomainButton = ({
  errors,
  setDomainAction,
  setConfirmActionOpened,
}: CopyDomainButtonProps) => {
  const enabledForDomain = useCopyDomainEnabledForDomain();

  return (
    <Center>
      <AccButton
        ml="auto"
        variant="secondary"
        onClick={() => {
          setDomainAction('copy');
          setConfirmActionOpened(true);
        }}
        disabled={
          (errors && !!Object.entries(errors)?.length) ||
          !enabledForDomain ||
          enabledForDomain === 'loading'
        }
      >
        {t('Copy')}
      </AccButton>
      {!enabledForDomain && (
        <>
          <GutterSm direction="l" />
          <HelpTextPopover
            position="top"
            text={t(
              'This domain is too large and its history is too long to copy. Contact support if you need to proceed with copying the domain.',
            )}
          />
        </>
      )}
    </Center>
  );
};

const TransferMultiAccountDomain = () => {
  const [transferDomain] = useTransferMultiAccountDomainMutation();
  const [domainAction, setDomainAction] = useState<DomainAction>('move');
  const [confirmActionOpened, setConfirmActionOpened] = useState<boolean>(false);
  const [responseCode, setResponseCode] = useState<KeywordOveruseCode | null>(null);
  const [retrySubmitData, setRetrySubmitData] = useState<any | null>(null);
  const [retrySubmitIsCopy, setRetrySubmitIsCopy] = useState<boolean | null>(null);

  const handleTransferDomain = async (values: any, copy: boolean) => {
    if (!values?.client_to?.value || !values?.domain_from?.value) {
      toast.error(t('Something went wrong'));
    }
    const result = await transferDomain({
      variables: {
        input: {
          copy,
          toClientId: values.client_to.value,
          domainId: values.domain_from.value,
        },
      },
    });

    const errors = result.data?.transferMultiAccountDomain?.errors;
    if (errors && !isEmpty) {
      toast.error(errors[0]?.messages[0]);
      return;
    }

    const code = result.data?.transferMultiAccountDomain?.code;
    if (code) {
      setResponseCode(code);
      setRetrySubmitData(values);
      setRetrySubmitIsCopy(copy);
      return;
    }

    toast.success(copy ? t('Domain copy job started…') : t('Domain has been moved'));
  };

  return (
    <>
      <Breadcrumbs />
      <div className={styles.container}>
        <Form
          onSubmit={() => {
            setDomainAction('move');
            setConfirmActionOpened(true);
          }}
          formClassName={styles.form}
        >
          {({ errors, form: { getState } }) => {
            return (
              <>
                {responseCode === KeywordOveruseCode.UpgradePlanActiveLimit && (
                  <UpgradePlanModal
                    defaultOpened
                    onClose={() => {
                      setResponseCode(null);
                      setRetrySubmitData(null);
                      setRetrySubmitIsCopy(null);
                    }}
                  />
                )}
                {responseCode === KeywordOveruseCode.UpgradePlanTrackedLimit && (
                  <UpgradePlanModal
                    defaultOpened
                    onClose={() => {
                      setResponseCode(null);
                      setRetrySubmitData(null);
                      setRetrySubmitIsCopy(null);
                    }}
                    trackedLimit
                  />
                )}

                {responseCode === KeywordOveruseCode.RequestUpgradePlanActiveLimit && (
                  <RequestUpgradePlanModal
                    defaultOpened
                    onClose={() => {
                      setResponseCode(null);
                      setRetrySubmitData(null);
                      setRetrySubmitIsCopy(null);
                    }}
                  />
                )}
                {responseCode === KeywordOveruseCode.RequestUpgradePlanTrackedLimit && (
                  <RequestUpgradePlanModal
                    defaultOpened
                    onClose={() => {
                      setResponseCode(null);
                      setRetrySubmitData(null);
                      setRetrySubmitIsCopy(null);
                    }}
                    trackedLimit
                  />
                )}

                {responseCode === KeywordOveruseCode.EnableOveruseActiveLimit && (
                  <EnableOveruseModal
                    defaultOpened
                    area={EnableOveruseArea.TransferDomain}
                    onClose={() => {
                      setResponseCode(null);
                      setRetrySubmitData(null);
                      setRetrySubmitIsCopy(null);
                    }}
                    onEnableOveruseSuccess={() => {
                      if (retrySubmitData === null || retrySubmitIsCopy === null) {
                        toast.error(t('Something went wrong'));
                        return;
                      }
                      handleTransferDomain(retrySubmitData, retrySubmitIsCopy);
                    }}
                    // eslint-disable-next-line dot-notation
                    organizationId={getState().values['account_to']?.value}
                  />
                )}
                {responseCode === KeywordOveruseCode.EnableOveruseTrackedLimit && (
                  <EnableOveruseModal
                    defaultOpened
                    area={EnableOveruseArea.TransferDomain}
                    onClose={() => {
                      setResponseCode(null);
                      setRetrySubmitData(null);
                      setRetrySubmitIsCopy(null);
                    }}
                    onEnableOveruseSuccess={() => {
                      if (retrySubmitData === null || retrySubmitIsCopy === null) {
                        toast.error(t('Something went wrong'));
                        return;
                      }
                      handleTransferDomain(retrySubmitData, retrySubmitIsCopy);
                    }}
                    // eslint-disable-next-line dot-notation
                    organizationId={getState().values['account_to']?.value}
                    trackedLimit
                  />
                )}

                {responseCode === KeywordOveruseCode.RequestEnableOveruseActiveLimit && (
                  <RequestEnableOveruseModal
                    defaultOpened
                    onClose={() => {
                      setResponseCode(null);
                      setRetrySubmitData(null);
                      setRetrySubmitIsCopy(null);
                    }}
                  />
                )}
                {responseCode === KeywordOveruseCode.RequestEnableOveruseTrackedLimit && (
                  <RequestEnableOveruseModal
                    defaultOpened
                    onClose={() => {
                      setResponseCode(null);
                      setRetrySubmitData(null);
                      setRetrySubmitIsCopy(null);
                    }}
                    trackedLimit
                  />
                )}

                <DomainActionConfirmationModal
                  opened={confirmActionOpened}
                  setOpened={setConfirmActionOpened}
                  domainAction={domainAction}
                  onConfirm={handleTransferDomain}
                />

                <TransferMultiAccountDomainForm />
                <Flex justify="end" gap="sm">
                  <CopyDomainButton
                    errors={errors}
                    setDomainAction={setDomainAction}
                    setConfirmActionOpened={setConfirmActionOpened}
                  />
                  <AccButton
                    variant="primary"
                    type="submit"
                    disabled={errors && !!Object.entries(errors)?.length}
                  >
                    {t('Transfer')}
                  </AccButton>
                </Flex>
              </>
            );
          }}
        </Form>
      </div>
    </>
  );
};

export default TransferMultiAccountDomain;
