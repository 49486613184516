import { Box, useMantineTheme } from '@mantine/core';

type Direction = 't' | 'b' | 'l' | 'r' | 'x' | 'y';

type UseMarginPropsProps = {
  direction: Direction;
  sm: boolean;
};

const useMarginProps = ({ direction, sm }: UseMarginPropsProps) => {
  const {
    other: { gutter, gutterSm },
  } = useMantineTheme();

  const margin = sm ? gutterSm : gutter;

  return { [`m${direction}`]: margin };
};

type GutterProps = Partial<UseMarginPropsProps>;

export const Gutter = ({ direction = 'b', sm = false }: GutterProps) => {
  const marginProps = useMarginProps({ direction, sm });
  return <Box {...marginProps} />;
};

type GutterSmProps = Pick<GutterProps, 'direction'>;

export const GutterSm = ({ direction }: GutterSmProps) => {
  return <Gutter direction={direction} sm />;
};
