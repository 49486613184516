import { useMantineTheme } from '@mantine/core';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccButton from 'Components/AccButton/AccButton';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import withFiltersEditor from 'Components/HOC/withFiltersEditor';
import type { FiltersEditorProps } from 'Components/HOC/withFiltersEditor';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n/index';
import styleVariables from 'css/base/variables.module.scss';
import filterExit from 'icons/filter-exit.svg';

interface ResetFilterProps {
  compact?: boolean;
}
const ResetFilter = (props: ResetFilterProps & FiltersEditorProps) => {
  const { colors, primaryShade } = useMantineTheme();
  const primarySnorlax = colors.snorlax[primaryShade as number];

  const { filtersEditor, compact } = props;
  const handleReset = () => {
    filtersEditor.resetFilters();
  };

  return !compact ? (
    <AccButton
      variant="tertiary"
      trackingKey={TrackingKey.ClearFilters}
      onClick={handleReset}
      leftSection={<AccFastIcon src={filterExit} size={20} color={primarySnorlax} />}
    >
      {!compact && t('Clear filters')}
    </AccButton>
  ) : (
    <AccTooltip tooltip={t('Clear filters')}>
      <AccActionIcon variant="subtle" onClick={handleReset} c={styleVariables.snorlax4}>
        <AccFastIcon src={filterExit} size={20} color={primarySnorlax} />
      </AccActionIcon>
    </AccTooltip>
  );
};

export default withFiltersEditor(ResetFilter) as React.ComponentType<ResetFilterProps>;
