import { ColumnSetting, QueryOrder } from 'Components/DataTable';
import { t } from 'Utilities/i18n';

export const HomeTreeViewDefaultOrder: {
  order: QueryOrder;
  orderBy: string;
} = {
  order: QueryOrder.DESC,
  orderBy: 'group',
};

export enum HomeTreeViewColumnID {
  CHECKBOX = 'checkbox',
  NAME = 'name',
  COUNTRY = 'country',
  DEVICE = 'device',
  KEYWORDS = 'keywords',
  DISCOVERED_KEYWORDS = 'keydis_count',
  AI_SHARE_OF_VOICE = 'ai_share_of_voice',
  SHARE_OF_VOICE = 'share_of_voice',
  AVG_RANK_VALUE = 'avg_rank',
  AVG_BASE_RANK = 'avg_base_rank',
  SEARCH_VOLUME = 'search_volume',
  AI_TRAFFIC_VALUE = 'ai_traffic_value',
  TRAFFIC_VALUE = 'traffic_value',
  DYNAMIC_CTR = 'dynamic_ctr',
  DYNAMIC_CTR_MAX = 'dynamic_ctr_max',
  ABOVE_THE_FOLD = 'above_the_fold',
}

export const homeTreeViewTable: ColumnSetting[] = [
  {
    id: HomeTreeViewColumnID.CHECKBOX,
    queryWithClauses: [],
    tableSetting: { required: true },
  },
  {
    id: HomeTreeViewColumnID.NAME,
    queryWithClauses: [],
    tableSetting: { required: true, getLabel: () => t('Group') },
  },
  {
    id: HomeTreeViewColumnID.COUNTRY,
    queryWithClauses: ['countryCode'],
    tableSetting: { required: true, getLabel: () => t('Country') },
  },
  {
    id: HomeTreeViewColumnID.DEVICE,
    queryWithClauses: ['keywords'],
    tableSetting: { required: false, defaultHide: true, getLabel: () => t('Device') },
  },
  {
    id: HomeTreeViewColumnID.KEYWORDS,
    queryWithClauses: ['keywords'],
    tableSetting: { required: false, getLabel: () => t('Keywords') },
  },
  {
    id: HomeTreeViewColumnID.DISCOVERED_KEYWORDS,
    queryWithClauses: ['keydisCount'],
    tableSetting: { defaultHide: false, required: false, getLabel: () => t('Discovered keywords') },
  },
  {
    id: HomeTreeViewColumnID.AI_SHARE_OF_VOICE,
    queryWithClauses: ['aiShareOfVoice'],
    tableSetting: {
      defaultHide: false,
      required: false,
      getLabel: () => t('AI SoV'),
    },
  },
  {
    id: HomeTreeViewColumnID.SHARE_OF_VOICE,
    queryWithClauses: ['shareOfVoice'],
    tableSetting: { defaultHide: true, required: false, getLabel: () => t('SoV') },
  },
  {
    id: HomeTreeViewColumnID.AVG_RANK_VALUE,
    queryWithClauses: ['avgRank'],
    tableSetting: {
      getLabel: () => t('Average Rank'),
    },
  },
  {
    id: HomeTreeViewColumnID.AVG_BASE_RANK,
    queryWithClauses: ['avgBaseRank'],
    tableSetting: {
      getLabel: () => t('Avg. Base Rank'),
      defaultHide: true,
      required: false,
    },
  },
  {
    id: HomeTreeViewColumnID.SEARCH_VOLUME,
    queryWithClauses: ['searchVolume'],
    tableSetting: { required: false, getLabel: () => t('Searches') },
  },
  {
    id: HomeTreeViewColumnID.AI_TRAFFIC_VALUE,
    queryWithClauses: ['aiTrafficValue'],
    tableSetting: { required: false, getLabel: () => t('AI Traffic value') },
  },
  {
    id: HomeTreeViewColumnID.TRAFFIC_VALUE,
    queryWithClauses: ['trafficValue'],
    tableSetting: { required: false, defaultHide: true, getLabel: () => t('Traffic value') },
  },
  {
    id: HomeTreeViewColumnID.DYNAMIC_CTR,
    queryWithClauses: ['ctr'],
    tableSetting: { defaultHide: false, required: false, getLabel: () => t('CTR') },
  },
  {
    id: HomeTreeViewColumnID.DYNAMIC_CTR_MAX,
    queryWithClauses: ['ctrMax'],
    tableSetting: { defaultHide: true, required: false, getLabel: () => t('Max CTR') },
  },
  {
    id: HomeTreeViewColumnID.ABOVE_THE_FOLD,
    queryWithClauses: ['aboveTheFold'],
    tableSetting: { defaultHide: true, required: false, getLabel: () => t('Above the fold') },
  },
];
