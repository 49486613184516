import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { t } from 'Utilities/i18n';
import CopyToClipboardForm from './CopyToClipboardForm';
import { useModal } from 'Hooks/base/useModal';

type Props = {
  value: string | Promise<string>;
  confirmButtonLabel?: string;
  removeDuplicates?: boolean;
};

const CopyToClipboard = ({ value, confirmButtonLabel, removeDuplicates = true }: Props) => {
  const { hideModal } = useModal();
  return (
    <ModalBorder className="copy-modal" title={t('Copy to Clipboard')} onClose={hideModal}>
      <CopyToClipboardForm
        hideModal={hideModal}
        value={value}
        confirmButtonLabel={confirmButtonLabel}
        removeDuplicates={removeDuplicates}
      />
    </ModalBorder>
  );
};

export default CopyToClipboard;
