msgid ""
msgstr ""
"Project-Id-Version: Danish (AccuRanker)\n"
"Report-Msgid-Bugs-To: \n"
"POT-Creation-Date: \n"
"PO-Revision-Date: 2025-01-15 07:27+0000\n"
"Last-Translator: Emil Martin Vork Heunecke <emvh@accuranker.com>\n"
"Language-Team: Danish <http://weblate.servers.ac/projects/accuranker/react/"
"da/>\n"
"Language: da\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=n != 1;\n"
"X-Generator: Weblate 4.12.1\n"

#: src/AppDeclaration.tsx:96
msgid "Error Page"
msgstr "Fejlside"

#: src/AppDeclaration.tsx:102
msgid "Log in"
msgstr "Log ind"

#: src/AppDeclaration.tsx:112
#: src/Pages/Auth/PasswordSetupPage/components/PasswordChangeForm.tsx:61
#: src/Pages/Auth/ResetPasswordPage/components/ResetPasswordForm.tsx:24
#: src/Pages/Auth/ResetPasswordPage/components/ResetPasswordForm.tsx:51
msgid "Reset Password"
msgstr "Nulstil adgangskode"

#: src/AppDeclaration.tsx:125
#: src/Pages/Auth/PasswordSetupPage/components/PasswordChangeForm.tsx:22
#: src/Pages/Profile/Profile.tsx:25
msgid "Change Password"
msgstr "Skift kodeord"

#: src/AppDeclaration.tsx:135 src/AppDeclaration.tsx:324
#: src/AppDeclaration.tsx:391 src/AppDeclaration.tsx:404
#: src/AppDeclaration.tsx:417 src/AppDeclaration.tsx:430
#: src/AppDeclaration.tsx:443 src/AppDeclaration.tsx:456
#: src/AppDeclaration.tsx:477 src/AppDeclaration.tsx:492
#: src/AppDeclaration.tsx:505 src/AppDeclaration.tsx:517
#: src/AppDeclaration.tsx:531
#: src/Components/Modal/Wizard/ConnectToGA/Group/index.tsx:61
#: src/Components/Modal/Wizard/ConnectToGA/Property/index.tsx:102
#: src/Components/Modal/Wizard/ConnectToGA/PropertyBulkImport/index.tsx:179
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:98
msgid "Account"
msgstr "Konto"

#: src/AppDeclaration.tsx:135 src/AppDeclaration.tsx:324
#: src/AppDeclaration.tsx:443 src/AppDeclaration.tsx:456
#: src/AppDeclaration.tsx:477
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:152
msgid "Subscription"
msgstr "Abonnement"

#: src/AppDeclaration.tsx:135
msgid "Checkout"
msgstr "Checkout"

#: src/AppDeclaration.tsx:149
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:283
msgid "Welcome"
msgstr "Velkommen"

#: src/AppDeclaration.tsx:161 src/Pages/Affiliate/index.tsx:19
#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:225
msgid "Affiliate"
msgstr "Partner"

#: src/AppDeclaration.tsx:171 src/AppDeclaration.tsx:181
#: src/AppDeclaration.tsx:191 src/AppDeclaration.tsx:201
#: src/AppDeclaration.tsx:211 src/AppDeclaration.tsx:221
#: src/AppDeclaration.tsx:231 src/AppDeclaration.tsx:241
#: src/AppDeclaration.tsx:252 src/AppDeclaration.tsx:262
#: src/AppDeclaration.tsx:273
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:237
#: src/Pages/Sales/Organization/index.tsx:823
msgid "Sales"
msgstr "Salg"

#: src/AppDeclaration.tsx:171 src/Pages/Sales/Invoice/Customers/index.tsx:63
#: src/Pages/Sales/Organization/index.tsx:371
#: src/Pages/Sales/Organization/index.tsx:825
#: src/Pages/Sales/Search/index.tsx:103 src/Pages/Sales/Search/index.tsx:152
#: src/Pages/Sales/Search/index.tsx:173 src/Pages/Sales/Search/index.tsx:187
#: src/Pages/Sales/Search/index.tsx:201
#: src/Pages/Sales/Search/sales-search-input.tsx:52
#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:200
msgid "Organization"
msgstr "Organisation"

#: src/AppDeclaration.tsx:181 src/AppDeclaration.tsx:211
#: src/Components/Modal/Content/DomainsComparison/components/CollapseSearch.tsx:40
#: src/Components/QuickNavigation/QuickNavigation.tsx:217
#: src/Components/SearchInput/index.tsx:39
#: src/Pages/KeywordDiscovery/components/DialogOverlay/index.tsx:166
#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/KeywordSearch.tsx:212
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:243
#: src/Pages/PublicReport/publicKeywordsReportTable/SearchField.tsx:81
#: src/Pages/Register/components/ContactInfo.tsx:114
msgid "Search"
msgstr "Søg"

#: src/AppDeclaration.tsx:181 src/AppDeclaration.tsx:737
#: src/Components/Modal/Content/CreateTagModal/CreateOrEditStaticTagModal/index.tsx:153
#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:296
#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:273
#: src/Pages/Sales/Trials/index.tsx:77
msgid "Create"
msgstr "Opret"

#: src/AppDeclaration.tsx:191
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:248
#: src/Pages/Sales/CreatePlan/index.tsx:126
#: src/Pages/Sales/CreatePlan/index.tsx:138
#: src/Pages/Sales/Organization/index.tsx:899
msgid "Plans"
msgstr "Abonnementer"

#: src/AppDeclaration.tsx:201
#: src/Pages/Affiliate/AffiliateDashboard/index.tsx:49
#: src/Pages/Affiliate/index.tsx:34
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:252
msgid "Customers"
msgstr "Kunder"

#: src/AppDeclaration.tsx:221
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:276
msgid "Importer"
msgstr "Importer"

#: src/AppDeclaration.tsx:231
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:256
msgid "Mails"
msgstr "Mails"

#: src/AppDeclaration.tsx:241
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:260
msgid "Trials"
msgstr "Trials"

#: src/AppDeclaration.tsx:252
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:264
msgid "Tools"
msgstr "Værktøjer"

#: src/AppDeclaration.tsx:262
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:268
msgid "Invoice Customers"
msgstr "Fakturakunde"

#: src/AppDeclaration.tsx:273
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:272
msgid "Reconcile Payments"
msgstr "Afstem betalinger"

#: src/AppDeclaration.tsx:282
#: src/Components/Modal/Wizard/ConnectToGA/Profile/index.tsx:41
#: src/Pages/Layout/DashboardTemplate/SideNavbar/components/NavbarHeader/useMenuItems.tsx:39
#: src/Pages/Profile/Profile.tsx:12
msgid "Profile"
msgstr "Profil"

#: src/AppDeclaration.tsx:296
#: src/Components/Chart/Notes/support/helpers.tsx:209
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/useKeywordNavItems.tsx:52
#: src/Pages/Profile/components/EditProfile/index.tsx:75
#: src/Pages/Sales/Organization/index.tsx:861
msgid "Notes"
msgstr "Notater"

#: src/AppDeclaration.tsx:308 src/AppDeclaration.tsx:531
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/useKeywordNavItems.tsx:53
#: src/Pages/PublicReport/publicReportSettings/index.tsx:402
msgid "Settings"
msgstr "Indstillinger"

#: src/AppDeclaration.tsx:324 src/Components/Modal/Content/Invoices/index.tsx:9
#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:173
#: src/Pages/Billing/Overview/index.tsx:60
#: src/Pages/Sales/Organization/index.tsx:908
msgid "Invoices"
msgstr "Fakturaer"

#: src/AppDeclaration.tsx:335 src/AppDeclaration.tsx:348
msgid "Sub-accounts"
msgstr "Under-kontoer"

#: src/AppDeclaration.tsx:335
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:133
msgid "Transfer & Copy Domains"
msgstr "Kopier & flyt domæner"

#: src/AppDeclaration.tsx:348
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:128
msgid "Accounts Requests"
msgstr "Adgangsforespørgelser"

#: src/AppDeclaration.tsx:361
#: src/Components/QuickNavigation/support/helpers.tsx:212
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:123
#: src/Pages/Sales/Organization/index.tsx:890
msgid "Sub-Accounts"
msgstr "Under-kontoer"

#: src/AppDeclaration.tsx:378
msgid "Auth Callback"
msgstr "Auth callback"

#: src/AppDeclaration.tsx:391 src/Components/AccountUsage/index.tsx:56
#: src/Components/Modal/Content/AddEditWorkspaceForm/index.tsx:95
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/components/OveruseInfoPanelBlockList.tsx:46
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:109
#: src/Pages/Sales/Organization/index.tsx:881
#: src/Pages/SelectPlan/plan-features.tsx:111 src/Pages/Users/index.tsx:45
#: src/Pages/Users/index.tsx:61
#: src/Pages/Workspaces/WorkspacesTable/getColumns.tsx:61
msgid "Users"
msgstr "Brugere"

#: src/AppDeclaration.tsx:404
#: src/Components/Modal/Content/AddEditUserForm/index.tsx:116
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:118
#: src/Pages/Workspaces/index.tsx:50 src/Pages/Workspaces/index.tsx:70
msgid "Workspaces"
msgstr "Workspaces"

#: src/AppDeclaration.tsx:417
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:138
msgid "External Access"
msgstr "Ekstern adgang"

#: src/AppDeclaration.tsx:430
msgid "Keyword usage"
msgstr "Søgeordsforbrug"

#: src/AppDeclaration.tsx:443
#: src/Components/AdvancedPlanUpsellChart/index.tsx:48
#: src/Components/Filters/FilterEditorForm/index.tsx:43
#: src/Components/Filters/FilterItem/index.tsx:55
#: src/Components/Filters/SavedFilterGroups/SavedFilterGroup/index.tsx:119
#: src/Components/Filters/UpdateFilterGroup/index.tsx:64
#: src/Components/Modal/Content/AddEditUserForm/index.tsx:142
#: src/Components/Modal/Content/AddEditWorkspaceForm/index.tsx:112
#: src/Components/Modal/Content/AddKeywords/Footer/index.tsx:46
#: src/Components/Modal/Content/AddNote/AddNoteForm/index.tsx:76
#: src/Components/Modal/Content/AddSearchEngine/index.tsx:244
#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:267
#: src/Components/Modal/Content/AddTags/AddTagsForm/index.tsx:40
#: src/Components/Modal/Content/AddWalletItem/AddWalletItemForm/index.tsx:79
#: src/Components/Modal/Content/BuildCompetitor/BuildCompetitorForm/index.tsx:98
#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:296
#: src/Components/Modal/Content/CancelPlan/CancelPlanCallForm/index.tsx:37
#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:357
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:216
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:250
#: src/Components/Modal/Content/Confirmation/index.tsx:136
#: src/Components/Modal/Content/CopyToClipboard/CopyToClipboardForm/index.tsx:122
#: src/Components/Modal/Content/CreateTagModal/CreateOrEditStaticTagModal/index.tsx:158
#: src/Components/Modal/Content/EditAccount/index.tsx:91
#: src/Components/Modal/Content/EditAdobeAccount/index.tsx:89
#: src/Components/Modal/Content/EditBigQueryAccount/index.tsx:89
#: src/Components/Modal/Content/EditFilterGroup/EditFilterGroupForm/index.tsx:110
#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:203
#: src/Components/Modal/Content/ExportAdTracking/ExportAdTrackingForm/index.tsx:113
#: src/Components/Modal/Content/GroupModalView/index.tsx:50
#: src/Components/Modal/Content/LandingPage/LandingPageForm/new.tsx:171
#: src/Components/Modal/Content/MoveDomain/MoveDomainForm/index.tsx:220
#: src/Components/Modal/Content/MoveKeywords/MoveKeywordsForm/index.tsx:118
#: src/Components/Modal/Content/RemoveTags/index.tsx:131
#: src/Components/Modal/Content/RequestAccess/RequestAccessForm/index.tsx:143
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/NextTableSettingsForm.tsx:110
#: src/Components/Modal/Wizard/Common/ConnectOAuthAccount/index.tsx:149
#: src/Components/Modal/Wizard/Common/GoogleAccounts/SelectGoogleAccount/index.tsx:137
#: src/Components/Modal/Wizard/Common/GoogleAccounts/SelectGoogleAccount/index.tsx:164
#: src/Components/Modal/Wizard/ConnectToAdobe/AddAdobeMarketingAccount/index.tsx:79
#: src/Components/Modal/Wizard/ConnectToAdobe/ConnectAdobeAccount/index.tsx:127
#: src/Components/Modal/Wizard/ConnectToAdobe/SelectAdobeAccount/index.tsx:107
#: src/Components/Modal/Wizard/ConnectToAdobe/SelectAdobeAccount/index.tsx:126
#: src/Components/Modal/Wizard/ConnectToBigQuery/BackfillData/index.tsx:116
#: src/Components/Modal/Wizard/ConnectToBigQuery/ConnectBigQuery/index.tsx:122
#: src/Components/Modal/Wizard/ConnectToBigQuery/FailedAddingBigQueryAccount/index.tsx:24
#: src/Components/Modal/Wizard/ConnectToBigQuery/SelectBigQueryAccount/index.tsx:151
#: src/Components/Modal/Wizard/ConnectToBigQuery/SelectBigQueryAccount/index.tsx:180
#: src/Components/Modal/Wizard/ConnectToDrive/index.tsx:84
#: src/Components/Modal/Wizard/ConnectToGA/AddGAAccount/index.tsx:138
#: src/Components/Modal/Wizard/ConnectToOAuth/SelectOAuthAccount/index.tsx:80
#: src/Components/Modal/Wizard/ConnectToOAuth/SelectOAuthAccount/index.tsx:97
#: src/Components/OveruseConfirmation/index.tsx:86
#: src/Components/OveruseConfirmation/index.tsx:273
#: src/Components/PeriodFilter/CompareToContent/index.tsx:171
#: src/Components/Table/TableRow/ReportActionCell/index.tsx:30
#: src/Hooks/useKeyword.tsx:317
#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:41
#: src/Pages/Accounts/TransferMultiAccountDomain/index.tsx:73
#: src/Pages/Billing/Checkout/index.tsx:83
#: src/Pages/ConnectedAccounts/support/hooks.tsx:209
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useFolderActions.ts:35
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useTagActions.ts:92
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useTagActions.ts:124
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/DynamicTagsBuilder.tsx:80
#: src/Pages/ExternalAccess/OwnersTable/index.tsx:54
#: src/Pages/Integrations/hooks/useIntegrations.tsx:442
#: src/Pages/Keywords/Competitors/support/actions.ts:73
#: src/Pages/Keywords/Groupings/EditMode/components/DeleteFolderModal.tsx:35
#: src/Pages/Keywords/Groupings/EditMode/components/EditModeHeader/components/MoveGroupModal.tsx:56
#: src/Pages/Keywords/Groupings/EditMode/components/GroupEditActions.tsx:36
#: src/Pages/Keywords/Groupings/EditMode/components/NodeItem/EditNodeInput.tsx:106
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/usePathCellActions.ts:94
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/usePathCellActions.ts:125
#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingsBulkActions.tsx:83
#: src/Pages/Keywords/Groupings/ViewMode/ViewMode.tsx:89
#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/support/hooks.ts:149
#: src/Pages/NotesList/NotesTable/support/useNotesTableInfo.tsx:68
#: src/Pages/Profile/components/GoogleDriveAccount/index.tsx:47
#: src/Pages/Profile/components/IntegrationsApiToken/index.tsx:58
#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:64
#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:293
#: src/Pages/Sales/Organization/Components/NotesPanel/index.tsx:55
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:393
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:455
#: src/Pages/ScheduledReportBuilder/PublicReportOptions/index.tsx:126
#: src/Pages/Users/UsersTable/index.tsx:100
#: src/Pages/Workspaces/WorkspacesTable/index.tsx:82
msgid "Cancel"
msgstr "Annuller"

#: src/AppDeclaration.tsx:477
msgid "Select Plan"
msgstr "Vælg abonnement"

#: src/AppDeclaration.tsx:492
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:162
msgid "Connected Integrations"
msgstr "Forbundne integrationer"

#: src/AppDeclaration.tsx:505
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:171
msgid "Wallet"
msgstr "Pung"

#: src/AppDeclaration.tsx:517
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:180
msgid "AccuAPI"
msgstr "AccuAPI"

#: src/AppDeclaration.tsx:545
#: src/Components/Modal/Content/AddKeywords/ImportFromGSCInput/index.tsx:36
msgid "Import from Google Search Console"
msgstr "Importer fra Google Search Console"

#: src/AppDeclaration.tsx:561
#: src/Pages/Billing/Subscription/Subscription.tsx:31
#: src/Pages/Profile/components/EditProfile/index.tsx:47
msgid "Overview"
msgstr "Overblik"

#: src/AppDeclaration.tsx:571 src/AppDeclaration.tsx:581
#: src/Pages/KeywordDiscovery/components/DialogOverlay/index.tsx:137
#: src/Pages/SiteMapping/components/CompetitorTable.tsx:195
msgid "Keyword Discovery"
msgstr "Keyword Discovery"

#: src/AppDeclaration.tsx:589 src/Components/AccountUsage/index.tsx:32
#: src/Components/Chart/BubbleChart/support/hooks.ts:93
#: src/Components/Chart/Notes/components/ChartNotePopoverContent.tsx:79
#: src/Components/Filters/Editors/Keywords/data.ts:11
#: src/Components/Filters/Editors/KeywordsList/data.ts:22
#: src/Components/Modal/Content/AddKeywords/KeywordsInput/index.tsx:24
#: src/Components/Modal/Content/AddNote/AddNoteForm/index.tsx:62
#: src/Components/Modal/Content/CreateTagModal/CreateOrEditStaticTagModal/index.tsx:132
#: src/Components/Modal/Content/EditNote/EditNoteForm/index.tsx:146
#: src/Components/Modal/Content/SalesOrganizationDomains/index.tsx:34
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:180
#: src/Pages/Domains/support/types.ts:81
#: src/Pages/Domains/TreeView/support/constants.ts:55
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:183
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:185
#: src/Pages/importExamples/ImportExamples.tsx:41
#: src/Pages/importExamples/ImportExamples.tsx:62
#: src/Pages/Integrations/hooks/useIntegrations.tsx:261
#: src/Pages/KeywordDiscovery/components/KpiBar/index.tsx:30
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/components/OveruseInfoPanelBlockList.tsx:34
#: src/Pages/Keywords/Groupings/support/constants.ts:70
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:207
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:209
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/hooks.tsx:115
#: src/Pages/Keywords/Overview/components/KpiBar/Boxes/KeywordsCount.tsx:33
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:60
#: src/Pages/Keywords/Overview/components/WinnersLosers/WinnerLosersBar.tsx:36
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/useKeywordNavItems.tsx:44
#: src/Pages/NotesList/NotesTable/support/useNotesTableInfo.tsx:156
#: src/Pages/PausedDomains/index.tsx:246
#: src/Pages/Profile/components/EditProfile/index.tsx:51
#: src/Pages/PublicReport/pageContent/index.tsx:139
#: src/Pages/Sales/CreatePlan/SalesPlanChoicesTable/index.tsx:74
#: src/Pages/Sales/Organization/index.tsx:296
#: src/Pages/Sales/Organization/index.tsx:436
#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:462
#: src/Pages/SelectPlan/index.tsx:399
msgid "Keywords"
msgstr "Søgeord"

#: src/AppDeclaration.tsx:599
#: src/Pages/AIOverview/components/AIOverviewTable/support/AIOverviewCell.tsx:211
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:417
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:72
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/useKeywordNavItems.tsx:45
#: src/Pages/Profile/components/EditProfile/index.tsx:71
msgid "AI Overview"
msgstr "AI Overblik"

#: src/AppDeclaration.tsx:609
#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:280
#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:283
#: src/Pages/Integrations/hooks/useIntegrations.tsx:270
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:86
#: src/Pages/Keywords/Overview/components/CompetitorsBubbleChart/index.tsx:103
#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/ShareOfVoiceContainer.tsx:58
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/useKeywordNavItems.tsx:49
#: src/Pages/Profile/components/EditProfile/index.tsx:55
#: src/Pages/Sales/CreatePlan/SalesPlanChoicesTable/index.tsx:86
msgid "Competitors"
msgstr "Konkurrenter"

#: src/AppDeclaration.tsx:619
msgid "SERP"
msgstr "SERP"

#: src/AppDeclaration.tsx:629
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/useKeywordNavItems.tsx:47
msgid "Tag Cloud"
msgstr "Tag Cloud"

#: src/AppDeclaration.tsx:640
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/useKeywordNavItems.tsx:50
msgid "Landing Pages"
msgstr "Landingssider"

#: src/AppDeclaration.tsx:650 src/Pages/SelectPlan/plan-features.tsx:47
msgid "Organic Site Explorer"
msgstr "Organisk Site Explorer"

#: src/AppDeclaration.tsx:660
#: src/Components/Filters/Editors/Clients/data.tsx:17
#: src/Components/QuickNavigation/support/helpers.tsx:211
#: src/Pages/Domains/CardView/components/CardViewGroupsAndDomains.tsx:112
#: src/Pages/Groups/index.tsx:42
#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/NextGroupsAndDomains.tsx:57
#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/NextGroupsAndDomains.tsx:59
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:54
msgid "Groups"
msgstr "Grupper"

#: src/AppDeclaration.tsx:673 src/AppDeclaration.tsx:686
#: src/Components/IntegrationsMenu/index.tsx:38
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:188
#: src/Pages/Profile/components/EditProfile/index.tsx:232
msgid "Integrations"
msgstr "Integrationer"

#: src/AppDeclaration.tsx:673
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:203
msgid "API Filters"
msgstr "API filtre"

#: src/AppDeclaration.tsx:699 src/AppDeclaration.tsx:711
#: src/AppDeclaration.tsx:724 src/AppDeclaration.tsx:737
#: src/AppDeclaration.tsx:750 src/AppDeclaration.tsx:759
#: src/AppDeclaration.tsx:768
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:73
#: src/Pages/ReportTemplateBuilder/index.tsx:16
#: src/Pages/ScheduledReportBuilder/index.tsx:19
msgid "Reporting"
msgstr "Rapportering"

#: src/AppDeclaration.tsx:699 src/AppDeclaration.tsx:768
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:83
msgid "Scheduled Reports"
msgstr "Planlagte rapporter"

#: src/AppDeclaration.tsx:699 src/AppDeclaration.tsx:711
#: src/Components/Forms/SearchEngine/index.tsx:274
#: src/Components/Modal/Content/EditNote/EditNoteForm/index.tsx:124
#: src/Components/Modal/Content/Sales/OrganizationNote/OrgNoteForm/OrgNoteForm.tsx:69
#: src/Components/Table/TableRow/ReportActionCell/index.tsx:60
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:515
#: src/Pages/Keywords/Table/Actions/MoreActions/index.tsx:137
#: src/Pages/Layout/ActionsMenu/Actions/index.tsx:49
#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:333
#: src/Pages/Users/UsersTable/index.tsx:144
#: src/Pages/Workspaces/WorkspacesTable/index.tsx:119
msgid "Edit"
msgstr "Rediger"

#: src/AppDeclaration.tsx:711 src/AppDeclaration.tsx:724
#: src/AppDeclaration.tsx:737 src/AppDeclaration.tsx:750
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:91
msgid "Report Templates"
msgstr "Rapportskabeloner"

#: src/AppDeclaration.tsx:724
#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:345
msgid "Clone"
msgstr "Klon"

#: src/AppDeclaration.tsx:759
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:87
msgid "Generated Reports"
msgstr "Genererede rapporter"

#: src/AppDeclaration.tsx:780
msgid "Report for domain"
msgstr "Rapport for domæne"

#: src/AppDeclaration.tsx:787
msgid "Activate your trial account"
msgstr "Aktivér din prøvekonto"

#: src/AppDeclaration.tsx:803
msgid "Account Blocked"
msgstr "Konto blokeret"

#: src/AppDeclaration.tsx:818
msgid "Trial has expired"
msgstr "Prøvekontoen er udløbet"

#: src/AppDeclaration.tsx:830
msgid "Plan has expired"
msgstr "Abonnementet er udløbet"

#: src/AppDeclaration.tsx:842 src/Pages/ErrorPages/FailedPayment/index.tsx:338
msgid "Payment failed"
msgstr "Betaling fejlet"

#: src/AppDeclaration.tsx:855 src/Components/AccountUsage/index.tsx:43
#: src/Components/Filters/Editors/Domains/data.tsx:18
#: src/Components/QuickNavigation/support/helpers.tsx:210
#: src/Pages/Domains/CardView/components/CardViewGroupsAndDomains.tsx:135
#: src/Pages/Domains/index.tsx:79 src/Pages/Domains/PauseSwitch.tsx:23
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/components/OveruseInfoPanelBlockList.tsx:40
#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/NextGroupsAndDomains.tsx:74
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:49
#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:426
msgid "Domains"
msgstr "Domæner"

#: src/AppDeclaration.tsx:865 src/AppDeclaration.tsx:877
#: src/AppDeclaration.tsx:890 src/Pages/Import/Validation/index.tsx:234
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:215
msgid "Import"
msgstr "Importer"

#: src/AppDeclaration.tsx:865
msgid "Validation"
msgstr "Validering"

#: src/AppDeclaration.tsx:877
msgid "Examples"
msgstr "Eksempler"

#: src/AppDeclaration.tsx:903 src/Pages/Affiliate/index.tsx:33
#: src/Pages/Integrations/hooks/useIntegrations.tsx:252
#: src/Pages/KeywordDiscovery/index.tsx:350
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/useKeywordNavItems.tsx:43
msgid "Dashboard"
msgstr "Overblik"

#: src/AppDeclaration.tsx:919 src/Pages/ErrorPages/404/index.tsx:23
msgid "Error"
msgstr "Fejl"

#: src/AppDeclaration.tsx:919
msgid "Something Went Wrong"
msgstr "Noget gik galt"

#: src/AppDeclaration.tsx:924 src/Pages/ErrorPages/AccessDenied/index.tsx:15
msgid "Access Denied"
msgstr "Adgang nægtet"

#: src/AppDeclaration.tsx:936
msgid "Page not found"
msgstr "Siden blev ikke fundet"

#: src/Components/AccBadge/index.tsx:66
#: src/Components/Modal/Content/Confirmation/index.tsx:137
#: src/Components/Modal/Wizard/ConnectToGA/Property/index.tsx:72
#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:137
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useFolderActions.ts:34
#: src/Pages/DomainSettings/components/Menu/DisplaySettings/AverageRankSettings/index.tsx:97
#: src/Pages/DomainSettings/components/Menu/DisplaySettings/SearchSettings/index.tsx:61
#: src/Pages/Keywords/Groupings/ViewMode/ViewMode.tsx:88
msgid "Yes"
msgstr "Ja"

#: src/Components/AccBadge/index.tsx:71
#: src/Components/Modal/Wizard/ConnectToGA/Property/index.tsx:73
#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:137
#: src/Pages/DomainSettings/components/Menu/DisplaySettings/AverageRankSettings/index.tsx:97
#: src/Pages/DomainSettings/components/Menu/DisplaySettings/SearchSettings/index.tsx:61
msgid "No"
msgstr "Nej"

#: src/Components/AccButton/AccFeatureCallout.tsx:105
msgid "Click here to learn more"
msgstr "Klik for at lære mere"

#: src/Components/AccButton/AccFeatureCallout.tsx:116
msgid "Got it!"
msgstr "Forstået!"

#: src/Components/AccContextToolbar/AccContextToolbar.tsx:12
#: src/Pages/Domains/TreeView/Components/TreeViewToolbar.tsx:14
#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingsToolbar.tsx:20
msgid "item selected"
msgstr "element valgt"

#: src/Components/AccContextToolbar/AccContextToolbar.tsx:13
#: src/Pages/Domains/TreeView/Components/TreeViewToolbar.tsx:15
#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingsToolbar.tsx:21
msgid "items selected"
msgstr "elementer valgte"

#: src/Components/AccListSelect/index.tsx:132
msgid ""
"Search... (Tip: you can enter ',' seperated values or multiple lines, e.g. "
"copy/paste from Excel or Sheets)"
msgstr ""
"Søg... (Tip: du kan skrive \",\" separerede værdier eller flere linjer, fx "
"ved at kopiere fra Excel eller Sheets)"

#: src/Components/AccListSelect/index.tsx:141
msgid "selected"
msgstr "valgte"

#: src/Components/AccListSelect/index.tsx:157
msgid "Show selection"
msgstr "Vis valgte"

#: src/Components/AccListSelect/index.tsx:167
#: src/Pages/Domains/CardView/components/CardViewGroupsAndDomains.tsx:35
#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingViewModeActions.tsx:37
msgid "Clear selection"
msgstr "Ryd valgte"

#: src/Components/AccountUsage/index.tsx:33
#: src/Components/AccountUsage/index.tsx:45
#: src/Components/AccountUsage/index.tsx:57
#: src/Components/AccountUsage/index.tsx:69
msgid "%s of %s"
msgstr "%s af %s"

#: src/Components/AccountUsage/index.tsx:45
#: src/Components/AccountUsage/index.tsx:57
#: src/Components/AccountUsage/index.tsx:72
#: src/Pages/SelectPlan/plan-features.tsx:117
msgid "Unlimited"
msgstr "Ubegrænset"

#: src/Components/AccountUsage/index.tsx:67
msgid "Max number of Workspaces"
msgstr "Maksimalt antal workspaces"

#: src/Components/AccSelect/AccMultiSelect.tsx:100
msgid "+"
msgstr "+"

#: src/Components/AccSelect/AccMultiSelect.tsx:173
#: src/Components/AccSelect/AccSelect.tsx:87
msgid "Clear value"
msgstr "Ryd værdi"

#: src/Components/AccSelect/AccMultiSelect.tsx:565
#: src/Components/AccSelect/components/DelayedSpinner.tsx:17
#: src/Components/Chart/ChartLoader/ChartLoader.tsx:46
#: src/Components/Chart/ReactHighcarts.tsx:38
#: src/Components/DataTable/components/footer/Pagination/helpers.tsx:146
#: src/Components/HighchartsLang/index.ts:35
#: src/Components/ImporterStatus/index.tsx:54
#: src/Components/Modal/Content/KeywordInfo/index.tsx:88
#: src/Components/Modal/Content/KeywordInfo/index.tsx:105
#: src/Components/Modal/Wizard/ConnectToAdobe/SelectAdobeAccount/index.tsx:65
#: src/Components/Modal/Wizard/ConnectToGA/Account/index.tsx:72
#: src/Components/Modal/Wizard/ConnectToGA/Profile/index.tsx:59
#: src/Components/Modal/Wizard/ConnectToGA/PropertyBulkImport/index.tsx:135
#: src/Components/Modal/Wizard/ConnectToGSC/GSCBulkWebsites/index.tsx:193
#: src/Components/Modal/Wizard/ConnectToGSC/GSCWebsite/index.tsx:191
#: src/Components/Table/VirtualizedTable/VirtualizedTable.tsx:111
#: src/Pages/Keywords/Groupings/EditMode/index.tsx:173
#: src/Pages/Keywords/Groupings/ViewMode/ViewMode.tsx:74
msgid "Loading…"
msgstr "Henter…"

#: src/Components/AccSelect/support/helpers.ts:7
msgid "Add: \"%s\""
msgstr "Tilføj: \"%s\""

#: src/Components/AccSelect/support/hooks.tsx:144
#: src/Components/ImpersonateDropdown/ImpresonateSelect/ImpersonateSelect.tsx:76
#: src/Components/LinkedAccountsDropdown/LinkedAccountSelect/LinkedAccountSelect.tsx:55
#: src/Components/Modal/Content/DomainsComparison/components/CollapseGroups.tsx:89
msgid "No results found"
msgstr "Ingen resultater fundet"

#: src/Components/AccTableHeaderCell/index.tsx:269
msgid "Filter by"
msgstr "Filtrer efter"

#: src/Components/AccTableHeaderCell/index.tsx:290
msgid "Sort by"
msgstr "Sorter efter"

#: src/Components/AccTableHeaderCell/index.tsx:315
#: src/Components/AccTableHeaderCell/index.tsx:364
msgid "Unsort"
msgstr "Afsortér"

#: src/Components/AccTableHeaderCell/index.tsx:323
msgid "Sort by change"
msgstr "Sorter efter ændring"

#: src/Components/AccTag/index.tsx:81
msgid "Add as Filter"
msgstr "Tilføj som filter"

#: src/Components/AccTag/index.tsx:97
#: src/Components/Modal/Content/CreateTagModal/CreateOrEditDynamicTagModal/index.tsx:86
msgid "Edit Dynamic Tag"
msgstr "Rediger dynamisk tag"

#: src/Components/AccTag/index.tsx:105
msgid "Remove Tag"
msgstr "Slet tag"

#: src/Components/AccuTable/CellRenderer/aboveTheFold.tsx:22
#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:60
#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:61
#: src/Pages/Domains/TreeView/support/constants.ts:120
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:680
#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/support/constants.tsx:75
#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:222
#: src/Pages/Keywords/Groupings/HistoryGraph/helpers/getMetricControlOptions.ts:40
#: src/Pages/Keywords/Groupings/support/constants.ts:165
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:685
#: src/Pages/Keywords/Table/keydisCells.ts:135
msgid "Above the fold"
msgstr "Over folden"

#: src/Components/AccuTable/CellRenderer/aboveTheFold.tsx:22
msgid "Not above the fold"
msgstr "Ikke over folden"

#: src/Components/AccuTable/CellRenderer/actions.tsx:53
#: src/Components/AccuTable/CellRenderer/actions.tsx:67
msgid "Refresh keyword"
msgstr "Opdater søgeord"

#: src/Components/AccuTable/CellRenderer/actions.tsx:54
#: src/Components/AccuTable/CellRenderer/actions.tsx:68
#: src/Pages/Keywords/Table/Actions/MoreActions/index.tsx:70
msgid "You can only refresh keywords when viewing the latest data"
msgstr "Du kan kun opdatere søgeord når du ser det nyeste data"

#: src/Components/AccuTable/CellRenderer/actions.tsx:90
#: src/Components/AccuTable/CellRenderer/actions.tsx:103
msgid "Show SERP result"
msgstr "Vis SERP'en"

#: src/Components/AccuTable/CellRenderer/actions.tsx:90
#: src/Components/AccuTable/CellRenderer/actions.tsx:103
msgid "SERP is no longer available for this date"
msgstr "SERP'en er ikke længere tilgængelig for denne dato"

#: src/Components/AccuTable/CellRenderer/aiSearchVolumePlaceholder.tsx:10
msgid "Import keyword to see AI Search Volume"
msgstr "Importér søgeord for at se AI Søgevolumen"

#: src/Components/AccuTable/CellRenderer/CellUrlWithStatus/CellUrlWithStatus.tsx:77
msgid "Show title and description on the SERP"
msgstr "Vis SERP'ens titel og beskrivelse"

#: src/Components/AccuTable/CellRenderer/CellUrlWithStatus/CellUrlWithStatus.tsx:191
msgid "Preferred URL. %s."
msgstr "Foretrukket URL. %s."

#: src/Components/AccuTable/CellRenderer/CellUrlWithStatus/helpers.tsx:32
msgid "Preferred URL matches ranked page"
msgstr "Foretrukket URL matcher siden der ranker"

#: src/Components/AccuTable/CellRenderer/CellUrlWithStatus/helpers.tsx:43
msgid "Set the preferred URL for this keyword"
msgstr "Indstil foretrukket URL for dette søgeord"

#: src/Components/AccuTable/CellRenderer/CellUrlWithStatus/helpers.tsx:44
msgid "No preferred URL set"
msgstr "Ingen foretrukket URL indstillet"

#: src/Components/AccuTable/CellRenderer/CellUrlWithStatus/helpers.tsx:53
msgid "Not ranked for correct preferred URL"
msgstr "Ranker ikke for foretrukket URL"

#: src/Components/AccuTable/CellRenderer/competition.tsx:35
msgid "No Ads Competition for this search engine"
msgstr "Ingen annoncekonkurrence for denne søgemaskine"

#: src/Components/AccuTable/CellRenderer/cpc.tsx:35
msgid "No CPC for this search engine"
msgstr "Ingen CPC for denne søgemaskine"

#: src/Components/AccuTable/CellRenderer/ctr.tsx:26
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:202
msgid "No CTR for this date."
msgstr "Ingen CTR på denne dato."

#: src/Components/AccuTable/CellRenderer/ctr.tsx:31
msgid "No CTR for this search engine."
msgstr "Ingen CTR for denne søgemaskine."

#: src/Components/AccuTable/CellRenderer/ctrChange.tsx:45
#: src/Components/AccuTable/CellRenderer/rank.tsx:35
#: src/Components/AccuTable/CellRenderer/rankChange.tsx:52
#: src/Components/Chart/LineChart/support/helpers.ts:203
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:363
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:410
#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/components/CompetitorRankCell.tsx:19
#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/components/CompetitorsRankChangeCell.tsx:21
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:376
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:422
#: src/Pages/Keywords/Overview/components/StockTooltipContent/StockTooltipContent.tsx:58
#: src/Pages/Keywords/Overview/components/StockTooltipContent/StockTooltipContent.tsx:125
msgid "Not in top 100"
msgstr "Ikke i top 100"

#: src/Components/AccuTable/CellRenderer/ctrMax.tsx:53
msgid "Show top 10 CTRs"
msgstr "Vis top 10 CTR'er"

#: src/Components/AccuTable/CellRenderer/description.tsx:108
#: src/Components/Fields/Form/FormSubmitButton.tsx:16
#: src/Components/Filters/SaveFilterGroup/SaveFilterForm/index.tsx:52
#: src/Components/Modal/Content/AddEditUserForm/index.tsx:137
#: src/Components/Modal/Content/AddEditWorkspaceForm/index.tsx:107
#: src/Components/Modal/Content/BuildCompetitor/BuildCompetitorForm/index.tsx:93
#: src/Components/Modal/Content/CreateTagModal/CreateOrEditStaticTagModal/index.tsx:153
#: src/Components/Modal/Content/EditFilterGroup/EditFilterGroupForm/index.tsx:105
#: src/Components/Modal/Content/EditNote/EditNoteForm/index.tsx:120
#: src/Components/Modal/Content/GroupModalView/index.tsx:45
#: src/Components/Modal/Content/LandingPage/LandingPageForm/new.tsx:161
#: src/Components/Modal/Content/MoveKeywords/MoveKeywordsForm/index.tsx:113
#: src/Components/Modal/Content/Sales/DemoContent/index.tsx:85
#: src/Components/Modal/Content/Sales/Extend/index.tsx:76
#: src/Components/Modal/Content/Sales/MakeAffiliate/index.tsx:104
#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:161
#: src/Pages/AccountSettings/index.tsx:292
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/DynamicTagsBuilder.tsx:75
#: src/Pages/DomainSettings/components/Menu/DisplaySettings/index.tsx:172
#: src/Pages/Keywords/Groupings/EditMode/components/GroupEditActions.tsx:33
#: src/Pages/PublicReport/publicReportSettings/index.tsx:606
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:532
msgid "Save"
msgstr "Gem"

#: src/Components/AccuTable/CellRenderer/description.tsx:134
msgid "Click to edit"
msgstr "Klik for at redigere"

#: src/Components/AccuTable/CellRenderer/HelperComponents/CellLoaders/withKeywordCellLoader.tsx:53
msgid "Refreshing data…"
msgstr "Opdaterer data…"

#: src/Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/serpIcon.tsx:84
msgid "%s %s %s"
msgstr "%s %s %s"

#: src/Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/serpIcon.tsx:87
#: src/Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/serpIcon.tsx:102
#: src/Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/SerpPopover.tsx:48
msgid "(owned)"
msgstr "(ejet)"

#: src/Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/serpIcon.tsx:88
msgid "- positioned before rank %s"
msgstr "- placeret før placering %s"

#: src/Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/serpIcon.tsx:100
msgid "[serpLabel] [owned]"
msgstr "[serpLabel] [owned]"

#: src/Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/serpIcon.tsx:107
#: src/Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/SerpPopover.tsx:52
msgid "Position on SERP: Before rank [serpRank]"
msgstr "Placering på SERP: Før placering [serpRank]"

#: src/Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/serpIcon.tsx:114
msgid "Position in Feature: [featurePosition]"
msgstr "Placering i element: [featurePosition]"

#: src/Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/serpIcon.tsx:134
#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/SERPFeatures.tsx:111
msgid "Click to learn more"
msgstr "Klik for at læse mere"

#: src/Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/serpIcon.tsx:151
#: src/Components/Filters/AddFilter/component.tsx:185
#: src/Components/Filters/FiltersShell.tsx:21
msgid "Add filter"
msgstr "Tilføj filter"

#: src/Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/SerpPopover.tsx:18
msgid "All SERP Features"
msgstr "Alle SERP elementer"

#: src/Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/SerpPopover.tsx:59
msgid "Position in Feature: [numberInFeature]"
msgstr "Placering i element: [numberInFeature]"

#: src/Components/AccuTable/CellRenderer/HelperComponents/Top10DomainsPopover/top10Domains.tsx:80
#: src/Components/Filters/Editors/Domain/data.ts:16
#: src/Components/Modal/Content/MoveDomain/MoveDomainForm/index.tsx:182
#: src/Components/Modal/Content/MoveKeywords/MoveKeywordsForm/index.tsx:94
#: src/Components/Modal/Content/MoveKeywords/MoveKeywordsForm/index.tsx:107
#: src/Components/Modal/Content/SalesOrganizationDomains/index.tsx:33
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:20
#: src/Components/Modal/Wizard/ConnectToGA/PropertyBulkImport/index.tsx:140
#: src/Components/Modal/Wizard/ConnectToGSC/GSCBulkWebsites/index.tsx:198
#: src/Pages/Accounts/TransferMultiAccountDomain/TransferMultiAccountDomainForm/TransferMultiAccountDomainForm.tsx:166
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:244
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:220
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:222
#: src/Pages/Sales/Organization/index.tsx:246
#: src/Pages/Sales/Search/index.tsx:96
#: src/Pages/Sales/Search/sales-search-input.tsx:46
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:255
msgid "Domain"
msgstr "Domæne"

#: src/Components/AccuTable/CellRenderer/HelperComponents/Top10DomainsPopover/top10Domains.tsx:86
#: src/Components/Filters/Editors/DynamicCtr/data.ts:18
#: src/Components/Filters/Editors/GscCtr/data.ts:17
#: src/Components/Filters/Editors/ID/data.ts:18
#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:40
#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:41
#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:191
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:352
#: src/Pages/Domains/TreeView/support/constants.ts:110
#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:203
#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/support/constants.tsx:156
#: src/Pages/Keywords/Groupings/HistoryGraph/helpers/getMetricControlOptions.ts:24
#: src/Pages/Keywords/Groupings/support/constants.ts:129
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/constants.ts:81
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/hooks.tsx:290
#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageCTR/AverageCtrChart.tsx:101
#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:271
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:638
#: src/Pages/Keywords/Table/keydisCells.ts:200
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:759
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:177
#: src/Pages/SiteMapping/components/CompetitorTable.tsx:82
#: src/Pages/SiteMapping/components/TreeTable/Header.tsx:84
msgid "CTR"
msgstr "CTR"

#: src/Components/AccuTable/CellRenderer/keyword/components/KeywordCellTagsList.tsx:61
#: src/Components/AccuTable/CellRenderer/keyword/components/KeywordExtraTags.tsx:57
#: src/Components/AccuTable/CellRenderer/keyword/components/KeywordExtraTagsCompact.tsx:63
msgid "Add as filter or edit tag: "
msgstr "Tilføj som filter eller rediger tag. "

#: src/Components/AccuTable/CellRenderer/keyword/components/KeywordCellTagsList.tsx:62
#: src/Components/AccuTable/CellRenderer/keyword/components/KeywordExtraTags.tsx:58
#: src/Components/AccuTable/CellRenderer/keyword/components/KeywordExtraTagsCompact.tsx:64
msgid "Add as filter or remove tag: "
msgstr "Tilføj som filter eller fjern tag. "

#: src/Components/AccuTable/CellRenderer/keyword/components/KeywordExtraTags.tsx:31
msgid "Show all tags"
msgstr "Vis alle tags"

#: src/Components/AccuTable/CellRenderer/keyword/components/KeywordExtraTagsCompact.tsx:30
msgid "Click to show all tags"
msgstr "Klik for at vise alle tags"

#: src/Components/AccuTable/CellRenderer/keyword/index.tsx:63
#: src/Components/Controls/SearchEngineSettings/index.tsx:21
#: src/Components/Forms/SearchEngine/index.tsx:60
#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:154
msgid "Ignore local pack"
msgstr "Ignorer lokale resultater"

#: src/Components/AccuTable/CellRenderer/keyword/index.tsx:67
#: src/Components/Controls/SearchEngineSettings/index.tsx:28
#: src/Components/Filters/Editors/KeywordOptions/data.ts:23
#: src/Components/Forms/SearchEngine/index.tsx:64
msgid "Ignore featured snippet"
msgstr "Ignorer fremhævede uddrag"

#: src/Components/AccuTable/CellRenderer/keyword/index.tsx:71
#: src/Components/Filters/Editors/KeywordOptions/data.ts:27
#: src/Components/Forms/SearchEngine/index.tsx:68
msgid "Ignore in share of voice"
msgstr "Ignorer i synlighed"

#: src/Components/AccuTable/CellRenderer/keyword/index.tsx:75
#: src/Components/Forms/SearchEngine/index.tsx:72
msgid "Autocorrect enabled"
msgstr "Autocorrect slået til"

#: src/Components/AccuTable/CellRenderer/keyword/index.tsx:137
msgid "Ignored domain: %s"
msgstr "Ignoreret domæne: %s"

#: src/Components/AccuTable/CellRenderer/keyword/index.tsx:171
msgid "Rank history not available while updating keyword"
msgstr "Kan ikke tilgå placeringshistorik mens søgeordet opdateres"

#: src/Components/AccuTable/CellRenderer/keyword/index.tsx:174
msgid "Show rank history for '%s'"
msgstr "Vis placeringshistorik for '%s'"

#: src/Components/AccuTable/CellRenderer/pixelsFromTop.tsx:27
msgid "No data for this date."
msgstr "Ingen data for denne dato."

#: src/Components/AccuTable/CellRenderer/rank.tsx:22
msgid ""
"Rankings for this search engine will come at a slower rate than Google and "
"Bing."
msgstr ""
"Placeringer for denne søgemaskine kommer ind lidt langsommere end Google og "
"Bing."

#: src/Components/AccuTable/CellRenderer/rank.tsx:23
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1121
msgid "Refreshing rank…"
msgstr "Opdaterer placering…"

#: src/Components/AccuTable/CellRenderer/rank.tsx:29
msgid "Not in Local Pack"
msgstr "Ikke blandt lokalresultater"

#: src/Components/AccuTable/CellRenderer/rank.tsx:36
#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/components/CompetitorRankCell.tsx:20
msgid "> 100"
msgstr "> 100"

#: src/Components/AccuTable/CellRenderer/rank.tsx:73
msgid "No rank for date"
msgstr "Ingen placering for dato"

#: src/Components/AccuTable/CellRenderer/rank.tsx:98
msgid "This rank has %s more positions, click to view."
msgstr "Dette resultat har %s yderligere placeringer."

#: src/Components/AccuTable/CellRenderer/rank.tsx:99
msgid "Hide extra ranks."
msgstr "Skjul ekstra placeringer."

#: src/Components/AccuTable/CellRenderer/rank.tsx:119
msgid "View rank history"
msgstr "Se placeringshistorie"

#: src/Components/AccuTable/CellRenderer/rank.tsx:120
msgid "Hide rank history"
msgstr "Skjul placeringshistorik"

#: src/Components/AccuTable/CellRenderer/searchIntent.tsx:38
msgid "No Search Intent for this search engine"
msgstr "Ingen søgeintention for denne søgemaskine"

#: src/Components/AccuTable/CellRenderer/searchVolume.tsx:65
#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/components/SearchVolumeCell.tsx:31
msgid "Local search volume is not available for this location."
msgstr "Søgevolumen-data er ikke tilgængeligt for denne lokation."

#: src/Components/AccuTable/CellRenderer/searchVolume.tsx:72
msgid "Search volume not available."
msgstr "Søgevolume er ikke tilgængelig."

#: src/Components/AccuTable/CellRenderer/searchVolume.tsx:125
msgid "View historic search volume for this keyword"
msgstr "Vis historisk søgevolumen for dette søgeord"

#: src/Components/AccuTable/CellRenderer/SerpDropdown.tsx:24
msgid "Show all SERP Features"
msgstr "Vis alle SERP-elementer"

#: src/Components/AccuTable/CellRenderer/shareOfVoiceChange.tsx:41
#: src/Components/AccuTable/CellRenderer/shareOfVoiceChange.tsx:45
msgid "Share of Voice not available."
msgstr "Synlighed er ikke tilgængelig."

#: src/Components/AccuTable/CellRenderer/shareOfVoiceCPC.tsx:33
msgid "No Traffic Value for this date."
msgstr "Ingen trafikværdi for denne søgemaskine."

#: src/Components/AccuTable/CellRenderer/shareOfVoiceCPC.tsx:37
msgid "No Traffic Value for this search engine."
msgstr "Ingen trafikværdi for denne søgemaskine."

#: src/Components/AccuTable/CellRenderer/topDomain.tsx:43
#: src/Components/Filters/Editors/TopCompetitor/components/TopCompetitorFieldInput.tsx:20
msgid "Local result"
msgstr "Lokalresultat"

#: src/Components/AccuTable/CellRenderer/topDomain.tsx:54
msgid "Show top 10 competitors"
msgstr "Vis top 10-konkurrenter"

#: src/Components/AccuTable/CellRenderer/traffic.tsx:33
msgid "No AI SoV for this search engine"
msgstr "Ingen AI Synlighed for denne søgemaskine"

#: src/Components/AdvancedPlanUpsellChart/index.tsx:30
msgid "Want More Metrics?"
msgstr "Vil du have flere tal?"

#: src/Components/AdvancedPlanUpsellChart/index.tsx:35
msgid "Upgrade your plan to enable advanced metrics such as SoV Pro and more."
msgstr ""
"Opgrader dit abonnement for at få adgang til avancerede beregninger som "
"Synlighed Pro og mere."

#: src/Components/AdvancedPlanUpsellChart/index.tsx:40
#: src/Pages/Keywords/Overview/MissingDataOverlay.tsx:96
#: src/Pages/Layout/ActionsMenu/Actions/UpgradeAction.tsx:32
msgid "Upgrade plan"
msgstr "Opgrader abonnement"

#: src/Components/AdvancedPlanUpsellChart/index.tsx:58
msgid "Click to read more"
msgstr "Klik for at læse mere"

#: src/Components/BaseContainer/support/hooks.ts:35
msgid "Warning: Internet Explorer usage"
msgstr "Advarsel: Internet Explorer"

#: src/Components/BaseContainer/support/hooks.ts:36
msgid ""
"You could see multiple issues while using this app in IE, please use other "
"browser to avoid negative user experience."
msgstr ""
"Du vil opleve problemer hvis du bruger Internet Explorer. Brug venligst en "
"anden browser for en meget bedre oplevelse."

#: src/Components/BaseContainer/support/hooks.ts:40
#: src/Components/Modal/Wizard/ConnectToOAuth/index.tsx:47
#: src/Hooks/useKeyword.tsx:723
#: src/Pages/Accounts/TransferMultiAccountDomain/index.tsx:82
msgid "OK"
msgstr "Okay"

#: src/Components/Breadcrumbs/Breadcrumbs.tsx:20
msgid "Go to home page"
msgstr "Gå til startsiden"

#: src/Components/Cell/ChangeCell.tsx:16 src/Components/Cell/index.tsx:91
#: src/Components/Cell/NumberCell.tsx:24
#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:200
msgid "No value for this date"
msgstr "Ingen værdi for dato"

#: src/Components/Cell/LinkToKeywordListCell.tsx:55
#: src/Components/DomainDetails/index.tsx:81
#: src/Pages/Domains/CardView/components/DomainCard/components/DomainActions.tsx:46
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/DomainActions.tsx:53
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/GroupActions.tsx:55
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/KeywordsCell.tsx:58
msgid "Go to keywords list"
msgstr "Gå til søgeordslisten"

#: src/Components/Chart/BubbleChart/support/hooks.ts:89
#: src/Components/Filters/Editors/ShareOfVoice/data.ts:18
#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:26
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:122
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:143
#: src/Pages/Domains/CardView/components/DomainCard/components/DomainCharts.tsx:65
#: src/Pages/Domains/CardView/support/getSorting.ts:53
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:243
#: src/Pages/Domains/support/types.ts:107
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:261
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:262
#: src/Pages/Keywords/Competitors/components/CompetitorChartContent/support/helpers.ts:21
#: src/Pages/Keywords/Groupings/HistoryGraph/helpers/getMetricControlOptions.ts:12
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:297
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:298
#: src/Pages/Keywords/LandingPage/components/KeywordLandingChart/support/helpers.ts:15
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:81
#: src/Pages/Keywords/Overview/components/HistoryCharts/ShareOfVoice/helpers.ts:14
#: src/Pages/Keywords/Overview/components/HistoryCharts/ShareOfVoice/SovChart.tsx:72
#: src/Pages/Keywords/Overview/components/HistoryCharts/ShareOfVoice/SovChart.tsx:91
#: src/Pages/Keywords/Overview/components/KpiBar/Boxes/ShareOfVoiceKpiBox.tsx:38
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:64
#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/ShareOfVoiceContainer.tsx:39
#: src/Pages/Keywords/Overview/components/WinnersLosers/components/WinnerLoserBarLabel.tsx:114
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:910
#: src/Pages/PublicReport/publicReportSettings/index.tsx:564
#: src/Pages/PublicReport/publicReportSettings/index.tsx:573
#: src/Pages/SelectPlan/plan-features.tsx:63
msgid "Share of Voice"
msgstr "Synlighed"

#: src/Components/Chart/BubbleChart/support/hooks.ts:97
#: src/Components/Chart/BubbleChart/support/hooks.ts:106
#: src/Components/Filters/Editors/SearchVolume/data.ts:19
#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:45
#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:46
#: src/Pages/Keywords/Groupings/HistoryGraph/helpers/getMetricControlOptions.ts:28
#: src/Pages/Keywords/LandingPage/components/KeywordLandingChart/support/helpers.ts:27
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/hooks.tsx:129
#: src/Pages/SiteMapping/components/CompetitorTable.tsx:86
#: src/Pages/SiteMapping/components/TreeTable/Header.tsx:70
msgid "Search volume"
msgstr "Søgevolume"

#: src/Components/Chart/BubbleChart/support/hooks.ts:120
msgid "Keywords Count"
msgstr "Antal søgeord"

#: src/Components/Chart/BubbleChart/support/hooks.ts:167
msgid "Change domain to "
msgstr "Skift domæne til "

#: src/Components/Chart/LineChart/support/hooks/useChartConfig.tsx:131
#: src/Components/PeriodFilter/CompareToContentSegmented/index.tsx:163
msgid "1w"
msgstr "1u"

#: src/Components/Chart/LineChart/support/hooks/useChartConfig.tsx:132
msgid "View 1 week"
msgstr "Se 1 uge"

#: src/Components/Chart/LineChart/support/hooks/useChartConfig.tsx:137
#: src/Components/PeriodFilter/CompareToContentSegmented/index.tsx:181
msgid "1m"
msgstr "1m"

#: src/Components/Chart/LineChart/support/hooks/useChartConfig.tsx:138
msgid "View 1 month"
msgstr "Se 1 måned"

#: src/Components/Chart/LineChart/support/hooks/useChartConfig.tsx:143
msgid "3m"
msgstr "3m"

#: src/Components/Chart/LineChart/support/hooks/useChartConfig.tsx:144
msgid "View 3 months"
msgstr "Se 3 måneder"

#: src/Components/Chart/LineChart/support/hooks/useChartConfig.tsx:148
msgid "YTD"
msgstr "ÅTD"

#: src/Components/Chart/LineChart/support/hooks/useChartConfig.tsx:149
msgid "View year to date"
msgstr "Se år til dato"

#: src/Components/Chart/LineChart/support/hooks/useChartConfig.tsx:154
#: src/Components/PeriodFilter/CompareToContentSegmented/index.tsx:190
msgid "1y"
msgstr "1å"

#: src/Components/Chart/LineChart/support/hooks/useChartConfig.tsx:155
msgid "View 1 year"
msgstr "Se 1 år"

#: src/Components/Chart/LineChart/support/hooks/useChartConfig.tsx:159
msgid "All "
msgstr "Alt "

#: src/Components/Chart/LineChart/support/hooks/useChartConfig.tsx:160
msgid "View all"
msgstr "Se alt"

#: src/Components/Chart/Notes/components/ChartNotePopoverContent.tsx:31
msgid "[from] of [to]"
msgstr "[from] af [to]"

#: src/Components/Chart/Notes/components/ChartNotePopoverContent.tsx:86
#: src/Pages/NotesList/NotesTable/support/useNotesTableInfo.tsx:181
msgid "Edit note"
msgstr "Rediger notat"

#: src/Components/Chart/Notes/support/helpers.tsx:93
msgid ""
"Change in Google Keyword Planner.[br][br]Google has made major changes in "
"their[br]tallying of search volume. [br][br][readMore]"
msgstr ""
"Ændring i Google Keyword Planner.[br][br]Google har foretaget store "
"ændringer i deres[br]optælling af søgevolumen. [br][br][readMore]"

#: src/Components/Chart/Notes/support/helpers.tsx:103
#: src/Pages/Integrations/components/IntegrationCard.tsx:51
msgid "Read more"
msgstr "Læs mere"

#: src/Components/Chart/Notes/support/helpers.tsx:116
msgid ""
"Major AI CTR model update.[br][br]AccuRanker's AI CTR model now incorporates "
"40 additional features, e.g. AI Overview.[br][br]This gives much more "
"accurate CTR estimates than before."
msgstr ""
"Større opdatering af AI CTR-modellen.[br][br]AccuRankers AI CTR-model "
"inkluderer nu 40 ekstra funktioner, f.eks. AI Oversigt.[br][br]Dette giver "
"meget mere præcise CTR-estimater end før."

#: src/Components/Chart/ReactHighcarts.tsx:29
#: src/Components/HighchartsLang/index.ts:26
#: src/Pages/Keywords/Overview/components/KebabMenu/index.tsx:105
msgid "Download CSV"
msgstr "Gem CSV"

#: src/Components/Chart/ReactHighcarts.tsx:30
#: src/Components/HighchartsLang/index.ts:27
msgid "Download JPEG"
msgstr "Gem JPEG"

#: src/Components/Chart/ReactHighcarts.tsx:31
#: src/Components/HighchartsLang/index.ts:28
msgid "Download PDF document"
msgstr "Gem PDF dokument"

#: src/Components/Chart/ReactHighcarts.tsx:32
#: src/Components/HighchartsLang/index.ts:29
msgid "Download PNG image"
msgstr "Gem PNG billede"

#: src/Components/Chart/ReactHighcarts.tsx:33
#: src/Components/HighchartsLang/index.ts:30
msgid "Download SVG vector image"
msgstr "Gem SVG"

#: src/Components/Chart/ReactHighcarts.tsx:34
#: src/Components/HighchartsLang/index.ts:31
msgid "Download XLS"
msgstr "Gem XLS"

#: src/Components/Chart/ReactHighcarts.tsx:35
#: src/Components/HighchartsLang/index.ts:32
msgid "Back to {series.name}"
msgstr "Tilbage til {series.name}"

#: src/Components/Chart/ReactHighcarts.tsx:36
#: src/Components/HighchartsLang/index.ts:33
msgid "No data to display"
msgstr "Ingen data at vise"

#: src/Components/Chart/ReactHighcarts.tsx:37
#: src/Components/HighchartsLang/index.ts:34
msgid "Print chart"
msgstr "Udskriv graf"

#: src/Components/Chart/TreeChart/Tree/index.tsx:627
msgid "Reset position"
msgstr "Nulstil position"

#: src/Components/Chart/TreeChart/Tree/index.tsx:636
msgid "Zoom in"
msgstr "Zoom ind"

#: src/Components/Chart/TreeChart/Tree/index.tsx:651
msgid "Zoom out"
msgstr "Zoom ud"

#: src/Components/ContactSupportButton/index.tsx:16
#: src/Components/CrossSellBanner/index.tsx:55
#: src/Components/Intercom/IntercomButton.tsx:51
msgid "Close support"
msgstr "Luk supporten"

#: src/Components/ContactSupportButton/index.tsx:16
#: src/Components/CrossSellBanner/index.tsx:55
#: src/Components/Intercom/IntercomButton.tsx:51
#: src/Components/OveruseConfirmation/index.tsx:76
#: src/Pages/PublicReport/ErrorFallback.tsx:59
#: src/Pages/SelectPlan/index.tsx:115
msgid "Contact support"
msgstr "Kontakt supporten"

#: src/Components/Controls/ColorPicker/index.tsx:72
#: src/Components/Modal/Wizard/CreateAffiliateLink/GetAffiliateLink/index.tsx:119
#: src/Components/Modal/Wizard/CreateAffiliateLink/GetAffiliateLink/index.tsx:130
#: src/Components/Table/TableRow/CopyTextCell/index.tsx:21
#: src/Pages/AIOverview/components/AIOverviewTable/support/AIOverviewCell.tsx:216
#: src/Pages/AIOverview/components/AIOverviewTable/support/AIOverviewCell.tsx:281
#: src/Pages/AIOverview/components/AIOverviewTable/support/AIOverviewCell.tsx:328
#: src/Pages/PublicReport/Header.tsx:94 src/Pages/PublicReport/Header.tsx:106
#: src/Pages/Sales/CreatePlan/CreatePlanSuccess/index.tsx:30
#: src/Pages/Sales/Plans/SalesPlansTable/support/hooks.tsx:111
#: src/Pages/Sales/Search/index.tsx:139
#: src/Pages/ScheduledReportBuilder/PublicReportOptions/index.tsx:145
msgid "Copied!"
msgstr "Kopieret!"

#: src/Components/Controls/Dropdowns/CountrySelect/index.tsx:95
#: src/Components/Controls/Dropdowns/LocaleSelect/index.tsx:174
msgid "Suggested countries and locales"
msgstr "Foreslåede lande og sprog"

#: src/Components/Controls/Dropdowns/CountrySelect/index.tsx:96
#: src/Components/Controls/Dropdowns/LocaleSelect/index.tsx:175
msgid "Other countries and locales"
msgstr "Andre lande og sprog"

#: src/Components/Controls/Dropdowns/SearchItemsList/index.tsx:263
msgid "No domain matches your search"
msgstr "Ingen domæner matcher søgningen"

#: src/Components/Controls/Dropdowns/SimpleItemsList/index.tsx:120
msgid "No items to display"
msgstr "Ingen data at vise"

#: src/Components/Controls/LocationInput/index.tsx:83
msgid "Please select a location from the suggestions"
msgstr "Vælg venligst en placering fra forslagene"

#: src/Components/Controls/LocationInput/index.tsx:87
msgid "You cannot add more than one empty location."
msgstr "Du kan ikke tilføje mere end én tom placering."

#: src/Components/Controls/PasswordInput/index.tsx:109
msgid ""
"This password has been found in data breaches and is not secure. Please "
"choose another one."
msgstr ""
"Denne adgangskode er blevet fundet i datalæk og er ikke sikker. Vælg "
"venligst en anden."

#: src/Components/Controls/SearchEngineSettings/index.tsx:22
#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:155
msgid ""
"If checked, any local pack found on the SERP will not be included in the "
"rank position."
msgstr ""
"Hvis afkrydset springer vi alle lokale resultater over, og de indgår således "
"ikke i placeringen."

#: src/Components/Controls/SearchEngineSettings/index.tsx:29
#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:163
msgid "Ignore the featured snippet that is often shown on how/what searches."
msgstr ""
"Ignorer det fremhævede uddrag der ofte hvis på hvem/hvad/hvor søgninger."

#: src/Components/Controls/SearchEngineSettings/index.tsx:33
#: src/Components/Filters/Editors/KeywordOptions/data.ts:35
#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:170
msgid "Enable autocorrect"
msgstr "Slå auto correct til"

#: src/Components/Controls/SearchEngineSettings/index.tsx:34
msgid "Enable Google Autocorrect for this keyword."
msgstr "Slå Google autocorrect til for dette søgeord."

#: src/Components/Controls/SearchSettingsField/index.tsx:36
msgid "This search engine is no longer supported."
msgstr "Denne søgemaskine er ikke længere understøttet."

#: src/Components/Controls/SearchSettingsField/index.tsx:39
msgid ""
"To add YouTube as a search engine you need to add the channel as a domain."
msgstr ""
"For at tilføje YouTube som søgemaskine skal du tilføje tn YouTube kanal som "
"et domæne."

#: src/Components/Controls/SearchSettingsField/index.tsx:42
msgid "Mixed search engines are not supported for this domain."
msgstr "Blandede søgemaskiner understøttes ikke for dette domæne."

#: src/Components/Controls/SearchSettingsField/index.tsx:45
msgid "Mixed search engines are not supported for Naver."
msgstr "Blandede søgemaskiner understøttes ikke for Naver."

#: src/Components/Controls/SearchSettingsField/index.tsx:47
msgid "This search engine is not supported for the selected locale."
msgstr "Denne søgemaskine er ikke understøttet for dette søgeområde."

#: src/Components/Controls/SearchSettingsField/index.tsx:97
#: src/Components/Filters/Editors/SearchType/hooks.ts:23
#: src/Pages/AccuApi/SearchHistoryTable/index.tsx:40
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/DeviceCell.tsx:33
#: src/Pages/Keywords/Table/Chart/SearchVolume/index.tsx:74
#: src/Pages/Keywords/Table/Chart/SearchVolume/index.tsx:174
#: src/Pages/SelectPlan/plan-features.tsx:25
msgid "Desktop"
msgstr "Desktop"

#: src/Components/Controls/SearchSettingsField/index.tsx:100
#: src/Components/Filters/Editors/SearchType/hooks.ts:29
#: src/Pages/AccuApi/SearchHistoryTable/index.tsx:37
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/DeviceCell.tsx:21
#: src/Pages/Keywords/Table/Chart/SearchVolume/index.tsx:78
#: src/Pages/Keywords/Table/Chart/SearchVolume/index.tsx:180
#: src/Pages/SelectPlan/plan-features.tsx:25
msgid "Mobile"
msgstr "Mobil"

#: src/Components/Controls/SearchSettingsField/SearchEngineInput/index.tsx:88
msgid "Desktop is not supported by this search engine"
msgstr "Desktop understøttes ikke af denne søgemaskine"

#: src/Components/Controls/SearchSettingsField/SearchEngineInput/index.tsx:100
msgid "Mobile is not supported by this search engine"
msgstr "Mobil understøttes ikke af denne søgemaskine"

#: src/Components/Controls/TagsInput/index.tsx:55
#: src/Components/Controls/TagsInput/index.tsx:74
msgid "Create tag \"%s\""
msgstr "Opret tag \"%s\""

#: src/Components/Controls/TagsInput/support/__tests__/helpers.spec.ts:22
#: src/Components/Controls/TagsInput/support/__tests__/helpers.spec.ts:53
#: src/Components/Controls/TagsInput/support/__tests__/helpers.spec.ts:77
#: src/Components/Controls/TagsInput/support/helpers.ts:36
#: src/Components/Controls/TagsInput/support/helpers.ts:54
msgid "Dynamic Tags"
msgstr "Dynamiske tags"

#: src/Components/Controls/TagsInput/support/__tests__/helpers.spec.ts:28
#: src/Components/Controls/TagsInput/support/helpers.ts:47
#: src/Components/Filters/Editors/Tags/data.ts:18
#: src/Components/Modal/Content/AddKeywords/TagsInput/index.tsx:7
#: src/Components/Modal/Content/AddTags/AddTagsForm/index.tsx:19
#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/ShareOfVoiceContainer.tsx:54
#: src/Pages/Profile/components/EditProfile/index.tsx:67
msgid "Tags"
msgstr "Tags"

#: src/Components/Controls/TextInput/index.tsx:142
#: src/Components/Modal/Content/TableSettingsModal/components/TableSettingsForm/TableSettingsForm.tsx:49
#: src/Pages/ErrorPages/pageError/index.tsx:32
msgid "Reset"
msgstr "Nulstil"

#: src/Components/Controls/TextInput/index.tsx:147
msgid "Clear input"
msgstr "Nulstil indput"

#: src/Components/DataTable/components/body/BodyCell/components/CheckboxBodyCell.tsx:16
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/index.tsx:167
msgid "Can not select more than %s items."
msgstr "Du kan ikke vælge mere end %s elementer."

#: src/Components/DataTable/components/common/TableInfo/DynamicItemsIndicator.tsx:41
msgid "Keyword added"
msgid_plural "Keywords added"
msgstr[0] "Søgeord tilføjet"
msgstr[1] "Søgeord tilføjet"

#: src/Components/DataTable/components/common/TableInfo/DynamicItemsIndicator.tsx:42
msgid "and"
msgstr "og"

#: src/Components/DataTable/components/common/TableInfo/DynamicItemsIndicator.tsx:43
msgid "Keyword removed"
msgid_plural "Keywords removed"
msgstr[0] "Søgeord fjernet"
msgstr[1] "Søgeord fjernet"

#: src/Components/DataTable/components/common/TableInfo/DynamicItemsIndicator.tsx:44
msgid "from domain"
msgstr "fra domæne"

#: src/Components/DataTable/components/common/TableInfo/DynamicItemsIndicator.tsx:44
msgid "to domain"
msgstr "til domæne"

#: src/Components/DataTable/components/common/TableInfo/DynamicItemsIndicator.tsx:48
#: src/Pages/Keywords/Table/Actions/MoreActions/index.tsx:65
#: src/Pages/Layout/ActionsMenu/Actions/index.tsx:69
#: src/Pages/Profile/components/IntegrationsApiToken/index.tsx:59
#: src/Pages/ScheduledReportBuilder/PublicReportOptions/index.tsx:127
msgid "Refresh"
msgstr "Opdater"

#: src/Components/DataTable/components/common/TableInfo/DynamicSelectionInfo.tsx:76
msgid "%s keyword on this page selected."
msgid_plural "%s keywords on this page selected."
msgstr[0] "%s søgeord valgt på denne side."
msgstr[1] "%s søgeord valgt på denne side."

#: src/Components/DataTable/components/common/TableInfo/DynamicSelectionInfo.tsx:84
msgid "%s keyword selected."
msgid_plural "%s keywords selected."
msgstr[0] "%s søgeord valgt."
msgstr[1] "%s søgeord valgt."

#: src/Components/DataTable/components/common/TableInfo/helpers.ts:29
msgid "Select first %s keywords that match the filter."
msgstr "Vælg de første %s søgeord der matcher filteret."

#: src/Components/DataTable/components/common/TableInfo/helpers.ts:30
msgid "Select first %s keywords."
msgstr "Vælg de første %s søgeord."

#: src/Components/DataTable/components/common/TableInfo/helpers.ts:34
msgid "Select all %s keywords that match the filter."
msgstr "Vælg alle %s søgeord, der matcher filteret."

#: src/Components/DataTable/components/common/TableInfo/helpers.ts:35
msgid "Select all %s keywords."
msgstr "Vælg alle %s søgeord."

#: src/Components/DataTable/components/common/TableInfo/helpers.ts:45
#: src/Components/DataTable/components/common/TableInfo/helpers.ts:54
msgid "Selected the one keyword that match the filter."
msgid_plural "Selected all %s keywords that match the filter."
msgstr[0] "Vælg søgeordet, der matcher med filteret."
msgstr[1] "Vælg alle %s søgeord, der matcher med filteret."

#: src/Components/DataTable/components/common/TableInfo/helpers.ts:50
#: src/Components/DataTable/components/common/TableInfo/helpers.ts:59
msgid "Selected 1 keyword."
msgid_plural "Selected all %s keywords."
msgstr[0] "1 søgeord valgt."
msgstr[1] "%s søgeord valgt."

#: src/Components/DataTable/components/common/TablePlaceholder.tsx:16
#: src/Components/Modal/Wizard/ConnectToAdobe/Suite/index.tsx:93
#: src/Pages/Affiliate/AffiliateCommissions/AffiliateCommissionsTable/index.tsx:130
#: src/Pages/Affiliate/AffiliateCustomers/AffiliateCustomersTable/index.tsx:140
#: src/Pages/Affiliate/AffiliatePayments/AffiliatePaymentsTable/index.tsx:138
#: src/Pages/Affiliate/AffiliateVisitors/AffiliateReferralsTable/index.tsx:64
#: src/Pages/AIOverview/components/AIOverviewTable/AIOverviewTable.tsx:34
#: src/Pages/ExternalAccess/OwnersTable/index.tsx:142
#: src/Pages/ExternalAccess/PendingOwnersTable/index.tsx:150
#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/KeywordDiscoveryTable.tsx:66
#: src/Pages/KeywordOverusePage/components/OveruseTable/OveruseTable.tsx:36
#: src/Pages/NotesList/NotesTable/index.tsx:38
#: src/Pages/Sales/Mails/Table/index.tsx:14
msgid "No Data"
msgstr "Ingen data"

#: src/Components/DataTable/components/common/TablePlaceholder.tsx:17
#: src/Pages/KeywordOverusePage/components/OveruseTable/OveruseTable.tsx:36
msgid "There are currently no keywords."
msgstr "Der er ingen søgeord."

#: src/Components/DataTable/components/footer/Pagination/components/PaginationButton.tsx:38
msgid "…"
msgstr "…"

#: src/Components/DataTable/components/footer/Pagination/helpers.tsx:120
msgid "Loading [from] to [to] of [total]"
msgstr "Henter [from] til [to] af [total]"

#: src/Components/DataTable/components/footer/Pagination/helpers.tsx:151
msgid "[from] to [to] of [total]"
msgstr "[from] til [to] af [total]"

#: src/Components/DataTable/docs/DataTable.stories.tsx:261
#: src/Pages/NotesList/NotesTable/support/useNotesTableInfo.tsx:119
msgid "Sort by date created"
msgstr "Sorter efter oprettelsesdato"

#: src/Components/DataTable/docs/DataTable.stories.tsx:268
msgid "Filter by created date…"
msgstr "Filtrer efter oprettelsesdato…"

#: src/Components/DataTable/hooks/columns/useColumns.tsx:51
msgid "Loading data…"
msgstr "Henter data…"

#: src/Components/DataTable/hooks/copy/useDataCopy.tsx:39
msgid "Copied %s rows (maximum). Use download report for the full history."
msgstr ""
"%s rækker kopieret (maksimum). Brug download rapport for at hente den fulde "
"historik."

#: src/Components/DataTable/hooks/copy/useDataCopy.tsx:45
msgid "Keywords copied to clipboard"
msgstr "Søgeord kopieret til udklipsholder"

#: src/Components/DemoContent/DemoContentBadge.tsx:29
msgid ""
"This is a demo group and cannot be edited.<br>The data is updated once a day."
msgstr ""
"Dette er en demo gruppe og lan ikke redigeres.<b>Data bliver opdateret "
"dagligt."

#: src/Components/DemoContent/DemoContentBadge.tsx:30
msgid ""
"This is a demo domain and cannot be edited.<br>The data is updated once a "
"day."
msgstr ""
"Dette er et demo domæne og lan ikke redigeres.<b>Date bliver opdateret "
"dagligt."

#: src/Components/DemoContent/DemoContentBadge.tsx:40
msgid "Demo Group"
msgstr "Demogruppe"

#: src/Components/DemoContent/DemoContentBadge.tsx:40
msgid "Demo Domain"
msgstr "Demo domæne"

#: src/Components/DevBar/index.tsx:19
msgid "RUNNING IN DEVELOPMENT MODE (click to disable)"
msgstr "UDVIKLINGSMILJØ ER SLÅET TIL (klik for at slå fra)"

#: src/Components/DomainDetails/index.tsx:69
#: src/Components/Modal/Content/NotAllowedModal/index.tsx:34
#: src/Components/Modal/Content/UpsellModal/index.tsx:32
#: src/Pages/Domains/CardView/components/DomainCard/components/DomainActions.tsx:39
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/DomainActions.tsx:40
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/GroupActions.tsx:41
#: src/Pages/ErrorPages/404/index.tsx:31
#: src/Pages/ErrorPages/pageError/index.tsx:35
#: src/Pages/PublicReport/ErrorFallback.tsx:62
msgid "Go to dashboard"
msgstr "Gå til overblik"

#: src/Components/Dropzone/index.tsx:139
#: src/Components/Modal/Wizard/ConnectToBigQuery/ConnectBigQuery/index.tsx:103
msgid "Drag and drop file to upload"
msgstr "Træk og slip for at uploade"

#: src/Components/Dropzone/index.tsx:156
msgid "Select file"
msgstr "Vælg fil"

#: src/Components/Dropzone/index.tsx:162
msgid "Preview of uploaded file"
msgstr "Forhåndsvisning af uploadet fil"

#: src/Components/ExportButton/index.tsx:39
msgid "Image downloaded"
msgstr "Billede downloadet"

#: src/Components/ExportButton/index.tsx:42
msgid "Export as image failed"
msgstr "Eksport som billede fejlede"

#: src/Components/ExportButton/index.tsx:53
msgid "Download chart"
msgstr "Hent graf"

#: src/Components/Fields/Field/FieldGroupAccess.tsx:56
#: src/Components/Fields/Field/FieldUsers.tsx:114
#: src/Pages/Profile/components/EditProfile/index.tsx:258
msgid "All Groups"
msgstr "Alle Grupper"

#: src/Components/Fields/Field/FieldGroupAccess.tsx:67
msgid "Pick a group from the list"
msgstr "Vælg en gruppe fra listen"

#: src/Components/Fields/Field/FieldGroupAccess.tsx:68
msgid "There are no groups with that name"
msgstr "Der er ingen grupper med det navn"

#: src/Components/Fields/Field/FieldGroupAccess.tsx:79
#: src/Components/Fields/Field/FieldWorkspace.tsx:100
msgid "+[count] more"
msgstr "+[count] mere"

#: src/Components/Fields/Field/FieldGroupAccess.tsx:92
msgid "1 domain"
msgid_plural "%s domains"
msgstr[0] "1 domæne"
msgstr[1] "%s domæner"

#: src/Components/Fields/Field/FieldUsers.tsx:88
msgid "Select users"
msgstr "Vælg brugere"

#: src/Components/Fields/Field/FieldUsers.tsx:89
msgid "There are no users with that name"
msgstr "Der er ingen brugere med det navn"

#: src/Components/Fields/Field/FieldWorkspace.tsx:65
msgid "Admin and Superusers have access to all groups"
msgstr "Admins og Superbrugere har adgang til alle grupper"

#: src/Components/Fields/Field/FieldWorkspace.tsx:78
#: src/Components/Fields/Field/FieldWorkspace.tsx:81
msgid "Edit workspace access"
msgstr "Rediger workspace-adgang"

#: src/Components/Fields/Field/FieldWorkspace.tsx:88
msgid "No workspace selected"
msgstr "Intet workspace valgt"

#: src/Components/Fields/Field/FieldWorkspace.tsx:89
msgid "There are no Workspaces with that name"
msgstr "Der er ingen workspaces med det navn"

#: src/Components/Fields/Field/FieldWorkspace.tsx:111
msgid "Workspace won't have any effect as the user has access to all groups"
msgstr ""
"Workspace vil ikke have nogen effekt, da brugeren har adgang til alle grupper"

#: src/Components/Fields/Field/ImagePicker/index.tsx:39
msgid "Drag and drop image to upload"
msgstr "Træk og slip for at uploade"

#: src/Components/Fields/Field/ImagePicker/index.tsx:40
msgid "Invalid file type. Please provide an image type."
msgstr "Ugyldig filtype. Brug venligst en billedfil."

#: src/Components/Fields/Field/JsonPicker/index.tsx:30
msgid "Invalid JSON file format"
msgstr "Ugyldig JSON fil"

#: src/Components/Filters/AddFilter/component.tsx:47
msgid "Open keywords filter"
msgstr "Åbn søgeordsfilter"

#: src/Components/Filters/AddFilter/component.tsx:48
msgid "Open domains filter"
msgstr "Åbn domænefilter"

#: src/Components/Filters/AddFilter/component.tsx:98
#: src/Components/Modal/Content/Shortcuts/index.tsx:39
msgid "Open all filters"
msgstr "Åbn alle filtre"

#: src/Components/Filters/AddFilter/component.tsx:115
#: src/Components/Modal/Layout/ModalBorder/index.tsx:103
msgid "Close this modal"
msgstr "Luk dette vindue"

#: src/Components/Filters/AddFilter/component.tsx:152
msgid "Select a type of filter"
msgstr "Vælg filtertype"

#: src/Components/Filters/AddFilter/component.tsx:155
msgid "No filter matches your search"
msgstr "Ingen filtre matcher søgningen"

#: src/Components/Filters/Common/ColumnChecklistEditor/index.tsx:85
msgid "Search SERP features…"
msgstr "Søg i SERP-elementer…"

#: src/Components/Filters/Common/DateEditor/index.tsx:19
#: src/Components/Filters/Editors/Date/dataFactory.ts:18
#: src/Components/Filters/Editors/SignedUp/data.ts:18
msgid "After"
msgstr "Efter"

#: src/Components/Filters/Common/DateEditor/index.tsx:24
#: src/Components/Filters/Editors/Date/dataFactory.ts:19
#: src/Components/Filters/Editors/SignedUp/data.ts:19
msgid "Before"
msgstr "Før"

#: src/Components/Filters/Common/DateEditor/index.tsx:29
#: src/Components/Filters/Editors/Date/dataFactory.ts:16
#: src/Components/Filters/Editors/SignedUp/data.ts:16
msgid "On"
msgstr "På"

#: src/Components/Filters/Common/DateEditor/index.tsx:34
#: src/Components/Filters/Common/NumberEditor/InputField.tsx:78
#: src/Components/Filters/Editors/Date/dataFactory.ts:17
#: src/Components/Filters/Editors/SignedUp/data.ts:17
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:60
msgid "Between"
msgstr "Mellem"

#: src/Components/Filters/Common/labelFunc.ts:48
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:45
#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/KeywordSearch.tsx:59
msgid "Equals"
msgstr "Lig med"

#: src/Components/Filters/Common/labelFunc.ts:49
msgid "Greater than"
msgstr "Større end"

#: src/Components/Filters/Common/labelFunc.ts:50
msgid "Greater than or equal"
msgstr "Større end eller lig med"

#: src/Components/Filters/Common/labelFunc.ts:51
msgid "Less than"
msgstr "Mindre end"

#: src/Components/Filters/Common/labelFunc.ts:52
msgid "Less than or equal"
msgstr "Mindre end eller lig med"

#: src/Components/Filters/Common/labelFunc.ts:53
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:46
#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/KeywordSearch.tsx:60
msgid "Not equals"
msgstr "Ikke ens"

#: src/Components/Filters/Common/labelFunc.ts:54
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:44
#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/KeywordSearch.tsx:58
msgid "Contains"
msgstr "Indeholder"

#: src/Components/Filters/Common/labelFunc.ts:55
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:47
#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/KeywordSearch.tsx:61
msgid "Not contains"
msgstr "Indeholder ikke"

#: src/Components/Filters/Common/labelFunc.ts:56
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:48
#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/KeywordSearch.tsx:62
msgid "Starts with"
msgstr "Begynder med"

#: src/Components/Filters/Common/labelFunc.ts:57
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:49
#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/KeywordSearch.tsx:63
msgid "Ends with"
msgstr "Slutter med"

#: src/Components/Filters/Common/labelFunc.ts:58
msgid "All of the selected"
msgstr "Alle de valgte"

#: src/Components/Filters/Common/labelFunc.ts:59
msgid "Any of the selected"
msgstr "Nogen af de valgte"

#: src/Components/Filters/Common/labelFunc.ts:60
msgid "None of the selected"
msgstr "Ingen af de valgte"

#: src/Components/Filters/Common/labelFunc.ts:61
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:50
#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/KeywordSearch.tsx:64
msgid "RegEx"
msgstr "RegEx"

#: src/Components/Filters/Common/labelFunc.ts:62
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:51
#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/KeywordSearch.tsx:65
msgid "Not RegEx"
msgstr "Ikke RegEx"

#: src/Components/Filters/Common/labelFunc.ts:66
#: src/Components/Filters/Editors/HighestRankingPageMatch/data.tsx:23
msgid "Correct URL"
msgstr "Korrekt URL"

#: src/Components/Filters/Common/labelFunc.ts:67
#: src/Components/Filters/Editors/HighestRankingPageMatch/data.tsx:28
msgid "Incorrect URL"
msgstr "Inkorrekt URL"

#: src/Components/Filters/Common/labelFunc.ts:68
#: src/Components/Filters/Editors/HighestRankingPageMatch/data.tsx:33
msgid "Preferred URL not set"
msgstr "Foretrukket URL ikke indstillet"

#: src/Components/Filters/Common/labelFunc.ts:93
#: src/Components/Filters/Common/labelFunc.ts:113
msgid "Not a string filter"
msgstr "Ugyldigt tekstfilter"

#: src/Components/Filters/Common/labelFunc.ts:98
msgid "Between %s and %s"
msgstr "Mellem %s og %s"

#: src/Components/Filters/Common/labelFunc.ts:105
msgid "Not numeric filter"
msgstr "Ugyldigt numerisk filter"

#: src/Components/Filters/Common/NumberChangeEditor/InputField.tsx:80
#: src/Components/Filters/Common/NumberEditor/InputField.tsx:86
msgid "No filter"
msgstr "Intet filter"

#: src/Components/Filters/Common/NumberEditor/index.tsx:8
#: src/Components/Filters/Common/NumberEditor/index.tsx:16
#: src/Utilities/validation.ts:87 src/Utilities/validation.ts:91
#: src/Utilities/validation.ts:145 src/Utilities/validation.ts:149
#: src/Utilities/validation.ts:151 src/Utilities/validation.ts:154
msgid "This field is required"
msgstr "Dette felt skal udfyldes"

#: src/Components/Filters/Common/NumberEditor/index.tsx:20
msgid "Right should be >= left"
msgstr "Højre skal være større eller lig med venstre"

#: src/Components/Filters/Common/NumberEditor/InputField.tsx:63
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:61
msgid "Equal ="
msgstr "Lig med"

#: src/Components/Filters/Common/NumberEditor/InputField.tsx:68
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:63
msgid "Less than <"
msgstr "Mindre end"

#: src/Components/Filters/Common/NumberEditor/InputField.tsx:73
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:64
msgid "Greater than >"
msgstr "Større end"

#: src/Components/Filters/Common/NumberEditor/InputField.tsx:94
#: src/Components/Filters/Editors/LocalRank/data.ts:23
msgid "No value"
msgstr "Ingen værdi"

#: src/Components/Filters/Common/StringEditor/index.tsx:50
#: src/Components/Filters/Common/StringEditor/index.tsx:74
msgid "Reset filter"
msgstr "Nulstil filter"

#: src/Components/Filters/Editors/AboveTheFold/data.ts:20
#: src/Components/Filters/Editors/AboveTheFold/hooks.ts:20
#: src/Pages/Domains/CardView/components/DomainCard/components/DomainKpi.tsx:93
#: src/Pages/Domains/CardView/support/getSorting.ts:35
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:652
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:654
#: src/Pages/KeywordDiscovery/components/KpiBar/index.tsx:54
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:660
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:662
#: src/Pages/Keywords/Overview/components/KpiBar/Boxes/AboveTheFold.tsx:19
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:65
msgid "Above the Fold"
msgstr "Over folden"

#: src/Components/Filters/Editors/AboveTheFold/hooks.ts:25
msgid "Below the Fold"
msgstr "Ikke over folden"

#: src/Components/Filters/Editors/AdCompetition/data.ts:23
#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/support/constants.tsx:144
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:724
msgid "Ad Competition"
msgstr "Ann.-konkurrence"

#: src/Components/Filters/Editors/AiSearchVolume/data.ts:19
msgid "AI Search volume"
msgstr "AI Søgevolume"

#: src/Components/Filters/Editors/AiTrafficValue/data.ts:23
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:157
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:475
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:476
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:464
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:465
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:103
#: src/Pages/Keywords/Overview/components/HistoryCharts/AiTrafficValue/helpers.ts:11
#: src/Pages/Keywords/Overview/components/HistoryCharts/AiTrafficValue/TrafficValue.tsx:54
#: src/Pages/Keywords/Overview/components/HistoryCharts/AiTrafficValue/TrafficValue.tsx:79
#: src/Pages/Keywords/Overview/components/KpiBar/Boxes/AiTrafficValue.tsx:20
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:63
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:473
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:553
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:555
msgid "AI Traffic Value"
msgstr "AI Trafikværdi"

#: src/Components/Filters/Editors/AiTrafficValueChange/data.ts:17
msgid "AI Traffic Value change"
msgstr "AI Trafikværdisænding"

#: src/Components/Filters/Editors/Alphabet/data.tsx:20
msgid "Alphabet"
msgstr "Alfabet"

#: src/Components/Filters/Editors/Alphabet/getItems.ts:8
msgid "Latin"
msgstr "Latin"

#: src/Components/Filters/Editors/Alphabet/getItems.ts:14
msgid "Arabic"
msgstr "Arabisk"

#: src/Components/Filters/Editors/Alphabet/getItems.ts:20
msgid "Cyrillic"
msgstr "Kyrillisk"

#: src/Components/Filters/Editors/Alphabet/getItems.ts:26
msgid "Devanagari"
msgstr "Devanagari"

#: src/Components/Filters/Editors/Alphabet/getItems.ts:32
msgid "Hangul"
msgstr "Hangul"

#: src/Components/Filters/Editors/Alphabet/getItems.ts:38
msgid "Han"
msgstr "Han"

#: src/Components/Filters/Editors/Alphabet/getItems.ts:44
msgid "Hiragana"
msgstr "Hiragana"

#: src/Components/Filters/Editors/Alphabet/getItems.ts:50
msgid "Katakana"
msgstr "Katakana"

#: src/Components/Filters/Editors/BaseRank/data.ts:18
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:71
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:68
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:391
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:437
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:439
msgid "Base Rank"
msgstr "Rå Placering"

#: src/Components/Filters/Editors/BaseRankChange/data.ts:17
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:473
msgid "Base Rank change"
msgstr "Rå Placeringsændring"

#: src/Components/Filters/Editors/Campaigns/data.ts:16
msgid "Campaigns"
msgstr "Kampagner"

#: src/Components/Filters/Editors/Campaigns/data.ts:20
#: src/Components/Filters/Editors/Placements/data.ts:20
#: src/Components/Filters/Editors/Placements/index.tsx:37
msgid "No placement"
msgstr "Ingen placering"

#: src/Components/Filters/Editors/Campaigns/index.tsx:36
msgid "Enter campaigns"
msgstr "Indtast kampagner"

#: src/Components/Filters/Editors/Campaigns/index.tsx:37
msgid "No campaign"
msgstr "Ingen kampagne"

#: src/Components/Filters/Editors/Clicks/data.ts:18
#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:182
msgid "Clicks"
msgstr "Kliks"

#: src/Components/Filters/Editors/Clients/ClientsInput/index.tsx:69
msgid "Enter group names"
msgstr "Indtast gruppenavne"

#: src/Components/Filters/Editors/CompetitorDomain/data.tsx:20
#: src/Components/Filters/Editors/CompetitorDomain/data.tsx:30
msgid "Dynamic Competitor Domain"
msgstr "Dynamisk Konkurrent Domæne"

#: src/Components/Filters/Editors/CompetitorDomain/data.tsx:24
msgid "Enter Domain"
msgstr "Indtast Domæne"

#: src/Components/Filters/Editors/CompetitorUrl/data.tsx:26
#: src/Components/Filters/Editors/CompetitorUrl/data.tsx:36
msgid "Dynamic Competitor URL"
msgstr "Dynamisk konkurrent URL"

#: src/Components/Filters/Editors/CompetitorUrl/data.tsx:30
#: src/Components/Filters/Editors/HighestRankingPage/data.ts:32
msgid "Enter URL"
msgstr "Indtast URL"

#: src/Components/Filters/Editors/CountryLocale/data.tsx:17
#: src/Components/Forms/SearchEngine/index.tsx:176
msgid "Country locale"
msgstr "Område"

#: src/Components/Filters/Editors/CountryLocale/index.tsx:33
msgid "Select locale"
msgstr "Vælg søgeområde"

#: src/Components/Filters/Editors/CountryName/data.ts:17
#: src/Components/Modal/Content/SupportedCountriesList/index.tsx:50
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:44
#: src/Hooks/useLocalSearch.ts:13
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:242
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:247
#: src/Pages/Billing/CompanyInfoWidget/index.tsx:381
#: src/Pages/Domains/TreeView/support/constants.ts:45
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:145
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:147
#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:135
#: src/Pages/KeywordDiscovery/components/KeydisCountrySelect/KeydisCountrySelect.tsx:75
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:329
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:351
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:356
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:103
#: src/Pages/Register/components/AddressInfo.tsx:85
#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:256
#: src/Pages/SelectPlan/plan-features.tsx:29
msgid "Country"
msgstr "Land"

#: src/Components/Filters/Editors/CountryName/data.ts:21
#: src/Components/Filters/Editors/Date/dataFactory.ts:34
msgid "Enter the country"
msgstr "Indtast land"

#: src/Components/Filters/Editors/CPC/data.ts:18
#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:165
#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/support/constants.tsx:132
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:712
#: src/Pages/Keywords/Table/keydisCells.ts:188
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:877
msgid "CPC"
msgstr "CPC"

#: src/Components/Filters/Editors/Date/dataFactory.ts:24
#: src/Components/Modal/Content/AddNote/AddNoteForm/index.tsx:45
#: src/Components/Modal/Content/EditNote/EditNoteForm/index.tsx:138
#: src/Components/Modal/Content/Messages/index.tsx:60
#: src/Components/Modal/Content/SalesOrganizationAbuseLog/index.tsx:39
#: src/Components/PeriodFilter/CompareToContent/index.tsx:94
#: src/Pages/AccuApi/SearchHistoryTable/index.tsx:81
#: src/Pages/Affiliate/AffiliateCommissions/AffiliateCommissionsTable/index.tsx:72
#: src/Pages/Affiliate/AffiliatePayments/AffiliatePaymentsTable/index.tsx:80
#: src/Pages/Import/importTable.tsx:185 src/Pages/Invoices/InvoicesTable.tsx:23
#: src/Pages/KeywordOverusePage/components/OveruseTable/support/hooks.tsx:71
#: src/Pages/Keywords/Overview/components/HistoryCharts/LocalPackRankNeighborly/table.tsx:33
#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/table.tsx:21
#: src/Pages/Keywords/Overview/components/OverviewNotifications/support/hooks/table.tsx:144
#: src/Pages/Keywords/Overview/components/SearchTrendNeighborly/table.tsx:33
#: src/Pages/ReportsOverview/GeneratedReports/helpers/hooks.tsx:117
#: src/Pages/Sales/Organization/index.tsx:642
#: src/Pages/Sales/Organization/index.tsx:774
#: src/Pages/Sales/Organization/index.tsx:800
#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:113
#: src/Pages/Wallet/WalletsTable.tsx:21
msgid "Date"
msgstr "Dato"

#: src/Components/Filters/Editors/DisplayName/data.ts:17
#: src/Pages/DomainSettings/components/Menu/general.tsx:19
msgid "Display name"
msgstr "Visningsnavn"

#: src/Components/Filters/Editors/DisplayName/data.ts:21
msgid "Enter the display name"
msgstr "Indtast visningsnavnet"

#: src/Components/Filters/Editors/Domain/data.ts:20
msgid "Enter the domain name"
msgstr "Indtast domænet"

#: src/Components/Filters/Editors/Domain/data.ts:22
msgid "contains %s"
msgstr "indeholder %s"

#: src/Components/Filters/Editors/Domains/DomainsInput/index.tsx:46
msgid "Enter domains"
msgstr "Indtast domæner"

#: src/Components/Filters/Editors/Domains/DomainsLabel/index.tsx:46
msgid "Domain not on this account"
msgstr "Domænet er ikke på denne konto"

#: src/Components/Filters/Editors/DynamicCtrChange/data.ts:17
msgid "CTR change"
msgstr "CTR-ændring"

#: src/Components/Filters/Editors/DynamicCtrMax/data.ts:23
#: src/Pages/Domains/TreeView/support/constants.ts:115
#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/support/constants.tsx:168
#: src/Pages/Keywords/Groupings/support/constants.ts:135
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:664
msgid "Max CTR"
msgstr "Maks CTR"

#: src/Components/Filters/Editors/Folders/data.ts:18
msgid "Exact folder"
msgstr "Præcis mappe"

#: src/Components/Filters/Editors/Folders/data.ts:19
msgid "Folder or subfolder of"
msgstr "Mappe eller undermappe af"

#: src/Components/Filters/Editors/Folders/data.ts:23
msgid "Folders"
msgstr "Mapper"

#: src/Components/Filters/Editors/Folders/index.tsx:29
msgid "Select folder(s)"
msgstr "Vælg mappe(r)"

#: src/Components/Filters/Editors/GscPosition/data.ts:18
#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:233
msgid "Position"
msgstr "Position"

#: src/Components/Filters/Editors/HasExtraRanks/data.ts:20
msgid "Has extra ranks"
msgstr "Har ekstra placeringer"

#: src/Components/Filters/Editors/HasExtraRanks/hooks.ts:19
msgid "More than one rank"
msgstr "Mere end én placering"

#: src/Components/Filters/Editors/HasExtraRanks/hooks.ts:24
msgid "Zero or one rank"
msgstr "Nul eller én placering"

#: src/Components/Filters/Editors/HasGoogleAnalytics/data.ts:28
#: src/Pages/Integrations/hooks/useIntegrations.tsx:47
#: src/Pages/Keywords/Overview/components/OverviewActionBar.tsx:141
#: src/Pages/Sales/Organization/index.tsx:277
msgid "Google Analytics"
msgstr "Google Analytics"

#: src/Components/Filters/Editors/HasGoogleSearchConsole/data.ts:19
msgid "Has Google Search Console"
msgstr "Forbundet til Google Search Console"

#: src/Components/Filters/Editors/HasGoogleSearchConsole/data.ts:23
msgid "Does not have Google Search Console"
msgstr "Ikke forbundet til Google Search Console"

#: src/Components/Filters/Editors/HasGoogleSearchConsole/data.ts:28
#: src/Pages/Integrations/hooks/useIntegrations.tsx:117
#: src/Pages/Keywords/Overview/components/OverviewActionBar.tsx:151
#: src/Pages/Sales/Organization/index.tsx:282
#: src/Pages/SelectPlan/plan-features.tsx:93
msgid "Google Search Console"
msgstr "Google Search Console"

#: src/Components/Filters/Editors/helpers.ts:7
msgid "Moved up more than"
msgstr "Rykket op mere end"

#: src/Components/Filters/Editors/helpers.ts:8
msgid "Moved up by more than %s"
msgstr "Rykket op med mere end %s"

#: src/Components/Filters/Editors/helpers.ts:11
msgid "Moved down more than"
msgstr "Rykket ned mere end"

#: src/Components/Filters/Editors/helpers.ts:12
msgid "Moved down by more than %s"
msgstr "Rykket ned med mere end %s"

#: src/Components/Filters/Editors/helpers.ts:15
msgid "Moved up"
msgstr "Rykket op"

#: src/Components/Filters/Editors/helpers.ts:16
msgid "Moved up by %s or more"
msgstr "Rykket op med %s eller mere"

#: src/Components/Filters/Editors/helpers.ts:19
msgid "Moved down"
msgstr "Rykket ned"

#: src/Components/Filters/Editors/helpers.ts:20
msgid "Moved down by %s or more"
msgstr "Rykket ned med %s eller mere"

#: src/Components/Filters/Editors/helpers.ts:23
#: src/Components/Filters/Editors/helpers.ts:24
msgid "Did not change"
msgstr "Er uændret"

#: src/Components/Filters/Editors/HighestRankingPage/data.ts:28
#: src/Components/Filters/Editors/HighestRankingPage/data.ts:38
#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:127
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:93
#: src/Pages/Affiliate/AffiliateVisitors/AffiliateReferralsTable/index.tsx:39
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:310
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:312
#: src/Pages/AIOverview/components/UrlChart.tsx:64
#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/support/constants.tsx:96
#: src/Pages/Keywords/Overview/components/OverviewNotifications/support/hooks/table.tsx:136
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:602
#: src/Pages/Keywords/Table/keydisCells.ts:151
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:673
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:675
#: src/Pages/SelectPlan/plan-features.tsx:55
#: src/Pages/SiteMapping/components/CompetitorTable.tsx:66
#: src/Pages/SiteMapping/components/TreeTable/Header.tsx:121
msgid "URL"
msgstr "URL"

#: src/Components/Filters/Editors/HighestRankingPageMatch/data.tsx:39
msgid "Preferred URL match"
msgstr "Foretrukket URL matcher"

#: src/Components/Filters/Editors/Impressions/data.ts:18
#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:161
msgid "Impressions"
msgstr "Visninger"

#: src/Components/Filters/Editors/KeywordOptions/data.ts:19
msgid "Ignore local results"
msgstr "Ignorer lokale resultater"

#: src/Components/Filters/Editors/KeywordOptions/data.ts:31
msgid "Ignored domain"
msgstr "Ignoreret domæne"

#: src/Components/Filters/Editors/KeywordOptions/data.ts:40
msgid "Keyword Options"
msgstr "Søgeordsindstillinger"

#: src/Components/Filters/Editors/Keywords/data.ts:16
#: src/Components/Filters/Editors/KeywordsListSelect/data.tsx:40
msgid "Enter the keyword"
msgstr "Indtast søgeordet"

#: src/Components/Filters/Editors/Keywords/data.ts:20
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:23
#: src/Pages/AccuApi/SearchHistoryTable/index.tsx:94
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:187
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:189
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:24
#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:105
#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/support/constants.tsx:32
#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/support/hooks.tsx:43
#: src/Pages/Keywords/Overview/components/OverviewNotifications/support/hooks/table.tsx:89
#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:199
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:267
#: src/Pages/Keywords/Table/keydisCells.ts:62
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:233
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:235
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:63
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:68
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:70
#: src/Pages/SelectPlan/plan-features.tsx:55
msgid "Keyword"
msgstr "Søgeord"

#: src/Components/Filters/Editors/KeywordsList/index.tsx:33
msgid "Enter keywords"
msgstr "Indtast søgeord"

#: src/Components/Filters/Editors/KeywordsList/KeywordsInput/index.tsx:67
msgid "Fetching keywords"
msgstr "Henter søgeord"

#: src/Components/Filters/Editors/KeywordsList/KeywordsInput/index.tsx:68
#: src/Components/Modal/Content/CreateTagModal/CreateOrEditStaticTagModal/index.tsx:133
msgid "Type to search for keywords"
msgstr "Skriv for at søge efter søgeord"

#: src/Components/Filters/Editors/KeywordsListSelect/data.tsx:31
msgid "Keyword is one of "
msgstr "Søgeord er et af "

#: src/Components/Filters/Editors/KeywordsListSelect/data.tsx:36
#: src/Components/Filters/Editors/KeywordsListSelect/data.tsx:44
msgid "Multiple Keywords"
msgstr "Flere søgeord"

#: src/Components/Filters/Editors/LandingPages/data.tsx:17
msgid "Preferred URL"
msgstr "Foretrukket URL"

#: src/Components/Filters/Editors/LandingPages/LandingPagesInput/index.tsx:64
msgid "Fetching pages"
msgstr "Henter sider"

#: src/Components/Filters/Editors/LandingPages/LandingPagesInput/index.tsx:65
msgid "Enter preferred URL"
msgstr "Indtast foretrukket URL"

#: src/Components/Filters/Editors/LocalRank/data.ts:26
msgid "Not a no-value filter"
msgstr "Ugyldigt ikke-numerisk filter"

#: src/Components/Filters/Editors/LocalRank/data.ts:40
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:79
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:442
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:508
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:516
msgid "Local Pack Rank"
msgstr "Lokal Placering"

#: src/Components/Filters/Editors/LocalRankChange/data.ts:17
msgid "Local Pack Rank change"
msgstr "Lokal placeringsændring"

#: src/Components/Filters/Editors/Location/data.ts:18
#: src/Components/Filters/Editors/Location/data.ts:27
#: src/Components/Modal/Content/AddSearchEngine/index.tsx:104
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:50
#: src/Components/Modal/Wizard/ConnectToBigQuery/ConnectBigQuery/InputLocation.tsx:25
#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/support/hooks.tsx:85
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:317
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:366
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:368
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:110
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:115
msgid "Location"
msgstr "Lokation"

#: src/Components/Filters/Editors/Location/data.ts:22
#: src/Components/Filters/Editors/Location/index.tsx:32
msgid "Select location"
msgstr "Vælg lokation"

#: src/Components/Filters/Editors/MaxAiSov/data.ts:18
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:136
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:859
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:866
msgid "Max AI Share of Voice"
msgstr "Max AI Synlighed"

#: src/Components/Filters/Editors/MaxAiTrafficValue/data.ts:23
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:167
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:598
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:606
msgid "Max AI Traffic Value"
msgstr "Max AI Trafikværdi"

#: src/Components/Filters/Editors/MaxRank/data.ts:18
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:74
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:423
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:485
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:494
msgid "Max Base Rank"
msgstr "Max Rå Placering"

#: src/Components/Filters/Editors/MaxSov/data.ts:18
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:146
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:950
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:957
msgid "Max Share of Voice"
msgstr "Max Synlighed"

#: src/Components/Filters/Editors/MaxTrafficValue/data.ts:23
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:177
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:530
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1008
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1015
msgid "Max Traffic Value"
msgstr "Max Trafikværdi"

#: src/Components/Filters/Editors/Name/data.ts:25
#: src/Components/Filters/Editors/UserName/data.ts:25
#: src/Components/Modal/Content/AddEditWorkspaceForm/index.tsx:67
#: src/Components/Modal/Wizard/ConnectToAdobe/ConnectAdobeAccount/index.tsx:92
#: src/Pages/IntegrationApi/IntegrationAPIsTable/index.tsx:50
#: src/Pages/ReportsOverview/ScheduledReports/helpers/hooks.tsx:150
#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:210
#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:113
#: src/Pages/Sales/CreatePlan/SalesPlanChoicesTable/index.tsx:46
#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:200
#: src/Pages/Sales/Invoice/Customers/SalesInvoiceCustomersTable/hooks.tsx:85
#: src/Pages/Sales/Organization/index.tsx:325
#: src/Pages/Sales/Organization/index.tsx:428
#: src/Pages/Sales/Plans/SalesPlansTable/support/hooks.tsx:52
#: src/Pages/Sales/Search/index.tsx:270
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:444
#: src/Pages/Users/UsersTable/getColumns.tsx:34
#: src/Pages/Workspaces/WorkspacesTable/getColumns.tsx:16
msgid "Name"
msgstr "Navn"

#: src/Components/Filters/Editors/Name/data.ts:29
msgid "Enter the name"
msgstr "Indtast navnet"

#: src/Components/Filters/Editors/Note/data.ts:25
#: src/Components/Modal/Content/AddNote/AddNoteForm/index.tsx:36
#: src/Components/Modal/Content/EditNote/EditNoteForm/index.tsx:129
#: src/Components/Modal/Content/Sales/OrganizationNote/OrgNoteForm/OrgNoteForm.tsx:64
#: src/Pages/NotesList/NotesTable/support/useNotesTableInfo.tsx:138
#: src/Pages/Sales/Organization/Components/NotesPanel/index.tsx:84
msgid "Note"
msgstr "Notat"

#: src/Components/Filters/Editors/Note/data.ts:29
#: src/Components/Modal/Content/AddNote/AddNoteForm/index.tsx:38
#: src/Components/Modal/Content/EditNote/EditNoteForm/index.tsx:132
msgid "Enter note"
msgstr "Indtast notat"

#: src/Components/Filters/Editors/PageSerpFeatures/data.ts:30
msgid "Google / Naver"
msgstr "Google / Naver"

#: src/Components/Filters/Editors/PageSerpFeatures/data.ts:30
#: src/Components/Filters/Editors/PageSerpFeaturesOwned/data.ts:26
#: src/Components/Filters/Editors/SearchEngine/hooks.ts:26
#: src/Components/Table/TableRow/RankOptions/index.tsx:38
#: src/Pages/SelectPlan/plan-features.tsx:21
#: src/Pages/SelectPlan/plan-features.tsx:68
msgid "Google"
msgstr "Google"

#: src/Components/Filters/Editors/PageSerpFeatures/data.ts:42
#: src/Components/Filters/Editors/PageSerpFeaturesOwned/data.ts:40
#: src/Components/Filters/Editors/SearchEngine/hooks.ts:38
#: src/Components/Table/TableRow/RankOptions/index.tsx:54
msgid "YouTube"
msgstr "YouTube"

#: src/Components/Filters/Editors/PageSerpFeatures/data.ts:55
#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:219
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:117
#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/SERPFeatures.tsx:102
#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/SERPFeatures.tsx:120
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1026
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1028
msgid "SERP Features"
msgstr "SERP Elementer"

#: src/Components/Filters/Editors/PageSerpFeaturesOwned/data.ts:55
msgid "SERP Features owned"
msgstr "Ejede SERP-elementer"

#: src/Components/Filters/Editors/PageSerpFeaturesRank/data.ts:18
msgid "SERP feature rank"
msgstr "SERP-elementplacering"

#: src/Components/Filters/Editors/PixelsFromTop/data.ts:23
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:105
msgid "Pixels From Top"
msgstr "Pixels fra Top"

#: src/Components/Filters/Editors/PixelsFromTopChange/data.ts:23
msgid "Pixels From Top Change"
msgstr "Pixels fra Top, ændring"

#: src/Components/Filters/Editors/Placements/data.ts:16
msgid "Placements"
msgstr "Placeringer"

#: src/Components/Filters/Editors/Placements/index.tsx:36
msgid "Enter placements"
msgstr "Indtast placeringer"

#: src/Components/Filters/Editors/Rank/data.ts:19
#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:118
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:59
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:63
#: src/Hooks/data/metrics/useMetrics.ts:30
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:258
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:260
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:39
#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/support/constants.tsx:63
#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/support/hooks.tsx:110
#: src/Pages/Keywords/Overview/components/OverviewNotifications/support/hooks/table.tsx:113
#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:224
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:359
#: src/Pages/Keywords/Table/keydisCells.ts:119
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:393
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:395
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:122
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:135
#: src/Pages/SelectPlan/plan-features.tsx:55
msgid "Rank"
msgstr "Placering"

#: src/Components/Filters/Editors/RankChange/data.ts:17
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:300
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:427
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:150
msgid "Rank change"
msgstr "Placeringsændring"

#: src/Components/Filters/Editors/SalesManager/data.tsx:26
#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:241
#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:137
msgid "Sales Manager"
msgstr "Salgsmanager"

#: src/Components/Filters/Editors/SalesManager/data.tsx:30
msgid "Enter the name of the Sales Manager"
msgstr "Indtast navnet på Salgsmanageren"

#: src/Components/Filters/Editors/SearchEngine/data.ts:20
msgid "Search engine"
msgstr "Søgemaskine"

#: src/Components/Filters/Editors/SearchEngine/hooks.ts:32
#: src/Components/Table/TableRow/RankOptions/index.tsx:42
#: src/Pages/SelectPlan/plan-features.tsx:21
#: src/Pages/SelectPlan/plan-features.tsx:68
msgid "Bing"
msgstr "Bing"

#: src/Components/Filters/Editors/SearchEngine/hooks.ts:44
#: src/Components/Table/TableRow/RankOptions/index.tsx:50
msgid "Baidu"
msgstr "Baidu"

#: src/Components/Filters/Editors/SearchEngine/hooks.ts:50
#: src/Components/Table/TableRow/RankOptions/index.tsx:58
msgid "Naver"
msgstr "Naver"

#: src/Components/Filters/Editors/SearchIntent/data.tsx:21
#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/support/constants.tsx:44
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:292
msgid "Search intent"
msgstr "Søgeintention"

#: src/Components/Filters/Editors/SearchIntent/getItems.tsx:15
#: src/icons/search-intent/searchIntentIcon.tsx:36
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/SearchIntentCell.tsx:22
msgid "Informational"
msgstr "Informational"

#: src/Components/Filters/Editors/SearchIntent/getItems.tsx:20
#: src/icons/search-intent/searchIntentIcon.tsx:35
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/SearchIntentCell.tsx:21
msgid "Navigational"
msgstr "Navigational"

#: src/Components/Filters/Editors/SearchIntent/getItems.tsx:25
#: src/icons/search-intent/searchIntentIcon.tsx:34
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/SearchIntentCell.tsx:20
msgid "Commercial"
msgstr "Commercial"

#: src/Components/Filters/Editors/SearchIntent/getItems.tsx:30
#: src/icons/search-intent/searchIntentIcon.tsx:33
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/SearchIntentCell.tsx:19
msgid "Transactional"
msgstr "Transactional"

#: src/Components/Filters/Editors/SearchType/data.ts:20
msgid "Device type"
msgstr "Enhedstype"

#: src/Components/Filters/Editors/ShareOfVoiceChange/data.ts:18
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:935
msgid "Share of Voice change"
msgstr "Ændring i synlighed"

#: src/Components/Filters/Editors/SignedUp/data.ts:24
msgid "SignedUp"
msgstr "Oprettede sig"

#: src/Components/Filters/Editors/Starred/data.tsx:21
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:232
msgid "Starred"
msgstr "Stjernemarkeret"

#: src/Components/Filters/Editors/Starred/getItems.tsx:21
msgid "Is starred"
msgstr "Er stjernemarkeret"

#: src/Components/Filters/Editors/Starred/getItems.tsx:35
msgid "Is not starred"
msgstr "Er ikke stjernemarkeret"

#: src/Components/Filters/Editors/Subdomain/data.ts:20
msgid "Subdomain"
msgstr "Underdomæne"

#: src/Components/Filters/Editors/Subdomain/index.tsx:24
msgid ""
"Subdomains not enabled on this domain. To enable subdomains go to:[br][br]"
"[link:Settings] > Edit domain > Show advanced settings > Include subdomains"
msgstr ""
"Underdomæner er ikke aktiveret på dette domæne. For at aktivere "
"underdomæner, gå til:[br][br][link:Indstillinger] > Rediger domæne > Vis "
"avancerede indstillinger > Inkluder underdomæner"

#: src/Components/Filters/Editors/Tags/data.ts:26
msgid "Keywords with the tag: %s"
msgstr "Søgeord med tagget: %s"

#: src/Components/Filters/Editors/Tags/data.ts:30
msgid "Keywords with all of the tags: %s"
msgstr "Søgeord med alle tags: %s"

#: src/Components/Filters/Editors/Tags/data.ts:30
#: src/Components/Filters/Editors/Tags/data.ts:33
#: src/Components/Filters/Editors/Tags/data.ts:39
msgid "', '"
msgstr "', '"

#: src/Components/Filters/Editors/Tags/data.ts:33
msgid "Keywords with any of the tags: %s"
msgstr "Søgeord med et af tagsene: %s"

#: src/Components/Filters/Editors/Tags/data.ts:37
msgid "Keywords without the tag: %s"
msgstr "Søgeord uden tagget: %s"

#: src/Components/Filters/Editors/Tags/data.ts:39
msgid "Keywords with none of the tags: %s"
msgstr "Søgeord med ingen af tagsne: %s"

#: src/Components/Filters/Editors/Tags/data.ts:42
msgid "Keywords with no tags"
msgstr "Søgeord uden tags"

#: src/Components/Filters/Editors/Tags/index.tsx:21
msgid "Any of the selected tags"
msgstr "Mindst én af de valgte tags"

#: src/Components/Filters/Editors/Tags/index.tsx:25
msgid "All of the selected tags"
msgstr "Alle de valgte tags"

#: src/Components/Filters/Editors/Tags/index.tsx:29
msgid "None of the selected tags"
msgstr "Ingen af de valgte tags"

#: src/Components/Filters/Editors/Tags/index.tsx:33
#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/support/helpers.ts:15
msgid "No tags"
msgstr "Ingen tags"

#: src/Components/Filters/Editors/Tags/index.tsx:47
msgid "Enter the tags"
msgstr "Indtast tags"

#: src/Components/Filters/Editors/TopCompetitor/components/TopCompetitorFieldInput.tsx:52
msgid "Type to search..."
msgstr "Skriv for at søge..."

#: src/Components/Filters/Editors/TopCompetitor/data.ts:17
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:194
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:618
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:624
msgid "Top Competitor"
msgstr "Top Konkurrent"

#: src/Components/Filters/Editors/TopCompetitor/data.ts:21
msgid "Local Result"
msgstr "Lokalresultat"

#: src/Components/Filters/Editors/TopCompetitor/data.ts:21
msgid "' or '"
msgstr "' eller '"

#: src/Components/Filters/Editors/Traffic/data.ts:23
#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:21
#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:208
#: src/Pages/Domains/TreeView/support/constants.ts:68
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:219
#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/support/constants.tsx:180
#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:192
#: src/Pages/Keywords/Competitors/components/CompetitorChartContent/support/helpers.ts:29
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:144
#: src/Pages/Keywords/Competitors/support/constants.ts:77
#: src/Pages/Keywords/Groupings/support/constants.ts:88
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:252
#: src/Pages/Keywords/LandingPage/components/KeywordLandingChart/support/helpers.ts:36
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/constants.ts:71
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/hooks.tsx:244
#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:249
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:679
#: src/Pages/Keywords/Table/keydisCells.ts:225
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:813
#: src/Pages/SiteMapping/components/CompetitorTable.tsx:70
#: src/Pages/SiteMapping/components/TreeTable/Header.tsx:74
msgid "AI SoV"
msgstr "AI synlighed"

#: src/Components/Filters/Editors/TrafficValue/data.ts:23
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:153
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:174
#: src/Hooks/data/metrics/useMetrics.ts:47
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:522
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:523
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:507
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:508
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:100
#: src/Pages/Keywords/Overview/components/HistoryCharts/TrafficValue/helpers.ts:11
#: src/Pages/Keywords/Overview/components/HistoryCharts/TrafficValue/TrafficValue.tsx:49
#: src/Pages/Keywords/Overview/components/HistoryCharts/TrafficValue/TrafficValue.tsx:60
#: src/Pages/Keywords/Overview/components/KpiBar/Boxes/TrafficValue.tsx:20
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:62
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:778
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:968
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:972
msgid "Traffic Value"
msgstr "Trafikværdi"

#: src/Components/Filters/Editors/TrafficValueChange/data.ts:17
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:996
msgid "Traffic Value change"
msgstr "Trafikværdiændring"

#: src/Components/Filters/Editors/UniqueIds/data.ts:16
msgid "Unique IDs"
msgstr "Unikke ID'er"

#: src/Components/Filters/Editors/UniqueIds/data.ts:20
#: src/Components/Filters/Editors/UniqueIds/index.tsx:37
msgid "No unique ID"
msgstr "Intet unikt ID"

#: src/Components/Filters/Editors/UniqueIds/index.tsx:36
msgid "Enter unique IDs"
msgstr "Indtast unikke ID'er"

#: src/Components/Filters/Editors/UserName/data.ts:29
msgid "Enter the users name"
msgstr "Indtast brugerens navn"

#: src/Components/Filters/FilterEditorForm/index.tsx:42
#: src/Components/Modal/Content/AddWalletItem/AddWalletItemForm/index.tsx:74
#: src/Components/Modal/Content/Sales/OrganizationNote/OrgNoteForm/OrgNoteForm.tsx:69
#: src/Pages/Keywords/Groupings/EditMode/components/NodeItem/EditNodeInput.tsx:109
#: src/Pages/Layout/ActionsMenu/Actions/index.tsx:78
#: src/Pages/ReportTemplateBuilder/ElementTypes/ElementType/index.tsx:31
msgid "Add"
msgstr "Tilføj"

#: src/Components/Filters/FilterEditorForm/index.tsx:42
#: src/Components/Modal/Content/TableSettingsModal/components/TableSettingsForm/TableSettingsForm.tsx:58
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/NextTableSettingsForm.tsx:105
#: src/Components/PeriodFilter/CompareToContent/index.tsx:174
#: src/Pages/Billing/UpdatePaymentInfo/index.tsx:95
#: src/Pages/Billing/UpdatePaymentMethod/UpdatePaymentMethodWidget/index.tsx:149
#: src/Pages/Keywords/Groupings/EditMode/components/NodeItem/EditNodeInput.tsx:109
#: src/Pages/Layout/ActionsMenu/Actions/index.tsx:59
#: src/Pages/Profile/components/ChangePassword/index.tsx:89
#: src/Pages/Profile/components/EditProfile/index.tsx:274
#: src/Pages/Sales/Organization/index.tsx:748
msgid "Update"
msgstr "Opdater"

#: src/Components/Filters/FilterEditorForm/index.tsx:44
msgid "Clear"
msgstr "Nulstil"

#: src/Components/Filters/FilterItem/component.tsx:63
msgid "This filter is not applicable for this tab"
msgstr "Dette filter er ikke relevant for denne side"

#: src/Components/Filters/FilterItem/component.tsx:67
msgid "Clear the filter"
msgstr "Nulstil filteret"

#: src/Components/Filters/FilterItem/index.tsx:52
msgid "Delete Filter?"
msgstr "Slet filter?"

#: src/Components/Filters/FilterItem/index.tsx:54
msgid "The filter and current segment will be permanently deleted."
msgstr "Filteret og det nuværende segmentet bliver permanent slettet."

#: src/Components/Filters/FilterItem/index.tsx:56
#: src/Components/Forms/EditForm/index.tsx:59
#: src/Components/Forms/SearchEngine/index.tsx:294
#: src/Components/Table/TableRow/ReportActionCell/index.tsx:31
#: src/Components/Table/TableRow/ReportActionCell/index.tsx:67
#: src/Pages/ConnectedAccounts/support/hooks.tsx:356
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useTagActions.ts:91
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useTagActions.ts:123
#: src/Pages/Import/importTable.tsx:158
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:527
#: src/Pages/Keywords/Groupings/EditMode/components/DeleteFolderModal.tsx:44
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/usePathCellActions.ts:93
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/usePathCellActions.ts:124
#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingsBulkActions.tsx:82
#: src/Pages/Keywords/Table/Actions/MoreRemoveActions/index.tsx:52
#: src/Pages/PausedDomains/index.tsx:198
#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:65
#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:350
#: src/Pages/Sales/Organization/Components/NotesPanel/index.tsx:56
#: src/Pages/Sales/Organization/index.tsx:355
#: src/Pages/Users/UsersTable/index.tsx:149
#: src/Pages/Workspaces/WorkspacesTable/index.tsx:124
msgid "Delete"
msgstr "Slet"

#: src/Components/Filters/FilterItem/index.tsx:73
#: src/Components/Filters/FilterItem/index.tsx:82
msgid "Unable to delete filter"
msgstr "Kunne ikke slette filter"

#: src/Components/Filters/FilterItem/index.tsx:78
#: src/Components/Filters/SavedFilterGroups/SavedFilterGroup/index.tsx:100
msgid "Segment deleted"
msgstr "Segment slettet"

#: src/Components/Filters/FiltersShell.tsx:13
msgid "Filters are disabled for this page"
msgstr "Filtre er deaktiveret for denne side"

#: src/Components/Filters/getFilterData.ts:130
msgid "Date added"
msgstr "Dato tilføjet"

#: src/Components/Filters/ResetFilter/index.tsx:32
#: src/Components/Filters/ResetFilter/index.tsx:35
#: src/Components/TableEmptyState/index.tsx:81
msgid "Clear filters"
msgstr "Nulstil filtre"

#: src/Components/Filters/SavedFilterGroups/index.tsx:74
#: src/Components/Filters/SavedFilterGroups/index.tsx:84
msgid "Saved segments"
msgstr "Gemte segmenter"

#: src/Components/Filters/SavedFilterGroups/SavedFilterGroup/index.tsx:96
#: src/Components/Filters/SavedFilterGroups/SavedFilterGroup/index.tsx:104
msgid "Unable to delete segment"
msgstr "Kunne ikke slette segmentet"

#: src/Components/Filters/SavedFilterGroups/SavedFilterGroup/index.tsx:116
msgid "Delete Segment?"
msgstr "Slet segment?"

#: src/Components/Filters/SavedFilterGroups/SavedFilterGroup/index.tsx:118
msgid "The segment will be permanently deleted."
msgstr "Segmentet bliver permanent slettet."

#: src/Components/Filters/SavedFilterGroups/SavedFilterGroup/index.tsx:120
#: src/Components/Filters/SavedFilterGroups/SavedFilterGroup/index.tsx:220
msgid "Delete segment"
msgstr "Slet segment"

#: src/Components/Filters/SavedFilterGroups/SavedFilterGroup/index.tsx:181
#: src/Components/Filters/SavedFilterGroups/SavedFilterGroup/index.tsx:187
msgid "Unable to set default segment"
msgstr "Kunne ikke indstille standard-segmentet"

#: src/Components/Filters/SavedFilterGroups/SavedFilterGroup/index.tsx:203
msgid "Edit segment"
msgstr "Rediger segment"

#: src/Components/Filters/SavedFilterGroups/SavedFilterGroup/index.tsx:212
msgid "Disable default"
msgstr "Deaktiver standard"

#: src/Components/Filters/SavedFilterGroups/SavedFilterGroup/index.tsx:212
msgid "Enable default"
msgstr "Aktiver standard"

#: src/Components/Filters/SaveFilterGroup/index.tsx:44
#: src/Components/Filters/SaveFilterGroup/index.tsx:59
msgid "Failed to save segment"
msgstr "Kunne ikke gemme segment"

#: src/Components/Filters/SaveFilterGroup/index.tsx:75
#: src/Components/Filters/SaveFilterGroup/index.tsx:80
#: src/Components/Filters/SaveFilterGroup/SaveFilterForm/index.tsx:51
msgid "Save segment"
msgstr "Gem segment"

#: src/Components/Filters/SaveFilterGroup/SaveFilterForm/index.tsx:23
msgid "No filters added"
msgstr "Ingen filtre tilføjet"

#: src/Components/Filters/SaveFilterGroup/SaveFilterForm/index.tsx:29
msgid "Name can not be empty"
msgstr "Navn må ikke være blankt"

#: src/Components/Filters/SaveFilterGroup/SaveFilterForm/index.tsx:40
msgid "Name already taken"
msgstr "Navn allerede i brug"

#: src/Components/Filters/SaveFilterGroup/SaveFilterForm/index.tsx:57
#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:114
msgid "Enter name"
msgstr "Indtast navn"

#: src/Components/Filters/UpdateFilterGroup/index.tsx:47
msgid "Unable to update segment"
msgstr "Kunne ikke opdatere segmentet"

#: src/Components/Filters/UpdateFilterGroup/index.tsx:51
msgid "Segment updated"
msgstr "Segment opdateret"

#: src/Components/Filters/UpdateFilterGroup/index.tsx:61
msgid "Update Segment?"
msgstr "Opdater segment?"

#: src/Components/Filters/UpdateFilterGroup/index.tsx:62
msgid "The segment will be permanently updated."
msgstr "Segmentet bliver permanent opdateret."

#: src/Components/Filters/UpdateFilterGroup/index.tsx:63
#: src/Components/Filters/UpdateFilterGroup/index.tsx:77
#: src/Components/Filters/UpdateFilterGroup/index.tsx:80
msgid "Update segment"
msgstr "Opdater segment"

#: src/Components/Forms/SearchEngine/index.tsx:185
msgid "Search engines"
msgstr "Søgemaskiner"

#: src/Components/Forms/SearchEngine/index.tsx:215
msgid "Add search engine"
msgstr "Tilføj søgemaskine"

#: src/Components/Forms/SearchEngine/index.tsx:218
msgid "Locations"
msgstr "Lokationer"

#: src/Components/Forms/SearchEngine/index.tsx:240
msgid "Add location"
msgstr "Tilføj lokation"

#: src/Components/Forms/SearchEngine/index.tsx:268
msgid "Primary country"
msgstr "Primært land"

#: src/Components/HelpGuideButton/index.tsx:17
#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingViewModeActions.tsx:113
#: src/Pages/Keywords/Overview/components/OverviewActionBar.tsx:176
msgid "Help guide"
msgstr "Hjælpeguide"

#: src/Components/ImpersonateDropdown/ImpresonateSelect/ImpersonateSelect.tsx:75
msgid "Impersonate account"
msgstr "Log på kunde"

#: src/Components/ImpersonateDropdown/ImpresonateSelect/ImpersonateSelect.tsx:77
#: src/Components/LinkedAccountsDropdown/LinkedAccountSelect/LinkedAccountSelect.tsx:56
#: src/Components/Modal/Content/MoveDomain/MoveDomainForm/index.tsx:190
#: src/Components/Modal/Content/MoveDomain/MoveDomainForm/index.tsx:203
msgid "Type to search"
msgstr "Skriv for at søge"

#: src/Components/ImpersonateDropdown/StopImpersonating/index.tsx:30
#: src/Pages/Register/index.tsx:312
msgid "Stop impersonating"
msgstr "Bliv dig selv"

#: src/Components/ImporterStatus/index.tsx:32
#: src/Pages/Import/importer_helpers.ts:13
#: src/Pages/Import/Validation/index.tsx:99
msgid "Setup"
msgstr "Opsætning"

#: src/Components/ImporterStatus/index.tsx:35
#: src/Pages/Import/importer_helpers.ts:20
#: src/Pages/Import/Validation/index.tsx:100
#: src/Pages/Import/Validation/Status/validating.tsx:13
msgid "Validating"
msgstr "Validerer"

#: src/Components/ImporterStatus/index.tsx:38
#: src/Pages/Import/importer_helpers.ts:23
#: src/Pages/Import/Validation/index.tsx:101
msgid "Failed"
msgstr "Fejlet"

#: src/Components/ImporterStatus/index.tsx:41
msgid "Processing"
msgstr "Behandler"

#: src/Components/ImporterStatus/index.tsx:64
msgid "No failed imports for the selected date range"
msgstr "Ingen fejlede imports i den valgte periode"

#: src/Components/ImporterStatus/index.tsx:70
msgid "Customer"
msgstr "Kunde"

#: src/Components/ImporterStatus/index.tsx:71
msgid "Job"
msgstr "Job"

#: src/Components/ImporterStatus/index.tsx:72
#: src/Pages/Affiliate/AffiliateCustomers/AffiliateCustomersTable/index.tsx:103
#: src/Pages/Import/importTable.tsx:186
#: src/Pages/Sales/Invoice/Customers/SalesInvoiceCustomersTable/hooks.tsx:146
#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:499
msgid "Status"
msgstr "Status"

#: src/Components/ImporterStatus/index.tsx:73
#: src/Pages/Import/importTable.tsx:187
msgid "Progress"
msgstr "Fremskridt"

#: src/Components/IntegrationButtons/AnalyticsIntegrationButtons.tsx:75
#: src/Components/IntegrationButtons/BigQueryIntegrationButtons.tsx:57
#: src/Components/IntegrationButtons/SearchConsoleIntegrationButtons.tsx:44
#: src/Components/Modal/Wizard/ConnectToAdobe/index.tsx:49
#: src/Components/Modal/Wizard/ConnectToBigQuery/index.tsx:65
#: src/Components/Modal/Wizard/ConnectToGA/index.tsx:129
#: src/Components/Modal/Wizard/ConnectToGSC/index.tsx:61
#: src/Pages/ConnectedAccounts/support/hooks.tsx:163
msgid "Account removed"
msgstr "Konto slettet"

#: src/Components/IntegrationButtons/AnalyticsIntegrationButtons.tsx:80
#: src/Components/IntegrationButtons/BigQueryIntegrationButtons.tsx:62
#: src/Components/IntegrationButtons/BigQueryIntegrationButtons.tsx:66
#: src/Components/IntegrationButtons/SearchConsoleIntegrationButtons.tsx:49
#: src/Components/Modal/Wizard/ConnectToAdobe/index.tsx:54
#: src/Components/Modal/Wizard/ConnectToBigQuery/index.tsx:70
#: src/Components/Modal/Wizard/ConnectToGA/index.tsx:134
#: src/Components/Modal/Wizard/ConnectToGSC/index.tsx:69
msgid "Failed to remove account"
msgstr "Kunne ikke slette kontoen"

#: src/Components/IntegrationButtons/AnalyticsIntegrationButtons.tsx:96
#: src/Pages/Layout/ActionsMenu/Actions/index.tsx:102
msgid "Upgrade to GA4"
msgstr "Opgrader til GA4"

#: src/Components/IntegrationButtons/AnalyticsIntegrationButtons.tsx:107
#: src/Pages/Layout/ActionsMenu/Actions/index.tsx:92
msgid "Connect to Analytics"
msgstr "Forbind til Analytics"

#: src/Components/IntegrationButtons/AnalyticsIntegrationButtons.tsx:117
msgid "Disconnect from Analytics"
msgstr "Afbryd forbindelsen til Analytics"

#: src/Components/IntegrationButtons/BigQueryIntegrationButtons.tsx:80
msgid "Connect to BigQuery"
msgstr "Forbind til BigQuery"

#: src/Components/IntegrationButtons/BigQueryIntegrationButtons.tsx:92
msgid "Data can only be backfilled once"
msgstr "Historisk data kan kun skrives én gang"

#: src/Components/IntegrationButtons/BigQueryIntegrationButtons.tsx:93
msgid "Push historic keyword data to BigQuery"
msgstr "Send historisk data til BigQuery"

#: src/Components/IntegrationButtons/BigQueryIntegrationButtons.tsx:98
msgid "Backfilling..."
msgstr "Sender historisk data..."

#: src/Components/IntegrationButtons/BigQueryIntegrationButtons.tsx:100
#: src/Pages/DomainSettings/components/Menu/BigQueryBackfill.tsx:24
msgid "Data is backfilled"
msgstr "Historisk data er kopieret"

#: src/Components/IntegrationButtons/BigQueryIntegrationButtons.tsx:101
msgid "Backfill data"
msgstr "Kopier historisk data"

#: src/Components/IntegrationButtons/BigQueryIntegrationButtons.tsx:110
msgid "Disconnect from BigQuery"
msgstr "Afbryd forbindelsen til BigQuery"

#: src/Components/IntegrationButtons/SearchConsoleIntegrationButtons.tsx:62
#: src/Components/Modal/Wizard/ConnectToGSC/index.tsx:88
#: src/Pages/Keywords/Table/support/connectToGSCHovercard.tsx:81
#: src/Pages/Layout/ActionsMenu/Actions/index.tsx:112
msgid "Connect to Search Console"
msgstr "Forbind til Search Console"

#: src/Components/IntegrationButtons/SearchConsoleIntegrationButtons.tsx:74
msgid "Disconnect from GSC"
msgstr "Afbryd forbindelsen til Google Search Console"

#: src/Components/Intercom/useInsertIntercomCloseButton.tsx:40
msgid "close the chat"
msgstr "Luk chatten"

#: src/Components/JsonFormatter/index.tsx:29
msgid "Invalid JSON data"
msgstr "Ugyldig JSON data"

#: src/Components/JsonFormatter/index.tsx:56
#: src/Pages/Layout/DashboardTemplate/SideNavbar/components/NavbarFooter/index.tsx:83
msgid "Expand"
msgstr "Udvid"

#: src/Components/JsonFormatter/index.tsx:56
#: src/Pages/Layout/DashboardTemplate/SideNavbar/components/NavbarFooter/index.tsx:83
#: src/Pages/Layout/DashboardTemplate/SideNavbar/components/NavbarFooter/index.tsx:88
msgid "Collapse"
msgstr "Fold ind"

#: src/Components/LinkedAccountsDropdown/index.tsx:30
#: src/Pages/ErrorPages/Blocked/index.tsx:25
msgid "Logout from account"
msgstr "Log ud fra konto"

#: src/Components/LinkedAccountsDropdown/LinkedAccountSelect/LinkedAccountSelect.tsx:44
msgid "You are already on the selected account."
msgstr "Du er allerede på den valgte konto."

#: src/Components/LinkedAccountsDropdown/LinkedAccountSelect/LinkedAccountSelect.tsx:54
msgid "Change account"
msgstr "Skift konto"

#: src/Components/Loader/index.tsx:34
msgid "Loading data, please wait a moment"
msgstr "Henter data, vent et øjeblik"

#: src/Components/Loader/index.tsx:35
msgid "Loading data, this might take a while"
msgstr "Henter data, det kan tage et stykke tid"

#: src/Components/Modal/Content/AddAccount/index.tsx:62
msgid "Select Connection Provider"
msgstr "Vælg forbindelsesudbyder"

#: src/Components/Modal/Content/AddAccount/index.tsx:69
#: src/Components/Modal/Content/CancelPlan/CancelPlanCallForm/index.tsx:78
#: src/Components/Modal/Content/RemoveTags/index.tsx:160
msgid "Close this window"
msgstr "Luk dette vindue"

#: src/Components/Modal/Content/AddEditUserForm/index.tsx:58
msgid "Error loading user data. Try again later"
msgstr "Fejl under hentning af data. Prøv igen senere"

#: src/Components/Modal/Content/AddEditUserForm/index.tsx:65
msgid "When you add a new user an email will be sent to the new user."
msgstr "Når du tilføjer en ny bruger, bliver der sendt en e-mail til brugeren."

#: src/Components/Modal/Content/AddEditUserForm/index.tsx:67
msgid ""
"To activate the account please make sure that the new user clicks the "
"activate link in the email."
msgstr ""
"For at aktivere kontoen skal du sørge for, at den nye bruger klikker på "
"linket i e-mailen."

#: src/Components/Modal/Content/AddEditUserForm/index.tsx:88
#: src/Pages/Profile/components/EditProfile/index.tsx:190
msgid "Full Name"
msgstr "Fulde navn"

#: src/Components/Modal/Content/AddEditUserForm/index.tsx:89
#: src/Pages/Profile/components/EditProfile/index.tsx:192
msgid "Enter full name"
msgstr "Indtast det fulde navn"

#: src/Components/Modal/Content/AddEditUserForm/index.tsx:97
#: src/Pages/Auth/ResetPasswordPage/components/ResetPasswordForm.tsx:40
#: src/Pages/Profile/components/EditProfile/index.tsx:197
#: src/Pages/Sales/Organization/index.tsx:326
#: src/Pages/Sales/Search/index.tsx:138
#: src/Pages/Users/UsersTable/getColumns.tsx:51
msgid "Email"
msgstr "E-mail"

#: src/Components/Modal/Content/AddEditUserForm/index.tsx:98
msgid "Enter email address"
msgstr "Indtast e-mailadresse"

#: src/Components/Modal/Content/AddEditUserForm/index.tsx:104
#: src/Pages/Sales/Search/index.tsx:159
#: src/Pages/Users/UsersTable/getColumns.tsx:68
msgid "User role"
msgstr "Brugerrolle"

#: src/Components/Modal/Content/AddEditUserForm/index.tsx:105
#: src/Pages/Users/UsersTable/getColumns.tsx:72
msgid "Select one of the user roles for the user"
msgstr "Vælg en af brugerrollerne for brugeren"

#: src/Components/Modal/Content/AddEditUserForm/index.tsx:118
msgid ""
"Select one of the workspaces for the user. \"All Groups\" gives access to "
"every group."
msgstr ""
"Vælg et af brugerens workspaces. \"Alle Grupper\" giver adgang til hver "
"eneste gruppe."

#: src/Components/Modal/Content/AddEditWorkspaceForm/index.tsx:53
msgid "Error loading workspace data. Try again later"
msgstr "Fejl ved indlæsning af workspace-data. Prøv igen senere"

#: src/Components/Modal/Content/AddEditWorkspaceForm/index.tsx:58
msgid "Instruction how to add new workspace"
msgstr "Instruktion til hvordan man tilføjer ny workspace"

#: src/Components/Modal/Content/AddEditWorkspaceForm/index.tsx:68
msgid "Enter workspace name"
msgstr "Indtast workspace-navn"

#: src/Components/Modal/Content/AddEditWorkspaceForm/index.tsx:76
#: src/Components/Modal/Content/EditAccount/index.tsx:74
#: src/Components/Modal/Content/EditAccount/index.tsx:77
#: src/Components/Modal/Content/EditAdobeAccount/index.tsx:72
#: src/Components/Modal/Content/EditAdobeAccount/index.tsx:75
#: src/Components/Modal/Content/EditBigQueryAccount/index.tsx:72
#: src/Components/Modal/Content/EditBigQueryAccount/index.tsx:75
#: src/Components/Modal/Content/SalesOrganizationAbuseLog/index.tsx:40
#: src/Components/Modal/Content/Shortcuts/index.tsx:116
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:35
#: src/Components/Modal/Wizard/ConnectToAdobe/ConnectAdobeAccount/index.tsx:110
#: src/Components/Modal/Wizard/ConnectToAdobe/ConnectAdobeAccount/index.tsx:113
#: src/Pages/ConnectedAccounts/support/hooks.tsx:236
#: src/Pages/importExamples/ImportExamples.tsx:70
#: src/Pages/importExamples/ImportExamples.tsx:122
#: src/Pages/Keywords/Groupings/support/constants.ts:60
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:182
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:305
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:331
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:333
#: src/Pages/Sales/Organization/index.tsx:801
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:449
#: src/Pages/Wallet/WalletsTable.tsx:35
#: src/Pages/Workspaces/WorkspacesTable/getColumns.tsx:32
msgid "Description"
msgstr "Beskrivelse"

#: src/Components/Modal/Content/AddEditWorkspaceForm/index.tsx:77
msgid "Workspace description"
msgstr "Workspace beskrivelse"

#: src/Components/Modal/Content/AddEditWorkspaceForm/index.tsx:82
#: src/Pages/Workspaces/WorkspacesTable/getColumns.tsx:49
msgid "Group Access"
msgstr "Gruppe Adgang"

#: src/Components/Modal/Content/AddEditWorkspaceForm/index.tsx:84
msgid "Select one or more Groups to grant access to this workspace."
msgstr "Vælg en eller flere grupper som skal gives adgang til denne workspace."

#: src/Components/Modal/Content/AddEditWorkspaceForm/index.tsx:107
msgid "Add Workspace"
msgstr "Tilføj workspace"

#: src/Components/Modal/Content/AddGroup/index.tsx:29
#: src/Components/Modal/Content/AddGroup/index.tsx:59
#: src/Components/Modal/Content/AddWalletItem/index.tsx:41
#: src/Components/Modal/Content/EditGroup/index.tsx:29
msgid "Request failed"
msgstr "Forespørgsel fejlede"

#: src/Components/Modal/Content/AddGroup/index.tsx:48
msgid "Group added: [groupName]"
msgstr "Gruppe tilføjet: [groupName]"

#: src/Components/Modal/Content/AddGroup/index.tsx:65
#: src/Components/QuickNavigation/QuickNavigation.tsx:184
msgid "Add Group"
msgstr "Tilføj gruppe"

#: src/Components/Modal/Content/AddKeywords/AdvancedSettingsInput/index.tsx:11
msgid "Advanced settings"
msgstr "Avancerede indstillinger"

#: src/Components/Modal/Content/AddKeywords/AdvancedSettingsInput/index.tsx:15
msgid "Star Keywords"
msgstr "Stjernemarker søgeord"

#: src/Components/Modal/Content/AddKeywords/AdvancedSettingsInput/index.tsx:19
#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:181
msgid "Ignore in Share of Voice"
msgstr "Ignorer i Synlighed"

#: src/Components/Modal/Content/AddKeywords/AdvancedSettingsInput/index.tsx:20
msgid "This is usually used for branded keywords"
msgstr "Dette bruges normalt til brandede søgeord"

#: src/Components/Modal/Content/AddKeywords/Footer/index.tsx:39
msgid "Keywords to be added:"
msgstr "Antal søgeord der tilføjes:"

#: src/Components/Modal/Content/AddKeywords/Footer/index.tsx:54
msgid "Add keyword"
msgid_plural "Add keywords "
msgstr[0] "Tilføj søgeord"
msgstr[1] "Tilføj søgeord"

#: src/Components/Modal/Content/AddKeywords/ImportFromCSVInput/index.tsx:9
msgid "Import from CSV"
msgstr "Importer fra CSV"

#: src/Components/Modal/Content/AddKeywords/ImportFromGSCInput/index.tsx:48
msgid "Connect to Google Search Console"
msgstr "Forbind til Google Search Console"

#: src/Components/Modal/Content/AddKeywords/index.tsx:342
#: src/Components/Modal/Content/AddKeywords/index.tsx:388
#: src/Components/Modal/Content/AddKeywords/index.tsx:541
#: src/Components/Modal/Content/AddKeywords/index.tsx:562
#: src/Components/Modal/Content/BuildCompetitor/utils/useSubmitCompetitor.ts:54
#: src/Components/Modal/Content/BuildCompetitor/utils/useSubmitCompetitor.ts:85
#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/utils/useAddDomainForm.tsx:248
#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/utils/useUpdateDomain.tsx:119
#: src/Components/Modal/Content/EditUser/useUpdateUser.ts:54
#: src/Components/Modal/Content/EditWorkspace/useUpdateWorkspace.ts:46
#: src/Components/OveruseConfirmation/index.tsx:206
#: src/Components/OveruseConfirmation/index.tsx:223
#: src/Pages/Accounts/TransferMultiAccountDomain/index.tsx:174
#: src/Pages/Accounts/TransferMultiAccountDomain/index.tsx:272
#: src/Pages/Accounts/TransferMultiAccountDomain/index.tsx:292
#: src/Pages/PausedDomains/index.tsx:109 src/Pages/PausedDomains/index.tsx:322
#: src/Pages/PausedDomains/index.tsx:336
#: src/Pages/Profile/components/EditProfile/index.tsx:129
msgid "Something went wrong"
msgstr "Noget gik galt"

#: src/Components/Modal/Content/AddKeywords/index.tsx:415
msgid "Keywords added to queue.."
msgstr "Søgeordene er blevet tilføjet til køen.."

#: src/Components/Modal/Content/AddKeywords/index.tsx:427
msgid "Adding keywords to [domainName]..."
msgstr "Føjer søgeord til [domain]..."

#: src/Components/Modal/Content/AddKeywords/index.tsx:430
msgid "Adding keywords..."
msgstr "Tilføjer søgeord..."

#: src/Components/Modal/Content/AddKeywords/index.tsx:471
#: src/Pages/Keywords/Overview/MissingDataOverlay.tsx:93
msgid "Add Keywords"
msgstr "Tilføj søgeord"

#: src/Components/Modal/Content/AddKeywords/KeywordOveruseNotice/index.tsx:25
msgid ""
"You are adding more keywords than your current [maxKeywords] keywords plan "
"allow. You are exceeding your limit by [exceeding] keywords."
msgstr ""
"Du tilføjer flere søgeord end dit nuværende abonnement tillader "
"([maxKeywords]). Du overskrider grænsen med [exceeding] søgeord."

#: src/Components/Modal/Content/AddKeywords/KeywordsInput/index.tsx:29
msgid "Enter your keywords (one per line)"
msgstr "Indtast dine søgeord (ét søgeord per linie)"

#: src/Components/Modal/Content/AddKeywords/KeywordSuggestionsInput/index.tsx:71
msgid "Hide keyword suggestions"
msgstr "Skjul søgeordsforslag"

#: src/Components/Modal/Content/AddKeywords/KeywordSuggestionsInput/index.tsx:71
msgid "Show keyword suggestions"
msgstr "Vis søgeordsforslag"

#: src/Components/Modal/Content/AddKeywords/KeywordSuggestionsInput/index.tsx:87
msgid "Start typing for keyword suggestions…"
msgstr "Begynd at skrive for søgeordsforslag…"

#: src/Components/Modal/Content/AddKeywords/KeywordSuggestionsInput/index.tsx:93
msgid "Click on a keyword to add/remove it in the list above"
msgstr "Klik på et søgeord for et tilføje/fjerne det i listen ovenover"

#: src/Components/Modal/Content/AddKeywords/SearchEnginesInput/index.tsx:72
#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/utils/useAddDomainForm.tsx:289
msgid "You cannot add the same locale multiple times."
msgstr "Du kan ikke tilføje det samme land flere gange."

#: src/Components/Modal/Content/AddKeywords/SearchEnginesInput/index.tsx:107
msgid "Search Engine, Locale and Location"
msgstr "Søgemaskine, område og lokation"

#: src/Components/Modal/Content/AddKeywords/SearchEnginesInput/index.tsx:120
msgid "Please add at least one locale"
msgstr "Vælg mindst ét område"

#: src/Components/Modal/Content/AddKeywords/SearchEnginesInput/index.tsx:128
msgid "Add Search Engine"
msgstr "Tilføj søgemaskine"

#: src/Components/Modal/Content/AddKeywords/TagsInput/index.tsx:7
msgid "Enter tags here"
msgstr "Indtast tags her"

#: src/Components/Modal/Content/AddKeywords/utils/index.tsx:64
msgid "%s%s search engine"
msgstr "%s%s søgemaskine"

#: src/Components/Modal/Content/AddKeywords/utils/index.tsx:65
msgid "%s%s search engines"
msgstr "%s%s søgemaskiner"

#: src/Components/Modal/Content/AddKeywords/utils/index.tsx:71
msgid "%s locations"
msgstr "%s lokationer"

#: src/Components/Modal/Content/AddKeywords/utils/index.tsx:100
msgid "%s keyword"
msgid_plural "%s keywords"
msgstr[0] "%s søgeord"
msgstr[1] "%s søgeord"

#: src/Components/Modal/Content/AddNote/AddNoteForm/index.tsx:53
#: src/Components/Modal/Content/EditNote/EditNoteForm/index.tsx:143
msgid "Add for all keywords"
msgstr "Tilføj for alle søgeord"

#: src/Components/Modal/Content/AddNote/AddNoteForm/index.tsx:62
#: src/Components/Modal/Content/EditNote/EditNoteForm/index.tsx:146
msgid "Keywords to exclude"
msgstr "Søgeord der skal ekskluderes"

#: src/Components/Modal/Content/AddNote/AddNoteForm/index.tsx:71
#: src/Pages/Keywords/Table/Actions/MoreActions/index.tsx:76
#: src/Pages/NotesList/index.tsx:48
#: src/Pages/Sales/Organization/Components/NotesPanel/index.tsx:74
msgid "Add note"
msgstr "Tilføj notat"

#: src/Components/Modal/Content/AddNote/AddNoteForm/index.tsx:86
msgid "The note will reference [amount] keywords."
msgstr "Notatet vil omhandle [amount] søgeord."

#: src/Components/Modal/Content/AddNote/AddNoteForm/index.tsx:89
msgid "The note will reference all keywords"
msgstr "Notatet vil omhandle alle søgeord"

#: src/Components/Modal/Content/AddNote/index.tsx:56
msgid "Add Note"
msgstr "Tilføj notat"

#: src/Components/Modal/Content/AddSearchEngine/index.tsx:75
msgid "Please select at least one option"
msgstr "Vælg mindst én mulighed"

#: src/Components/Modal/Content/AddSearchEngine/index.tsx:117
#: src/Components/Modal/Content/AddSearchEngine/index.tsx:213
msgid "Enter location (e.g. New York, NY, USA)"
msgstr "Indtast lokation (fx New York, NY, USA)"

#: src/Components/Modal/Content/AddSearchEngine/index.tsx:118
msgid "Location not supported for selected search engine…"
msgstr "Lokation understøttes ikke af den valgte søgemaskine…"

#: src/Components/Modal/Content/AddSearchEngine/index.tsx:131
msgid "Add another location"
msgstr "Tilføj endnu en placering"

#: src/Components/Modal/Content/AddSearchEngine/index.tsx:159
#: src/Components/Modal/Layout/ModalBorder/index.tsx:39
msgid "ESC to close"
msgstr "ESC for at lukke"

#: src/Components/Modal/Content/AddSearchEngine/index.tsx:196
msgid "Search Engines & Devices"
msgstr "Søgemaskiner og enheder"

#: src/Components/Modal/Content/AddSearchEngine/index.tsx:231
#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:149
msgid "Google Settings"
msgstr "Google indstillinger"

#: src/Components/Modal/Content/AddSearchEngine/index.tsx:239
msgid "Edit locale"
msgstr "Ok"

#: src/Components/Modal/Content/AddSearchEngine/index.tsx:239
msgid "Add locale"
msgstr "Tilføj område"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:49
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:188
#: src/Pages/SelectPlan/plan-features.tsx:74
msgid "Monthly"
msgstr "Månedlig"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:53
msgid "Annually"
msgstr "Årligt"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:59
msgid "My account"
msgstr "Min konto"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:63
msgid "New sub-account"
msgstr "Ny under-konto"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:92
msgid "New sub-account created and paid"
msgstr "Under-konto oprettet og betalt"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:128
msgid "New sub-account created"
msgstr "Under-konto oprettet"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:152
#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:195
#: src/Pages/Affiliate/AffiliateCustomers/AffiliateCustomersTable/index.tsx:86
#: src/Pages/Billing/Overview/Plan/index.tsx:127
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/components/OveruseInfoDetails.tsx:43
msgid "Plan"
msgstr "Abonnement"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:158
#: src/Pages/Welcome/components/welcomeActions.tsx:37
msgid "Select plan"
msgstr "Vælg plan"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:165
msgid "Billing Cycle"
msgstr "Faktureringsperiode"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:171
msgid "Select billing cycle"
msgstr "Vælg faktureringsperiode"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:187
#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:190
msgid "Users Full Name"
msgstr "Brugerens fulde navn"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:197
#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:201
msgid "Users Email Address"
msgstr "Brugerens e-mailadresse"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:218
msgid "Who Pays?"
msgstr "Hvem betaler?"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:232
#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:235
msgid "Company Name"
msgstr "Firmanavn"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:249
msgid "I agree to the [link:Terms and Conditons]"
msgstr "Jeg accepterer [link:betingelser og vilkår]"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:262
msgid "Create and pay"
msgstr "Opret og betal"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:262
msgid "Create and send email"
msgstr "Opret og send e-mail"

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:274
msgid ""
"The account will be created and billed instantly using the credit card that "
"is already on your account."
msgstr ""
"Kontoen bliver oprettet og betalt med det kredit kort der allerede er på din "
"konto."

#: src/Components/Modal/Content/AddSubAccount/AddSubAccountForm/index.tsx:277
msgid ""
"The account will be created and the user entered will receive an email with "
"login info. There is no trial period on the account, and it must be paid to "
"be usable."
msgstr ""
"Kontoen bliver oprettet og brugeren der er indtastet vil modtage en e-mail "
"med login info. Der er ingen prøveperiode på kontoen, og den skal betales "
"før den kan bruges."

#: src/Components/Modal/Content/AddSubAccount/index.tsx:21
msgid "Create New Sub-Account"
msgstr "Opret ny under-konto"

#: src/Components/Modal/Content/AddTags/AddTagsForm/index.tsx:21
msgid "Select existing tags or enter new"
msgstr "Vælg eksisterende tags eller indtast nye"

#: src/Components/Modal/Content/AddTags/AddTagsForm/index.tsx:35
#: src/Components/Modal/Content/AddTags/index.tsx:46
msgid "Add Tags"
msgstr "Tilføj tags"

#: src/Components/Modal/Content/AddUser/index.tsx:38
msgid "Invite New User"
msgstr "Inviter ny bruger"

#: src/Components/Modal/Content/AddUser/useCreateUser.ts:47
msgid "User invited"
msgstr "Bruger inviteret"

#: src/Components/Modal/Content/AddWalletItem/AddWalletItemForm/index.tsx:40
msgid "Description (shown on invoice)"
msgstr "Beskrivelse (vist på faktura)"

#: src/Components/Modal/Content/AddWalletItem/AddWalletItemForm/index.tsx:41
msgid "Enter description"
msgstr "Indsæt beskrivelse"

#: src/Components/Modal/Content/AddWalletItem/AddWalletItemForm/index.tsx:48
msgid "Customer owes us"
msgstr "Kunde skylder os"

#: src/Components/Modal/Content/AddWalletItem/AddWalletItemForm/index.tsx:49
msgid ""
"On: Money the customer owes AccuRanker.[br]Off: Money AccuRanker owes the "
"customer."
msgstr ""
"Til: Beløb kunden skylder AccuRanker.[br]Fra: Beløb AccuRanker skylder "
"kunden."

#: src/Components/Modal/Content/AddWalletItem/AddWalletItemForm/index.tsx:56
#: src/Pages/Affiliate/AffiliateVisitors/AffiliateReferralsTable/index.tsx:46
#: src/Pages/Wallet/WalletsTable.tsx:65
msgid "Amount"
msgstr "Beløb"

#: src/Components/Modal/Content/AddWalletItem/AddWalletItemForm/index.tsx:57
msgid "Enter positive non-zero amount"
msgstr "Indtast positiv værdi større end nul"

#: src/Components/Modal/Content/AddWalletItem/AddWalletItemForm/index.tsx:63
msgid "Currency"
msgstr "Valuta"

#: src/Components/Modal/Content/AddWalletItem/index.tsx:36
msgid "Wallet item added"
msgstr "Wallet enhed tilføjet"

#: src/Components/Modal/Content/AddWalletItem/index.tsx:47
#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:349
msgid "Add Wallet Item"
msgstr "Tilføj Wallet Enhed"

#: src/Components/Modal/Content/AddWorkspace/index.tsx:38
msgid "Add New Workspace"
msgstr "Tilføj nyt workspace"

#: src/Components/Modal/Content/AddWorkspace/useAddWorkspace.ts:40
msgid "Added Workspace:"
msgstr "Tilføjet Workspace:"

#: src/Components/Modal/Content/AffiliateInfo/index.tsx:16
msgid "AccuRanker affiliate ID (aaid)"
msgstr "AccuRanker affiliate ID (aaid)"

#: src/Components/Modal/Content/AffiliateInfo/index.tsx:20
msgid "Paypal email"
msgstr "PayPal e-mail"

#: src/Components/Modal/Content/AffiliateInfo/index.tsx:24
msgid "Minimum payout"
msgstr "Minimum udbetaling"

#: src/Components/Modal/Content/AffiliateInfo/index.tsx:30
msgid "Commission percentage"
msgstr "Procent provision"

#: src/Components/Modal/Content/AffiliateInfo/index.tsx:34
#: src/Pages/Affiliate/AffiliateCustomers/AffiliateCustomersTable/index.tsx:115
msgid "Total commission"
msgstr "Total provision"

#: src/Components/Modal/Content/AffiliateInfo/index.tsx:40
msgid "Total unpaid commission (and older than 30 days)"
msgstr "Total ubetalt provision (og ældre end 30 dage)"

#: src/Components/Modal/Content/AffiliateInfo/index.tsx:48
msgid "Total paid commission"
msgstr "Total udbetalt provision"

#: src/Components/Modal/Content/AffiliateInfo/index.tsx:60
#: src/Pages/Affiliate/AffiliateDashboard/index.tsx:98
msgid "Affiliate Information"
msgstr "Affiliate information"

#: src/Components/Modal/Content/BuildCompetitor/BuildCompetitorForm/index.tsx:61
msgid "Competitor Domain"
msgstr "Konkurrentdomæne"

#: src/Components/Modal/Content/BuildCompetitor/BuildCompetitorForm/index.tsx:62
msgid "Example: amazon.co.uk"
msgstr "Eksempel: amazon.co.uk"

#: src/Components/Modal/Content/BuildCompetitor/BuildCompetitorForm/index.tsx:71
msgid "Competitor Display Name"
msgstr "Konkurrentvisningsnavn"

#: src/Components/Modal/Content/BuildCompetitor/BuildCompetitorForm/index.tsx:72
msgid "Example: Amazon UK"
msgstr "Eksempel: Amazon UK"

#: src/Components/Modal/Content/BuildCompetitor/BuildCompetitorForm/index.tsx:77
msgid "Competitor Google Business names"
msgstr "Konkurrent Google Business navne"

#: src/Components/Modal/Content/BuildCompetitor/BuildCompetitorForm/index.tsx:78
msgid "Competitor Google Business names..."
msgstr "Konkurrent Google Business navne..."

#: src/Components/Modal/Content/BuildCompetitor/BuildCompetitorForm/index.tsx:79
#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:266
msgid "Enter name of the business and hit enter"
msgstr "Skriv navnet på forretningen og tryk enter"

#: src/Components/Modal/Content/BuildCompetitor/BuildCompetitorForm/index.tsx:103
msgid "Competitor data will be ready around 10 seconds after saving."
msgstr "Konkurrentdata vil være klar omkring 10 sekunder efter du har gemt."

#: src/Components/Modal/Content/BuildCompetitor/index.tsx:22
msgid "Edit Competitor"
msgstr "Rediger konkurrent"

#: src/Components/Modal/Content/BuildCompetitor/index.tsx:22
#: src/Pages/Keywords/Overview/components/KebabMenu/index.tsx:110
#: src/Pages/Keywords/Overview/MissingDataOverlay.tsx:90
msgid "Add Competitor"
msgstr "Tilføj konkurrent"

#: src/Components/Modal/Content/BuildCompetitor/utils/useSubmitCompetitor.ts:57
msgid "Competitor added. Data will be available in around 10 seconds."
msgstr "Konkurrent tilføjet. Data vil være tilgængelige om cirka 10 sekunder."

#: src/Components/Modal/Content/BuildCompetitor/utils/useSubmitCompetitor.ts:88
msgid "Competitor updated"
msgstr "Konkurrent opdateret"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:218
msgid "Domain or YouTube Channel URL"
msgstr "Domæne eller YouTube-kanal URL"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:221
msgid "example.com"
msgstr "eksempel.dk"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:222
msgid "youtube.com/channel/UCv8uTVRcwNF1JkZ1Z0d7WUg"
msgstr "youtube.com/channel/UCv8uTVRcwNF1JkZ1Z0d7WUg"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:227
msgid ""
"Please enter a valid domain or URL, e.g. example.com or example.com/path."
msgstr ""
"Indtast venligst et gyldigt domæne eller URL, f.eks. eksempel.com eller "
"eksempel.com/sti."

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:228
msgid ""
"Enter a YouTube channel url e.g. youtube.com/channel/UCv8uTVRcwNF1JkZ1Z0d7WUg"
msgstr ""
"Indtast en YouTube-kanal URL, f.eks. youtube.com/channel/"
"UCv8uTVRcwNF1JkZ1Z0d7WUg"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:233
msgid "Display Name"
msgstr "Visningsnavn"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:235
msgid "My Project Name"
msgstr "Mit projektnavn"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:236
msgid "[bold] If set this will display instead of the [mode] name."
msgstr "[bold] Hvis sat, viser vi dette navn i stedet for domænenavnet."

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:237
msgid "Optional."
msgstr "Valgfri."

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:238
msgid "domain"
msgstr "domæne"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:238
msgid "channel"
msgstr "kanal"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:249
#: src/Pages/PausedDomains/index.tsx:233
msgid "Belongs to Group"
msgstr "Tilhører gruppe"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:251
msgid "Belongs to group"
msgstr "Tilhører gruppe"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:264
#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:265
msgid "Google Business Names"
msgstr "Google Business navne"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:267
msgid ""
"For some local results Google does not include a link to the website. To "
"make sure we can still find the domain on the search result page, please "
"enter the exact names of the Google Business page here."
msgstr ""
"For nogle lokale resultater har Google ikke et link til hjemmesiden. For at "
"sikre at vi kan finde domænet på søgeresultatsiden, kan du indtaste domænets "
"navn(e) fra Google Business-siden her."

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:283
msgid "Hide advanced settings"
msgstr "Skjul avancerede indstillinger"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/BuildDomainForm.tsx:283
msgid "Show advanced settings"
msgstr "Vis avancerede indstillinger"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/AdvancedSettings/AdvancedSettingsFields.tsx:59
#: src/Pages/DomainSettings/components/Menu/index.tsx:43
#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:100
msgid "General"
msgstr "Generelt"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/AdvancedSettings/AdvancedSettingsFields.tsx:63
msgid "Show domain share of voice as a percentage of maximum value."
msgstr "Vis domænet synlighed som en procentdel af maksimum værdien."

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/AdvancedSettings/AdvancedSettingsFields.tsx:64
msgid "Show share of voice as percentage"
msgstr "Vis synlighed i procent"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/AdvancedSettings/AdvancedSettingsFields.tsx:69
msgid ""
"Include results from *.domain.com. If not checked, we only include results "
"for www.domain.com and domain.com."
msgstr ""
"Medtag resultater fra *.domain.dk. Hvis du ikke krydser denne af inkluderer "
"vi kun resultater for www.domain.dk og domain.dk."

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/AdvancedSettings/AdvancedSettingsFields.tsx:72
msgid "Include subdomains"
msgstr "Inkluder under-domæner"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/AdvancedSettings/AdvancedSettingsFields.tsx:78
msgid ""
"Only include results where the URL found is an exact match to what is "
"entered in domain name"
msgstr ""
"Inkluder kun resultater hvor den fundne URL er et præcist match på det der "
"er indtastet i domænenavn"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/AdvancedSettings/AdvancedSettingsFields.tsx:81
msgid "Exact match"
msgstr "Præcis match"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/AdvancedSettings/AdvancedSettingsFields.tsx:87
msgid "When a domain is paused, it cannot be un-paused for 14 days."
msgstr ""
"Når et domæne er sat på pause kan det ikke startes igen før der er gået 14 "
"dage."

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/AdvancedSettings/AdvancedSettingsFields.tsx:88
msgid "Pause domain"
msgstr "Pause domæne"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/AdvancedSettings/ConnectionSettings.tsx:22
msgid "Connections"
msgstr "Forbindelser"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/DomainFormAlerts.tsx:10
msgid "You are about to add a YouTube channel."
msgstr "Du er ved at tilføje en YouTube kanal."

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/DomainFormAlerts.tsx:12
msgid ""
"When using a channel, we will be tracking all the video urls within your "
"channel."
msgstr "Når du bruger en kanal, holder vi øje med alle videoer i din kanal."

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/DomainFormAlerts.tsx:14
msgid "You can read more about our YouTube integration [link:here]."
msgstr "Du kan læse mere om vores YouTube integration [link:her]."

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/DomainFormAlerts.tsx:27
msgid "You are about to add a YouTube video URL."
msgstr "Du er ved at tilføje en YouTube video URL."

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/DomainFormAlerts.tsx:29
msgid ""
"If you add the channel URL, AccuRanker will automatically track all video "
"URLs within the channel."
msgstr ""
"Hvis du tilføjer kanalens webadresse, holder AccuRanker automatisk øje med "
"alle videoerne på den kanal."

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/DomainFormAlerts.tsx:33
msgid "Read more about the YouTube integration [link:here]."
msgstr "Du kan læse mere om YouTube integrationen [link:her]."

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/LocationFieldSection.tsx:24
msgid "Select engine, locale and location"
msgstr "Vælg søgemaskine, område og lokation"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/LocationFieldSection.tsx:25
msgid "Add another engine, locale and location"
msgstr "Tilføj en anden søgemaskine, område og lokation"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/components/LocationFieldSection.tsx:40
msgid "Default Search Engine, Locale and Location"
msgstr "Standard søgemaskine, område og lokation"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/utils/useAddDomainForm.tsx:112
msgid ""
"An error occurred while trying to add the domain, please try again or "
"contact support."
msgstr ""
"Der opstod en fejl under forsøg på at tilføje domænet. Prøv igen eller "
"kontakt supporten."

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/utils/useAddDomainForm.tsx:135
msgid "Domain added"
msgstr "Domæne tilføjet"

#: src/Components/Modal/Content/BuildDomain/BuildDomainForm/utils/useUpdateDomain.tsx:123
msgid "Domain updated"
msgstr "Domæne opdateret"

#: src/Components/Modal/Content/BuildDomain/index.tsx:33
msgid "Edit Domain"
msgstr "Rediger domæne"

#: src/Components/Modal/Content/BuildDomain/index.tsx:33
#: src/Components/QuickNavigation/QuickNavigation.tsx:164
msgid "Add Domain"
msgstr "Tilføj domæne"

#: src/Components/Modal/Content/CancelPlan/CancelPlanCallForm/index.tsx:24
msgid ""
"We’re sorry to hear that you want to cancel your AccuRanker subscription"
msgstr "Vi er kede af at du ønsker at opsige dit AccuRanker abonnement"

#: src/Components/Modal/Content/CancelPlan/CancelPlanCallForm/index.tsx:26
msgid ""
"Click next to follow the process on how to cancel your AccuRanker "
"subscription."
msgstr ""
"Klik \"Næste\" for at følge processen til at opsige dit AccuRanker "
"abonnement."

#: src/Components/Modal/Content/CancelPlan/CancelPlanCallForm/index.tsx:32
msgid "Next"
msgstr "Næste"

#: src/Components/Modal/Content/CancelPlan/CancelPlanCallForm/index.tsx:48
msgid ""
"In order to cancel your AccuRanker subscription, please call our customer "
"service at [link:+45 89 87 39 44] or send an email to [email: "
"hello@accuranker.com]. Customer service is available from 08:00 to 16:00, "
"Mon-Fri, CET."
msgstr ""
"For at opsige dit AccuRanker abonnement, bedes du ringe til vores "
"kundeservice på [link:+45 89 87 39 44] eller sende en email til [email: "
"hello@accuranker.com]. Kundeservice er åben fra 08:00 til 16:00 mandag til "
"fredag."

#: src/Components/Modal/Content/CancelPlan/CancelPlanCallForm/index.tsx:57
msgid ""
"Please note that a subscription needs to be cancelled 14 days before the "
"subscription is set for renewal."
msgstr ""
"Vær opmærksom på at abonnementet skal opsiges 14 dage inden det bliver "
"fornyet."

#: src/Components/Modal/Content/CancelPlan/CancelPlanCallForm/index.tsx:62
msgid ""
"Your subscription has been cancelled when you receive a e-mail confirmation "
"from our customer representative."
msgstr "Dit abonnement er blevet opsagt når du modtager en e-mail bekræftelse."

#: src/Components/Modal/Content/CancelPlan/CancelPlanCallForm/index.tsx:69
msgid "hello@accuranker.com"
msgstr "hello@accuranker.com"

#: src/Components/Modal/Content/CancelPlan/CancelPlanCallForm/index.tsx:72
msgid "Call +45 89 87 39 44"
msgstr "Ring til +45 89 87 39 44"

#: src/Components/Modal/Content/CancelPlan/index.tsx:17
msgid "Cancel my AccuRanker Subscription"
msgstr "Annuller mit AccuRanker-abonnement"

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:61
#: src/Constants/report.ts:13
#: src/Pages/ReportsOverview/ScheduledReports/helpers/hooks.tsx:44
#: src/Pages/ScheduledReportBuilder/data.ts:13
#: src/Pages/ScheduledReportBuilder/data.ts:36
#: src/Pages/ScheduledReportBuilder/data.ts:56
#: src/Pages/ScheduledReportBuilder/data.ts:82
#: src/Pages/SelectPlan/plan-features.tsx:82
msgid "Excel"
msgstr "Excel"

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:65
#: src/Constants/report.ts:14
#: src/Pages/ReportsOverview/ScheduledReports/helpers/hooks.tsx:51
#: src/Pages/ScheduledReportBuilder/data.ts:17
#: src/Pages/ScheduledReportBuilder/data.ts:40
#: src/Pages/ScheduledReportBuilder/data.ts:60
#: src/Pages/ScheduledReportBuilder/data.ts:86
#: src/Pages/SelectPlan/plan-features.tsx:81
msgid "CSV"
msgstr "CSV"

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:69
#: src/Constants/report.ts:16
#: src/Pages/Integrations/hooks/useIntegrations.tsx:153
#: src/Pages/ReportsOverview/ScheduledReports/helpers/hooks.tsx:58
#: src/Pages/ScheduledReportBuilder/data.ts:21
#: src/Pages/ScheduledReportBuilder/data.ts:44
#: src/Pages/ScheduledReportBuilder/data.ts:64
#: src/Pages/ScheduledReportBuilder/data.ts:90
msgid "Google Sheets"
msgstr "Google Sheets"

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:122
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:110
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:126
msgid "Report created"
msgstr "Rapport oprettet"

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:225
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:219
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:245
msgid "Unable to create report"
msgstr "Kunne ikke oprette rapport"

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:237
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:143
#: src/Pages/Profile/components/GoogleDriveAccount/index.tsx:68
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:229
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:255
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/Add/index.tsx:111
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/Edit/index.tsx:138
msgid ""
"You do not have a Google Drive connection setup with AccuRanker. Please "
"connect to your Google account to allow AccuRanker to create spreadsheet "
"reports. AccuRanker will only have access to the files it creates, and "
"cannot read other files."
msgstr ""
"Du har ikke en Google Drive forbindelse sat op med AccuRanker. Forbind "
"venligst din Google konto for at give AccuRanker adgang til at oprette "
"regneark. AccuRanker har kun adgang til de filer vi opretter og kan ikke "
"læse andre filer."

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:251
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:261
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:266
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:287
msgid ""
"Your report is ready. To view it, please click the 'Open Google Sheets' "
"button below."
msgstr ""
"Din rapport er klar. For at se den, klik på knappen 'Åben Google Sheets' "
"nedenfor."

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:254
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:264
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:269
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:290
msgid ""
"Your report is ready. The download should have started automatically. If it "
"hasn't, please click the download button below."
msgstr ""
"Din rapport er klar. Downloaden skulle være startet automatisk. Hvis ikke, "
"klik venligst på download-knappen nedenfor."

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:264
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:225
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:245
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:271
msgid "Your report is being generated. Please wait…"
msgstr "Din rapport er ved at blive lavet. Vent venligst…"

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:265
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:227
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:246
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:272
msgid ""
"Please note that reports are queued up and can take a few minutes to "
"complete."
msgstr ""
"Bemærk at rapporter sættes i kø og kan tage et par minutter om at blive klar."

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:267
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:232
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:248
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:274
msgid ""
"If nothing happens, your browser may be blocking the download. You can "
"probably find your report in the "
msgstr ""
"Hvis der ikke sker noget kan det være fordi din browser blokerer downloaden. "
"Du kan sandsynligvis finde rapporten i "

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:270
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:235
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:251
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:277
msgid "generated reports tab."
msgstr "genererede rapporter."

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:291
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:322
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:346
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:406
msgid "Report Type"
msgstr "Rapporttype"

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:301
#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:307
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:190
#: src/Components/Modal/Content/SupportedCountriesList/index.tsx:51
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:54
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:383
#: src/Pages/Profile/components/EditProfile/index.tsx:207
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:333
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:340
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:379
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:386
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:467
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:471
msgid "Language"
msgstr "Sprog"

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:314
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:198
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:347
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:392
msgid "Email to"
msgstr "E-mail til"

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:320
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:201
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:354
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:399
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:497
msgid "You can enter multiple addresses"
msgstr "Du kan indtaste flere adresser"

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:340
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:271
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:375
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:436
msgid "Open Google Sheets"
msgstr "Åben Google Sheets"

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:341
#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:351
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:211
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:245
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:271
#: src/Components/Table/TableRow/ReportActionCell/index.tsx:47
#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:184
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:376
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:386
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:437
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:447
msgid "Download"
msgstr "Hent"

#: src/Components/Modal/Content/CompetitorsRanksReport/CompetitorsRanksReportForm/index.tsx:351
#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:386
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:447
msgid "Generating"
msgstr "Genererer"

#: src/Components/Modal/Content/CompetitorsRanksReport/index.tsx:26
#: src/Components/Modal/Content/CompetitorsReport/index.tsx:25
#: src/Components/Modal/Content/KeywordHistoryReport/index.tsx:27
#: src/Components/Modal/Content/OneTimeReport/index.tsx:26
msgid "Download Report"
msgstr "Download rapport"

#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:70
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:132
#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:160
msgid ""
"An error occurred. Please report it to customer support. Sorry for the "
"inconvenience."
msgstr ""
"En fejl opstod. Kontakt venligst kundesupporten. Beklager ulejligheden."

#: src/Components/Modal/Content/CompetitorsReport/CompetitorsReportForm/index.tsx:180
#: src/Pages/SelectPlan/plan-features.tsx:78
msgid "Report type"
msgstr "Rapporttype"

#: src/Components/Modal/Content/Confirmation/index.tsx:116
msgid "[link:Terms and Conditions]"
msgstr "[link:Betingelser og vilkår]"

#: src/Components/Modal/Content/Confirmation/index.tsx:125
msgid "I agree to the [link:Terms and Conditions]"
msgstr "Jeg accepterer [link:betingelser og vilkår]"

#: src/Components/Modal/Content/Confirmation/index.tsx:141
msgid "Unlocking in %s sec"
msgstr "Låser op om %s sekunder"

#: src/Components/Modal/Content/ConnectToAnalytics/index.tsx:58
msgid "Select Analytics Provider"
msgstr "Vælg analytics udbyder"

#: src/Components/Modal/Content/CopyToClipboard/CopyToClipboardForm/index.tsx:101
msgid ""
"Press [shortcut] to copy to clipboard or just click on \"[confirm]\" button"
msgstr "Tryk [shortcut] for at kopiere eller klik på \"[confirm]\" knappen"

#: src/Components/Modal/Content/CopyToClipboard/CopyToClipboardForm/index.tsx:117
#: src/Components/QuickNavigation/QuickNavigation.tsx:85
#: src/Pages/Accounts/TransferMultiAccountDomain/index.tsx:147
#: src/Pages/Sales/Plans/SalesPlansTable/support/hooks.tsx:111
#: src/Pages/Sales/Search/index.tsx:139
msgid "Copy"
msgstr "Kopier"

#: src/Components/Modal/Content/CopyToClipboard/index.tsx:15
msgid "Copy to Clipboard"
msgstr "Kopier til udklipsholder"

#: src/Components/Modal/Content/CreateTagModal/CreateOrEditDynamicTagModal/index.tsx:80
msgid "Dynamic tag was updated!"
msgstr "Det dynamiske tag er opdateret!"

#: src/Components/Modal/Content/CreateTagModal/CreateOrEditDynamicTagModal/index.tsx:80
msgid "New Dynamic tag was added!"
msgstr "Nyt dynamisk tag er blevet tilføjet!"

#: src/Components/Modal/Content/CreateTagModal/CreateOrEditDynamicTagModal/index.tsx:86
msgid "Create Dynamic Tag"
msgstr "Opret dynamisk tag"

#: src/Components/Modal/Content/CreateTagModal/CreateOrEditStaticTagModal/index.tsx:88
msgid "Successfully edited static tag!"
msgstr "Det statiske tag blev redigeret!"

#: src/Components/Modal/Content/CreateTagModal/CreateOrEditStaticTagModal/index.tsx:88
msgid "New static tag was added!"
msgstr "Nyt statisk tag er blevet tilføjet!"

#: src/Components/Modal/Content/CreateTagModal/CreateOrEditStaticTagModal/index.tsx:100
msgid "Edit Static Tag"
msgstr "Rediger statisk tag"

#: src/Components/Modal/Content/CreateTagModal/CreateOrEditStaticTagModal/index.tsx:100
msgid "Create Static Tag"
msgstr "Opret statisk tag"

#: src/Components/Modal/Content/CreateTagModal/CreateOrEditStaticTagModal/index.tsx:110
msgid "Tag"
msgstr "Tag"

#: src/Components/Modal/Content/CreateTagModal/CreateOrEditStaticTagModal/index.tsx:111
msgid "Enter tag here"
msgstr "Indtast tag her"

#: src/Components/Modal/Content/CreateTagModal/CreateOrEditStaticTagModal/index.tsx:118
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/DynamicTagsBuilder.tsx:65
#: src/Pages/Keywords/Groupings/support/constants.ts:55
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:165
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:167
msgid "Folder"
msgstr "Mappe"

#: src/Components/Modal/Content/CreateTagModal/CreateOrEditStaticTagModal/index.tsx:119
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/DynamicTagsBuilder.tsx:66
#: src/Pages/Keywords/Groupings/EditMode/components/EditModeHeader/components/MoveGroupModal.tsx:49
msgid "Select folder"
msgstr "Vælg mappe"

#: src/Components/Modal/Content/CreateTagModal/CreateOrEditStaticTagModal/index.tsx:125
msgid "This tag has [keywords] associated keywords."
msgstr "Denne tag har [keywords] tilknyttede søgeord."

#: src/Components/Modal/Content/DomainsComparison/components/CollapseButtons.tsx:20
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:741
msgid "Expand all"
msgstr "Udvid alle"

#: src/Components/Modal/Content/DomainsComparison/components/CollapseButtons.tsx:31
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:741
msgid "Collapse all"
msgstr "Fold alle sammen"

#: src/Components/Modal/Content/DomainsComparison/components/CollapseGroups.tsx:91
msgid "Try different search terms."
msgstr "Prøv en anden søgning."

#: src/Components/Modal/Content/DomainsComparison/components/CollapseItem.tsx:99
msgid "Max number (%s) of selected items reached."
msgstr "Maks. antal elementer er valgt (%s)."

#: src/Components/Modal/Content/DomainsComparison/components/CollapseItem.tsx:101
msgid "No sub-folders or tags found."
msgstr "Ingen undermapper eller tags fundet."

#: src/Components/Modal/Content/DomainsComparison/components/CollapseItem.tsx:144
msgid "demo group"
msgstr "Demogruppe"

#: src/Components/Modal/Content/DomainsComparison/components/CollapseItem.tsx:145
msgid ""
"This is a group with demo domains and cannot be edited.<br>The data is "
"updated once a day."
msgstr ""
"Dette er en gruppe med demodomæner, som ikke kan redigeres<br>Data opdateres "
"en gang om dagen."

#: src/Components/Modal/Content/DomainsComparison/components/CompareCharts.tsx:50
#: src/Hooks/displayCurrency.ts:67 src/Hooks/useLocalSearch.ts:9
#: src/Pages/DomainSettings/components/Menu/DisplaySettings/index.tsx:48
#: src/Pages/DomainSettings/support/helpers.tsx:48
msgid "Account Default"
msgstr "Kontobaseret Standard"

#: src/Components/Modal/Content/DomainsComparison/components/CompareCharts.tsx:94
msgid "Currency:"
msgstr "Valuta:"

#: src/Components/Modal/Content/DomainsComparison/components/CompareCharts.tsx:115
#: src/Pages/Keywords/Groupings/HistoryGraph/foldersGraph.tsx:60
msgid "No data to compare"
msgstr "Ingen data at sammenligne"

#: src/Components/Modal/Content/DomainsComparison/components/CompareCharts.tsx:119
msgid ""
"The selected items contains no comparable data. Select a different set of "
"groups, domains, folders or tags to compare."
msgstr ""
"De valgte elementer indeholder ingen sammenlignelige data. Vælg et andet sæt "
"grupper, domæner, mapper eller tags, der skal sammenlignes."

#: src/Components/Modal/Content/DomainsComparison/components/CompareCharts.tsx:122
msgid ""
"Select one or more groups, domains, folders, or tags to view the comparison "
"graph."
msgstr ""
"Vælg én eller flere grupper, domæner, mapper eller tags for at se "
"sammenligningsgrafen."

#: src/Components/Modal/Content/DomainsComparison/index.tsx:23
#: src/Components/Modal/Content/KeywordsComparison/index.tsx:15
msgid "Keywords Comparison"
msgstr "Søgeordssammenligning"

#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:21
#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:22
#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:211
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:126
#: src/Pages/Domains/CardView/components/DomainCard/components/DomainCharts.tsx:65
#: src/Pages/Domains/CardView/support/getSorting.ts:47
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:276
#: src/Pages/Domains/support/types.ts:125
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:221
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:222
#: src/Pages/KeywordDiscovery/components/KpiBar/index.tsx:42
#: src/Pages/Keywords/Groupings/HistoryGraph/helpers/getMetricControlOptions.ts:8
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:254
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:255
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:99
#: src/Pages/Keywords/Overview/components/HistoryCharts/EstimatedVisits/EstimatedVisits.tsx:41
#: src/Pages/Keywords/Overview/components/HistoryCharts/EstimatedVisits/EstimatedVisits.tsx:101
#: src/Pages/Keywords/Overview/components/HistoryCharts/EstimatedVisits/helpers.ts:12
#: src/Pages/Keywords/Overview/components/KpiBar/Boxes/AiShareOfVoiceBox.tsx:26
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:67
#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/ShareOfVoiceContainer.tsx:39
#: src/Pages/Keywords/Overview/components/WinnersLosers/components/WinnerLoserBarLabel.tsx:114
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:816
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:823
#: src/Pages/PublicReport/publicReportSettings/index.tsx:563
#: src/Pages/PublicReport/publicReportSettings/index.tsx:572
#: src/Pages/SiteMapping/components/CompetitorTable.tsx:69
#: src/Pages/SiteMapping/components/TreeTable/Header.tsx:75
msgid "AI Share of Voice"
msgstr "AI Synlighed"

#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:26
#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:177
#: src/Pages/Domains/TreeView/support/constants.ts:74
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:259
#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/support/constants.tsx:199
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:232
#: src/Pages/Keywords/Competitors/support/constants.ts:112
#: src/Pages/Keywords/Groupings/support/constants.ts:94
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:295
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/constants.ts:56
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/hooks.tsx:157
#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:249
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:743
#: src/Pages/Keywords/Table/keydisCells.ts:250
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:907
msgid "SoV"
msgstr "Synlighed"

#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:30
#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:31
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:323
#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:132
#: src/Pages/Keywords/Groupings/HistoryGraph/helpers/getMetricControlOptions.ts:16
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:337
msgid "Avg. rank"
msgstr "Gns. placering"

#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:30
msgid "AR"
msgstr "GP"

#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:35
#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:36
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:370
#: src/Pages/Keywords/Groupings/HistoryGraph/helpers/getMetricControlOptions.ts:20
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:383
msgid "Avg. base rank"
msgstr "Gns. rå placering"

#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:35
msgid "ABR"
msgstr "GRP"

#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:45
msgid "SV"
msgstr "SV"

#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:50
#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:51
#: src/Pages/Domains/TreeView/support/constants.ts:100
#: src/Pages/Keywords/Groupings/HistoryGraph/helpers/getMetricControlOptions.ts:32
#: src/Pages/Keywords/Groupings/support/constants.ts:119
msgid "AI Traffic value"
msgstr "AI Trafikværdi"

#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:50
msgid "AI TV"
msgstr "AI TV"

#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:55
#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:56
#: src/Pages/Domains/TreeView/support/constants.ts:105
#: src/Pages/Keywords/Groupings/HistoryGraph/helpers/getMetricControlOptions.ts:36
#: src/Pages/Keywords/Groupings/support/constants.ts:124
#: src/Pages/Keywords/LandingPage/components/KeywordLandingChart/support/helpers.ts:31
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/constants.ts:66
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/hooks.tsx:229
msgid "Traffic value"
msgstr "Trafikværdi"

#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:55
msgid "TV"
msgstr "TV"

#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:60
msgid "AtF"
msgstr "OF"

#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:65
#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:66
msgid "Avg. pixels from top"
msgstr "Gns. pixels fra top"

#: src/Components/Modal/Content/DomainsComparison/support/getMetricControlOptions.tsx:65
msgid "PFT"
msgstr "PFT"

#: src/Components/Modal/Content/EditAccount/index.tsx:52
#: src/Components/Modal/Content/EditAccount/index.tsx:63
#: src/Components/Modal/Content/EditAdobeAccount/index.tsx:59
#: src/Components/Modal/Content/EditBigQueryAccount/index.tsx:59
msgid "Failed to update"
msgstr "Kunne ikke opdatere"

#: src/Components/Modal/Content/EditAccount/index.tsx:71
msgid "Edit %s Account"
msgstr "Rediger %s konto"

#: src/Components/Modal/Content/EditAccount/index.tsx:86
#: src/Components/Modal/Content/EditAdobeAccount/index.tsx:84
#: src/Components/Modal/Content/EditBigQueryAccount/index.tsx:84
msgid "Edit connection"
msgstr "Rediger forbindelse"

#: src/Components/Modal/Content/EditAdobeAccount/index.tsx:68
#: src/Components/Modal/Content/EditBigQueryAccount/index.tsx:68
msgid "Edit Adobe Account"
msgstr "Rediger Adobe konto"

#: src/Components/Modal/Content/EditFilterGroup/EditFilterGroupForm/index.tsx:58
msgid "Unable to edit segment"
msgstr "Kunne ikke redigere segmentet"

#: src/Components/Modal/Content/EditFilterGroup/EditFilterGroupForm/index.tsx:74
#: src/Components/Modal/Content/EditFilterGroup/EditFilterGroupForm/index.tsx:77
msgid "Segment name"
msgstr "Segmentnavn"

#: src/Components/Modal/Content/EditFilterGroup/EditFilterGroupForm/index.tsx:86
msgid ""
"Mark this segment to be used in API as API filter. See more details [link:"
"here]."
msgstr "Markér dette segment from et API filter. Se flere detaljer [link:her]."

#: src/Components/Modal/Content/EditFilterGroup/EditFilterGroupForm/index.tsx:92
msgid "API filter"
msgstr "API filtre"

#: src/Components/Modal/Content/EditFilterGroup/index.tsx:22
msgid "Edit Segment"
msgstr "Rediger segment"

#: src/Components/Modal/Content/EditGroup/index.tsx:41
msgid "Failed to edit group"
msgstr "Kunne ikke redigere gruppe"

#: src/Components/Modal/Content/EditGroup/index.tsx:48
#: src/Pages/Groups/GroupsTable/support/useGroupsTableConfig.tsx:140
msgid "Edit Group"
msgstr "Rediger gruppe"

#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:65
msgid "Ignored Domain"
msgstr "Ignoreret domæne"

#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:79
msgid "Select or enter a domain"
msgstr "Vælg eller indtast et domæne"

#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:81
msgid ""
"Exclude a specific domain from rank calculations for selected keywords. "
"Useful for disregarding brands in branded searches (e.g., omitting \"gucci."
"com\" for \"gucci belt\") or ignoring social media results. This setting "
"affects the rank but not the base rank, CTR, etc."
msgstr ""
"Udeluk et bestemt domæne fra placeringssberegninger for de valgte søgeord. "
"Det kan bruges til at ignorere brands i brandede søgninger (f.eks. udelade "
"'gucci.com' for 'gucci bælte') eller ignorere resultater fra sociale medier. "
"Denne indstilling påvirker placeringen, men ikke den Rå Placering, CTR osv."

#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:88
msgid "The [count] selected keywords have mixed ignored domains:"
msgstr "De [count] valgte søgeord har ikke alle det samme ignorede domæne:"

#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:110
msgid "Remove ignored domains for the [count] selected keywords"
msgstr "Fjern ignorerede domæner for de [count] valgte søgeord"

#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:162
msgid "Ignore featured snippets"
msgstr "Ignorer fremhævede uddrag"

#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:171
msgid "Enable Google Autocorrect for this keyword"
msgstr "Slår Google auto correct til for dette søgeord"

#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:176
msgid "Advanced Settings"
msgstr "Avancerede indstillinger"

#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:182
msgid "Do not include this keyword in the Share of Voice calculation."
msgstr "Medtag ikke dette søgeord i beregningen af Synlighed."

#: src/Components/Modal/Content/EditKeywords/EditKeywordsForm/index.tsx:198
#: src/Pages/Keywords/Table/Actions/MoreActions/index.tsx:91
msgid "Change settings"
msgstr "Skift indstillinger"

#: src/Components/Modal/Content/EditKeywords/index.tsx:197
msgid "Change Settings for Selected Keywords"
msgstr "Skift indstillinger for de valgte søgeord"

#: src/Components/Modal/Content/EditNote/EditNoteForm/index.tsx:92
msgid "Failed to update note"
msgstr "Kunne ikke opdatere notat"

#: src/Components/Modal/Content/EditNote/EditNoteForm/index.tsx:160
msgid "Related Keywords"
msgstr "Relaterede søgeord"

#: src/Components/Modal/Content/EditNote/EditNoteForm/index.tsx:180
#: src/Components/Modal/Content/MoveDomain/MoveDomainForm/index.tsx:110
#: src/Components/Modal/Content/SERPInfo/index.tsx:29
#: src/Components/Modal/Content/UpsellModal/index.tsx:37
#: src/Components/Modal/Wizard/Common/GoogleAccounts/SaveGoogleAccount/index.tsx:119
#: src/Components/Modal/Wizard/ConnectToAdobe/Suite/index.tsx:99
#: src/Components/Modal/Wizard/ConnectToGSC/GSCWebsite/index.tsx:164
#: src/Components/Modal/Wizard/ConnectToGSC/GSCWebsite/index.tsx:213
#: src/Pages/Billing/UpdatePaymentMethod/UpdatePaymentMethodWidget/index.tsx:141
#: src/Pages/ExternalAccess/components/MultiAccountAllowAccess/components/MultiAccountActionButtons.tsx:23
#: src/Pages/Invoices/index.tsx:43
#: src/Pages/ReportTemplateBuilder/ReportTemplate/index.tsx:72
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:523
msgid "Back"
msgstr "Tilbage"

#: src/Components/Modal/Content/EditNote/EditNoteForm/index.tsx:180
#: src/Components/Modal/Content/GeneralError/index.tsx:35
#: src/Components/Modal/Content/PermissionUpdatedModal/index.tsx:18
#: src/Components/Modal/Wizard/Common/ConnectedAccount/index.tsx:15
#: src/Components/Modal/Wizard/ConnectToBigQuery/AddedBigQueryAccount/index.tsx:31
#: src/Components/OveruseConfirmation/index.tsx:162
#: src/Components/OveruseConfirmation/index.tsx:363
#: src/Pages/AIOverview/components/AIOverviewTable/support/AIOverviewCell.tsx:204
msgid "Close"
msgstr "Luk"

#: src/Components/Modal/Content/EditNote/index.tsx:18
msgid "Update Note"
msgstr "Opdater notat"

#: src/Components/Modal/Content/EditUser/index.tsx:71
msgid "Edit User"
msgstr "Rediger bruger"

#: src/Components/Modal/Content/EditUser/useUpdateUser.ts:70
msgid "User updated"
msgstr "Bruger opdateret"

#: src/Components/Modal/Content/EditWorkspace/index.tsx:63
msgid "Edit Workspace"
msgstr "Rediger Workspace"

#: src/Components/Modal/Content/EditWorkspace/useUpdateWorkspace.ts:61
msgid "Workspace updated"
msgstr "Workspace opdateret"

#: src/Components/Modal/Content/ExportAdTracking/ExportAdTrackingForm/index.tsx:67
msgid "Use this form to export ad tracking."
msgstr "Brug denne formular til at eksportere ad-tracking."

#: src/Components/Modal/Content/ExportAdTracking/ExportAdTrackingForm/index.tsx:69
msgid "Start Date"
msgstr "Startdato"

#: src/Components/Modal/Content/ExportAdTracking/ExportAdTrackingForm/index.tsx:73
msgid "Select Start Date"
msgstr "Vælg startdato"

#: src/Components/Modal/Content/ExportAdTracking/ExportAdTrackingForm/index.tsx:79
msgid "End Date"
msgstr "Slutdato"

#: src/Components/Modal/Content/ExportAdTracking/ExportAdTrackingForm/index.tsx:83
msgid "Select End Date"
msgstr "Vælg slutdato"

#: src/Components/Modal/Content/ExportAdTracking/ExportAdTrackingForm/index.tsx:89
msgid "Channel"
msgstr "Kanal"

#: src/Components/Modal/Content/ExportAdTracking/ExportAdTrackingForm/index.tsx:93
msgid "Select Channel"
msgstr "Vælg kanal"

#: src/Components/Modal/Content/ExportAdTracking/ExportAdTrackingForm/index.tsx:108
msgid "Export"
msgstr "Eksporter"

#: src/Components/Modal/Content/ExportAdTracking/index.tsx:23
#: src/Pages/Sales/Tools/index.tsx:49
msgid "Export Ad Tracking"
msgstr "Eksporter ad-tracking"

#: src/Components/Modal/Content/FoldersReport/index.tsx:22
msgid "Create Report"
msgstr "Opret rapport"

#: src/Components/Modal/Content/GeneralError/index.tsx:30
#: src/Components/Modal/Content/PaymentFailed/index.tsx:27
msgid "The error message states:"
msgstr "Fejlbeskeden er:"

#: src/Components/Modal/Content/GeneralError/index.tsx:41
msgid "Go back to the billing overview"
msgstr "Gå tilbage til betalingsoverblikket"

#: src/Components/Modal/Content/GroupModalView/index.tsx:29
#: src/Pages/Groups/GroupsTable/support/useGroupsTableConfig.tsx:36
msgid "Group Name"
msgstr "Gruppenavn"

#: src/Components/Modal/Content/GroupModalView/index.tsx:33
msgid "Group name"
msgstr "Gruppenavn"

#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:101
msgid "Search date"
msgstr "Søgedato"

#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:113
msgid "Date and time of the search in your local timezone"
msgstr "Dato og tid for søgningen i din lokale tidszone"

#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:143
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:111
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:419
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:420
#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/support/constants.tsx:108
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:431
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:432
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/constants.ts:51
#: src/Pages/Keywords/Overview/components/KpiBar/Boxes/SearchVolume.tsx:19
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:70
#: src/Pages/Keywords/Overview/components/SearchTrendNeighborly/table.tsx:47
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:614
#: src/Pages/Keywords/Table/keydisCells.ts:165
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:710
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:719
msgid "Search Volume"
msgstr "Søgevolume"

#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:147
msgid "Average monthly search volume"
msgstr "Gennemsnitlig månedlig søgevolumen"

#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:154
#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:158
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:114
#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/support/constants.tsx:120
#: src/Pages/Keywords/Overview/components/KpiBar/Boxes/AiSearchVolume.tsx:19
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:71
#: src/Pages/Keywords/Overview/components/SearchTrendNeighborly/table.tsx:47
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:626
#: src/Pages/Keywords/Table/keydisCells.ts:179
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:731
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:735
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:745
msgid "AI Search Volume"
msgstr "AI Søgevolume"

#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:169
msgid "Average cost per click (Google Ads)"
msgstr "Gennemsnitlig pris per klik (Google Ads)"

#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:181
msgid "Share of voice"
msgstr "Synlighed"

#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:195
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:181
#: src/Pages/SiteMapping/components/CompetitorTable.tsx:81
#: src/Pages/SiteMapping/components/TreeTable/Header.tsx:85
msgid "Click-through rate"
msgstr "Click-through rate"

#: src/Components/Modal/Content/KeywordHistory/Table/support.tsx:222
msgid "Features on the SERP"
msgstr "Elementer på SERP-siden"

#: src/Components/Modal/Content/KeywordInfo/components/GoogleTrendsChart/index.tsx:19
msgid "Google trends"
msgstr "Google trends"

#: src/Components/Modal/Content/KeywordInfo/components/GoogleTrendsChart/index.tsx:28
#: src/Components/Modal/Content/KeywordInfo/components/GoogleTrendsChart/index.tsx:48
msgid "Trend"
msgstr "Trend"

#: src/Components/Modal/Content/KeywordInfo/index.tsx:31
#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/CompetitorsRanksTable.tsx:21
msgid "Competitor Ranks"
msgstr "Konkurrentplaceringer"

#: src/Components/Modal/Content/KeywordInfo/index.tsx:32
msgid "Dynamic Top 10 Competitors Ranks"
msgstr "Top 10 dynamiske konkurrenters ranks"

#: src/Components/Modal/Content/KeywordInfo/index.tsx:33
msgid "Google Trends"
msgstr "Google Trends"

#: src/Components/Modal/Content/KeywordsComparison/index.tsx:21
msgid "The graph shows how the selected keywords have been ranking over time."
msgstr "Grafen viser hvordan de valgte søgeord har rangeret over tid."

#: src/Components/Modal/Content/KeywordsComparison/index.tsx:24
msgid "Keyword Ranks"
msgstr "Søgeordsplaceringer"

#: src/Components/Modal/Content/LandingPage/index.tsx:53
msgid "Change Preferred URL"
msgstr "Skift foretrukket URL"

#: src/Components/Modal/Content/LandingPage/LandingPageForm/new.tsx:90
#: src/Components/Modal/Content/LandingPage/LandingPageForm/new.tsx:106
msgid "Unable to set landing page"
msgstr "Kunne ikke indstille foretrukket URL"

#: src/Components/Modal/Content/LandingPage/LandingPageForm/new.tsx:141
#: src/Components/Modal/Content/LandingPage/LandingPageForm/new.tsx:143
msgid "Select Preferred URL"
msgstr "Vælg foretrukket URL"

#: src/Components/Modal/Content/LandingPage/LandingPageForm/new.tsx:167
msgid "Remove preferred URL"
msgstr "Fjern foretrukket URL"

#: src/Components/Modal/Content/Messages/index.tsx:41
#: src/Components/Modal/Content/Sales/LockOrganization/index.tsx:102
#: src/Pages/Affiliate/AffiliateCommissions/AffiliateCommissionsTable/index.tsx:84
#: src/Pages/Affiliate/AffiliatePayments/AffiliatePaymentsTable/index.tsx:92
#: src/Pages/ConnectedAccounts/support/hooks.tsx:251
#: src/Pages/ReportsOverview/GeneratedReports/helpers/hooks.tsx:71
#: src/Pages/ReportsOverview/ScheduledReports/helpers/hooks.tsx:134
#: src/Pages/Sales/Search/index.tsx:269 src/Pages/Wallet/WalletsTable.tsx:49
msgid "Type"
msgstr "Type"

#: src/Components/Modal/Content/Messages/index.tsx:46
#: src/Pages/Accounts/SubAccounts/index.tsx:22
msgid "Information"
msgstr "Information"

#: src/Components/Modal/Content/Messages/index.tsx:68
#: src/Components/Modal/Content/RequestAccess/RequestAccessForm/index.tsx:126
#: src/Components/Modal/Content/RequestAccess/RequestAccessForm/index.tsx:127
#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:128
msgid "Message"
msgstr "Besked"

#: src/Components/Modal/Content/Messages/index.tsx:84
msgid "Loading message [from] to [to] of [total] messages"
msgstr "Henter besked [from] til [to] af [total] beskeder"

#: src/Components/Modal/Content/Messages/index.tsx:90
msgid "Message [from] to [to] of [total] messages"
msgstr "Besked [from] til [to] af [total] beskeder"

#: src/Components/Modal/Content/Messages/index.tsx:98
msgid "Your Messages"
msgstr "Dine beskeder"

#: src/Components/Modal/Content/MessagesNew/index.tsx:53
#: src/Pages/Layout/DashboardTemplate/SideNavbar/components/NavbarHeader/useMenuItems.tsx:59
msgid "Messages"
msgstr "Beskeder"

#: src/Components/Modal/Content/MessagesNew/index.tsx:58
msgid "New messages"
msgstr "Nye beskeder"

#: src/Components/Modal/Content/MessagesNew/index.tsx:74
msgid "Mark all as read"
msgstr "Marker alle som læst"

#: src/Components/Modal/Content/MessagesNew/index.tsx:95
msgid "No new messages."
msgstr "Ingen nye beskeder."

#: src/Components/Modal/Content/MessagesNew/index.tsx:138
msgid "Mark as read"
msgstr "Marker som læst"

#: src/Components/Modal/Content/MessagesNew/index.tsx:174
msgid "View read messages"
msgstr "Vis læste beskeder"

#: src/Components/Modal/Content/MoveDomain/index.tsx:20
msgid "Move Domain"
msgstr "Flyt domæne"

#: src/Components/Modal/Content/MoveDomain/MoveDomainForm/index.tsx:89
msgid "Domain moved"
msgstr "Domæne flyttet"

#: src/Components/Modal/Content/MoveDomain/MoveDomainForm/index.tsx:111
#: src/Components/Modal/Content/MoveDomain/MoveDomainForm/index.tsx:215
#: src/Pages/Sales/Tools/index.tsx:42
msgid "Move domain"
msgstr "Flyt domæne"

#: src/Components/Modal/Content/MoveDomain/MoveDomainForm/index.tsx:113
#: src/Pages/Accounts/TransferMultiAccountDomain/index.tsx:52
msgid "Move domain?"
msgstr "Flyt domæne?"

#: src/Components/Modal/Content/MoveDomain/MoveDomainForm/index.tsx:114
msgid ""
"Are you sure you wish to move: %s (%s) from organization %s (%s) to %s (%s) "
"on organization %s (%s)."
msgstr ""
"Er du sikker på at du vil flytte: %s (%s) fra kontoen %s (%s) til %s (%s) på "
"kontoen %s (%s)."

#: src/Components/Modal/Content/MoveDomain/MoveDomainForm/index.tsx:180
msgid "Use this form to move domain to the group."
msgstr "Brug denne formular til at flytte domænet til gruppen."

#: src/Components/Modal/Content/MoveDomain/MoveDomainForm/index.tsx:186
#: src/Pages/Accounts/TransferMultiAccountDomain/TransferMultiAccountDomainForm/TransferMultiAccountDomainForm.tsx:167
msgid "Select domain"
msgstr "Vælg domæne"

#: src/Components/Modal/Content/MoveDomain/MoveDomainForm/index.tsx:196
#: src/Components/Modal/Content/SalesOrganizationDomains/index.tsx:32
#: src/Components/Modal/Wizard/ConnectToGA/Group/index.tsx:44
#: src/Components/Modal/Wizard/ConnectToGSC/GSCBulkWebsites/index.tsx:237
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:162
#: src/Pages/Domains/support/types.ts:76
#: src/Pages/Domains/TreeView/support/constants.ts:40
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:99
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:129
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:131
#: src/Pages/Import/importTable.tsx:189
#: src/Pages/Import/Validation/Status/new.tsx:271
#: src/Pages/KeywordDiscovery/components/KeydisDomainSelect/helpers.tsx:109
#: src/Pages/Sales/Organization/index.tsx:239
#: src/Pages/Sales/Search/index.tsx:110 src/Pages/Sales/Search/index.tsx:170
#: src/Pages/Sales/Search/sales-search-input.tsx:58
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:279
msgid "Group"
msgstr "Gruppe"

#: src/Components/Modal/Content/MoveDomain/MoveDomainForm/index.tsx:200
#: src/Components/Modal/Wizard/ConnectToGA/Group/index.tsx:56
#: src/Pages/Accounts/TransferMultiAccountDomain/TransferMultiAccountDomainForm/TransferMultiAccountDomainForm.tsx:154
msgid "Select group"
msgstr "Vælg gruppe"

#: src/Components/Modal/Content/MoveKeywords/index.tsx:25
msgid "Move to Other Domain"
msgstr "Flyt til et andet domæne"

#: src/Components/Modal/Content/MoveKeywords/MoveKeywordsForm/index.tsx:75
#: src/Pages/ErrorPages/pageError/index.tsx:19 src/Pages/Import/index.tsx:88
#: src/Pages/Import/Validation/Status/new.tsx:166
msgid "Oops! Something went wrong"
msgstr "Ups! Der gik noget galt"

#: src/Components/Modal/Content/MoveKeywords/MoveKeywordsForm/index.tsx:85
msgid "Updated"
msgstr "Opdateret"

#: src/Components/Modal/Content/NewVersion/index.tsx:55
msgid "AccuRanker Has Been Updated"
msgstr "AccuRanker er blevet opdateret"

#: src/Components/Modal/Content/NewVersion/index.tsx:57
msgid ""
"Please refresh AccuRanker to see the new version. See the latest big "
"releases [link: here]."
msgstr ""
"Du skal genindlæse AccuRanker for at bruge den nye version. Se de seneste "
"store ændringer [link: her]."

#: src/Components/Modal/Content/NewVersion/index.tsx:80
#: src/Components/Modal/Content/PermissionDeniedModal/index.tsx:44
msgid "Refresh AccuRanker"
msgstr "Genindlæs AccuRanker"

#: src/Components/Modal/Content/NotAllowedModal/index.tsx:24
msgid "Data Not Found"
msgstr "Data ikke fundet"

#: src/Components/Modal/Content/NotAllowedModal/index.tsx:27
msgid ""
"The data you are editing/viewing no longer exists or your are logged in to "
"another account."
msgstr ""
"Dataene du ændrer/viser eksisterer ikke længere, eller du er logget ind på "
"en anden konto."

#: src/Components/Modal/Content/NotAllowedModal/index.tsx:39
msgid "Reload"
msgstr "Genindlæs"

#: src/Components/Modal/Content/NotOrgAdmin/index.tsx:17
msgid "Only Admins Can Upgrade"
msgstr "Kun administratorere kan opgradere"

#: src/Components/Modal/Content/NotOrgAdmin/index.tsx:20
#: src/Pages/SelectPlan/support/withOnlyOrgPermission.tsx:21
msgid "You need to contact your organization admin to upgrade the account."
msgstr "Du skal kontakte din kontoadministrator for at opgradere kontoen."

#: src/Components/Modal/Content/PartialScrape/index.tsx:58
msgid "You have selected a date that is not fully refreshed"
msgstr "Du har valgt en dato, der endnu ikke er fuldt opdateret"

#: src/Components/Modal/Content/PartialScrape/index.tsx:65
msgid ""
"Your domain is currently undergoing a scraping process. You are trying to "
"view ranks for [periodTo], however [domain] was last refreshed at "
"[lastFullScrape]."
msgstr ""
"Dit domæne gennemgår i øjeblikket en scraping-proces. Du forsøger at se "
"placeringer for [periodTo], men [domain] blev sidst opdateret på "
"[lastFullScrape]."

#: src/Components/Modal/Content/PartialScrape/index.tsx:73
msgid ""
"You are trying to view ranks for [periodTo], however [domain] has not yet "
"been fully refreshed."
msgstr ""
"Du forsøger at se placeringer for [periodTo], men [domain] er endnu ikke "
"fuldt opdateret."

#: src/Components/Modal/Content/PartialScrape/index.tsx:83
msgid "You will therefore only see a subset of ranks for the chosen date."
msgstr "Du vil derfor kun se en del af placeringerne for den valgte dato."

#: src/Components/Modal/Content/PartialScrape/index.tsx:87
msgid "You can manually refresh on [link:"
msgstr "Du kan manuelt opdatere på [link:"

#: src/Components/Modal/Content/PartialScrape/index.tsx:88
msgid "the keywords list"
msgstr "søgeordslisten"

#: src/Components/Modal/Content/PaymentFailed/index.tsx:25
msgid "We were unable to process your payment"
msgstr "Vi kunne ikke gennemføre din betaling"

#: src/Components/Modal/Content/PaymentFailed/index.tsx:32
msgid "Back to payment page"
msgstr "Tilbage til betalingssiden"

#: src/Components/Modal/Content/PaymentSuccess/index.tsx:30
msgid "Payment Confirmed"
msgstr "Betaling godkendt"

#: src/Components/Modal/Content/PaymentSuccess/index.tsx:36
msgid "Go to the billing overview"
msgstr "Gå tilbage til betalingsoverblikket"

#: src/Components/Modal/Content/PermissionDeniedModal/index.tsx:34
msgid "You no longer have access"
msgstr "Du har ikke længere adgang"

#: src/Components/Modal/Content/PermissionDeniedModal/index.tsx:36
msgid ""
"Your access to AccuRanker has been revoked by your organization. Please "
"contact an organization admin to regain access."
msgstr ""
"Din adgang til AccuRanker er blevet tilbagekaldt af din organisation. "
"Kontakt en organisationsadministrator for at genvinde adgang."

#: src/Components/Modal/Content/PermissionDeniedModal/index.tsx:40
msgid "Click on \"Refresh AccuRanker\" button or wait for auto refresh."
msgstr ""
"Klik på knappen \"Opdater AccuRanker\" eller vent på automatisk opdatering."

#: src/Components/Modal/Content/PermissionUpdatedModal/index.tsx:11
msgid "Your permission level changed"
msgstr "Dit tilladelsesniveau er ændret"

#: src/Components/Modal/Content/PermissionUpdatedModal/index.tsx:12
msgid "Your permission level has been changed by your organization."
msgstr "Dit tilladelsesniveau er blevet ændret af din organisation."

#: src/Components/Modal/Content/PermissionUpdatedModal/index.tsx:14
msgid "We’ve applied changes to your account."
msgstr "Vi har anvendt ændringer på din konto."

#: src/Components/Modal/Content/RegisterFailed/index.tsx:17
msgid "We were unable to create your account, please try again."
msgstr "Vi kunne ikke oprette din konto, prøv venligst igen."

#: src/Components/Modal/Content/RegisterFailed/index.tsx:28
msgid "Back to sign up"
msgstr "Tilbage til opret konto"

#: src/Components/Modal/Content/RegisterPhoneVerify/form.tsx:149
msgid "Send code by SMS"
msgstr "Send kode på SMS"

#: src/Components/Modal/Content/RegisterPhoneVerify/form.tsx:163
msgid "Send code by automated voice call"
msgstr "Send kode ved automatisk telefonopkald"

#: src/Components/Modal/Content/RegisterPhoneVerify/form.tsx:174
msgid "Please enter the code you have received:"
msgstr "Indtast den kode du har modtaget:"

#: src/Components/Modal/Content/RegisterPhoneVerify/form.tsx:178
#: src/Pages/Register/components/ContactInfo.tsx:155
msgid "Code"
msgstr "Kode"

#: src/Components/Modal/Content/RegisterPhoneVerify/form.tsx:179
msgid "Enter code"
msgstr "Indtast kode"

#: src/Components/Modal/Content/RegisterPhoneVerify/form.tsx:205
msgid "Update & send"
msgstr "Opdater & send"

#: src/Components/Modal/Content/RegisterPhoneVerify/form.tsx:207
msgid "Validate code"
msgstr "Bekræft kode"

#: src/Components/Modal/Content/RegisterPhoneVerify/form.tsx:208
msgid "Send code"
msgstr "Send kode"

#: src/Components/Modal/Content/RegisterPhoneVerify/form.tsx:222
msgid "Edit phone number or verification method"
msgstr "Rediger telefonnummeret eller bekræftelsesmetoden"

#: src/Components/Modal/Content/RegisterPhoneVerify/index.tsx:23
msgid "Please verify your phone number"
msgstr "Bekræft venligst dit telefonnummer"

#: src/Components/Modal/Content/RemoveTags/index.tsx:116
msgid "Select the tags you want to delete:"
msgstr "Vælg de tags du vil slette:"

#: src/Components/Modal/Content/RemoveTags/index.tsx:126
msgid "Remove tags"
msgstr "Slet tags"

#: src/Components/Modal/Content/RemoveTags/index.tsx:136
#: src/Components/Modal/Content/RemoveTags/index.tsx:165
msgid "Dynamic Tags can be deleted from the [link:Tag Cloud tab]."
msgstr "Dynamiske tags kan slettes fra [link:Tag Cloud-fanen]."

#: src/Components/Modal/Content/RemoveTags/index.tsx:155
msgid "Found no tags for the selected keywords."
msgstr "Fandt ingen tags for de valgte søgeord."

#: src/Components/Modal/Content/RemoveTags/index.tsx:185
msgid "Delete Tags"
msgstr "Slet tags"

#: src/Components/Modal/Content/RequestAccess/index.tsx:15
msgid "Request for access to your AccuRanker account"
msgstr "Anmodning om adgang til din AccuRanker konto"

#: src/Components/Modal/Content/RequestAccess/index.tsx:18
msgid "Request Access"
msgstr "Forespørg om adgang"

#: src/Components/Modal/Content/RequestAccess/RequestAccessForm/index.tsx:79
msgid "Request sent"
msgstr "Forespørgsel sendt"

#: src/Components/Modal/Content/RequestAccess/RequestAccessForm/index.tsx:102
#: src/Pages/Accounts/TransferMultiAccountDomain/TransferMultiAccountDomainForm/TransferMultiAccountDomainForm.tsx:142
msgid "From organization"
msgstr "Fra organisation"

#: src/Components/Modal/Content/RequestAccess/RequestAccessForm/index.tsx:104
msgid "Select your organization"
msgstr "Vælg organisation"

#: src/Components/Modal/Content/RequestAccess/RequestAccessForm/index.tsx:112
#: src/Components/Modal/Content/RequestAccess/RequestAccessForm/index.tsx:114
msgid "To email"
msgstr "Til e-mail"

#: src/Components/Modal/Content/RequestAccess/RequestAccessForm/index.tsx:120
#: src/Components/Modal/Content/RequestAccess/RequestAccessForm/index.tsx:121
#: src/Components/Modal/Content/Sales/MailDetails/index.tsx:61
#: src/Pages/Sales/Mails/Table/support/useMailsTableInfo.tsx:48
msgid "Subject"
msgstr "Emne"

#: src/Components/Modal/Content/RequestAccess/RequestAccessForm/index.tsx:131
msgid "Access the account as an admin user"
msgstr "Tilgå kontoen som admin-bruger"

#: src/Components/Modal/Content/RequestAccess/RequestAccessForm/index.tsx:132
msgid "Send a copy to myself"
msgstr "Send en kopi til mig selv"

#: src/Components/Modal/Content/RequestAccess/RequestAccessForm/index.tsx:138
msgid "Send request"
msgstr "Send forespørgsel"

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:105
msgid "Could not create invoice."
msgstr "Kunne ikke lave faktura."

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:108
msgid "Invoice created."
msgstr "Faktura oprettet."

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:132
msgid "%s month"
msgid_plural "%s months"
msgstr[0] "%s måned"
msgstr[1] "%s måneder"

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:165
msgid "Create Invoice"
msgstr "Opret faktura"

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:216
#: src/Pages/Billing/OrderPlanWidget/index.tsx:56
#: src/Pages/Billing/Overview/Plan/index.tsx:61
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/components/OveruseInfoDetails.tsx:82
msgid "Billing cycle"
msgstr "Faktureringsperiode"

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:242
msgid "Make plan changes"
msgstr "Lav abonnementsændringer"

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:243
msgid "All prices are in %s"
msgstr "Alle priser er i %s"

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:249
#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:90
#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:248
msgid "Max keywords"
msgstr "Max antal søgeord"

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:255
#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:297
msgid "Price for the selected period"
msgstr "Pris for den valgte periode"

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:262
msgid "Enable 10% discount on yearly plans compared to monthly"
msgstr "Tilføj 10% rabat på årlige planer sammenlignet med den månedlige pris"

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:294
msgid ""
"The discount does not affect the price entered in the [boldLabel] field. "
"However, it is used to calculate the plan's price if the billing cycle is "
"changed from yearly to monthly payment or vice versa.\n"
"\n"
"[headline1]\n"
"Checked: If the customer changes to a monthly plan, the price will be higher "
"than the yearly price / 12 [DEFAULT].\n"
"Unchecked: If the customer changes to a monthly plan, the price will be the "
"yearly price / 12.\n"
"\n"
"[headline2]\n"
"If the customer switches to an yearly plan, the price will be less than the "
"monthly price * 12.\n"
"Unchecked: If the customer switches to an yearly plan, the price will be the "
"monthly price * 12 [DEFAULT]."
msgstr ""
"Rabatten påvirker ikke prisen, der er indtastet i feltet [boldLabel]. Den "
"bruges dog til at beregne planens pris, hvis faktureringscyklussen ændres "
"fra årlig til månedlig betaling eller omvendt.\n"
"\n"
"[headline1]\n"
"Afkrydset: Hvis kunden skifter til en månedlig plan, vil prisen være højere "
"end årsprisen / 12 [STANDARD].\n"
"Ikke afkrydset: Hvis kunden skifter til en månedlig plan, vil prisen være "
"årsprisen / 12.\n"
"\n"
"[headline2]\n"
"Hvis kunden skifter til en årlig plan, vil prisen være mindre end "
"månedsprisen * 12.\n"
"Ikke afkrydset: Hvis kunden skifter til en årlig plan, vil prisen være "
"månedsprisen * 12 [STANDARD]."

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:299
msgid "Billing cycle = 12 months (called yearly plan)"
msgstr "Faktureringscyklus = 12 måneder (kaldet årlig plan)"

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:302
msgid "Billing cycle < 12 months (called monthly plan)"
msgstr "Faktureringscyklus < 12 måneder (kaldet månedlig plan)"

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:312
msgid "Optional message to be displayed on the invoice"
msgstr "Valgfri besked, der skal vises på fakturaen"

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:318
msgid "Free start period before the payment starts"
msgstr "Gratis startperiode, før betalingen starter"

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:324
msgid "Payment start date"
msgstr "Startdato for betaling"

#: src/Components/Modal/Content/Sales/CreateInvoice/index.tsx:336
#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:262
msgid "Create invoice"
msgstr "Opret faktura"

#: src/Components/Modal/Content/Sales/DemoContent/index.tsx:47
msgid "Content added"
msgstr "Indhold tilføjet"

#: src/Components/Modal/Content/Sales/DemoContent/index.tsx:68
msgid "Add Demo Organizations"
msgstr "Tilføj demo-organisationer"

#: src/Components/Modal/Content/Sales/DemoContent/index.tsx:70
msgid "Organizations"
msgstr "Organisationer"

#: src/Components/Modal/Content/Sales/DemoContent/index.tsx:80
msgid "Only visible using API"
msgstr "Kun synlig via API"

#: src/Components/Modal/Content/Sales/Extend/index.tsx:47
msgid "Plan extended"
msgstr "Plan forlænget"

#: src/Components/Modal/Content/Sales/Extend/index.tsx:58
msgid "Extend"
msgstr "Udvid"

#: src/Components/Modal/Content/Sales/Extend/index.tsx:61
msgid "Days to extend"
msgstr "Forlæng antal dage"

#: src/Components/Modal/Content/Sales/Extend/index.tsx:64
msgid "Enter the amount of days the plan should be extended."
msgstr "Indtast det antal dage planen skal forlænges med."

#: src/Components/Modal/Content/Sales/Extend/index.tsx:70
msgid ""
"Please note that you will add the above days to when the last plan of the "
"organization end. Meaning that if the last plan ended on 1/1-2018 and you "
"add 20 days the plan will end on 20/1-2018 (it will also re-activate the "
"organization if this new end date is in the future)"
msgstr ""
"Bemærk at du tilføjer ovenstående dage til hvornår den sidste plan på "
"firmaet slutter. Dvs. at hvis den sidste plan slutter d. 1/1-2018 og du "
"tilføjer 20 dage, så vil planen slutte d. 20/1-2018 (det vil også "
"genaktivere kontoen hvis den nye slut-dato er i fremtiden)"

#: src/Components/Modal/Content/Sales/LockOrganization/index.tsx:64
msgid "Organization locked"
msgstr "Firma låst"

#: src/Components/Modal/Content/Sales/LockOrganization/index.tsx:109
#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:347
msgid "Abuse"
msgstr "Misbrug"

#: src/Components/Modal/Content/Sales/LockOrganization/index.tsx:113
msgid "No contact"
msgstr "Ingen kontakt"

#: src/Components/Modal/Content/Sales/LockOrganization/index.tsx:117
msgid "Pause"
msgstr "Pause"

#: src/Components/Modal/Content/Sales/LockOrganization/index.tsx:124
msgid "Blacklist domains"
msgstr "Sortlist domæner"

#: src/Components/Modal/Content/Sales/LockOrganization/index.tsx:128
msgid "Custom message"
msgstr "Custom besked"

#: src/Components/Modal/Content/Sales/LockOrganization/index.tsx:131
msgid "Enter custom message to be shown on blocked page"
msgstr "Indtast besked der skal vises på den blokerede side"

#: src/Components/Modal/Content/Sales/LockOrganization/index.tsx:137
#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:556
msgid "Lock Account"
msgstr "Lås konto"

#: src/Components/Modal/Content/Sales/LockOrganization/index.tsx:148
msgid "Lock Organization"
msgstr "Lås firma"

#: src/Components/Modal/Content/Sales/MailDetails/index.tsx:37
msgid "Mail details"
msgstr "Mail-detaljer"

#: src/Components/Modal/Content/Sales/MailDetails/index.tsx:41
#: src/Pages/Sales/Invoice/Customers/SalesInvoiceCustomersTable/hooks.tsx:73
msgid "Created at"
msgstr "Oprettet den"

#: src/Components/Modal/Content/Sales/MailDetails/index.tsx:45
msgid "Original-Message-ID"
msgstr "Original-Message-ID"

#: src/Components/Modal/Content/Sales/MailDetails/index.tsx:49
msgid "Recipients"
msgstr "Modtagere"

#: src/Components/Modal/Content/Sales/MailDetails/index.tsx:53
msgid "From email"
msgstr "Fra e-mail"

#: src/Components/Modal/Content/Sales/MailDetails/index.tsx:57
msgid "Reply to"
msgstr "Svar til"

#: src/Components/Modal/Content/Sales/MailDetails/index.tsx:65
msgid "Opened"
msgstr "Åbnet"

#: src/Components/Modal/Content/Sales/MailDetails/index.tsx:69
msgid "MTA accepted"
msgstr "MTA accepteret"

#: src/Components/Modal/Content/Sales/MailDetails/index.tsx:73
msgid "MTA error"
msgstr "MTA fejl"

#: src/Components/Modal/Content/Sales/MailDetails/index.tsx:77
msgid "Notifications types"
msgstr "Notifikationstyper"

#: src/Components/Modal/Content/Sales/MailDetails/index.tsx:81
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:101
#: src/Pages/Keywords/Overview/components/OverviewNotifications/OverviewNotifications.tsx:20
msgid "Notifications"
msgstr "Notifikationer"

#: src/Components/Modal/Content/Sales/MakeAffiliate/index.tsx:48
msgid "Affiliate could not be created."
msgstr "Affiliate kunne ikke oprettes."

#: src/Components/Modal/Content/Sales/MakeAffiliate/index.tsx:51
msgid "Affiliate created."
msgstr "Affiliate oprettet."

#: src/Components/Modal/Content/Sales/MakeAffiliate/index.tsx:75
msgid "Make Organization Affiliate"
msgstr "Lav firma til affiliate"

#: src/Components/Modal/Content/Sales/MakeAffiliate/index.tsx:78
msgid "Select primary affiliate contact"
msgstr "Vælg primær affliate kontakt"

#: src/Components/Modal/Content/Sales/MakeAffiliate/index.tsx:89
#: src/Components/Modal/Content/Sales/MakeAffiliate/index.tsx:93
msgid "PayPal email"
msgstr "PayPal e-mail"

#: src/Components/Modal/Content/Sales/MakeAffiliate/index.tsx:97
msgid "Enter PayPal email"
msgstr "Indtast PayPal e-mail"

#: src/Components/Modal/Content/Sales/OrganizationNote/index.tsx:20
msgid "[mode] Note"
msgstr "[mode] Notat"

#: src/Components/Modal/Content/Sales/OrganizationNote/OrgNoteForm/OrgNoteForm.tsx:65
msgid "Enter your note here..."
msgstr "Indtast din note her..."

#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:55
msgid "Plan update failed"
msgstr "Plan opdatering fejlede"

#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:57
msgid "Plan updated"
msgstr "Plan opdateret"

#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:64
#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:73
msgid "Edit Organization Plan"
msgstr "Rediger organisationsplan"

#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:66
msgid "Loading"
msgstr "Henter"

#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:93
msgid "Amount of keywords available on this plan. Current amount: "
msgstr "Antal søgeord tilgængelig på dette abonnement. Nuværende antal: "

#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:104
#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:255
msgid "Max competitors"
msgstr "Maks konkurrenter"

#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:107
msgid "Amount of competitors available on this plan. Current amount: "
msgstr "Antal konkurrenter tilgængelig på denne plan. Nuværende antal: "

#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:117
#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:262
msgid "Max Workspaces"
msgstr "Max antal workspaces"

#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:120
msgid "Amount of Workspaces available on this plan. Current amount: "
msgstr "Antal workspaces tilgængelig på dette abonnement. Nuværende antal: "

#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:130
#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:287
msgid "Advanced metrics"
msgstr "Avancerede beregninger"

#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:133
msgid "Enable advanced metrics on this plan."
msgstr "Aktiver avancerede beregninger på denne plan."

#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:138
#: src/Pages/Integrations/hooks/useIntegrations.tsx:370
msgid "API"
msgstr "API"

#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:142
msgid "Enable API on this plan."
msgstr "Slå API til på denne plan."

#: src/Components/Modal/Content/Sales/OrganizationPlan/index.tsx:147
msgid "Enable write API on this plan."
msgstr "Slå skrive API til på denne plan."

#: src/Components/Modal/Content/Sales/PayAffiliate/index.tsx:38
msgid "Could not pay affiliate."
msgstr "Kunne ikke betale affiliate."

#: src/Components/Modal/Content/Sales/PayAffiliate/index.tsx:41
msgid "Affiliate paid."
msgstr "Affiliate betalt."

#: src/Components/Modal/Content/Sales/PayAffiliate/index.tsx:58
msgid "Money owed to affiliate:"
msgstr "Skyldigt beløb til affiliate:"

#: src/Components/Modal/Content/Sales/PayAffiliate/index.tsx:62
msgid "USD:"
msgstr "USD:"

#: src/Components/Modal/Content/Sales/PayAffiliate/index.tsx:68
msgid "EUR:"
msgstr "EUR:"

#: src/Components/Modal/Content/Sales/PayAffiliate/index.tsx:74
msgid "DKK:"
msgstr "DKK:"

#: src/Components/Modal/Content/Sales/PayAffiliate/index.tsx:80
msgid "Minimum payout USD:"
msgstr "Minimum udbetaling:"

#: src/Components/Modal/Content/Sales/PayAffiliate/index.tsx:86
msgid "Total USD:"
msgstr "Total USD:"

#: src/Components/Modal/Content/Sales/PayAffiliate/index.tsx:96
#: src/Pages/Register/index.tsx:302
msgid "Pay"
msgstr "Betal"

#: src/Components/Modal/Content/Sales/PayAffiliate/index.tsx:102
msgid ""
"[b:Note on Total USD:][br]This an approximate total in USD.[br]We do not "
"payout this amount. We only use it to compare to \"Minimum payout USD\""
msgstr ""
"[b:Bemærk om total USD:][br]Dette er et omtrentligt total i USD.[br]Vi "
"udbetaler ikke dette beløb. Vi bruger det kun til at sammenligne med "
"\"Minimum udbetaling USD\""

#: src/Components/Modal/Content/Sales/PayAffiliate/index.tsx:114
msgid "Pay Affiliate"
msgstr "Betal affiliate"

#: src/Components/Modal/Content/Sales/Refund/index.tsx:44
msgid "Organization refunded"
msgstr "Firma refunderet"

#: src/Components/Modal/Content/Sales/Refund/index.tsx:51
#: src/Components/Modal/Content/Sales/Refund/index.tsx:80
#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:347
#: src/Pages/Sales/Organization/index.tsx:731
msgid "Refund"
msgstr "Refunder"

#: src/Components/Modal/Content/Sales/Refund/index.tsx:58
msgid "Reason for the refund"
msgstr "Begrundelse for refundering"

#: src/Components/Modal/Content/Sales/Refund/index.tsx:59
msgid "What is the reason for this refund?"
msgstr "What er grunden til denne refundering?"

#: src/Components/Modal/Content/Sales/Refund/index.tsx:65
msgid "Partial refund"
msgstr "Delvis refundering"

#: src/Components/Modal/Content/Sales/Refund/index.tsx:66
msgid "Amount to refund excl. VAT. Leave empty for full refund."
msgstr "Beløb der skal refunderes eks. moms. Tomt for det fulde beløb."

#: src/Components/Modal/Content/Sales/Refund/index.tsx:71
msgid "High Risk - Skip Braintree refund"
msgstr "Høj risiko - Skip Braintree refund"

#: src/Components/Modal/Content/Sales/Refund/index.tsx:72
msgid ""
"Check this to bypass settlement in Braintree. You should only use this for "
"customers paying by invoice and only if you are 100% sure of what you are "
"doing!"
msgstr ""
"Markér dette for at omgå afregning i Braintree. Du bør kun bruge dette til "
"kunder, der betaler via faktura, og kun hvis du er 100 % sikker på, hvad du "
"gør!"

#: src/Components/Modal/Content/Sales/UpdateInvoiceContactInfo/index.tsx:47
msgid "Could not update invoice:[br][msgs]"
msgstr "Kunne ikke opdatere faktura:[br][msgs]"

#: src/Components/Modal/Content/Sales/UpdateInvoiceContactInfo/index.tsx:54
msgid "Invoice updated."
msgstr "Faktura opdateret."

#: src/Components/Modal/Content/Sales/UpdateInvoiceContactInfo/index.tsx:64
msgid "Update the contact details on the issued invoice."
msgstr "Opdater kontaktdetaljerne på den udstedte faktura."

#: src/Components/Modal/Content/Sales/UpdateInvoiceContactInfo/index.tsx:65
msgid "It will NOT change the details if country or VAT number has changed."
msgstr ""
"Det skifter IKKE detaljerne hvis land eller momsnummer er blevet ændret."

#: src/Components/Modal/Content/Sales/UpdateInvoiceContactInfo/index.tsx:69
msgid "Update invoice"
msgstr "Opdater faktura"

#: src/Components/Modal/Content/Sales/UpdateInvoiceContactInfo/index.tsx:77
msgid "Update payment contact on invoice"
msgstr "Opdater betalingskontakt på faktura"

#: src/Components/Modal/Content/SalesOrganizationAbuseLog/index.tsx:28
msgid "Abuse Log"
msgstr "Misbrugslog"

#: src/Components/Modal/Content/SalesOrganizationAbuseLog/index.tsx:41
#: src/Pages/Sales/Organization/index.tsx:802
msgid "Log"
msgstr "Log"

#: src/Components/Modal/Content/SalesOrganizationDomains/index.tsx:21
#: src/Components/Modal/Content/SalesOrganizationDomains/index.tsx:28
msgid "Organization domains"
msgstr "Firmadomæner"

#: src/Components/Modal/Content/SelectTagTypeModal/index.tsx:30
#: src/Pages/Keywords/Groupings/EditMode/components/EditModeEmptyContent.tsx:36
msgid "Create Tag"
msgstr "Opret tag"

#: src/Components/Modal/Content/SelectTagTypeModal/index.tsx:33
msgid "What kind of tag would you like to create?"
msgstr "Hvilket slags tag vil du oprette?"

#: src/Components/Modal/Content/SelectTagTypeModal/index.tsx:40
msgid "Dynamic tag"
msgstr "Dynamisk tag"

#: src/Components/Modal/Content/SelectTagTypeModal/index.tsx:43
msgid "Static tag"
msgstr "Statisk tag"

#: src/Components/Modal/Content/SelectTagTypeModal/index.tsx:51
msgid ""
"Learn how you can use [linkDynamicTag:dynamic tags] and [linkStaticTag:"
"static tags]."
msgstr ""
"Lær hvordan du bruger [linkDynamicTag:dynamiske tags] og [linkStaticTag:"
"statiske tags]."

#: src/Components/Modal/Content/SessionBlockModal/SessionBlockModal.tsx:73
msgid ""
"Someone else is using the same user account as you to access AccuRanker. For "
"security reasons we do not allow account sharing. If you have just been "
"using your account on another computer, you can click the [assumeCtrl:"
"\"Assume control\"] button to resume you work on this computer. Note that "
"doing so will show this prompt on anyone else who might be using this user "
"account right now. If you want to log out and sign in as another user, "
"please click the [logOut:\"Log out\"] button"
msgstr ""
"Din AccuRanker-konto er i brug på en anden enhed. Af sikkerhedsmæssige "
"årsager tillader vi ikke delte konti. Hvis du har brugt din konto på en "
"anden computer, kan du klikke [assumeCtrl:\"Overtag\"] herunder for at "
"genoptage dit arbejde på denne computer. Vær opmærksom på at det vil blokere "
"andre, der måtte bruge din konto lige nu. Hvis du ønsker at logge på som en "
"anden bruger, så klik [logOut:\"Log ud\"] herunder"

#: src/Components/Modal/Content/SessionBlockModal/SessionBlockModal.tsx:93
msgid ""
"Please note that we allow unlimited users on your account, so to avoid this "
"in future please create an AccuRanker user just for you. "
msgstr ""
"Dit abonnement tillader et ubegrænset antal brugere, så for at undgå dette "
"fremover bør du oprette en personlig AccuRanker-bruger. "

#: src/Components/Modal/Content/SessionBlockModal/SessionBlockModal.tsx:97
msgid ""
"Click the [createUser:\"Create user\"] button to create a new user account."
msgstr ""
"Klik på [createUser:\"Opret bruger\"] knappen for at lave et nyt login."

#: src/Components/Modal/Content/SessionBlockModal/SessionBlockModal.tsx:108
msgid "Create your own user free of charge, contact one of your admins:"
msgstr ""
"Få din egen personlige brugerkonto. Kontakt en af dine administratorer:"

#: src/Components/Modal/Content/SessionBlockModal/SessionBlockModal.tsx:134
msgid "Your account is in use on another computer"
msgstr "Din konto er i brug på en anden computer"

#: src/Components/Modal/Content/SessionBlockModal/SessionBlockModal.tsx:138
msgid "Session Block Modal"
msgstr "Session blokeret"

#: src/Components/Modal/Content/SessionBlockModal/SessionBlockModal.tsx:150
msgid "Create user"
msgstr "Opret bruger"

#: src/Components/Modal/Content/SessionBlockModal/SessionBlockModal.tsx:159
msgid "Assume Control"
msgstr "Overtag"

#: src/Components/Modal/Content/SessionBlockModal/SessionBlockModal.tsx:162
msgid "Log out"
msgstr "Log ud"

#: src/Components/Modal/Content/Shortcuts/index.tsx:27
msgid "Close opened menu"
msgstr "Luk den åbne menu"

#: src/Components/Modal/Content/Shortcuts/index.tsx:31
msgid "Open quick navigation"
msgstr "Åbn hurtig-navigation"

#: src/Components/Modal/Content/Shortcuts/index.tsx:35
msgid "Open keywords or domains filter"
msgstr "Åbn søgeords- eller domænefilter"

#: src/Components/Modal/Content/Shortcuts/index.tsx:45
msgid "Open copy to clipboard modal"
msgstr "Åben Kopier til udklipsholder modal"

#: src/Components/Modal/Content/Shortcuts/index.tsx:85
msgid "or"
msgstr "eller"

#: src/Components/Modal/Content/Shortcuts/index.tsx:111
msgid "Keyboard Shortcuts"
msgstr "Tastaturgenveje"

#: src/Components/Modal/Content/Shortcuts/index.tsx:115
msgid "Shortcut"
msgstr "Genvej"

#: src/Components/Modal/Content/ShowServiceMessageModal/index.tsx:51
msgid "Service Message"
msgstr "Servicemeddelelse"

#: src/Components/Modal/Content/SupportedCountriesList/index.tsx:52
msgid "Use in CSV"
msgstr "Brug i CSV"

#: src/Components/Modal/Content/SupportedCountriesList/index.tsx:53
msgid "Supported search engines"
msgstr "Understøttede søgemaskiner"

#: src/Components/Modal/Content/SupportedCountriesList/index.tsx:102
msgid "List of countries"
msgstr "Liste over lande"

#: src/Components/Modal/Content/TableSettingsModal/components/TableSettingsForm/components/ColumnCheckboxGroupField.tsx:82
msgid "Uncheck all"
msgstr "Fravælg alle"

#: src/Components/Modal/Content/TableSettingsModal/components/TableSettingsForm/components/ColumnCheckboxGroupField.tsx:82
msgid "Check all"
msgstr "Vælg alle"

#: src/Components/Modal/Content/TableSettingsModal/components/TableSettingsForm/components/ColumnCheckboxGroupField.tsx:154
msgid "Analytics account required"
msgstr "Analytics konto påkrævet"

#: src/Components/Modal/Content/TableSettingsModal/components/TableSettingsForm/components/ColumnCheckboxGroupField.tsx:161
msgid ""
"The following columns are included in any plan with advanced metrics, "
"upgrade now to unlock these metrics."
msgstr ""
"De følgende kolonner er inkluderet i alle planer med avancerede beregninger. "
"Opgrader nu for at låse tallene op."

#: src/Components/Modal/Content/TableSettingsModal/components/TableSettingsForm/TableSettingsForm.tsx:37
msgid "Customize which columns you wish to see for this table."
msgstr "Tilpas hvilke kolonner du ønsker at se i denne tabel."

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/components/TableSettingGroup.tsx:55
msgid "(can’t be reordered)"
msgstr "(kan ikke omraarangeres)"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/NextTableSettingsForm.tsx:45
msgid "Reset to default"
msgstr "Nulstil til standard"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/NextTableSettingsForm.tsx:73
msgid "Include all"
msgstr "Inkluder alt"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/NextTableSettingsForm.tsx:98
msgid "(Drag to reorder column groups)"
msgstr "(Træk for at omarrangere kolonnegrupper)"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:15
msgid "Keyword information"
msgstr "Søgeordsinformation"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:30
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:222
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:227
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:298
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:303
#: src/Pages/SelectPlan/plan-features.tsx:24
msgid "Search Type"
msgstr "Søgetype"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:34
#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/SearchIntent.tsx:92
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:87
#: src/Pages/Keywords/Overview/components/SearchIntent/SearchIntentChartContent.tsx:50
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:318
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:324
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:93
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:96
#: src/Pages/PublicReport/support/useSegmentedControlItems.tsx:34
msgid "Search Intent"
msgstr "Søgeintention"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:40
msgid "Location information"
msgstr "Placeringsinformationer"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:66
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:73
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:80
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:85
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:101
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:131
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:145
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:162
#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:176
#: src/Pages/Domains/support/types.ts:97 src/Pages/Domains/support/types.ts:115
#: src/Pages/Domains/support/types.ts:133
msgid "Change"
msgstr "Ændring"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:84
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:69
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:635
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:637
msgid "Pixels from Top"
msgstr "Pixels fra Top"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:91
msgid "Essentials"
msgstr "Essentiele"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:96
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:354
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:365
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:761
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:768
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:185
msgid "Click-through Rate"
msgstr "Click-through rate"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:106
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:396
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:407
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:795
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:802
msgid "Max Click-through Rate"
msgstr "Max click-through rate"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:180
msgid "Cost per Click"
msgstr "Cost per click"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:185
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:893
msgid "Competition"
msgstr "Competition"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:189
msgid "Keyword Competition"
msgstr "Keyword Competition"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:200
msgid "Timestamp"
msgstr "Tidsstempel"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:202
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:329
#: src/Pages/Domains/support/types.ts:147
msgid "Created On"
msgstr "Oprettet den"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:203
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1079
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1081
msgid "Last Update"
msgstr "Seneste opdatering"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:208
#: src/Pages/Accounts/PendingRequests/AccountsRequestsTable/support/hooks.tsx:126
#: src/Pages/ConnectedAccounts/support/hooks.tsx:341
#: src/Pages/Domains/support/types.ts:152
#: src/Pages/ExternalAccess/OwnersTable/index.tsx:117
#: src/Pages/ExternalAccess/PendingOwnersTable/index.tsx:115
#: src/Pages/Groups/GroupsTable/support/useGroupsTableConfig.tsx:129
#: src/Pages/Keywords/Competitors/support/constants.ts:260
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:846
#: src/Pages/PausedDomains/index.tsx:286
#: src/Pages/ReportsOverview/GeneratedReports/helpers/hooks.tsx:131
#: src/Pages/ReportsOverview/ScheduledReports/helpers/hooks.tsx:229
#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:296
#: src/Pages/Sales/CreatePlan/SalesPlanChoicesTable/index.tsx:98
#: src/Pages/Sales/Organization/index.tsx:707
#: src/Pages/Sales/Plans/SalesPlansTable/support/hooks.tsx:100
#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:526
#: src/Pages/Users/UsersTable/getColumns.tsx:128
#: src/Pages/Workspaces/WorkspacesTable/getColumns.tsx:87
msgid "Actions"
msgstr "Handlinger"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:215
msgid "View SERP"
msgstr "Se SERP'en"

#: src/Components/Modal/Content/TableSettingsModal/NextTableSettings/support/config.tsx:223
msgid "Refresh Keyword"
msgstr "Opdater søgeord"

#: src/Components/Modal/Content/TableSettingsModal/support/hooks/useSaveTableSettings.tsx:72
#: src/Components/Modal/Content/TableSettingsModal/support/hooks/useSaveTableSettings.tsx:79
msgid "Failed to save new table settings."
msgstr "Nye tabelindstillinger kunne ikke gemmes."

#: src/Components/Modal/Content/TableSettingsModal/TableSettingsModal.tsx:65
msgid "Configure Columns"
msgstr "Konfigurer kolonner"

#: src/Components/Modal/Content/TableSettingsModal/TableSettingsModal.tsx:89
msgid "Table Settings"
msgstr "Tabelindstillinger"

#: src/Components/Modal/Content/UpsellModal/index.tsx:22
msgid "You Cannot Edit a Demo Domain"
msgstr "Du kan ikke ændre et demo domæne"

#: src/Components/Modal/Content/UpsellModal/index.tsx:25
msgid ""
"You are trying to edit a demo account. If you wish to add or edit keywords "
"please add a new domain on the dashboard."
msgstr ""
"Du forsøger at ændre et demo domæne. Hvis du vil tilføje søgeord skal du "
"oprette et nyt domæne."

#: src/Components/Modal/Layout/ModalBorder/index.tsx:130
msgid "An error occurred. Please try again."
msgstr "Der opstod en fejl. Prøv venligst igen."

#: src/Components/Modal/Layout/ModalBorder/index.tsx:133
#: src/Pages/ErrorPages/500/index.tsx:49
msgid "If the problem persists, please [support]."
msgstr "Hvis problemet fortsætte, bedes du [support]."

#: src/Components/Modal/Layout/ModalBorder/index.tsx:134
msgid "contact support"
msgstr "Kontakt supporten"

#: src/Components/Modal/Layout/ModalBorder/index.tsx:140
#: src/Pages/ErrorPages/500/index.tsx:55
msgid "Event id:"
msgstr "Event id:"

#: src/Components/Modal/Layout/ModalBorder/index.tsx:143
#: src/Pages/ErrorPages/500/index.tsx:58
#: src/Pages/Profile/components/IntegrationsApiToken/index.tsx:75
#: src/Pages/Profile/components/IntegrationsApiToken/index.tsx:89
msgid "copied"
msgstr "kopieret"

#: src/Components/Modal/Layout/ModalBorder/index.tsx:143
#: src/Pages/ErrorPages/500/index.tsx:58
msgid "Copy id to clipboard"
msgstr "Kopiér til udklipsholder"

#: src/Components/Modal/Layout/ModalBorder/index.tsx:164
msgid "Close modal"
msgstr "Luk dette vindue"

#: src/Components/Modal/Wizard/Common/ConnectOAuthAccount/index.tsx:81
#: src/Components/Modal/Wizard/ConnectToAdobe/ConnectAdobeAccount/index.tsx:63
#: src/Components/Modal/Wizard/ConnectToBigQuery/ConnectBigQuery/index.tsx:57
msgid "Failed to add connection"
msgstr "Kunne ikke oprette forbindelse"

#: src/Components/Modal/Wizard/Common/ConnectOAuthAccount/index.tsx:108
msgid "Connection Name"
msgstr "Forbindelsesnavn"

#: src/Components/Modal/Wizard/Common/ConnectOAuthAccount/index.tsx:112
#: src/Components/Modal/Wizard/ConnectToBigQuery/ConnectBigQuery/index.tsx:87
#: src/Components/Modal/Wizard/ConnectToBigQuery/ConnectBigQuery/index.tsx:89
msgid "Connection name"
msgstr "Forbindelsesnavn"

#: src/Components/Modal/Wizard/Common/ConnectOAuthAccount/index.tsx:117
msgid "The name is used to identify the connection in the integrations list."
msgstr "Navnet bruges til at identificere forbindelsen i integrationslisten."

#: src/Components/Modal/Wizard/Common/ConnectOAuthAccount/index.tsx:134
#: src/Components/Modal/Wizard/ConnectToAdobe/ConnectAdobeAccount/index.tsx:122
#: src/Pages/Profile/components/GoogleDriveAccount/index.tsx:92
msgid "Add connection"
msgstr "Tilføj forbindelse"

#: src/Components/Modal/Wizard/Common/GoogleAccounts/SaveGoogleAccount/index.tsx:43
#: src/Components/Modal/Wizard/Common/GoogleAccounts/SaveGoogleAccount/index.tsx:89
msgid "Failed to verify state"
msgstr "Kunne ikke validere tilstand"

#: src/Components/Modal/Wizard/Common/GoogleAccounts/SaveGoogleAccount/index.tsx:78
msgid "Failed to save OAuth token"
msgstr "Kunne ikke gemme OAuth token"

#: src/Components/Modal/Wizard/Common/GoogleAccounts/SelectGoogleAccount/index.tsx:84
msgid "Google Search Console (%s)"
msgstr "Google Search Console (%s)"

#: src/Components/Modal/Wizard/Common/GoogleAccounts/SelectGoogleAccount/index.tsx:91
msgid "Select the Google connection you want to use."
msgstr "Vælg den Google-forbindelse, du vil bruge."

#: src/Components/Modal/Wizard/Common/GoogleAccounts/SelectGoogleAccount/index.tsx:110
#: src/Components/Modal/Wizard/ConnectToBigQuery/SelectBigQueryAccount/index.tsx:120
msgid "Add new connection"
msgstr "Tilføj ny forbindelse"

#: src/Components/Modal/Wizard/Common/GoogleAccounts/SelectGoogleAccount/index.tsx:117
msgid ""
"This domain is already connected to [account] Google Account, you can "
"connect to another account above or disconnect [link:here]."
msgstr ""
"Dette domæne er allerede forbundet til [account] Google konto. Du kan "
"forbinde til en anden konto ovenover eller afbryde forbindelsen [link:her]."

#: src/Components/Modal/Wizard/Common/GoogleAccounts/SelectGoogleAccount/index.tsx:132
#: src/Components/Modal/Wizard/ConnectToBigQuery/SelectBigQueryAccount/index.tsx:146
msgid "Select connection"
msgstr "Vælg forbindelse"

#: src/Components/Modal/Wizard/Common/GoogleAccounts/SelectGoogleAccount/index.tsx:148
msgid "We could not find a connected Google Account, please set one up first."
msgstr "Der er ingen forbundne Google kontoer. Tilføj en først."

#: src/Components/Modal/Wizard/Common/GoogleAccounts/SelectGoogleAccount/index.tsx:153
msgid "Connect a Google account"
msgstr "Forbind til en Google konto"

#: src/Components/Modal/Wizard/ConnectToAdobe/AddAdobeMarketingAccount/index.tsx:48
msgid "Account connected"
msgstr "Konto forbundet"

#: src/Components/Modal/Wizard/ConnectToAdobe/AddAdobeMarketingAccount/index.tsx:53
msgid "Failed to connect to Adobe Analytics"
msgstr "Forbindelsen til Adobe Analytics kunne ikke oprettes"

#: src/Components/Modal/Wizard/ConnectToAdobe/ConnectAdobeAccount/index.tsx:73
msgid ""
"If you are having trouble setting up your Adobe Analytics account, then we "
"have a guide on how to get started which you can read [link:here]."
msgstr ""
"Hvis du har problemer med opsætningen af din Adobe Analytics konto kan du "
"læse vores guide [link:her]."

#: src/Components/Modal/Wizard/ConnectToAdobe/ConnectAdobeAccount/index.tsx:89
msgid "User Name"
msgstr "Brugernavn"

#: src/Components/Modal/Wizard/ConnectToAdobe/ConnectAdobeAccount/index.tsx:100
msgid "Shared Secret"
msgstr "Shared Secret"

#: src/Components/Modal/Wizard/ConnectToAdobe/ConnectAdobeAccount/index.tsx:103
msgid "Secret"
msgstr "Secret"

#: src/Components/Modal/Wizard/ConnectToAdobe/index.tsx:65
msgid "Select Adobe Suite"
msgstr "Vælg Adobe suite"

#: src/Components/Modal/Wizard/ConnectToAdobe/index.tsx:76
msgid "Add Adobe Analytics account"
msgstr "Tilføj Adobe Analytics konto"

#: src/Components/Modal/Wizard/ConnectToAdobe/index.tsx:107
msgid "Select Adobe Account"
msgstr "Vælg Adobe konto"

#: src/Components/Modal/Wizard/ConnectToAdobe/index.tsx:119
msgid "Add Adobe Connection"
msgstr "Tilføj forbindelse til Adobe"

#: src/Components/Modal/Wizard/ConnectToAdobe/SelectAdobeAccount/index.tsx:50
msgid "Select the Adobe connection you want to use."
msgstr "Vælg den Adobe-forbindelse, du vil bruge."

#: src/Components/Modal/Wizard/ConnectToAdobe/SelectAdobeAccount/index.tsx:66
msgid ""
"Don't see the Adobe Account you are looking for? You can always add an "
"additional account [link:here]."
msgstr ""
"Kan du ikke finde den Adobe konto du leder efter? Du kan altid tilføje en "
"konto mere [link:her]."

#: src/Components/Modal/Wizard/ConnectToAdobe/SelectAdobeAccount/index.tsx:85
msgid ""
"This domain is already connected to [account] Adobe Account, you can connect "
"to another account above or disconnect [link:here]."
msgstr ""
"Dette domæne er allerede forbundet til [account] Adobe konto. Du kan "
"forbinde til en anden konto ovenover eller afbryde forbindelsen [link:her]."

#: src/Components/Modal/Wizard/ConnectToAdobe/SelectAdobeAccount/index.tsx:102
#: src/Components/Modal/Wizard/ConnectToGA/Account/index.tsx:93
#: src/Components/Modal/Wizard/ConnectToOAuth/SelectOAuthAccount/index.tsx:83
msgid "Select account"
msgstr "Vælg konto"

#: src/Components/Modal/Wizard/ConnectToAdobe/SelectAdobeAccount/index.tsx:117
msgid "We could not find a connected Adobe Account, please set one up first."
msgstr "Der er ingen forbundne Adobe kontoer. Tilføj en først."

#: src/Components/Modal/Wizard/ConnectToAdobe/SelectAdobeAccount/index.tsx:121
msgid "Connect an Adobe account"
msgstr "Forbind til en Adobe konto"

#: src/Components/Modal/Wizard/ConnectToAdobe/Suite/index.tsx:85
msgid "Suite"
msgstr "Suite"

#: src/Components/Modal/Wizard/ConnectToAdobe/Suite/index.tsx:94
msgid "There are currently no suites."
msgstr "Der er ingen suites."

#: src/Components/Modal/Wizard/ConnectToBigQuery/AddedBigQueryAccount/index.tsx:21
#: src/Components/Modal/Wizard/ConnectToBigQuery/index.tsx:178
msgid "Your Google BigQuery account has been connected."
msgstr "Din Google BigQuery konto er nu forbundet."

#: src/Components/Modal/Wizard/ConnectToBigQuery/AddedBigQueryAccount/index.tsx:25
msgid "You can now close this window. Data is backfilled."
msgstr "Du kan nu lukke dette vindue. Historisk data er blevet kopieret."

#: src/Components/Modal/Wizard/ConnectToBigQuery/AddedBigQueryAccount/index.tsx:26
msgid "You can now close this window or go to next step to backfill data."
msgstr ""
"Du kan nu lukke dette vindue eller gå til næste trin for at kopiere "
"historisk data."

#: src/Components/Modal/Wizard/ConnectToBigQuery/AddedBigQueryAccount/index.tsx:41
msgid "Backfill data now"
msgstr "Kopier historisk data"

#: src/Components/Modal/Wizard/ConnectToBigQuery/BackfillData/index.tsx:80
msgid ""
"Copy historic keyword data to BigQuery.[br][i:Note:] You can only backfill "
"data once per domain."
msgstr ""
"Kopier historisk data til BigQuery.[br][i:Obs:] Du kan kun kopiere historisk "
"data én gang pr domæne."

#: src/Components/Modal/Wizard/ConnectToBigQuery/BackfillData/index.tsx:90
msgid "Select below"
msgstr "Vælg herunder"

#: src/Components/Modal/Wizard/ConnectToBigQuery/BackfillData/index.tsx:97
#: src/Components/PeriodFilter/buttons.ts:8
#: src/Components/PeriodFilter/CompareToContentSegmented/index.tsx:154
#: src/Pages/PublicReport/publicReportSettings/index.tsx:299
msgid "Yesterday"
msgstr "I går"

#: src/Components/Modal/Wizard/ConnectToBigQuery/BackfillData/index.tsx:111
msgid "Start backfill"
msgstr "Start kopiering"

#: src/Components/Modal/Wizard/ConnectToBigQuery/ConnectBigQuery/index.tsx:66
msgid ""
"Invalid BigQuery configuration file - missing required fields: {{fields}}"
msgstr ""
"Ugyldig BigQuery konfigurationsfil - Obligatoriske felter mangler: {{fields}}"

#: src/Components/Modal/Wizard/ConnectToBigQuery/ConnectBigQuery/index.tsx:90
msgid "The name is used to identify the connection in the integration list."
msgstr "Navnet bruges til at identificere forbindelsen på integrationslisten."

#: src/Components/Modal/Wizard/ConnectToBigQuery/ConnectBigQuery/index.tsx:97
msgid "Upload BigQuery configuration"
msgstr "Upload BigQuery konfiguration"

#: src/Components/Modal/Wizard/ConnectToBigQuery/ConnectBigQuery/index.tsx:99
msgid ""
"The configuration file should be in JSON. If you need to create a new key, "
"go to the relevant Service Account and select Manage Keys under Actions. "
"Select 'ADD KEY' making sure you select JSON format and click 'CREATE'. "
"Download the JSON file and upload here."
msgstr ""
"Konfigurationsfilen skal være i JSON. Hvis du har brug for at oprette en ny "
"nøgle, skal du gå til den relevante Service Account og vælge Manage Keys "
"under Actions. Vælg 'ADD KEY', og sørg for at vælge JSON-format, og klik på "
"'CREATE'. Download JSON-filen, og upload den her."

#: src/Components/Modal/Wizard/ConnectToBigQuery/ConnectBigQuery/index.tsx:105
msgid "Invalid file type. Please provide a JSON type."
msgstr "Ugyldig filtype. Brug venligst en JSON-fil."

#: src/Components/Modal/Wizard/ConnectToBigQuery/ConnectBigQuery/index.tsx:117
msgid "Connect"
msgstr "Forbind"

#: src/Components/Modal/Wizard/ConnectToBigQuery/ConnectBigQuery/InputLocation.tsx:24
msgid "Data location"
msgstr "Data lokation"

#: src/Components/Modal/Wizard/ConnectToBigQuery/ConnectBigQuery/InputLocation.tsx:46
msgid "Low CO₂"
msgstr "Lav CO₂"

#: src/Components/Modal/Wizard/ConnectToBigQuery/FailedAddingBigQueryAccount/index.tsx:14
#: src/Components/Modal/Wizard/ConnectToBigQuery/index.tsx:105
msgid "Failed to connect to Google BigQuery."
msgstr "Kunne ikke oprette forbindelse til Google BigQuery."

#: src/Components/Modal/Wizard/ConnectToBigQuery/FailedAddingBigQueryAccount/index.tsx:15
msgid "You can go to first step to try again or contact support."
msgstr "Der gik noget galt. Prøv igen fra første trin eller kontakt supporten."

#: src/Components/Modal/Wizard/ConnectToBigQuery/FailedAddingBigQueryAccount/index.tsx:19
msgid "Go to first step"
msgstr "Gå til første trin"

#: src/Components/Modal/Wizard/ConnectToBigQuery/index.tsx:99
msgid "Domain connected to Google BigQuery"
msgstr "Forbundet til Google BigQuery"

#: src/Components/Modal/Wizard/ConnectToBigQuery/index.tsx:123
msgid "Started backfilling data from Google BigQuery"
msgstr "Startede tilbagefyldning af data fra Google BigQuery"

#: src/Components/Modal/Wizard/ConnectToBigQuery/index.tsx:128
msgid "Failed to start backfilling data from Google BigQuery"
msgstr "Kunne ikke starte tilbagefyldning af data fra Google BigQuery"

#: src/Components/Modal/Wizard/ConnectToBigQuery/index.tsx:155
msgid "Select Google BigQuery Account"
msgstr "Vælg Google BigQuery konto"

#: src/Components/Modal/Wizard/ConnectToBigQuery/index.tsx:168
msgid "Add BigQuery Connection"
msgstr "Tilføj BigQuery Forbindelse"

#: src/Components/Modal/Wizard/ConnectToBigQuery/index.tsx:174
msgid "Connected Google BigQuery Account"
msgstr "Forbundne Google BigQuery kontoer"

#: src/Components/Modal/Wizard/ConnectToBigQuery/index.tsx:185
msgid "Failed to connect to Google BigQuery"
msgstr "Forbindelsen til Google BigQuery kunne ikke oprettes"

#: src/Components/Modal/Wizard/ConnectToBigQuery/index.tsx:195
msgid "Connected domain with Google BigQuery account"
msgstr "Domænet er forbundet til en Google BigQuery konto"

#: src/Components/Modal/Wizard/ConnectToBigQuery/index.tsx:202
msgid "Backfill data to Google BigQuery"
msgstr "Kopier historisk data til Google BigQuery"

#: src/Components/Modal/Wizard/ConnectToBigQuery/SelectBigQueryAccount/index.tsx:76
msgid "Google BigQuery (%s)"
msgstr "Google BigQuery (%s)"

#: src/Components/Modal/Wizard/ConnectToBigQuery/SelectBigQueryAccount/index.tsx:93
#: src/Pages/DomainSettings/components/Menu/connectToBigQuery.tsx:19
msgid "Dataset name"
msgstr "Dataset navn"

#: src/Components/Modal/Wizard/ConnectToBigQuery/SelectBigQueryAccount/index.tsx:94
msgid "Only letters and underscores. Start and end with letter."
msgstr "Kun bogstaver og underscores. Start og afslut med et bogstav."

#: src/Components/Modal/Wizard/ConnectToBigQuery/SelectBigQueryAccount/index.tsx:95
msgid ""
"Enter the name of the BigQuery dataset for this domain. If you have already "
"created the dataset yourself, make sure it matches the dataset within your "
"project."
msgstr ""
"Indtast navnet på BigQuery-datasættet for dette domæne. Hvis du allerede har "
"oprettet datasættet selv, skal du sikre, at det matcher datasættet i dit "
"projekt."

#: src/Components/Modal/Wizard/ConnectToBigQuery/SelectBigQueryAccount/index.tsx:107
msgid "Select the Google BigQuery connection you want to use."
msgstr "Vælg den Google BigQuery-forbindelse, du vil bruge."

#: src/Components/Modal/Wizard/ConnectToBigQuery/SelectBigQueryAccount/index.tsx:128
msgid ""
"This domain is already connected to [account] Google BigQuery Account, you "
"can connect to another account above or disconnect [link:here]."
msgstr ""
"Dette domæne er allerede forbundet til [account] Google BigQuery-konto. Du "
"kan forbinde til en anden konto ovenfor eller afbryde forbindelsen [link:"
"her]."

#: src/Components/Modal/Wizard/ConnectToBigQuery/SelectBigQueryAccount/index.tsx:164
msgid ""
"We could not find a Google BigQuery Connection, please set one up first."
msgstr "Der er ingen forbundne Google BigQuery konti. Tilføj en først."

#: src/Components/Modal/Wizard/ConnectToBigQuery/SelectBigQueryAccount/index.tsx:169
msgid "Connect a Google BigQuery account"
msgstr "Forbind til en Google BigQuery konto"

#: src/Components/Modal/Wizard/ConnectToDrive/index.tsx:72
msgid ""
"This feature is part of the [link1:Professional], [link2:Expert] and [link3:"
"Enterprise] plans."
msgstr ""
"Denne funktion er en del af [link1:Professional], [link2:Expert] og [link3:"
"Enterprise] planerne."

#: src/Components/Modal/Wizard/ConnectToDrive/index.tsx:105
#: src/Components/Modal/Wizard/ConnectToDrive/index.tsx:127
msgid "Add Google Drive Account"
msgstr "Tilføj Google Drive konto"

#: src/Components/Modal/Wizard/ConnectToDrive/index.tsx:137
msgid "Connected Google Drive Account"
msgstr "Forbundne Google Drive kontoer"

#: src/Components/Modal/Wizard/ConnectToDrive/index.tsx:141
msgid ""
"Your Google Drive account has been connected, you can now create reports "
"that are directly saved in Google Drive."
msgstr ""
"Din Google Drive konto er forbundet. Du kan nu oprette rapporter direkte til "
"dit Google Drive."

#: src/Components/Modal/Wizard/ConnectToGA/Account/index.tsx:54
#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:92
#: src/Pages/AccountSettings/index.tsx:200
msgid "Account Name"
msgstr "Kontonavn"

#: src/Components/Modal/Wizard/ConnectToGA/Account/index.tsx:73
msgid "Please select the Google Analytics account that you wish to use."
msgstr "Vælg venligst den Google Analytics konto du vil bruge."

#: src/Components/Modal/Wizard/ConnectToGA/Account/index.tsx:83
msgid "Select all"
msgstr "Vælg alle"

#: src/Components/Modal/Wizard/ConnectToGA/Account/index.tsx:99
msgid "Connection"
msgstr "Forbindelse"

#: src/Components/Modal/Wizard/ConnectToGA/AddGAAccount/index.tsx:77
#: src/Components/Modal/Wizard/ConnectToGSC/GSCBulkWebsites/index.tsx:131
msgid "Failed to connect "
msgstr "Kunne ikke oprette forbindelse "

#: src/Components/Modal/Wizard/ConnectToGA/AddGAAccount/index.tsx:80
msgid "Connected all selected domains to Google Analytics"
msgstr "Forbundet alle valgte domæner til Google Analytics"

#: src/Components/Modal/Wizard/ConnectToGA/AddGAAccount/index.tsx:116
msgid "Failed to connect to Google Analytics"
msgstr "Forbindelsen til Google Analytics kunne ikke oprettes"

#: src/Components/Modal/Wizard/ConnectToGA/index.tsx:145
msgid "Select Analytics Account"
msgstr "Vælg Analytics-Konto"

#: src/Components/Modal/Wizard/ConnectToGA/index.tsx:167
#: src/Components/Modal/Wizard/ConnectToGSC/index.tsx:112
msgid "Select Group"
msgstr "Vælg gruppe"

#: src/Components/Modal/Wizard/ConnectToGA/index.tsx:175
msgid "Select Analytics Property for Each Domain"
msgstr "Vælg Analytics Property for hvert domæne"

#: src/Components/Modal/Wizard/ConnectToGA/index.tsx:176
msgid "Select Analytics Property"
msgstr "Vælg Analytics-ejendom"

#: src/Components/Modal/Wizard/ConnectToGA/index.tsx:205
msgid "Select Analytics Profile"
msgstr "Vælg Analytics-Profil"

#: src/Components/Modal/Wizard/ConnectToGA/index.tsx:225
msgid "Add Google Analytics Account"
msgstr "Tilføj Google Analytics-Konto"

#: src/Components/Modal/Wizard/ConnectToGA/index.tsx:266
#: src/Components/Modal/Wizard/ConnectToGSC/index.tsx:134
msgid "Select Google Connection"
msgstr "Vælg Google Forbindelse"

#: src/Components/Modal/Wizard/ConnectToGA/index.tsx:278
#: src/Components/Modal/Wizard/ConnectToGA/index.tsx:295
#: src/Components/Modal/Wizard/ConnectToGSC/index.tsx:146
msgid "Add Google Connection"
msgstr "Tilføj Google Forbindelse"

#: src/Components/Modal/Wizard/ConnectToGA/Profile/index.tsx:60
msgid "Please select the Google Analytics profile that you wish to use."
msgstr "Vælg venligst den Google Analytics profil du vil bruge."

#: src/Components/Modal/Wizard/ConnectToGA/Profile/index.tsx:68
#: src/Components/Modal/Wizard/ConnectToGA/Property/index.tsx:53
#: src/Components/Modal/Wizard/ConnectToGA/PropertyBulkImport/index.tsx:142
#: src/Components/Modal/Wizard/ConnectToGSC/GSCBulkWebsites/index.tsx:200
msgid "Property"
msgstr "Ejendom"

#: src/Components/Modal/Wizard/ConnectToGA/Profile/index.tsx:71
msgid "Select profile"
msgstr "Vælg profil"

#: src/Components/Modal/Wizard/ConnectToGA/Property/index.tsx:71
msgid "Hide UA Properties?"
msgstr "Skjul UA properties?"

#: src/Components/Modal/Wizard/ConnectToGA/Property/index.tsx:78
msgid ""
"By default, the switch is toggled on to hide Universal Analytics properties "
"from available options, because we are in the process of migrating to Google "
"Analytics 4.[br][br]This ensures that only relevant properties are displayed "
"for you to choose from.[br][br]If you need to select a Universal Analytics "
"property, simply toggle the switch to show all available options."
msgstr ""
"Som standard er omskifteren indstillet til at skjule Universal Analytics-"
"ejendomme fra de tilgængelige valgmuligheder, fordi vi er i gang med at "
"migrere til Google Analytics 4.[br][br]Dette sikrer, at kun relevante "
"ejendomme vises, som du kan vælge imellem.[br][br]Hvis du har brug for at "
"vælge en Universal Analytics-ejendom, skal du blot skifte omskifteren for at "
"vise alle tilgængelige muligheder."

#: src/Components/Modal/Wizard/ConnectToGA/Property/index.tsx:97
msgid "Select property"
msgstr "Vælg egenskab"

#: src/Components/Modal/Wizard/ConnectToGA/PropertyBulkImport/index.tsx:163
#: src/Components/Modal/Wizard/ConnectToGSC/GSCBulkWebsites/index.tsx:221
msgid "Could not find any domains"
msgstr "Fandt ingen domæner"

#: src/Components/Modal/Wizard/ConnectToGA/PropertyBulkImport/index.tsx:174
msgid "Connect properties"
msgstr "Forbind properties"

#: src/Components/Modal/Wizard/ConnectToGSC/GSCBulkWebsites/index.tsx:137
msgid "Successfully connected "
msgstr "Forbindelse lykkedes "

#: src/Components/Modal/Wizard/ConnectToGSC/GSCBulkWebsites/index.tsx:141
msgid "Connected all selected domains to Google Search Console"
msgstr "Forbundet alle valgte domæner til Google Search Console"

#: src/Components/Modal/Wizard/ConnectToGSC/GSCBulkWebsites/index.tsx:232
msgid "Connect domains"
msgstr "Forbind domæner"

#: src/Components/Modal/Wizard/ConnectToGSC/GSCWebsite/index.tsx:96
#: src/Components/Modal/Wizard/ConnectToGSC/GSCWebsite/index.tsx:100
msgid "Failed to connect to Google Search Console"
msgstr "Kunne ikke forbinde til Google Search Console"

#: src/Components/Modal/Wizard/ConnectToGSC/GSCWebsite/index.tsx:150
msgid ""
"We could not find any properties. Please check your Google Search Console "
"account."
msgstr ""
"Vi kunne ikke finde nogle properties. Tjek venligst din Google Search "
"Console konto."

#: src/Components/Modal/Wizard/ConnectToGSC/GSCWebsite/index.tsx:174
msgid "Select Property"
msgstr "Vælg Property"

#: src/Components/Modal/Wizard/ConnectToGSC/GSCWebsite/index.tsx:192
msgid "Please select the Google Search Console property that you wish to use."
msgstr "Vælg venligst den Google Search Console hjemmeside du ønsker at bruge."

#: src/Components/Modal/Wizard/ConnectToGSC/GSCWebsite/index.tsx:199
msgid "Select website"
msgstr "Vælg hjemmeside"

#: src/Components/Modal/Wizard/ConnectToGSC/index.tsx:162
msgid "Add Google connection"
msgstr "Tilføj forbindelse til Google"

#: src/Components/Modal/Wizard/ConnectToOAuth/index.tsx:114
msgid "Select %s account"
msgstr "Vælg %s konto"

#: src/Components/Modal/Wizard/ConnectToOAuth/index.tsx:125
#: src/Components/Modal/Wizard/ConnectToOAuth/index.tsx:141
msgid "Add %s connection"
msgstr "Tilføj %s forbindelse"

#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:6
msgid "Agency Analytics"
msgstr "Agency Analytics"

#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:7
#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:31
#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:43
msgid "Imports all your data."
msgstr "Importerer alle dine data."

#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:10
msgid "Authoritylabs"
msgstr "Authoritylabs"

#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:11
#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:27
#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:39
msgid "Contact support for more information."
msgstr "Kontakt supporten for mere information."

#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:14
msgid "AWR Cloud"
msgstr "AWR Cloud"

#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:15
#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:19
#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:23
#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:35
msgid "Imports history for a single domain."
msgstr "Importerer historik for et enkelt domæne."

#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:18
msgid "AWR Desktop"
msgstr "AWR Desktop"

#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:22
msgid "Link Assistant Rank Tracker"
msgstr "Link Assistant Rank Tracker"

#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:26
msgid "Positionly"
msgstr "Positionly"

#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:30
msgid "ProRankTracker"
msgstr "ProRankTracker"

#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:34
msgid "Rank Ranger"
msgstr "Rank Ranger"

#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:38
msgid "SERanking"
msgstr "SERanking"

#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:42
msgid "Serpbook"
msgstr "Serpbook"

#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:46
msgid "Serpfox"
msgstr "Serpfox"

#: src/Components/Modal/Wizard/ConnectToOAuth/providersConfig.ts:47
msgid "Imports one or more domains."
msgstr "Importer et eller flere domæner."

#: src/Components/Modal/Wizard/ConnectToOAuth/SelectOAuthAccount/index.tsx:52
msgid "Select the %s connection you want to use for keywords import."
msgstr "Vælg den %s forbindelse du vil bruge til at importere søgeord."

#: src/Components/Modal/Wizard/ConnectToOAuth/SelectOAuthAccount/index.tsx:69
msgid ""
"Don't see the [accountName] account you are looking for? You can always add "
"an additional account [link:here]."
msgstr ""
"Kan du ikke finde den [accountName] konto du leder efter? Du kan altid "
"tilføje en konto mere [link:her]."

#: src/Components/Modal/Wizard/ConnectToOAuth/SelectOAuthAccount/index.tsx:93
msgid "We could not find a connected %s Account, please set one up first."
msgstr "Der er ingen forbundne %s kontoer. Tilføj en først."

#: src/Components/Modal/Wizard/ConnectToOAuth/SelectOAuthAccount/index.tsx:100
msgid "Connect an %s account"
msgstr "Forbind til en %s konto"

#: src/Components/Modal/Wizard/CreateAffiliateLink/EnterLink/index.tsx:34
msgid ""
"To create an affiliate link paste any URL you'd like to link to from the "
"[link:AccuRanker website] below. You will then be able to copy paste a "
"version of this URL with your affiliate ID added."
msgstr ""
"For at oprette et affiliate link skal du indsætte en URL fra [link:"
"AccuRanker's hjemmeside]. Du får derefter lavet et link hvor dit affiliate "
"ID er tilføjet."

#: src/Components/Modal/Wizard/CreateAffiliateLink/EnterLink/index.tsx:43
msgid ""
"You can track additional parameters such as 'campaign' and 'placement', "
"these fields are optional and dynamic."
msgstr ""
"Du kan spore ekstra parametre så som 'campaign' og 'placement'. Disse felter "
"er valgfrie og dynamiske."

#: src/Components/Modal/Wizard/CreateAffiliateLink/EnterLink/index.tsx:51
msgid "URL to link to (e.g. https://www.accuranker.com/product)"
msgstr "URL du vil linke til (f.eks. https://www.accuranker.com/product)"

#: src/Components/Modal/Wizard/CreateAffiliateLink/EnterLink/index.tsx:56
msgid "Optional fields"
msgstr "Valgfrie felter"

#: src/Components/Modal/Wizard/CreateAffiliateLink/EnterLink/index.tsx:60
msgid "(optional) campaign"
msgstr "(valgfri) kampagne"

#: src/Components/Modal/Wizard/CreateAffiliateLink/EnterLink/index.tsx:64
msgid "(optional) placement"
msgstr "(valgfri) placering"

#: src/Components/Modal/Wizard/CreateAffiliateLink/EnterLink/index.tsx:71
msgid "Create link"
msgstr "Opret link"

#: src/Components/Modal/Wizard/CreateAffiliateLink/GetAffiliateLink/index.tsx:101
msgid ""
"Your affiliate link has been generated. Use it to link to the AccuRanker "
"website to track your referrals."
msgstr ""
"Dit affiliate link er blevet lavet. Brug det til at linke til AccuRanker så "
"dine besøgende bliver sporet."

#: src/Components/Modal/Wizard/CreateAffiliateLink/GetAffiliateLink/index.tsx:107
msgid ""
"You can track additional parameters such as 'campaign', 'placement' and "
"'unique'. Simply use the query parameters 'c', 'p', and 'u'. (e.g. "
"'[link]&c=test-campaign&p=test-position&u=123')"
msgstr ""
"Du kan spore ekstra parametre så som 'campaign', 'placement' og 'unique'. Du "
"skal bare bruge URL parametrene 'c', 'p' og 'u'. (f.eks. '[link]&c=test-"
"campaign&p=test-position&u=123')"

#: src/Components/Modal/Wizard/CreateAffiliateLink/GetAffiliateLink/index.tsx:116
msgid "Affiliate URL"
msgstr "Affiliate URL"

#: src/Components/Modal/Wizard/CreateAffiliateLink/GetAffiliateLink/index.tsx:127
msgid "Affiliate Link"
msgstr "Affiliate link"

#: src/Components/Modal/Wizard/CreateAffiliateLink/GetAffiliateLink/index.tsx:140
msgid "Get another link"
msgstr "Lav endnu et link"

#: src/Components/Modal/Wizard/CreateAffiliateLink/index.tsx:30
#: src/Pages/Affiliate/AffiliateDashboard/index.tsx:91
msgid "Create affiliate link"
msgstr "Opret affiliate link"

#: src/Components/Modal/Wizard/CreateAffiliateLink/index.tsx:36
msgid "Get affiliate link"
msgstr "Lav affiliate link"

#: src/Components/NewsDialog/index.tsx:16
msgid ""
"[h5: AI Search Volume!][br][textsm:We are excited to introduce AI Search "
"Volume, our new search volume metric with much more accurate search volume "
"estimates! [br][br]You can learn more about AI Search Volume [link:here]."
msgstr ""
"[h5: AI Søgevolumen!][br][textsm:Vi er glade for at introducere AI "
"Søgevolumen, vores nye søgevolumen-metrik med meget mere præcise "
"søgevolumenestimater! [br][br]Du kan lære mere om AI Søgevolumen [link:her]."

#: src/Components/OveruseConfirmation/index.tsx:51
#: src/Components/OveruseConfirmation/index.tsx:133
#: src/Components/OveruseConfirmation/index.tsx:229
#: src/Components/OveruseConfirmation/index.tsx:331
msgid "You have reached your keyword limit"
msgstr "Du har nået max antal søgeord"

#: src/Components/OveruseConfirmation/index.tsx:59
#: src/Components/OveruseConfirmation/index.tsx:141
msgid "You have reached your keyword limit of [maxKeywords] keywords."
msgstr "Du har nået dit abonnements grænse på [maxKeywords] søgeord."

#: src/Components/OveruseConfirmation/index.tsx:63
#: src/Components/OveruseConfirmation/index.tsx:145
#: src/Components/OveruseConfirmation/index.tsx:247
#: src/Components/OveruseConfirmation/index.tsx:349
msgid "Deleted keywords are included in your account's daily keyword limit."
msgstr "Slettede søgeord tæller med i din kontos daglige forbrug."

#: src/Components/OveruseConfirmation/index.tsx:67
msgid ""
"Don't worry - You can easily upgrade your account to continue your work. "
"Feel free to contact our dedicated support team if you have any questions."
msgstr ""
"Bare rolig - Du kan nemt opgradere din konto og fortsætte dit arbejde. "
"Kontakt supporten hvis du har spørgsmål."

#: src/Components/OveruseConfirmation/index.tsx:100
msgid "View plans"
msgstr "Se planer"

#: src/Components/OveruseConfirmation/index.tsx:149
msgid "Contact your account administrator to upgrade your plan."
msgstr "Kontakt din kontoadministrator for at opgradere din plan."

#: src/Components/OveruseConfirmation/index.tsx:237
#: src/Components/OveruseConfirmation/index.tsx:339
msgid "You have reached your keyword limit of [maxKeywords]."
msgstr "Du har nået dit abonnements grænse på [maxKeywords] søgeord."

#: src/Components/OveruseConfirmation/index.tsx:239
msgid ""
"AccuRanker allows you to exceed your keyword plan limit so you can continue "
"optimising without interruption. You’ll only be charged for the extra "
"keywords that go beyond your current plan’s allowance."
msgstr ""
"AccuRanker giver dig mulighed for at overskride din søgeordsplangrænse, så "
"du kan fortsætte med at optimere uden afbrydelser. Du vil kun blive opkrævet "
"for de ekstra søgeord, der overstiger din nuværende plans tilladelse."

#: src/Components/OveruseConfirmation/index.tsx:253
msgid ""
"By clicking “Approve”, you acknowledge our [link:terms and conditions] and "
"approve the changes to your subscription."
msgstr ""
"Ved at klikke på “Godkend” anerkender du vores [link:vilkår og betingelser] "
"og godkender ændringerne i dit abonnement."

#: src/Components/OveruseConfirmation/index.tsx:298
msgid "Approve"
msgstr "Godkend"

#: src/Components/OveruseConfirmation/index.tsx:341
msgid ""
"Contact your account administrator to allow keyword overuse or upgrade to "
"the next plan."
msgstr ""
"Kontakt din kontoadministrator for at tillade overforbrug af søgeord eller "
"opgradere til den næste plan."

#: src/Components/PercentageOfMaxBar/percentageOfMaxBar.tsx:51
#: src/Components/PercentageOfMaxCircular/percentageOfMaxCircular.tsx:51
msgid "out of"
msgstr "ud af"

#: src/Components/PercentageOfMaxBar/percentageOfMaxBar.tsx:52
#: src/Components/PercentageOfMaxCircular/percentageOfMaxCircular.tsx:52
msgid "discovered keywords"
msgstr "opdagede søgeord"

#: src/Components/PeriodFilter/buttons.ts:19
msgid "One week ago"
msgstr "En uge siden"

#: src/Components/PeriodFilter/buttons.ts:30
msgid "Two weeks ago"
msgstr "To uger siden"

#: src/Components/PeriodFilter/buttons.ts:41
msgid "One month ago"
msgstr "En måned siden"

#: src/Components/PeriodFilter/buttons.ts:52
msgid "One year ago"
msgstr "Et år siden"

#: src/Components/PeriodFilter/buttons.ts:63
#: src/Components/PeriodFilter/CompareToContentSegmented/index.tsx:199
#: src/Pages/PublicReport/publicReportSettings/index.tsx:298
msgid "Initial"
msgstr "Oprindelig"

#: src/Components/PeriodFilter/CompareToContent/index.tsx:96
msgid "Compare Data"
msgstr "Sammenlign Data"

#: src/Components/PeriodFilter/CompareToContent/index.tsx:113
msgid "Please note that you will not be viewing the latest data."
msgstr "Bemærk venligst, at du ikke får vist de nyeste data."

#: src/Components/PeriodFilter/CompareToContent/index.tsx:128
msgid "Latest complete refresh"
msgstr "Seneste fuldstændige opdatering"

#: src/Components/PeriodFilter/CompareToContent/index.tsx:129
#: src/Components/PeriodFilter/CompareToContent/index.tsx:136
msgid "Local time"
msgstr "Lokal tid"

#: src/Components/PeriodFilter/CompareToContent/index.tsx:132
msgid "Domain not fully scraped"
msgstr "Domæne ikke færdigtjekket"

#: src/Components/PeriodFilter/CompareToContent/index.tsx:135
msgid "Next scheduled refresh"
msgstr "Næste planlagte opdatering"

#: src/Components/PeriodFilter/CompareToContentSegmented/getTooltipDate.tsx:21
msgid "Compare data from"
msgstr "Sammenlign data fra"

#: src/Components/PeriodFilter/CompareToContentSegmented/index.tsx:172
msgid "2w"
msgstr "2u"

#: src/Components/PeriodFilter/CompareToContentSegmented/index.tsx:206
msgid "Select a custom date range"
msgstr "Vælg et tilpasset datointerval"

#: src/Components/PeriodFilter/CompareToContentSegmented/index.tsx:216
msgid "Custom"
msgstr "Brugerdefineret"

#: src/Components/PeriodFilter/CompareToContentSegmented/index.tsx:237
msgid "Next scheduled refresh:"
msgstr "Næste planlagte opdatering:"

#: src/Components/PeriodFilter/CompareToContentSegmented/index.tsx:253
msgid "Comparing data to:"
msgstr "Sammenligner data med:"

#: src/Components/PeriodFilter/CompareToContentSegmented/index.tsx:253
msgid "Comparing data from:"
msgstr "Sammenligner data fra:"

#: src/Components/PeriodFilter/CompareToDropdown/index.tsx:133
#: src/Pages/PublicReport/reportPeriodSegmentedControl/index.tsx:19
#: src/Pages/Sales/Organization/index.tsx:426
msgid "Period"
msgstr "Periode"

#: src/Components/PeriodFilter/CompareToDropdown/index.tsx:140
msgid "Comparing data to"
msgstr "Sammenligner data til"

#: src/Components/PeriodFilter/CompareToDropdown/index.tsx:147
msgid "Comparing"
msgstr "Sammenligner"

#: src/Components/PeriodFilter/CompareToDropdown/index.tsx:209
msgid "You are not viewing the latest data"
msgstr "Du ser ikke de seneste data"

#: src/Components/PieChart/index.tsx:29
#: src/Pages/Keywords/SERP/Chart/index.tsx:26
#: src/Pages/Keywords/SERP/Chart/support/useChartConfig.tsx:275
#: src/Pages/Keywords/SERP/ChartHistory/ChartHistoryChart.tsx:43
#: src/Pages/Keywords/SERP/FlippedChart/index.tsx:26
#: src/Pages/Keywords/SERP/FlippedChart/support/useFlippedChartConfig.tsx:233
#: src/Pages/Keywords/SERP/SerpPieDistribution/index.tsx:81
msgid "No SERP feature data for the selected filters"
msgstr "Ingen data for SERP-elementer med de valgte filtre"

#: src/Components/QuickNavigation/QuickNavigation.tsx:76
#: src/Components/RefreshKeywords/index.tsx:99
msgid "Ok"
msgstr "Ok"

#: src/Components/QuickNavigation/QuickNavigation.tsx:242
msgid "Search domains, groups and accounts..."
msgstr "Søg på domæner, grupper og konti..."

#: src/Components/QuickNavigation/support/helpers.tsx:173
msgid "favicon for [domain]"
msgstr "favicon for [domain]"

#: src/Components/QuickNavigation/support/helpers.tsx:190
#: src/Pages/Domains/DomainsTable/components/GroupStats.tsx:89
#: src/Pages/Sales/Organization/index.tsx:106
msgid "domains"
msgstr "domæner"

#: src/Components/RankDistribution/index.tsx:30
#: src/Components/RankDistribution/index.tsx:31
#: src/Components/RankDistribution/index.tsx:32
#: src/Components/RankDistribution/index.tsx:33
#: src/Components/RankDistribution/index.tsx:34
msgid "Top %s"
msgstr "Top %s"

#: src/Components/RefreshKeywords/index.tsx:54
msgid "Keywords queued for refresh.."
msgstr "Søgeordene er sat i kø til opdatering."

#: src/Components/RefreshKeywords/index.tsx:63
msgid "Refreshing all keywords on [domainName]..."
msgstr "Opdaterer alle søgeord for [domainName]..."

#: src/Components/RefreshKeywords/index.tsx:66
msgid "Refreshing all keywords... "
msgstr "Opdaterer alle søgeord... "

#: src/Components/RefreshKeywords/index.tsx:85 src/Hooks/useKeyword.tsx:709
msgid "Degraded Performance"
msgstr "Nedsat ydeevne"

#: src/Components/RefreshKeywords/index.tsx:89
msgid "Your keywords will be automatically be refreshed every 24-hours."
msgstr "Dine søgeord vil automatisk blive opdateret hver 24. time."

#: src/Components/RefreshKeywords/index.tsx:91 src/Hooks/useKeyword.tsx:715
msgid ""
"We are currently having some internal issues on our network, we are working "
"on fixing them. Your keywords will be automatically be refreshed every 24-"
"hours."
msgstr ""
"Vi har desværre lidt problemer med vores netværk. Vi arbejder på at løse "
"problemet. Dine søgeord opdateres fortsat automatisk en gang i døgnet."

#: src/Components/RefreshKeywords/index.tsx:95 src/Hooks/useKeyword.tsx:719
msgid "We apologize for the inconvenience."
msgstr "Vi beklager eventuelle ulemper."

#: src/Components/RefreshKeywords/index.tsx:108
msgid "Refresh Keywords"
msgstr "Opdater søgeord"

#: src/Components/RefreshKeywords/index.tsx:113
msgid ""
"Refresh all keywords on the domain: [domainName]. After refreshing, the next "
"manual refresh will be available in 2 hours."
msgstr ""
"Opdater alle søgeord på domænet: [domainName]. Efter opdatering vil den "
"næste manuelle opdatering være tilgængelig om 2 timer."

#: src/Components/RefreshKeywords/index.tsx:123
msgid "Refresh keywords"
msgstr "Opdater søgeord"

#: src/Components/RefreshKeywords/index.tsx:125
msgid "Your keywords are automatically refreshed every 24 hours."
msgstr "Dine søgeord opdateres automatisk hver 24. time."

#: src/Components/RefreshKeywords/index.tsx:141
msgid "Refresh all keywords"
msgstr "Opdater alle søgeord"

#: src/Components/RefreshKeywords/index.tsx:142
msgid "Next refresh available in %s"
msgstr "Næste opdatering mulig om %s"

#: src/Components/RefreshKeywords/index.tsx:184
msgid "Refreshing data"
msgstr "Opdaterer data"

#: src/Components/RequestBigQueryFeature/index.tsx:12
msgid "Request access to Google BigQuery feature"
msgstr "Bed om adgang til Google BigQuery funktionen"

#: src/Components/RequestBigQueryFeature/index.tsx:19
msgid "To request access to Google BigQuery feature, please contact support."
msgstr ""
"Kontakt venligst supporten for at få adgang til Google BigQuery funktionen,."

#: src/Components/RunningTasksSubscription/index.tsx:31
msgid "Updating keywords for [domain]..."
msgstr "Opdaterer søgeord for [domain]..."

#: src/Components/RunningTasksSubscription/index.tsx:32
msgid "Updating keywords..."
msgstr "Opdaterer søgeord..."

#: src/Components/RunningTasksSubscription/index.tsx:38
msgid "The added keyword(s) already exist, or plan size exceeded"
msgstr ""
"De tilføjede søgeord findes allerede, eller din abonnementsstørrelse bliver "
"overskredet"

#: src/Components/RunningTasksSubscription/index.tsx:39
msgid "[completedTasks] out of [totalTasks]"
msgstr "[completedTasks] ud af [totalTasks]"

#: src/Components/RunningTasksSubscription/index.tsx:46
msgid "Updated: [lastUpdatedKeyword]"
msgstr "Opdateret: [lastUpdatedKeyword]"

#: src/Components/SerpFeatureBox/SerpFeatureBox.tsx:93
#: src/Components/SerpFeatureBox/SerpFeatureBox.tsx:178
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/SERPCell.tsx:142
#: src/Pages/Keywords/SERP/Chart/support/useChartConfig.tsx:119
#: src/Pages/Keywords/SERP/ChartHistory/SerpOwnedSegmentedControl.tsx:27
#: src/Pages/Keywords/SERP/SerpPieDistribution/index.tsx:77
msgid "Owned"
msgstr "Ejet"

#: src/Components/SerpFeatureBox/SerpFeatureBox.tsx:94
#: src/Components/SerpFeatureBox/SerpFeatureBox.tsx:177
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/SERPCell.tsx:141
msgid "Available"
msgstr "Tilgængelig"

#: src/Components/SerpFeatureBox/SerpFeatureBox.tsx:136
msgid "Add \"[serpLabel]\" as filter"
msgstr "Tilføj \"[serpLabel]\" som filter"

#: src/Components/SimpleSelect/SimpleSelect.tsx:21
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/helpers.tsx:30
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/helpers.tsx:32
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/helpers.tsx:41
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:618
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:620
#: src/Utilities/validation.ts:303
msgid "Field is required"
msgstr "Dette felt skal udfyldes"

#: src/Components/SoMeFormFields/SocialMediaIcons.tsx:33
#: src/Components/SoMeFormFields/SoMeFormFields.tsx:31
msgid "Facebook"
msgstr "Facebook"

#: src/Components/SoMeFormFields/SocialMediaIcons.tsx:38
#: src/Components/SoMeFormFields/SoMeFormFields.tsx:47
msgid "LinkedIn"
msgstr "LinkedIn"

#: src/Components/SoMeFormFields/SocialMediaIcons.tsx:43
#: src/Components/SoMeFormFields/SoMeFormFields.tsx:64
msgid "X"
msgstr "X"

#: src/Components/SoMeFormFields/SocialMediaIcons.tsx:48
#: src/Components/SoMeFormFields/SoMeFormFields.tsx:82
msgid "TikTok"
msgstr "TikTok"

#: src/Components/SoMeFormFields/SocialMediaIcons.tsx:53
#: src/Components/SoMeFormFields/SoMeFormFields.tsx:99
msgid "Instagram"
msgstr "Instagram"

#: src/Components/SoMeFormFields/SoMeFormFields.tsx:21
msgid "Link competitor social media"
msgstr "Tilføj konkurrent social media"

#: src/Components/SoMeFormFields/SoMeFormFields.tsx:21
msgid "Link social media"
msgstr "Tilføj social media"

#: src/Components/SoMeFormFields/SoMeFormFields.tsx:32
msgid ""
"Please enter you Facebook information in one of the following formats:"
"[break1] 1. [b1:FacebookProfile] [break2]2. [b2:facebook.com/FacebookProfile]"
msgstr ""
"Indtast dine Facebook-oplysninger i et af følgende formater:[break1] 1. [b1:"
"FacebookProfile] [break2]2. [b2:facebook.com/FacebookProfile]"

#: src/Components/SoMeFormFields/SoMeFormFields.tsx:41
msgid "Facebook profile"
msgstr "Facebook profil"

#: src/Components/SoMeFormFields/SoMeFormFields.tsx:48
msgid ""
"Please enter your LinkedIn information in one of the following formats:"
"[break1] 1. [b1:linkedin.com/in/yourLinkedInProfile][break2]2. [b2:linkedin."
"com/company/yourCompanyLinkedInProfile] [break3]Note that a personal (/in/) "
"and a company (/company/) LinkedIn have different URL path."
msgstr ""
"Indtast dine LinkedIn-oplysninger i et af følgende formater:[break1] 1. [b1:"
"linkedin.com/in/DinLinkedInProfil][break2]2. [b2:linkedin.com/company/"
"DitFirmaLinkedInProfil] [break3]Bemærk, at en personlig (/in/) og en "
"virksomheds (/company/) LinkedIn har forskellige URL-stier."

#: src/Components/SoMeFormFields/SoMeFormFields.tsx:58
msgid "LinkedIn profile"
msgstr "LinkedIn-profil"

#: src/Components/SoMeFormFields/SoMeFormFields.tsx:65
msgid ""
"Please enter your X information in one of the following formats:[break1] 1. "
"[b1:YourXHandle] [break2]2. [b2:x.com/YourXHandle] [break3]3. [b3:twitter."
"com/YourXHandle]"
msgstr ""
"Indtast dine X-oplysninger i et af følgende formater:[break1] 1. [b1:"
"DitXHandle] [break2]2. [b2:x.com/DitXHandle] [break3]3. [b3:twitter.com/"
"YourTwitterXHandle]"

#: src/Components/SoMeFormFields/SoMeFormFields.tsx:76
msgid "@XHandle"
msgstr "@XHandle"

#: src/Components/SoMeFormFields/SoMeFormFields.tsx:84
msgid ""
"Please enter you TikTok information in one of the following formats:[break1] "
"1. [b1:YourTikTokHandle] [break2]2. [b2:tiktok.com/YourTikTokHandle]"
msgstr ""
"Indtast dine TikTok-oplysninger i et af følgende formater:[break1] 1. [b1:"
"DitTikTokHandle] [break2]2. [b2:tiktok.com/DitTikTokHandle]"

#: src/Components/SoMeFormFields/SoMeFormFields.tsx:93
msgid "@TikTokHandle"
msgstr "@TikTokHandle"

#: src/Components/SoMeFormFields/SoMeFormFields.tsx:100
msgid ""
"Please enter you Instagram information in one of the following formats:"
"[break1] 1. [b1:InstagramProfile] [break2]2. [b2:instagram.com/"
"InstagramProfile]"
msgstr ""
"Indtast dine Instagram-oplysninger i et af følgende formater:[break1] 1. [b1:"
"InstagramProfil] [break2]2. [b2:instagram.com/InstagramProfil]"

#: src/Components/SoMeFormFields/SoMeFormFields.tsx:109
msgid "@InstagramHandle"
msgstr "@InstagramHandle"

#: src/Components/Sort/index.tsx:44
msgid "Sort"
msgstr "Sorter"

#: src/Components/Table/TableRow/RankOptions/index.tsx:30
msgid "Desktop search"
msgstr "Desktopsøgning"

#: src/Components/Table/TableRow/RankOptions/index.tsx:34
msgid "Mobile search"
msgstr "Mobilsøgning"

#: src/Components/Table/TableRow/RankOptions/index.tsx:46
msgid "Yandex"
msgstr "Yandex"

#: src/Components/Table/TableRow/ReportActionCell/index.tsx:33
msgid "Delete Scheduled Report?"
msgstr "Slet planlagt rapport?"

#: src/Components/Table/TableRow/ReportActionCell/index.tsx:34
msgid "This action is irreversible"
msgstr "Denne handling kan ikke fortrydes"

#: src/Components/Table/TableRow/ReportScheduleCell/index.tsx:13
msgid "Runs daily"
msgstr "Kører dagligt"

#: src/Components/Table/TableRow/ReportScheduleCell/index.tsx:17
msgid "monday"
msgstr "mandag"

#: src/Components/Table/TableRow/ReportScheduleCell/index.tsx:18
msgid "tuesday"
msgstr "tirsdag"

#: src/Components/Table/TableRow/ReportScheduleCell/index.tsx:19
msgid "wednesday"
msgstr "onsdag"

#: src/Components/Table/TableRow/ReportScheduleCell/index.tsx:20
msgid "thursday"
msgstr "torsdag"

#: src/Components/Table/TableRow/ReportScheduleCell/index.tsx:21
msgid "friday"
msgstr "fredag"

#: src/Components/Table/TableRow/ReportScheduleCell/index.tsx:22
msgid "saturday"
msgstr "lørdag"

#: src/Components/Table/TableRow/ReportScheduleCell/index.tsx:23
msgid "sunday"
msgstr "søndag"

#: src/Components/Table/TableRow/ReportScheduleCell/index.tsx:25
msgid "Runs every %s"
msgstr "Kører hver %s"

#: src/Components/Table/TableRow/ReportScheduleCell/index.tsx:31
msgid "Runs on the %s. day of every month"
msgstr "Kører den %s. dag i måneden"

#: src/Components/Table/TableRow/ValueDelta/index.tsx:97
msgid "Compare value: [val]"
msgstr "Sammenligningsværdi: [val]"

#: src/Components/Table/TreeTable/components/TableRowContainer.tsx:55
#: src/Pages/SiteMapping/components/TreeTable/TableRowChild.tsx:100
msgid ""
"It looks like you're trying to organize your tags. Do you want to enter the "
"folder editing mode?"
msgstr ""
"Det ligner du prøver at organisere dine tags. Vil du gå i "
"redigeringstilstand?"

#: src/Components/Table/TreeTable/TreeTable.tsx:135
msgid "No data found"
msgstr "Ingen data fundet"

#: src/Components/TableEmptyState/index.tsx:74
msgid "No data"
msgstr "Ingen data"

#: src/Components/TableEmptyState/index.tsx:77
msgid ""
"There is currently no data to display, this could be due to your active "
"filters. Clear your filters and try again."
msgstr ""
"Der er ingen data at vise. Det kan skyldes dine aktive filtre. Nulstil "
"filtrene og prøv igen."

#: src/Components/Title/AccTitle.tsx:121
msgid "More information"
msgstr "Mere information"

#: src/Components/UnpaidInvoiceBar/index.tsx:20
msgid ""
"Action required! Your latest invoice is overdue. Please ensure payment is "
"processed immediately."
msgstr ""
"Handling påkrævet! Din seneste faktura er forfalden. Sørg venligst for, at "
"betalingen foretages øjeblikkeligt."

#: src/Components/UnpaidInvoiceBar/index.tsx:44
msgid ""
"Action required! Your payment is overdue. Please ensure payment is made "
"immediately to avoid losing access and data."
msgstr ""
"Handling påkrævet! Din betaling er forfalden. Sørg venligst for, at "
"betalingen foretages øjeblikkeligt for at undgå at miste adgang og data."

#: src/Components/UrlSerpPreview/index.tsx:11
msgid "Not available"
msgstr "Ikke tilgængelig"

#: src/Components/User/GoogleAccounts/Callback/index.ts:46
msgid "Waiting for Google Analytics..."
msgstr "Afventer Google Analytics..."

#: src/Components/ValueIndicator/value-indicator.tsx:81
msgid "Compare value:"
msgstr "Sammenlign værdi:"

#: src/Components/ViewMode/index.tsx:29
msgid "Default"
msgstr "Standard"

#: src/Components/ViewMode/index.tsx:39
msgid "Medium"
msgstr "Medium"

#: src/Components/ViewMode/index.tsx:49
msgid "Compact"
msgstr "Kompakt"

#: src/Components/ViewMode/index.tsx:60
msgid "Tree view"
msgstr "Trævisning"

#: src/Components/ViewMode/index.tsx:70
msgid "Card view"
msgstr "Kortvisning"

#: src/Constants/i18n.ts:11 src/Pages/Layout/NavTop/index.tsx:35
#: src/Pages/Profile/components/EditProfile/index.tsx:33
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:586
msgid "Danish"
msgstr "Dansk"

#: src/Constants/i18n.ts:12 src/Pages/Layout/NavTop/index.tsx:36
#: src/Pages/Profile/components/EditProfile/index.tsx:37
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:586
msgid "English"
msgstr "Engelsk"

#: src/Constants/messages.ts:3
msgid "Disabled for demo domains"
msgstr "Deaktiveret på demo domæner"

#: src/Constants/messages.ts:5
msgid ""
"Disabled for read-only users<br/>Contact your organization administrator"
msgstr ""
"Deaktiveret for læse-brugere<br/>Kontakt din organisationsadministrator"

#: src/Constants/report.ts:12
#: src/Pages/ReportsOverview/ScheduledReports/helpers/hooks.tsx:37
#: src/Pages/Sales/Organization/index.tsx:714
#: src/Pages/ScheduledReportBuilder/data.ts:32
#: src/Pages/ScheduledReportBuilder/data.ts:52
#: src/Pages/ScheduledReportBuilder/data.ts:78
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:594
#: src/Pages/SelectPlan/plan-features.tsx:80
msgid "PDF"
msgstr "PDF"

#: src/Constants/report.ts:15
msgid "Public Report"
msgstr "Offentlig Rapport"

#: src/Hooks/actions/useLogout.tsx:39
msgid "Failed to perform logout"
msgstr "Kunne ikke logge ud"

#: src/Hooks/data/metrics/useMetrics.ts:27
msgid "SoV percent"
msgstr "Synlighed procent"

#: src/Hooks/data/metrics/useMetrics.ts:27
msgid "SoV / Visits"
msgstr "Synlighed / Besøgende"

#: src/Hooks/data/metrics/useMetrics.ts:32
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:560
#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:147
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:544
msgid "Avg. CTR"
msgstr "Gns. CTR"

#: src/Hooks/data/metrics/useMetrics.ts:34
msgid "AI SoV / Visits"
msgstr "AI Synlighed / Besøgende"

#: src/Hooks/data/metrics/useMetrics.ts:41
#: src/Pages/AIOverview/components/UrlChart.tsx:67
msgid "Count"
msgstr "Antal"

#: src/Hooks/data/metrics/useMetrics.ts:43
msgid "Avg Revenue"
msgstr "Gns. Indtjening"

#: src/Hooks/data/metrics/useMetrics.ts:45
#: src/Pages/Domains/TreeView/support/constants.ts:95
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:417
#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:177
#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/support/hooks.tsx:94
#: src/Pages/Keywords/Groupings/support/constants.ts:114
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:429
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:155
#: src/Pages/SiteMapping/components/CompetitorTable.tsx:87
#: src/Pages/SiteMapping/components/TreeTable/Header.tsx:69
msgid "Searches"
msgstr "Søgninger"

#: src/Hooks/Graphql/helpers.ts:77
msgid "Failed to perform operation, please try again"
msgstr "Kunne ikke gennemføre operationen, prøv venligst igen"

#: src/Hooks/Graphql/helpers.ts:112
#: src/Pages/Billing/CompanyInfoWidget/index.tsx:316
msgid "Something went wrong. Please try again, or contact support."
msgstr "Der gik noget galt. Prøv igen eller kontakt supporten."

#: src/Hooks/useKeyword.tsx:310
msgid "Delete Keyword?"
msgid_plural "Delete Keywords?"
msgstr[0] "Slet søgeord?"
msgstr[1] "Slet søgeord?"

#: src/Hooks/useKeyword.tsx:311
msgid "The keyword will be permanently deleted."
msgid_plural "The keywords will be permanently deleted."
msgstr[0] "Søgeordet bliver permanent slettet."
msgstr[1] "Søgeordene bliver permanent slettet."

#: src/Hooks/useKeyword.tsx:316
msgid "Delete keyword"
msgid_plural "Delete keywords"
msgstr[0] "Slet søgeord"
msgstr[1] "Slet søgeordene"

#: src/Hooks/useKeyword.tsx:479
msgid "Copy keywords"
msgstr "Kopier søgeord"

#: src/Hooks/useKeyword.tsx:713
msgid "Dear Customer"
msgstr "Kære kunde"

#: src/Hooks/useLocalSearch.ts:17
msgid "Local"
msgstr "Lokal"

#: src/icons/search-intent/searchIntentIcon.tsx:39
#: src/Pages/Keywords/Overview/components/SearchIntent/SearchIntentChartContent.tsx:134
msgid "Add \"Transactional\" as filter"
msgstr "Tilføj \"Transactional\" som filter"

#: src/icons/search-intent/searchIntentIcon.tsx:40
#: src/Pages/Keywords/Overview/components/SearchIntent/SearchIntentChartContent.tsx:116
msgid "Add \"Commercial\" as filter"
msgstr "Tilføj \"Commercial\" som filter"

#: src/icons/search-intent/searchIntentIcon.tsx:41
#: src/Pages/Keywords/Overview/components/SearchIntent/SearchIntentChartContent.tsx:83
msgid "Add \"Navigational\" as filter"
msgstr "Tilføj \"Navigational\" som filter"

#: src/icons/search-intent/searchIntentIcon.tsx:42
#: src/Pages/Keywords/Overview/components/SearchIntent/SearchIntentChartContent.tsx:101
msgid "Add \"Informational\" as filter"
msgstr "Tilføj \"Informational\" som filter"

#: src/Pages/Accounts/PendingRequests/AccountsRequestsTable/index.tsx:25
msgid "No Requests"
msgstr "Ingen forespørgsler"

#: src/Pages/Accounts/PendingRequests/AccountsRequestsTable/index.tsx:26
msgid "There are currently no requests."
msgstr "Der er ingen forespørgsler."

#: src/Pages/Accounts/PendingRequests/AccountsRequestsTable/support/hooks.tsx:37
msgid "Request removed"
msgstr "Forespørgsel fjernet"

#: src/Pages/Accounts/PendingRequests/AccountsRequestsTable/support/hooks.tsx:40
#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:72
msgid "Unable to remove access"
msgstr "Kunne ikke fjerne adgang"

#: src/Pages/Accounts/PendingRequests/AccountsRequestsTable/support/hooks.tsx:74
msgid "Send To"
msgstr "Send til"

#: src/Pages/Accounts/PendingRequests/AccountsRequestsTable/support/hooks.tsx:88
#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:114
#: src/Pages/ExternalAccess/OwnersTable/index.tsx:101
#: src/Pages/ExternalAccess/PendingOwnersTable/index.tsx:99
msgid "Account access as"
msgstr "Kontonavn som"

#: src/Pages/Accounts/PendingRequests/AccountsRequestsTable/support/hooks.tsx:93
#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:119
#: src/Pages/ExternalAccess/OwnersTable/index.tsx:105
#: src/Pages/ExternalAccess/PendingOwnersTable/index.tsx:103
msgid "Admin user"
msgstr "Administrator bruger"

#: src/Pages/Accounts/PendingRequests/AccountsRequestsTable/support/hooks.tsx:93
#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:119
#: src/Pages/ExternalAccess/OwnersTable/index.tsx:105
#: src/Pages/ExternalAccess/PendingOwnersTable/index.tsx:103
msgid "Normal user"
msgstr "Normal bruger"

#: src/Pages/Accounts/PendingRequests/AccountsRequestsTable/support/hooks.tsx:106
#: src/Pages/Groups/GroupsTable/support/useGroupsTableConfig.tsx:114
#: src/Pages/NotesList/NotesTable/support/useNotesTableInfo.tsx:117
#: src/Pages/PausedDomains/index.tsx:258
#: src/Pages/Sales/Mails/Table/support/useMailsTableInfo.tsx:31
msgid "Created At"
msgstr "Oprettet den"

#: src/Pages/Accounts/PendingRequests/AccountsRequestsTable/support/hooks.tsx:134
msgid "Delete request"
msgstr "Slet anmodning"

#: src/Pages/Accounts/PendingRequests/index.tsx:37
#: src/Pages/Accounts/SubAccounts/components/SubAccountActions.tsx:41
msgid "Request access to another account"
msgstr "Bed om adgang til en anden konto"

#: src/Pages/Accounts/PendingRequests/index.tsx:41
#: src/Pages/Accounts/SubAccounts/components/SubAccountActions.tsx:45
msgid "Only admin users can request access to another account"
msgstr "Kun administratorbrugere kan bede om adgang til en anden konto"

#: src/Pages/Accounts/SubAccounts/components/InformationForm/index.tsx:56
msgid ""
"Sub-accounts enables you to manage additional AccuRanker accounts from your "
"main account. You can control whether this account or the sub-account should "
"handle billing."
msgstr ""
"Under-kontoer giver dig mulighed for at administrere og tilgå andre "
"AccuRanker kontoer fra din hovedkonto. Du kan vælge om denne konto eller "
"under-kontoen skal stå for betaling."

#: src/Pages/Accounts/SubAccounts/components/InformationForm/index.tsx:63
msgid "You must be an administrator to enable sub-accounts."
msgstr "Du skal være administrator for at slå under-kontoer til."

#: src/Pages/Accounts/SubAccounts/components/InformationForm/index.tsx:70
msgid "Enabling sub-accounts is not possible from a sub-account"
msgstr "Det er ikke muligt at slå underkontoer til fra en underkonto"

#: src/Pages/Accounts/SubAccounts/components/InformationForm/index.tsx:72
msgid "Enabling sub-accounts is not possible on a trial account"
msgstr "Aktivering af underkonti er ikke muligt på en prøvekonto"

#: src/Pages/Accounts/SubAccounts/components/InformationForm/index.tsx:82
msgid "Enable sub-accounts"
msgstr "Slå under-kontoer til"

#: src/Pages/Accounts/SubAccounts/components/SubAccountActions.tsx:29
msgid "Add sub-account"
msgstr "Tilføj under-konto"

#: src/Pages/Accounts/SubAccounts/components/SubAccountActions.tsx:35
msgid "Adding sub-accounts is not possible from a sub-account"
msgstr "Det er ikke muligt at tilføje underkontoer fra en underkonto"

#: src/Pages/Accounts/SubAccounts/components/SubAccountActions.tsx:36
msgid "Admin and Super users can create new sub-accounts"
msgstr "Kun Admins og Superbrugere kan oprette nye under-kontoer"

#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:43
msgid "Remove access and cancel plan"
msgstr "Fjern adgang og annuller konto"

#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:44
#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:210
#: src/Pages/ExternalAccess/OwnersTable/index.tsx:55
msgid "Remove access"
msgstr "Fjern adgang"

#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:46
msgid "Remove Access to Sub-account?"
msgstr "Fjern adgang til under-konto?"

#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:48
msgid ""
"This sub-account is paid by you. Removing access will cancel the sub-"
"accounts subscription."
msgstr ""
"Under-kontoen bliver betalt af dig. Hvis du fjerner adgangen bliver under-"
"kontoen annulleret."

#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:51
msgid "Access to the sub-account will be permanently removed."
msgstr "Adgang til under-kontoen bliver fjernet permanent."

#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:69
msgid "Access removed"
msgstr "Adgang fjernet"

#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:132
msgid "Paid By You"
msgstr "Betalt af dig"

#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:150
msgid "Next Payment"
msgstr "Næste betaling"

#: src/Pages/Accounts/SubAccounts/components/SubAccountsTable/support/hooks.tsx:179
msgid "Find in [link:your invoice list]"
msgstr "Find i din [link:liste af fakturaer]"

#: src/Pages/Accounts/TransferMultiAccountDomain/index.tsx:52
msgid "Copy domain?"
msgstr "Kopier domæne?"

#: src/Pages/Accounts/TransferMultiAccountDomain/index.tsx:55
msgid ""
"Are you sure you want to [action] the domain [domain] to the group [group] "
"on the [organization] account?"
msgstr ""
"Er du sikker på at du vil [action] domænet [domain] til gruppen [group] på "
"[organization] kontoen?"

#: src/Pages/Accounts/TransferMultiAccountDomain/index.tsx:58
msgid "copy"
msgstr "kopi"

#: src/Pages/Accounts/TransferMultiAccountDomain/index.tsx:58
msgid "transfer"
msgstr "flytning"

#: src/Pages/Accounts/TransferMultiAccountDomain/index.tsx:154
msgid ""
"This domain is too large and its history is too long to copy. Contact "
"support if you need to proceed with copying the domain."
msgstr ""
"Dette domæne er for stort, og dets historie er for lang til at kopiere. "
"Kontakt support, hvis du har brug for at fortsætte med at kopiere domænet."

#: src/Pages/Accounts/TransferMultiAccountDomain/index.tsx:200
msgid "Domain copy job started…"
msgstr "Domæne kopijob startet…"

#: src/Pages/Accounts/TransferMultiAccountDomain/index.tsx:200
msgid "Domain has been moved"
msgstr "Domæne er blevet flyttet"

#: src/Pages/Accounts/TransferMultiAccountDomain/index.tsx:344
msgid "Transfer"
msgstr "Flytning"

#: src/Pages/Accounts/TransferMultiAccountDomain/TransferMultiAccountDomainForm/TransferMultiAccountDomainForm.tsx:136
msgid "Select domain to transfer"
msgstr "Vælg domæne der skal flyttes"

#: src/Pages/Accounts/TransferMultiAccountDomain/TransferMultiAccountDomainForm/TransferMultiAccountDomainForm.tsx:143
msgid "Select organization"
msgstr "Vælg organisation"

#: src/Pages/Accounts/TransferMultiAccountDomain/TransferMultiAccountDomainForm/TransferMultiAccountDomainForm.tsx:153
msgid "From group"
msgstr "Fra gruppe"

#: src/Pages/Accounts/TransferMultiAccountDomain/TransferMultiAccountDomainForm/TransferMultiAccountDomainForm.tsx:155
#: src/Pages/Accounts/TransferMultiAccountDomain/TransferMultiAccountDomainForm/TransferMultiAccountDomainForm.tsx:200
#: src/Pages/Domains/CardView/components/NoGroups.tsx:10
msgid "No groups found"
msgstr "Ingen grupper fundet"

#: src/Pages/Accounts/TransferMultiAccountDomain/TransferMultiAccountDomainForm/TransferMultiAccountDomainForm.tsx:168
#: src/Pages/Domains/CardView/components/NoDomains.tsx:28
msgid "No domains found"
msgstr "Ingen domæner fundet"

#: src/Pages/Accounts/TransferMultiAccountDomain/TransferMultiAccountDomainForm/TransferMultiAccountDomainForm.tsx:181
#: src/Pages/Accounts/TransferMultiAccountDomain/TransferMultiAccountDomainForm/TransferMultiAccountDomainForm.tsx:199
msgid "Select destination group"
msgstr "Vælg gruppe"

#: src/Pages/Accounts/TransferMultiAccountDomain/TransferMultiAccountDomainForm/TransferMultiAccountDomainForm.tsx:187
msgid "To organization"
msgstr "Til organisation"

#: src/Pages/Accounts/TransferMultiAccountDomain/TransferMultiAccountDomainForm/TransferMultiAccountDomainForm.tsx:198
msgid "To group"
msgstr "Til gruppe"

#: src/Pages/AccountSettings/AverageRank/helpers.tsx:12
msgid ""
"This setting controls if \"Not in top 100\" rank keywords are included in "
"the average rank calculation. This setting will be applied to all domains by "
"default. You can override this setting for each domain."
msgstr ""
"Denne indstilling styrer, om \"Ikke i top 100\"-placeringssøgeord inkluderes "
"i gennemsnitlig placeringsberegning. Denne indstilling vil blive anvendt på "
"alle domæner som standard. Du kan overskrive denne indstilling for hvert "
"domæne."

#: src/Pages/AccountSettings/AverageRank/helpers.tsx:22
msgid ""
"This setting controls how much \"Not in top 100\" rank keywords will affect "
"the average rank calculation. If you set the weight higher, these keywords "
"will have more impact on the average rank."
msgstr ""
"Denne indstilling styrer, hvor meget \"Ikke i top 100\"-placeringssøgeord "
"vil påvirke beregningen af den gennemsnitlige placering. Hvis du sætter "
"vægten højere, vil disse søgeord have større indflydelse på den "
"gennemsnitlige placering."

#: src/Pages/AccountSettings/AverageRank/helpers.tsx:49
msgid ""
"\"Not in top 100\" ranks are not included in average rank calculation "
"because of the above setting."
msgstr ""
"\"Ikke i top 100\"-placeringer bliver ikke i medregnet i den gennemsnitlige "
"placering pga. indstillingen ovenfor."

#: src/Pages/AccountSettings/AverageRank/index.tsx:42
#: src/Pages/Domains/CardView/components/DomainCard/components/DomainCharts.tsx:57
#: src/Pages/Domains/CardView/support/getSorting.ts:41
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:209
#: src/Pages/Domains/support/types.ts:91
#: src/Pages/Domains/TreeView/support/constants.ts:80
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:325
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:326
#: src/Pages/KeywordDiscovery/components/KpiBar/index.tsx:48
#: src/Pages/Keywords/Competitors/components/CompetitorChartContent/support/helpers.ts:25
#: src/Pages/Keywords/Competitors/support/constants.ts:130
#: src/Pages/Keywords/Groupings/support/constants.ts:100
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:339
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:340
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:82
#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageRank/AverageRankChartContent.tsx:101
#: src/Pages/Keywords/Overview/components/KpiBar/Boxes/AverageRank.tsx:19
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:61
#: src/Pages/PublicReport/support/useSegmentedControlItems.tsx:18
msgid "Average Rank"
msgstr "Gns. placering"

#: src/Pages/AccountSettings/AverageRank/index.tsx:46
#: src/Pages/DomainSettings/components/Menu/DisplaySettings/AverageRankSettings/index.tsx:83
msgid "Include \"Not in top 100\" rank keywords"
msgstr "Inkluder \"Ikke i top 100\"-placeringssøgeord"

#: src/Pages/AccountSettings/AverageRank/index.tsx:54
#: src/Pages/DomainSettings/components/Menu/DisplaySettings/AverageRankSettings/index.tsx:112
msgid "Rank value of \"Not in top 100\" rank keywords"
msgstr "Placerings værdi af \"Ikke i top 100\"-placeringssøgeord"

#: src/Pages/AccountSettings/helpers.tsx:64
msgid ""
"With this enabled you can access and create other AccuRanker accounts from "
"your own account."
msgstr ""
"Med det slået til kan du oprette og tilgå andre AccuRanker kontoer fra din "
"egen."

#: src/Pages/AccountSettings/helpers.tsx:70
msgid "Enabling sub-accounts is not possible from a sub-account."
msgstr "Det er ikke muligt at slå underkontoer til fra en underkonto."

#: src/Pages/AccountSettings/helpers.tsx:74
#: src/Pages/AccountSettings/helpers.tsx:90
msgid "Already enabled. To disable contact support."
msgstr "Allerede aktiveret. Kontakt support for at deaktivere."

#: src/Pages/AccountSettings/helpers.tsx:87
msgid ""
"With this enabled you can add as many keywords to your plan as you like."
msgstr ""
"Med dette aktiveret kan du tilføje lige så mange søgeord, som du ønsker."

#: src/Pages/AccountSettings/helpers.tsx:95
msgid "Not available on trial accounts."
msgstr "Ikke tilgængelig på prøvekonti."

#: src/Pages/AccountSettings/helpers.tsx:117
msgid ""
"Show search volume metrics based on local or country values for local "
"keywords. This setting applies to all domains set to 'Account Default'."
msgstr ""
"Vis søgevolumen baseret på lokale eller landeværdier for lokale søgeord. "
"Denne indstilling bliver anvendt på alle domæner indstillet til "
"'Kontobaseret Standard'."

#: src/Pages/AccountSettings/helpers.tsx:127
msgid ""
"[bold:Note:] If unchecked, national search volume metrics will be displayed "
"regardless of keyword location."
msgstr ""
"[bold:Note:] Hvis den ikke er markeret, vil nationale søgevolumen blive vist "
"uanset placeringen af søgeordet."

#: src/Pages/AccountSettings/helpers.tsx:144
#: src/Pages/DomainSettings/support/helpers.tsx:59
msgid ""
"When enabled, all search volume figures and related metrics (AI Share of "
"Voice, Cost per Click, Ad Competition, Share of Voice, and Traffic Value) "
"where a specific keyword location is specified will display local metrics. "
"If disabled, all related metrics will be based on country values. Learn more"
msgstr ""
"Når det er aktiveret, vil alle søgevolumen- og tilknyttede metrikker (AI "
"synlighed, \"Pris per klik\", Annoncekonkurrence, Synlighed og Trafikværdi), "
"hvor en bestemt placering for søgeordet er angivet, vise lokale målinger. "
"Hvis det er deaktiveret, vil alle relaterede metrikker være baseret på "
"landeværdier. Læs mere"

#: src/Pages/AccountSettings/helpers.tsx:152
#: src/Pages/DomainSettings/support/helpers.tsx:67
#: src/Pages/DomainSettings/support/helpers.tsx:86
#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageCTR/AverageCtrChart.tsx:94
#: src/Pages/Keywords/Overview/components/HistoryCharts/EstimatedVisits/EstimatedVisits.tsx:94
msgid "here."
msgstr "her."

#: src/Pages/AccountSettings/helpers.tsx:162
msgid ""
"The currency will reflect on your CPC & traffic values across AccuRanker. "
"This setting applies to all domains set to 'Account Default'."
msgstr ""
"Valutaen vil afspejle sig i din CPC og trafikværdi. Denne indstilling bliver "
"anvendt på alle domæner indstillet til 'Kontobaseret Standard'."

#: src/Pages/AccountSettings/index.tsx:164
msgid "Account settings updated successfully."
msgstr "Opdatering af kontoindstillinger lykkedes."

#: src/Pages/AccountSettings/index.tsx:168
#: src/Pages/DomainSettings/components/Menu/DisplaySettings/index.tsx:110
#: src/Pages/ExternalAccess/components/MultiAccountAllowAccess/components/MultiAccountRequestTextBlock.tsx:75
#: src/Pages/ExternalAccess/components/MultiAccountAllowAccess/MultiAccountAllowAccess.tsx:76
#: src/Pages/ExternalAccess/components/MultiAccountAllowAccess/MultiAccountAllowAccess.tsx:92
msgid "Something went wrong. Please try again later."
msgstr "Der gik noget galt. Prøv igen senere."

#: src/Pages/AccountSettings/index.tsx:197
msgid "Account Information"
msgstr "Konto informationer"

#: src/Pages/AccountSettings/index.tsx:201
msgid "Enter account name"
msgstr "Indtast kontonavn"

#: src/Pages/AccountSettings/index.tsx:210
msgid "Account Preferences"
msgstr "Konto Indstillinger"

#: src/Pages/AccountSettings/index.tsx:214
msgid "Enable the sub-account feature"
msgstr "Slå underkonto funktionen til"

#: src/Pages/AccountSettings/index.tsx:230
msgid "Enable keyword exceeding for this account?"
msgstr "Aktiver merforbrug af søgeord?"

#: src/Pages/AccountSettings/index.tsx:245
msgid "Account Display Settings"
msgstr "Kontovisningsindstillinger"

#: src/Pages/AccountSettings/index.tsx:251
msgid "Local search volume"
msgstr "Lokal søgevolumen"

#: src/Pages/AccountSettings/index.tsx:264
#: src/Pages/DomainSettings/components/Menu/DisplaySettings/index.tsx:149
msgid "CPC Currency"
msgstr "CPC Valuta"

#: src/Pages/AccuApi/index.tsx:30
msgid "No token found"
msgstr "Ingen token fundet"

#: src/Pages/AccuApi/index.tsx:41
msgid "Limit status overview"
msgstr "Forbrugsstatussoversigt"

#: src/Pages/AccuApi/index.tsx:47
msgid "Credits remaining"
msgstr "Credits tilbage"

#: src/Pages/AccuApi/index.tsx:54
msgid " out of "
msgstr " ud af "

#: src/Pages/AccuApi/index.tsx:60
msgid " per month. ([number] credits = 1 Google Search)"
msgstr " per måned. ([number] credits = 1 Google-søgning)"

#: src/Pages/AccuApi/index.tsx:66
msgid "GET requests remaining"
msgstr "GET-kald tilbage"

#: src/Pages/AccuApi/index.tsx:71 src/Pages/AccuApi/index.tsx:87
msgid "out of "
msgstr "ud af "

#: src/Pages/AccuApi/index.tsx:77 src/Pages/AccuApi/index.tsx:93
msgid " per hour"
msgstr " per time"

#: src/Pages/AccuApi/index.tsx:82
msgid "POST requests remaining"
msgstr "POST-kald tilbage"

#: src/Pages/AccuApi/index.tsx:99
#: src/Pages/Profile/components/IntegrationsApiToken/index.tsx:71
msgid "API Token"
msgstr "API Token"

#: src/Pages/AccuApi/index.tsx:111
msgid "Click here to read the documentation"
msgstr "Klik her for at se dokumentationen"

#: src/Pages/AccuApi/index.tsx:120
msgid "Requests per hour"
msgstr "Kald pr. time"

#: src/Pages/AccuApi/index.tsx:124
msgid "From:"
msgstr "Fra:"

#: src/Pages/AccuApi/index.tsx:130
msgid "To:"
msgstr "Til:"

#: src/Pages/AccuApi/index.tsx:140
msgid "Search history"
msgstr "Søgehistorik"

#: src/Pages/AccuApi/SearchHistoryTable/index.tsx:39
msgid "Tablet"
msgstr "Tablet"

#: src/Pages/AccuApi/SearchHistoryTable/index.tsx:107
msgid "Engine"
msgstr "Søgemaskine"

#: src/Pages/AccuApi/SearchHistoryTable/index.tsx:114
#: src/Pages/Domains/TreeView/support/constants.ts:50
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:166
#: src/Pages/Keywords/Groupings/support/constants.ts:65
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:190
msgid "Device"
msgstr "Enhed"

#: src/Pages/AccuApi/SearchHistoryTable/index.tsx:134
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:341
msgid "Locale"
msgstr "Lokalitet"

#: src/Pages/AccuApi/SearchHistoryTable/index.tsx:156
msgid "Geo"
msgstr "Geo"

#: src/Pages/AccuApi/SearchHistoryTable/index.tsx:180
msgid "No History"
msgstr "Ingen Historik"

#: src/Pages/AccuApi/SearchHistoryTable/index.tsx:181
msgid "There is currently no search history for this account."
msgstr "Der er i øjeblikket ingen søgehistorik for denne konto."

#: src/Pages/AccuApi/SpendingChart/index.tsx:44
msgid "Requests"
msgstr "Kald"

#: src/Pages/AccuApi/SpendingChart/useSpendinChartData.tsx:23
msgid "Hourly requests"
msgstr "Kald pr. time"

#: src/Pages/Affiliate/AffiliateCommissions/AffiliateCommissionsTable/index.tsx:96
#: src/Pages/Affiliate/AffiliatePayments/AffiliatePaymentsTable/index.tsx:104
msgid "Commission amount"
msgstr "Provisionsbeløb"

#: src/Pages/Affiliate/AffiliateCommissions/AffiliateCommissionsTable/index.tsx:112
#: src/Pages/Affiliate/AffiliatePayments/AffiliatePaymentsTable/index.tsx:120
#: src/Pages/Invoices/InvoicesTable.tsx:114
#: src/Pages/Invoices/InvoicesTable.tsx:117
#: src/Pages/Sales/Organization/index.tsx:656
#: src/Pages/Sales/Organization/index.tsx:668
msgid "Paid"
msgstr "Betalt"

#: src/Pages/Affiliate/AffiliateCommissions/AffiliateCommissionsTable/index.tsx:131
msgid "There are currently no commissions"
msgstr "Der er ingen kommisioner"

#: src/Pages/Affiliate/AffiliateCustomers/AffiliateCustomersTable/index.tsx:65
#: src/Pages/IntegrationApi/IntegrationAPIsTable/index.tsx:37
#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:181
#: src/Pages/Sales/Invoice/Customers/SalesInvoiceCustomersTable/hooks.tsx:57
#: src/Pages/Sales/Organization/index.tsx:207
#: src/Pages/Sales/Organization/index.tsx:324
#: src/Pages/Sales/Organization/index.tsx:369
#: src/Pages/Sales/Organization/index.tsx:424
#: src/Pages/Sales/Organization/index.tsx:636
#: src/Pages/Sales/Search/index.tsx:268
msgid "ID"
msgstr "ID"

#: src/Pages/Affiliate/AffiliateCustomers/AffiliateCustomersTable/index.tsx:71
msgid "Referred on"
msgstr "Henvist den"

#: src/Pages/Affiliate/AffiliateCustomers/AffiliateCustomersTable/index.tsx:141
msgid "There are currently no customers in this table"
msgstr "Der er ingen kunder i denne tabel"

#: src/Pages/Affiliate/AffiliateDashboard/index.tsx:242
msgid "Page views"
msgstr "Sidevisninger"

#: src/Pages/Affiliate/AffiliateDashboard/index.tsx:249
msgid "Visitors"
msgstr "Besøgende"

#: src/Pages/Affiliate/AffiliateDashboard/index.tsx:256
msgid "Active Trials"
msgstr "Aktive trials"

#: src/Pages/Affiliate/AffiliateDashboard/index.tsx:263
msgid "New Trials"
msgstr "Nye trials"

#: src/Pages/Affiliate/AffiliateDashboard/index.tsx:270
msgid "Visitor to Trial conversion rate"
msgstr "Besøgende til trial konverteringsratio"

#: src/Pages/Affiliate/AffiliateDashboard/index.tsx:278
msgid "Active Customers"
msgstr "Aktive kunder"

#: src/Pages/Affiliate/AffiliateDashboard/index.tsx:285
msgid "New Customers"
msgstr "Nye kunder"

#: src/Pages/Affiliate/AffiliateDashboard/index.tsx:292
msgid "Commissions"
msgstr "Provisioner"

#: src/Pages/Affiliate/AffiliateDashboard/index.tsx:334
msgid "Error fetching data."
msgstr "Fejl under hentning af data."

#: src/Pages/Affiliate/AffiliateDashboard/index.tsx:339
msgid "There are no data for the selected period."
msgstr "Der er ingen data for den valgte periode."

#: src/Pages/Affiliate/AffiliatePayments/AffiliatePaymentsTable/index.tsx:139
#: src/Pages/Affiliate/AffiliateVisitors/AffiliateReferralsTable/index.tsx:65
msgid "There are currently no referrers"
msgstr "Der er ingen referrers"

#: src/Pages/Affiliate/index.tsx:35
msgid "Referrals"
msgstr "Henvisninger"

#: src/Pages/Affiliate/index.tsx:36
msgid "Commisions"
msgstr "Provisioner"

#: src/Pages/Affiliate/index.tsx:37
msgid "Payments"
msgstr "Betalinger"

#: src/Pages/AIOverview/components/AIOverviewTable/AIOverviewTable.tsx:35
msgid "Currently, no keywords feature the AI Overview SERP feature."
msgstr "I øjeblikket viser ingen søgeord AI Oversigt SERP-funktionen."

#: src/Pages/AIOverview/components/AIOverviewTable/support/AIOverviewCell.tsx:194
msgid "An error occurred while getting details about this SERP feature."
msgstr "Der opstod en fejl under hentning af detaljer om denne SERP-funktion."

#: src/Pages/AIOverview/components/AIOverviewTable/support/AIOverviewCell.tsx:212
msgid "owned"
msgstr "ejet"

#: src/Pages/AIOverview/components/AIOverviewTable/support/AIOverviewCell.tsx:216
msgid "Copy the AI Overview text"
msgstr "Kopier AI Oversigt teksten"

#: src/Pages/AIOverview/components/AIOverviewTable/support/AIOverviewCell.tsx:247
msgid "Listed domains"
msgstr "Listede domæner"

#: src/Pages/AIOverview/components/AIOverviewTable/support/AIOverviewCell.tsx:281
#: src/Pages/AIOverview/components/AIOverviewTable/support/AIOverviewCell.tsx:328
msgid "Copy the URL"
msgstr "Kopier URLen"

#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:167
msgid "Woops! Something went wrong when generating data for the CSV export"
msgstr "Hov! Noget gik galt ved forsøg på at generere data for CSV eksporten"

#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:197
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:243
msgid "Add filter for Keyword"
msgstr "Tilføj filter for søgeord"

#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:225
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:301
msgid "Add filter for Search Type"
msgstr "Tilføj filter for søgetype"

#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:245
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:354
msgid "Add filter for Country Locale"
msgstr "Tilføj filter for land"

#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:268
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:296
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:399
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:423
msgid "Add filter for Rank"
msgstr "Tilføj filter for placering"

#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:270
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:298
#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/support/hooks.tsx:121
#: src/Pages/Keywords/Table/keydisCells.ts:127
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:401
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:425
msgid "Rank column filters"
msgstr "Sorteringskolonnefiltre"

#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:285
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:373
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:223
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:257
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:291
#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/support/hooks.tsx:127
#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/support/hooks.tsx:164
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:170
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:215
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:269
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:307
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:338
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:367
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:396
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:425
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:455
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:492
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/hooks.tsx:174
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/hooks.tsx:210
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/hooks.tsx:272
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/hooks.tsx:322
#: src/Pages/Keywords/Overview/components/OverviewNotifications/support/hooks/table.tsx:126
#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:238
#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:260
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:416
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:462
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:531
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:576
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:653
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:776
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:833
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:926
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:987
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:142
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:194
msgid "+/-"
msgstr "+/-"

#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:287
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:227
#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/support/hooks.tsx:135
#: src/Pages/Keywords/Overview/components/OverviewNotifications/support/hooks/table.tsx:128
#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:241
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:418
msgid "Change in rank between the two compared dates"
msgstr "Ændring i placering mellem de to sammenlignede datoer"

#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:319
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:682
msgid "Add filter for URL"
msgstr "Tilføj filter for URL"

#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:329
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:688
msgid "Refreshing rank"
msgstr "Opdaterer placering"

#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:362
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:384
#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:405
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:765
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:783
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:800
msgid "Add filter for CTR"
msgstr "Tilføj filter for CTR"

#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:375
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:778
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:198
msgid "Change in ctr between the two compared dates"
msgstr "Ændring i CTR mellem de to sammenlignede datoer"

#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:386
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:785
#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:202
msgid "Change in CTR"
msgstr "Ændring i CTR"

#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:394
#: src/Pages/Keywords/Table/keydisCells.ts:211
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:483
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:596
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:793
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:857
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:948
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1006
msgid "Max"
msgstr "Maks"

#: src/Pages/AIOverview/components/AIOverviewTable/support/useAIOverviewConfig.tsx:427
msgid "AI Overview data"
msgstr "AI Overblik data"

#: src/Pages/AIOverview/components/HistoricalKeywordCountChart/HistoricalKeywordCount.tsx:41
msgid "Keywords including AIO"
msgstr "Søgeord indeholdende AIO"

#: src/Pages/AIOverview/components/HistoricalKeywordCountChart/HistoricalKeywordCount.tsx:49
msgid "Keywords including AIO - owned"
msgstr "Søgeord indeholdende AIO - ejede"

#: src/Pages/AIOverview/components/HistoricalKeywordCountChart/HistoricalKeywordCount.tsx:58
msgid "Historical Keyword Count with AI Overview"
msgstr "Historisk Antal Søgeord med AI Oversigt"

#: src/Pages/AIOverview/components/HistoricalKeywordCountChart/HistoricalKeywordCount.tsx:74
msgid ""
"The graph illustrates the number of keywords including AI Overview SERPs "
"over time, along with the distribution of owned AI Overview SERPs."
msgstr ""
"Grafen illustrerer antallet af søgeord med AI Oversigt SERPs over tid, "
"sammen med fordelingen af ejede AI Oversigt SERPs."

#: src/Pages/AIOverview/components/TopDomainsChart/TopDomainsChart.tsx:27
msgid "Top Domains found in AI Overview"
msgstr "Top Domæner fundet i AI Oversigt"

#: src/Pages/AIOverview/components/TopDomainsChart/TopDomainsChart.tsx:28
msgid ""
"The table highlights the top domains most frequently appearing in AI "
"Overview SERPs for this domain."
msgstr ""
"Tabellen fremhæver de domæner som hyppigst forekommer i AI Oversigt SERPs "
"for dette domæne."

#: src/Pages/AIOverview/components/TopUrlsChart/TopUrlsChart.tsx:27
msgid "Top URLs"
msgstr "Top URLer"

#: src/Pages/AIOverview/components/TopUrlsChart/TopUrlsChart.tsx:28
msgid ""
"The table shows the top URLs displayed in the AI Overview SERPs for this "
"domain."
msgstr "Tabellen viser top URLer vist i dette domænes AI Oversigt SERPs."

#: src/Pages/AIOverview/components/UrlCountChart/UrlCountChart.tsx:37
#: src/Pages/AIOverview/components/UrlCountChart/UrlCountChart.tsx:39
msgid "Number of keywords"
msgstr "Antal søgeord"

#: src/Pages/AIOverview/components/UrlCountChart/UrlCountChart.tsx:40
msgid "Number of URLs in AI Overview"
msgstr "Antal URLer i AI Oversigt"

#: src/Pages/AIOverview/components/UrlCountChart/UrlCountChart.tsx:45
msgid "Distribution of URLs in AI Overviews"
msgstr "Fordeling af URLer i AI Oversigter"

#: src/Pages/AIOverview/components/UrlCountChart/UrlCountChart.tsx:55
msgid ""
"The graph illustrates the distribution of URLs across AI Overview SERPs for "
"this domain, highlighting the frequency of AI Overviews with varying URL "
"counts."
msgstr ""
"Grafen illustrerer fordelingen af URLer på tværs af AI Oversigt SERPs for "
"dette domæne, hvilket fremhæver frekvensen af AI Oversigter med varierende "
"antal URLer."

#: src/Pages/AIOverview/components/useAIOBarChartConfig.tsx:183
msgid "No data for the selected filters"
msgstr "Ingen data for de valgte filtre"

#: src/Pages/Auth/LoginPage/components/AuthTokenForm.tsx:24
msgid "Please enter the token generated by your token generator."
msgstr "Indtast den nøgle der er lavet af din nøglegenerator."

#: src/Pages/Auth/LoginPage/components/AuthTokenForm.tsx:30
msgid "Token"
msgstr "Token"

#: src/Pages/Auth/LoginPage/components/AuthTokenForm.tsx:37
#: src/Pages/Auth/PasswordSetupPage/components/PasswordChangeForm.tsx:51
#: src/Pages/Auth/ResetPasswordPage/components/ResetPasswordForm.tsx:45
msgid "Back to Login"
msgstr "Tilbage til log ind"

#: src/Pages/Auth/LoginPage/components/AuthTokenForm.tsx:43
msgid "Log In"
msgstr "Log ind"

#: src/Pages/Auth/LoginPage/components/LoginForm.tsx:33
msgid "Work email address"
msgstr "Arbejds-mailadresse"

#: src/Pages/Auth/LoginPage/components/LoginForm.tsx:40
#: src/Pages/Register/components/CompanyInfo.tsx:98
msgid "Password"
msgstr "Adgangskode"

#: src/Pages/Auth/LoginPage/components/LoginForm.tsx:59
msgid "Forgot your password?"
msgstr "Glemt din adgangskode?"

#: src/Pages/Auth/LoginPage/components/LoginForm.tsx:73
msgid "Sign in"
msgstr "Log ind"

#: src/Pages/Auth/LoginPage/components/LoginForm.tsx:79
msgid "OR SIGN IN WITH"
msgstr "ELLER LOG IND MED"

#: src/Pages/Auth/LoginPage/components/LoginForm.tsx:84
msgid "Don't have an account?"
msgstr "Har du ikke en konto?"

#: src/Pages/Auth/LoginPage/components/LoginForm.tsx:88
#: src/Pages/Auth/LoginPage/LoginPage.tsx:138
msgid "Sign up"
msgstr "Opret"

#: src/Pages/Auth/LoginPage/LoginPage.tsx:101
#: src/Pages/Auth/ResetPasswordPage/ResetPasswordPage.tsx:29
#: src/Pages/Register/index.tsx:237
msgid "The World's Fastest & Most Accurate Rank Tracker"
msgstr "Verdens hurtigste rank-tracker"

#: src/Pages/Auth/LoginPage/LoginPage.tsx:104
msgid "Sign in to AccuRanker"
msgstr "Log ind i AccuRanker"

#: src/Pages/Auth/LoginPage/LoginPage.tsx:123
msgid "User not found"
msgstr "Konto ikke fundet"

#: src/Pages/Auth/LoginPage/LoginPage.tsx:127
msgid ""
"We're unable to find an account associated with the email "
"address[userNotFoundEmail].[gutterSm]To create a new account, or to sign up "
"with a different email address, please click the button below."
msgstr ""
"Vi kan ikke finde en konto, der er forbundet med e-"
"mailadressen[userNotFoundEmail].[gutterSm]Klik på knappen nedenfor for at "
"oprette en ny konto eller tilmelde dig med en anden e-mailadresse."

#: src/Pages/Auth/PasswordSetupPage/components/PasswordChangeForm.tsx:35
msgid "New Password"
msgstr "Nyt kodeord"

#: src/Pages/Auth/PasswordSetupPage/components/PasswordChangeForm.tsx:43
msgid "New Password Confirmation"
msgstr "Bekræftelse af nyt kodeord"

#: src/Pages/Auth/PasswordSetupPage/components/PasswordChangeStatusBlocks.tsx:11
msgid "Password reset"
msgstr "Nulstil adgangskode"

#: src/Pages/Auth/PasswordSetupPage/components/PasswordChangeStatusBlocks.tsx:13
msgid "Your password has been reset."
msgstr "Dit kodeord er blevet nulstillet."

#: src/Pages/Auth/PasswordSetupPage/components/PasswordChangeStatusBlocks.tsx:16
msgid "Continue to Log in"
msgstr "Fortsæt til log ind"

#: src/Pages/Auth/PasswordSetupPage/components/PasswordChangeStatusBlocks.tsx:26
msgid "Invalid Link"
msgstr "Ugyldigt link"

#: src/Pages/Auth/PasswordSetupPage/components/PasswordChangeStatusBlocks.tsx:29
msgid ""
"Link is not valid or expired, please request new link to reset password."
msgstr "Linket er ikke validt eller er udløbet."

#: src/Pages/Auth/PasswordSetupPage/components/PasswordChangeStatusBlocks.tsx:33
msgid "Request new link to reset password"
msgstr "Få nyt link til nulstilling af kodeord"

#: src/Pages/Auth/ResetPasswordPage/components/ResetPasswordForm.tsx:27
msgid ""
"Enter the email you use for log in. We will send you an email with a link to "
"reset your password."
msgstr ""
"Indtast den e-mailadresse du bruger til log ind. Vi sender dig en e-mail med "
"et link til at nulstille dit kodeord."

#: src/Pages/Auth/ResetPasswordPage/components/ResetPasswordForm.tsx:59
msgid ""
"If you can't remember your log in email, please contact our support by using "
"the support icon in the corner - or send an email to [link:hello@accuranker."
"com]."
msgstr ""
"Hvis du ikke kan huske den email, du bruger til log ind, kan du kontakte "
"supporten - eller sende en e-mail til [link:hello@accuranker.com]."

#: src/Pages/Auth/ResetPasswordPage/components/ResetPasswordForm.tsx:63
msgid "AccuRanker support Email"
msgstr "AccuRanker support e-mail"

#: src/Pages/Auth/ResetPasswordPage/components/SuccessResetPassword.tsx:11
msgid "Reset your password"
msgstr "Nulstil dit kodeord"

#: src/Pages/Auth/ResetPasswordPage/components/SuccessResetPassword.tsx:14
msgid "We have sent you a password reset email. Please check your inbox."
msgstr "Vi har sendt dig en e-mail med et link til nulstilling af adgangskode."

#: src/Pages/Auth/ResetPasswordPage/components/SuccessResetPassword.tsx:18
msgid "Back to Log in"
msgstr "Tilbage til log ind"

#: src/Pages/Billing/CancelPlan/index.tsx:53
msgid "Unable to cancel plan"
msgstr "Kunne ikke annullere abonnement"

#: src/Pages/Billing/CancelPlan/index.tsx:66
msgid "Your subscription has been canceled"
msgstr "Dit abonnement er blevet annulleret"

#: src/Pages/Billing/CancelPlan/index.tsx:69
msgid "You can access your AccuRanker account until %s"
msgstr "Du kan tilgå din AccuRanker konto indtil %s"

#: src/Pages/Billing/CancelPlan/index.tsx:78
msgid ""
"If you at one point would like to come back to AccuRanker, we will be "
"storing your data, so you won’t lose your valuable historical data."
msgstr ""
"Hvis du på et tidspunkt ønsker at komme tilbage til AccuRanker, så har vi "
"gemt dine data så du ikke mister vigtige historiske data."

#: src/Pages/Billing/CancelPlan/index.tsx:82
msgid "Sincerely, The AccuRanker Team"
msgstr "Med venlig hilsen, AccuRanker holdet"

#: src/Pages/Billing/CancelPlan/index.tsx:84
#: src/Pages/Billing/CancelPlan/index.tsx:138
msgid "Go to billing"
msgstr "Gå til betaling"

#: src/Pages/Billing/CancelPlan/index.tsx:97
msgid "Cancellation of your AccuRanker subscription"
msgstr "Annullering af dit AccuRanker abonnement"

#: src/Pages/Billing/CancelPlan/index.tsx:100
msgid "Hi %s"
msgstr "Hej %s"

#: src/Pages/Billing/CancelPlan/index.tsx:102
msgid "You are about to cancel your subscription."
msgstr "Du er ved at opsige dit abonnement."

#: src/Pages/Billing/CancelPlan/index.tsx:106
#: src/Pages/Profile/components/EditProfile/two-factor.tsx:21
msgid "Details"
msgstr "Detaljer"

#: src/Pages/Billing/CancelPlan/index.tsx:108
msgid "Plan: %s with %s keywords"
msgstr "Abonnement: %s med %s søgeord"

#: src/Pages/Billing/CancelPlan/index.tsx:115
msgid "Price: [price] [period]"
msgstr "Pris: [price] [period]"

#: src/Pages/Billing/CancelPlan/index.tsx:124
msgid "paid monthly"
msgstr "månedlig betaling"

#: src/Pages/Billing/CancelPlan/index.tsx:124
msgid "paid yearly"
msgstr "betalt årligt"

#: src/Pages/Billing/CancelPlan/index.tsx:128
msgid "Your subscription will be accessible until: %s"
msgstr "Dit abonnement er tilgængeligt indtil: %s"

#: src/Pages/Billing/CancelPlan/index.tsx:141
msgid "Cancel subscription"
msgstr "Opsig abonnement"

#: src/Pages/Billing/Checkout/index.tsx:84
msgid "Select Another Plan"
msgstr "Vælg et andet abonnement"

#: src/Pages/Billing/Checkout/index.tsx:86
msgid ""
"Something went wrong with the plan you selected. Select another plan or "
"contact us if you have any questions."
msgstr ""
"Der skete en fejl med det valgte abonnement. Vælg et andet abonnement eller "
"kontakt os hvis du har spørgsmål."

#: src/Pages/Billing/Checkout/index.tsx:135
#: src/Pages/Layout/NavTop/index.tsx:66
msgid "Processing payment"
msgstr "Foretager betaling"

#: src/Pages/Billing/Checkout/index.tsx:272
msgid ""
"You are paying via invoice. Please contact us to change your subscription."
msgstr "Du betaler via faktura. Kontakt os for at ændre dit abonnement."

#: src/Pages/Billing/Checkout/index.tsx:309
msgid "Your Order Details"
msgstr "Dine ordreoplysninger"

#: src/Pages/Billing/Checkout/index.tsx:322
#: src/Pages/Billing/UpdatePaymentInfo/index.tsx:84
msgid "Company Details"
msgstr "Firmadetaljer"

#: src/Pages/Billing/Checkout/index.tsx:330
msgid "Payment Details"
msgstr "Betalingsdetaljer"

#: src/Pages/Billing/Checkout/index.tsx:348
msgid "Place Order"
msgstr "Afgiv bestilling"

#: src/Pages/Billing/Checkout/index.tsx:365
#: src/Pages/Users/UsersTable/index.tsx:102
#: src/Pages/Workspaces/WorkspacesTable/index.tsx:84
msgid "Confirm"
msgstr "Godkend"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:150
#: src/Pages/Register/components/CompanyInfo.tsx:53
msgid "Company name"
msgstr "Firmanavn"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:156
#: src/Pages/Register/components/CompanyInfo.tsx:59
msgid "Enter your company name"
msgstr "Indtast firmanavn"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:171
msgid "Email address for invoices"
msgstr "E-mailadresse til fakturaer"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:176
#: src/Pages/Profile/components/EditProfile/index.tsx:201
msgid "Enter your email"
msgstr "Indtast din e-mail"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:184
msgid "P.O. number"
msgstr "P.O. nummer"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:189
msgid "Enter P.O. number if required"
msgstr "Indtast P.O. nummer hvis nødvendigt"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:205
msgid "Enter your VAT number to avoid VAT charges"
msgstr "Indtast dit CVR-nummer for at undgå pålagt moms (gælder ikke DK)"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:212
msgid "EU vat number"
msgstr "CVR-nummer"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:231
msgid "Enter your vat number"
msgstr "Indtast CVR-nummer"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:327
#: src/Pages/SelectPlan/plan-features.tsx:29
msgid "Street"
msgstr "Adresse"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:333
#: src/Pages/Register/components/AddressInfo.tsx:43
msgid "Enter your street"
msgstr "Indtast adresse"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:341
msgid "Zip/postal code"
msgstr "Postnummer"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:347
#: src/Pages/Register/components/AddressInfo.tsx:78
msgid "Enter your ZIP"
msgstr "Indtast postnummer"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:355
#: src/Pages/Register/components/AddressInfo.tsx:50
#: src/Pages/SelectPlan/plan-features.tsx:29
msgid "City"
msgstr "By"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:361
#: src/Pages/Register/components/AddressInfo.tsx:56
msgid "Enter your city"
msgstr "Indtast by"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:369
msgid "State / Province / Region"
msgstr "Stat"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:374
#: src/Pages/Register/components/AddressInfo.tsx:68
msgid "Enter your state/province/region"
msgstr "Indtast område"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:387
#: src/Pages/Register/components/AddressInfo.tsx:89
msgid "Select your country"
msgstr "Vælg land"

#: src/Pages/Billing/CompanyInfoWidget/index.tsx:401
msgid "Required fields"
msgstr "Påkrævede felter"

#: src/Pages/Billing/DealExpired/index.tsx:32
msgid ""
"[header: Deal Expired][br]Click here to return to the [link: Select Plan] "
"page"
msgstr ""
"[header: Tilbud udløbet][br]Klik her for at gå til [link: Skift abonnement] "
"siden"

#: src/Pages/Billing/DealExpired/index.tsx:41
msgid ""
"[header: Deal Not Yet Open][br]Click here to return to the [link: Select "
"Plan] page"
msgstr ""
"[header: Tilbud ikke åbent endnu][br]Klik her for at gå tilbage til [link: "
"Skift abonnement] siden"

#: src/Pages/Billing/DealExpired/index.tsx:53
msgid "Go Back"
msgstr "Gå Tilbage"

#: src/Pages/Billing/InfoWidget/index.tsx:17
msgid ""
"You are upgrading your current plan. Plan upgrades are effective immediately."
msgstr ""
"Du er ved at opgradere dit abonnement. Abonnementopgraderinger træder i "
"kraft med det samme."

#: src/Pages/Billing/InfoWidget/index.tsx:27
msgid ""
"You are downgrading your current plan. Plans downgrades are effective from "
"your next payment."
msgstr "Du nedgraderer dit abonnement. Nedgraderinger sker fra næste betaling."

#: src/Pages/Billing/InfoWidget/index.tsx:31
msgid "Important"
msgstr "Vigtigt"

#: src/Pages/Billing/InfoWidget/index.tsx:33
msgid ""
"If you utilize more domains, keywords, competitors or users than is "
"available on your new plan on the day of the downgrade, we will not be able "
"to downgrade you and you will be billed for your old plan."
msgstr ""
"Hvis du bruger flere domæner, søgeord, konkurrenter eller brugere end der er "
"til rådighed på dit nye abonnement på nedgraderingsdagen, kan vi desværre "
"ikke nedgradere og du fortsætter på dit gamle abonnement."

#: src/Pages/Billing/OrderPlanWidget/index.tsx:58
msgid "month"
msgid_plural "months"
msgstr[0] "måned"
msgstr[1] "måneder"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:66
msgid "price"
msgstr "pris"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:83
msgid "Receivable from current plan"
msgstr "Tilgodehavende fra nuværende abonnement"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:100
msgid "Sign-on discount"
msgstr "Sign-on rabat"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:124
msgid "Discount will be applied monthly for %s months"
msgstr "Rabatten vil blive givet månedligt i %s måneder"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:137
msgid "Coupon discount"
msgstr "Kuponrabat"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:154
msgid "Sub-account discount"
msgstr "Underkonto rabat"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:171
msgid "Wallet credit"
msgstr "Tegnebogskredit"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:171
msgid "Wallet debit"
msgstr "Tegnebogsdebet"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:190
#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:283
msgid "Keyword overuse"
msgstr "Merforbrug af søgeord"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:191
msgid "Keyword overuse refund"
msgstr "Refundering af søgeord merforbrug"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:207
#: src/Pages/Sales/Organization/index.tsx:685
msgid "Total before VAT"
msgstr "I alt ekskl. moms"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:222
#: src/Pages/Invoices/InvoicesTable.tsx:74
#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:267
#: src/Pages/Sales/Search/index.tsx:198
msgid "VAT"
msgstr "Moms"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:238
#: src/Pages/Invoices/InvoicesTable.tsx:95
#: src/Pages/KeywordOverusePage/components/OveruseTable/support/hooks.tsx:78
#: src/Pages/Keywords/SERP/FlippedChart/support/useFlippedChartConfig.tsx:78
#: src/Pages/Keywords/Table/Chart/SearchVolume/index.tsx:82
#: src/Pages/Sales/Invoice/Customers/SalesInvoiceCustomersTable/hooks.tsx:134
#: src/Pages/Sales/Organization/index.tsx:696
msgid "Total"
msgstr "Total"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:256
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:157
msgid "Change Plan"
msgstr "Skift abonnement"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:266
msgid "Change to yearly payment"
msgstr "Skift til årlig betaling"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:267
msgid "Change to monthly payment"
msgstr "Skift til månedlig betaling"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:303
msgid "After the trial, you will continue on"
msgstr "Efter prøveperioden fortsætter du på"

#: src/Pages/Billing/OrderPlanWidget/index.tsx:338
#: src/Pages/Billing/OrderPlanWidget/index.tsx:381
msgid "Selected plan"
msgstr "Valgt plan"

#: src/Pages/Billing/Overview/index.tsx:26
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/components/OveruseInfoDetails.tsx:106
msgid "Change plan"
msgstr "Skift abonnement"

#: src/Pages/Billing/Overview/index.tsx:26
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/components/OveruseInfoDetails.tsx:106
msgid "Reactivate"
msgstr "Genaktiver"

#: src/Pages/Billing/Overview/index.tsx:31
msgid "Only admin users can change the plan"
msgstr "Det er kun administrator-brugere der kan ændre abonnementet"

#: src/Pages/Billing/Overview/index.tsx:32
msgid "Only admin users can reactivate the plan"
msgstr "Det er kun administrator-brugere der kan genaktivere abonnementet"

#: src/Pages/Billing/Overview/index.tsx:46
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/OveruseInfoPanel.tsx:42
msgid "Plan Info"
msgstr "Abonnementsinfo"

#: src/Pages/Billing/Overview/index.tsx:50
#: src/Pages/Billing/Subscription/Subscription.tsx:34
msgid "Billing Info"
msgstr "Faktureringsinfo"

#: src/Pages/Billing/Overview/index.tsx:54
#: src/Pages/Billing/Subscription/Subscription.tsx:38
#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:244
msgid "Payment Method"
msgstr "Betalingsmetode"

#: src/Pages/Billing/Overview/PaymentContact/index.tsx:15
msgid "You need to be organization admin to see billing information."
msgstr "Du skal være administrator for at faktureringsoplysninger."

#: src/Pages/Billing/Overview/PaymentContact/index.tsx:18
msgid "No billing information for this account"
msgstr "Ingen faktureringsinformation på denne konto"

#: src/Pages/Billing/Overview/PaymentContact/index.tsx:37
msgid "P.O. number:"
msgstr "P.O. nummer:"

#: src/Pages/Billing/Overview/PaymentContact/index.tsx:37
#: src/Pages/Billing/Overview/PaymentContact/index.tsx:42
msgid "Not set"
msgstr "Ikke sat"

#: src/Pages/Billing/Overview/PaymentContact/index.tsx:42
msgid "Email for invoices:"
msgstr "E-mailadresse til fakturaer:"

#: src/Pages/Billing/Overview/PaymentMethod/index.tsx:16
msgid "You need to be organization admin to see payment method information."
msgstr "Du skal være administrator for at se information om betalingsmetode."

#: src/Pages/Billing/Overview/PaymentMethod/index.tsx:21
msgid "Your account is paid via invoice."
msgstr "Dit abonnement bliver betalt via faktura."

#: src/Pages/Billing/Overview/PaymentMethod/index.tsx:22
msgid "Your payment terms are %s days."
msgstr "Din betalingsvilkår er %s dage."

#: src/Pages/Billing/Overview/PaymentMethod/index.tsx:24
msgid ""
"The invoice is emailed to your payment contact. If none is set, the invoices "
"are sent to all account admins."
msgstr ""
"Fakturaen bliver sendt til dig på den mail du har indtastet under "
"betalingsoplysninger. Hvis ikke du har indtastet en email, sendes den til "
"alle administratorer."

#: src/Pages/Billing/Overview/PaymentMethod/index.tsx:36
msgid "No payment method selected"
msgstr "Ingen betalingsmetode valgt"

#: src/Pages/Billing/Overview/PaymentMethod/index.tsx:49
msgid "Card Number"
msgstr "Kortnummer"

#: src/Pages/Billing/Overview/PaymentMethod/index.tsx:53
msgid "Expiration Date"
msgstr "Udløbsdato"

#: src/Pages/Billing/Overview/PaymentMethod/index.tsx:57
msgid ""
"We do not store credit card information on our servers. [link:Our payment "
"processor] is a validated Level 1 PCI DSS Compliant Service Provider."
msgstr ""
"Vi gemmer ikke kreditkort-information på vores servere. [link:Vores "
"betalingsprocessor] er valideret Level 1 PCI DSS Compliant Service Provider."

#: src/Pages/Billing/Overview/PaymentMethod/index.tsx:79
msgid "PayPal Account"
msgstr "PayPal konto"

#: src/Pages/Billing/Overview/Plan/index.tsx:39
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/components/OveruseInfoDetails.tsx:59
msgid "Monthly price"
msgstr "Månedlig pris"

#: src/Pages/Billing/Overview/Plan/index.tsx:50
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/components/OveruseInfoDetails.tsx:70
msgid "Annual price"
msgstr "Årligt"

#: src/Pages/Billing/Overview/Plan/index.tsx:64
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/components/OveruseInfoDetails.tsx:84
msgid "Paid monthly"
msgid_plural "Paid every %s months"
msgstr[0] "Månedlig betaling"
msgstr[1] "Betaling hver %s. måned"

#: src/Pages/Billing/Overview/Plan/index.tsx:70
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/components/OveruseInfoDetails.tsx:89
msgid "Next payment"
msgstr "Næste betaling"

#: src/Pages/Billing/Overview/Plan/index.tsx:92
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/OveruseInfoPanel.tsx:51
msgid "You need to be organization admin to update plan"
msgstr "Du skal være administrator for at opdatere abonnement"

#: src/Pages/Billing/Overview/Plan/index.tsx:96
msgid "Failed to fetch data"
msgstr "Kunne ikke hente data"

#: src/Pages/Billing/Overview/Plan/index.tsx:101
#: src/Pages/Billing/Overview/Plan/index.tsx:104
#: src/Pages/Billing/Overview/Plan/index.tsx:106
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/components/OveruseInfoDetails.tsx:46
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/components/OveruseInfoDetails.tsx:50
msgid "%s with %s keywords"
msgstr "%s med %s søgeord"

#: src/Pages/Billing/Overview/Plan/index.tsx:114
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/components/OveruseInfoDetails.tsx:34
msgid "The plan will expire on %s. You will be switched to the \"%s\" on %s."
msgstr "Abonnementet udløber den %s. Du skifter til %s den %s."

#: src/Pages/Billing/Overview/Plan/index.tsx:136
#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/components/OveruseInfoDetails.tsx:94
msgid "Cancel my account"
msgstr "Opsig min konto"

#: src/Pages/Billing/Overview/Plan/index.tsx:145
msgid "Plan cancelled"
msgstr "Plan annulleret"

#: src/Pages/Billing/Overview/Plan/index.tsx:154
msgid "This plan is expired"
msgstr "Dette abonnement er udløbet"

#: src/Pages/Billing/PaymentWidget/index.tsx:54
msgid "Connecting to payment provider…"
msgstr "Forbinder til vores betalingsudbyder…"

#: src/Pages/Billing/PaymentWidget/index.tsx:321
msgid "Payment Information"
msgstr "Betalingsinfo"

#: src/Pages/Billing/SpecialOfferTimer/index.tsx:16
msgid "This offer expires in"
msgstr "Dette tilbud udløber om"

#: src/Pages/Billing/SpecialOfferTimer/index.tsx:20
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:314
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:319
msgid "Day"
msgid_plural "Days"
msgstr[0] "Dag"
msgstr[1] "Dage"

#: src/Pages/Billing/SpecialOfferTimer/index.tsx:24
msgid "Hour"
msgid_plural "Hours"
msgstr[0] "Time"
msgstr[1] "Timer"

#: src/Pages/Billing/SpecialOfferTimer/index.tsx:28
msgid "Minute"
msgid_plural "Minutes"
msgstr[0] "Minut"
msgstr[1] "Minutter"

#: src/Pages/Billing/SpecialOfferTimer/index.tsx:32
msgid "Second"
msgid_plural "Seconds"
msgstr[0] "Sekund"
msgstr[1] "Sekunder"

#: src/Pages/Billing/UpdatePaymentInfo/index.tsx:56
msgid ""
"We were unable to save your company information. Please contact support."
msgstr ""
"Vi kunne ikke gemme dine virksomhedsoplysninger. Kontakt venligst support."

#: src/Pages/Billing/UpdatePaymentInfo/index.tsx:67
msgid "We could not update your company information"
msgstr "Vi kunne ikke opdatere dine firmainformationer"

#: src/Pages/Billing/UpdatePaymentInfo/index.tsx:74
msgid "Company details updated successfully"
msgstr "Virksomhedsoplysninger opdateret succesfuldt"

#: src/Pages/Billing/UpdatePaymentMethod/UpdatePaymentMethodWidget/index.tsx:96
msgid "We could not update your payment information"
msgstr "Vi kunne ikke opdatere dine betalingsinformationer"

#: src/Pages/ConnectedAccounts/index.tsx:32
msgid "Connect integration"
msgstr "Forbind integration"

#: src/Pages/ConnectedAccounts/support/hooks.tsx:161
msgid "Unable to remove account"
msgstr "Kunne ikke slette kontoen"

#: src/Pages/ConnectedAccounts/support/hooks.tsx:210
msgid "Delete account"
msgstr "Slet konto"

#: src/Pages/ConnectedAccounts/support/hooks.tsx:212
msgid "Delete Connected Account?"
msgstr "Slet forbundet konto?"

#: src/Pages/ConnectedAccounts/support/hooks.tsx:213
msgid "Any domain using this account will be disconnected as well."
msgstr "Alle domæner der bruger denne konto får forbindelsen afbrudt."

#: src/Pages/ConnectedAccounts/support/hooks.tsx:266
msgid "Added By"
msgstr "Tilføjet af"

#: src/Pages/ConnectedAccounts/support/hooks.tsx:281
msgid "Linked At"
msgstr "Forbundet den"

#: src/Pages/ConnectedAccounts/support/hooks.tsx:296
msgid "no. uses - Adobe Marketing"
msgstr "antal anvendelser - Adobe Marketing"

#: src/Pages/ConnectedAccounts/support/hooks.tsx:308
msgid "no. uses - Google Analytics"
msgstr "antal anvendelser - Google Analytics"

#: src/Pages/ConnectedAccounts/support/hooks.tsx:319
msgid "no. uses - GSC"
msgstr "Gange brugt - GSC"

#: src/Pages/ConnectedAccounts/support/hooks.tsx:329
msgid "no. uses - BigQuery"
msgstr "Gange brugt - BigQuery"

#: src/Pages/ConnectedAccounts/support/hooks.tsx:351
msgid "Rename"
msgstr "Omdøb"

#: src/Pages/Domains/CardView/components/AddDomain/index.tsx:34
#: src/Pages/Domains/CardView/components/NoDomains.tsx:37
#: src/Pages/Domains/DomainsActionBar.tsx:110
#: src/Pages/KeywordDiscovery/components/ResearchPanel/importButton.tsx:118
#: src/Pages/Keywords/Overview/components/OverviewActionBar.tsx:188
msgid "Add domain"
msgstr "Tilføj domæne"

#: src/Pages/Domains/CardView/components/CardViewGroupsAndDomains.tsx:111
#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/NextGroupsAndDomains.tsx:56
msgid "Add new group"
msgstr "Tilføj ny gruppe"

#: src/Pages/Domains/CardView/components/CardViewGroupsAndDomains.tsx:113
#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/NextGroupsAndDomains.tsx:58
msgid "Search groups"
msgstr "Søg i grupper"

#: src/Pages/Domains/CardView/components/CardViewGroupsAndDomains.tsx:134
#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/NextGroupsAndDomains.tsx:73
msgid "Add new domain"
msgstr "Tilføj domæne"

#: src/Pages/Domains/CardView/components/CardViewGroupsAndDomains.tsx:136
#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/NextGroupsAndDomains.tsx:75
msgid "Search for any domain"
msgstr "Søg efter et domæne"

#: src/Pages/Domains/CardView/components/CardViewGroupsAndDomains.tsx:137
msgid "Search and select domains"
msgstr "Søg og vælg domæner"

#: src/Pages/Domains/CardView/components/DomainCard/components/CardFooter.tsx:22
#: src/Pages/Domains/support/useCheckboxSelection.tsx:21
msgid "Max number of selected domains reached."
msgstr "Du har nået det maksimale antal valgte domæner."

#: src/Pages/Domains/CardView/components/DomainCard/components/CardFooter.tsx:33
#: src/Pages/Domains/Toolbar/index.tsx:53
#: src/Pages/Keywords/Table/Actions/BulkActions.tsx:213
msgid "Compare"
msgstr "Sammenlign"

#: src/Pages/Domains/CardView/components/DomainCard/components/DomainCharts.tsx:81
#: src/Pages/Domains/CardView/support/getSorting.ts:59
#: src/Pages/KeywordDiscovery/components/KpiBar/index.tsx:60
#: src/Pages/Keywords/Competitors/components/CompetitorChartContent/support/helpers.ts:33
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:187
#: src/Pages/Keywords/Competitors/support/constants.ts:95
#: src/Pages/Keywords/LandingPage/components/KeywordLandingChart/support/helpers.ts:41
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:98
#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageCTR/AverageCtrChart.tsx:68
#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageCTR/AverageCtrChart.tsx:100
#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageCTR/helpers.ts:12
#: src/Pages/Keywords/Overview/components/KpiBar/Boxes/DynamicCtrKpiBox.tsx:25
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:66
msgid "Average CTR"
msgstr "Gennemsnitlig CTR"

#: src/Pages/Domains/CardView/components/DomainCard/components/DomainKpi.tsx:81
#: src/Pages/Domains/CardView/support/getSorting.ts:23
msgid "Tracked Keywords"
msgstr "Overvågede Søgeord"

#: src/Pages/Domains/CardView/components/DomainCard/components/DomainKpi.tsx:87
#: src/Pages/Domains/CardView/support/getSorting.ts:29
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:194
#: src/Pages/Domains/support/types.ts:86
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/hooks.tsx:147
msgid "Discovered Keywords"
msgstr "Opdagede søgeord"

#: src/Pages/Domains/CardView/components/DomainCard/components/DomainMenu.tsx:25
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:77
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useDomainActions.ts:23
msgid "Domain is being deleted. This might take a few seconds."
msgstr "Domænet bliver slettet. Det kan tage et par sekunder."

#: src/Pages/Domains/CardView/components/DomainCard/components/DomainMenu.tsx:39
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:87
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useDomainActions.ts:31
#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/support/hooks.ts:204
#: src/Pages/PausedDomains/index.tsx:152
msgid "Could not delete domain"
msgstr "Kunne ikke slette domæne"

#: src/Pages/Domains/CardView/components/DomainCard/components/DomainMenu.tsx:81
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:116
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useDomainActions.ts:74
#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/support/hooks.ts:144
#: src/Pages/PausedDomains/index.tsx:175
msgid "Delete Domain?"
msgstr "Slet domæne?"

#: src/Pages/Domains/CardView/components/DomainCard/components/DomainMenu.tsx:83
#: src/Pages/Domains/CardView/components/DomainCard/components/DomainMenu.tsx:107
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:118
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:366
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/DomainActions.tsx:74
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useDomainActions.ts:76
#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/support/hooks.ts:150
#: src/Pages/PausedDomains/index.tsx:177
msgid "Delete domain"
msgstr "Slet domæne"

#: src/Pages/Domains/CardView/components/DomainCard/components/DomainMenu.tsx:90
#: src/Pages/Keywords/Overview/components/KebabMenu/index.tsx:76
#: src/Pages/Keywords/Overview/components/KpiBar/components/KpiBoxDropdown.tsx:12
msgid "More options"
msgstr "Flere indstillinger"

#: src/Pages/Domains/CardView/components/DomainCard/components/DomainMenu.tsx:104
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:354
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/DomainActions.tsx:68
#: src/Pages/DomainSettings/components/Menu/general.tsx:56
#: src/Pages/Layout/ActionsMenu/Actions/index.tsx:118
msgid "Edit domain"
msgstr "Rediger domæne"

#: src/Pages/Domains/CardView/components/DomainCard/KeywordsDistribution.tsx:17
msgid "Keywords distribution for desktop device."
msgstr "Procentvis af søgeord på desktop."

#: src/Pages/Domains/CardView/components/DomainCard/KeywordsDistribution.tsx:23
msgid "Keywords distribution for mobile device."
msgstr "Procentvis af søgeord på mobil."

#: src/Pages/Domains/CardView/components/NoDomains.tsx:29
msgid "You can add new domain by clicking the \"Add domain\" button."
msgstr ""
"Du kan tilføje et nyt domæne ved at klikke på knappen \"Tilføj domæne\"."

#: src/Pages/Domains/CardView/components/NoGroups.tsx:11
msgid "You can add new group by clicking \"Add group\" button."
msgstr ""
"Du kan tilføje en ny gruppe ved at klikke på knappen \"Tilføj gruppe\"."

#: src/Pages/Domains/CardView/support/getSorting.ts:17
#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:140
#: src/Pages/PausedDomains/index.tsx:208
msgid "Domain Name"
msgstr "Domænenavn"

#: src/Pages/Domains/DomainsActionBar.tsx:118 src/Pages/Groups/index.tsx:49
msgid "Add group"
msgstr "Tilføj gruppe"

#: src/Pages/Domains/DomainsActionBar.tsx:124
msgid "Connect all domains to Analytics"
msgstr "Forbind alle domæner til Analytics"

#: src/Pages/Domains/DomainsActionBar.tsx:129
msgid "Connect all domains to Search Console"
msgstr "Forbind alle domæner til Search Console"

#: src/Pages/Domains/DomainsActionBar.tsx:145
#: src/Pages/KeywordDiscovery/components/Actionbar/index.tsx:29
#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingViewModeActions.tsx:146
#: src/Pages/Layout/ActionsMenu/Actions/index.tsx:158
msgid "Configure columns"
msgstr "Konfigurer kolonner"

#: src/Pages/Domains/DomainsTable/cells/KeywordsCell.tsx:32
#: src/Pages/Keywords/Overview/components/OverviewActionBar.tsx:124
#: src/Pages/Keywords/Table/Actions/ActionMenuList.tsx:49
#: src/Pages/Keywords/Table/KeywordsTable.tsx:63
msgid "Add keywords"
msgstr "Tilføj søgeord"

#: src/Pages/Domains/DomainsTable/components/GroupStats.tsx:84
#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/SearchIntent.tsx:145
#: src/Pages/Sales/Organization/index.tsx:106
msgid "keywords"
msgstr "søgeord"

#: src/Pages/Domains/DomainsTable/index.tsx:57
msgid "No domains"
msgstr "Ingen domæner"

#: src/Pages/Domains/DomainsTable/index.tsx:58
msgid "There is currently no domains added to your account."
msgstr "Der er ingen domæner på din konto."

#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:155
msgid "Add filter for Domain"
msgstr "Tilføj filter for domæne"

#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:173
msgid "Add filter for Group"
msgstr "Tilføj filter for gruppe"

#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:233
msgid "Change in Rank"
msgstr "Ændring i placering"

#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:261
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:272
msgid "Change in SoV between the two compared dates"
msgstr "Ændring i synlighed mellem de to sammenlignede datoer"

#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:267
msgid "Change in SoV"
msgstr "Ændring i synlighed"

#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:295
msgid "Change in AI SoV between the two compared dates"
msgstr "Ændring i AI synlighed mellem de to sammenlignede datoer"

#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:301
msgid "Change in AI SoV"
msgstr "Ændring i AI synlighed"

#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:310
#: src/Pages/Domains/support/types.ts:142
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:83
#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistribution/RankingDistributionChartContent.tsx:59
#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/RankingDistributionNeighborlyChartContent.tsx:59
#: src/Pages/PublicReport/support/useSegmentedControlItems.tsx:26
msgid "Ranking Distribution"
msgstr "Placeringsfordeling"

#: src/Pages/Domains/DomainsTable/support/useDomainsTableInfo.tsx:362
msgid "Can't edit a demo domain"
msgstr "Du kan ikke redigere et demo domæne"

#: src/Pages/Domains/PauseSwitch.tsx:32
msgid "Paused Domains"
msgstr "Pausede domæner"

#: src/Pages/Domains/Toolbar/index.tsx:19
msgid "domain selected"
msgstr "domæne valgt"

#: src/Pages/Domains/Toolbar/index.tsx:20
msgid "domains selected"
msgstr "domæner valgt"

#: src/Pages/Domains/TreeView/Components/Cells/CountryCell.tsx:25
msgid "Show all countries"
msgstr "Vis alle lande"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/DomainActions.tsx:62
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/FolderActions.tsx:61
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/FolderActions.tsx:30
msgid "Create folder"
msgstr "Opret mappe"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/FolderActions.tsx:39
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/index.tsx:143
msgid "Go to dashboard and filter by folder"
msgstr "Gå til dashboard og filtrer efter mappe"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/FolderActions.tsx:49
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/KeywordsCell.tsx:54
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/index.tsx:154
msgid "Go to keywords list and filter by folder"
msgstr "Gå til søgeordslisten og filtrer efter mappe"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/FolderActions.tsx:55
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/FolderActions.tsx:25
#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingsEmptyContent.tsx:39
#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingViewModeActions.tsx:79
msgid "Create tag"
msgstr "Opret tag"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/GroupActions.tsx:65
msgid "Create domain"
msgstr "Opret domæne"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/GroupActions.tsx:71
msgid "Edit group"
msgstr "Rediger gruppe"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/GroupActions.tsx:77
#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useGroupActions.ts:67
#: src/Pages/DomainSettings/components/DynamicTagsBuilder/components/BuilderTokenGroup/components/TokenGroupActions.tsx:40
#: src/Pages/Groups/GroupsTable/support/useGroupHandlers.ts:95
#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/support/hooks.ts:150
msgid "Delete group"
msgstr "Slet gruppe"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/SERPAction.tsx:17
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/index.tsx:158
msgid "Show SERP features"
msgstr "Vis SERP-elementer"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/TagActions.tsx:48
msgid "Go to dashboard and filter by tag"
msgstr "Gå til dashboard og filtrer efter tag"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/TagActions.tsx:56
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/KeywordsCell.tsx:56
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/index.tsx:155
msgid "Go to keywords list and filter by tag"
msgstr "Gå til søgeordslisten og filtrer efter tag"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/TagActions.tsx:62
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/TagActions.tsx:32
#: src/Pages/SiteMapping/components/TagsSection.tsx:81
msgid "Edit dynamic tag"
msgstr "Rediger dynamisk tag"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/TagActions.tsx:62
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/TagActions.tsx:32
#: src/Pages/SiteMapping/components/TagsSection.tsx:81
msgid "Edit static tag"
msgstr "Rediger statisk tag"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/TagActions.tsx:68
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/TagActions.tsx:38
msgid "Clone dynamic tag"
msgstr "Duplikér dynamisk tag"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/TagActions.tsx:74
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/TagActions.tsx:44
msgid "Delete dynamic tag"
msgstr "Slet dynamisk tag"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/TagActions.tsx:74
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/TagActions.tsx:44
msgid "Delete static tag"
msgstr "Slet statisk tag"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useFolderActions.ts:36
msgid "Redirect to \"Tag Cloud\" page?"
msgstr "Omdiriger til \"Tag Cloud\"-siden?"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useFolderActions.ts:38
msgid ""
"You can only add folders in \"Tag Cloud\" page in edit mode. Do you want to "
"enter edit mode?"
msgstr ""
"Du kan kun tilføje mapper på \"Tag Cloud\"-siden i redigeringstilstand. Vil "
"du gå til redigeringstilstand?"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useGroupActions.ts:21
#: src/Pages/Groups/GroupsTable/support/useGroupHandlers.ts:32
#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/support/hooks.ts:172
msgid "Could not delete group"
msgstr "Kan ikke slette gruppen"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useGroupActions.ts:23
#: src/Pages/Groups/GroupsTable/support/useGroupHandlers.ts:34
#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/support/hooks.ts:176
msgid "Group deleted"
msgstr "Gruppe slettet"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useGroupActions.ts:65
#: src/Pages/Groups/GroupsTable/support/useGroupHandlers.ts:93
#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/support/hooks.ts:144
msgid "Delete Group?"
msgstr "Slet gruppe?"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useTagActions.ts:70
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/usePathCellActions.ts:74
msgid "(copy)"
msgstr "(kopi)"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useTagActions.ts:85
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/usePathCellActions.ts:87
msgid "Delete dynamic tag?"
msgstr "Slet dynamisk tag?"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useTagActions.ts:87
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/usePathCellActions.ts:89
msgid ""
"The dynamic tag \"[tag]\" will be permanently deleted and be removed from "
"all matching keywords."
msgstr ""
"Det dynamiske tag \"[tag]\" vil blive slettet permanent og fjernet fra alle "
"matchende søgeord."

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useTagActions.ts:104
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/usePathCellActions.ts:106
msgid "Dynamic tag \"%s\" was deleted!"
msgstr "Det dynamiske tag er slettet!"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useTagActions.ts:117
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/usePathCellActions.ts:118
msgid "Delete static tag?"
msgstr "Slet statisk tag?"

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useTagActions.ts:119
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/usePathCellActions.ts:120
msgid ""
"The static tag \"[tag]\" will be permanently deleted and be removed from all "
"keywords."
msgstr ""
"Det statiske tag \"[tag]\" vil blive slettet permanent og fjernet fra alle "
"søgeord."

#: src/Pages/Domains/TreeView/Components/Cells/PathCell/useTagActions.ts:131
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/usePathCellActions.ts:132
msgid "Static tag \"%s\" was deleted!"
msgstr "Det statiske tag er slettet!"

#: src/Pages/Domains/TreeView/Components/TreeViewContent.tsx:18
msgid "No Groups or Domains Found"
msgstr "Ingen Grupper eller Domæner Fundet"

#: src/Pages/Domains/TreeView/Components/TreeViewContent.tsx:21
msgid "We found no groups, domains, or tags to show for the specified search."
msgstr ""
"Vi fandt ingen grupper, domæner eller tags at vise for den angivne søgning."

#: src/Pages/Domains/TreeView/Components/TreeViewContent.tsx:22
msgid "We found no groups or domains to show for this domain."
msgstr "Vi fandt ingen grupper eller domæner at vise for dette domæne."

#: src/Pages/Domains/TreeView/Components/TreeViewContent.tsx:25
msgid "No groups, domains, or tags found"
msgstr "Ingen grupper, domæner eller tags fundet"

#: src/Pages/Domains/TreeView/Components/TreeViewContent.tsx:26
msgid ""
"We found no groups, domains, or tags. This could be due to your active "
"filters. Clear your filters and try again."
msgstr ""
"Vi fandt ingen grupper, domæner eller tags. Dette kan skyldes dine aktive "
"filtre. Nulstil dine filtre og prøv igen."

#: src/Pages/Domains/TreeView/Components/TreeViewContent.tsx:30
#: src/Pages/Keywords/Groupings/EditMode/components/EditModeEmptyContent.tsx:36
#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingsEmptyContent.tsx:39
#: src/Pages/SiteMapping/components/TreeTable/index.tsx:170
msgid "Clear Search"
msgstr "Ryd søgning"

#: src/Pages/Domains/TreeView/index.tsx:66
msgid "Loading Groups and Domains…"
msgstr "Indlæser Grupper og Domæner…"

#: src/Pages/Domains/TreeView/support/constants.ts:60
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:202
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:203
#: src/Pages/KeywordOverusePage/components/OveruseChart/support/helpers.ts:138
#: src/Pages/Keywords/Groupings/support/constants.ts:75
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:226
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:227
#: src/Pages/SiteMapping/components/Right.tsx:227
msgid "Discovered keywords"
msgstr "Opdagede søgeord"

#: src/Pages/Domains/TreeView/support/constants.ts:87
#: src/Pages/Keywords/Groupings/support/constants.ts:107
msgid "Avg. Base Rank"
msgstr "Gns. Rå Placering"

#: src/Pages/Domains/TreeView/support/useTreeStructure.tsx:122
msgid "Woops - something went wrong when trying to unfold this domain!"
msgstr "Hov - noget gik galt ved forsøg på at udfolde dette domæne!"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:172
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:192
msgid "Distribution of devices"
msgstr "Fordeling af enheder"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:173
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:193
msgid "Share of mobile searches"
msgstr "Andel af mobile søgninger"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:191
#: src/Pages/Groups/GroupsTable/support/useGroupsTableConfig.tsx:88
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:215
msgid "Number of Keywords"
msgstr "Antal søgeord"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:200
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:224
msgid "Disc. keywords"
msgstr "Opd. søgeord"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:244
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:280
msgid "Show history for AI Share of Voice"
msgstr "Vis historik for AI Synlighed"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:308
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:323
msgid "Show history for Share of Voice"
msgstr "Vis historik for Synlighed"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:353
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:366
msgid "Show history for average rank"
msgstr "Vis historik for gennemsnitsplacering"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:372
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:373
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:385
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:386
#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageBaseRank/AverageBaseRankChart.tsx:107
#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageBaseRank/helpers.tsx:11
msgid "Average base rank"
msgstr "Rå Gennemsnitsplacering"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:400
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:412
msgid "Show history for average base rank"
msgstr "Vis historik for gennemsnitsplacering"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:453
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:448
msgid "Show history for search volume"
msgstr "Vis historik for søgevolume"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:473
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:462
#: src/Pages/SiteMapping/components/CompetitorTable.tsx:76
msgid "AI Traf. val"
msgstr "AI Traf. vær"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:505
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:490
msgid "Show history for AI Traffic value"
msgstr "Vis historik for AI trafikværdi"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:520
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:505
msgid "Traf. val"
msgstr "Traf. vær"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:562
#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:563
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:546
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:547
msgid "Average Click-through Rate"
msgstr "Gennemsnitlig Click-through Rate"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:605
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:572
msgid "Show history for average CTR"
msgstr "Vis placeringshistorik for CTR"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:621
#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:162
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:587
msgid "Avg. Max CTR"
msgstr "Gns. Max CTR"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:623
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:589
msgid "Average Max Click-through Rate"
msgstr "Gennemsnitlig maks. Click-through Rate"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:624
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:590
msgid "Average Max CTR"
msgstr "Gennemsnitlig maks. CTR"

#: src/Pages/Domains/TreeView/useTreeViewTableConfig.tsx:671
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:677
msgid "Show history for Above the fold"
msgstr "Vis historik for Over Folden"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/components/BuilderToken/BuilderToken.tsx:39
msgid "Select Field"
msgstr "Vælg felt"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/components/BuilderToken/BuilderToken.tsx:52
msgid "Operation"
msgstr "Operation"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/components/BuilderToken/components/FieldDynamicInput.tsx:28
msgid "Enter value"
msgstr "Indtast værdi"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/components/BuilderToken/components/FieldDynamicInput.tsx:48
msgid "From"
msgstr "Fra"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/components/BuilderToken/components/FieldDynamicInput.tsx:51
msgid "To"
msgstr "Til"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/components/BuilderTokenGroup/BuilderTokenGroup.tsx:70
msgid "Rules"
msgstr "Regler *"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/components/BuilderTokenGroup/BuilderTokenGroup.tsx:84
msgid "OR"
msgstr "ELLER"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/components/BuilderTokenGroup/BuilderTokenGroup.tsx:84
msgid "AND"
msgstr "OG"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/components/BuilderTokenGroup/components/TokenGroupActions.tsx:23
msgid "Add rule"
msgstr "Tilføj regel"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/components/BuilderTokenGroup/components/TokenGroupActions.tsx:27
msgid "Add rule group"
msgstr "Tilføj regelgruppe"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/components/DynamicTagsRulesCount.tsx:55
msgid "Tracked keywords: "
msgstr "Overvågede søgeord: "

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/components/DynamicTagsRulesCount.tsx:64
msgid "Discovered keywords: "
msgstr "Opdagede søgeord: "

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/DynamicTagsBuilder.tsx:37
msgid "Dynamic tag already exists."
msgstr "Det dynamiske tag findes allerede."

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/DynamicTagsBuilder.tsx:57
msgid "Tag Name"
msgstr "Tag Navn"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/DynamicTagsBuilder.tsx:58
msgid "Tag name"
msgstr "Tag Navn"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/DynamicTagsBuilder.tsx:89
msgid "Tag created by: %s"
msgstr "Tag oprettet af: %s"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/DynamicTagsBuilder.tsx:92
msgid "Last updated at: %s"
msgstr "Sidst opdateret: %s"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:29
msgid "Landing page url"
msgstr "Landingsside URL"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:34
msgid "Landing page title tag"
msgstr "Landingsside titel"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:55
msgid "Contains keyword"
msgstr "Indeholder søgeord"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:62
msgid "Not equals !="
msgstr "Ikke ens !="

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:68
msgid "Any"
msgstr "Hvilken som helst"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:69
#: src/Pages/Import/GSC/index.tsx:118
#: src/Pages/Keywords/Overview/components/SearchTypeSwitch/index.tsx:83
#: src/Pages/Keywords/SERP/ChartHistory/SerpOwnedSegmentedControl.tsx:33
#: src/Pages/Sales/Customers/CustomerStatusDropdown.tsx:12
#: src/Pages/Sales/Invoice/Customers/index.tsx:71
#: src/Pages/Sales/Trials/index.tsx:56
msgid "All"
msgstr "Alle"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:70
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:106
msgid "None"
msgstr "Ingen"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/constants.ts:71
msgid "Empty"
msgstr "Tom"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/helpers.tsx:36
msgid "Provide both from and to"
msgstr "Indtast både til og fra"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/helpers.tsx:38
msgid "From should be less then To"
msgstr "Fra skal være mindre end til"

#: src/Pages/DomainSettings/components/DynamicTagsBuilder/support/helpers.tsx:77
msgid "Field is required."
msgstr "Dette felt skal udfyldes."

#: src/Pages/DomainSettings/components/Menu/BigQueryBackfill.tsx:26
msgid "Backfilling in progress"
msgstr "Kopiering i gang"

#: src/Pages/DomainSettings/components/Menu/BigQueryBackfill.tsx:27
msgid "Data is not backfilled"
msgstr "Historisk data er ikke kopieret"

#: src/Pages/DomainSettings/components/Menu/connectToAnalytics.tsx:14
msgid "Connected to Adobe Analytics"
msgstr "Forbundet til Adobe Analytics"

#: src/Pages/DomainSettings/components/Menu/connectToAnalytics.tsx:15
msgid "Connected to Google Analytics"
msgstr "Forbundet til Google Analytics"

#: src/Pages/DomainSettings/components/Menu/connectToAnalytics.tsx:16
msgid "This domain is not connected to an analytics service"
msgstr "Dette domæne er ikke forbundet til en analytics service"

#: src/Pages/DomainSettings/components/Menu/connectToAnalytics.tsx:41
#: src/Pages/DomainSettings/components/Menu/connectToGSC.tsx:20
msgid "Google OAuth Connection"
msgstr "Google OAuth forbindelse"

#: src/Pages/DomainSettings/components/Menu/connectToAnalytics.tsx:44
msgid "Adobe Marketing Connection"
msgstr "Adobe Marketing forbindelse"

#: src/Pages/DomainSettings/components/Menu/connectToAnalytics.tsx:49
#: src/Pages/DomainSettings/components/Menu/index.tsx:52
msgid "Analytics"
msgstr "Analytics"

#: src/Pages/DomainSettings/components/Menu/connectToBigQuery.tsx:27
msgid "Backfill date"
msgstr "Dato for kopiering"

#: src/Pages/DomainSettings/components/Menu/connectToBigQuery.tsx:44
#: src/Pages/DomainSettings/components/Menu/connectToBigQuery.tsx:53
#: src/Pages/Integrations/hooks/useIntegrations.tsx:391
#: src/Pages/Keywords/Overview/components/OverviewActionBar.tsx:156
msgid "Google BigQuery"
msgstr "Google BigQuery"

#: src/Pages/DomainSettings/components/Menu/connectToBigQuery.tsx:59
msgid "Connected to BigQuery"
msgstr "Forbundet til BigQuery"

#: src/Pages/DomainSettings/components/Menu/connectToBigQuery.tsx:59
msgid "This domain is not connected to BigQuery"
msgstr "Dette domæne er ikke forbundet til BigQuery"

#: src/Pages/DomainSettings/components/Menu/connectToGSC.tsx:10
msgid "Connected to Google Search Console"
msgstr "Forbundet til Google Search Console"

#: src/Pages/DomainSettings/components/Menu/connectToGSC.tsx:11
msgid "This domain is not connected to Google Search Console"
msgstr "Dette domæne er ikke forbundet til Google Search Console"

#: src/Pages/DomainSettings/components/Menu/connectToGSC.tsx:24
msgid "Website"
msgstr "Hjemmeside"

#: src/Pages/DomainSettings/components/Menu/connectToGSC.tsx:37
#: src/Pages/DomainSettings/components/Menu/index.tsx:61
msgid "Search Console"
msgstr "Search Console"

#: src/Pages/DomainSettings/components/Menu/DisplaySettings/AverageRankSettings/index.tsx:87
#: src/Pages/DomainSettings/components/Menu/DisplaySettings/AverageRankSettings/utils.tsx:26
msgid "Controlled by account settings"
msgstr "Styres af kontoindstillinger"

#: src/Pages/DomainSettings/components/Menu/DisplaySettings/AverageRankSettings/index.tsx:107
#: src/Pages/DomainSettings/components/Menu/DisplaySettings/SearchSettings/index.tsx:68
msgid "Use account settings"
msgstr "Brug kontoindstillinger"

#: src/Pages/DomainSettings/components/Menu/DisplaySettings/AverageRankSettings/utils.tsx:27
msgid ""
"\"Not in top 100\" rank keywords are not included in average rank "
"calculation because of the above setting and the account setting"
msgstr ""
"\"Ikke i top 100\"-placeringssøgeord medregnes ikke i den gennemsnitlige "
"placeringsberegning pga. indstillingen ovenfor samt kontoindstillingen"

#: src/Pages/DomainSettings/components/Menu/DisplaySettings/AverageRankSettings/utils.tsx:30
msgid ""
"\"Not in top 100\" rank keywords are not included in average rank "
"calculation because of the above setting"
msgstr ""
"\"Ikke i top 100\"-placeringssøgeord medregnes ikke i den gennemsnitlige "
"placeringsberegning pga. indstillingen ovenfor"

#: src/Pages/DomainSettings/components/Menu/DisplaySettings/AverageRankSettings/utils.tsx:71
msgid ""
"This settings controls if \"Not in top 100\" rank keywords are included in "
"the average rank calculation. You can set it to \"Yes\" or \"No\" to "
"override the account setting for this domain."
msgstr ""
"Denne indstilling styrer, om \"Ikke i top 100\"-placeringssøgeord inkluderes "
"i gennemsnitlig placeringsberegning. Du kan sætte den til \"Ja\" eller \"Nej"
"\" for at overskrive kontoindstillingen for dette domæne."

#: src/Pages/DomainSettings/components/Menu/DisplaySettings/AverageRankSettings/utils.tsx:81
msgid ""
"This setting controls how much \"Not in top 100\" rank keywords will affect "
"the average rank calculation. If you set the value higher, these keywords "
"will have more impact on the average rank."
msgstr ""
"Denne indstilling styrer, hvor meget \"Ikke i top 100\"-placeringssøgeord "
"vil påvirke beregningen af den gennemsnitlige placering. Hvis du sætter "
"vægten højere, vil disse søgeord have større indflydelse på den "
"gennemsnitlige placering."

#: src/Pages/DomainSettings/components/Menu/DisplaySettings/index.tsx:106
msgid "Display settings for domain updated successfully."
msgstr "Opdatering af domænets visningsindstillinger lykkedes."

#: src/Pages/DomainSettings/components/Menu/DisplaySettings/index.tsx:118
#: src/Pages/DomainSettings/components/Menu/index.tsx:78
msgid "Display Settings"
msgstr "Visningsindstillinger"

#: src/Pages/DomainSettings/components/Menu/DisplaySettings/SearchSettings/index.tsx:53
msgid "Use local search volume"
msgstr "Brug lokal søgevolumen"

#: src/Pages/DomainSettings/components/Menu/general.tsx:15
msgid "Domain name or YouTube channel url"
msgstr "Domænenavn eller YouTube-kanal URL"

#: src/Pages/DomainSettings/components/Menu/general.tsx:42
msgid "General Settings"
msgstr "Generelle indstillinger"

#: src/Pages/DomainSettings/components/Menu/index.tsx:70
msgid "BigQuery"
msgstr "BigQuery"

#: src/Pages/DomainSettings/support/helpers.tsx:41
msgid ""
"Select your custom currency that fits your market. This will reflect on your "
"CPC & traffic values on this domain."
msgstr ""
"Vælg din tilpassede valuta, som passer til dit marked. Dette vil afspejles "
"på dine CPC & traffikværdier på dette domæne."

#: src/Pages/DomainSettings/support/helpers.tsx:46
msgid ""
"If set to '[accountDefault]', this domain will follow your account's CPC "
"currency setting."
msgstr ""
"Hvis sat til '[accountDefault]', vil dette domæne følge din konto CPC valuta "
"indstilling."

#: src/Pages/DomainSettings/support/helpers.tsx:78
msgid ""
"Connect to Google's BigQuery to stream your AccuRanker data into Google's "
"fully managed data warehouse. Use your AccuRanker data along with your other "
"data streams within BigQuery for scalable and efficient data analysis. Read "
"more about setting up a BigQuery connection with AccuRanker"
msgstr ""
"Opret forbindelse til Googles BigQuery for at streame dine AccuRanker-data "
"ind i Googles fuldt administrerede datalager. Brug dine AccuRanker-data "
"sammen med dine andre datastreams i BigQuery til skalerbar og effektiv "
"dataanalyse. Læs mere om opsætning af en BigQuery-forbindelse med AccuRanker"

#: src/Pages/ErrorPages/404/index.tsx:25
msgid ""
"Unfortunately, the requested page could not be found. Please, try another "
"URL, or [goTo]."
msgstr ""
"Den forespurgte side findes desværre ikke. Prøv venligst en anden adresse "
"eller [goTo]."

#: src/Pages/ErrorPages/404/index.tsx:27
msgid "return to dashboard"
msgstr "Retur til overblik"

#: src/Pages/ErrorPages/404/index.tsx:27
msgid "go to login"
msgstr "Gå til log ind"

#: src/Pages/ErrorPages/404/index.tsx:31
#: src/Pages/PublicReport/ErrorFallback.tsx:62
msgid "Go to login"
msgstr "Gå til log ind"

#: src/Pages/ErrorPages/500/index.tsx:42
msgid "Internal Server Error"
msgstr "Intern serverfejl"

#: src/Pages/ErrorPages/500/index.tsx:44
msgid ""
"Unfortunately, the requested page could not be reached. Please, try again "
"later, or use the main menu to navigate to a different page."
msgstr ""
"Der skete desværre en fejl under indlæsningen af den forespurgte side. Prøv "
"venligst igen senere eller gå til forsiden og gå til en anden side."

#: src/Pages/ErrorPages/500/index.tsx:50
msgid "contact our support"
msgstr "Kontakt vores support"

#: src/Pages/ErrorPages/500/index.tsx:79
msgid "Reload app"
msgstr "Genindlæs app"

#: src/Pages/ErrorPages/AccessDenied/index.tsx:18
msgid ""
"It appears you don’t have permission to access this page. Please contact "
"your organization administrator to get permission."
msgstr ""
"Det ser ud til, at du ikke har tilladelse til at få adgang til denne side. "
"Kontakt din organisationsadministrator for at få tilladelse."

#: src/Pages/ErrorPages/expiredPageComponent/components/CoreFeatureBullets.tsx:22
msgid "Performance"
msgstr "Ydeevne"

#: src/Pages/ErrorPages/expiredPageComponent/components/CoreFeatureBullets.tsx:23
msgid ""
"Experience the same fast response whether you are tracking 5.000 or 500.000 "
"keywords."
msgstr ""
"Oplev den samme hurtige respons, uanset om du sporer 5.000 eller 500.000 "
"søgeord."

#: src/Pages/ErrorPages/expiredPageComponent/components/CoreFeatureBullets.tsx:29
msgid "Real-time data"
msgstr "Data i realtid"

#: src/Pages/ErrorPages/expiredPageComponent/components/CoreFeatureBullets.tsx:30
msgid ""
"All data is updated every 24 hours, with the possibility to do on-demand "
"updates on entire domains or single keywords at no extra cost."
msgstr ""
"Alle data opdateres hver 24. time med mulighed for at lave on-demand-"
"opdateringer på hele domæner eller enkelte søgeord uden ekstra omkostninger."

#: src/Pages/ErrorPages/expiredPageComponent/components/CoreFeatureBullets.tsx:36
msgid "AI CTR"
msgstr "AI CTR"

#: src/Pages/ErrorPages/expiredPageComponent/components/CoreFeatureBullets.tsx:37
msgid ""
"Gives you insight into any given keyword, the actual CTR, and the maximum "
"potential CTR that can be achieved."
msgstr ""
"Giver dig indsigt i et givent søgeord, den faktiske CTR og den maksimale "
"potentielle CTR, der kan opnås."

#: src/Pages/ErrorPages/expiredPageComponent/components/CoreFeatureBullets.tsx:43
msgid "Segmentation"
msgstr "Segmentering"

#: src/Pages/ErrorPages/expiredPageComponent/components/CoreFeatureBullets.tsx:44
msgid ""
"Slice & dice your data on more than 30 parameters. Save them as segments. "
"Available in the API as well."
msgstr ""
"Skær dine data i skiver og terninger på mere end 30 parametre. Gem dem som "
"segmenter. Også tilgængelig i API'en."

#: src/Pages/ErrorPages/expiredPageComponent/components/CoreFeatureBullets.tsx:50
#: src/Pages/SelectPlan/plan-features.tsx:60
msgid "Dynamic competitors"
msgstr "Dynamiske konkurrenter"

#: src/Pages/ErrorPages/expiredPageComponent/components/CoreFeatureBullets.tsx:51
msgid ""
"Know exactly who is stealing your organic traffic, it might not be who you "
"think it is."
msgstr ""
"Find ud af, hvem der stjæler din organiske trafik, for det er ikke sikkert, "
"at det er dem, du tror."

#: src/Pages/ErrorPages/expiredPageComponent/components/CoreFeatureBullets.tsx:57
msgid "Keyword research"
msgstr "Søgeordsanalyse"

#: src/Pages/ErrorPages/expiredPageComponent/components/CoreFeatureBullets.tsx:58
msgid ""
"24B+ keywords global database gives you instant insight into which keywords "
"you and your competitors are ranking for."
msgstr ""
"Global database med 24B+ søgeord giver dig øjeblikkelig indsigt i, hvilke "
"søgeord du og dine konkurrenter rangerer på."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:36
msgid "We were unable to retry your payment. Please contact support."
msgstr "Vi kunne ikke forsøge at hæve penge igen. Kontakt venligst supporten."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:51
#: src/Pages/ErrorPages/PlanExpired/index.tsx:61
msgid "Your payment was successful."
msgstr "Din betaling blev gennemført."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:89
msgid "Payment Failed - Read For Info"
msgstr "Betalingen fejlede - Læs for info"

#: src/Pages/ErrorPages/FailedPayment/index.tsx:92
msgid "Your payment failed with error: [strong]"
msgstr "Din betaling fejlede med fejlen: [strong]"

#: src/Pages/ErrorPages/FailedPayment/index.tsx:97
msgid "Why did this happen:"
msgstr "Hvorfor skete det:"

#: src/Pages/ErrorPages/FailedPayment/index.tsx:101
msgid "How can you fix this:"
msgstr "Hvordan kan du løse det:"

#: src/Pages/ErrorPages/FailedPayment/index.tsx:108
msgid ""
"This error in unrecoverable, which means that you WILL NEED to [link:update "
"your payment method.]"
msgstr ""
"Denne fejl er uoprettelig, hvilket betyder at du SKAL [link:opdatere din "
"betalingsmetode.]"

#: src/Pages/ErrorPages/FailedPayment/index.tsx:119
msgid ""
"This error might resolve itself, we do however recommend that you [link:"
"update your payment method] or contact your bank to get the issue resolved "
"right away."
msgstr ""
"Denne fejl løser måske sig selv, men vi anbefaler at du [link:opdaterer din "
"betalingsmetode] eller kontakter din bank for at få problemet løst med det "
"samme."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:144
msgid ""
"Your bank declined the transaction without returning a proper reason. For "
"more info you will need to contact your bank."
msgstr ""
"Din bank nægtede transaktionen uden at returnere en ordentlig grund. For "
"mere information skal du kontakte din bank."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:151
msgid ""
"The account did not have sufficient funds to cover the transaction amount at "
"the time of the transaction."
msgstr ""
"Kontoen havde ikke tilstrækkelige midler til at dække transaktionsbeløbet på "
"transaktionstidspunktet."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:160
msgid "The attempted transaction exceeds the activity limit of the account."
msgstr "Transaktionen overstiger aktivitetsgrænsen på kontoen."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:165
msgid "Your credit card has expired."
msgstr "Dit kreditkort er udløbet."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:170
msgid "Your credit card number is not on file with the card-issuing bank."
msgstr "Dit kreditkort nummer kan ikke genkendes hos banken."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:175
msgid ""
"Your bank is declining the transaction for unspecified reason, possibly due "
"to an issue with the card itself."
msgstr ""
"Din bank afviser transaktionen af ukendte årsager, muligvis pga. et problem "
"med kortet."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:184
msgid "Your bank is unwilling to accept the transaction."
msgstr "Din bank ønsker ikke at acceptere transaktionen."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:189
msgid "Your credit card has been reported lost or stolen."
msgstr "Dit kreditkort er meldt tabt eller stjålet."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:194
msgid "There is a restriction on your credit card."
msgstr "Der er en restriktion på dit kredit kort."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:199
msgid ""
"The pre-approved payment authorization that you have previously agreed to "
"between AccuRanker and PayPal has been revoked."
msgstr ""
"Den forhåndsgodkendte betalingsautorisation, som du tidligere har aftalt "
"mellem AccuRanker og PayPal, er blevet tilbagekaldt."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:206
msgid "The payment method associated with your PayPal account was declined."
msgstr "Betalingsmetoden på din PayPal konto blev afvist."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:211
msgid "Your PayPal account cannot be used for transactions at this time."
msgstr "Din PayPal konto kan ikke bruges til transaktioner i øjeblikket."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:216
msgid "PayPal did not accept the transaction."
msgstr "PayPal accepterede ikke transaktionen."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:221
msgid "3D Secure authentication not performed"
msgstr "3D Secure validering er ikke sket"

#: src/Pages/ErrorPages/FailedPayment/index.tsx:225
msgid "We are not sure."
msgstr "Vi er ikke sikre."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:238
msgid ""
"Entering the same credit card will very often result in a successful "
"transaction because the bank will re-approve the subscription. If that does "
"not work you must contact your bank or update your payment method."
msgstr ""
"Meget ofte hjælper det at indtaste det samme kreditkort igen, da banken så "
"gen-godkender abonnementet. Hvis det ikke virker er du nødt til at kontakte "
"din bank eller opdatere din betalingsmetode."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:245
msgid ""
"Please check your bank account to make sure the amount is available or "
"update your payment method."
msgstr ""
"Tjek venligst din bankkonto for at sikre, at beløbet er tilgængeligt eller "
"opdater din betalingsmetode."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:254
msgid ""
"You can either contact your bank to get the limit raised or use a different "
"payment method."
msgstr ""
"Du kan enten kontakte din bank for at få grænsen hævet eller bruge en anden "
"betalingsmetode."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:261
msgid "You must update your payment method."
msgstr "Du skal opdatere din betalingsmetode."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:266
#: src/Pages/ErrorPages/FailedPayment/index.tsx:271
msgid "You must contact your bank or use a different payment method."
msgstr "Du er nødt til at kontakte din bank eller skifte betalingsmetode."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:278
msgid ""
"You will need to contact your bank for more info. If you are using PayPal, "
"you must contact them instead."
msgstr ""
"Du skal kontakte din bank for mere info. Hvis du bruger PayPal er det dem du "
"skal kontakte."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:285
msgid "Change your payment method to a working credit card."
msgstr "Skift din betalingsmetode til at brugbart kreditkort."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:290
msgid "You must contact your bank to get the restriction lifted."
msgstr "Du skal kontakte din bank for at få restriktionen fjernet."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:295
msgid ""
"To re-approve you will need to update your payment method and add your "
"PayPal account again."
msgstr ""
"For at gen-godkende skal du opdatere din betalingsmetode og tilføje din "
"PayPal konto igen."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:302
msgid "You need to update your payment method in either PayPal or here."
msgstr "Du skal opdatere din betalingsmetode i enter PayPal eller her."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:307
msgid "Please contact PayPal or use a different payment method."
msgstr "Kontakt PayPal eller brug en anden betalingsmetode."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:312
msgid "You must contact PayPal for more information about why this happened."
msgstr "Du skal kontakte PayPal for mere information om hvorfor dette skete."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:317
msgid "You must add you credit card again to verify it with 3D Secure."
msgstr ""
"Du skal tilføje dit kreditkort igen for at verificere det med 3D Secure."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:321
msgid "Please contact support so we can work together to resolve this."
msgstr "Kontakt venligst support så vi sammen kan løse problemet."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:334
msgid "Performing payment, please wait…"
msgstr "Gennemfører betaling, vent venligst…"

#: src/Pages/ErrorPages/FailedPayment/index.tsx:347
msgid "We have tried collecting"
msgstr "Vi har forsøgt at hæve"

#: src/Pages/ErrorPages/FailedPayment/index.tsx:351
msgid "a total of %s times."
msgstr "i alt %s gange."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:355
msgid ""
"In order to continue with your plan please verify (and update) your [link:"
"payment method] and click \"Retry Payment\"."
msgstr ""
"For at fortsætte med dit abonnement skal du verificere (og opdatere) dine "
"[link:betalingsoplysninger] og derefter forsøge at gennemføre betalingen "
"igen."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:362
msgid "We will try again on %s."
msgstr "Vi prøver igen den %s."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:364
msgid ""
"Failure to update your payment details will result in your account being "
"suspended."
msgstr ""
"Hvis du ikke opdaterer dine betalingsoplysninger vil din konto blive "
"suspenderet."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:367
msgid ""
"If you have any questions or want to discuss your plan please contact us via "
"our support function."
msgstr ""
"Hvis du har nogen spørgsmål eller ønsker at snakke om dit næste abonnement "
"kan du kontakte os via vores supportfunktion."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:373
msgid ""
"You can update your payment details [paymentMethodLink:here] or billing info "
"[billingInfoLink:here]."
msgstr ""
"Du kan også opdatere dine betalingsdetaljer [paymentMethodLink:her] og "
"betalingsinfo [billingInfoLink:her]."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:387
msgid ""
"You can view the invoice for the failed payment [failedInvoiceUrl:here]."
msgstr "Du kan din faktura for den fejlede betaling [failedInvoiceUrl:her]."

#: src/Pages/ErrorPages/FailedPayment/index.tsx:411
msgid "Retry payment"
msgstr "Prøv betaling igen"

#: src/Pages/ErrorPages/FailedPayment/index.tsx:418
msgid "Update payment method"
msgstr "Opdater betalingsmetode"

#: src/Pages/ErrorPages/FailedPayment/index.tsx:434
msgid "Payment Failed - Avoid data loss"
msgstr "Betalingen fejlede - Undgå datatab"

#: src/Pages/ErrorPages/pageError/index.tsx:23
msgid "This page didn't load correctly, please reload the page and try again."
msgstr "Denne side blev ikke indlæst korrekt, venligst genindlæs og prøv igen."

#: src/Pages/ErrorPages/PlanExpired/index.tsx:50
msgid "We were unable to re-active your plan. Please contact support."
msgstr "Vi kunne ikke genaktivere dit abonnement. Kontakt venligst supporten."

#: src/Pages/ErrorPages/PlanExpired/index.tsx:77
msgid "Your %s plan has expired."
msgstr "Dit %s abonnement er udløbet."

#: src/Pages/ErrorPages/PlanExpired/index.tsx:83
msgid ""
"Your free plan has expired. You can choose a new plan using the options "
"below. "
msgstr ""
"Din gratis plan er udløbet. Du kan vælge en ny plan ved hjælp af "
"mulighederne nedenfor. "

#: src/Pages/ErrorPages/PlanExpired/index.tsx:84
msgid ""
"You can either re-active your plan at the current rate ([price] every "
"[months] months), or choose a new plan using the options below. "
msgstr ""
"Du kan enten genaktivere dit abonnement ([price] hver [months] måned), eller "
"vælge et nyt abonnement nedenunder. "

#: src/Pages/ErrorPages/PlanExpired/index.tsx:96
msgid ""
"You can also update your payment details [paymentMethodLink:here] or billing "
"info [billingInfoLink:here]."
msgstr ""
"Du kan også opdatere dine betalingsdetaljer [paymentMethodLink:her] og "
"betalingsinfo [billingInfoLink:her]."

#: src/Pages/ErrorPages/PlanExpired/index.tsx:109
msgid "Please contact an organization admin to reactivate your account."
msgstr "Kontakt en organisationsadministrator for at genaktivere din konto."

#: src/Pages/ErrorPages/PlanExpired/index.tsx:112
msgid ""
"If you have any questions or would like to discuss your plan, please contact "
"us through our [support] located in the lower left corner."
msgstr ""
"Hvis du har nogen spørgsmål eller ønsker at snakke om dit næste abonnement "
"kan du kontakte os via vores supportfunktion, som du finder i nederste "
"venstre hjørne."

#: src/Pages/ErrorPages/PlanExpired/index.tsx:114
msgid "support function"
msgstr "support-funktion"

#: src/Pages/ErrorPages/PlanExpired/index.tsx:126
msgid "Choose a new plan"
msgstr "Vælg et nyt abonnement"

#: src/Pages/ErrorPages/PlanExpired/index.tsx:135
msgid "Book a demo"
msgstr "Book en demo"

#: src/Pages/ErrorPages/PlanExpired/index.tsx:152
msgid "Show invoices"
msgstr "Vis fakturaer"

#: src/Pages/ErrorPages/PlanExpired/index.tsx:157
msgid "Re-activate plan"
msgstr "Genaktiver abonnement"

#: src/Pages/ErrorPages/PlanExpired/index.tsx:167
msgid "Your Current Plan Has Expired"
msgstr "Dit abonnement er udløbet"

#: src/Pages/ErrorPages/TrialExpired/index.tsx:12
msgid ""
"We hope you enjoyed your journey with AccuRanker and discovered the powerful "
"insights and tools that can help elevate your SEO strategy."
msgstr ""
"Vi håber, du har nydt din rejse med AccuRanker og opdaget de stærke "
"indsigter og værktøjer, der kan hjælpe med at løfte din SEO-strategi."

#: src/Pages/ErrorPages/TrialExpired/index.tsx:16
msgid "Don't let your SEO efforts pause here!"
msgstr "Lad ikke din SEO-indsats gå i stå her!"

#: src/Pages/ErrorPages/TrialExpired/index.tsx:18
msgid ""
"Join the thousands of clients worldwide who are benefiting from the full "
"suite of features that AccuRanker offers."
msgstr ""
"Slut dig til de tusindvis af kunder verden over, der nyder godt af den fulde "
"pakke af funktioner, som AccuRanker tilbyder."

#: src/Pages/ErrorPages/TrialExpired/index.tsx:30
msgid "Upgrade your account"
msgstr "Opgrader dit abonnement"

#: src/Pages/ErrorPages/TrialExpired/index.tsx:39
msgid "Still uncertain?"
msgstr "Er du stadig usikker?"

#: src/Pages/ErrorPages/TrialExpired/index.tsx:42
msgid ""
"We understand that every decision needs careful consideration. If you have "
"any questions or need further information, feel free to book a meeting with "
"our team of experts."
msgstr ""
"Vi forstår, at enhver beslutning skal overvejes nøje. Hvis du har spørgsmål "
"eller brug for yderligere information, er du velkommen til at booke et møde "
"med vores team af eksperter."

#: src/Pages/ErrorPages/TrialExpired/index.tsx:59
msgid "Book a 1:1 meeting"
msgstr "Book et 1:1-møde"

#: src/Pages/ErrorPages/TrialExpired/index.tsx:68
msgid "Your trial has come to an end"
msgstr "Din prøveversion er udløbet"

#: src/Pages/ExternalAccess/components/ExternalAccessTables.tsx:22
msgid "Pending requests to access this account"
msgstr "Afventende anmodninger om adgang til denne konto"

#: src/Pages/ExternalAccess/components/ExternalAccessTables.tsx:28
msgid "Organizations with access to this account"
msgstr "Konti med adgang til denne konto"

#: src/Pages/ExternalAccess/components/MultiAccountAllowAccess/components/MultiAccountActionButtons.tsx:33
msgid "Decline"
msgstr "Afvis"

#: src/Pages/ExternalAccess/components/MultiAccountAllowAccess/components/MultiAccountActionButtons.tsx:41
#: src/Pages/ExternalAccess/components/MultiAccountAllowAccess/components/MultiAccountRequestTextBlock.tsx:81
msgid "Allow access"
msgstr "Tillad adgang"

#: src/Pages/ExternalAccess/components/MultiAccountAllowAccess/components/MultiAccountRequestTextBlock.tsx:50
msgid "You cannot accept your own invitation."
msgstr "Du kan ikke acceptere din egen invitation."

#: src/Pages/ExternalAccess/components/MultiAccountAllowAccess/components/MultiAccountRequestTextBlock.tsx:57
msgid "Invitation has expired. Please request a new invitation."
msgstr "Invitationen er udløbet. Forespørg om en ny."

#: src/Pages/ExternalAccess/components/MultiAccountAllowAccess/components/MultiAccountRequestTextBlock.tsx:65
msgid "You have already granted access to organization [name]."
msgstr "Du har allerede givet adgang til kontoen [name]."

#: src/Pages/ExternalAccess/components/MultiAccountAllowAccess/components/MultiAccountRequestTextBlock.tsx:82
msgid "Allow user from %s to access your account?"
msgstr "Tillad adgang til brugere fra %s til din konto?"

#: src/Pages/ExternalAccess/components/MultiAccountAllowAccess/components/MultiAccountRequestTextBlock.tsx:83
msgid "Note that admin access will be given to the users."
msgstr "Bemærk at brugerne får administratorrettigheder."

#: src/Pages/ExternalAccess/components/MultiAccountAllowAccess/MultiAccountAllowAccess.tsx:72
msgid "Multi account request was accepted successfully."
msgstr "Kontoadgang godkendt."

#: src/Pages/ExternalAccess/components/MultiAccountAllowAccess/MultiAccountAllowAccess.tsx:88
msgid "Multi account request was declined successfully."
msgstr "Kontoadgang afvist."

#: src/Pages/ExternalAccess/OwnersTable/index.tsx:57
msgid "Remove organization access?"
msgstr "Fjern adgang?"

#: src/Pages/ExternalAccess/OwnersTable/index.tsx:58
msgid ""
"Once removing access, the organization %s can no longer access your account."
msgstr "Når du fjerner adgangen kan %s ikke længere tilgå din konto."

#: src/Pages/ExternalAccess/OwnersTable/index.tsx:70
msgid "Organization removed"
msgstr "Firma fjernet"

#: src/Pages/ExternalAccess/OwnersTable/index.tsx:77
msgid "Unable to remove organization"
msgstr "Kunne ikke fjerne firma"

#: src/Pages/ExternalAccess/OwnersTable/index.tsx:87
#: src/Pages/ExternalAccess/PendingOwnersTable/index.tsx:85
msgid "Organization Name"
msgstr "firmanavn"

#: src/Pages/ExternalAccess/OwnersTable/index.tsx:129
msgid "Remove"
msgstr "Slet"

#: src/Pages/ExternalAccess/OwnersTable/index.tsx:143
#: src/Pages/ExternalAccess/PendingOwnersTable/index.tsx:151
msgid "There are currently no organizations with external access."
msgstr "Der er i øjeblikket ingen organisationer med ekstern adgang."

#: src/Pages/ExternalAccess/PendingOwnersTable/index.tsx:55
msgid "Organization added"
msgstr "Firma tilføjet"

#: src/Pages/ExternalAccess/PendingOwnersTable/index.tsx:58
msgid "Unable to accept organization"
msgstr "Kunne ikke godkende firma"

#: src/Pages/ExternalAccess/PendingOwnersTable/index.tsx:74
msgid "Organization denied"
msgstr "Firma afvist"

#: src/Pages/ExternalAccess/PendingOwnersTable/index.tsx:77
msgid "Unable to deny organization"
msgstr "Kunne ikke afvise firma"

#: src/Pages/ExternalAccess/PendingOwnersTable/index.tsx:126
msgid "Accept"
msgstr "Accepter"

#: src/Pages/ExternalAccess/PendingOwnersTable/index.tsx:136
msgid "Deny"
msgstr "Benægt"

#: src/Pages/Groups/GroupsTable/support/useGroupsTableConfig.tsx:43
msgid "Add group as filter and go to domains"
msgstr "Tilføj grupper som filter og gå til domæner"

#: src/Pages/Groups/GroupsTable/support/useGroupsTableConfig.tsx:71
msgid "Number of Domains"
msgstr "Antal domæner"

#: src/Pages/Groups/GroupsTable/support/useGroupsTableConfig.tsx:145
msgid "Delete Group"
msgstr "Slet gruppe"

#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:92
#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/support/constants.tsx:11
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:221
msgid "Checkbox"
msgstr "Afkrydsning"

#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:121
msgid "Sort by Keyword"
msgstr "Sorter efter søgeord"

#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:124
msgid "Filter by Keyword…"
msgstr "Filtrer efter søgeord…"

#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:147
msgid "Sort by Country"
msgstr "Sorter efter land"

#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:150
msgid "Filter by Country"
msgstr "Filtrer efter land"

#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:168
msgid "Sort by Impressions"
msgstr "Sorter efter visninger"

#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:171
msgid "Filter by Impressions"
msgstr "Filtrer efter visninger"

#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:189
msgid "Sort by Clicks"
msgstr "Sorter efter klik"

#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:192
msgid "Filter by Clicks"
msgstr "Filtrer efter klik"

#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:219
msgid "Sort by CTR"
msgstr "Sorter efter CTR"

#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:222
msgid "Filter by CTR"
msgstr "Filtrer efter CTR"

#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:249
msgid "Sort by Position"
msgstr "Sorter efter lokation"

#: src/Pages/Import/GSC/GSCKeywordTable/helpers/hooks.tsx:252
msgid "Filter by Position"
msgstr "Filtrer efter lokation"

#: src/Pages/Import/GSC/GSCKeywordTable/index.tsx:19
msgid "No Keywords"
msgstr "Ingen søgeord"

#: src/Pages/Import/GSC/GSCKeywordTable/index.tsx:20
msgid "There are currently no keywords to import."
msgstr "Der er i øjeblikket ingen nøgleord at importere."

#: src/Pages/Import/GSC/index.tsx:242
msgid "Hide tracked keywords"
msgstr "Skjul overvågede søgeord"

#: src/Pages/Import/GSC/index.tsx:246
msgid "Show tracked keywords"
msgstr "Se overvågede søgeord"

#: src/Pages/Import/GSC/index.tsx:277
msgid "Import keywords directly from Google Search Console"
msgstr "Importer søgeord direkte fra Google Search Console"

#: src/Pages/Import/GSC/index.tsx:284
msgid "Import selected keyword (%s)"
msgid_plural "Import selected keywords (%s)"
msgstr[0] "Importer valgt søgeord (%s)"
msgstr[1] "Importer valgte søgeord (%s)"

#: src/Pages/Import/GSC/index.tsx:303
msgid "Import filtered (%s)"
msgstr "Import filtreret (%s)"

#: src/Pages/Import/GSC/index.tsx:303
msgid "Import all"
msgstr "Importer alle"

#: src/Pages/Import/importer_helpers.ts:17
msgid "In queue (Validating)"
msgstr "I kø (Validerer)"

#: src/Pages/Import/importer_helpers.ts:27
msgid "In queue (Importing)"
msgstr "I kø (Importerer)"

#: src/Pages/Import/importer_helpers.ts:30
#: src/Pages/Import/Validation/index.tsx:102
msgid "Importing"
msgstr "Importering"

#: src/Pages/Import/importer_helpers.ts:33
#: src/Pages/Import/Validation/index.tsx:103
msgid "Completed"
msgstr "Færdig"

#: src/Pages/Import/importTable.tsx:80
msgid "Failed to download the file. Please contact support."
msgstr "Kunne ikke downloade filen. Prøv igen, eller kontakt supporten."

#: src/Pages/Import/importTable.tsx:102
#: src/Pages/Import/Validation/index.tsx:80
msgid "Files are stored for 14 days"
msgstr "Filerne gemmes i 14 dage"

#: src/Pages/Import/importTable.tsx:123
msgid "Delete Import?"
msgstr "Slet importering?"

#: src/Pages/Import/importTable.tsx:124
msgid "The import will be permanently deleted."
msgstr "Importeringen bliver permanent slettet."

#: src/Pages/Import/importTable.tsx:125
msgid "Delete import"
msgstr "Slet importering"

#: src/Pages/Import/importTable.tsx:157
#: src/Pages/Sales/Plans/SalesPlansTable/support/hooks.tsx:117
msgid "View"
msgstr "Vis"

#: src/Pages/Import/importTable.tsx:172
msgid "No Imports"
msgstr "Ingen importeringer"

#: src/Pages/Import/importTable.tsx:173
msgid "Start an import above to see the progress here."
msgstr "Start en import ovenfor for at se fremskridt her."

#: src/Pages/Import/importTable.tsx:188
msgid "File"
msgstr "Fil"

#: src/Pages/Import/index.tsx:94
msgid "Imports"
msgstr "Importeringer"

#: src/Pages/Import/index.tsx:115
msgid "From file"
msgstr "Fra fil"

#: src/Pages/Import/index.tsx:119
msgid "How to import from a CSV/Excel file"
msgstr "Sådan importerer du fra en CSV/Excel fil"

#: src/Pages/Import/index.tsx:122
msgid "Make sure your CSV/Excel file is in the format specified [link]."
msgstr "Sørg for, at din CSV/Excel-fil er i det angivne format [link]."

#: src/Pages/Import/index.tsx:123
#: src/Pages/importExamples/ImportExamples.tsx:89
#: src/Pages/Layout/DashboardTemplate/DemoDomainBanner/DemoDomainBanner.tsx:53
msgid "here"
msgstr "her"

#: src/Pages/Import/index.tsx:126
msgid "Upload the file below."
msgstr "Upload filen nedenunder."

#: src/Pages/Import/index.tsx:127
msgid "Follow the steps to setup the file and import."
msgstr "Følg trinene for at opsætte filen og importere den."

#: src/Pages/Import/index.tsx:128
msgid ""
"[br]NB. importing keywords might lead to exceeding your plan limit. Read "
"more about keyword exceedings [here]."
msgstr ""
"[br]OBS Import af søgeord kan føre til, at du overskrider begrænsningen i "
"dit abonnement. Læs om merforbrug af søgeord [here]."

#: src/Pages/Import/index.tsx:152
msgid "Preparing"
msgstr "Forbereder"

#: src/Pages/Import/index.tsx:171
msgid "Invalid file. Please provide a CSV/Excel file as described above."
msgstr "Ugyldig filtype. Brug venligst en CSV/Excel fil som beskrevet ovenfor."

#: src/Pages/Import/index.tsx:195
msgid ""
"To get information on how to import from a third-party, please click the "
"button below and send your message to our support team. They will then "
"provide the information you need to get started."
msgstr ""
"For at få oplysninger om, hvordan du importerer fra en tredjepart, skal du "
"klikke på knappen nedenunder og sende en besked til vores supportteam. De "
"giver derefter de oplysninger, du har brug for, for at komme i gang."

#: src/Pages/Import/index.tsx:201
msgid "From third-parties"
msgstr "Fra tredjeparter"

#: src/Pages/Import/index.tsx:211
msgid "Import from a third party?"
msgstr "Importer fra tredjepart?"

#: src/Pages/Import/index.tsx:213
msgid "Need to import your data from a third party?"
msgstr "Har du brug for at importere fra tredjepart?"

#: src/Pages/Import/index.tsx:215
msgid "Dont worry, we've got you covered."
msgstr "Bare rolig, vi har din ryg."

#: src/Pages/Import/index.tsx:218
msgid ""
"Send your message to our support team. They will then provide the "
"information you need to get started."
msgstr ""
"Send en besked til vores supportteam. De giver derefter de oplysninger, du "
"har brug for, for at komme i gang."

#: src/Pages/Import/index.tsx:234
msgid "Connect to a third party"
msgstr "Forbind til en tredjepart"

#: src/Pages/Import/Validation/index.tsx:124
msgid "In queue ([statusText])"
msgstr "I kø ([statusText])"

#: src/Pages/Import/Validation/index.tsx:131
msgid "Step [currentStep] of [steps] - [statusText]"
msgstr "Trin [currentStep] af [steps] - [statusText]"

#: src/Pages/Import/Validation/index.tsx:236
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:226
msgid "File Import"
msgstr "Fil-import"

#: src/Pages/Import/Validation/Status/completed.tsx:9
msgid "Your import is completed."
msgstr "Din import er færdig."

#: src/Pages/Import/Validation/Status/completed.tsx:13
#: src/Pages/Import/Validation/Status/validationError.tsx:112
msgid "Back to imports"
msgstr "Tilbage til importering"

#: src/Pages/Import/Validation/Status/new.tsx:212
msgid ""
"Below you are able to map and import your CSV/excel file and import the data "
"in the correct format into AccuRanker."
msgstr ""
"Nedenfor er du i stand til at kortlægge og importere din CSV/excel-fil og "
"importere dataene i det rigtige format til AccuRanker."

#: src/Pages/Import/Validation/Status/new.tsx:217
msgid ""
"If you need help with the structure of the columns or see allowed values, "
"please refer to the [link:examples]."
msgstr ""
"Hvis du har brug for hjælp til strukturen af kolonnerne eller til at se de "
"tilladte værdier, se venligst [link:eksemplerne]."

#: src/Pages/Import/Validation/Status/new.tsx:236
msgid ""
"In the table below you see a list of the columns in the uploaded CSV/Excel "
"file. To start the import please map the column with the corresponding "
"AccuRanker field."
msgstr ""
"I nedenstående tabel ser du en liste over kolonnerne i den uploadede CSV/"
"Excel-fil. For at starte importen skal du forbinde kolonnen med det "
"tilsvarende AccuRanker-felt."

#: src/Pages/Import/Validation/Status/new.tsx:240
msgid "Map Columns With Fields"
msgstr "Forbind Kolonner Med Felter"

#: src/Pages/Import/Validation/Status/new.tsx:247
msgid "Number"
msgstr "Nummer"

#: src/Pages/Import/Validation/Status/new.tsx:248
#: src/Pages/importExamples/ImportExamples.tsx:68
#: src/Pages/importExamples/ImportExamples.tsx:120
msgid "Column name"
msgstr "Kolonnenavn"

#: src/Pages/Import/Validation/Status/new.tsx:249
msgid "Map into field"
msgstr "Forbind til felt"

#: src/Pages/Import/Validation/Status/new.tsx:267
msgid "The group you select is where the domain and keywords will be imported."
msgstr "Den gruppe, du vælger, er hvor domænet og søgeordene importeres til."

#: src/Pages/Import/Validation/Status/new.tsx:286
msgid ""
"There are errors with your setup, please correct the issues before importing."
msgstr "Der er fejl i din opsætningen. Ret problemerne og prøv igen."

#: src/Pages/Import/Validation/Status/new.tsx:291
#: src/Pages/Import/Validation/Status/new.tsx:296
msgid "Submit"
msgstr "Indsend"

#: src/Pages/Import/Validation/Status/new.tsx:303
msgid ""
"In the table below you can see a preview of how columns in the uploaded CSV/"
"Excel file will be mapped to AccuRanker fields."
msgstr ""
"I nedenstående tabel ser du en forhåndsvisning af, hvordan kolonnerne i den "
"uploadede CSV/Excel-fil vil tilsvare AccuRanker felter."

#: src/Pages/Import/Validation/Status/new.tsx:307
#: src/Pages/ScheduledReportBuilder/index.tsx:29
msgid "Preview"
msgstr "Forhåndsvisning"

#: src/Pages/Import/Validation/Status/newHelpers.ts:40
msgid "'[name]' is being used by column [id] ([column])"
msgstr "'[name]' bruges allerede af kolonne [id] ([column])"

#: src/Pages/Import/Validation/Status/newHelpers.ts:59
msgid "Please select a group for your import."
msgstr "Vælg en gruppe til din import."

#: src/Pages/Import/Validation/Status/newHelpers.ts:108
msgid "Missing required fields for [type] import: [missingRequiredNames]"
msgstr "Udfyld alle påkrævede felter for [type] import: [missingRequiredNames]"

#: src/Pages/Import/Validation/Status/newHelpers.ts:109
msgid "history"
msgstr "historik"

#: src/Pages/Import/Validation/Status/newHelpers.ts:109
msgid "keyword"
msgstr "søgeord"

#: src/Pages/Import/Validation/Status/processing.tsx:22
#: src/Pages/Import/Validation/Status/validating.tsx:13
msgid "Initializing"
msgstr "Klargører"

#: src/Pages/Import/Validation/Status/processing.tsx:35
msgid "Waiting for import to resume"
msgstr "Venter på at import genoptages"

#: src/Pages/Import/Validation/Status/processing.tsx:56
msgid "[hours] hours, "
msgstr "[hours] timer, "

#: src/Pages/Import/Validation/Status/processing.tsx:57
msgid "[minutes] minutes and "
msgstr "[minutes] minutter og "

#: src/Pages/Import/Validation/Status/processing.tsx:58
msgid "[seconds] seconds."
msgstr "[seconds] sekunder."

#: src/Pages/Import/Validation/Status/processing.tsx:59
msgid "Estimated time remaining: [hoursString][minutesString][secondsString]"
msgstr "Anslået resttid: [hoursString][minutesString][secondsString]"

#: src/Pages/Import/Validation/Status/validationError.tsx:25
msgid "All lines"
msgstr "Alle linjer"

#: src/Pages/Import/Validation/Status/validationError.tsx:25
#: src/Pages/Import/Validation/Status/validationError.tsx:28
msgid "Lines"
msgstr "Linjer"

#: src/Pages/Import/Validation/Status/validationError.tsx:25
msgid "Line"
msgstr "Linje"

#: src/Pages/Import/Validation/Status/validationError.tsx:42
msgid ""
"During validation, we found one or more errors in the following column(s). "
"Please fix them and try again. If you are unable to resolve the error(s) "
"please contact our support."
msgstr ""
"Under validering fandt vi en eller flere fejl i følgende kolonne(r). Vær "
"venlig at løse fejlen(e) og prøv igen. Hvis du ikke kan løse fejlen(e), er "
"du velkommen til at kontakte vores support."

#: src/Pages/Import/Validation/Status/validationError.tsx:77
msgid ""
"During validation, we found one or more general errors. Please fix them and "
"try again. If you are unable to resolve the error(s) please contact our "
"support."
msgstr ""
"Under validering fandt vi en eller flere generelle fejl. Vær venlig at løse "
"fejlen(e) og prøv igen. Hvis du ikke kan løse fejlen(e), er du velkommen til "
"at kontakte vores support."

#: src/Pages/importExamples/ImportExamples.tsx:35
msgid "Example files"
msgstr "Fileksempler"

#: src/Pages/importExamples/ImportExamples.tsx:48
#: src/Pages/importExamples/ImportExamples.tsx:110
msgid "Keywords incl. rank history"
msgstr "Nøgleord inkl. placeringshistorie"

#: src/Pages/importExamples/ImportExamples.tsx:69
#: src/Pages/importExamples/ImportExamples.tsx:121
msgid "Required"
msgstr "Påkrævet"

#: src/Pages/importExamples/ImportExamples.tsx:71
#: src/Pages/importExamples/ImportExamples.tsx:123
msgid "Example"
msgstr "Eksempel"

#: src/Pages/importExamples/ImportExamples.tsx:86
msgid "View available countries [link]."
msgstr "Se de tilgængelige lande [link]."

#: src/Pages/importExamples/ImportExamples.tsx:114
msgid "Fields from Keywords in addition to the ones below."
msgstr "Felter fra søgeord ud over dem nedenfor."

#: src/Pages/IntegrationApi/index.tsx:13
msgid ""
"API filters can be used to setup filters that you can apply on API call. You "
"create an API filter by creating a normal keywords filter and then "
"converting it to an API filter. Learn how to use API filters in the [link:"
"API docs]."
msgstr ""
"API filtre bruges til at opsætte filtre du vil bruge i dine API kald. Du "
"opretter et API filter ved at først at lave et normalt filter for derefter "
"at konvertere den til et API filter. Læs mere i vores [link:API "
"dokumentation]."

#: src/Pages/IntegrationApi/IntegrationAPIsTable/index.tsx:71
msgid "No Filters"
msgstr "Intet filter"

#: src/Pages/IntegrationApi/IntegrationAPIsTable/index.tsx:72
msgid "There are currently no API filters to show."
msgstr "Der er i øjeblikket ingen API-filtre at vise."

#: src/Pages/Integrations/components/IntegrationCard.tsx:32
msgid "Popular"
msgstr "Populær"

#: src/Pages/Integrations/components/IntegrationCard.tsx:37
#: src/Pages/Integrations/components/IntegrationCard.tsx:49
msgid "Coming Soon"
msgstr "Kommer snart"

#: src/Pages/Integrations/components/IntegrationCard.tsx:42
msgid "BETA"
msgstr "BETA"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:51
msgid ""
"Google Analytics is the most popular digital analytics tool offered by "
"Google that helps you to keep track of your customer’s journey by analysing "
"visitor traffic."
msgstr ""
"Google Analytics er det mest populære analyseværktøj fra Google, der hjælper "
"dig med at holde styr på besøgende på din hjemmeside."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:56
msgid ""
"Connecting AccuRanker to your Google Analytics account will provide more in-"
"depth data such as Est. visitors per keyword and Landing pages/Tag cloud "
"data for professional users."
msgstr ""
"Ved at forbinde Google Analytics til AccuRanker får du adgang til data såsom "
"est. antal besøgende, landing-pages og tag cloud."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:61
msgid ""
"Integration with Google Analytics is completely free of charge and our "
"customer service team will be happy to help you if you have any questions."
msgstr ""
"Integrationen med Google Analytics er gratis og vores kundesupport hjælper "
"dig gerne hvis du har spørgsmål."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:67
msgid ""
"Learn how to connect to Google Analytics with our [link:Step by step guide]"
msgstr "Lær at forbinde til Google Analytics med vores [link:guide]"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:84
#: src/Pages/Keywords/Overview/components/OverviewActionBar.tsx:146
#: src/Pages/Sales/Organization/index.tsx:287
#: src/Pages/SelectPlan/plan-features.tsx:94
msgid "Adobe Analytics"
msgstr "Adobe Analytics"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:88
msgid ""
"Adobe Analytics (formerly known as Omniture) is a widely popular analytics "
"tool used for applying real-time analytics and detailed segmentation across "
"all of your marketing channels. "
msgstr ""
"Adobe Analytics (tidligere Omniture) er et populært analyseværktøj til "
"realtids-analyse og detaljeret segmentering på tværs af alle dine "
"marketingskanaler. "

#: src/Pages/Integrations/hooks/useIntegrations.tsx:93
msgid ""
"Connecting AccuRanker to your Adobe Analytics account will provide more in-"
"depth data such as organic traffic, goals and where the traffic is coming "
"from."
msgstr ""
"Hvis du forbinder AccuRanker med Adobe Analytics får du adgang til data "
"såsom organisk trafik, mål samt hvor trafikken kommer fra."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:99
msgid ""
"Learn how to connect to Adobe Analytics with our [link:Step by step guide]"
msgstr "Lær at forbinde til Adobe Analytics med vores [link:guide]"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:121
msgid ""
"Google Search Console (formerly known as Google Webmaster Tools) is a free "
"web service by Google, that allows to check indexing status and optimize "
"visibility of the website."
msgstr ""
"Google Search Console (tidligere Webmaster Tools) er en gratis service fra "
"Google hvor du kan se din indekserings-status samt optimere din synlighed."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:126
msgid ""
"Connecting AccuRanker to your Google Search Console account will enable you "
"to easily import your keywords. Integration with Google Search Console is "
"completely free of charge and our customer service team will be happy to "
"help you if you have any questions."
msgstr ""
"Hvis du forbinder AccuRanker med din Google Search Console konto, vil du "
"være i stand til at hente de søgeord der findes i Search Console. "
"Integration med Google Search Console er helt gratis, og vores "
"kundeserviceteam står klar til at hjælpe dig, hvis du har spørgsmål."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:132
msgid ""
"Learn how to connect to Google Search Console with our [link:Step by step "
"guide]"
msgstr ""
"Se hvordan du forbinder til Google Search Console med vores [link:guide]"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:157
msgid ""
"Google Sheets is another integration that can help you streamline your "
"reporting."
msgstr ""
"Google Sheets er en anden integration der kan hjælpe dig med at strømline "
"din rapportering."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:160
msgid ""
"Connecting Google Drive Account with AccuRanker will enable you to export "
"your reports directly into Google Sheets."
msgstr ""
"Ved at forbinde din Google Drive konto til AccuRanker kan du gemme rapporter "
"direkte i Google Sheets."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:166
msgid ""
"Learn how to export AccuRanker data into Google Spreadsheets with our [link:"
"Step by step guide]"
msgstr ""
"Lær hvordan du eksporterer AccuRanker data til Google Sheets med vores [link:"
"guide]"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:187
msgid "Google Looker Studio"
msgstr "Google Looker Studio"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:191
msgid ""
"Google Looker Studio is a dashboard tool that turns your data into "
"informative dashboards and reports that are easy to read and share."
msgstr ""
"Google Looker Studio er et dashboard værktøj der kan transformere dine data "
"om til informative dashboards og rapporter."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:197
msgid ""
"To use the AccuRanker Google Looker Studio data source use the following "
"[link:link]"
msgstr ""
"Klik [link:her] for at bruge AccuRanker's Google Looker Studio connector"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:211
msgid ""
"Connecting Google Looker Studio with AccuRanker will enable you to show "
"advanced AccuRanker data on Google Looker Studio dashboard in a simple and "
"sleek way."
msgstr ""
"Hvis du forbinder Google Looker Studio med AccuRanker bliver du i stand til "
"at vise AccuRanker data i Google Looker Studio på en let og lækker måde."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:216
msgid ""
"Integration with Google Looker Studio is completely free of charge and our "
"customer service team will be happy to help you if you have any questions."
msgstr ""
"Integrationen med Google Looker Studio er helt gratis. Hvis du støder i "
"problemer med integrationen hjælper vores kundeservice gerne med spørgsmål."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:222
msgid ""
"Learn how to export AccuRanker data into Google Looker Studio with our [link:"
"Step by step guide]"
msgstr ""
"Lær hvordan du eksporterer AccuRanker data til Google Looker Studio med "
"vores [link:guide]"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:238
msgid "Pre-made templates"
msgstr "Forudlavede skabeloner"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:240
msgid ""
"AccuRanker has created three templates to help you get started presenting "
"your data in Google Looker Studio:"
msgstr ""
"AccuRanker har oprettet tre skabeloner for at hjælpe dig i gang med at "
"præsentere dine data i Google Looker Studio:"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:275
msgid ""
"Once you've connected AccuRanker and Looker Studio as explained above, you "
"can copy a template by selecting [b: Make a copy] from the [b2: More "
"options] menu in the upper right corner."
msgstr ""
"Når du har forbundet AccuRanker og Looker Studio som forklaret ovenfor, kan "
"du kopiere en skabelon ved at vælge [b: Lav en kopi] fra [b2: Flere "
"muligheder]-menuen i øverste højre hjørne."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:288
msgid ""
"screenshot showing the 'Make a copy' option inside the 'More options' menu "
"in Looker Studio"
msgstr ""
"skærmbillede, der viser 'Lav en kopi'-indstillingen i 'Flere muligheder'-"
"menuen i Looker Studio"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:302
msgid "CSV Importer"
msgstr "CSV importer"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:305
msgid "With our bulk importer you are able to import data from a CSV file."
msgstr "Med vores masse-importer kan du importere data fra en CSV fil."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:308
msgid ""
"To use the AccuRanker CSV importer go to the the importer and [link:Upload "
"CSV]"
msgstr ""
"For at bruge AccuRanker CSV importeren skal du gå til importeren og [link:"
"Upload CSV]"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:323
msgid "Third-party Importer"
msgstr "Tredjepartsimporter"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:327
msgid ""
"With our third-party importers we can import your data from a range of other "
"rank tracking tools. Contact our support and we will be happy to help you "
"import your data into AccuRanker from a third-party tool."
msgstr ""
"Med vores tredjeparts-importere kan vi importere data fra en lang række rank "
"tracking værktøjer. Kontakt vores support for at få hjælp til at få dine "
"historiske data ind i AccuRanker."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:338
msgid "Databox"
msgstr "Databox"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:342
msgid ""
"Databox is a decision-making platform built to help you track performance, "
"discover insights and understand what's going on with your business. It "
"connects your cloud services, spreadsheets, databases and custom "
"integrations to organize all of your business KPIs in one place. Databox "
"will deliver your metrics via mobile, browser, big screen, Apple Watch®, and "
"even Slack."
msgstr ""
"Databox er en beslutningsplatform, der er bygget til at hjælpe dig med at "
"holde øje med ydeevne, give indsigt og få dig til at forstå hvad der sker "
"med din virksomhed. Det forbinder dine skytjenester, regneark, databaser og "
"brugerdefinerede integrationer for at organisere alle dine forretnings-"
"KPI'er ét sted. Databox leverer dine metrics via mobil, browser, storskærm, "
"Apple Watch® og endda Slack."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:347
msgid ""
"View analytics data from AccuRanker, HubSpot, Google Analytics, SEMRush, "
"Wistia, Drift, Youtube, Facebook Advertising, Instagram, X and many more "
"sources in just a few clicks. You can easily pull all of your KPIs into one "
"place, build your own dashboards with no coding required, set measurable "
"goals to track your progress and get performance alerts, recommendations or "
"important KPIs delivered to you when they matter most. This integration "
"provides robust reporting capabilities outside of the AccuRanker reporting "
"app. If AccuRanker provides an API for the data, we make it possible for you "
"to visualize it in Databox. Used by thousands of AccuRanker customers in "
"total, Databox is focused on serving the needs of AccuRanker customers."
msgstr ""
"Se analysedata fra AccuRanker, HubSpot, Google Analytics, SEMRush, Wistia, "
"Drift, YouTube, Facebook Advertising, Instagram, X og mange flere kilder med "
"få klik. Du kan nemt trække alle dine KPI'er på ét sted, oprette dine egne "
"dashboards uden selv at skulle kode noget, indstille målbare mål for at "
"spore dine fremskridt og få ydeevneadvarsler, anbefalinger eller vigtige "
"KPI'er leveret til dig, når de betyder mest. Denne integration giver robuste "
"rapporteringsfunktioner uden for AccuRanker-rapporteringsappen. Hvis "
"AccuRanker leverer en API til dataene, gør vi det muligt for dig at "
"visualisere det i databasen. Databox er brugt af tusindvis af AccuRanker-"
"kunder og er derfor fokuseret på at imødekomme AccuRanker-kundernes behov."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:353
msgid "You can read more and enable the integration [link:here]"
msgstr "Du kan læse mere og aktivere integrationen [link:her]"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:374
msgid ""
"Using this API you will be able to retrieve metrics from your AccuRanker "
"account."
msgstr ""
"Ved hjælp af dette API vil du være i stand til at hente tal fra din "
"AccuRanker konto."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:378
msgid ""
"To learn more about the API please refer to the [link:API documentation]"
msgstr "Læs vores [link:dokumentation] for at lære mere om vores API"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:396
msgid ""
"Connecting AccuRanker to BigQuery transforms data analysis by delivering "
"faster, in-depth insights\n"
"              on keyword performance, SEO trends, and overall marketing "
"effectiveness, and it's all in real time."
msgstr ""
"Ved at forbinde AccuRanker til BigQuery transformeres dataanalyse ved at "
"levere hurtigere og dybdegående indsigter\n"
" i søgeordsydelse, SEO-trends og den samlede marketingeffektivitet – alt "
"sammen i realtid."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:403
msgid "To use the Google BigQuery data source use the following [link:link]"
msgstr "Klik [link:her] for at bruge Google BigQuery data kilden"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:409
msgid ""
"This integration is perfect for time-sensitive tasks like monitoring live "
"metrics instantly.\n"
"              BigQuery ensures seamless data integration and scales "
"effortlessly, allowing customers to analyze\n"
"              AccuRanker data alongside other datasets without extra "
"configuration, providing a complete view\n"
"              of everything from keyword rankings to traffic trends. All to "
"help you make faster, data-driven decisions."
msgstr ""
"Denne integration er perfekt til tidskritiske opgaver som øjeblikkelig "
"overvågning af live-metrikker.\n"
" BigQuery sikrer problemfri dataintegration og skalerer uden besvær, hvilket "
"gør det muligt for kunder at analysere\n"
" AccuRanker-data sammen med andre datasæt uden ekstra konfiguration. Det "
"giver et komplet overblik over alt fra\n"
" søgeordsplaceringer til trafiktrends. Alt sammen for at hjælpe dig med at "
"træffe hurtigere, datadrevne beslutninger."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:418
msgid ""
"Learn how to connect to Google BigQuery with our [link:Step by step guide]"
msgstr "Lær at forbinde til Google BigQuery med vores [link:guide]"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:429
msgid ""
"[b:This feature is available to customers on our 20K+ plans.][br]Reach out "
"to customer support to enable the BigQuery feature."
msgstr ""
"[b:Denne integration er tilgængelig for abonnementer med 20K+ søgeord.]"
"[br]Kontakt kundesupport for mere information."

#: src/Pages/Integrations/hooks/useIntegrations.tsx:458
msgid "Request Integration"
msgstr "Foreslå integration"

#: src/Pages/Integrations/hooks/useIntegrations.tsx:462
msgid ""
"If you wish to request an integration, please send an email to "
"support@accuranker.com"
msgstr ""
"Send en e-mail til support@accuranker.com hvis du ønsker at foreslå en "
"integration"

#: src/Pages/Integrations/index.tsx:18
msgid "Standard integrations"
msgstr "Standardintegrationer"

#: src/Pages/Integrations/index.tsx:23
msgid "Advanced integrations"
msgstr "Avancerede-integrationer"

#: src/Pages/Integrations/index.tsx:24
msgid ""
"These integrations utilize the [api:AccuRanker API]. API access is included "
"in all plans."
msgstr ""
"Disse integrationer anvender [api:AccuRanker API]. API-adgang er inkluderet "
"i alle planer."

#: src/Pages/Integrations/index.tsx:42
msgid "Enterprise integrations"
msgstr "Enterprise integrationer"

#: src/Pages/Integrations/index.tsx:43
msgid ""
"These integrations are only available on enterprise plans. Reach out to "
"[link:customer support] for more information."
msgstr ""
"Disse integrationer er kun tilgængelige på Enterprise abonnementer. Kontakt "
"[link:supporten] for mere information."

#: src/Pages/Invoices/index.tsx:24
msgid "Invoices for"
msgstr "Fakturaer for"

#: src/Pages/Invoices/InvoicesTable.tsx:37
msgid "Invoice Number"
msgstr "Fakturanummer"

#: src/Pages/Invoices/InvoicesTable.tsx:54
msgid "Amount Excl. VAT"
msgstr "Ekskl. moms"

#: src/Pages/KeywordDiscovery/components/Actions/Actions.tsx:135
msgid "Go to keywords"
msgstr "Gå til søgeord"

#: src/Pages/KeywordDiscovery/components/Actions/Actions.tsx:148
#: src/Pages/KeywordDiscovery/components/ResearchPanel/ImportKeywordsCard.tsx:121
msgid "to"
msgstr "til"

#: src/Pages/KeywordDiscovery/components/DialogOverlay/index.tsx:140
msgid ""
"In-depth analysis of your own and your competitors' domains. By importing "
"keywords,\n"
"you can continuously monitor performance and gain real-time insights for "
"even deeper analysis."
msgstr ""
"Dybdegående analyse af dine egne og dine konkurrenters domæner. Ved at "
"importere søgeord,\n"
"kan du kontinuerligt overvåge præstationen og opnå realtidsindsigt til endnu "
"dybere analyse."

#: src/Pages/KeywordDiscovery/components/DialogOverlay/NoUntrackedKeywordsAlert.tsx:15
msgid ""
"[b: No keywords found][br]We did not find any untracked keywords for the "
"selected domain."
msgstr ""
"[b: Ingen søgeord fundet][br]Vi fandt ingen søgeord som matcher det valgte "
"domæne."

#: src/Pages/KeywordDiscovery/components/KeydisDomainSelect/helpers.tsx:37
msgid "Your Domains"
msgstr "Dine domæner"

#: src/Pages/KeywordDiscovery/components/KeydisDomainSelect/helpers.tsx:43
msgid "Please wait"
msgstr "Vent venligst"

#: src/Pages/KeywordDiscovery/components/KeydisDomainSelect/helpers.tsx:181
msgid "Recent Searches"
msgstr "Seneste søgninger"

#: src/Pages/KeywordDiscovery/components/KeydisDomainSelect/index.tsx:199
msgid "Press enter to search for this domain"
msgstr "Tryk enter for at søge efter dette domæne"

#: src/Pages/KeywordDiscovery/components/KeydisDomainSelect/index.tsx:290
msgid "Loading domains..."
msgstr "Henter domæner..."

#: src/Pages/KeywordDiscovery/components/KeydisDomainSelect/index.tsx:291
msgid "Select or enter a path to search. E.g. example.com/path"
msgstr "Vælg eller indtast en sti til at søge på. F.eks. eksempel.com/sti"

#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/KeywordDiscoveryTable.tsx:67
msgid "There are currently no keywords to discover."
msgstr "Der er pt. ingen søgeord i Keyword Discovery."

#: src/Pages/KeywordDiscovery/components/KeywordDiscoveryTable/support/constants.tsx:211
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/SERPCell.tsx:136
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:810
#: src/Pages/Keywords/Table/keydisCells.ts:264
msgid "SERP features"
msgstr "SERP-elementer"

#: src/Pages/KeywordDiscovery/components/KpiBar/index.tsx:36
msgid "Traffic val. ([currency])"
msgstr "Trafikværdi ([currency])"

#: src/Pages/KeywordDiscovery/components/Placeholders/index.tsx:22
msgid "Paid Content"
msgstr "Betalt content"

#: src/Pages/KeywordDiscovery/components/Placeholders/index.tsx:23
msgid "The Keyword Discovery tool is only available on paid plans."
msgstr ""
"Keyword Discovery værktøjet er kun tilgængeligt på betalte abonnementer."

#: src/Pages/KeywordDiscovery/components/ResearchDashboard/index.tsx:52
msgid "Hide this panel"
msgstr "Skjul dette panel"

#: src/Pages/KeywordDiscovery/components/ResearchDashboard/index.tsx:52
msgid "Show more details"
msgstr "Vis flere detaljer"

#: src/Pages/KeywordDiscovery/components/ResearchDashboard/index.tsx:63
msgid "Hide the quick actions research panel"
msgstr "Skjul quick actions panelet"

#: src/Pages/KeywordDiscovery/components/ResearchDashboard/index.tsx:64
msgid "Show the quick actions research panel"
msgstr "Vis quick actions panelet"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/importButton.tsx:117
msgid "Add domain with keywords"
msgstr "Tilføj domænet med søgeord"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/importButton.tsx:120
msgid "Import [keywords] keywords"
msgstr "Importer [keywordsCount] søgeord"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/importButton.tsx:127
msgid "Select keywords to import"
msgstr "Vælg søgeord at importere"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/ImportKeywordsCard.tsx:72
#: src/Pages/KeywordDiscovery/components/ResearchPanel/ImportKeywordsCard.tsx:87
msgid "Keyword Import"
msgstr "Søgeordsimport"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/ImportKeywordsCard.tsx:75
msgid ""
"To import your desired keywords, simply mark them in the table below and "
"then click on the \"import keywords\" button to import them to your domain."
msgstr ""
"For at importere søgeord, marker dem i tabellen nedenfor og klik på "
"\"importer søgeord\" knappen for at importere dem til dit domæne."

#: src/Pages/KeywordDiscovery/components/ResearchPanel/ImportKeywordsCard.tsx:81
msgid "screenshot of table listing keywords"
msgstr "screenshot af tabel med søgeord"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/ImportKeywordsCard.tsx:97
msgid "Selected keywords"
msgstr "Valgte søgeord"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/ImportKeywordsCard.tsx:105
msgid "Available keywords"
msgstr "Tilgængelige søgeord"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/KeywordSearch.tsx:158
msgid "Keyword Search"
msgstr "Søgning efter søgeord"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/KeywordSearch.tsx:195
msgid "Press enter to search"
msgstr "Tryk på Enter for at søge"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/KeywordSearch.tsx:196
msgid "Keyword search"
msgstr "Søgning efter søgeord"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/KeywordSearch.tsx:214
msgid "Clear search"
msgstr "Ryd søgning"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/SearchIntent.tsx:127
msgid "Filter by [intentName]"
msgstr "Filtrer efter [intentName]"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/SearchIntent.tsx:143
msgid "of"
msgstr "af"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActions/SERPFeatures.tsx:105
msgid ""
"This box shows the SERP Features and their related keywords count.[br]Toggle "
"between them to see which ones are owned and which ones are available.[br]"
"[guide]"
msgstr ""
"Denne boks viser SERP-elementer og deres antal relaterede søgeord.[br]Skift "
"mellem dem for at se, hvilke der er ejet, og hvilke der er tilgængelige.[br]"
"[guide]"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActionsCard.tsx:32
msgid ""
"This panel is used to maximize your keyword research speed and accuracy with "
"handy filter shortcuts.[br]Mix and match actions to quickly zero in on the "
"most relevant keywords.[br][br][tip] Make your search even more targeted by "
"combining the actions with filters from the top-level filter bar."
msgstr ""
"Dette panel bruges til at maksimere hastigheden og nøjagtigheden af din "
"søgning efter søgeord med praktiske filtergenveje. [br]Bland og match "
"handlinger for hurtigt at finde de mest relevante søgeord. [br][br][tip] Gør "
"din søgning endnu mere målrettet ved at kombinere handlingerne med filtre "
"fra filterlinjen i toppen af siden."

#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActionsCard.tsx:36
msgid "Tip"
msgstr "Tip"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActionsCard.tsx:40
msgid "Quick Actions"
msgstr "Hurtige Handlinger"

#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActionsCard.tsx:44
#: src/Pages/KeywordDiscovery/components/ResearchPanel/QuickActionsCard.tsx:48
msgid "Reset all filters"
msgstr "Nulstil alle filtre"

#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:74
msgid "Phrase"
msgstr "Sætning"

#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:85
msgid "Variations"
msgstr "Variationer"

#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:87
msgid "Sort by variations"
msgstr "Sorter efter variationer"

#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:135
msgid "Sort by average rank"
msgstr "Sorter efter gennemsnitsplacering"

#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:150
msgid "Sort by Avg. CTR"
msgstr "Sorter efter Gns. CTR"

#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:165
msgid "Sort by Avg. Max CTR"
msgstr "Sorter efter Gns. Max CTR"

#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:180
msgid "Sort by Searches"
msgstr "Sorter efter Søgninger"

#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:195
msgid "Sort by AI SoV"
msgstr "Sorter efter AI Synlighed"

#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:207
msgid "Traf. val."
msgstr "Traf. vær."

#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:210
msgid "Sort by Traffic value"
msgstr "Sorter efter Trafikværdi"

#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:225
#: src/Pages/Keywords/Table/keydisCells.ts:137
msgid "Sort by Above the fold"
msgstr "Sorter efter Over Folden"

#: src/Pages/KeywordDiscovery/components/WordCloud/wordCloudTable.tsx:244
msgid "Loading Word Cloud…"
msgstr "Henter Ordsky…"

#: src/Pages/KeywordDiscovery/hooks.ts:108
msgid " is redirected to %s"
msgstr " er redirected til %s"

#: src/Pages/KeywordDiscovery/index.tsx:144
msgid "No results found for %s!"
msgstr "Ingen resultater fundet for %s!"

#: src/Pages/KeywordDiscovery/index.tsx:145
msgid "No results found for this domain!"
msgstr "Ingen resultater fundet for dette domæne!"

#: src/Pages/KeywordDiscovery/index.tsx:353
msgid "Word Cloud"
msgstr "Ordsky"

#: src/Pages/KeywordDiscovery/support/helpers.ts:23
msgid "… and %s Keywords"
msgstr "… og %s søgeord"

#: src/Pages/KeywordDiscovery/support/helpers.ts:24
msgid "… %s Keywords"
msgstr "… %s søgeord"

#: src/Pages/KeywordDiscovery/support/hooks/kpis.tsx:30
msgid ""
"No keywords found.\n"
" Try removing one or more filters."
msgstr ""
"Ingen søgeord fundet.\n"
" Prøv at fjerne en eller flere filtre."

#: src/Pages/KeywordOverusePage/components/OveruseChart/components/OveruseChartDescription.tsx:9
#: src/Pages/KeywordOverusePage/components/OveruseTable/OveruseTable.tsx:23
msgid "Daily keyword exceedings transactions"
msgstr "Daglige transaktioner for søgeord merforbrug"

#: src/Pages/KeywordOverusePage/components/OveruseChart/components/OveruseChartDescription.tsx:11
#: src/Pages/KeywordOverusePage/components/OveruseTable/OveruseTable.tsx:25
msgid "Choose your billing period."
msgstr "Vælg faktureringsperiode."

#: src/Pages/KeywordOverusePage/components/OveruseChart/OveruseChart.tsx:18
msgid "Last 14 days keyword usage history"
msgstr "Søgeord merforbrug de sidste 14 dage"

#: src/Pages/KeywordOverusePage/components/OveruseChart/support/helpers.ts:18
msgid "Today"
msgstr "I dag"

#: src/Pages/KeywordOverusePage/components/OveruseChart/support/helpers.ts:18
msgid "Tomorrow"
msgstr "I morgen"

#: src/Pages/KeywordOverusePage/components/OveruseChart/support/helpers.ts:120
msgid "Exceeded keywords, save money by upgrading"
msgstr "Søgeord merforbrug, spar penge ved at opgradere"

#: src/Pages/KeywordOverusePage/components/OveruseChart/support/helpers.ts:129
msgid "Exceeded keywords"
msgstr "Søgeord merforbrug"

#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/components/OveruseInfoPanelBlockList.tsx:52
msgid "Perm. roles"
msgstr "Perm. roles"

#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/OveruseInfoPanel.tsx:70
msgid ""
"You should upgrade your account to a [nextPlanSize] keywords plan, as it "
"will save you [amount] a year"
msgstr ""
"Du bør opgradere til en [nextPlanSize] søgeordsplan for en årlig besparelse "
"på [amount]"

#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/OveruseInfoPanel.tsx:87
msgid "Next plan is [nextPlanSize] keywords"
msgstr "Næste plan: [nextPlanSize] søgeord"

#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/OveruseInfoPanel.tsx:98
msgid "Keyword Price"
msgstr "Søgeordspris"

#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/OveruseInfoPanel.tsx:103
msgid "Current daily keyword price:"
msgstr "Aktuel pris for søgeord pr dag:"

#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/OveruseInfoPanel.tsx:118
msgid "Exceeded daily keyword price:"
msgstr "Daglig pris for søgeord merforbrug:"

#: src/Pages/KeywordOverusePage/components/OveruseInfoPanel/OveruseInfoPanel.tsx:148
msgid "Upgrade my plan"
msgstr "Opgrader mit abonnement"

#: src/Pages/KeywordOverusePage/components/OveruseTable/components/DateRange.tsx:37
msgid "Billing period"
msgstr "Faktureringsperiode"

#: src/Pages/KeywordOverusePage/components/OveruseTable/support/hooks.tsx:40
msgid "Failed to load data for overuse table."
msgstr "Kunne ikke indlæse data til tabellen."

#: src/Pages/KeywordOverusePage/components/OveruseTable/support/hooks.tsx:89
msgid "Exceeded keyword quantity"
msgstr "Antal brugte søgeord ud over planen"

#: src/Pages/KeywordOverusePage/components/OveruseTable/support/hooks.tsx:94
#: src/Pages/Sales/CreatePlan/SalesPlanChoicesTable/index.tsx:59
#: src/Pages/Sales/Plans/SalesPlansTable/support/hooks.tsx:73
msgid "Price"
msgstr "Pris"

#: src/Pages/Keywords/Competitors/components/CompetitorActionMenu.tsx:40
msgid "Add competitor"
msgstr "Tilføj konkurrent"

#: src/Pages/Keywords/Competitors/components/CompetitorChartContent/support/helpers.ts:37
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:324
#: src/Pages/Keywords/Competitors/support/constants.ts:146
msgid "1-3"
msgstr "1-3"

#: src/Pages/Keywords/Competitors/components/CompetitorChartContent/support/helpers.ts:41
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:353
#: src/Pages/Keywords/Competitors/support/constants.ts:165
msgid "4-10"
msgstr "4-10"

#: src/Pages/Keywords/Competitors/components/CompetitorChartContent/support/helpers.ts:45
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:382
#: src/Pages/Keywords/Competitors/support/constants.ts:184
msgid "11-20"
msgstr "11-20"

#: src/Pages/Keywords/Competitors/components/CompetitorChartContent/support/helpers.ts:49
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:411
#: src/Pages/Keywords/Competitors/support/constants.ts:203
msgid "21-50"
msgstr "21-50"

#: src/Pages/Keywords/Competitors/components/CompetitorChartContent/support/helpers.ts:53
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:441
#: src/Pages/Keywords/Competitors/support/constants.ts:222
msgid "51-100"
msgstr "51-100"

#: src/Pages/Keywords/Competitors/components/CompetitorChartContent/support/helpers.ts:57
#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:471
#: src/Pages/Keywords/Competitors/support/constants.ts:241
msgid "Unranked"
msgstr "Urangeret"

#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/components/SearchVolumeCell.tsx:43
msgid "Show historic search volume for this keyword"
msgstr "Vis historisk søgevolumen for dette søgeord"

#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/support/hooks.tsx:50
#: src/Pages/Keywords/Overview/components/OverviewNotifications/support/hooks/table.tsx:106
#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:216
#: src/Pages/Keywords/Table/keydisCells.ts:64
msgid "Sort by keyword"
msgstr "Sorter efter søgeord"

#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/support/hooks.tsx:54
#: src/Pages/Keywords/Table/keydisCells.ts:67
msgid "Filter keyword…"
msgstr "Filtrer søgeord…"

#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/support/hooks.tsx:75
#: src/Pages/Keywords/Overview/components/OverviewNotifications/support/hooks/table.tsx:56
#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:166
msgid "Sort by search type"
msgstr "Sorter efter søgetype"

#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/support/hooks.tsx:79
msgid "Filter search type…"
msgstr "Filtrer søgetype…"

#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/support/hooks.tsx:98
msgid "Sort by monthly searches"
msgstr "Sorter efter månedlig søgevolumen"

#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/support/hooks.tsx:103
#: src/Pages/Keywords/Table/keydisCells.ts:170
msgid "Filter searches…"
msgstr "Filtrer søgninger…"

#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/support/hooks.tsx:114
#: src/Pages/Keywords/Overview/components/OverviewNotifications/support/hooks/table.tsx:115
#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:226
#: src/Pages/Keywords/Table/keydisCells.ts:121
msgid "Sort by rank"
msgstr "Sorter efter rank"

#: src/Pages/Keywords/Competitors/components/CompetitorsRanksTable/support/hooks.tsx:119
#: src/Pages/Keywords/Table/keydisCells.ts:125
msgid "Filter rank…"
msgstr "Filtrer placering…"

#: src/Pages/Keywords/Competitors/components/CompetitorsTable.tsx:58
msgid "Competitor Statistics"
msgstr "Konkurrentstatestik"

#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:55
msgid "No value for this date."
msgstr "Ingen værdi for denne dato."

#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:116
msgid "Competitor"
msgstr "Konkurrent"

#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:121
msgid "Competitor Info"
msgstr "Konkurrentinfo"

#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:149
msgid "AI Share of Voice for this competitor"
msgstr "AI Synlighed for denne konkurrent"

#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:159
msgid "No AI SoV for this date."
msgstr "Ingen AI synlighed for denne dato."

#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:173
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:835
msgid "Change in AI Share of Voice between the two compared dates"
msgstr "Ændring i AI synlighed mellem de to sammenlignede datoer"

#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:192
msgid "Average CTR for this competitor"
msgstr "Gennemsnitlig CTR for denne konkurrent"

#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:218
msgid "Change in CTR between the two compared dates"
msgstr "Ændring i CTR mellem de to sammenlignede datoer"

#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:237
msgid "Share of Voice for this competitor"
msgstr "Synlighed for denne konkurrent"

#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:291
msgid "Avg. Rank"
msgstr "Gns. Placering"

#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:296
msgid "Average rank of keywords for this competitor"
msgstr "Gennemsnitlig søgeordsplacering for denne konkurrent"

#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:311
msgid "Change in average rank of keywords between the two compared dates"
msgstr ""
"Ændring i gennemsnitlig søgeordsplacering mellem de to sammenlignede datoer"

#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:553
msgid "Refreshing competitors…"
msgstr "Opdaterer konkurrenter…"

#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:554
msgid "Loading competitors…"
msgstr "Henter konkurrenter…"

#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:556
msgid "Loading competitor [from] to [to] of [total] competitors"
msgstr "Henter konkurrent [from] til [to] af [total] konkurrenter"

#: src/Pages/Keywords/Competitors/hooks/useCompetitorsTableInfo.tsx:562
msgid "Competitor [from] to [to] of [total] competitors"
msgstr "Konkurrent [from] til [to] af [total] konkurrenter"

#: src/Pages/Keywords/Competitors/support/actions.ts:17
msgid "Could not delete competitor"
msgstr "Kunne ikke slette konkurrent"

#: src/Pages/Keywords/Competitors/support/actions.ts:47
msgid "Competitor deleted"
msgstr "Konkurrent slettet"

#: src/Pages/Keywords/Competitors/support/actions.ts:71
msgid "Delete Competitor?"
msgstr "Slet konkurrent?"

#: src/Pages/Keywords/Competitors/support/actions.ts:72
msgid "The competitor and its historic data will be permanently deleted."
msgstr "Konkurrenten og dens historiske data bliver permanent slettet."

#: src/Pages/Keywords/Competitors/support/actions.ts:74
msgid "Delete competitor"
msgstr "Slet konkurrent"

#: src/Pages/Keywords/Competitors/support/constants.ts:86
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:698
msgid "AI SoV +/-"
msgstr "AI Synlighed +/-"

#: src/Pages/Keywords/Competitors/support/constants.ts:121
#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:764
msgid "SoV +/-"
msgstr "Synlighed +/-"

#: src/Pages/Keywords/Competitors/support/constants.ts:155
msgid "1-3 +/-"
msgstr "1-3 +/-"

#: src/Pages/Keywords/Competitors/support/constants.ts:174
msgid "4-10 +/-"
msgstr "4-10 +/-"

#: src/Pages/Keywords/Competitors/support/constants.ts:193
msgid "11-20 +/-"
msgstr "11-20 +/-"

#: src/Pages/Keywords/Competitors/support/constants.ts:212
msgid "21-50 +/-"
msgstr "21-50 +/-"

#: src/Pages/Keywords/Competitors/support/constants.ts:231
msgid "51-100 +/-"
msgstr "51-100 +/-"

#: src/Pages/Keywords/Competitors/support/constants.ts:250
msgid "Unranked +/-"
msgstr "Urangeret +/-"

#: src/Pages/Keywords/Groupings/EditMode/components/DeleteFolderModal.tsx:20
msgid "Delete folder"
msgstr "Slet mappe"

#: src/Pages/Keywords/Groupings/EditMode/components/DeleteFolderModal.tsx:20
msgid "Delete tag"
msgstr "Slet tag"

#: src/Pages/Keywords/Groupings/EditMode/components/DeleteFolderModal.tsx:23
msgid ""
"You are about to delete the folder \"[folderName]\". All folders and tags "
"within this folder will be moved to the folder \"Uncategorized\"."
msgstr ""
"Du er ved at slette mappen \"[folderName]\". Alle mapper og tags i denne "
"mappe vil blive flyttet til mappen \"Ukategoriseret\"."

#: src/Pages/Keywords/Groupings/EditMode/components/DeleteFolderModal.tsx:27
msgid ""
"You are about to delete the tag \"[tagName]\". The tag will no longer appear "
"on any of your keywords."
msgstr ""
"Du er ved at slette tagget \"[tagName]\". Tagget vil ikke længere fremgå på "
"dine søgeord."

#: src/Pages/Keywords/Groupings/EditMode/components/EditModeEmptyContent.tsx:24
#: src/Pages/Keywords/Groupings/EditMode/components/EditModeEmptyContent.tsx:31
#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingsEmptyContent.tsx:27
#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingsEmptyContent.tsx:34
msgid "No tags or folders found"
msgstr "Ingen tags eller mapper fundet"

#: src/Pages/Keywords/Groupings/EditMode/components/EditModeEmptyContent.tsx:27
msgid "We found no tags or folders to show for specified search."
msgstr "Vi fandt ingen tags eller mapper at vise for denne søgning."

#: src/Pages/Keywords/Groupings/EditMode/components/EditModeEmptyContent.tsx:28
#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingsEmptyContent.tsx:31
msgid "We found no tags or folders to show for this domain."
msgstr "Vi fandt ingen tags eller mapper at vise for dette domæne."

#: src/Pages/Keywords/Groupings/EditMode/components/EditModeEmptyContent.tsx:32
#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingsEmptyContent.tsx:35
msgid ""
"We found no tags or folders to show, this could be due to your active "
"filters. Clear your filters and try again."
msgstr ""
"Vi fandt ingen tags eller mapper at vise. Det kan skyldes dine aktive "
"filtre. Nulstil filtrene og prøv igen."

#: src/Pages/Keywords/Groupings/EditMode/components/EditModeHeader/components/ClickToAddFolderButton.tsx:34
msgid "Click to add folder..."
msgstr "Klik for at tilføje en mappe..."

#: src/Pages/Keywords/Groupings/EditMode/components/EditModeHeader/components/MoveGroupModal.tsx:38
#: src/Pages/Keywords/Groupings/EditMode/components/EditModeHeader/components/MoveGroupModal.tsx:40
msgid "Root level"
msgstr "Rodniveau"

#: src/Pages/Keywords/Groupings/EditMode/components/EditModeHeader/components/MoveGroupModal.tsx:44
msgid "Move selected to"
msgstr "Flyt valgte til"

#: src/Pages/Keywords/Groupings/EditMode/components/EditModeHeader/components/MoveGroupModal.tsx:59
msgid "Move"
msgstr "Flyt"

#: src/Pages/Keywords/Groupings/EditMode/components/EditModeHeader/components/MoveSelectedNodesAction.tsx:16
msgid "Clear selection "
msgstr "Ryd valgte "

#: src/Pages/Keywords/Groupings/EditMode/components/EditModeHeader/components/MoveSelectedNodesAction.tsx:26
msgid "Move (%s) selected"
msgstr "Flyt (%s) valgte"

#: src/Pages/Keywords/Groupings/EditMode/components/EditModeHeader/components/TableWrapperGroupEdit.tsx:13
msgid "Edit mode"
msgstr "Redigeringstilstand"

#: src/Pages/Keywords/Groupings/EditMode/components/EditModeHeader/components/TableWrapperGroupEdit.tsx:18
msgid "You have unsaved changes"
msgstr "Du har ikke-gemte ændringer"

#: src/Pages/Keywords/Groupings/EditMode/components/EditModeHeader/components/TableWrapperGroupEdit.tsx:22
msgid "Drag and drop or select multiple folders/tags and \"Move (x) selected\""
msgstr "Træk og slip eller vælg flere mapper/tags og klik \"Flyt (x) valgte\""

#: src/Pages/Keywords/Groupings/EditMode/components/GroupEditActions.tsx:22
msgid "*Please note that filters are not applied while organizing folders."
msgstr ""
"*Vær opmærksom på at filtre ikke bliver anvendt når du organiserer mapper."

#: src/Pages/Keywords/Groupings/EditMode/components/GroupEditActions.tsx:33
msgid "Saving…"
msgstr "Gemmer…"

#: src/Pages/Keywords/Groupings/EditMode/components/NodeItem/EditNodeInput.tsx:33
msgid "Can't add folder with empty name"
msgstr "Kan ikke tilføje mappe med et tomt navn"

#: src/Pages/Keywords/Groupings/EditMode/components/NodeItem/EditNodeInput.tsx:34
msgid "Can't update tag with empty name"
msgstr "Kan ikke opdatere tag med et tomt navn"

#: src/Pages/Keywords/Groupings/EditMode/components/NodeItem/EditNodeInput.tsx:46
msgid "Provided name is already used in this directory."
msgstr "Navnet er allerede i brug i denne mappe."

#: src/Pages/Keywords/Groupings/EditMode/components/NodeItem/EditNodeInput.tsx:52
msgid "is not an allowed character"
msgstr "er ikke en tilladt karakter"

#: src/Pages/Keywords/Groupings/EditMode/components/NodeItem/NodeItem.tsx:234
msgid "Move (%s) here"
msgstr "Flyt (%s) hertil"

#: src/Pages/Keywords/Groupings/EditMode/components/NodeItem/NodeItem.tsx:258
msgid "Uncategorized folder cannot be deleted"
msgstr "Den ukategoriserede mappe kan ikke slettes"

#: src/Pages/Keywords/Groupings/EditMode/index.tsx:58
msgid "You have unsaved changes. Are you sure you want to leave?"
msgstr "Du har ikke-gemte ændringer. Er du sikker på du vil forlade siden?"

#: src/Pages/Keywords/Groupings/EditMode/support/helpers.tsx:16
msgid "Add Folder"
msgstr "Tilføj Mappe"

#: src/Pages/Keywords/Groupings/EditMode/support/hooks/useGroupData.ts:19
#: src/Pages/Keywords/Groupings/EditMode/support/hooks/useGroupData.ts:31
msgid "Failed! Please try again, and contact support if the problem persists."
msgstr "Handling fejlede. Prøv igen, eller kontakt supporten."

#: src/Pages/Keywords/Groupings/HistoryGraph/foldersGraph.tsx:46
msgid "Tag and Folder Comparison"
msgstr "Tag og mappe sammenligning"

#: src/Pages/Keywords/Groupings/HistoryGraph/foldersGraph.tsx:53
msgid "Compare selected by"
msgstr "Sammenlign valgte på"

#: src/Pages/Keywords/Groupings/HistoryGraph/foldersGraph.tsx:62
msgid ""
"The selected items contains no comparable data. [CloseTheModal] and select a "
"different set of folders or tags to compare."
msgstr ""
"De valgte elementer indeholder ingen sammenlignelige data. [CloseTheModal] "
"og vælg et andet sæt mapper eller tags at sammenligne."

#: src/Pages/Keywords/Groupings/HistoryGraph/foldersGraph.tsx:68
msgid "Close the modal"
msgstr "Luk modalen"

#: src/Pages/Keywords/Groupings/support/constants.ts:80
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:236
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:238
msgid "Market position"
msgstr "Markedsposition"

#: src/Pages/Keywords/Groupings/support/constants.ts:140
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:608
#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:282
msgid "Intent"
msgstr "Intention"

#: src/Pages/Keywords/Groupings/support/constants.ts:146
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:615
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:617
#: src/Pages/Keywords/Overview/components/WinnersLosers/index.tsx:53
#: src/Pages/Keywords/Overview/components/WinnersLosers/index.tsx:93
#: src/Pages/Keywords/Overview/components/WinnersLosers/WinnerLosersBar.tsx:71
msgid "Winners"
msgstr "Vindere"

#: src/Pages/Keywords/Groupings/support/constants.ts:151
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:633
#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:635
#: src/Pages/Keywords/Overview/components/WinnersLosers/index.tsx:54
#: src/Pages/Keywords/Overview/components/WinnersLosers/index.tsx:101
#: src/Pages/Keywords/Overview/components/WinnersLosers/WinnerLosersBar.tsx:80
msgid "Losers"
msgstr "Tabere"

#: src/Pages/Keywords/Groupings/support/constants.ts:158
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/RankingDistributionCell.tsx:84
msgid "Ranking distribution"
msgstr "Placeringsfordeling"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/CompetitorPositionCell.tsx:24
msgid "Your position (click to show more)"
msgstr "Din position (klik for at se mere)"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/CompetitorPositionCell.tsx:25
msgid "Your dynamic competitor score is 0 (click to show more)"
msgstr "Din dynamiske konkurrentscore er 0 (klik for at vise mere)"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/CompetitorPositionDropdownCell.tsx:64
msgid "Dynamic competitor score"
msgstr "Dynamisk konkurrentscore"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/DiscoveredKeywordsCell.tsx:78
msgid "Not available for groups"
msgstr "Ikke tilgængelig for grupper"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/DiscoveredKeywordsCell.tsx:78
msgid "Only available for dynamic tags"
msgstr "Kun tilgængelig for dynamiske tags"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/DiscoveredKeywordsCell.tsx:80
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/DiscoveredKeywordsCell.tsx:93
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/ValueAndIndicatorCell.tsx:60
msgid "N/A"
msgstr "N/A"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/DiscoveredKeywordsCell.tsx:93
msgid "Go to discovery"
msgstr "Gå til discovery"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/FoldersMaxCtrCell.tsx:61
#: src/Pages/Keywords/Groupings/ViewMode/components/cells/FoldersMaxCtrCell.tsx:84
msgid "Rank "
msgstr "Placering "

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/FoldersMaxCtrCell.tsx:94
msgid "Average CTR by rank"
msgstr "Gennemsnits CTR efter placering"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/FoldersMaxCtrCell.tsx:109
msgid "Show average CTR by rank"
msgstr "Vis gennemsnits CTR efter placering"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/index.tsx:64
msgid "No keywords to compare"
msgstr "Ingen søgeord at sammenligne"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/index.tsx:100
#: src/Pages/SiteMapping/components/TreeTable/RowContent.tsx:88
msgid "toggle row closed"
msgstr "kollaps række"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/index.tsx:100
#: src/Pages/SiteMapping/components/TreeTable/RowContent.tsx:88
msgid "toggle row open"
msgstr "åben række"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/index.tsx:122
msgid "You can only add folders in edit mode. Do you want to enter edit mode?"
msgstr ""
"Du kan kun oprette mapper i redigeringstilstand. Vil du gå i "
"redigeringstilstand?"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/PathCell/index.tsx:144
msgid "Go to dashboard list and filter by tag"
msgstr "Gå til dashboard og filtrer efter tag"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/RankingDistributionCell.tsx:12
msgid "Show ranking distribution"
msgstr "Vis placeringsfordeling"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/SearchIntentCell.tsx:33
msgid "Show Search Intent distribution"
msgstr "Vis søgeintentionsfordeling"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/SearchIntentCell.tsx:55
msgid "Search intent count"
msgstr "Søgeintention optælling"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/SERPCell.tsx:156
msgid "No [owned]SERP features found"
msgstr "Ingen [ejede]SERP-funktioner fundet"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/SERPCell.tsx:157
msgid "owned "
msgstr "ejet "

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/WinnersOrLosersCell.tsx:37
msgid "Go to keywords list and filter by winners"
msgstr "Gå til søgeordslisten og filtrer efter vindere"

#: src/Pages/Keywords/Groupings/ViewMode/components/cells/WinnersOrLosersCell.tsx:38
msgid "Go to keywords list and filter by losers"
msgstr "Gå til søgeordslisten og filtrer efter tabere"

#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingsBulkActions.tsx:68
msgid "Delete tags?"
msgstr "Slet tags?"

#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingsBulkActions.tsx:70
msgid ""
"The following tags will be permanently deleted and removed from all "
"keywords: [tags]"
msgstr "Disse tags slettes permanent og fjernes fra alle søgeord: [tags]"

#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingsBulkActions.tsx:100
msgid "Selected tags were deleted!"
msgstr "De valgte tags blev slettet!"

#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingsBulkActions.tsx:118
#: src/Pages/Keywords/Table/Actions/MoreRemoveActions/index.tsx:33
msgid "Delete tags"
msgstr "Slet tags"

#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingsBulkActions.tsx:135
msgid "Compare selected"
msgstr "Sammenlign valgte"

#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingsEmptyContent.tsx:30
msgid "We found no tags or folders to show for the specified search."
msgstr "Vi fandt ingen tags eller mapper at vise for denne søgning."

#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingViewModeActions.tsx:90
msgid "Edit folders"
msgstr "Rediger mapper"

#: src/Pages/Keywords/Groupings/ViewMode/components/GroupingViewModeActions.tsx:99
#: src/Pages/Layout/ActionsMenu/Actions/index.tsx:132
msgid "Download report"
msgstr "Hent rapport"

#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:129
msgid "Show history"
msgstr "Vis historik"

#: src/Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig.tsx:652
msgid "Rank dist."
msgstr "Placeringer"

#: src/Pages/Keywords/Groupings/ViewMode/ViewMode.tsx:90
msgid "Enter Edit Mode?"
msgstr "Gå i redigeringstilstand?"

#: src/Pages/Keywords/LandingPage/components/KeywordLandingChart/support/helpers.ts:21
#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/hooks.tsx:196
msgid "Revenue"
msgstr "Indtjening"

#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/components/LandingCostValueCell.tsx:14
msgid "Weighted avg. CPC: %s"
msgstr "Vægtet gns. CPC: %s"

#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/hooks.tsx:40
msgid "Go to Site Explorer and view landing page"
msgstr "Gå til Site Explorer og vis landingsside"

#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/hooks.tsx:71
msgid "No value found"
msgstr "Ingen værdi fundet"

#: src/Pages/Keywords/LandingPage/components/KeywordLandingTable/support/hooks.tsx:91
msgid "Path"
msgstr "Sti"

#: src/Pages/Keywords/Overview/chartSelectHelpers.ts:83
msgid ""
"Cannot save chart configuration due to duplicate charts. Duplicate charts: %s"
msgstr ""
"Kan ikke gemme graf konfiguration pga. duplikerede grafer. Duplikerede "
"grafer: %s"

#: src/Pages/Keywords/Overview/components/AddCharts/AddCharts.tsx:36
msgid "Available Widgets"
msgstr "Tilgængelige Widgets"

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:84
msgid "Ranking Distribution with table"
msgstr "Placeringsfordeling med tabel"

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:85
msgid "Local Ranking Distribution with table"
msgstr "Local Placeringsfordeling med tabel"

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:88
#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/ShareOfVoiceContainer.tsx:32
msgid "Share of Voice, by"
msgstr "Synlighed"

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:89
#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/ShareOfVoiceContainer.tsx:32
msgid "AI Share of Voice, by"
msgstr "AI Synlighed"

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:90
msgid "Dynamic Competitors, by"
msgstr "Dynamiske Konkurrenter"

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:91
#: src/Pages/Keywords/Overview/components/WinnersLosers/index.tsx:81
msgid "Top Winners/Losers"
msgstr "Vindere & Tabere"

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:92
#: src/Pages/Keywords/Overview/components/WinnersLosers/index.tsx:81
msgid "Top AI Winners/Losers"
msgstr "Top AI Vindere/Tabere"

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:93
msgid "Organic Traffic (GA/GSC)"
msgstr "Organisk trafik (GA/GSC)"

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:94
#: src/Pages/Keywords/Overview/components/SearchTrend/SearchTrend.tsx:101
#: src/Pages/Keywords/Overview/components/SearchTrendNeighborly/SearchTrendNeighborly.tsx:66
msgid "Search Trend"
msgstr "Søgetrend"

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:95
#: src/Pages/Keywords/Overview/components/SearchTrend/SearchTrend.tsx:101
#: src/Pages/Keywords/Overview/components/SearchTrendNeighborly/SearchTrendNeighborly.tsx:66
msgid "AI Search Trend"
msgstr "AI Søgetrend"

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:96
msgid "Search Trend with table"
msgstr "Søgetrend med tabel"

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:97
msgid "AI Search Trend with table"
msgstr "AI Søgetrend med tabel"

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:102
#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageBaseRank/AverageBaseRankChart.tsx:82
#: src/Pages/Keywords/Overview/components/KpiBar/Boxes/AverageBaseRank.tsx:19
msgid "Average Base Rank"
msgstr "Gns. Rå placering"

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:104
#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistribution/RankingDistributionChartContent.tsx:59
msgid "Base Ranking Distribution"
msgstr "Rå Placeringsfordeling"

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:143
msgid ""
"The total Share of Voice for the keywords in the current filtering over time."
msgstr ""
"Den totale synlighed for søgeordene i den nuværende filtrering over tid."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:146
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:79
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:136
msgid ""
"The average rank for all keywords in the current filtering and whether it's "
"trending upwards or downwards."
msgstr ""
"Den gennemsnitlige placering for alle søgeord i den nuværende filtrering den "
"har en opadgående eller nedadgående tendens."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:149
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:152
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:155
msgid ""
"The distribution of how ALL your ranks have developed over time, divided "
"into six specified rank ranges."
msgstr ""
"Fordelingen af, hvordan ALLE dine placeringer har udviklet sig over tid, "
"opdelt i seks specificerede rangintervaller."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:158
msgid "The relative size of you and your competitors in three dimensions."
msgstr "Den relative størrelse af dig og dine konkurrenter i tre dimensioner."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:161
msgid ""
"Categorizes all keywords in the current filtering by the primary purpose "
"behind the search."
msgstr ""
"Kategoriserer alle søgeord i den aktuelle filtrering efter det primære "
"formål med søgningen."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:164
msgid "Share of Voice aggregated by: tags, competitors, or landing pages."
msgstr "Synlighed aggregeret efter: tags, konkurrenter eller landing pages."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:167
msgid "AI Share of Voice aggregated by: tags, competitors, or landing pages."
msgstr "AI Synlighed aggregeret efter: tags, konkurrenter eller landing pages."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:170
msgid ""
"The biggest competitors for the keywords in the current filtering, analyzed "
"by score, score over time, or ranks."
msgstr ""
"De største konkurrenter for søgeord i den aktuelle filtrering, analyseret "
"efter score, score over tid eller rangordninger."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:173
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:176
msgid ""
"The keywords with the largest rank changes, categorized as ‘winners’ or "
"‘losers’ based on their movement."
msgstr ""
"Søgeord med de største placeringsændringer, kategoriseret som 'vindere' "
"eller 'tabere' baseret på deres bevægelse."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:179
msgid ""
"The historical evolution of traffic from search engines, registered by "
"Google Analytics and Google Search Console."
msgstr ""
"Den historiske udvikling af trafik fra søgemaskiner, registreret af Google "
"Analytics og Google Search Console."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:182
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:188
msgid "The combined Search Volume of all your keywords on a monthly basis."
msgstr "Den samlede Søgevolumen for alle dine søgeord på månedsbasis."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:185
#: src/Pages/Keywords/Overview/components/ChartBox.tsx:191
msgid "The combined AI Search Volume of all your keywords on a monthly basis."
msgstr "Den samlede AI Søgevolumen for alle dine søgeord på månedsbasis."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:194
msgid ""
"The average Click-through Rate (CTR) for the keywords in the current "
"filtering over time, weighted by their Search Volume."
msgstr ""
"Den gennemsnitlige click-through rate (CTR) for søgeord i den nuværende "
"filtrering over tid, vægtet med deres søgevolumen."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:197
msgid ""
"The AI Share of Voice for the keywords in the current filtering over time, "
"estimated based on a machine learning model."
msgstr ""
"AI Synlighed for søgeord i den nuværende filtrering over tid, udregnet "
"baseret på en maskinlæringsmodel."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:200
msgid ""
"The traffic value for the keywords in the current filtering over time, "
"estimated by multiplying the average CPC by SoV."
msgstr "Trafikværdien for søgeord i den nuværende filtrering over tid."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:203
msgid ""
"The average base rank over time for all keywords in the current filtering "
"before applying any keyword settings."
msgstr ""
"Den gennemsnitlige rå placering over tid for alle søgeord i den nuværende "
"filtrering før anvendelse af nogen nøgleordsindstillinger."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:206
msgid ""
"The traffic value for the keywords in the current filtering over time, "
"estimated by multiplying the average CPC by AI SoV."
msgstr ""
"Trafikværdien for nøgleordene i den aktuelle filtrering over tid, estimeret "
"ved at gange det gennemsnitlige CPC med AI SoV."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:209
msgid ""
"The distribution of how ALL your base ranks have developed over time, "
"divided into six specified rank ranges."
msgstr ""
"Fordelingen af, hvordan ALLE dine rå placeringer har udviklet sig over tid, "
"opdelt i seks specificerede rangintervaller."

#: src/Pages/Keywords/Overview/components/ChartBox.tsx:212
msgid ""
"The average distance in pixel from the top of the SERP to the keywords in "
"the current filtering over time."
msgstr ""
"Den gennemsnitlige afstand i pixel fra toppen af SERP til søgeord i den "
"aktuelle filtrering over tid."

#: src/Pages/Keywords/Overview/components/CompetitorsBubbleChart/hooks.ts:77
#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/components/ShareOfVoiceCompetitors.tsx:51
msgid "Competitor 1"
msgstr "Konkurrent 1"

#: src/Pages/Keywords/Overview/components/CompetitorsBubbleChart/hooks.ts:78
#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/components/ShareOfVoiceCompetitors.tsx:52
msgid "Competitor 2"
msgstr "Konkurrent 2"

#: src/Pages/Keywords/Overview/components/CompetitorsBubbleChart/hooks.ts:79
#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/components/ShareOfVoiceCompetitors.tsx:53
msgid "Competitor 3"
msgstr "Konkurrent 3"

#: src/Pages/Keywords/Overview/components/CompetitorsBubbleChart/index.tsx:38
msgid ""
"This chart displays the selected domains competitors' relative size in three "
"dimensions.[br][br]"
msgstr ""
"Dette diagram viser de udvalgte domæners konkurrenters relative størrelse i "
"tre dimensioner.[br][br]"

#: src/Pages/Keywords/Overview/components/CompetitorsBubbleChart/index.tsx:44
msgid ""
"This chart displays your and your competitors' relative size in three "
"dimensions.[br][br]"
msgstr ""
"Denne graf viser din og dine konkurrenters relative størrelse i tre "
"dimensioner.[br][br]"

#: src/Pages/Keywords/Overview/components/CompetitorsBubbleChart/index.tsx:52
msgid ""
"The keyword count on the x-axis shows the amount of keywords in the current "
"filtering where the domain is in the top 100."
msgstr ""
"Antal søgeord på x-aksen viser antallet af søgeord i den nuværende "
"filtrering, hvor domænet er i top 100."

#: src/Pages/Keywords/Overview/components/CompetitorsBubbleChart/index.tsx:57
msgid ""
"The search volume on the y-axis shows the total search volume for keywords "
"where the domain is in the top 100."
msgstr ""
"Søgevolumen på y-aksen viser den totale søgevolumen for søgeord hvor domænet "
"er i top 100."

#: src/Pages/Keywords/Overview/components/CompetitorsBubbleChart/index.tsx:61
msgid "The size of the bubbles indicates the Share of Voice for the domain."
msgstr "Størrelsen på circlerne angiver synligheden for domænet."

#: src/Pages/Keywords/Overview/components/CompetitorsBubbleChart/index.tsx:63
msgid ""
"Hover the bubbles to see the exact numbers. Click and drag to zoom. Toggle a "
"competitor on/off by clicking the competitor to the right."
msgstr ""
"Hold musen over cirklerne for at se præcise tal. Klik og træk for at zoome. "
"Slå en konkurrent til/fra ved at klikke på konkurrenten til højre."

#: src/Pages/Keywords/Overview/components/FiltersNoEffectBadge.tsx:6
msgid "This chart is unaffected by filters set in the filterbar"
msgstr "Denne graf bliver ikke påvirket af filtre sat i filterbaren"

#: src/Pages/Keywords/Overview/components/FiltersNoEffectBadge.tsx:7
msgid "Unaffected by filters"
msgstr "Upåvirket af filtre"

#: src/Pages/Keywords/Overview/components/GoogleAnalyticsTraffic/GoogleAnalyticsTraffic.tsx:159
#: src/Pages/Keywords/Overview/components/GoogleAnalyticsTraffic/GoogleAnalyticsTraffic.tsx:195
msgid "GSC (year before)"
msgstr "GSC (året før)"

#: src/Pages/Keywords/Overview/components/GoogleAnalyticsTraffic/GoogleAnalyticsTraffic.tsx:160
msgid "GSC"
msgstr "GSC"

#: src/Pages/Keywords/Overview/components/GoogleAnalyticsTraffic/GoogleAnalyticsTraffic.tsx:161
#: src/Pages/Keywords/Overview/components/GoogleAnalyticsTraffic/GoogleAnalyticsTraffic.tsx:211
msgid "GA (year before)"
msgstr "GA (året før)"

#: src/Pages/Keywords/Overview/components/GoogleAnalyticsTraffic/GoogleAnalyticsTraffic.tsx:162
msgid "GA"
msgstr "GA"

#: src/Pages/Keywords/Overview/components/GoogleAnalyticsTraffic/GoogleAnalyticsTraffic.tsx:224
msgid ""
"This chart shows the historical evolution of traffic from search engines "
"registered by Google Analytics (GA) and Google Search Console (GSC).[br]"
"[br]The data we fetch from GA/GSC is filtered to include only traffic from "
"the primary country associated with the domain.[br][br]The GA traffic "
"displayed is the number of users visiting from a search engine, while the "
"GSC traffic is the number of clicks registered through GSC.[br][br]We pull "
"this data directly from the GA/GSC APIs when you connect your account(s).[br]"
"[br]The data is for ALL your users/keywords in GA/GSC, not just the ones you "
"track. This also means the chart is unaffected by filters.[br][br]Hint: Try "
"to hover or click the different labels below the chart!"
msgstr ""
"Denne graf viser den historiske udvikling i trafikken fra søgemaskiner "
"registreret af Google Analytics (GA) og Google Search Console (GSC).[br]"
"[br]Data hentet fra GA/GSC bliver filtreret så det kun inkluderer trafik fra "
"det primære land associeret med domænet.[br][br]GA trafikken vi viser er "
"antal brugere der besøger siden fra en søgemaskine, mens GSC trafikken er "
"antallet af klik registeret gennem GSC.[br][br]Vi henter dette data direkte "
"fra GA/GSC gennem deres API når du forbinder din konto.[br][br]Data er for "
"alle brugere/søgeord, ikke kun de søgeord du tracker. Dette betyder at "
"grafen ikke bliver påvirket af filtre.[br][br]Hint: Prøv at hold musen over "
"eller klik på et label under grafen!"

#: src/Pages/Keywords/Overview/components/GoogleAnalyticsTraffic/GoogleAnalyticsTraffic.tsx:254
msgid "Organic Traffic"
msgstr "Organisk Trafik"

#: src/Pages/Keywords/Overview/components/HistoryCharts/AiTrafficValue/TrafficValue.tsx:56
msgid ""
"This chart shows the total AI traffic value for the keywords in the current "
"filtering."
msgstr ""
"Denne graf viser den totale AI trafikværdi for søgeordene med den nuværende "
"filtrering."

#: src/Pages/Keywords/Overview/components/HistoryCharts/AiTrafficValue/TrafficValue.tsx:57
msgid ""
"The AI traffic value for an individual keywords is estimated as the average "
"CPC (from Google Ads) multiplied by the AI Share of Voice for the keyword."
msgstr ""
"AI Trafikværdien for et søgeord er estimeret ud fra den gennemsnitlige CPC "
"(fra Google Ads) ganget med AI synligheden for søgeordet."

#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageBaseRank/AverageBaseRankChart.tsx:84
msgid ""
"The graph displays the average base rank over time for all keywords in the "
"current filtering. Only keywords with ranks in the top 100 are included in "
"the calculation."
msgstr ""
"Grafen viser den gennemsnitlige rå placering over tid for alle søgeord i den "
"nuværende filtrering. Kun søgeord med placering i top 100 er inkluderet i "
"beregningen."

#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageBaseRank/AverageBaseRankChart.tsx:109
#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageRank/AverageRankChartContent.tsx:50
msgid "Click to see ranking keywords"
msgstr "Klik for at se rangerende søgeord"

#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageCTR/AverageCtrChart.tsx:70
msgid "Click to see keywords with CTR greater than 0"
msgstr "Klik for at se søgeord med CTR større end 0"

#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageCTR/AverageCtrChart.tsx:80
msgid ""
"The average click-through rate (CTR) is the average CTR for your individual "
"keywords weighted by their search volume."
msgstr ""
"Den gennemsnitlige click-through rate (CTR) er den gennemsnitlige CTR for "
"dine enkelte søgeord vægtet med deres søgevolumen."

#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageCTR/AverageCtrChart.tsx:84
#: src/Pages/Keywords/Overview/components/HistoryCharts/EstimatedVisits/EstimatedVisits.tsx:84
msgid ""
"The CTR for the individual keywords is estimated based on a machine learning "
"model. Learn more"
msgstr ""
"CTR for et enkelt søgeord er estimeret baseret på en machine learning model. "
"Lær mere"

#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageRank/AverageRankChartContent.tsx:48
#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageRank/helpers.tsx:18
msgid "Average rank"
msgstr "Gennemsnitsplacering"

#: src/Pages/Keywords/Overview/components/HistoryCharts/AverageRank/AverageRankChartContent.tsx:108
msgid ""
"The graph displays the average rank over time for all keywords in the "
"current filtering. Only keywords with ranks in the top 100 are included in "
"the calculation."
msgstr ""
"Grafen viser den gennemsnitlige placering over tid for alle søgeord i den "
"nuværende filtrering. Kun søgeord med placering i top 100 er inkluderet i "
"beregningen."

#: src/Pages/Keywords/Overview/components/HistoryCharts/EstimatedVisits/EstimatedVisits.tsx:43
msgid "Click to see keywords with AI SoV greater than 0"
msgstr "Klik for at se søgeord med AI synlighed større end 0"

#: src/Pages/Keywords/Overview/components/HistoryCharts/EstimatedVisits/EstimatedVisits.tsx:80
msgid ""
"This chart shows the AI Share of Voice which is the sum of the CTR times the "
"search volume for all your keywords in the current filtering."
msgstr ""
"Denne graf viser AI synlighed, hvilket beregnes som summen af CTR gange "
"søgevolumen for alle søgeord i den nuværende filtrering."

#: src/Pages/Keywords/Overview/components/HistoryCharts/LocalPackRankNeighborly/Chart/index.tsx:128
#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistribution/Chart/index.tsx:128
#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/Chart/index.tsx:127
#: src/Pages/Keywords/Overview/components/StockTooltipContent/StockTooltipContent.tsx:90
msgid "Total keywords"
msgstr "Antal søgeord"

#: src/Pages/Keywords/Overview/components/HistoryCharts/LocalPackRankNeighborly/LocalRankingDistributionNeighborlyChartContent.tsx:60
msgid "Local Pack Ranking Distribution"
msgstr "Lokal Placeringsfordeling"

#: src/Pages/Keywords/Overview/components/HistoryCharts/LocalPackRankNeighborly/LocalRankingDistributionNeighborlyChartContent.tsx:67
msgid ""
"This graph shows the distribution of local pack ranks grouped into four "
"different groups: Rank 1, 2, 3 and greater than 3."
msgstr ""
"Denne graf viser fordelingen af lokale placeringer grupperet i fire "
"forskellige grupper: Placering 1, 2, 3 og større end 3.Denne graf viser "
"fordelingen af lokale placeringer grupperet i fire forskellige grupper: "
"Placering 1, 2, 3 og større end 3."

#: src/Pages/Keywords/Overview/components/HistoryCharts/LocalPackRankNeighborly/table.tsx:46
#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/table.tsx:34
msgid "Rank 1"
msgstr "Placering 1"

#: src/Pages/Keywords/Overview/components/HistoryCharts/LocalPackRankNeighborly/table.tsx:53
msgid "Rank 2"
msgstr "Placering 2"

#: src/Pages/Keywords/Overview/components/HistoryCharts/LocalPackRankNeighborly/table.tsx:60
msgid "Rank 3"
msgstr "Placering 3"

#: src/Pages/Keywords/Overview/components/HistoryCharts/LocalPackRankNeighborly/table.tsx:67
msgid "Greater than 3"
msgstr "Større end 3"

#: src/Pages/Keywords/Overview/components/HistoryCharts/LocalPackRankNeighborly/table.tsx:74
#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistribution/helpers.tsx:67
#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/Chart/helpers.tsx:39
#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/table.tsx:76
msgid "Not ranking"
msgstr "Ingen placering"

#: src/Pages/Keywords/Overview/components/HistoryCharts/LocalPackRankNeighborly/table.tsx:157
#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/table.tsx:163
#: src/Pages/Keywords/Overview/components/SearchTrendNeighborly/table.tsx:136
msgid "% Difference"
msgstr "% Forskel"

#: src/Pages/Keywords/Overview/components/HistoryCharts/LocalPackRankNeighborly/table.tsx:180
#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/table.tsx:195
#: src/Pages/Keywords/Overview/components/SearchTrendNeighborly/table.tsx:146
msgid "# Difference"
msgstr "# Forskel"

#: src/Pages/Keywords/Overview/components/HistoryCharts/PixelsFromTop/helpers.ts:11
#: src/Pages/Keywords/Overview/components/HistoryCharts/PixelsFromTop/PixelsFromTop.tsx:54
#: src/Pages/Keywords/Overview/components/HistoryCharts/PixelsFromTop/PixelsFromTop.tsx:86
msgid "Average Pixels From Top"
msgstr "Gns. Pixels fra Top"

#: src/Pages/Keywords/Overview/components/HistoryCharts/PixelsFromTop/PixelsFromTop.tsx:56
msgid ""
"This chart shows the average pixels from top for the keywords in the current "
"filtering."
msgstr ""
"Denne graf viser de gennemsnitlige pixels fra toppen dine placeringer er for "
"søgeordene med den nuværende filtrering."

#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistribution/RankingDistributionChartContent.tsx:66
msgid ""
"This graph shows the distribution of ranks grouped into six different rank "
"ranges: 1-3, 4-10, 11-20, 21-50, 50-100, and not ranking. [br]The graph "
"shows how ALL your ranks have developed over time."
msgstr ""
"Denne graf viser fordelingen af placeringer i seks forskellige "
"placeringsgrupper: 1-3, 4-10, 11-20, 21-50, 50-100 og udenfor top 100. "
"[br]Grafen viser hvordan alle dine placeringer har udviklet sig over tid."

#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/RankingDistributionNeighborlyChartContent.tsx:66
msgid ""
"This graph shows the distribution of ranks grouped into six different rank "
"ranges: 1, 2-5, 6-10, 11-20, 21-100, and not ranking. [br]The graph shows "
"how ALL your ranks have developed over time."
msgstr ""
"Denne graf viser fordelingen af placeringer i seks forskellige "
"placeringsgrupper: 1, 2-5, 6-10, 11-20, 21-100 og udenfor top 100. "
"[br]Grafen viser hvordan ALLE dine placeringer har udviklet sig over tid."
"placeringsgrupper: 1, 2-5, 6-10, 11-20, 21-100 og udenfor top 100. "
"[br]Grafen viser hvordan ALLE dine placeringer har udviklet sig over tid."

#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/table.tsx:41
msgid "Rank 2-5"
msgstr "Placering 2-5"

#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/table.tsx:48
msgid "Rank 6-10"
msgstr "Placering 6-10"

#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/table.tsx:55
msgid "Rank 1-10"
msgstr "Placering 1-10"

#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/table.tsx:62
msgid "Rank 11-20"
msgstr "Placering 11-20"

#: src/Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/table.tsx:69
msgid "Rank 21-100"
msgstr "Placering 21-100"

#: src/Pages/Keywords/Overview/components/HistoryCharts/ShareOfVoice/SovChart.tsx:73
msgid "Click to see keywords with SoV greater than 0"
msgstr "Klik for at se søgeord med synlighed større end 0"

#: src/Pages/Keywords/Overview/components/HistoryCharts/ShareOfVoice/SovChart.tsx:80
msgid ""
"Share of Voice (SoV) is an indicator of how your most important keywords are "
"performing."
msgstr ""
"Synlighed er en indikator for hvordan dine vigtigste søgeord performer."

#: src/Pages/Keywords/Overview/components/HistoryCharts/ShareOfVoice/SovChart.tsx:81
msgid ""
"All keywords that rank between positions 1 to 20 are used for the "
"calculation. The average CTR for the position is multiplied by the search "
"volume of each keyword to get the SoV."
msgstr ""
"Alle søgeord på med placering mellem 1- og 20 bruges til denne udregning. "
"Den gennemsnitlige CTR for den enkelte placering bliver ganget med "
"søgevolumen for at finde synligheden."

#: src/Pages/Keywords/Overview/components/HistoryCharts/ShareOfVoice/SovChart.tsx:84
msgid ""
"In this chart, you can see how the total SoV for your keywords in the "
"current filtering has changed over time."
msgstr ""
"I denne graf kan du se hvordan den totale synlighed for dine søgeord i den "
"nuværende filtrering har udviklet sig over tid."

#: src/Pages/Keywords/Overview/components/HistoryCharts/support/lowData.tsx:49
msgid "[b:Placeholder data][br]Actual data will appear here in 1-2 days."
msgstr "[b:Placeholder data][br]Faktisk data vises indenfor 1-2 dage."

#: src/Pages/Keywords/Overview/components/HistoryCharts/TrafficValue/TrafficValue.tsx:62
msgid ""
"This chart shows the total traffic value for the keywords in the current "
"filtering."
msgstr ""
"Denne graf viser den totale trafikværdi for søgeordene i den nuværende "
"filtrering."

#: src/Pages/Keywords/Overview/components/HistoryCharts/TrafficValue/TrafficValue.tsx:63
msgid ""
"The traffic value for an individual keywords is estimated as the average CPC "
"(from Google Ads) multiplied by the Share of Voice for the keyword."
msgstr ""
"Trafikværdien for et søgeord er estimeret ud fra den gennemsnitlige CPC (fra "
"Google Ads) ganget med synligheden for søgeordet."

#: src/Pages/Keywords/Overview/components/KebabMenu/index.tsx:92
msgid "Download Image"
msgstr "Gem Billede"

#: src/Pages/Keywords/Overview/components/KebabMenu/index.tsx:118
#: src/Pages/Keywords/Overview/components/KpiBar/components/KpiBoxDropdown.tsx:26
msgid "Remove from Dashboard"
msgstr "Fjern fra Dashboard"

#: src/Pages/Keywords/Overview/components/KpiBar/Boxes/AiOverview.tsx:20
msgid "AI Overview (owned)"
msgstr "AI Oversigt (ejet)"

#: src/Pages/Keywords/Overview/components/KpiBar/Boxes/AiOverview.tsx:26
msgid "Owned: "
msgstr "Ejet: "

#: src/Pages/Keywords/Overview/components/KpiBar/Boxes/PixelsFromTop.tsx:19
msgid "Avg. Pixels From Top"
msgstr "Gns. Pixels fra Top"

#: src/Pages/Keywords/Overview/components/KpiBar/components/AddKpiDropdown.tsx:22
msgid "Add KPI"
msgstr "Tilføj KPI"

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:76
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:133
msgid ""
"The amount of tracked keywords in the current filtering and whether it's "
"trending upwards or downwards."
msgstr ""
"Antallet af sporede søgeord i den aktuelle filtrering og om det har en "
"opadgående eller nedadgående tendens."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:82
msgid ""
"The Traffic Value for the keywords in the current filtering and whether it's "
"trending upwards or downwards. Estimated by multiplying the average CPC by "
"SoV."
msgstr ""
"Trafikværdien for søgeord i den aktuelle filtrering og om det har en "
"opadgående eller nedadgående tendens. Estimeret ved at gange den "
"gennemsnitlige CPC med synlighed."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:85
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:142
msgid ""
"The total Share of Voice for the keywords in the current filtering and "
"whether it's trending upwards or downwards."
msgstr ""
"Synligheden af søgeord i den aktuelle filtrering og om det har en opadgående "
"eller nedadgående tendens."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:88
msgid ""
"The total AI Share of Voice for the keywords in the current filtering it's "
"trend based on a machine learning model. Estimated based on a machine "
"learning model."
msgstr ""
"AI Synligheden af søgeord i den aktuelle filtrering og dens trend baseret på "
"en maskinlæringsmodel.Estimeret baseret på en maskinlæringsmodel."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:91
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:148
msgid ""
"The total number of keywords in the current filtering that is visible in the "
"search results without scrolling."
msgstr ""
"Det samlede antal søgeord i den aktuelle filtrering, der er synlige i "
"søgeresultaterne uden at scrolle."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:94
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:151
msgid ""
"The average Click-through Rate for the keywords in the current filtering and "
"it's trend, weighted by their Search Volume."
msgstr ""
"Den gennemsnitlige click-through rate (CTR) for søgeord i den aktuelle "
"filtrering, vægtet med deres søgevolumen."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:97
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:154
msgid ""
"The base rank for all keywords in the current filtering before applying any "
"keyword settings and it's trend."
msgstr ""
"Den rå placering for søgeord i den akutelle filtering før anvendelse af "
"nogen nøgleordsindstillinger."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:100
msgid ""
"The traffic value for the keywords in the current filtering and whether it's "
"trending upwards or downwards. Estimated by multiplying the average CPC by "
"AI SoV"
msgstr ""
"Trafikværdien for nøgleordene i den aktuelle filtrering og om den trender "
"opad eller nedad . Estimeret ved at gange det gennemsnitlige CPC med AI SoV"

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:103
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:160
msgid ""
"The average distance in pixel from the top of the SERP to the keywords in "
"the current filtering and it's trend."
msgstr ""
"Den gennemsnitlige afstand i pixel fra toppen af SERP til søgeordene i den "
"aktuelle filtrering over tid."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:106
msgid ""
"The Search Volume for the keywords in the current filtering and whether it "
"is trending upwards or downwards."
msgstr ""
"Søgevolumen for søgeordene i den nuværende filtrering og om det har en "
"opadgående eller nedadgående tendens."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:109
msgid ""
"The AI Search Volume for the keywords in the current filtering and whether "
"it is trending upwards or downwards."
msgstr ""
"AI Søgevolumen for søgeordene i den nuværende filtrering og om det har en "
"opadgående eller nedadgående tendens."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:112
#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:169
msgid ""
"The share of keywords for which the AI Overview SERP feature is owned, "
"relative to the total keywords that include this SERP feature"
msgstr ""
"Andelen af søgeord hvor AI Oversigt SERP funktionen er ejet, relativt til "
"det totale antale søgeord some indeholder denne SERP funktion"

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:115
msgid ""
"The number of keywords that are ranked number one on the first page.\n"
"\n"
"First page is defined as top 10 results."
msgstr ""
"Antallet af søgeord, der er placeret som nummer ét på den første side.\n"
"\n"
"Første side er defineret som de 10 øverste resultater."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:118
msgid ""
"The number of keywords that are ranked in the top three on the first page.\n"
"\n"
"First page is defined as top 10 results."
msgstr ""
"Antallet af søgeord, der er placeret i top 3 på den første side.\n"
"\n"
"Første side er defineret som de 10 øverste resultater."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:121
msgid ""
"The number of keywords that are ranked on the first page.\n"
"\n"
"First page is defined as top 10 results."
msgstr ""
"Antallet af søgeord, der er placeret på den første side.\n"
"\n"
"Første side er defineret som de 10 øverste resultater."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:124
msgid ""
"The number of keywords that have moved up in rankings since the comparison "
"date."
msgstr ""
"Antallet af søgeord, der er rykket op i placering siden sammenligningsdatoen."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:127
msgid ""
"The number of keywords that have moved down in rankings since the comparison "
"date."
msgstr ""
"Antallet af søgeord, der er rykket ned i placering siden "
"sammenligningsdatoen."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:139
msgid ""
"The Traffic Value for the keywords in the current filtering andwhether it's "
"trending upwards or downwards."
msgstr ""
"Trafikværdien for søgeord i den aktuelle filtrering og om det har en "
"opadgående eller nedadgående tendens."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:145
msgid ""
"The total AI Share of Voice for the keywords in the current filtering it's "
"trend based on a machine learning model."
msgstr ""
"AI Synligheden af søgeord i den aktuelle filtrering og dens trend baseret på "
"en maskinlæringsmodel."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:157
msgid ""
"The traffic value for the keywords in the current filtering and whether it's "
"trending upwards or downwards."
msgstr ""
"Denne graf viser den totale trafikværdi for søgeordene i den nuværende "
"filtrering og om det har en opadgående eller nedadgående tendens."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:163
msgid ""
"The search volume for the keywords in the current filtering and whether it "
"is trending upwards or downwards."
msgstr ""
"Søgevolumen for søgeordene i den nuværende filtrering og om det har en "
"opadgående eller nedadgående tendens."

#: src/Pages/Keywords/Overview/components/KpiBar/index.tsx:166
msgid ""
"The AI search volume for the keywords in the current filtering and whether "
"it is trending upwards or downwards."
msgstr ""
"AI søgevolumen for søgeordene i den nuværende filtrering og om det har en "
"opadgående eller nedadgående tendens."

#: src/Pages/Keywords/Overview/components/OverviewNotifications/OverviewNotifications.tsx:14
msgid ""
"View any keyword notifications for the keyword set. Messages are only "
"created for keywords that are starred. You can star a keyword from the "
"keyword list if you wish to follow them closely. "
msgstr ""
"Se søgeordsnotifikationer her. Notifikationer bliver lavet for søgeord der "
"er stjernemarkerede. Du kan stjernemarkere et søgeord i søgeordslisten hvis "
"du ønsker at følge det tæt. "

#: src/Pages/Keywords/Overview/components/OverviewNotifications/OverviewNotificationsTable.tsx:29
msgid "No notifications"
msgstr "Ingen notifikationer"

#: src/Pages/Keywords/Overview/components/OverviewNotifications/OverviewNotificationsTable.tsx:32
msgid "Learn more about notifications"
msgstr "Lær mere om notifikationer"

#: src/Pages/Keywords/Overview/components/OverviewNotifications/support/hooks/table.tsx:40
#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:148
msgid "Sort by domain"
msgstr "Sorter efter domæne"

#: src/Pages/Keywords/Overview/components/OverviewNotifications/support/hooks/table.tsx:76
#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:186
msgid "Sort by location"
msgstr "Sorter efter lokation"

#: src/Pages/Keywords/Overview/components/SearchIntent/SearchIntentChartContent.tsx:52
msgid ""
"Aggregated search intent for all keywords in the current filtering. The size "
"of the bar corresponds to the percentage of keywords in the current "
"filtering with the corresponding search intent."
msgstr ""
"Aggregeret søgeintention for alle søgeord i den nuværende filtrering. "
"Størrelsen på barene svarer til procentdelen af søgeord i den nuværende "
"filtrering med den tilsvarende søgeintention."

#: src/Pages/Keywords/Overview/components/SearchTrend/SearchTrend.tsx:107
#: src/Pages/Keywords/Overview/components/SearchTrendNeighborly/SearchTrendNeighborly.tsx:71
msgid ""
"See the combined [ai]search volume of all your current keywords on a monthly "
"basis from three years back to the most recent data.[br][br]Data for the "
"latest complete month will be available around the 15th of the following "
"month."
msgstr ""
"Se den kombinerede [ai]søgevolumen for alle dine nuværende søgeord på "
"månedsbasis fra tre år tilbage til de nyeste data.[br][br]Data for den "
"seneste komplette måned vil være tilgængelige omkring den 15. i den følgende "
"måned."

#: src/Pages/Keywords/Overview/components/SearchTypeSwitch/index.tsx:65
msgid "Filter by Desktop"
msgstr "Filtrer efter Desktop"

#: src/Pages/Keywords/Overview/components/SearchTypeSwitch/index.tsx:75
msgid "Filter by Mobile"
msgstr "Filtrer efter Mobil"

#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/components/ShareOfVoiceCompetitors.tsx:54
msgid "Competitor 4"
msgstr "Konkurrent 4"

#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/components/ShareOfVoiceLandingPages.tsx:80
msgid "Click to see your keywords with highest ranking page: "
msgstr "Klik for at se dine søgeord med landingsside: "

#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/components/ShareOfVoiceTags.tsx:94
msgid "Click to see your keywords with tag: "
msgstr "Klik for at se dine søgeord med tagget: "

#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/ShareOfVoiceContainer.tsx:34
msgid ""
"See your [shareOfVoiceText] aggregated by: tags, competitors, or landing "
"pages by clicking the corresponding tab. [br][br]You can click the labels to "
"toggle them on/off to get a better view of the relative size of the other "
"tags/competitors/landing pages.[br][br]In both the Tags and the Page tab, "
"you can click the bars to filter the data."
msgstr ""
"Se din [shareOfVoiceText] aggregeret over tags, konkurrenter eller "
"landingssider ved at klikke på det tilsvarende menupunkt.[br][br]Du kan "
"klikke på labels for at slå dem til/fra for at få et bedre overblik over den "
"relative størrelse af de andre tags/konkurrenter/landingssider.[br][br]Under "
"tags og landingssider kan du klikke på grafen for at filtrere data."

#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/ShareOfVoiceContainer.tsx:61
msgid "Page"
msgstr "Landingsside"

#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/support/helpers.ts:12
msgid "Other tags"
msgstr "Andre tags"

#: src/Pages/Keywords/Overview/components/ShareOfVoiceByType/support/helpers.ts:27
#: src/Pages/Keywords/SERP/SerpPieDistribution/index.tsx:49
msgid "Other"
msgstr "Andet"

#: src/Pages/Keywords/Overview/components/UnknownCompetitorTabs/index.tsx:62
msgid "Pin this domain as a static competitor"
msgstr "Pin dette domæne som en statisk konkurrent"

#: src/Pages/Keywords/Overview/components/UnknownCompetitorTabs/index.tsx:115
msgid ""
"See who your biggest competitors are based on their ranking performance on "
"your keywords in the current filtering. Apply any filter or segment to see "
"your competitors for the corresponding set of keywords![br][br][tab1][br]For "
"each keyword, each competitor gets a score between 0 and 10 based on their "
"rank. Rank 1 gets 10 points, rank 2 gets 9 points, and so on. The total "
"score is the sum of these scores for all keywords in the current filtering."
"[br][br][tab2][br]See which ranks each competitor has.[br][br][tab3][br]See "
"your competitors' relative dominance of the top SERP over time."
msgstr ""
"Se hvem der er dine største konkurrenter baseret på deres placeringer for "
"søgeord i den nuværende filtrering. Anvend et filter eller segment for at se "
"dine konkurrenter for det tilsvarende sæt af søgeord![br][br][tab1][br]For "
"hvert søgeord får hver konkurrent en score mellem 0 og 10 baseret på deres "
"placering. Placering 1 får 10 point, placering 2 får 9, osv. Den totale "
"score er summen for disse scores for alle søgeord i den nuværende filtrering."
"[br][br][tab2][br]Se hvilke placeringer hver konkurrent har.[br][br][tab3]"
"[br]Se dine konkurrenters relative scores over tid."

#: src/Pages/Keywords/Overview/components/UnknownCompetitorTabs/index.tsx:127
#: src/Pages/Keywords/Overview/components/UnknownCompetitorTabs/index.tsx:158
msgid "Score"
msgstr "Score"

#: src/Pages/Keywords/Overview/components/UnknownCompetitorTabs/index.tsx:128
#: src/Pages/Keywords/Overview/components/UnknownCompetitorTabs/index.tsx:160
msgid "Ranks"
msgstr "Placeringer"

#: src/Pages/Keywords/Overview/components/UnknownCompetitorTabs/index.tsx:129
#: src/Pages/Keywords/Overview/components/UnknownCompetitorTabs/index.tsx:159
msgid "Score over time"
msgstr "Score over tid"

#: src/Pages/Keywords/Overview/components/UnknownCompetitorTabs/index.tsx:149
msgid "Dynamic Top 10 Competitors"
msgstr "Dynamiske Top 10 konkurrenter"

#: src/Pages/Keywords/Overview/components/WinnersLosers/components/WinnerLoserBarLabel.tsx:95
msgid "See %s in keyword list"
msgstr "Se %s i søgeordslisten"

#: src/Pages/Keywords/Overview/components/WinnersLosers/components/WinnerLoserBarLabel.tsx:95
msgid "winners"
msgstr "vindere"

#: src/Pages/Keywords/Overview/components/WinnersLosers/components/WinnerLoserBarLabel.tsx:95
msgid "losers"
msgstr "tabere"

#: src/Pages/Keywords/Overview/components/WinnersLosers/index.tsx:42
msgid ""
"[tab1][br]Keywords with an increase in rank since the compare date.[br][br]"
"[tab2][br]Keywords with a decrease in rank since the compare date.[br][br]"
"[tab3][br]The date selected in the date picker in the top right of the page."
"[br][br][tab4][br]Click the Winners/Losers tab to see the corresponding "
"keywords in the table below, or click the icon next to the Winners or Losers "
"title to see the keywords in the keyword list."
msgstr ""
"[tab1][br]Søgeord med en stigning i placering siden sammenligningsdatoen.[br]"
"[br][tab2][br]Søgeord med et fald i placering siden sammenligningsdatoen.[br]"
"[br][tab3][br]Den dato, der er valgt i datovælgeren øverst til højre på "
"siden.[br][br][tab4][br]Klik på fanebladet Vindere/Tabere for at se de "
"tilsvarende søgeord i tabellen nedenfor, eller klik på ikonet ved siden af "
"titlen Vindere eller Tabere for at se søgeord i søgeordstabellen."

#: src/Pages/Keywords/Overview/components/WinnersLosers/index.tsx:55
msgid "Compare date:"
msgstr "Sammenligningsdato:"

#: src/Pages/Keywords/Overview/components/WinnersLosers/index.tsx:56
msgid "Hint:"
msgstr "Hint:"

#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:252
#: src/Pages/Keywords/Table/keydisCells.ts:228
msgid "Sort by AI Share of Voice"
msgstr "Sorter efter AI Synlighed"

#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:252
msgid "Sort by Share of Voice"
msgstr "Sorter efter Synlighed"

#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:263
msgid "Sort by change in AI SoV"
msgstr "Sorter efter ændring i AI Synlighed"

#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:263
msgid "Sort by change in SoV"
msgstr "Sorter efter ændring i Synlighed"

#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:274
#: src/Pages/Keywords/Table/keydisCells.ts:202
msgid "Sort by click-through rate"
msgstr "Sorter efter click-through rate"

#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:284
#: src/Pages/Keywords/Table/keydisCells.ts:107
msgid "Sort by search intent"
msgstr "Sorter efter søgeintention"

#: src/Pages/Keywords/Overview/components/WinnersLosers/support/hooks.tsx:299
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1122
msgid "Loading keywords…"
msgstr "Henter søgeord…"

#: src/Pages/Keywords/Overview/MissingDataOverlay.tsx:80
msgid ""
"Add domain(s) to the selected\n"
"group to see this chart"
msgstr ""
"Tilføj et domæne til den valgte\n"
"gruppe for a se denne graf"

#: src/Pages/Keywords/Overview/MissingDataOverlay.tsx:99
msgid "Add Tag"
msgstr "Tilføj tag"

#: src/Pages/Keywords/Overview/MissingDataOverlay.tsx:137
msgid "Add competitor(s) to see this chart"
msgstr "Tilføj konkurrenter for at se denne graf"

#: src/Pages/Keywords/Overview/MissingDataOverlay.tsx:138
msgid "Add some tags to see this chart"
msgstr "Tilføj tags for at se denne graf"

#: src/Pages/Keywords/Overview/MissingDataOverlay.tsx:140
msgid "Fetching data...[br]This might take a few minutes"
msgstr "Henter data...[br]Det kan tage et par minutter"

#: src/Pages/Keywords/Overview/MissingDataOverlay.tsx:144
msgid ""
"No domains in this group are connected [br] to Google Search Console or "
"Google Analytics"
msgstr ""
"Ingen domæner i denne gruppe er forbundet [br] til Google Search Console "
"eller Google Analytics"

#: src/Pages/Keywords/Overview/MissingDataOverlay.tsx:149
msgid ""
"Connect to Google Search Console or [br]  Google Analytics to see this chart"
msgstr ""
"Forbind til Google Search Console eller [br] Google Analytics for at se "
"denne graf"

#: src/Pages/Keywords/Overview/MissingDataOverlay.tsx:153
msgid "Add keywords to see this chart"
msgstr "Tilføj søgeord for at se denne graf"

#: src/Pages/Keywords/Overview/MissingDataOverlay.tsx:155
msgid "Upgrade your plan to enable this chart"
msgstr "Opgrader dit abonnement for at se denne graf"

#: src/Pages/Keywords/Overview/MissingDataOverlay.tsx:157
msgid ""
"We're collecting data for this chart.[br]Please check back later for updates."
msgstr ""
"Vi indsamler data til dette diagram.[br]Vend tilbage senere for opdateringer."

#: src/Pages/Keywords/Overview/MissingDataOverlay.tsx:162
msgid ""
"No AI Overview SERP features\n"
" found for keywords"
msgstr ""
"Ingen AI Oversigt SERP funktioner\n"
" fundet for søgeord"

#: src/Pages/Keywords/Overview/NextChartsDashboard/DraggableChartListV2/components/AddChartBox.tsx:56
msgid "Add widgets to dashboard"
msgstr "Tilføj widget til dashboard"

#: src/Pages/Keywords/Overview/NextChartsDashboard/DraggableChartListV2/components/AddChartBox.tsx:60
msgid "Add Widget"
msgstr "Tilføj Widget"

#: src/Pages/Keywords/SERP/Chart/support/useChartConfig.tsx:56
#: src/Pages/Keywords/SERP/FlippedChart/support/useFlippedChartConfig.tsx:41
msgid "%s keyword with %s (%s total keywords)"
msgid_plural "%s keywords with %s (%s total keywords)"
msgstr[0] "%s søgeord med %s (%s totalt)"
msgstr[1] "%s søgeord med %s (%s totalt)"

#: src/Pages/Keywords/SERP/Chart/support/useChartConfig.tsx:64
msgid "Click to view historic data."
msgstr "Klik for at se historisk data."

#: src/Pages/Keywords/SERP/Chart/support/useChartConfig.tsx:109
msgid "Not owned"
msgstr "Ikke ejet"

#: src/Pages/Keywords/SERP/Chart/support/useChartConfig.tsx:208
msgid "% owned"
msgstr "% ejet"

#: src/Pages/Keywords/SERP/ChartHistory/ChartHistoryChart.tsx:80
msgid "Owned percentage"
msgstr "Eget procentdel"

#: src/Pages/Keywords/SERP/ChartHistory/ChartHistoryChart.tsx:80
#: src/Pages/Keywords/SERP/ChartHistory/ChartHistoryChart.tsx:121
msgid "Total count"
msgstr "Samlet antal"

#: src/Pages/Keywords/SERP/ChartHistory/ChartHistoryChart.tsx:84
msgid ""
"You owned [ownedCount] out of [totalCount] available [serpFeature]."
"[lineBreak][lineBreak]Click to see keywords with [serpFeature] on this date."
msgstr ""
"Du ejede [ownedCount] ud af [totalCount] tilgængelige [serpFeature]."
"[lineBreak][lineBreak]Klik for at se søgeordene med [serpFeature] på denne "
"dato."

#: src/Pages/Keywords/SERP/ChartHistory/ChartHistoryChart.tsx:121
msgid "Percentage owned"
msgstr "Procentdel ejet"

#: src/Pages/Keywords/SERP/ChartHistory/index.tsx:45
msgid "See the changes over time for an individual SERP feature."
msgstr "Se ændringerne over tid for en individuel SERP-element."

#: src/Pages/Keywords/SERP/ChartHistory/index.tsx:47
msgid "All SERP Historical"
msgstr "Al SERP historik"

#: src/Pages/Keywords/SERP/ChartHistory/index.tsx:56
msgid "Historical"
msgstr "Historisk"

#: src/Pages/Keywords/SERP/ChartHistory/SerpOwnedSegmentedControl.tsx:26
msgid "You cannot own this SERP feature."
msgstr "Du kan ikke eje dette SERP element."

#: src/Pages/Keywords/SERP/FlippedChart/support/useFlippedChartConfig.tsx:161
msgid "Amount of keywords with SERP"
msgstr "Antal søgeord med SERP"

#: src/Pages/Keywords/SERP/index.tsx:252
msgid "Total amount of keywords for each SERP feature vs how many you own."
msgstr ""
"Samlet antal søgeord for hver SERP-element i forhold til hvor mange du ejer."

#: src/Pages/Keywords/SERP/index.tsx:254
msgid "SERP Feature Ownership"
msgstr "Ejerskab af SERP-elementer"

#: src/Pages/Keywords/SERP/index.tsx:279
msgid "Owned SERP Feature Distribution"
msgstr "Fordeling af ejede SERP-elementer"

#: src/Pages/Keywords/SERP/index.tsx:293
msgid ""
"For some SERP features like video carousels or recipes, there will be "
"multiple domains present. This chart shows your average \"inside SERP feature"
"\" rank, for each SERP feature where this is possible."
msgstr ""
"For nogle SERP-funktioner som videokarusseller eller opskrifter vil der være "
"flere domæner til stede. Dette diagram viser din gennemsnitlige \"inden for "
"SERP-funktion\" rangering for hver SERP-funktion, hvor dette er muligt."

#: src/Pages/Keywords/SERP/index.tsx:297
msgid "Average rank inside SERP feature"
msgstr "Gennemsnitlig rangering inden for SERP-funktion"

#: src/Pages/Keywords/SERP/index.tsx:303
msgid "Total SERP Feature Overview"
msgstr "Overblik over de totale SERP-elementer"

#: src/Pages/Keywords/SERP/RightRankChart/index.tsx:49
msgid ""
"There is no data to show for keywords in the selected filtering, as you have "
"never ranked inside any of the following SERP features: "
msgstr ""
"Der er ingen data at vise for søgeord i den valgte filtrering, da du aldrig "
"har rangeret inden for nogen af de følgende SERP-funktioner: "

#: src/Pages/Keywords/SERP/SerpPieDistribution/index.tsx:76
msgid "Total owned"
msgstr "Totalt ejet"

#: src/Pages/Keywords/Table/Actions/BulkActions.tsx:213
msgid "Compare [amount]"
msgstr "Sammenlign [amount]"

#: src/Pages/Keywords/Table/Actions/MoreActions/index.tsx:81
msgid "Add tags"
msgstr "Tilføj tags"

#: src/Pages/Keywords/Table/Actions/MoreActions/index.tsx:86
msgid "Add stars"
msgstr "Tilføj stjernemarkeringer"

#: src/Pages/Keywords/Table/Actions/MoreActions/index.tsx:96
msgid "Change preferred URL"
msgstr "Skift foretrukket URL"

#: src/Pages/Keywords/Table/Actions/MoreActions/index.tsx:101
msgid "Duplicate"
msgstr "Dupliker"

#: src/Pages/Keywords/Table/Actions/MoreActions/index.tsx:106
msgid "Move to other domain"
msgstr "Flyt til et andet domæne"

#: src/Pages/Keywords/Table/Actions/MoreActions/index.tsx:111
#: src/Pages/Layout/ActionsMenu/Actions/index.tsx:145
#: src/Pages/Sales/CreatePlan/CreatePlanSuccess/index.tsx:33
msgid "Copy to clipboard"
msgstr "Kopier til udklipsholder"

#: src/Pages/Keywords/Table/Actions/MoreRemoveActions/index.tsx:37
msgid "Delete stars"
msgstr "Slet stjernemarkeringer"

#: src/Pages/Keywords/Table/Actions/MoreRemoveActions/index.tsx:41
msgid "Delete keywords"
msgstr "Slet søgeord"

#: src/Pages/Keywords/Table/Chart/SearchVolume/index.tsx:193
msgid "Historic [ai]Search Volume for \"[keyword]\""
msgstr "Historisk [ai]søgevolume for \"[keyword]\""

#: src/Pages/Keywords/Table/Chart/SearchVolume/index.tsx:195
#: src/Pages/Keywords/Table/Chart/SearchVolume/index.tsx:197
msgid "AI "
msgstr "AI "

#: src/Pages/Keywords/Table/Chart/SearchVolume/index.tsx:197
msgid "Historic [ai]Search Volume"
msgstr "Historisk [ai]søgevolume"

#: src/Pages/Keywords/Table/Chart/SearchVolume/index.tsx:233
#: src/Pages/PublicReport/support/TableRankChart.tsx:82
msgid "Collapse expanded row"
msgstr "ammenfold udvidet række"

#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:279
msgid "Search type"
msgstr "Søgetype"

#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:371
msgid "Rank +/-"
msgstr "Placering +/-"

#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:403
msgid "Base Rank +/-"
msgstr "Rå Placering +/-"

#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:459
msgid "Local Pack Rank +/-"
msgstr "Lokal Placering +/-"

#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:490
msgid "AI Traffic Value +/-"
msgstr "AI Trafikværdi +/-"

#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:504
msgid "Max AI Traffic value"
msgstr "Max AI Trafikværdi"

#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:517
msgid "Max AI SoV"
msgstr "Maks AI Synlighed"

#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:543
msgid "Max SoV"
msgstr "Maks Synlighed"

#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:556
msgid "Top competitor"
msgstr "Top konkurrent"

#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:568
msgid "Pixels from top"
msgstr "Pixels fra Top"

#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:580
msgid "Pixels from top +/-"
msgstr "Pixels fra top +/-"

#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:650
msgid "CTR +/-"
msgstr "CTR +/-"

#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:796
msgid "Traffic Value +/-"
msgstr "Trafikværdi +/-"

#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:822
msgid "Created on"
msgstr "Oprettet den"

#: src/Pages/Keywords/Table/hooks/keyword/constants.ts:834
msgid "Age"
msgstr "Alder"

#: src/Pages/Keywords/Table/hooks/keyword/useTableKeyword.tsx:144
msgid "Failed to fetch keywords data"
msgstr "Kunne ikke hente søgeordsdata"

#: src/Pages/Keywords/Table/keydisCells.ts:110
msgid "Filter search intent…"
msgstr "Filtrer søgeintention…"

#: src/Pages/Keywords/Table/keydisCells.ts:153
msgid "Sort by url"
msgstr "Sorter efter url"

#: src/Pages/Keywords/Table/keydisCells.ts:156
msgid "Filter url…"
msgstr "Filtrer url…"

#: src/Pages/Keywords/Table/keydisCells.ts:167
msgid "Sort by average monthly search volume"
msgstr "Sorter efter gennemsnitlig månedlig søgevolumen"

#: src/Pages/Keywords/Table/keydisCells.ts:181
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:740
msgid "Monthly AI Search Volume"
msgstr "Månedlig AI søgevolume"

#: src/Pages/Keywords/Table/keydisCells.ts:190
msgid "Sort by average cost per click"
msgstr "Sorter efter gennemsnitlig pris per klik"

#: src/Pages/Keywords/Table/keydisCells.ts:213
msgid "Sort by max possible click-through rate"
msgstr "Sorter efter højest mulig click-through rate"

#: src/Pages/Keywords/Table/keydisCells.ts:217
msgid "Filter by CTR…"
msgstr "Filtrer efter CTR…"

#: src/Pages/Keywords/Table/keydisCells.ts:232
msgid "Filter by AI Share of Voice…"
msgstr "Filtrer AI Synlighed…"

#: src/Pages/Keywords/Table/keydisCells.ts:240
msgid "Comp"
msgstr "Konk."

#: src/Pages/Keywords/Table/keydisCells.ts:242
msgid "Google Ads competition for this keyword"
msgstr "Annoncekonkurrence for dette søgeord"

#: src/Pages/Keywords/Table/keydisCells.ts:252
msgid "Sort by share of voice"
msgstr "Sorter efter Synlighed"

#: src/Pages/Keywords/Table/keydisCells.ts:256
msgid "Filter change in SoV…"
msgstr "Filtrer ændring i Synlighed…"

#: src/Pages/Keywords/Table/keydisCells.ts:266
msgid "Sort by features on the SERP"
msgstr "Sorter efter SERP-element"

#: src/Pages/Keywords/Table/keydisCells.ts:274
msgid "Filter features on the SERP…"
msgstr "Filtrer SERP-elementer…"

#: src/Pages/Keywords/Table/KeywordsTable.tsx:57
msgid "No keywords found on your domain"
msgstr "Ingen søgeord fundet for dit domæne"

#: src/Pages/Keywords/Table/KeywordsTable.tsx:78
msgid "No keywords found"
msgstr "Ingen søgeord fundet"

#: src/Pages/Keywords/Table/KeywordsTable.tsx:79
msgid ""
"We found no keywords to show, this could be due to your active filters. "
"Clear your filters and try again."
msgstr ""
"Vi fandt ingen søge vi kan vise. Det kan skyldes dine aktive filtre. Nulstil "
"filtrene og prøv igen."

#: src/Pages/Keywords/Table/KeywordsToolbar.tsx:42
msgid "keyword selected"
msgstr "søgeord valgt"

#: src/Pages/Keywords/Table/KeywordsToolbar.tsx:43
msgid "keywords selected"
msgstr "søgeord valgt"

#: src/Pages/Keywords/Table/support/connectToGSCHovercard.tsx:54
msgid "Improve AI Search Volume"
msgstr "Forbedr AI Søgevolumen"

#: src/Pages/Keywords/Table/support/connectToGSCHovercard.tsx:61
msgid ""
"Connect to to Google Search console to improve the accuracy of the AI Search "
"Volume estimates next time they are updated (~15th of each month). Learn "
"more [link:here]."
msgstr ""
"Forbind til Google Search Console for at forbedre nøjagtigheden af AI "
"Søgevolumen-estimaterne næste gang, de opdateres (~15. hver måned). Læs mere "
"[link:her]."

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:189
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:190
msgid "Starred keywords"
msgstr "Stjernemarkeret søgeord"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:199
msgid "Filter starred keywords"
msgstr "Filtrer stjernemarkerede søgeord"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:321
msgid "Add filter for Search Intent"
msgstr "Tilføj filter for søgeintention"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:371
msgid "Add filter for Location"
msgstr "Tilføj filter for placering"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:443
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:469
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:490
msgid "Add filter for Base Rank"
msgstr "Tilføj filter for rå placering"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:445
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:471
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:492
msgid "Base rank column filters"
msgstr "Rå placeringskolonnefiltre"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:464
msgid "Change in Base Rank between the two compared dates"
msgstr "Ændring i rå placering mellem de to sammenlignede datoer"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:506
msgid "LPR"
msgstr "LR"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:512
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:538
msgid "Add filter for Local Pack Rank"
msgstr "Tilføj filter for lokal placering"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:514
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:540
msgid "Local pack rank column filters"
msgstr "Kolonnefiltre for lokal placering"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:533
msgid "Change in Local Pack Rank between the two compared dates"
msgstr "Ændring i lokal placering mellem de to sammenlignede datoer"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:542
msgid "LPR change"
msgstr "LPR ændring"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:559
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:583
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:603
msgid "Add filter for AI Traffic Value"
msgstr "Tilføj filter for AI trafikværdi"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:560
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:584
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:604
msgid "AI Traffic Value filters"
msgstr "AI Trafikværdifiltre"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:578
msgid "Change in AI Traffic Value Change between the two compared dates"
msgstr "Ændring i AI traffikværdi ændring mellem de to sammenlignede datoer"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:586
msgid "Change in AI Traffic Value"
msgstr "Ændring i AI Trafikværdi"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:627
msgid "Add filter for Top Competitor"
msgstr "Tilføj filter for top konkurrent"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:628
msgid "Top competitor filter"
msgstr "Top konkurrentfilter"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:641
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:660
msgid "Add filter for Pixels from Top"
msgstr "Tilføj filter for pixels fra toppen"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:655
msgid "Change in pixel from top between the two compared dates"
msgstr "Ændring i pixelposition fra toppen mellem de to sammenlignede datoer"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:662
msgid "Change in Pixels from Top"
msgstr "Ændring i Pixels fra Top"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:712
msgid "Monthly Search Volume"
msgstr "Månedlig Søgevolume"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:715
msgid "Filter searches"
msgstr "Filtrer søgninger"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:743
msgid "Filter AI Search Volume"
msgstr "Filtrer AI søgevolume"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:820
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:840
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:864
msgid "Add filter for AI SoV"
msgstr "Tilføj filter for AI synlighed"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:842
msgid "Change in AI Share of Voice"
msgstr "Ændring i AI synlighed"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:879
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:886
msgid "Average Cost per Click"
msgstr "Gennemsnitlig pris per klik"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:883
msgid "Add filter for average Cost per Click"
msgstr "Tilføj filter for gennemsnitspris per klik"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:895
msgid "Google Ads Competition"
msgstr "Google annoncekonkurrence"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:899
msgid "Filter Google Ads competition"
msgstr "Filterer annoncekonkurrence"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:914
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:933
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:955
msgid "Add filter for change in Share of Voice"
msgstr "Tilføj filter for ændring i synlighed"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:928
msgid "Change in Share of Voice between the two compared dates"
msgstr "Ændring i synlighed mellem de to sammenlignede datoer"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:976
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:994
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1013
msgid "Add filter for Traffic Value"
msgstr "Tilføj filter for trafikværdi"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:989
msgid "Change in Traffic Value between the two compared dates"
msgstr "Ændring i traffikværdi mellem de to sammenlignede datoer"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1037
msgid "Filter features on the SERP"
msgstr "Filtrer SERP-elementer"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1065
#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1067
msgid "Added On"
msgstr "Tilføjet"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1070
msgid "Filter date when the keyword was added"
msgstr "Filtrer efter dato hvor søgeordet blev tilføjet"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1119
msgid "Keywords have recently been imported so this list might be outdated."
msgstr ""
"Der er blevet importeret søgeord for nylig, så denne side er muligvis "
"forældet."

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1124
msgid "Loading keyword [from] to [to] of [total] keywords"
msgstr "Henter søgeord [from] til [to] af [total] søgeord"

#: src/Pages/Keywords/Table/useKeywordTableInfo.tsx:1130
msgid "Keyword [from] to [to] of [total] keywords"
msgstr "Søgeord [from] til [to] af [total] søgeord"

#: src/Pages/Layout/ActionsMenu/Actions/index.tsx:97
msgid ""
"You currently have a UA (Universal Analytics) connection. We recommend "
"upgrading to GA4 (Google Analytics 4)."
msgstr ""
"Du har i øjeblikket en UA (Universal Analytics) forbindelse. Vi anbefaler at "
"opgradere til GA4 (Google Analytics 4)."

#: src/Pages/Layout/DashboardTemplate/DemoDomainBanner/DemoDomainBanner.tsx:50
msgid "[domain] cannot be edited. You can edit your own domain [link]."
msgstr "[domæne] can ikke redigeres. Du kan redigere dit eget domæne [link]."

#: src/Pages/Layout/DashboardTemplate/DemoDomainBanner/DemoDomainBanner.tsx:61
#: src/Pages/Layout/DashboardTemplate/DemoDomainBanner/DemoDomainBanner.tsx:66
#: src/Pages/Layout/DashboardTemplate/DemoDomainBanner/DemoDomainBanner.tsx:71
msgid "These domains"
msgstr "Disse domæner"

#: src/Pages/Layout/DashboardTemplate/DemoDomainBanner/DemoDomainBanner.tsx:61
#: src/Pages/Layout/DashboardTemplate/DemoDomainBanner/DemoDomainBanner.tsx:66
#: src/Pages/Layout/DashboardTemplate/DemoDomainBanner/DemoDomainBanner.tsx:71
msgid "This domain"
msgstr "Dette domæne"

#: src/Pages/Layout/DashboardTemplate/DemoDomainBanner/DemoDomainBanner.tsx:64
msgid "[domain] cannot be edited. [link] to see your own domains"
msgstr "[domæne] kan ikke redigeres. [link] for at se dine egne domæner"

#: src/Pages/Layout/DashboardTemplate/DemoDomainBanner/DemoDomainBanner.tsx:65
#: src/Pages/Layout/DashboardTemplate/DemoDomainBanner/DemoDomainBanner.tsx:70
msgid "Click here"
msgstr "Klik her"

#: src/Pages/Layout/DashboardTemplate/DemoDomainBanner/DemoDomainBanner.tsx:69
msgid "[domain] cannot be edited. [link] to add your own domain."
msgstr "[domain] kan ikke redigeres. [link] for at tilføje dit eget domæne."

#: src/Pages/Layout/DashboardTemplate/DemoDomainBanner/DemoDomainBanner.tsx:79
msgid "Demo domains."
msgstr "Demo domæner."

#: src/Pages/Layout/DashboardTemplate/DemoDomainBanner/DemoDomainBanner.tsx:79
msgid "Demo domain."
msgstr "Demo domæne."

#: src/Pages/Layout/DashboardTemplate/DomainTabs/DomainTabs.tsx:50
msgid "Add the domain to access this tab"
msgstr "Tilføj domænet for at tilgå denne fane"

#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/NextGroupsAndDomains.tsx:76
msgid "All domains"
msgstr "Alle domæner"

#: src/Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/support/hooks.ts:218
#: src/Pages/PausedDomains/index.tsx:161
msgid "Domain deleted"
msgstr "Domæne slettet"

#: src/Pages/Layout/DashboardTemplate/SideNavbar/components/NavbarFooter/index.tsx:73
msgid "Grump is [grumpMood]"
msgstr "Grump er [grumpMood]"

#: src/Pages/Layout/DashboardTemplate/SideNavbar/components/NavbarHeader/index.tsx:54
msgid "Profile, Messages and more..."
msgstr "Profil, beskeder og mere..."

#: src/Pages/Layout/DashboardTemplate/SideNavbar/components/NavbarHeader/useMenuItems.tsx:49
#: src/Pages/Profile/Profile.tsx:22
msgid "Edit Profile"
msgstr "Rediger profil"

#: src/Pages/Layout/DashboardTemplate/SideNavbar/components/NavbarHeader/useMenuItems.tsx:54
msgid "API docs"
msgstr "API dokumentation"

#: src/Pages/Layout/DashboardTemplate/SideNavbar/components/NavbarHeader/useMenuItems.tsx:74
msgid "What's new?"
msgstr "Nye ændringer"

#: src/Pages/Layout/DashboardTemplate/SideNavbar/components/NavbarHeader/useMenuItems.tsx:80
msgid "Shortcuts"
msgstr "Genveje"

#: src/Pages/Layout/DashboardTemplate/SideNavbar/components/NavbarHeader/useMenuItems.tsx:85
msgid "Affiliate Program"
msgstr "Affiliateprogram"

#: src/Pages/Layout/DashboardTemplate/SideNavbar/components/NavbarHeader/useMenuItems.tsx:91
msgid "Sign Out"
msgstr "Log ud"

#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:39
msgid "Home"
msgstr "Hjem"

#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:62
#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/useKeywordNavItems.tsx:46
msgid "Discovery"
msgstr "Discovery"

#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:104
msgid "Account Settings"
msgstr "Kontoindstillinger"

#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:147
msgid "Keyword Usage"
msgstr "Søgeordsforbrug"

#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:199
msgid "Third-Party Integrations"
msgstr "Integration med tredjepart"

#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:207
msgid "API Docs"
msgstr "API-dokumentation"

#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems.tsx:230
msgid "File Formatting"
msgstr "Formatering af filer"

#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/useKeywordNavItems.tsx:48
msgid "Site Explorer"
msgstr "Site Explorer"

#: src/Pages/Layout/DashboardTemplate/SideNavbar/support/useKeywordNavItems.tsx:51
msgid "SERP Analysis"
msgstr "SERP Analyse"

#: src/Pages/Layout/DashboardTemplate/TopNavbar/TrialWarnings/CountDownToDate.tsx:15
msgid "%s days, %s hours, %s minutes, %s seconds"
msgstr "%s dage, %s timer, %s minutter, %s sekunder"

#: src/Pages/Layout/DashboardTemplate/TopNavbar/TrialWarnings/TrialWarnings.tsx:33
msgid "YOUR TRIAL EXPIRES IN"
msgstr "DIN PRØVEKONTO UDLØBER OM"

#: src/Pages/Layout/DashboardTemplate/TopNavbar/TrialWarnings/TrialWarnings.tsx:34
msgid "0 days"
msgstr "0 dage"

#: src/Pages/Layout/DashboardTemplate/TopNavbar/TrialWarnings/TrialWarnings.tsx:37
msgid "SELECT PLAN"
msgstr "VÆLG PLAN"

#: src/Pages/Layout/NavTop/index.tsx:39
msgid "Disable locale debug"
msgstr "Slå locale debugging fra"

#: src/Pages/Layout/NavTop/index.tsx:43
msgid "Enable locale debug"
msgstr "Slå locale debugging til"

#: src/Pages/NotesList/NotesTable/index.tsx:39
msgid "Did not find any notes."
msgstr "Fandt ingen noter."

#: src/Pages/NotesList/NotesTable/support/useNotesTableInfo.tsx:52
msgid "Could not delete note. Something went wrong, please try again."
msgstr "Kunne ikke slettet notatet. Noget gik galt, prøv venligst igen."

#: src/Pages/NotesList/NotesTable/support/useNotesTableInfo.tsx:56
msgid "Note deleted"
msgstr "Notat slettet"

#: src/Pages/NotesList/NotesTable/support/useNotesTableInfo.tsx:65
#: src/Pages/Sales/Organization/Components/NotesPanel/index.tsx:50
msgid "Delete Note?"
msgstr "Slet notat?"

#: src/Pages/NotesList/NotesTable/support/useNotesTableInfo.tsx:66
msgid "The note will be permanently deleted."
msgstr "Notatet bliver permanent slettet."

#: src/Pages/NotesList/NotesTable/support/useNotesTableInfo.tsx:67
#: src/Pages/NotesList/NotesTable/support/useNotesTableInfo.tsx:193
msgid "Delete note"
msgstr "Slet notat"

#: src/Pages/NotesList/NotesTable/support/useNotesTableInfo.tsx:131
msgid "Sort by note"
msgstr "Sorter efter note"

#: src/Pages/NotesList/NotesTable/support/useNotesTableInfo.tsx:144
msgid "Sort by creator"
msgstr "Sorter efter opretter"

#: src/Pages/NotesList/NotesTable/support/useNotesTableInfo.tsx:151
msgid "Created By"
msgstr "Oprettet af"

#: src/Pages/NotesList/NotesTable/support/useNotesTableInfo.tsx:158
msgid "Sort by keywords"
msgstr "Sorter efter søgeord"

#: src/Pages/PausedDomains/index.tsx:137
msgid "Domain unpaused"
msgstr "Domæne ikke længere pauset"

#: src/Pages/PausedDomains/index.tsx:193
msgid "Unpause"
msgstr "Genoptag"

#: src/Pages/PausedDomains/index.tsx:270
msgid "Paused At"
msgstr "Pause den"

#: src/Pages/PausedDomains/index.tsx:362
msgid "No Paused Domains"
msgstr "Ingen pausede domæner"

#: src/Pages/PausedDomains/index.tsx:363
msgid "There are currently no paused domains."
msgstr "Der er i øjeblikket ingen pausede domæner."

#: src/Pages/Profile/components/ChangePassword/index.tsx:32
msgid "Old password is incorrect"
msgstr "Gammel adgangskode er forkert"

#: src/Pages/Profile/components/ChangePassword/index.tsx:35
#: src/Pages/Profile/components/ChangePassword/index.tsx:42
msgid "Failed to change password, please contact support"
msgstr "Kunne ikke ændre adgangskoden, kontakt venligst support"

#: src/Pages/Profile/components/ChangePassword/index.tsx:38
msgid "Password changed"
msgstr "Kodeord ændret"

#: src/Pages/Profile/components/ChangePassword/index.tsx:56
msgid "Old password"
msgstr "Nuværende kodeord"

#: src/Pages/Profile/components/ChangePassword/index.tsx:57
msgid "Enter your old password"
msgstr "Indtast dit gamle kodeord"

#: src/Pages/Profile/components/ChangePassword/index.tsx:62
msgid "New password"
msgstr "Nyt kodeord"

#: src/Pages/Profile/components/ChangePassword/index.tsx:63
msgid "Enter your new password"
msgstr "Vælg dit nye kodeord"

#: src/Pages/Profile/components/ChangePassword/index.tsx:75
msgid "Confirm new password"
msgstr "Bekræft nyt kodeord"

#: src/Pages/Profile/components/ChangePassword/index.tsx:77
msgid "Confirm your new password"
msgstr "Bekræft nyt kodeord"

#: src/Pages/Profile/components/EditProfile/index.tsx:59
msgid "Competitors ranking"
msgstr "Konkurrentplaceringer"

#: src/Pages/Profile/components/EditProfile/index.tsx:63
msgid "Landing pages"
msgstr "Landingssider"

#: src/Pages/Profile/components/EditProfile/index.tsx:132
msgid "Profile saved"
msgstr "Profil gemt"

#: src/Pages/Profile/components/EditProfile/index.tsx:208
msgid "Select language"
msgstr "Vælg sprog"

#: src/Pages/Profile/components/EditProfile/index.tsx:216
msgid "Default keywords page"
msgstr "Standard søgeordsside"

#: src/Pages/Profile/components/EditProfile/index.tsx:217
msgid "Select page"
msgstr "Vælg side"

#: src/Pages/Profile/components/EditProfile/index.tsx:225
msgid "Default \"compare to\" parameter"
msgstr "Standard \"sammenlig med\" valg"

#: src/Pages/Profile/components/EditProfile/index.tsx:226
msgid "Select default comparer"
msgstr "Vælg standard sammenligning"

#: src/Pages/Profile/components/EditProfile/index.tsx:243
msgid "Two-Factor Authentication (2FA)"
msgstr "To-Faktor Autentificering (2FA)"

#: src/Pages/Profile/components/EditProfile/index.tsx:249
msgid "Permissions"
msgstr "Tilladelser"

#: src/Pages/Profile/components/EditProfile/index.tsx:262
msgid "Social Authentication"
msgstr "Social Login"

#: src/Pages/Profile/components/EditProfile/index.tsx:283
msgid "Enable development mode"
msgstr "Slå udviklingsmiljø til"

#: src/Pages/Profile/components/EditProfile/index.tsx:289
msgid "Disable present mode"
msgstr "Deaktiver præsentertilstand"

#: src/Pages/Profile/components/EditProfile/index.tsx:289
msgid "Enable present mode"
msgstr "Aktiver præsentertilstand"

#: src/Pages/Profile/components/EditProfile/two-factor.tsx:19
msgid "2FA is on for this account"
msgstr "2FA er aktiveret for denne konto"

#: src/Pages/Profile/components/EditProfile/two-factor.tsx:20
msgid "2FA is not configured for this account!"
msgstr "2FA er ikke konfigureret for denne konto!"

#: src/Pages/Profile/components/EditProfile/two-factor.tsx:21
msgid "Enable"
msgstr "Aktivér"

#: src/Pages/Profile/components/EditProfile/two-factor.tsx:27
msgid ""
"We recommend to always enable 2FA authentication for increased security."
msgstr "Vi anbefaler altid at aktivere 2FA for øget sikkerhed."

#: src/Pages/Profile/components/EditProfile/utils.tsx:63
msgid "Unable to disconnect [provider] account. Please try again later."
msgstr "Kunne ikke fjerne [provider] konto. Prøv venligst igen senere."

#: src/Pages/Profile/components/EditProfile/utils.tsx:77
msgid "Disconnect with [provider]"
msgstr "Fjern [provider] konto"

#: src/Pages/Profile/components/EditProfile/utils.tsx:78
#: src/Pages/Profile/components/EditProfile/utils.tsx:108
msgid "Connect with [provider]"
msgstr "Forbind med [provider]"

#: src/Pages/Profile/components/EditProfile/utils.tsx:107
msgid "Disconnect from [provider]"
msgstr "Fjern [provider] konto"

#: src/Pages/Profile/components/GoogleDriveAccount/index.tsx:43
msgid "Delete Google Drive Connection?"
msgstr "Slet Google Drive forbindelse?"

#: src/Pages/Profile/components/GoogleDriveAccount/index.tsx:44
msgid ""
"All scheduled reports that you have created and that use Google Drive will "
"be deleted."
msgstr ""
"Alle planlagte rapporter der benytter sig af dit Google Drive vil blive "
"slettet."

#: src/Pages/Profile/components/GoogleDriveAccount/index.tsx:48
msgid "Delete connection"
msgstr "Slet forbindelse"

#: src/Pages/Profile/components/GoogleDriveAccount/index.tsx:80
msgid "Google Drive Connection"
msgstr "Google Drive forbindelse"

#: src/Pages/Profile/components/GoogleDriveAccount/index.tsx:84
msgid "Connected"
msgstr "Forbundet"

#: src/Pages/Profile/components/GoogleDriveAccount/index.tsx:84
msgid "No connection"
msgstr "Ingen forbindelse"

#: src/Pages/Profile/components/GoogleDriveAccount/index.tsx:100
msgid "Remove connection"
msgstr "Fjern forbindelse"

#: src/Pages/Profile/components/IntegrationsApiToken/index.tsx:53
msgid "Refresh API Token?"
msgstr "Opdater API Token?"

#: src/Pages/Profile/components/IntegrationsApiToken/index.tsx:55
msgid ""
"Are you sure you want to refresh the token? \n"
" This will invalidate the current token."
msgstr ""
"Er du sikker på, at du vil opdatere tokenet?\n"
"Dette vil ugyldiggøre den aktuelle token."

#: src/Pages/Profile/components/IntegrationsApiToken/index.tsx:75
#: src/Pages/Profile/components/IntegrationsApiToken/index.tsx:89
msgid "Copy token to clipboard"
msgstr "Kopier token til udklipsholder"

#: src/Pages/Profile/components/IntegrationsApiToken/index.tsx:95
msgid "Refresh API token"
msgstr "Opdater API token"

#: src/Pages/Profile/components/IntegrationsApiToken/index.tsx:102
msgid "Click here to find more information about our API"
msgstr "Klik her for at læse mere om vores API"

#: src/Pages/PublicReport/ErrorFallback.tsx:43
msgid "Something Went Wrong..."
msgstr "Noget gik galt..."

#: src/Pages/PublicReport/ErrorFallback.tsx:48
msgid "An error occurred while creating the report"
msgstr "Der opstod en fejl under generering af rapporten"

#: src/Pages/PublicReport/ErrorFallback.tsx:50
msgid "Please contact support if this issue persists"
msgstr "Kontakt venligst vores support, hvis dette problem fortsætter"

#: src/Pages/PublicReport/ErrorFallback.tsx:59
msgid "Close support chat"
msgstr "Luk support-chatten"

#: src/Pages/PublicReport/Header.tsx:72
msgid "Copy sharable link to clipboard"
msgstr "Kopiér delbart link til udklipsholder"

#: src/Pages/PublicReport/Header.tsx:73
msgid "Copy sharable link with current table sorting to clipboard"
msgstr "Kopiér delbart link med den aktuelle tabelsortering til udklipsholder"

#: src/Pages/PublicReport/Header.tsx:80 src/Pages/PublicReport/Header.tsx:89
msgid "Go to AccuRanker dashboard"
msgstr "Gå til AccuRanker overblik"

#: src/Pages/PublicReport/Header.tsx:80 src/Pages/PublicReport/Header.tsx:89
msgid "Go to AccuRanker.com"
msgstr "Gå til AccuRanker.com"

#: src/Pages/PublicReport/Header.tsx:102
msgid "Copy link"
msgstr "Kopiér link"

#: src/Pages/PublicReport/Header.tsx:114
msgid "Copy link with sorting"
msgstr "Kopiér link med sortering"

#: src/Pages/PublicReport/Header.tsx:147
msgid "Keyword ranking report for %s"
msgstr "Søgeordsplaceringsrapport for %s"

#: src/Pages/PublicReport/publicKeywordsReportTable/index.tsx:59
msgid "No Keywords for this domain"
msgstr "Ingen søgeord for dette domæne"

#: src/Pages/PublicReport/publicKeywordsReportTable/index.tsx:60
msgid "There are currently no keywords added to this domain."
msgstr "Der er pt. ingen søgeord tilføjet til dette domæne."

#: src/Pages/PublicReport/publicKeywordsReportTable/SearchField.tsx:62
msgid "Search keywords"
msgstr "Søg søgeord"

#: src/Pages/PublicReport/publicKpiBar/Boxes/index.ts:18
msgid "Ranked 1st on Page 1"
msgstr "Placeret som no 1 på side 1"

#: src/Pages/PublicReport/publicKpiBar/Boxes/index.ts:19
msgid "Ranked in Top 3 on Page 1"
msgstr "Placeret i Top 3 på Side 1"

#: src/Pages/PublicReport/publicKpiBar/Boxes/index.ts:20
msgid "On Page 1"
msgstr "På side 1"

#: src/Pages/PublicReport/publicKpiBar/Boxes/index.ts:21
msgid "Moved Up in Rankings"
msgstr "Rykket Op i Placering"

#: src/Pages/PublicReport/publicKpiBar/Boxes/index.ts:22
msgid "Moved Down in Rankings"
msgstr "Rykket Ned i Placering"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:264
msgid "Failed to save report settings"
msgstr "Kunne ikke gemme rapportindstillinger"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:269
msgid "Settings saved!"
msgstr "Indstillinger gemt!"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:300
msgid "One Week Ago"
msgstr "En Uge Siden"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:301
msgid "One Month Ago"
msgstr "En Måned Siden"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:302
msgid "One Year Ago"
msgstr "Et År Siden"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:381
msgid "Report settings"
msgstr "Rapport settings"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:383
msgid "Presentation"
msgstr "Præsentation"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:386
#: src/Pages/PublicReport/publicReportSettings/index.tsx:405
msgid "Note:"
msgstr "Note:"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:389
msgid ""
"This editor is not visible to your clients. Clients will only see the final "
"presentation."
msgstr ""
"Denne editor er ikke synlig for dine kunder. Kunderne vil kun kunne se den "
"endelige rapport."

#: src/Pages/PublicReport/publicReportSettings/index.tsx:408
msgid ""
"These settings are not visible to your clients. Clients will see the report "
"based on your settings."
msgstr ""
"Disse indstillinger er ikke synlige for dine kunder. Kunderne vil se "
"rapporten baseret på dine indstillinger."

#: src/Pages/PublicReport/publicReportSettings/index.tsx:422
msgid "Earliest possible start date"
msgstr "Tidligst mulig startdato"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:423
msgid ""
"Sets a limit on the earliest selectable date for data filtering.\n"
"Data prior to this date will not be accessible."
msgstr ""
"Sætter en grænse for den tidligst valgbare dato for datafiltrering.\n"
"Data før denne dato vil ikke være tilgængelige."

#: src/Pages/PublicReport/publicReportSettings/index.tsx:429
msgid "Include charts in report"
msgstr "Inkluder diagrammer i rapporten"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:433
msgid "Average Rank chart"
msgstr "Gns. placeringsgraf"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:438
msgid "Ranking Distribution chart"
msgstr "Placeringsfordelingsgraf"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:443
msgid "Search Intent chart"
msgstr "Søgeintentionsgraf"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:451
msgid "Show basic KPI boxes"
msgstr "Vis grundlæggende KPI bokse"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:452
msgid "Show boxes with basic [KPIs] on the domains keyword rankings."
msgstr "Vis bokse med grundlæggende [KPIs] om domænets søgeordsplaceringer."

#: src/Pages/PublicReport/publicReportSettings/index.tsx:455
#: src/Pages/PublicReport/publicReportSettings/index.tsx:506
msgid "Key Performance Indicators"
msgstr "Nøglepræstationsindikatorer"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:502
msgid "Show advanced KPI boxes"
msgstr "Vis avancerede KPI bokse"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:503
msgid "Show boxes with advanced [KPIs] on the domains keyword rankings."
msgstr "Vis bokse med avancerede [KPIs] for domænets søgeordsplacering."

#: src/Pages/PublicReport/publicReportSettings/index.tsx:559
msgid "Share of Voice calculation"
msgstr "Synlighedsberegning"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:560
msgid ""
"We recommend that users use the latest [AISOV] algorithm, which uses a "
"machine learning model.\n"
" However, it is still possible to view [SOV] using the older implementation "
"if needed."
msgstr ""
"Vi anbefaler, at brugere anvender den nyeste [AISOV]-algoritme, som bruger "
"en maskinlæringsmodel.\n"
"Det er dog stadig muligt at se [SOV] ved hjælp af den ældre implementering, "
"hvis det er nødvendigt."

#: src/Pages/PublicReport/publicReportSettings/index.tsx:577
msgid "Included keywords table columns"
msgstr "Inkluderede kolonner i søgeordstabellen"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:578
msgid ""
"Show additional table columns with advanced keyword metrics.\n"
"\n"
"This will add the [searchIntent] and [CTR] columns to the keywords chart."
msgstr ""
"Vis yderligere tabelkolonner med avancerede søgeordsmetrikker.\n"
"\n"
"Dette vil tilføje kolonnerne [searchIntent] og [CTR] til søgeordstabellen."

#: src/Pages/PublicReport/publicReportSettings/index.tsx:581
msgid "Search Intent-"
msgstr "Søgeintention-"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:582
msgid "Click-Through Rate"
msgstr "Click-Through Rate"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:589
msgid "Advanced table columns"
msgstr "Avancerede tabelkolonner"

#: src/Pages/PublicReport/publicReportSettings/index.tsx:594
msgid "Default compare to"
msgstr "Standard \"sammenlign med\""

#: src/Pages/PublicReport/publicReportSettings/index.tsx:606
msgid "Saving"
msgstr "Gemmer"

#: src/Pages/PublicReport/support/TableRankChart.tsx:52
#: src/Pages/PublicReport/support/TableRankChart.tsx:71
msgid "Historic Keyword Rank"
msgstr "Historisk søgeordsplacering"

#: src/Pages/PublicReport/support/TableRankChart.tsx:68
msgid "Historic Keyword Rank for \"[keyword]\""
msgstr "Historisk søgeordsplacering for \"[keyword]\""

#: src/Pages/PublicReport/support/TableRankChart.tsx:97
msgid "Historic rank"
msgstr "Historisk placering"

#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:146
msgid "Change in Rank between the two compared dates"
msgstr "Ændring i placering mellem de to sammenlignede datoer"

#: src/Pages/PublicReport/support/usePublicTableColumns.tsx:168
msgid "Average monthly Search Volume"
msgstr "Gennemsnitlig månedlig søgevolumen"

#: src/Pages/PublicReport/UserSwitch.tsx:25
msgid "Client"
msgstr "Klient"

#: src/Pages/PublicReport/UserSwitch.tsx:34
#: src/Pages/Sales/Organization/index.tsx:328
#: src/Pages/Sales/Organization/index.tsx:372
#: src/Pages/Sales/Organization/sub-account-alert.tsx:29
#: src/Utilities/workspaces.ts:13
msgid "Admin"
msgstr "Administrator"

#: src/Pages/PublicReport/UserSwitch.tsx:44
msgid "Click to view the report as a client"
msgstr "Klik for at se rapporten som klient"

#: src/Pages/PublicReport/UserSwitch.tsx:45
msgid "Click to view and customize the report as admin"
msgstr "Klik for at se- og tilpasse rapporten som administrator"

#: src/Pages/Register/components/AddressInfo.tsx:34
msgid "Address Information"
msgstr "Adresse"

#: src/Pages/Register/components/AddressInfo.tsx:37
msgid "Address (street)"
msgstr "Adresse"

#: src/Pages/Register/components/AddressInfo.tsx:63
#: src/Pages/SelectPlan/plan-features.tsx:29
msgid "State"
msgstr "Stat / område"

#: src/Pages/Register/components/AddressInfo.tsx:72
msgid "ZIP"
msgstr "Postnummer"

#: src/Pages/Register/components/AddressInfo.tsx:115
msgid "EU VAT number"
msgstr "CVR-nummer"

#: src/Pages/Register/components/AddressInfo.tsx:118
msgid "Enter your VAT number"
msgstr "Indtast CVR-nummer"

#: src/Pages/Register/components/CompanyInfo.tsx:21
msgid "Less than 1,000"
msgstr "Mindre end 1.000"

#: src/Pages/Register/components/CompanyInfo.tsx:25
msgid "1,000 - 5,000"
msgstr "1.000 - 5.000"

#: src/Pages/Register/components/CompanyInfo.tsx:29
msgid "5,000 - 10,000"
msgstr "5.000 - 10.000"

#: src/Pages/Register/components/CompanyInfo.tsx:33
msgid "10,000 - 50,000"
msgstr "10.000 - 50.000"

#: src/Pages/Register/components/CompanyInfo.tsx:37
msgid "More than 50,000"
msgstr "Mere end 50.000"

#: src/Pages/Register/components/CompanyInfo.tsx:41
msgid "Not tracking today"
msgstr "Tracker ikke i dag"

#: src/Pages/Register/components/CompanyInfo.tsx:68
msgid "Company domain"
msgstr "Virksomhedensdomæne"

#: src/Pages/Register/components/CompanyInfo.tsx:74
msgid "Enter your company domain (e.g. example.com)"
msgstr "Indtast din virksomheds domæne (f.eks. example.com)"

#: src/Pages/Register/components/CompanyInfo.tsx:84
msgid "How many keywords do you track today?"
msgstr "Hvor mange søgeord tracker du i dag?"

#: src/Pages/Register/components/CompanyInfo.tsx:88
msgid "Please select"
msgstr "Vælg venligst"

#: src/Pages/Register/components/CompanyInfo.tsx:101
msgid "Enter your password"
msgstr "Indtast dit kodeord"

#: src/Pages/Register/components/ContactInfo.tsx:27
msgid "Validated"
msgstr "Bekræftet"

#: src/Pages/Register/components/ContactInfo.tsx:31
msgid "Required for verification"
msgstr "Nødvendigt for at bekræfte oprettelsen"

#: src/Pages/Register/components/ContactInfo.tsx:75
#: src/Pages/Register/components/ContactInfo.tsx:81
msgid "First name"
msgstr "Fornavn"

#: src/Pages/Register/components/ContactInfo.tsx:91
#: src/Pages/Register/components/ContactInfo.tsx:97
msgid "Last name"
msgstr "Efternavn"

#: src/Pages/Register/components/ContactInfo.tsx:107
msgid "Phone"
msgstr "Telefon"

#: src/Pages/Register/components/ContactInfo.tsx:113
msgid "Enter your phone number"
msgstr "Indtast dit telefonnummer"

#: src/Pages/Register/components/ContactInfo.tsx:127
msgid ""
"Click here to receive a 4-digit verification code by either text message or "
"an automated phone call"
msgstr ""
"Klik her for at modtage en 4-cifret bekræftelseskode via enten sms eller et "
"automatisk telefonopkald"

#: src/Pages/Register/components/ContactInfo.tsx:136
msgid "Request code by"
msgstr "Anmod om kode via"

#: src/Pages/Register/components/ContactInfo.tsx:139
msgid "SMS"
msgstr "SMS"

#: src/Pages/Register/components/ContactInfo.tsx:144
msgid "Phone call"
msgstr "Telefonopkald"

#: src/Pages/Register/components/ContactInfo.tsx:150
msgid "Verify"
msgstr "Bekræft"

#: src/Pages/Register/components/ContactInfo.tsx:173
msgid "Work email"
msgstr "Arbejdsmail"

#: src/Pages/Register/components/PlanInfo.tsx:35
msgid "You are almost there, final step"
msgstr "Du er der næsten, sidste trin"

#: src/Pages/Register/components/PlanInfo.tsx:36
msgid "Activate your AccuRanker trial account - No credit card required"
msgstr "Aktivér din AccuRanker prøvekonto - Ingen kreditkort påkrævet"

#: src/Pages/Register/components/PlanInfo.tsx:42
msgid "Final step, create your AccuRanker account"
msgstr "Sidste trin, opret din AccuRanker konto"

#: src/Pages/Register/components/PlanInfo.tsx:44
msgid "Standard plan - %s %s per month with %s keywords"
msgstr "Standard abonnement: %s %s pr. måned med %s søgeord"

#: src/Pages/Register/components/PlanInfo.tsx:55
msgid "This is not a trial"
msgstr "Dette er ikke en prøveperiode"

#: src/Pages/Register/components/PlanInfo.tsx:59
msgid ""
"You are signing up for a paid plan and paying right away. If you are looking "
"for a trial please contact us at support@accuranker.com"
msgstr ""
"Du tilmelder dig en betalt plan og betaler med det samme. Hvis du leder "
"efter et prøveabbonoment, bedes du kontakte os på support@accuranker.com"

#: src/Pages/Register/components/TermsAndConditions.tsx:15
msgid ""
"I agree to the [termsLink:Terms and Conditions] and [policyLink:Privacy "
"Policy]"
msgstr ""
"Jeg accepterer [termsLink:betingelser og vilkår] og [policyLink:"
"privatlivspolitikken]"

#: src/Pages/Register/index.tsx:301
msgid "Activate trial"
msgstr "Aktiver prøveperiode"

#: src/Pages/Register/utils/useRegistrationSubmit.ts:76
msgid "Registration failed. Please contact support."
msgstr "Der skete en fejl under registrering. Kontakt venligst vores support."

#: src/Pages/Register/utils/useRegistrationSubmit.ts:133
#: src/Pages/Register/utils/useRegistrationSubmit.ts:138
#: src/Pages/Register/utils/useRegistrationSubmit.ts:175
msgid "Registration failed. Please contact support"
msgstr "Registrering mislykkedes. Kontakt venligst support"

#: src/Pages/ReportsOverview/GeneratedReports/generatedReportsTable.tsx:43
msgid "No Generated Reports"
msgstr "Ingen Genererede Rapporter"

#: src/Pages/ReportsOverview/GeneratedReports/generatedReportsTable.tsx:44
msgid "There are currently no generated reports."
msgstr "Der er i øjeblikket ingen genererede rapporter."

#: src/Pages/ReportsOverview/GeneratedReports/helpers/hooks.tsx:90
#: src/Pages/ReportsOverview/ScheduledReports/helpers/hooks.tsx:163
msgid "Report For"
msgstr "Rapport for"

#: src/Pages/ReportsOverview/GeneratedReports/helpers/hooks.tsx:98
#: src/Pages/ReportsOverview/ScheduledReports/helpers/hooks.tsx:168
msgid "All domains is %s"
msgstr "Alle domæner er %s"

#: src/Pages/ReportsOverview/GeneratedReports/index.tsx:85
msgid "Unable to delete report"
msgstr "Kunne ikke slette rapport"

#: src/Pages/ReportsOverview/GeneratedReports/index.tsx:88
msgid "Report deleted"
msgstr "Rapport slettet"

#: src/Pages/ReportsOverview/GeneratedReports/index.tsx:104
#: src/Pages/ReportsOverview/ScheduledReports/index.tsx:88
#: src/Pages/ReportTemplatesOverview/index.tsx:71
msgid "Reports…"
msgstr "Rapporter…"

#: src/Pages/ReportsOverview/ScheduledReports/helpers/hooks.tsx:121
msgid "Unable to delete scheduled report"
msgstr "Kunne ikke slette planlagt rapport"

#: src/Pages/ReportsOverview/ScheduledReports/helpers/hooks.tsx:124
msgid "Scheduled report deleted"
msgstr "Planlagte rapport slettet"

#: src/Pages/ReportsOverview/ScheduledReports/helpers/hooks.tsx:184
#: src/Pages/ReportTemplateBuilder/TemplateParams/index.tsx:29
msgid "Template Name"
msgstr "Skabelonnavn"

#: src/Pages/ReportsOverview/ScheduledReports/helpers/hooks.tsx:213
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:343
msgid "Schedule"
msgstr "Køreplan"

#: src/Pages/ReportsOverview/ScheduledReports/index.tsx:103
msgid "New scheduled report"
msgstr "Ny planlagt rapport"

#: src/Pages/ReportsOverview/ScheduledReports/ScheduledReportsTable.tsx:40
msgid "No Scheduled Reports"
msgstr "Ingen Planlagte Rapporter"

#: src/Pages/ReportsOverview/ScheduledReports/ScheduledReportsTable.tsx:41
msgid "There are currently no scheduled reports."
msgstr "Der er i øjeblikket ingen planlagte rapporter."

#: src/Pages/ReportTemplateBuilder/CloneTemplate/index.tsx:43
msgid "Copy of %s"
msgstr "Kopi af %s"

#: src/Pages/ReportTemplateBuilder/CloneTemplate/index.tsx:68
#: src/Pages/ReportTemplateBuilder/CreateTemplate/index.tsx:52
#: src/Pages/ReportTemplateBuilder/EditTemplate/index.tsx:55
msgid "Unable to save template: %s"
msgstr "Kunne ikke gemme skabelonen: %s"

#: src/Pages/ReportTemplateBuilder/CloneTemplate/index.tsx:70
#: src/Pages/ReportTemplateBuilder/CreateTemplate/index.tsx:54
#: src/Pages/ReportTemplateBuilder/EditTemplate/index.tsx:57
msgid "Template saved"
msgstr "Skabelon gemt"

#: src/Pages/ReportTemplateBuilder/ElementsList/index.tsx:21
msgid "You have not added any elements to this report template."
msgstr "Du har ikke tilføjet nogen elementer til denne skabelon."

#: src/Pages/ReportTemplateBuilder/ElementsList/index.tsx:23
msgid "Click the arrow to add an element to the template."
msgstr "Klik på pilen for at tilføje et element til skabelonen."

#: src/Pages/ReportTemplateBuilder/ElementsList/index.tsx:25
msgid "Each element can be added several times with different settings."
msgstr "Alle elementer kan tilføjes flere gange med forskellige indstillinger."

#: src/Pages/ReportTemplateBuilder/index.tsx:18
msgid "Template Builder"
msgstr "Skabelonbygger"

#: src/Pages/ReportTemplateBuilder/ReportTemplate/index.tsx:63
msgid "Report Elements"
msgstr "Rapportelementer"

#: src/Pages/ReportTemplateBuilder/ReportTemplate/index.tsx:75
msgid "Clear template"
msgstr "Nulstil skabelon"

#: src/Pages/ReportTemplateBuilder/ReportTemplate/index.tsx:78
msgid "Save template"
msgstr "Gem skabelon"

#: src/Pages/ReportTemplateBuilder/TemplateParams/index.tsx:32
msgid "Enter the name of your template"
msgstr "Indtast navnet på din skabelon"

#: src/Pages/ReportTemplateBuilder/TemplateParams/index.tsx:37
msgid "Brand color"
msgstr "Mærkefarve"

#: src/Pages/ReportTemplateBuilder/TemplateParams/index.tsx:38
msgid "This color will be used for separating lines in the report."
msgstr "Denne farve bruges som hovedfarve i rapporten."

#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:67
msgid "Delete Template '%s'?"
msgstr "Slet skabelonen '%s'?"

#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:68
msgid ""
"Please note that all scheduled reports using this template will be removed "
"as well"
msgstr ""
"Vær opmærksom på at alle planlagte rapporter der bruger denne skabelon også "
"vil blive slettet"

#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:87
msgid "Unable to remove template"
msgstr "Kunne ikke fjerne skabelon"

#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:89
msgid "Template removed"
msgstr "Skabelon slettet"

#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:121
msgid "Unable to set template as default"
msgstr "Kunne ikke sætte skabelonen som standard"

#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:232
msgid "Elements"
msgstr "Elementer"

#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:250
msgid "System Template"
msgstr "Systemskabelon"

#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:272
msgid "Default Template"
msgstr "Standardskabelon"

#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:315
msgid "Set as default"
msgstr "Sæt som standard"

#: src/Pages/ReportTemplatesOverview/helpers/hooks.tsx:366
msgid "Can't delete system templates"
msgstr "Systemskabeloner kan ikke slettes"

#: src/Pages/ReportTemplatesOverview/index.tsx:61
msgid "New template"
msgstr "Ny skabelon"

#: src/Pages/ReportTemplatesOverview/ReportTemplatesTable.tsx:58
msgid "No Report Templates"
msgstr "Ingen Rapportskabeloner"

#: src/Pages/ReportTemplatesOverview/ReportTemplatesTable.tsx:59
msgid "There are currently no report templates."
msgstr "Der er i øjeblikket ingen rapportskabeloner."

#: src/Pages/Sales/CreatePlan/CreatePlanSuccess/index.tsx:26
msgid "Your custom plan has been successfully created"
msgstr "Din brugerdefinerede plan er blevet oprettet"

#: src/Pages/Sales/CreatePlan/CreatePlanSuccess/index.tsx:38
msgid "Done"
msgstr "Færdig"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:89
msgid "New plan created"
msgstr "Ny plan oprettet"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:105
msgid "Category"
msgstr "Kategori"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:106
msgid "Select category"
msgstr "Vælg kategori"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:120
#: src/Pages/Sales/Plans/SalesPlansTable/support/hooks.tsx:66
msgid "Comment"
msgstr "Kommentar"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:121
msgid "Client wanted a plan with 500k keywords…"
msgstr "Kunden skulle bruge en plan med 500K søgeord…"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:122
msgid "Description of why the plan is created"
msgstr "Beskrivelse af hvorfor planen oprettes"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:129
msgid "500k keywords plan with 50 USD off on the first three months"
msgstr "500.000 søgeordsplan med 50 USD i rabat på de første tre måneder"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:130
msgid "Message for the customer"
msgstr "Besked til kunden"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:135
#: src/Pages/SelectPlan/index.tsx:400
msgid "Pricing"
msgstr "Pris"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:140
msgid "Price monthly USD"
msgstr "Månedlig pris i USD"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:142
msgid "Monthly price in USD"
msgstr "Månedlig pris i USD"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:148
msgid "Price yearly USD"
msgstr "Årlig pris i USD"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:150
msgid "Yearly price in USD"
msgstr "Årlig pris i USD"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:156
#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:164
msgid "Price monthly EUR"
msgstr "Månedlig pris i EUR"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:158
msgid "Monthly price in EUR (set 0 to calculate from USD)"
msgstr "Månedlig pris i EUR (sæt til 0 for at beregne fra USD)"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:166
msgid "Yearly price in EUR (set 0 to calculate from USD)"
msgstr "Årlig pris i EUR (sæt til 0 for at beregne fra USD)"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:172
msgid "Price monthly DKK"
msgstr "Månedlig pris i DKK"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:174
msgid "Monthly price in DKK (set 0 to calculate from USD)"
msgstr "Månedlig pris i DKK (sæt til 0 for at beregne fra USD)"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:180
msgid "Price yearly DKK"
msgstr "Årlig pris i DKK"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:182
msgid "Yearly price in DKK (set 0 to calculate from USD)"
msgstr "Årlig pris i DKK (sæt til 0 for at beregne fra USD)"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:187
msgid "Discounts"
msgstr "Rabatter"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:192
msgid "Sign-on discount USD"
msgstr "Sign-on rabat USD"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:194
msgid "Amount to discount in USD"
msgstr "Discount beløb i USD"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:199
msgid "Sign-on discount EUR"
msgstr "Sign-on rabat EUR"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:201
msgid "Amount to discount in EUR"
msgstr "Discount beløb i EUR"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:206
msgid "Sign-on discount DKK"
msgstr "Sign-on rabat DKK"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:208
msgid "Amount to discount in DKK"
msgstr "Discount beløb i DKK"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:213
msgid "Sign-on discount months"
msgstr "Sign-on rabat måneder"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:215
msgid "Total months that above discount should be applied."
msgstr "Total antal måneder ovenstående rabat skal gælde."

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:219
msgid "Deal"
msgstr "Aftale"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:224
msgid "Deal for specific organization"
msgstr "Aftale for specifikt firma"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:225
msgid "Organization id, leave empty for any organization"
msgstr "Organisations id, lad stå tomt for vilkårlig organisations"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:229
msgid "Deal starts on"
msgstr "Aftalen starter den"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:230
msgid "Start date of deal, leave empty for always"
msgstr "Startdato for aftalen (lad den være blank for evigt)"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:234
msgid "Deal ends on"
msgstr "Aftalen slutter den"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:235
msgid "End date of deal, leave empty for always"
msgstr "Slutdato for aftalen (lad være blank for evigt)"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:239
msgid "Show countdown"
msgstr "Vis nedtelling"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:243
msgid "Limits"
msgstr "Begrænsninger"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:267
#: src/Pages/Sales/Organization/index.tsx:443
msgid "Features"
msgstr "Funktioner"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:272
msgid "Valid for new only"
msgstr "Kun gyldig for nye"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:277
msgid "API access"
msgstr "API-adgang"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:282
msgid "API write access"
msgstr "Skrive API-adgang"

#: src/Pages/Sales/CreatePlan/CreateSalesPlanForm/index.tsx:296
msgid "Creating…"
msgstr "Opretter…"

#: src/Pages/Sales/CreatePlan/index.tsx:128
#: src/Pages/Sales/CreatePlan/index.tsx:140 src/Pages/Sales/Plans/index.tsx:34
msgid "Create plan"
msgstr "Opret plan"

#: src/Pages/Sales/CreatePlan/SalesPlanChoicesTable/index.tsx:103
msgid "Use plan"
msgstr "Brug abonnement"

#: src/Pages/Sales/CreatePlan/SalesPlanChoicesTable/index.tsx:117
msgid "No Base Plans"
msgstr "Ingen basisplaner"

#: src/Pages/Sales/CreatePlan/SalesPlanChoicesTable/index.tsx:118
msgid "There are currently no base plans to show."
msgstr "Der er i øjeblikket ingen basisplaner at vise."

#: src/Pages/Sales/Customers/CustomerStatusDropdown.tsx:16
#: src/Pages/Sales/Invoice/Customers/index.tsx:79
#: src/Pages/Sales/Invoice/Customers/SalesInvoiceCustomersTable/hooks.tsx:148
msgid "Churned"
msgstr "Opsagt"

#: src/Pages/Sales/Customers/CustomerStatusDropdown.tsx:20
#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:169
msgid "Trial ended"
msgstr "Prøveperiode udløbet"

#: src/Pages/Sales/Customers/CustomerStatusDropdown.tsx:24
#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:235
msgid "Failed payments"
msgstr "Fejlede betalinger"

#: src/Pages/Sales/Customers/CustomerStatusDropdown.tsx:28
msgid "Contraction"
msgstr "Nedgradering"

#: src/Pages/Sales/Customers/CustomerStatusDropdown.tsx:32
msgid "Affiliates"
msgstr "Affilierede"

#: src/Pages/Sales/Customers/index.tsx:70
msgid "Unassigned"
msgstr "Ikke tildelt"

#: src/Pages/Sales/Customers/index.tsx:77
msgid "Assigned"
msgstr "Tildelt"

#: src/Pages/Sales/Customers/index.tsx:84 src/Pages/Sales/Trials/index.tsx:61
msgid "Yours"
msgstr "Dine"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:152
msgid "Signed up"
msgstr "Oprettede sig"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:156
msgid "Sort by Signed up"
msgstr "Sorter på oprettelse"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:159
msgid "Filter by Signup Date…"
msgstr "Filtrer efter oprettelsesdato…"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:175
msgid "Contraction date"
msgstr "Nedgraderingsdato"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:187
msgid "Sort by ID"
msgstr "Sorter efter ID"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:190
msgid "Filter by ID"
msgstr "Filtrer efter ID"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:204
msgid "Sort by Name"
msgstr "Sorter efter navn"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:207
msgid "Filter by Name"
msgstr "Filtrer på navn"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:217
msgid "Churn date"
msgstr "Churndato"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:223
msgid "Churn MRR"
msgstr "Churn MRR"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:229
msgid "Billing retry date"
msgstr "Næste betalingsforsøg"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:288
#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:98
#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:182
msgid "Assign to you"
msgstr "Tildel til dig"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:319
#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:127
msgid "Select sales manager"
msgstr "Vælg salgsmanager"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:338
msgid "Sort by Sales Manager"
msgstr "Sorter efter Salgsmanager"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:341
msgid "Filter by Sales Manager…"
msgstr "Filtrer salgsmanager…"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:358
msgid "No customers to show"
msgstr "Ingen kunder at vise"

#: src/Pages/Sales/Customers/SalesCustomersTable/support/hooks.tsx:359
msgid "There is currently no customers in this section"
msgstr "Der er ingen kunder i denne sektion"

#: src/Pages/Sales/Invoice/Customers/index.tsx:75
#: src/Pages/Sales/Invoice/Customers/SalesInvoiceCustomersTable/hooks.tsx:148
#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:222
#: src/Pages/Sales/Organization/index.tsx:524
#: src/Pages/Sales/Search/index.tsx:182
msgid "Active"
msgstr "Aktiv"

#: src/Pages/Sales/Invoice/Customers/SalesInvoiceCustomersTable/hooks.tsx:98
msgid "Payment terms"
msgstr "Betalingsbetingelser"

#: src/Pages/Sales/Invoice/Customers/SalesInvoiceCustomersTable/hooks.tsx:100
msgid "%s days"
msgstr "%s dage"

#: src/Pages/Sales/Invoice/Customers/SalesInvoiceCustomersTable/hooks.tsx:110
#: src/Pages/Sales/Organization/index.tsx:670
msgid "Unpaid"
msgstr "Ikke betalt"

#: src/Pages/Sales/Invoice/Customers/SalesInvoiceCustomersTable/hooks.tsx:122
msgid "Overdue"
msgstr "Forfalden"

#: src/Pages/Sales/Invoice/Customers/SalesInvoiceCustomersTable/index.tsx:32
msgid "No Invoice Customers"
msgstr "Kunder uden faktura"

#: src/Pages/Sales/Invoice/Customers/SalesInvoiceCustomersTable/index.tsx:33
msgid "There are currently no invoice customers to show."
msgstr "Der er i øjeblikket ingen kunder at vise, som betaler via faktura."

#: src/Pages/Sales/Mails/Table/index.tsx:15
msgid "Did not find any emails."
msgstr "Fandt ingen emails."

#: src/Pages/Sales/Mails/Table/support/useMailsTableInfo.tsx:41
msgid "Recipient"
msgstr "Modtager"

#: src/Pages/Sales/Organization/Components/FeaturesPopover/index.tsx:76
msgid "Plan features"
msgstr "Planens funktioner"

#: src/Pages/Sales/Organization/Components/NotesPanel/index.tsx:52
msgid ""
"Someone put thought and effort into this. Are you sure you want to "
"permanently delete this note?"
msgstr ""
"Nogen har tænkte over dette. Er du sikker på du permanent vil slette noten?"

#: src/Pages/Sales/Organization/Components/NotesPanel/index.tsx:81
#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:219
#: src/Pages/Sales/Organization/index.tsx:261
#: src/Pages/Sales/Organization/index.tsx:370
msgid "Created"
msgstr "Oprettet"

#: src/Pages/Sales/Organization/Components/NotesPanel/index.tsx:83
msgid "Author"
msgstr "Forfatter"

#: src/Pages/Sales/Organization/Components/NotesPanel/index.tsx:103
msgid "Edited"
msgstr "Redigeret"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:38
msgid "Invoice"
msgstr "Faktura"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:40
msgid "braintree"
msgstr "braintree"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:62
msgid "Pay affiliate..."
msgstr "Betal affiliate..."

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:163
msgid "Overuse accepted:"
msgstr "Merforbrug accepteret:"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:168
msgid "Changed by:"
msgstr "Ændret af:"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:172
msgid " (user belongs to organization [multiAccountLink])"
msgstr " (bruger hører til organisationen [multiAccountLink])"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:187
#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:239
#: src/Pages/Sales/Organization/index.tsx:233
#: src/Pages/Sales/Organization/index.tsx:384
#: src/Pages/Sales/Organization/index.tsx:468
msgid "A"
msgstr "A"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:233
msgid "Billing name"
msgstr "Faktureringsnavn"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:272
msgid "No billing information"
msgstr "Ingen faktureringsinformation"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:278
msgid "Sales manager"
msgstr "Salgsmanager"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:289
msgid "Keywords deleted today"
msgstr "Slettede søgeord i dag"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:296
msgid "Keywords deleted manually"
msgstr "Manuelt slettede søgeord"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:300
msgid "Keywords from deleted domain"
msgstr "Søgeord fra slettede domæner"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:304
msgid "Keywords deleted in total"
msgstr "Slettede søgeord i alt"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:308
msgid "Keywords on a paused domain"
msgstr "Søgeord fra pausede domæner"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:312
msgid "Total deleted or paused keywords"
msgstr "Total antal slettede eller pausede søgeord"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:343
#: src/Pages/Sales/Search/index.tsx:232 src/Pages/Sales/Search/index.tsx:272
msgid "Impersonate"
msgstr "Log på kunde"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:345
msgid "Add demo content"
msgstr "Tilføj demoindhold"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:346
msgid "Extend Plan"
msgstr "Forlæng abonnement"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:353
msgid "Open in Pipedrive"
msgstr "Åben i Pipedrive"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/index.tsx:361
msgid "Lock / Close"
msgstr "Lås / Luk"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/KeywordUsageChart/index.tsx:32
msgid "Historic Keyword Usage (BETA)"
msgstr "Historik over søgeordsforbrug (BETA)"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/KeywordUsageChart/useKeywordUsageChartData.tsx:47
msgid "Scraped Keywords"
msgstr "Søgte søgeord"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/KeywordUsageChart/useKeywordUsageChartData.tsx:52
msgid "Estimated Keyword Usage"
msgstr "Estimeret søgeordsforbrug"

#: src/Pages/Sales/Organization/Components/OrganizationPanel/KeywordUsageChart/useKeywordUsageChartData.tsx:58
msgid "Plan Size"
msgstr "Abonnementsstørrelse"

#: src/Pages/Sales/Organization/index.tsx:77
#: src/Pages/Sales/Organization/index.tsx:84
msgid "Could not delete user"
msgstr "Kunne ikke slette bruger"

#: src/Pages/Sales/Organization/index.tsx:79
#: src/Pages/Users/UsersTable/index.tsx:127
msgid "User deleted"
msgstr "Bruger slettet"

#: src/Pages/Sales/Organization/index.tsx:102
msgid "Groups & Domains"
msgstr "Grupper & Domæner"

#: src/Pages/Sales/Organization/index.tsx:106
msgid "groups"
msgstr "grupper"

#: src/Pages/Sales/Organization/index.tsx:213
#: src/Pages/Sales/Organization/index.tsx:592
msgid "Filter..."
msgstr "Filtrer..."

#: src/Pages/Sales/Organization/index.tsx:268
msgid "Services"
msgstr "Tjenester"

#: src/Pages/Sales/Organization/index.tsx:327
msgid "Added"
msgstr "Tilføjet"

#: src/Pages/Sales/Organization/index.tsx:373
msgid "Billing"
msgstr "Fakturering"

#: src/Pages/Sales/Organization/index.tsx:394
#: src/Pages/Sales/Organization/sub-account-alert.tsx:46
msgid "Payer"
msgstr "Betaler"

#: src/Pages/Sales/Organization/index.tsx:396
msgid "Owner"
msgstr "Ejer"

#: src/Pages/Sales/Organization/index.tsx:398
#: src/Pages/Sales/Organization/sub-account-alert.tsx:50
msgid "Sub-Account"
msgstr "Under-konto"

#: src/Pages/Sales/Organization/index.tsx:403
msgid "Discount type"
msgstr "Rabattype"

#: src/Pages/Sales/Organization/index.tsx:409
msgid "Discount"
msgstr "Rabat"

#: src/Pages/Sales/Organization/index.tsx:430
msgid "Price (local)"
msgstr "Pris (lokal)"

#: src/Pages/Sales/Organization/index.tsx:433
msgid "Price (USD)"
msgstr "Pris (USD)"

#: src/Pages/Sales/Organization/index.tsx:439
msgid "Sign on bonus"
msgstr "Sign-on rabat"

#: src/Pages/Sales/Organization/index.tsx:478
msgid "Billing cycle in months"
msgstr "Faktureringsperiode i måneder"

#: src/Pages/Sales/Organization/index.tsx:525
msgid "Trial"
msgstr "Prøvekonto"

#: src/Pages/Sales/Organization/index.tsx:597
msgid "Show paid"
msgstr "Vis betalte"

#: src/Pages/Sales/Organization/index.tsx:600
msgid "Show unpaid"
msgstr "Vis ubetalte"

#: src/Pages/Sales/Organization/index.tsx:606
msgid "Show credit notes"
msgstr "Vis kreditnotaer"

#: src/Pages/Sales/Organization/index.tsx:649
msgid "Due date"
msgstr "Forfaldsdato"

#: src/Pages/Sales/Organization/index.tsx:663
msgid "Credit note for"
msgstr "Kreditnota for"

#: src/Pages/Sales/Organization/index.tsx:677
msgid "Refunded"
msgstr "Refunderet"

#: src/Pages/Sales/Organization/index.tsx:756
msgid "Emails"
msgstr "E-mails"

#: src/Pages/Sales/Organization/index.tsx:775
msgid "Reason"
msgstr "Begrundelse"

#: src/Pages/Sales/Organization/index.tsx:917
msgid "Failed Payments"
msgstr "Fejlede betalinger"

#: src/Pages/Sales/Organization/index.tsx:926
msgid "Trial Abuse Logs"
msgstr "Misbrugslog"

#: src/Pages/Sales/Organization/sub-account-alert.tsx:42
msgid "This account is a sub-account!"
msgstr "Denne konto er en under-konto!"

#: src/Pages/Sales/Plans/index.tsx:40
msgid "Name, ID, Comment…"
msgstr "Navn, ID, kommentar…"

#: src/Pages/Sales/Plans/SalesPlansTable/support/hooks.tsx:88
msgid "Subscribers"
msgstr "Abonnenter"

#: src/Pages/Sales/Reconcile/index.tsx:56
msgid "Invoice and Payment reconciled"
msgstr "Faktura og betaling afstemt"

#: src/Pages/Sales/Reconcile/index.tsx:59
msgid "Reconciliation failed"
msgstr "Afstemning fejlede"

#: src/Pages/Sales/Reconcile/index.tsx:70
msgid "Invoice marked as paid"
msgstr "Faktura markeret som betalt"

#: src/Pages/Sales/Reconcile/index.tsx:73
msgid "Failed to mark invoice as paid"
msgstr "Kunne ikke markerer faktura som betalt"

#: src/Pages/Sales/Reconcile/index.tsx:95
msgid "Mark as Paid"
msgstr "Marker som betalt"

#: src/Pages/Sales/Reconcile/index.tsx:103
msgid "Reconcile"
msgstr "Afstem"

#: src/Pages/Sales/Search/index.tsx:89
#: src/Pages/Sales/Search/sales-search-input.tsx:40
msgid "User"
msgstr "Bruger"

#: src/Pages/Sales/Search/index.tsx:117
#: src/Pages/Sales/Search/sales-search-input.tsx:64
msgid "Payment Contact"
msgstr "Faktureringsinfo"

#: src/Pages/Sales/Search/index.tsx:271
msgid "Other Information"
msgstr "Andre information"

#: src/Pages/Sales/Search/sales-search-input.tsx:28
msgid "Domain, Organization, User, Email, Name, ID, Anything…"
msgstr "Domæne, Firma, Bruger, E-mail, Navn, ID, Alting…"

#: src/Pages/Sales/Trials/index.tsx:71
msgid "Create trial for outbound lead"
msgstr "Lav trial for outbound lead"

#: src/Pages/Sales/Trials/index.tsx:72
msgid "Trial Email"
msgstr "Trial Email"

#: src/Pages/Sales/Trials/TrialsTable/index.tsx:91
msgid "No Trials"
msgstr "Ingen trials"

#: src/Pages/Sales/Trials/TrialsTable/index.tsx:92
msgid "There are currently no trials in this table."
msgstr "Der er ingen trials i denne tabel."

#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:49
#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:506
msgid "No plan info"
msgstr "Ingen planinfo"

#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:54
#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:508
msgid "Active trial"
msgstr "Aktiv trial"

#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:57
#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:510
msgid "Active paid plan"
msgstr "Aktivt betalt abonnement"

#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:61
#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:512
msgid "Expired trial"
msgstr "Udløbet trial"

#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:64
#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:506
msgid "Expired paid plan"
msgstr "Udløbet betalt abonnement"

#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:211
msgid "Copied to clipboard"
msgstr "Kopieret til udklipsholder"

#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:284
msgid "Lead score"
msgstr "Lead score"

#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:387
msgid "Origin"
msgstr "Oprindelse"

#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:404
msgid "Days left"
msgstr "Dage tilbage"

#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:414
msgid "n/a"
msgstr "n/a"

#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:421
msgid "The time left on the customers plan."
msgstr "Tid tilbage på kundens plan."

#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:543
msgid "Create Offer"
msgstr "Opret tilbud"

#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:576
msgid "Edit Plan"
msgstr "Rediger abonnement"

#: src/Pages/Sales/Trials/TrialsTable/support/hooks.tsx:596
msgid "Extend Trial"
msgstr "Forlæng prøveperiode"

#: src/Pages/ScheduledReportBuilder/data.ts:68
msgid "Public report"
msgstr "Offentlig rapport"

#: src/Pages/ScheduledReportBuilder/data.ts:93
msgid "Scheduled AccuRanker report"
msgstr "Planlagt AccuRanker rapport"

#: src/Pages/ScheduledReportBuilder/data.ts:95
msgid ""
"Hi,\n"
"\n"
"Your scheduled AccuRanker report is attached."
msgstr ""
"Hej\n"
"\n"
"Din planlagte AccuRanker rapport er vedhæftet."

#: src/Pages/ScheduledReportBuilder/index.tsx:21
msgid "Report Builder"
msgstr "Rapportbygger"

#: src/Pages/ScheduledReportBuilder/index.tsx:31
msgid "The preview is PDF only and includes no more than 100 keywords."
msgstr ""
"Forhåndsvisningen er kun for PDF rapporter og indeholder ikke mere end 100 "
"nøgleord."

#: src/Pages/ScheduledReportBuilder/index.tsx:36
msgid ""
"Please note, this is only visual preview. The data in the preview does not "
"reflect the real data!"
msgstr ""
"Vær opmærksom på at dette er en visuelt forhåndsvisning. Dataen i "
"forhåndsvisningen er ikke ægte data!"

#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:130
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:149
msgid "Domains list is empty"
msgstr "Listen over domæner er tom"

#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:202
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:228
msgid "Report is being generated and will be sent to %s when completed."
msgstr ""
"Din rapport er ved at blive lavet og bliver sendt til %s når den er klar."

#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:255
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:429
msgid "Email it to me when it's ready"
msgstr "Email den til mig når den er klar"

#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:308
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:326
msgid "The report will be emailed to %s when it is done."
msgstr "Rapporten bliver sendt til %s når den er klar."

#: src/Pages/ScheduledReportBuilder/KeywordHistoryReportForm/index.tsx:313
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:331
msgid "Unable to update recipient"
msgstr "Kunne ikke opdatere modtager"

#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:192
msgid "Can not find group for domain"
msgstr "Kan ikke finde gruppe for domæne"

#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:364
#: src/Pages/ScheduledReportBuilder/OneTimeReportForm/index.tsx:369
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:480
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:483
msgid "Template"
msgstr "Skabelon"

#: src/Pages/ScheduledReportBuilder/Preview/index.tsx:88
msgid "Please fill out the form to view the preview."
msgstr "Udfyld venligst formularen for at se forhåndsvisningen."

#: src/Pages/ScheduledReportBuilder/Preview/index.tsx:90
msgid "You can only preview PDF reports."
msgstr "Du kan kun se eksempler på PDF rapporter."

#: src/Pages/ScheduledReportBuilder/Preview/PdfPreview.tsx:33
msgid "The PDF preview took too long to load. Please try again later."
msgstr ""
"PDF-forhåndsvisningen tog for lang tid at indlæse. Prøv venligst igen senere."

#: src/Pages/ScheduledReportBuilder/Preview/PdfPreview.tsx:67
msgid "Preview of the PDF report"
msgstr "Forhåndsvisning af PDF-rapporten"

#: src/Pages/ScheduledReportBuilder/PublicReportOptions/index.tsx:121
msgid "Reset public report link?"
msgstr "Nulstil offentlig rapport link?"

#: src/Pages/ScheduledReportBuilder/PublicReportOptions/index.tsx:123
msgid ""
"Are you sure you want to reset the public link for this report? \n"
" This will invalidate the current link."
msgstr ""
"Er du sikker på, at du vil opdatere tokensættet og offentliggøre linket til "
"denne rapport?\n"
"Dette vil ugyldiggøre det nuværende tokenlink."

#: src/Pages/ScheduledReportBuilder/PublicReportOptions/index.tsx:139
msgid "Public link"
msgstr "Offentligt link"

#: src/Pages/ScheduledReportBuilder/PublicReportOptions/index.tsx:145
#: src/Pages/ScheduledReportBuilder/PublicReportOptions/index.tsx:150
msgid "Copy link to clipboard"
msgstr "Kopiér link til udklipsholder"

#: src/Pages/ScheduledReportBuilder/PublicReportOptions/index.tsx:159
msgid "Copy this link and give it to your client."
msgstr "Kopier dette link og giv det til din kunde."

#: src/Pages/ScheduledReportBuilder/PublicReportOptions/index.tsx:167
msgid "Reset public link"
msgstr "Nulstil offentlig link"

#: src/Pages/ScheduledReportBuilder/PublicReportOptions/index.tsx:177
msgid "Customize report"
msgstr "Tilpas rapport"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/Add/index.tsx:95
msgid "Unable to create scheduled report"
msgstr "Kunne ikke oprette planlagt rapport"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/Add/index.tsx:98
msgid "Report scheduled"
msgstr "Rapport plangt"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/Edit/index.tsx:123
msgid "Unable to update scheduled report"
msgstr "Kunne ikke opdatere planlagt rapport"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/Edit/index.tsx:127
msgid "Report updated"
msgstr "Rapport opdateret"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/EmailsInput/index.tsx:40
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/EmailsInput/index.tsx:41
msgid "Add email"
msgstr "Tilføj e-mail"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:180
#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:590
#: src/Pages/SelectPlan/plan-features.tsx:74
msgid "Daily"
msgstr "Dagligt"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:184
#: src/Pages/SelectPlan/plan-features.tsx:74
msgid "Weekly"
msgstr "Ugentligt"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:194
msgid "Monday"
msgstr "Mandag"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:198
msgid "Tuesday"
msgstr "Tirsdag"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:202
msgid "Wednesday"
msgstr "Onsdag"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:206
msgid "Thursday"
msgstr "Torsdag"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:210
msgid "Friday"
msgstr "Fredag"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:214
msgid "Saturday"
msgstr "Lørdag"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:218
msgid "Sunday"
msgstr "Søndag"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:224
msgid "Last day of month"
msgstr "Sidste dag i måneden"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:258
msgid "Select a domain"
msgstr "Vælg et domæne"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:282
msgid "Select a group"
msgstr "Vælg en gruppe"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:329
msgid ""
"* If the month doesn't have that many days it's treated as the last day of "
"the month. If you choose 'Last day of month' we will create a report from "
"the 1st to the last day of the month."
msgstr ""
"* Hvis der ikke er så mange dage i måneden, bliver det til sidste dag i "
"måneden. Hvis du vælger 'Sidste dag i måneden' opretter vi en rapport fra "
"den 1. til den sidste dage i måneden."

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:339
msgid "Email frequency"
msgstr "E-mail frekvens"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:355
msgid ""
"Reports are generated and sent after the daily ranking check on the chosen "
"day"
msgstr ""
"Rapporter genereres og sendes efter det daglige søgeordstjek på den valgte "
"dag"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:364
msgid "Send email from"
msgstr "Send email fra"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:367
msgid ""
"The report will be sent in an email from noreply@accuranker.com. Clicking "
"\"Reply\" will send an email back to the above address."
msgstr ""
"Rapporten vil blive sendt i en e-mail fra noreply@accuranker.com. Ved at "
"klikke på \"Svar\" i dit mailsystem sendes en e-mail tilbage til ovenstående "
"adresse."

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:373
msgid "Email subject"
msgstr "E-mail overskrift"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:377
msgid "Email body"
msgstr "E-mail tekst"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:461
msgid "Create for all domains in a group"
msgstr "Opret for alle domæner i en gruppe"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:493
msgid "Email Report To"
msgstr "E-mail til rapport til"

#: src/Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/index.tsx:513
msgid "Pick a domain/group before adding filters"
msgstr "Vælg et domæne/en gruppe før du kan tilføje filtre"

#: src/Pages/SelectPlan/index.tsx:88
msgid "[b1:All features] are available in [b2:all] plans at [b3:no extra cost]"
msgstr ""
"[b1:Alle funktioner] er tilgængelige på [b2:alle] planer [b3:uden ekstra "
"omkostninger]"

#: src/Pages/SelectPlan/index.tsx:103
msgid "Are you looking for more keywords?"
msgstr "Leder du efter flere søgeord?"

#: src/Pages/SelectPlan/index.tsx:107
msgid "Don't worry, we've got you covered."
msgstr "Bare rolig, vi har din ryg."

#: src/Pages/SelectPlan/index.tsx:109
msgid ""
"Click here to contact one of our customer success representatives, and "
"they'll get back to you."
msgstr "Klik her for at kontakte en af vores supportmedarbejdere."

#: src/Pages/SelectPlan/index.tsx:217
msgid "monthly pricing"
msgstr "pris pr måned"

#: src/Pages/SelectPlan/index.tsx:232
msgid "annual pricing"
msgstr "Årligt"

#: src/Pages/SelectPlan/index.tsx:240
msgid "best value"
msgstr "Mest værdi for pengene"

#: src/Pages/SelectPlan/index.tsx:276
msgid "You save"
msgstr "Du sparer"

#: src/Pages/SelectPlan/index.tsx:289
msgid "mo"
msgstr "md"

#: src/Pages/SelectPlan/index.tsx:307
msgid "Your Plan"
msgstr "Dit abonnement"

#: src/Pages/SelectPlan/index.tsx:315
msgid "Go to checkout"
msgstr "Gå til kassen"

#: src/Pages/SelectPlan/index.tsx:373
msgid "Recommended"
msgstr "Anbefalet"

#: src/Pages/SelectPlan/index.tsx:395
msgid "Current active keywords: "
msgstr "Antal aktive søgeord: "

#: src/Pages/SelectPlan/index.tsx:423
msgid "Upgrade Plan"
msgstr "Opgrader abonnement"

#: src/Pages/SelectPlan/plan-features.tsx:20
msgid "Search Engines"
msgstr "Søgemaskiner"

#: src/Pages/SelectPlan/plan-features.tsx:21
#: src/Pages/SelectPlan/plan-features.tsx:68
msgid "Youtube"
msgstr "YouTube"

#: src/Pages/SelectPlan/plan-features.tsx:28
msgid "Local Search"
msgstr "Lokalsøgning"

#: src/Pages/SelectPlan/plan-features.tsx:29
msgid "County"
msgstr "Amt"

#: src/Pages/SelectPlan/plan-features.tsx:29
msgid "Zipcode"
msgstr "Postnummer"

#: src/Pages/SelectPlan/plan-features.tsx:32
msgid "Live Keyword Data"
msgstr "Live søgeordsdata"

#: src/Pages/SelectPlan/plan-features.tsx:34
msgid "Automatic updated every 24 hour"
msgstr "Automatisk opdateret én gang i døgnet"

#: src/Pages/SelectPlan/plan-features.tsx:35
msgid "Unlimited on-demand"
msgstr "Ubegrænset on-demand"

#: src/Pages/SelectPlan/plan-features.tsx:35
msgid "Individual keyword"
msgstr "Individuelle søgeord"

#: src/Pages/SelectPlan/plan-features.tsx:35
msgid "All keywords"
msgstr "Alle søgeord"

#: src/Pages/SelectPlan/plan-features.tsx:39
msgid "Advanced Filtering"
msgstr "Avancerede filtre"

#: src/Pages/SelectPlan/plan-features.tsx:40
msgid "25+ filter types"
msgstr "25+ filtermuligheder"

#: src/Pages/SelectPlan/plan-features.tsx:40
msgid "Save as segments"
msgstr "Gemt som segmenter"

#: src/Pages/SelectPlan/plan-features.tsx:40
msgid "100% Dynamic"
msgstr "100% dynamisk"

#: src/Pages/SelectPlan/plan-features.tsx:43
msgid "Keyword Research"
msgstr "Søgeordsanalyse"

#: src/Pages/SelectPlan/plan-features.tsx:44
msgid "24B keywords globally"
msgstr "24 mia. søgeord globalt"

#: src/Pages/SelectPlan/plan-features.tsx:44
msgid "Advanced filtering"
msgstr "Avancerede filtrering"

#: src/Pages/SelectPlan/plan-features.tsx:48
msgid "Easy organic overview"
msgstr "Nemt organisk overblik"

#: src/Pages/SelectPlan/plan-features.tsx:48
msgid "Identify competitors keywords"
msgstr "Identificer konkurrenters søgeord"

#: src/Pages/SelectPlan/plan-features.tsx:51
msgid "Dynamic Keyword Tagging"
msgstr "Dynamisk søgeordstagging"

#: src/Pages/SelectPlan/plan-features.tsx:53
msgid "Custom rules"
msgstr "Brugerdefinerede regler"

#: src/Pages/SelectPlan/plan-features.tsx:54
msgid "Multiple OR, AND & Nested statements"
msgstr "Flere ELLER, OG & Indlejrede udsagn"

#: src/Pages/SelectPlan/plan-features.tsx:55
msgid "Parameters"
msgstr "Parametere"

#: src/Pages/SelectPlan/plan-features.tsx:55
msgid "Page title"
msgstr "Sidetitel"

#: src/Pages/SelectPlan/plan-features.tsx:59
msgid "SERP Feature Tracking"
msgstr "Sporing af SERP-elementer"

#: src/Pages/SelectPlan/plan-features.tsx:60
msgid "We track it all"
msgstr "Vi holder øje med det hele"

#: src/Pages/SelectPlan/plan-features.tsx:60
msgid "Competitor Tracking"
msgstr "Konkurrenttracking"

#: src/Pages/SelectPlan/plan-features.tsx:64
msgid "Measure your own share of voice"
msgstr "Mål din egen Synlighed"

#: src/Pages/SelectPlan/plan-features.tsx:64
msgid "Measure your competitors' share of voice"
msgstr "Mål dine konkurrenters synlighed"

#: src/Pages/SelectPlan/plan-features.tsx:67
msgid "Historic Search Volume"
msgstr "Historisk søgevolume"

#: src/Pages/SelectPlan/plan-features.tsx:71
msgid "Advanced Reporting"
msgstr "Avanceret rapportering"

#: src/Pages/SelectPlan/plan-features.tsx:73
msgid "Custom templates"
msgstr "Brugerdefinerede skabeloner"

#: src/Pages/SelectPlan/plan-features.tsx:74
msgid "Report scheduling"
msgstr "Skeduleret rapportering"

#: src/Pages/SelectPlan/plan-features.tsx:83
msgid "Google sheet"
msgstr "Google sheet"

#: src/Pages/SelectPlan/plan-features.tsx:84
msgid "Live"
msgstr "Live"

#: src/Pages/SelectPlan/plan-features.tsx:85
#: src/Pages/SelectPlan/plan-features.tsx:91
msgid "Google Data Studio"
msgstr "Google Data Studio"

#: src/Pages/SelectPlan/plan-features.tsx:89
msgid "Integration"
msgstr "Integration"

#: src/Pages/SelectPlan/plan-features.tsx:92
msgid "Google Analytics 4"
msgstr "Google Analytics 4"

#: src/Pages/SelectPlan/plan-features.tsx:95
msgid "Unlimited Restful API"
msgstr "Ubegrænset Restful API"

#: src/Pages/SelectPlan/plan-features.tsx:99
msgid "Data storage"
msgstr "Dataopbevaring"

#: src/Pages/SelectPlan/plan-features.tsx:100
msgid "We store your ranks forever"
msgstr "Vi gemmer dine placeringer for evigt"

#: src/Pages/SelectPlan/plan-features.tsx:103
msgid "Data migration"
msgstr "Datamigrering"

#: src/Pages/SelectPlan/plan-features.tsx:105
msgid "50+ Supported platforms"
msgstr "50+ understøttede platforme"

#: src/Pages/SelectPlan/plan-features.tsx:106
msgid "All historical data, no matter the timeframe"
msgstr "Alle historiske data, uanset tidsrammen"

#: src/Pages/SelectPlan/plan-features.tsx:107
msgid "Support from the customer success team"
msgstr "Support fra kundesuccesteamet"

#: src/Pages/SelectPlan/plan-features.tsx:112
msgid "Unlimited users"
msgstr "Ubegrænset antal brugerer"

#: src/Pages/SelectPlan/plan-features.tsx:112
msgid "Invite all your colleagues"
msgstr "Inviter alle dine kolleger"

#: src/Pages/SelectPlan/plan-features.tsx:112
#: src/Pages/SelectPlan/plan-features.tsx:119
msgid "No extra cost"
msgstr "Ingen ekstra omkostninger"

#: src/Pages/SelectPlan/plan-features.tsx:115
msgid "Domains / Projects"
msgstr "Domæner / Projekter"

#: src/Pages/SelectPlan/plan-features.tsx:118
msgid "Create as many domains/projects as you need"
msgstr "Opret så mange domæner/projekter, som du har brug for"

#: src/Pages/SelectPlan/plan-features.tsx:165
msgid "… and much much more"
msgstr "… og meget meget mere"

#: src/Pages/SiteMapping/components/ColumnLayout.tsx:46
msgid "No data found for the selected filtering."
msgstr "Ingen data fundet for de valgte filtre."

#: src/Pages/SiteMapping/components/CompetitorTable.tsx:74
#: src/Pages/SiteMapping/components/TreeTable/Header.tsx:80
msgid "Estimated traffic value"
msgstr "Estimeret trafikværdi"

#: src/Pages/SiteMapping/components/CompetitorTable.tsx:90
msgid "Number of shared keywords"
msgstr "Antal delte søgeord"

#: src/Pages/SiteMapping/components/CompetitorTable.tsx:91
#: src/Pages/SiteMapping/components/TreeTable/Header.tsx:89
msgid "Tracked"
msgstr "Tracked"

#: src/Pages/SiteMapping/components/CompetitorTable.tsx:93
#: src/Pages/SiteMapping/components/TreeTable/Header.tsx:95
msgid "Number of discovered keywords"
msgstr "Antal opdagede søgeord"

#: src/Pages/SiteMapping/components/CompetitorTable.tsx:94
#: src/Pages/SiteMapping/components/TreeTable/Header.tsx:94
msgid "Disc."
msgstr "Opd."

#: src/Pages/SiteMapping/components/CompetitorTable.tsx:174
msgid ""
"You track 0 keywords on this page which means we cannot determine your "
"competitors.\n"
"\n"
"See [numberOfKeywords] available keywords in [goToKeydis] or "
"[goToKeywordsModal] to see dynamic competitors for this landing page."
msgstr ""
"Du tracker 0 søgeord på denne side, hvilket betyder at vi ikke kan finde "
"dine konkurrenter.\n"
"\n"
"Se [numberOfKeywords] tilgængelige søgeord i [goToKeydis] eller "
"[goToKeywordsModal] for at se dynamiske konkurrenter for denne landingsside."

#: src/Pages/SiteMapping/components/CompetitorTable.tsx:187
msgid "import [numberOfKeywords] available keywords "
msgstr "Importér [numberOfKeywords] tilgængelige søgeord "

#: src/Pages/SiteMapping/components/CompetitorTable.tsx:201
msgid ""
"You are not indexed for any keywords on this page which means we cannot "
"determine your competitors."
msgstr ""
"Der ingen søgeord indekseret for denne side, hvilket betyder at vi ikke kan "
"bestemme dine konkurrenter."

#: src/Pages/SiteMapping/components/CompetitorTable.tsx:212
msgid ""
"We only show the subpages with most keywords. Apply a filter to explore the "
"remaining subpages of [parentPath] or increase the max subpages per page in "
"the slider above the chart."
msgstr ""
"Vi viser kun de undersider der har flest søgeord. Lav et filter for at se de "
"resterende undersider eller øg antallet af undersider per side i slideren "
"over grafen."

#: src/Pages/SiteMapping/components/CompetitorTable.tsx:401
msgid "See %s shared keywords"
msgstr "Se %s delte søgeord"

#: src/Pages/SiteMapping/components/CompetitorTable.tsx:409
#: src/Pages/SiteMapping/components/Right.tsx:232
#: src/Pages/SiteMapping/components/Right.tsx:266
msgid "See %s available keywords in Keyword Discovery"
msgstr "Se %s tilgængelige søgeord i Keyword Discovery"

#: src/Pages/SiteMapping/components/PathAndTitleView.tsx:18
#: src/Pages/SiteMapping/components/PathAndTitleView.tsx:27
msgid "No page title found"
msgstr "Ingen sidetitel fundet"

#: src/Pages/SiteMapping/components/PathAndTitleView.tsx:22
msgid "Page title available only with tracked keywords"
msgstr "Side titel er kun tilgængelig for overvågede søgeord"

#: src/Pages/SiteMapping/components/PathAndTitleView.tsx:39
msgid "Remaining subpages"
msgstr "Resterende landingssider"

#: src/Pages/SiteMapping/components/PathAndTitleView.tsx:77
msgid "Including subpages"
msgstr "Inklusiv undersider"

#: src/Pages/SiteMapping/components/Right.tsx:199
msgid "Tracked keywords"
msgstr "Overvågede søgeord"

#: src/Pages/SiteMapping/components/Right.tsx:202
#: src/Pages/SiteMapping/components/Right.tsx:231
msgid "This page"
msgstr "Denne side"

#: src/Pages/SiteMapping/components/Right.tsx:203
#: src/Pages/SiteMapping/components/Right.tsx:217
msgid "See %s tracked keywords"
msgstr "Se %s overvågede søgeord"

#: src/Pages/SiteMapping/components/Right.tsx:214
#: src/Pages/SiteMapping/components/Right.tsx:260
msgid "Including [nSubpages] subpages"
msgstr "Inklusiv [nSubpages] undersider"

#: src/Pages/SiteMapping/components/Right.tsx:215
#: src/Pages/SiteMapping/components/Right.tsx:263
msgid "No subpages found"
msgstr "Ingen undersider fundet"

#: src/Pages/SiteMapping/components/Right.tsx:244
#: src/Pages/SiteMapping/components/Right.tsx:278
msgid "Import %s keywords"
msgstr "Importer %s søgeord"

#: src/Pages/SiteMapping/components/Right.tsx:291
msgid "Tags for this page"
msgstr "Tags på denne side"

#: src/Pages/SiteMapping/components/Right.tsx:304
msgid "Dynamic Competitor Landing Pages"
msgstr "Dynamisk Konkurrent Landingssider"

#: src/Pages/SiteMapping/components/SectionHeader.tsx:65
msgid "Zero competitor certainty"
msgstr "Ingen konkurrentsikkerhed"

#: src/Pages/SiteMapping/components/SectionHeader.tsx:70
msgid "Low competitor certainty"
msgstr "Lav konkurrentsikkerhed"

#: src/Pages/SiteMapping/components/SectionHeader.tsx:75
msgid "Medium competitor certainty"
msgstr "Middel konkurrentsikkerhed"

#: src/Pages/SiteMapping/components/SectionHeader.tsx:80
msgid "High competitor certainty"
msgstr "Høj konkurrentsikkerhed"

#: src/Pages/SiteMapping/components/SectionHeader.tsx:88
msgid ""
"The more keywords you track, the higher competitor certainty.[br][br]You "
"currently track [amount] of your indexed keywords on this landing page."
msgstr ""
"Jo flere søgeord du overvåger, des højere konkurrentsikkerhed.[br][br]Du "
"overvåger [amount] af dine indekserede keywords på denne side."

#: src/Pages/SiteMapping/components/TagsSection.tsx:122
msgid "No tags have been created for this page yet."
msgstr "Der er endnu ikke oprettet nogen tags til denne side."

#: src/Pages/SiteMapping/components/TagsSection.tsx:132
msgid "%s keyword on the selected landing page has this %s tag"
msgid_plural "%s keywords on the selected landing page have this %s tag"
msgstr[0] "%s søgeord på den valgte landingsside har dette %s tag"
msgstr[1] "%s søgeord på den valgte landingsside har dette %s tag"

#: src/Pages/SiteMapping/components/TagsSection.tsx:136
msgid "dynamic "
msgstr "dynamisk "

#: src/Pages/SiteMapping/components/TagsSection.tsx:160
msgid "Create a dynamic tagging rule for this landing page"
msgstr "Opret en dynamisk tagging regel for denne landingsside"

#: src/Pages/SiteMapping/components/TagsSection.tsx:163
msgid "Add dynamic tag"
msgstr "Tilføj dynamiske tags"

#: src/Pages/SiteMapping/components/TreeChart.tsx:194
msgid "Data for landing page"
msgstr "Data for denne landingsside"

#: src/Pages/SiteMapping/components/TreeChart.tsx:210
msgid "Data including subpages"
msgstr "Data inklusiv undersider"

#: src/Pages/SiteMapping/components/TreeChart.tsx:419
msgid "Error loading data"
msgstr "Fejl under hentning af data"

#: src/Pages/SiteMapping/components/TreeHelpIcons.tsx:24
msgid "Click to select a page"
msgstr "Klik for at vælge en side"

#: src/Pages/SiteMapping/components/TreeHelpIcons.tsx:25
msgid "Click and drag to move the graph"
msgstr "Klik og træk for bevæge grafen"

#: src/Pages/SiteMapping/components/TreeHelpIcons.tsx:28
msgid "Double-click to see subpages"
msgstr "Dobbeltklik for at se undersider"

#: src/Pages/SiteMapping/components/TreeHelpIcons.tsx:29
msgid "Shift+Scroll to zoom"
msgstr "Shift+Scroll for at zoome"

#: src/Pages/SiteMapping/components/TreeLegend.tsx:38
msgid "Total number of keywords"
msgstr "Total antal søgeord"

#: src/Pages/SiteMapping/components/TreeLegend.tsx:102
msgid "Percentage tracked"
msgstr "Procentdel overvåget"

#: src/Pages/SiteMapping/components/TreeLegend.tsx:138
msgid "Subpages"
msgstr "Undersider"

#: src/Pages/SiteMapping/components/TreeLegend.tsx:149
msgid "Has"
msgstr "Har"

#: src/Pages/SiteMapping/components/TreeLegend.tsx:165
msgid "Doesn't have"
msgstr "Har ikke"

#: src/Pages/SiteMapping/components/TreeLegend.tsx:201
msgid "Total search volume"
msgstr "Total søgevolumen"

#: src/Pages/SiteMapping/components/TreeModeSwitch.tsx:24
msgid "View tree"
msgstr "Vis træ"

#: src/Pages/SiteMapping/components/TreeModeSwitch.tsx:24
msgid "View table"
msgstr "Vis tabel"

#: src/Pages/SiteMapping/components/TreeTable/components/TableSearchInput.tsx:20
msgid "Search..."
msgstr "Søg..."

#: src/Pages/SiteMapping/components/TreeTable/Header.tsx:79
msgid "AI Traf. val"
msgstr "AI Traf. vær"

#: src/Pages/SiteMapping/components/TreeTable/Header.tsx:90
msgid "Number of tracked keywords"
msgstr "Antal trackede søgeord"

#: src/Pages/SiteMapping/components/TreeTable/index.tsx:158
msgid "No pages found"
msgstr "Ingen sider fundet"

#: src/Pages/SiteMapping/components/TreeTable/index.tsx:161
msgid "We found no pages to show for the specified search."
msgstr "Vi fandt ingen sider at vise for denne søgning."

#: src/Pages/SiteMapping/components/TreeTable/index.tsx:162
msgid "We found no pages to show for this domain"
msgstr "Vi fandt ingen sider at vise for dette domæne"

#: src/Pages/SiteMapping/components/TreeTable/index.tsx:165
msgid "No  pages found"
msgstr "Ingen undersider fundet"

#: src/Pages/SiteMapping/components/TreeTable/index.tsx:166
msgid ""
"We found no pages to show, this could be due to your active filters. Clear "
"your filters and try again."
msgstr ""
"Vi fandt ingen sider vi kan vise. Det kan skyldes dine aktive filtre. "
"Nulstil filtrene og prøv igen."

#: src/Pages/SiteMapping/components/TreeTable/OtherRow.tsx:47
msgid ""
"We only show the subpages with most keywords. Apply a filter or adjust the "
"max subpages per path setting above the chart to explore the remaining "
"subpages of "
msgstr ""
"Vi viser kun de undersider der har flest søgeord. Tilføj et filter eller "
"juster antallet af undersider per side i slideren over grafen for at se de "
"resterende undersider for "

#: src/Pages/SiteMapping/components/TreeTable/OtherRow.tsx:52
msgid "Other subpages"
msgstr "Andre undersider"

#: src/Pages/SiteMapping/components/TreeTable/RowContent.tsx:118
msgid "Dynamic competitor landing pages available"
msgstr "Dynamisk konkurrent landingssider tilgængelige"

#: src/Pages/SiteMapping/components/TreeTable/RowContent.tsx:119
msgid "Dynamic competitor landing pages not available"
msgstr "Dynamisk konkurrent landingssider ikke tilgængelige"

#: src/Pages/SiteMapping/index.tsx:46
msgid "Tracked + keyword discovery keywords"
msgstr "Overvågede + opdagede søgeord"

#: src/Pages/SiteMapping/index.tsx:46
msgid "Tracked keywords only"
msgstr "Kun overvågede søgeord"

#: src/Pages/SiteMapping/index.tsx:55
msgid "Max subpages per page"
msgstr "Max undersider per side"

#: src/Pages/Users/index.tsx:53
msgid "Maximum users reached"
msgstr "Maksimalt antal brugere nået"

#: src/Pages/Users/index.tsx:55
msgid "Add user"
msgstr "Tilføj bruger"

#: src/Pages/Users/index.tsx:65
msgid "Need more Users?"
msgstr "Har du brug for flere brugere?"

#: src/Pages/Users/index.tsx:66
msgid "Contact Customer Support to request more Users"
msgstr "Kontakt kundesupport for at anmode om flere brugere"

#: src/Pages/Users/UsersTable/getColumns.tsx:81
msgid "Workspace"
msgstr "Workspace"

#: src/Pages/Users/UsersTable/getColumns.tsx:86
msgid "Domains are organized in groups, which are organized into Workspaces"
msgstr "Domæner er organiseret i Grupper, som er organiseret i Workspaces"

#: src/Pages/Users/UsersTable/getColumns.tsx:95
#: src/Pages/Workspaces/WorkspacesTable/getColumns.tsx:73
msgid "Time since edit"
msgstr "Tid siden rettelse"

#: src/Pages/Users/UsersTable/getColumns.tsx:103
msgid "Time since last permission change"
msgstr "Tid siden sidste rettighedsændring"

#: src/Pages/Users/UsersTable/getColumns.tsx:113
msgid "Member for"
msgstr "Medlem af"

#: src/Pages/Users/UsersTable/index.tsx:101
msgid "Delete user"
msgstr "Slet bruger"

#: src/Pages/Users/UsersTable/index.tsx:104
msgid "Delete User?"
msgstr "Slet bruger?"

#: src/Pages/Users/UsersTable/index.tsx:105
msgid "Once deleted the user [user] can no longer log in."
msgstr "Når brugeren [user] er slettet kan vedkommende ikke længere logge ind."

#: src/Pages/Users/UsersTable/index.tsx:124
#: src/Pages/Users/UsersTable/index.tsx:132
msgid "Something went wrong. Please reload and try again."
msgstr "Noget gik galt. Genindlæs venligst og prøv igen."

#: src/Pages/Users/UsersTable/index.tsx:177
msgid "No Users"
msgstr "Ingen brugere"

#: src/Pages/Users/UsersTable/index.tsx:178
msgid "There are currently no users."
msgstr "Der er i øjeblikket ingen brugere."

#: src/Pages/Welcome/components/SupportText.tsx:11
msgid "Support"
msgstr "Kundesupport"

#: src/Pages/Welcome/components/SupportText.tsx:13
msgid ""
"Our [support] is ready if you have any questions or need help. Click on the "
"orange bubble in the lower left corner, and we will get back to you as soon "
"as possible."
msgstr ""
"Vores [support] er klar, hvis du har spørgsmål eller brug for hjælp. Klik på "
"den orange boble i nederste venstre hjørne, så vender vi tilbage til dig "
"hurtigst muligt."

#: src/Pages/Welcome/components/SupportText.tsx:16
msgid "customer support"
msgstr "kontakt supporten"

#: src/Pages/Welcome/components/welcomeActions.tsx:19
msgid "Explore"
msgstr "Udforsk"

#: src/Pages/Welcome/components/welcomeActions.tsx:20
msgid "I want to explore the platform on my own and watch the videos"
msgstr "Jeg vil udforske platformen på egen hånd og se videoerne"

#: src/Pages/Welcome/components/welcomeActions.tsx:22
msgid "Play video"
msgstr "Afspil video"

#: src/Pages/Welcome/components/welcomeActions.tsx:26
msgid "Guided tour"
msgstr "Rundvisning"

#: src/Pages/Welcome/components/welcomeActions.tsx:27
msgid ""
"Schedule a free meeting with a specialist, and get the full onboarding "
"experience"
msgstr ""
"Book et gratis møde med en af vores specialister og få den fulde onboarding "
"oplevelse"

#: src/Pages/Welcome/components/welcomeActions.tsx:31
msgid "Schedule meeting"
msgstr "Book møde"

#: src/Pages/Welcome/components/welcomeActions.tsx:38
msgid ""
"I am familiar with the platform and would just like to upgrade my account"
msgstr "Jeg er bekendt med platformen og vil gerne opgradere min konto"

#: src/Pages/Welcome/components/welcomeActions.tsx:40
msgid "Select my plan"
msgstr "Vælg min plan"

#: src/Pages/Welcome/components/welcomeText.tsx:12
msgid "Welcome to AccuRanker"
msgstr "Velkommen til AccuRanker"

#: src/Pages/Welcome/components/welcomeText.tsx:16
msgid "Dear"
msgstr "Kære"

#: src/Pages/Welcome/components/welcomeText.tsx:21
msgid ""
"We are excited for you to experience the power of our platform during the 14-"
"day trial period, which includes access to all features and the ability to "
"track up to 100 keywords. Discover how AccuRanker can help you stay ahead of "
"competitors and increase website traffic."
msgstr ""
"Vi er glade for at have dig om bord, og ser frem til, at du oplever "
"AccuRanker de næste 14 dage. Du vil have adgang til alle funktioner og kan "
"overvåge op til 100 søgeord. Oplev hvordan AccuRanker kan hjælpe dig med, at "
"holde dig et skridt foran konkurrenterne og øge trafikken til din hjemmeside."

#: src/Pages/Welcome/components/welcomeText.tsx:27
msgid ""
"We offer three convenient ways to get started to help you make the most of "
"your trial."
msgstr ""
"Vi tilbyder tre overskuelige måder at komme i gang på for at få mest muligt "
"ud af din prøveperiode."

#: src/Pages/Workspaces/index.tsx:58
msgid "Maximum Workspaces reached"
msgstr "Maksimalt antal workspaces nået"

#: src/Pages/Workspaces/index.tsx:60
msgid "Add workspace"
msgstr "Tilføj workspace"

#: src/Pages/Workspaces/index.tsx:77
msgid "Need more Workspaces?"
msgstr "Har du brug for flere workspaces?"

#: src/Pages/Workspaces/index.tsx:78
msgid "Contact Customer Support to request more Workspaces."
msgstr "Kontakt kundesupport for at anmode om flere workspaces."

#: src/Pages/Workspaces/WorkspacesTable/index.tsx:83
msgid "Delete workspace"
msgstr "Slet workspace"

#: src/Pages/Workspaces/WorkspacesTable/index.tsx:86
msgid "Delete workspace?"
msgstr "Slet workspace?"

#: src/Pages/Workspaces/WorkspacesTable/index.tsx:87
msgid ""
"Once deleted, users assigned to this role will lose access to related groups."
msgstr ""
"Når den er slettet, mister brugere tildelt denne rolle adgang til relaterede "
"grupper."

#: src/Pages/Workspaces/WorkspacesTable/index.tsx:102
msgid "Workspace has been deleted."
msgstr "Workspace er slettet."

#: src/Pages/Workspaces/WorkspacesTable/index.tsx:149
msgid "No Workspaces"
msgstr "Ingen Workspaces"

#: src/Pages/Workspaces/WorkspacesTable/index.tsx:150
msgid "There are currently no Workspaces."
msgstr "Der er i øjeblikket ingen Workspaces."

#: src/Utilities/Common/files.ts:17
msgid "Invalid file type."
msgstr "Ugyldigt filtype."

#: src/Utilities/Common/files.ts:22
msgid "File size is too big. Please provide files no larger than %s"
msgstr ""
"Filstørrelsen overskrider grænsen. Brug venligst filer med maksimal "
"størrelse %s"

#: src/Utilities/getConfirmationDescription.tsx:4
msgid "The domain [name] and all its keywords will be permanently deleted."
msgstr "Domænet [name] og alle dets søgeord vil blive permanent slettet."

#: src/Utilities/getConfirmationDescription.tsx:9
msgid ""
"The group [name] and all the domains within it will be permanently deleted."
msgstr "Gruppen [name] og alle dets domæner vil blive permanent slettet."

#: src/Utilities/getSortingInfo.tsx:42
msgid "A to Z"
msgstr "A til Z"

#: src/Utilities/getSortingInfo.tsx:43 src/Utilities/getSortingInfo.tsx:54
msgid "Lowest to highest"
msgstr "Laveste til højeste"

#: src/Utilities/getSortingInfo.tsx:44
msgid "Oldest to newest"
msgstr "Ældste til nyeste"

#: src/Utilities/getSortingInfo.tsx:45
msgid "Fewest to most"
msgstr "Færrest til flest"

#: src/Utilities/getSortingInfo.tsx:46 src/Utilities/getSortingInfo.tsx:51
msgid "Highest to lowest"
msgstr "Højeste til laveste"

#: src/Utilities/getSortingInfo.tsx:47
msgid "Ascending"
msgstr "Stigende"

#: src/Utilities/getSortingInfo.tsx:50
msgid "Z to A"
msgstr "Z til A"

#: src/Utilities/getSortingInfo.tsx:52
msgid "Newest to oldest"
msgstr "Nyeste til ældste"

#: src/Utilities/getSortingInfo.tsx:53
msgid "Most to fewest"
msgstr "Flest til færrest"

#: src/Utilities/getSortingInfo.tsx:55
msgid "Descending"
msgstr "Aftagende"

#: src/Utilities/validation.ts:21
msgid "Password may be compromised. Please enter another."
msgstr "Koden kan være kompromitteret. Indtast venligst en anden."

#: src/Utilities/validation.ts:64
msgid "Please enter your full name"
msgstr "Indtast dit fulde navn"

#: src/Utilities/validation.ts:72
msgid "Please enter both first and last name"
msgstr "Indtast både for- og efternavn"

#: src/Utilities/validation.ts:78
msgid "Each name must have at least two letters"
msgstr "Hvert navn skal indeholde minimum 2 karakterer"

#: src/Utilities/validation.ts:102
msgid "Please enter a valid domain, e.g. amazon.com"
msgstr "Indtast venligst et gyldigt domænenavn, fx. dr.dk"

#: src/Utilities/validation.ts:106
msgid "Please enter a valid URL"
msgstr "Indtast venligst en gyldig URL"

#: src/Utilities/validation.ts:110
msgid "%s is not a valid URL"
msgstr "%s er ikke en gyldig URL"

#: src/Utilities/validation.ts:117
msgid "Please enter a valid domain name"
msgstr "Indtast venligst et gyldigt domænenavn"

#: src/Utilities/validation.ts:129
msgid "Are you sure this is the name of your company?"
msgstr "Er du sikker på, at dette er navnet på dit firma?"

#: src/Utilities/validation.ts:137
msgid "Dataset name can only contain letters and underscores."
msgstr "Dataset-navnet må kun indeholder bogstaver og underscore."

#: src/Utilities/validation.ts:140
msgid "Dataset name must start and end with letter."
msgstr "Dataset-navnet skal starte og slutte med et bogstav."

#: src/Utilities/validation.ts:158
msgid "A positive number is required"
msgstr "Et positivt tal er krævet"

#: src/Utilities/validation.ts:160
msgid "Phone number is invalid"
msgstr "Telefonnummeret er ugyldigt"

#: src/Utilities/validation.ts:162 src/Utilities/validation.ts:169
#: src/Utilities/validation.ts:178
msgid "This is not a valid email"
msgstr "Dette er ikke en gyldig e-mail"

#: src/Utilities/validation.ts:168
msgid "One or more invalid emails"
msgstr "En eller flere ugyldige emails"

#: src/Utilities/validation.ts:183
msgid "Password must be at least 8 characters"
msgstr "Kodeordet skal være mindst 8 tegn langt"

#: src/Utilities/validation.ts:187
msgid "Must contain a letter or special character"
msgstr "Skal indeholde et bogstav eller specialtegn"

#: src/Utilities/validation.ts:189
msgid "Must contain a number"
msgstr "Skal indeholde et tal"

#: src/Utilities/validation.ts:191
msgid "Password can be no more than 200 characters"
msgstr "Adgangskoden må ikke bestå af mere end 200 tegn"

#: src/Utilities/validation.ts:195 src/Utilities/validation.ts:198
msgid "Your passwords do not match"
msgstr "Dine kodeord stemmer ikke overens"

#: src/Utilities/validation.ts:280 src/Utilities/validation.ts:281
msgid "Invalid VAT"
msgstr "Ugyldigt VAT"

#: src/Utilities/validation.ts:317
msgid "Invalid VAT number"
msgstr "Ugyldigt VAT nummer"

#: src/Utilities/validation.ts:346
msgid "Unmatched parenthesis"
msgstr "Uoverensstemmende parenteser"

#: src/Utilities/validation.ts:348
msgid "Unmatched square brackets"
msgstr "Uoverensstemmende firkantede parenteser"

#: src/Utilities/validation.ts:350
msgid "Unmatched curly braces"
msgstr "Uoverensstemmelse mellem krøllede parenteser"

#: src/Utilities/validation.ts:352
msgid "Invalid escape character"
msgstr "Ugyldigt escape-tegn"

#: src/Utilities/validation.ts:358
msgid "Invalid regex"
msgstr "Ugyldig regex"

#: src/Utilities/validation.ts:361
msgid "Invalid regex: "
msgstr "Ugyldig regex: "

#: src/Utilities/workspaces.ts:15
msgid "Super User"
msgstr "Superbruger"

#: src/Utilities/workspaces.ts:17
msgid "Write User"
msgstr "Skrive-bruger"

#: src/Utilities/workspaces.ts:19
msgid "Read User"
msgstr "Læse-bruger"

#: src/Utilities/workspaces.ts:27
msgid "Full Access to all Workspaces and can manage users and billing."
msgstr ""
"Fuld adgang til alle workspaces og kan administrere brugere og fakturering."

#: src/Utilities/workspaces.ts:29
msgid ""
"Full Access to all Workspaces and can manage API, Groups, Sub-accounts & "
"Workspaces."
msgstr ""
"Fuld adgang til alle workspaces og kan administrere API, grupper, underkonti "
"og workspaces."

#: src/Utilities/workspaces.ts:33
msgid ""
"Can add and delete keywords and domains but only within assigned Workspaces."
msgstr ""
"Kan tilføje og slette søgeord og domæner, men kun inden for tildelte "
"workspaces."

#: src/Utilities/workspaces.ts:35
msgid "Can only view data within assigned Workspaces."
msgstr "Kan kun se data inden for tildelte workspaces."

#~ msgid ""
#~ "Connect your BigQuery account to unlock powerful data analytics "
#~ "capabilities. \n"
#~ "\n"
#~ "        Export your keyword data directly to BigQuery for advanced "
#~ "analysis, custom reporting, and seamless integration with your existing "
#~ "data warehouse. \n"
#~ "        \n"
#~ "        Combine AccuRanker data with other marketing metrics to gain "
#~ "deeper insights into your SEO performance and make more data-driven SEO "
#~ "decisions."
#~ msgstr ""
#~ "Forbind din BigQuery konto for at få adgang til effektive "
#~ "dataanalysefunktioner.\n"
#~ "\n"
#~ "        Eksportér dine søgeord direkte til BigQuery for avanceret "
#~ "analyse, tilpasset rapportering og problemfri integration med dit "
#~ "eksisterende data warehouse. \n"
#~ "        \n"
#~ "        Kombinér AccuRanker-data med andre marketings metrikker for at få "
#~ "dybere indsigt i din SEO-performance og træffe mere datadrevne SEO-"
#~ "beslutninger."

#~ msgid "New integration - BigQuery"
#~ msgstr "Ny integration - BigQuery"

#~ msgid "New AI Overview Tab!"
#~ msgstr "Ny AI Oversigt Fane!"

#~ msgid ""
#~ "Understand how AI-generated summaries shape search rankings with greater "
#~ "clarity.\n"
#~ "\n"
#~ "Use our dedicated AI Overview page to gain in-depth insight into this "
#~ "SERP feature ✨"
#~ msgstr ""
#~ "Forstå hvordan AI-genererede oversigter former søgerangeringer med større "
#~ "klarhed.\n"
#~ "\n"
#~ "Brug vores dedikerede AI Oversigt side for at få dybtgående indsigt i "
#~ "denne SERP funktion ✨"

#~ msgid "Explore the new reports:"
#~ msgstr "Udforsk de nye rapporter:"

#~ msgid "Report Type > Public report"
#~ msgstr "Rapport Type > Offentlig rapport"

#, fuzzy
#~| msgid "There is currently no data in this table."
#~ msgid "This feature is currently not available"
#~ msgstr "Der er ingen data i denne tabel."

#, fuzzy
#~| msgid "Dynamic Tags can be deleted from the Tag Cloud tab."
#~ msgid "Dynamic Tags can be deleted from the link:Tag Cloud tab."
#~ msgstr "Dynamiske tags kan slettes fra fanen Tag Cloud."

#~ msgid "Configuration file should be in JSON format."
#~ msgstr "Konfigurationsfilen skal være i JSON-format."

#~ msgid "Hubspot"
#~ msgstr "Hubspot"

#~ msgid "Open in Hubspot"
#~ msgstr "Åben i HubSpot"

#~ msgid "No link"
#~ msgstr "Intet link"

#, fuzzy
#~| msgid "Losers"
#~ msgid "Loosers"
#~ msgstr "Tabere"

#~ msgid "%s (high to low)"
#~ msgstr "%s (høj til lav)"

#~ msgid "%s (low to high)"
#~ msgstr "%s (lav til høj)"

#~ msgid "Changes in %s (high to low)"
#~ msgstr "Ændring i %s (høj til lav)"

#~ msgid "Changes in %s (low to high)"
#~ msgstr "Ændring i %s (lav til høj)"

#~ msgid "Order by"
#~ msgstr "Sorter efter"

#~ msgid "Service window"
#~ msgstr "Servicevindue"

#~ msgid ""
#~ "Our database servers are down for maintenance.[br]We will be back online "
#~ "shortly.[br]Please try again in a few minutes."
#~ msgstr ""
#~ "Vores databaseservere er nede for vedligeholdelse.[br]Vi er tilbage "
#~ "online om kort tid.[br]Prøv venligst igen om et par minutter."

#~ msgid "The location must be unique. Please select another location."
#~ msgstr "Placeringen skal være unik. Vælg venligst en anden placering."

#~ msgid "Disconnect from Search Console"
#~ msgstr "Afbryd forbindelsen til Search Console"

#~ msgid "Times Used for Google Analytics"
#~ msgstr "Gange brugt til Google Analytics"

#~ msgid "Times Used for Google Search Console"
#~ msgstr "Gange brugt til Google Search Console"

#~ msgid "New Feature!"
#~ msgstr "Ny funktion!"

#~ msgid ""
#~ "Explore the new [b:tree view] for your domain list. This view mode "
#~ "provides a hierarchical display of all your groups, domains, and tags!"
#~ msgstr ""
#~ "Udforsk den nye [b:trævisning] for din domæneliste. Denne "
#~ "visningstilstand giver en hierarkisk visning af alle dine grupper, "
#~ "domæner og tags!"

#~ msgid "You should upgrade your account to a "
#~ msgstr "Opgrader din konto til en "

#~ msgid " keywords"
#~ msgstr " søgeord"

#~ msgid " plan"
#~ msgstr " plan"

#~ msgid " plan, as it will save you "
#~ msgstr " plan, da det vil spare dig for "

#~ msgid " a year"
#~ msgstr " årligt"

#~ msgid "Set table view density to default"
#~ msgstr "Indstil tabelvisning til standard"

#~ msgid "Set table view density to medium"
#~ msgstr "Indstil tabelvisning til medium"

#~ msgid "Set table view density to compact"
#~ msgstr "Indstil tabelvisning til kompakt"

#~ msgid ""
#~ "[br][textsm:We are excited to introduce AI Search Volume, our new search "
#~ "volume metric with much more accurate search volume estimates! [br]"
#~ "[br]You can learn more about AI Search Volume [link:here]."
#~ msgstr ""
#~ "[br][textsm:Vi er glade for at introducere AI Søgevolumen, vores nye "
#~ "søgevolumen-metrik med meget mere præcise søgevolumenestimater! [br]"
#~ "[br]Du kan lære mere om AI Søgevolumen [link:her]."

#, fuzzy
#~| msgid "Show tracked keywords"
#~ msgid "Do you track keywords today?"
#~ msgstr "Se overvågede søgeord"

#~ msgid "Where would you like to track your keywords?"
#~ msgstr "Hvor vil du gerne tracke dine søgeord?"

#~ msgid "Full name"
#~ msgstr "Fulde navn"

#~ msgid "Enter your full name"
#~ msgstr "Indtast dit fulde navn"

#~ msgid ""
#~ "[h5: AccuRanker Visual Update!][br][textsm:We are thrilled to present a "
#~ "visual update, showcasing an upgraded navigation sidebar menu and a "
#~ "variety of aesthetic enhancements throughout the app. [br][br]You can "
#~ "learn more about navigating in AccuRanker [link:here]."
#~ msgstr ""
#~ "[h5: AccuRanker Visual Update!][br][textsm:Vi er glade for at kunne "
#~ "præsentere en visuel opdatering, der viser en opgraderet "
#~ "navigationssidemenu og en række æstetiske forbedringer i hele appen. [br]"
#~ "[br]Du kan lære mere om at navigere i AccuRanker [link:her]."

#~ msgid ""
#~ "Access to the old version of the public reports is also available for a "
#~ "limited time only:"
#~ msgstr ""
#~ "Tilgå den tidligere version af public reports i en begrænset periode her:"

#~ msgid "Copy public link to old version"
#~ msgstr "Kopiér delbart link til den tidligere version"

#~ msgid "Cancel my AccuRanker Account"
#~ msgstr "Opsig mit AccuRanker-abonnement"

#~ msgid "New Feature: Card View"
#~ msgstr "Ny Funktion: Kortvisning"

#~ msgid ""
#~ "The new [b:‘Card View’] is now available on your homepage dashboard. This "
#~ "feature offers a more visual and compact way to view your information. "
#~ "You can easily switch between the [b2:‘standard table’] and the [b3:‘new "
#~ "card’] view to match your preference. [br]Give it a try to enhance your "
#~ "dashboard experience!"
#~ msgstr ""
#~ "Den nye [b:'Kortvisning'] er nu tilgængelig på dit dashboard. Denne "
#~ "funktion giver en mere visuel og kompakt måde at se dine domæner på. Du "
#~ "kan nemt skifte mellem [b2:'standardtabellen'] og [b3:'den nye kort']-"
#~ "visning, så oversigten passer til dine præferencer. [br] Tag et kig og se "
#~ "du ikke bliver vild med den nye dashboard-oplevelse!"

#~ msgid "API v4 Write"
#~ msgstr "Skrive API v4"

#~ msgid "Sign In"
#~ msgstr "Log ind"

#~ msgid "Set view to card"
#~ msgstr "Indstil til kortvisning"

#~ msgid "Filter by created date"
#~ msgstr "Filtrer efter oprettelsesdato"

#~ msgid "Filter by note"
#~ msgstr "Filtrer efter note"

#~ msgid "Filter by creator"
#~ msgstr "Filtrer efter opretter"

#~ msgid "Filter by keywords"
#~ msgstr "Filtrer efter søgeord"

#~ msgid "The domain %s will be permanently deleted."
#~ msgstr "Domænet %s bliver permanent slettet."

#~ msgid "The domain \"%s\" and all its keywords will be permanently deleted."
#~ msgstr "Domænet \"%s\" og alle dets søgeord vil blive permanent slettet."

#~ msgid ""
#~ "Congratulations, you’ve reached your account limit of [maxKeywords] "
#~ "keywords! But don’t let that stop you - easily upgrade your plan to add "
#~ "more keywords."
#~ msgstr ""
#~ "Tillykke, du har nået din kontogrænse på [maxKeywords] søgeord! Men lad "
#~ "det ikke stoppe dig - opgrader nemt din plan for at tilføje flere søgeord."

#~ msgid ""
#~ "You reached the limit because your account has deleted keywords today."
#~ msgstr "Du har nået grænsen, fordi din konto har slettet søgeord i dag."

#~ msgid ""
#~ "If you have any questions, feel free to contact our support team or your "
#~ "dedicated customer success manager."
#~ msgstr ""
#~ "Hvis du har spørgsmål, er du velkommen til at kontakte vores supportteam "
#~ "eller din dedikerede customer success manager."

#~ msgid ""
#~ "Can not select more than %s keywords. Deselect keywords in order to "
#~ "select this"
#~ msgstr ""
#~ "Kan ikke vælge mere end %s søgeord. Fravælg søgeord for at vælge dette"

#~ msgid "You can upgrade your plan [link:here]."
#~ msgstr "Du kan opgradere dit abonnement [link:her]."

#~ msgid "Keywords to be added: "
#~ msgstr "Antal søgeord der tilføjes: "

#~ msgid ""
#~ "By adding these keywords, your account will exceed its current keyword "
#~ "limit.[br]If you want to manage your keyword usage click [here:here].[br]"
#~ "[br]"
#~ msgstr ""
#~ "Ved at tilføje disse søgeord går din konto over den grænse, der er på din "
#~ "nuværende plan.[br]Klik [here:her] for at se dit søgeordsforbrug.[br][br]"

#~ msgid ""
#~ "By adding these keywords, your account will exceed its current keyword "
#~ "limit. [br]If you want to manage your keyword usage click [here:here]."
#~ msgstr ""
#~ "Ved at tilføje disse søgeord går din konto over den grænse, der er på din "
#~ "nuværende plan.[br]Klik [here:her] for at se dit søgeordsforbrug."

#~ msgid "Continue"
#~ msgstr "Fortsæt"

#~ msgid "Domain move job started…"
#~ msgstr "Domæne flyttejob startet…"

#~ msgid "Transfer failed"
#~ msgstr "Flytning fejlet"

#~ msgid ""
#~ "The organization you are trying to transfer the domain to does not have "
#~ "enough keywords on their current plan. [br]If you want to manage keyword "
#~ "usage on the plan click [here:here]."
#~ msgstr ""
#~ "Organisationen du forsøger at flytte domænet til har ikke nok keywords på "
#~ "dens nuværende plan. [br]Hvis du vil styre søgeordsforbruget på planen "
#~ "klik [here:her]."

#~ msgid "Thank you for connecting with Google Search Console!"
#~ msgstr "Tak for at forbinde til Google Search Console!"

#~ msgid ""
#~ "We appreciate your partnership and are excited to work with you. You will "
#~ "soon receive an email containing all the details and updates about our "
#~ "new Keyword Research universe. We look forward to collaborating with you "
#~ "and your team to achieve great results together."
#~ msgstr ""
#~ "Vi værdsætter dit partnerskab og ser frem til at arbejde sammen med dig. "
#~ "Du vil snart modtage en email med alle detaljer og opdateringer om vores "
#~ "nye søgeordsanalyse-univers. Vi ser frem til at samarbejde med dig og dit "
#~ "team for at opnå fantastiske resultater sammen."

#~ msgid "Join AccuRanker's new Keyword Research product group?"
#~ msgstr "Bliv medlem af AccuRanker's nye produktgruppe for søgeordsanalyse?"

#~ msgid ""
#~ "Be a part of the future with AccuRanker's beta access! Shape the future "
#~ "of keyword research tools by influencing features, design, and "
#~ "functionality. Limited slots available—connect your Google Search Console "
#~ "now!"
#~ msgstr ""
#~ "Bliv en del af fremtiden med AccuRanker's beta-adgang! Form fremtiden for "
#~ "søgeordsanalyseværktøjer ved at påvirke funktioner, design og "
#~ "funktionalitet. Begrænsede pladser tilgængelige – tilslut din Google "
#~ "Search Console nu!"

#~ msgid ""
#~ "We've given our public reports a major overhaul featuring a cleaner "
#~ "design.\n"
#~ "\n"
#~ "[explore]\n"
#~ "Go to [reportType] to see the updates in action."
#~ msgstr ""
#~ "Offentlige rapporter har nu fået et nyt og forbedret design.\n"
#~ "\n"
#~ "[explore]\n"
#~ "Gå til [reportType] for at se opdateringen live."

#~ msgid ""
#~ "You can now manage user roles and workspaces. Specify user roles to "
#~ "control user permissions, and assign workspaces to control which groups "
#~ "users can access.[br]To learn more, read the related [link:blog post]."
#~ msgstr ""
#~ "Du kan nu administrere brugerroller og workspaces. Angiv brugerroller for "
#~ "at kontrollere brugerrettigheder, og tildel workspaces for at "
#~ "kontrollere, hvilke grupper brugere kan få adgang til.[br]For at lære "
#~ "mere, læs det relaterede [link:blogindlæg]."

#~ msgid "Your subscription has now been canceled."
#~ msgstr "Dit abonnement er nu blevet annulleret."

#~ msgid ""
#~ "Your report is now ready, download should have started but if it haven't "
#~ "please click the download link below"
#~ msgstr ""
#~ "Din rapport er nu klar. Download skulle have startet, hvis ikke kan du "
#~ "klikke på linket nedenunder"

#~ msgid ""
#~ "Your report is now ready! It's been saved to your connected Google "
#~ "account. "
#~ msgstr ""
#~ "Din rapport er nu klar! Den er gemt til din forbundne Google konto. "

#~ msgid "[googleSheetsLink:Click here] to see the report on Google Sheets."
#~ msgstr "[googleSheetsLink:Klik her] for at se rapporten på Google Sheets."

#, fuzzy
#~| msgid "groups"
#~ msgid "All groups"
#~ msgstr "grupper"

#, fuzzy
#~| msgid "Last Visited"
#~ msgid "Last Edited"
#~ msgstr "Sidste besøg"

#~ msgid "Admin users can access and manage the Account menu"
#~ msgstr "Admin-brugere kan tilgå og ændre indstillinger i Konto menuen"

#~ msgid "User Type"
#~ msgstr "Brugertype"

#~ msgid "We found no tags or folders to show for this domain. "
#~ msgstr "Vi fandt ingen tags eller mapper at vise for dette domæne. "

#~ msgid "Invite new user"
#~ msgstr "Inviter ny bruger"

#~ msgid "Organization admin"
#~ msgstr "Afdelingsadmin"

#~ msgid ""
#~ "You are seeing this editor because you are logged in. Your client will "
#~ "only see the text you write, not the editor"
#~ msgstr ""
#~ "Du ser denne editor, fordi du er logget ind. Din klient vil kun se den "
#~ "tekst, du skriver, ikke editoren."

#~ msgid "Exclude"
#~ msgstr "Ekskluder"

#~ msgid "Enter the location"
#~ msgstr "Indtast lokation"

#~ msgid "Reset filters"
#~ msgstr "Nulstil filtre"

#~ msgid ""
#~ "If this keeps happening, please let us know. Any details on how to "
#~ "duplicate the error is deeply appreciated!"
#~ msgstr ""
#~ "Kontakt os hvis dette bliver ved med at ske. Vi bliver rigtigt glade for "
#~ "instruktioner omkring hvordan vi kan genskabe fejlen!"

#~ msgid "Click to open"
#~ msgstr "Klik for at åbne"

#~ msgid "Both static and dynamic tags with this name"
#~ msgstr "Både statiske og dynamiske tags med dette navn"

#~ msgid "All other tags"
#~ msgstr "Alle andre tags"

#~ msgid ""
#~ "Easily drag & drop to arrange your dashboard layout. Add or remove charts "
#~ "to tailor the dashboard to your needs."
#~ msgstr ""
#~ "Drag og drop for at arrangere dit dashboard layout. Tilføj eller fjern "
#~ "Widgets for at tilpasse dashboardet til dine behov."

#~ msgid ""
#~ "Click this to reset the public report link. By doing so old links will "
#~ "stop working!"
#~ msgstr ""
#~ "Klik her for at nulstille linket til den offentlige rapport. Hvis du gør "
#~ "det holder det gamle link op med at virke!"

#~ msgid ""
#~ "Don't see the Google Account you are looking for? You can always connect "
#~ "an additional account [link:here]."
#~ msgstr ""
#~ "Kan du ikke finde den Google konto du leder efter? Du kan altid forbinde "
#~ "en konto mere [link:her]."

#~ msgid "Select Google connection"
#~ msgstr "Vælg forbindelse til Google"

#~ msgid "Add YouTube Account"
#~ msgstr "Tilføj YouTube konto"

#~ msgid "Connected YouTube Account"
#~ msgstr "Forbundne YouTube kontoer"

#~ msgid ""
#~ "Your YouTube account has been connected, you can now create reports that "
#~ "are directly saved in Google Drive."
#~ msgstr ""
#~ "Din YouTube-konto er forbundet. Du kan nu oprette rapporter direkte til "
#~ "dit Google Drive."

#~ msgid "Click to see your Navigational keywords"
#~ msgstr "Klik for at se dine Navigational søgeord"

#~ msgid "Click to see your Informational keywords"
#~ msgstr "Klik for at se dine Informational søgeord"

#~ msgid "Click to see your Commercial keywords"
#~ msgstr "Klik for at se dine Commercial søgeord"

#~ msgid "Click to see your Transactional keywords"
#~ msgstr "Klik for at se dine Transactional søgeord"

#~ msgid "Sort by Domain"
#~ msgstr "Sorter efter domæne"

#~ msgid "Sort by Search Type"
#~ msgstr "Sorter efter søgetype"

#~ msgid "Sort by Search Intent"
#~ msgstr "Sorter efter søgeintention"

#~ msgid "Sort by Description"
#~ msgstr "Sorter efter beskrivelse"

#~ msgid "Sort by Location"
#~ msgstr "Sorter efter lokation"

#~ msgid "Sort by Rank"
#~ msgstr "Sorter efter rank"

#~ msgid "Sort by Base Rank"
#~ msgstr "Sorter efter rå placering"

#~ msgid "Sort by Max Base Rank"
#~ msgstr "Sorter efter max rå placering"

#~ msgid "Sort by Local Pack Rank"
#~ msgstr "Sorter efter lokal placering"

#~ msgid "AI Traf. Val."
#~ msgstr "AI Trafikværdi"

#~ msgid "Sort by AI Traffic Value (CPC * AI SoV)"
#~ msgstr "Sorter efter AI Trafikværdi (CPC * AI Synlighed)"

#~ msgid "Sort by max potential AI Traffic Value (CPC * AI SoV)"
#~ msgstr "Sorter efter Max. Pot. AI-trafikværdi (CPC * AI Synlighed)"

#~ msgid "Sort by Pixels from Top"
#~ msgstr "Sorter efter pixels fra toppen"

#~ msgid "Sort by URL"
#~ msgstr "Sorter efter URL"

#~ msgid "Sort by average monthly Search Volume"
#~ msgstr "Sorter efter gennemsnitlig månedlig søgevolumen"

#~ msgid "Sort by Click-through Rate"
#~ msgstr "Sorter efter click-through rate"

#~ msgid "Sort by change in Click-through Rate"
#~ msgstr "Sorter efter ændring i click-through rate"

#~ msgid "Sort by Max Click-through Rate on page"
#~ msgstr "Sorter efter max click-through rate på siden"

#~ msgid "Sort by Max AI SoV"
#~ msgstr "Sorter efter max AI synlighed"

#~ msgid "Comp."
#~ msgstr "Konk."

#~ msgid "Sort by change in Share of Voice"
#~ msgstr "Sorter efter ændring i synlighed"

#~ msgid "Sort by Max Share of Voice"
#~ msgstr "Sorter efter max synlighed"

#~ msgid "Traf. Val."
#~ msgstr "Trafikværdi"

#~ msgid "The Traffic Value of your SoV (CPC * SoV)"
#~ msgstr "Total værdi af din synlighed (CPC * Synlighed)"

#~ msgid "Sort by Max Traffic Value (CPC * Max SoV)"
#~ msgstr "Sorter efter max trafikværdi (CPC * Max Synlighed)"

#~ msgid "Sort by date when the keyword was added"
#~ msgstr "Sorter efter dato hvor søgeordet blev tilføjet"

#~ msgid "Last update"
#~ msgstr "Senest opdateret"

#~ msgid "Sort by time since the keyword was updated"
#~ msgstr "Sorter efter dato hvor søgeordet blev tilføjet"

#~ msgid "Help"
#~ msgstr "Hjælpe"

#~ msgid "See in dashboard"
#~ msgstr "Se på Overblik"

#~ msgid "Click to see landing page in Organic Site Explorer"
#~ msgstr "Klik for at se landingssiden i Organisk Site Explorer"

#~ msgid "Quick navigate"
#~ msgstr "Hurtig-navigation"

#~ msgid "Failed to change password"
#~ msgstr "Kunne ikke ændre kodeord"

#~ msgid "Failed to open modal."
#~ msgstr "Kunne ikke åbne vinduet."

#~ msgid "Make affiliate"
#~ msgstr "Lav til affiliate"

#~ msgid "Make Affiliate"
#~ msgstr "Lav til affiliate"

#~ msgid "Market Analysis"
#~ msgstr "Markedsanalyse"

#~ msgid "Enter domain name"
#~ msgstr "Indtast domænet"

#~ msgid "Customer Email"
#~ msgstr "Kundeemail"

#~ msgid "Enter customer email"
#~ msgstr "Indtast kundens e-mail"

#~ msgid "Customer Name"
#~ msgstr "Kundenavn"

#~ msgid "Enter customer name"
#~ msgstr "Indtast kundens navn"

#~ msgid "Add Market Analysis"
#~ msgstr "Tilføj markedsanalyse"

#~ msgid "Did not find any market analyses."
#~ msgstr "Fandt ingen markedsanalyse."

#~ msgid "Could not delete Market Analysis page"
#~ msgstr "Kunne ikke slette markedsanalyse siden"

#~ msgid "Market Analysis page deleted"
#~ msgstr "Markedsanalysesiden slettet"

#~ msgid "Delete Market Analysis Page?"
#~ msgstr "Slet markedsanalysesiden?"

#~ msgid "The Market Analysis page will be permanently deleted."
#~ msgstr "Markedsanalysesiden bliver permanent slettet."

#~ msgid "Go to"
#~ msgstr "Gå til"

#~ msgid "Customer Mail"
#~ msgstr "Kundeemail"

#~ msgid "Number of Visits"
#~ msgstr "Antal besøgende"

#~ msgid "Delete row"
#~ msgstr "Slet række"

#~ msgid "Customize your dashboard"
#~ msgstr "Tilpas dit dashboard"

#~ msgid "Filter by this dynamic tag:  %s"
#~ msgstr "Filtrer med dette dynamiske tag: %s"

#~ msgid "Filter by this static tag: %s"
#~ msgstr "Filtrer med dette statiske tag: %s"

#~ msgid "Remove tag: %s"
#~ msgstr "Fjern tag: %s"

#~ msgid "Dynamic tag:  %s"
#~ msgstr "Dynamisk tag: %s"

#~ msgid "Filter by this tag: %s"
#~ msgstr "Filtrer med dette tag: %s"

#~ msgid "Remove tag:  %s"
#~ msgstr "Slet tag: %s"

#~ msgid "Help Guide"
#~ msgstr "Hjælpeguide"

#~ msgid "[serpLabel] [owned] [beforeRank]"
#~ msgstr "[serpLabel] [owned] [beforeRank]"

#~ msgid "Remove chart"
#~ msgstr "Slet graf"

#~ msgid "Add chart"
#~ msgstr "Tilføj graf"

#~ msgid "Change chart"
#~ msgstr "Skift graf"

#~ msgid "This box will disappear when you leave edit mode"
#~ msgstr "Denne boks forsvinder, når du forlader redigeringstilstand"

#~ msgid "KPI"
#~ msgstr "KPI"

#~ msgid "Chart"
#~ msgstr "Graf"

#~ msgid "Not selected %ss"
#~ msgstr "Ikke valgte %ss"

#~ msgid "Selected %ss"
#~ msgstr "Valgte %ss"

#~ msgid "Remove %s"
#~ msgstr "Slet %s"

#~ msgid "Dynamic CTR"
#~ msgstr "Dynamisk CTR"

#~ msgid "Click to configure which charts are shown"
#~ msgstr "Klik for at konfigurere hvilke grafer der vises"

#~ msgid "Configure overview"
#~ msgstr "Konfigurer overblik"

#~ msgid "Restore default charts"
#~ msgstr "Gendan standard grafer"

#~ msgid "Winners:"
#~ msgstr "Vindere:"

#~ msgid "Keywords with an increase in rank since the compare date."
#~ msgstr "Søgeord med forbedret placering siden sammenligningsdatoen."

#~ msgid "Losers:"
#~ msgstr "Tabere:"

#~ msgid "The compare date:"
#~ msgstr "Sammenligningsdatoen:"

#~ msgid "The date selected in the date picker in the top right of the page."
#~ msgstr "Datoen valgt i dato-vælgeren øverst til højre på siden."

#~ msgid ""
#~ "Click the Winners/Losers cards to see the corresponding keywords in the "
#~ "table below, or click the icon in the top right corner of the card to see "
#~ "the keywords in the keyword list."
#~ msgstr ""
#~ "Klik på Vindere/Tabere boksene for at se de tilsvarende søgeord i "
#~ "tabellen nedenfor, eller klik på ikonet i højre hjørne af hver boks for "
#~ "at se søgeordene i søgeordslisten."

#~ msgid "Is this note only related to certain keywords?"
#~ msgstr "Er notatet kun relevant for bestemte søgeord?"

#~ msgid "Default template"
#~ msgstr "Standardskabelon"

#~ msgid "Select…"
#~ msgstr "Vælg…"

#~ msgid "Accepted overuse?"
#~ msgstr "Accepteret overforbrug?"

#~ msgid "You can now reorder columns."
#~ msgstr "Du kan nu omarrangere kolonner."

#~ msgid "CTR Potential"
#~ msgstr "CTR potentiale"

#~ msgid "Prev"
#~ msgstr "Forrige"

#~ msgid "Winner"
#~ msgstr "Vinder"

#~ msgid "Click to show more"
#~ msgstr "Klik for at se mere"

#~ msgid "Sort by country"
#~ msgstr "Sorter efter land"

#~ msgid "Filter locale…"
#~ msgstr "Filtrer lokation…"

#~ msgid "Filter base rank…"
#~ msgstr "Filtrer rå placering…"

#~ msgid "Filter local pack rank…"
#~ msgstr "Filtrer lokel placering…"

#~ msgid "Change in AI traffic value between the two compared dates"
#~ msgstr "Ændring i AI Traffikværdi mellem de to sammenlignede datoer"

#~ msgid "Filter CTR…"
#~ msgstr "Filtrer CTR…"

#~ msgid "Sort by Max. SoV"
#~ msgstr "Sorter efter AI Max Synlighed"

#~ msgid "Filter traffic value…"
#~ msgstr "Filtrer trafikværdi…"

#~ msgid "Comfortable"
#~ msgstr "Komfortabel"

#~ msgid "Table view mode:"
#~ msgstr "Tablelvisnings tilstand:"

#~ msgid "Table settings"
#~ msgstr "Tabelindstillinger"

#~ msgid "Ad Comp."
#~ msgstr "Ann.-kon."

#~ msgid "Show existing"
#~ msgstr "Vis eksisterende"

#~ msgid "Hide existing"
#~ msgstr "Skjul eksisterende"

#~ msgid "Date & Compare to"
#~ msgstr "Dato & Sammenlign med"

#~ msgid "Latest compared to"
#~ msgstr "Sidste sammenlignet med"

#, fuzzy
#~| msgid "Credit"
#~ msgid "Credits:"
#~ msgstr "Kredit"

#~ msgid "Credit used"
#~ msgstr "Anvendt credits"

#, fuzzy
#~| msgid "Click to edit"
#~ msgid "Click to see top 10"
#~ msgstr "Klik for at redigere"

#~ msgid "Top domain"
#~ msgstr "Top domæne"

#, fuzzy
#~| msgid "Go to dashboard"
#~ msgid "Show dashboard"
#~ msgstr "Gå til overblik"

#, fuzzy
#~| msgid " keywords"
#~ msgid "Show keywords"
#~ msgstr " søgeord"

#~ msgid "No Search Volume data for the selected filters"
#~ msgstr "Ingen Søgevolumen-data med den valgte filtrering"

#~ msgid "No search volume data for the selected period"
#~ msgstr "Ingen søgevolumen-data for den valgte periode"

#~ msgid "%s rows"
#~ msgstr "%s rækker"

#~ msgid "Show"
#~ msgstr "Vis"

#~ msgid "Account Type"
#~ msgstr "Kontotype"

#~ msgid "Enter tag"
#~ msgstr "Indtast tag"

#~ msgid "Provider"
#~ msgstr "Udbyder"

#~ msgid ""
#~ "Select the Adobe connection you want to use for connecting this domain to "
#~ "Adobe Analytics."
#~ msgstr ""
#~ "Vælg den Adobe forbindelse du vil bruge til at forbinde dette domæne til "
#~ "Adobe Analytics."

#~ msgid "API Write"
#~ msgstr "Skrive API"

#~ msgid "Advanced Metrics"
#~ msgstr "Avancerede beregninger"

#~ msgid "Max domains"
#~ msgstr "Max antal domæner"

#~ msgid "Please add subdomain filter to see data."
#~ msgstr "Til venligst underdomæne filter for at se data."

#~ msgid "Loading %s from server…"
#~ msgstr "Henter %s fra serveren…"

#~ msgid "%s of %s %s loaded"
#~ msgstr "%s af %s %s hentet"

#~ msgid "Back to top"
#~ msgstr "Tilbage til toppen"

#~ msgid "No domains found on your account"
#~ msgstr "Ingen domæner fundet på din konto"

#~ msgid "Contacting Google"
#~ msgstr "Forbinder til Google Search Console"

#~ msgid "Price yearly EUR"
#~ msgstr "Årlig pris i EUR"

#~ msgid "api filters"
#~ msgstr "api filtre"

#~ msgid "# Unpaid"
#~ msgstr "# Ikke betalt"

#~ msgid "# Total"
#~ msgstr "# I alt"

#~ msgid "invoice customers"
#~ msgstr "fakturabetalende kunder"

#~ msgid "owners"
#~ msgstr "ejere"

#~ msgid "pending owners"
#~ msgstr "afventende ejere"

#~ msgid ""
#~ "Also you can update your payment details [paymentMethodLink:here] or "
#~ "billing info [billingInfoLink:here]."
#~ msgstr ""
#~ "Du kan også opdatere dine betalingsdetaljer [paymentMethodLink:her] og "
#~ "betalingsinfo [billingInfoLink:her]."

#~ msgid "templates"
#~ msgstr "skabeloner"

#~ msgid "reports"
#~ msgstr "rapporter"

#~ msgid "scheduled reports"
#~ msgstr "planlagte rapporter"

#~ msgid "Wizard"
#~ msgstr "Wizard"

#~ msgid "Voucher added"
#~ msgid_plural "Vouchers added"
#~ msgstr[0] "Kupon tilføjet"
#~ msgstr[1] "Kuponer tilføjet"

#~ msgid "Vouchers"
#~ msgstr "Kuponer"

#~ msgid "Enter vouchers one per line"
#~ msgstr "Indtast én kupon per linie"

#~ msgid "Add vouchers"
#~ msgstr "Tilføj kuponer"

#~ msgid "Add Prepaid Voucher"
#~ msgstr "Tilføj forudbetalt kupon"

#~ msgid "Add prepaid voucher"
#~ msgstr "Tilføj forudbetalt kupon"

#~ msgid "Only admin users can add prepaid vouchers"
#~ msgstr "Det er kun administratorer der kan tilføje forudbetalte kuponer"

#, fuzzy
#~| msgid "Import"
#~ msgid "/import"
#~ msgstr "Importer"

#, fuzzy
#~| msgid "Report Templates"
#~ msgid "/import/examples"
#~ msgstr "Rapportskabeloner"

#~ msgid "Payment Info"
#~ msgstr "Betalingsinfo"

#~ msgid "Add new user"
#~ msgstr "Opret bruger"

#~ msgid ""
#~ "[h4: Ignore domains, Max AI SoV and much more!][br][br][textsm:A load of "
#~ "big features just landed! Introducing metrics [br]such as Max AI SoV, AI "
#~ "Traffic Value and Pixels from top.[br]Configure your KPIs and ignore "
#~ "domains in rankings. [br][br]Learn about these and many more new features "
#~ "in [br]our [link:release log]! "
#~ msgstr ""
#~ "[h4: Ignorer domæner, Max AI SoV og meget mere!][br][br][textsm:En masse "
#~ "fede nye funktioner er lige lanceret! Vi introducerer[br]metrikker som "
#~ "Max AI Synlighed, AI Trafikværdi og Pixels [br] fra Top.[br]Konfigurer "
#~ "hvilke KPI'er du ser og ignorer domæner i dine [br]placeringer. Læs mere "
#~ "om disse og mange flere nye [br]funktioner i vores [link:release log]! "

#~ msgid "Sign in with "
#~ msgstr "Log ind med "

#~ msgid "Microsoft"
#~ msgstr "Microsoft"

#~ msgid "Welcome back"
#~ msgstr "Velkommen tilbage"

#~ msgid "Welcome to"
#~ msgstr "Velkommen til"

#~ msgid "[loginLink:Login] or [signUpLink:Sign up] to access your account."
#~ msgstr ""
#~ "[loginLink:Log ind] eller [signUpLink:Opret dig] for at tilgå din konto."

#~ msgid "Update billing info"
#~ msgstr "Opdater faktureringsinfo"

#~ msgid "Only admin users can update billing information"
#~ msgstr "Det er kun administrator-brugere der kan opdatere betalingsmetoden"

#~ msgid "Only admin users can update payment method"
#~ msgstr "Det er kun administrator-brugere der opdatere betalingsmetoden"

#~ msgid "Profile Settings"
#~ msgstr "Profil Indstillinger"

#~ msgid "Trial Expired"
#~ msgstr "Prøveperiode udløbet"

#~ msgid ""
#~ "If you would like to continue using AccuRanker, please subscribe to a "
#~ "plan."
#~ msgstr ""
#~ "Hvis du fortsat ønsker at bruge AccuRanker skal du betale for et "
#~ "abonnement."

#~ msgid "Pending requests"
#~ msgstr "Afventende forespørgsler"

#~ msgid "Manage sub-accounts"
#~ msgstr "Administrer under-kontoer"

#~ msgid ""
#~ "We support importing keywords or keywords incl. rank history. Below are "
#~ "shown the required columns for each  of the two import modes as well as "
#~ "example files available for download."
#~ msgstr ""
#~ "Vi understøtter import af søgeord med og uden placeringshistorie. "
#~ "Nedenfor vises de krævede kolonner for hver af de to importtilstande samt "
#~ "eksempler, som der kan hentes."

#~ msgid "Example:"
#~ msgstr "Eksempel:"

#~ msgid "Import From Google Search Console"
#~ msgstr "Importer fra Google Search Console"

#~ msgid "Import From Third Parties"
#~ msgstr "Importer fra tredjeparter"

#~ msgid "Schedule Report"
#~ msgstr "Planlæg rapport"

#~ msgid "Edit Schedule Report"
#~ msgstr "Rediger planlagt rapport"

#~ msgid "Domains in %s"
#~ msgstr "Domæner i %s"

#~ msgid "GET 90% OFF - FIRST TWO MONTHS"
#~ msgstr "FÅ 90% RABAT - FØRSTE TO MÅNEDER"

#~ msgid "CLAIM OFFER"
#~ msgstr "BENYT TILBUD"

#~ msgid "Customer [br]satisfaction"
#~ msgstr "Kunde [br]tilfredshed"

#~ msgid "Please enter your company information."
#~ msgstr "Indtast dine firmainformationer."

#~ msgid "Please enter your contact information."
#~ msgstr "Indtast dine kontaktinformationer."

#~ msgid "Please enter your address information."
#~ msgstr "Indtast dine adresseinformationer."

#~ msgid "Please enter your payment information."
#~ msgstr "Indtast dine betalingsinformationer."

#~ msgid "Please agree to our Terms and Conditions."
#~ msgstr "Accepter venligst vores betingelser og vilkår."

#~ msgid "Please fill out the missing fields marked with red."
#~ msgstr "Udfyld de manglende felter markeret med rød."

#~ msgid ""
#~ "After your trial, you will be charged $%s per month. You can cancel or "
#~ "change your plan at any time on your account page."
#~ msgstr ""
#~ "Efter din prøveperiode vil du blive opkrævet %s$ pr. måned. Du kan til "
#~ "enhver tid annullere eller ændre din plan på din kontoside."

#~ msgid "Missing required fields"
#~ msgstr "Påkrævede felter mangler"

#~ msgid "Enter your company URL"
#~ msgstr "Indtast firma URL"

#~ msgid "Job Title"
#~ msgstr "Jobtitel"

#~ msgid "Enter your job title"
#~ msgstr "Indtast din jobtitel"

#~ msgid "Your phone number is needed for verification"
#~ msgstr "Dit telefonnummer er nødvendigt for bekræfte oprettelsen"

#~ msgid "Work Email"
#~ msgstr "Arbejdsmail"

#~ msgid ""
#~ "I agree to the [termsLink:Terms and Conditions] and [policyLink:Privacy "
#~ "Policy] *"
#~ msgstr ""
#~ "Jeg accepterer [termsLink:betingelser og vilkår] og [policyLink:"
#~ "privatlivspolitikken] *"

#~ msgid "%s trial plan with %s keywords"
#~ msgstr "%s prøveabonnement med %s søgeord"

#~ msgid "First %s days are free, hereafter $%s monthly"
#~ msgstr "De første %s dage er gratis, derefter $%s månedligt"

#~ msgid "You will get access to a %s days free trial period"
#~ msgstr "Du vil få adgang til en %s-dages gratis prøveperiode"

#~ msgid "Final step, activate your trial account"
#~ msgstr "Sidste skridt: aktivér din prøvekonto"

#~ msgid "First %s days free, then $%s monthly"
#~ msgstr "De første %s dage gratis, derefter $%s månedligt"

#~ msgid "%s months $%s, then $%s monthly"
#~ msgstr "%s måneder $%s, derefter $%s månedligt"

#~ msgid "No credit card required for %s keyword trial"
#~ msgstr "Intet kreditkort påkrævet for en prøveperiode med %s søgeord"

#~ msgid ""
#~ "You sign up for a paid plan and pay immediately. If you are looking for a "
#~ "trial, please contact us at support@accuranker"
#~ msgstr ""
#~ "Du tilmelder dig en betalt plan og betaler med det samme. Hvis du er på "
#~ "udkig efter en prøveperiode, bedes du kontakte os på support@accuranker"

#~ msgid "Contact Information"
#~ msgstr "Kontaktinfo"

#~ msgid "Company Information"
#~ msgstr "Firmainformation"

#~ msgid "ACTIVATE MY TRIAL"
#~ msgstr "AKTIVÉR MIN PRØVEPERIODE"

#~ msgid "PAY"
#~ msgstr "BETAL"

#~ msgid "Logout"
#~ msgstr "Log ud"

#~ msgid "Affiliate Dashboard"
#~ msgstr "Affiliate-overblik"

#~ msgid "Commission"
#~ msgstr "Kommission"

#~ msgid "MRR"
#~ msgstr "MRR"

#~ msgid "affiliates"
#~ msgstr "affiliates"

#~ msgid "Affiliate Metrics"
#~ msgstr "Affiliate beregninger"

#~ msgid "Unable to save template | %s"
#~ msgstr "Kunne ikke gemme skabelonen | %s"

#~ msgid "Agency"
#~ msgstr "Bureau"

#~ msgid "E-commerce"
#~ msgstr "E-commerce"

#~ msgid "Brand"
#~ msgstr "Mærke"

#~ msgid "Independent/Consultant"
#~ msgstr "Uafhængig/Konsulent"

#~ msgid "R.K."
#~ msgstr "R.K."

#~ msgid ""
#~ "Amount of requested keywords selected by user when they created the "
#~ "account."
#~ msgstr "Antal søgeord valgt af brugeren da han oprettede kontoen."

#~ msgid "Employees"
#~ msgstr "Ansatte"

#~ msgid "1"
#~ msgstr "1"

#~ msgid "16 to 50"
#~ msgstr "16 til 50"

#~ msgid "51 to 200"
#~ msgstr "51 til 200"

#~ msgid "201 to 1,000"
#~ msgstr "201 til 1.000"

#~ msgid "1,000 or more"
#~ msgstr "1.000 eller flere"

#~ msgid "In Dialogue"
#~ msgstr "I dialog"

#~ msgid "pending requests"
#~ msgstr "afventende forespørgsler"

#~ msgid "new customers"
#~ msgstr "nye kunder"

#~ msgid "Organization, Sales manager…"
#~ msgstr "Firma, Salgsmanager…"

#~ msgid "organizations"
#~ msgstr "afdelinger"

#~ msgid "wallets"
#~ msgstr "punge"

#~ msgid "commissions"
#~ msgstr "provisioner"

#~ msgid "payments"
#~ msgstr "betalinger"

#~ msgid "referrers"
#~ msgstr "henvisninger"

#~ msgid "invoices"
#~ msgstr "fakturaer"

#~ msgid "connected accounts"
#~ msgstr "forbundne kontoer"

#~ msgid "Is refund"
#~ msgstr "Refunderet"

#~ msgid "Upd. con. inf."
#~ msgstr "Opd. kon. inf."

#~ msgid ""
#~ "You can manually refresh on [link:the keywords list] or wait untill "
#~ "[nextScheduledRefresh] for the automatic scheduled refresh."
#~ msgstr ""
#~ "Du kan opdatere manuelt på [link:the keywords list] eller vente til "
#~ "[nextScheduledRefresh] for den planlagte automatiske opdatering."

#~ msgid ""
#~ "We just released a new version of AccuRanker 🎉. You can read the release "
#~ "notes below and should you have any questions or comments, then feel free "
#~ "to message our support team."
#~ msgstr ""
#~ "Vi har lige udgivet en ny version af AccuRanker 🎉. Du kan læse om "
#~ "ændringerne nedenunder. Hvis du har spørgsmål eller kommentarer så skriv "
#~ "endelig til vores supporthold."

#~ msgid ""
#~ "Since your last visit we have released [releasesNumber] updates to "
#~ "AccuRanker! If you wish to read all the release notes click [link:here]."
#~ msgstr ""
#~ "Siden dit sidste besøg har vi udgivet [releasesNumber] opdateringer til "
#~ "AccuRanker! Du kan klikke [link:her] for at læse om alle ændringerne."

#~ msgid "Twitter"
#~ msgstr "Twitter"

#, fuzzy
#~| msgid ""
#~| "Please enter you TikTok information in one of the following formats:"
#~| "[break1] 1. [b1:YourTikTokHandle] [break2]2. [b2:tiktok.com/"
#~| "YourTikTokHandle]"
#~ msgid ""
#~ "Please enter your Twitter information in one of the following formats:"
#~ "[break1] 1. [b1:YourTwitterHandle] [break2]2. [b2:twitter.com/"
#~ "YourTwitterHandle]"
#~ msgstr ""
#~ "Indtast dine TikTok-oplysninger i et af følgende formater:[break1] 1. [b1:"
#~ "DitTikTokHandle] [break2]2. [b2:tiktok.com/DitTikTokHandle]"

#~ msgid "@TwitterHandle"
#~ msgstr "@TwitterNavn"

#~ msgid "Yearly price"
#~ msgstr "Årlig pris"

#~ msgid "plans"
#~ msgstr "abonnementer"

#~ msgid "CTR max"
#~ msgstr "CTR maks."

#~ msgid "Type to add an ignored domain, e.g. example.com"
#~ msgstr "Skriv for at tilføje et ignoreret domæne, f.eks. example.com"

#~ msgid ""
#~ "[h4: Multiple filters and social media tracking!][textsm:You can now "
#~ "track your company SoMe profiles on[br]Facebook, Instagram, LinkedIn, "
#~ "Twitter and TikTok.[br]We've also made it possible to have multiple of "
#~ "the [br]same filter.[br]Learn more and check out the newest features in "
#~ "[br]our [link:release log]! "
#~ msgstr ""
#~ "[h4: Flere filtre og sporing på sociale medier!][textsm:Du kan nu spore "
#~ "din virksomheds SoMe-profiler på [br]Facebook, Instagram, LinkedIn, "
#~ "Twitter og TikTok.[br]Vi har også gjort det muligt at have flere af det "
#~ "[br]samme filter.[br]Læs mere og tjek de nyeste funktioner i [br]vores "
#~ "[link:release log]! "

#~ msgid "CTR Max"
#~ msgstr "CTR Maks"

#~ msgid "CTR potential"
#~ msgstr "CTR potentiale"

#~ msgid "Search Volume History for [keyword]"
#~ msgstr "Søgevolumenhistorik for \"[keyword]\""

#~ msgid "Error occurred…"
#~ msgstr "Der skete en fejl…"

#~ msgid ""
#~ "An error happened when trying to fetch the data. If the problem persist, "
#~ "contact AccuRanker"
#~ msgstr ""
#~ "Der opstod en fejl, da data skulle hentes. Kontakt AccuRanker hvis "
#~ "problemet fortsætter"

#~ msgid "Connect to analytics"
#~ msgstr "Forbind til Analytics"

#~ msgid "Disconnect from analytics"
#~ msgstr "Afbryd forbindelsen til Analytics"

#~ msgid "Connect to search console"
#~ msgstr "Forbind til Search Console"

#~ msgid "Connect domains to analytics"
#~ msgstr "Forbind domæner til analytics"

#~ msgid "Connect domains to search console"
#~ msgstr "Forbind domæner til Search Console"

#~ msgid ""
#~ "These integrations requires API. API is part of the [link1:Professional], "
#~ "[link2:Expert] and [link3:Enterprise] plans."
#~ msgstr ""
#~ "Disse integrationer kræver API. API er en del af [link1:Professional], "
#~ "[link2:Expert] og [link3:Enterprise] abonnementerne."

#~ msgid "sub accounts"
#~ msgstr "under-kontoer"

#~ msgid "Type to add a ignored domain, e.g. example.com"
#~ msgstr "Skriv for at tilføje et ignoreret domæne, f.eks. example.com"

#~ msgid "Optional message"
#~ msgstr "Valgfri besked"

#, fuzzy
#~| msgid "Add Google Analytics Account"
#~ msgid "No properties found on this Google Analytics account"
#~ msgstr "Tilføj Google Analytics-Konto"

#~ msgid "All have"
#~ msgstr "Alle har"

#~ msgid "Some have"
#~ msgstr "Nogle har"

#~ msgid "None has"
#~ msgstr "Ingen har"

#~ msgid "Register"
#~ msgstr "Registrer"

#~ msgid "Your company domain"
#~ msgstr "Din virksomheds domæne"

#~ msgid "Your full name"
#~ msgstr "Dit fulde navn"

#~ msgid "Your work email"
#~ msgstr "Din arbejds-email"

#~ msgid "The world's fastest & most accurate rank tracker"
#~ msgstr "Verdens hurtigste & mest præcise rank-tracker"

#~ msgid "No credit card required - 14 days free access to all features"
#~ msgstr ""
#~ "Intet kreditkort påkrævet - 14 dages prøveperiode med adgang til alle "
#~ "features"

#, fuzzy
#~| msgid "Please enter your company information."
#~ msgid "Please enter your company domain."
#~ msgstr "Indtast dine firmainformationer."

#~ msgid ""
#~ "We're proud to announce that Ranktracker has been acquired by AccuRanker"
#~ msgstr ""
#~ "Vi er stolte af at offentliggøre, at Ranktracker er blevet opkøbt af "
#~ "AccuRanker"

#~ msgid ""
#~ "\"Google Grump\" Rating highlighting unrest in Google's algorithm. "
#~ "<br>Currently \"%s\"."
#~ msgstr ""
#~ "\"Google Grump\" Rating fremhæver udsving i Googles algoritme. "
#~ "<br>Aktuelt \"%s\"."

#~ msgid "Enter location"
#~ msgstr "Indtast lokation"

#~ msgid ""
#~ "Are you sure you want to move the domain [domain] to the group [group] on "
#~ "the [organization] account?"
#~ msgstr ""
#~ "Er du sikker på at du vil flytte domænet [domain] til gruppen [group] på "
#~ "[organization] kontoen?"

#~ msgid "Select organization and group to move/copy domain to"
#~ msgstr "Vælg firma og gruppe der skal flyttes/kopieres til"

#~ msgid "Check this to copy the domain instead of moving it."
#~ msgstr "Vælg her for at kopiere domænet i stedet for at flytte det."

#~ msgid "Unable to show the page requested."
#~ msgstr "Kunne ikke vise den ønskede side."

#~ msgid "Your trial will end in %s day."
#~ msgid_plural "Your trial will end in %s days."
#~ msgstr[0] "Din prøvekonto udløber om %s dag."
#~ msgstr[1] "Din prøvekonto udløber om %s dage."

#~ msgid "You will automatically move to a paid plan when your trial ends."
#~ msgstr ""
#~ "Du bliver automatisk flyttet til et betalt abonnement når din "
#~ "prøveperiode slutter."

#~ msgid "Click here to manage your subscription."
#~ msgstr "Klik her for at administrere dit abonnement."

#~ msgid "Your trial account expires in %s day."
#~ msgid_plural "Your trial account expires in %s days."
#~ msgstr[0] "Din prøvekonto udløber om %s dag."
#~ msgstr[1] "Din prøvekonto udløber om %s dage."

#~ msgid "Make sure you upgrade your account to avoid losing your data."
#~ msgstr "Opgrader din konto så du undgår at miste dine data."

#, fuzzy
#~| msgid "Please enter a valid domain name"
#~ msgid "Please enter a valid domain"
#~ msgstr "Indtast venligst et gyldigt domænenavn"

#~ msgid "[amount] selected"
#~ msgstr "[amount] valgt"

#~ msgid "Delete…"
#~ msgstr "Slet…"

#~ msgid "TODO: Your account was created"
#~ msgstr "TODO: Din konto er blevet oprettet"

#~ msgid "TODO: account created"
#~ msgstr "TODO: Din konto er blevet oprettet"

#~ msgid "TODO: Start using AccuRanker"
#~ msgstr "TODO: Begynd at bruge AccuRanker"

#~ msgid "monthly"
#~ msgstr "månedlig"

#~ msgid "annual"
#~ msgstr "årlig"

#~ msgid "Save money"
#~ msgstr "Spar penge"

#~ msgid "pricing"
#~ msgstr "pris"

#~ msgid "Add Dynamic Tag"
#~ msgstr "Tilføj Dynamisk Tag"

#~ msgid "No credit card needed for trial with %s keywords"
#~ msgstr "Vi behøver ikke kreditkort for en prøveperiode med %s søgeord"

#~ msgid "URL to track"
#~ msgstr "Tracking-URL"

#~ msgid "Enter the URL you want to track"
#~ msgstr "Indtast det domæne, som du ønsker at tracke"

#~ msgid "Same as company URL"
#~ msgstr "Samme som firma-URL"

#~ msgid "SEO specialist"
#~ msgstr "SEO-specialist"

#~ msgid "Marketing specialist"
#~ msgstr "Markedsføringsspecialist"

#~ msgid "Manager"
#~ msgstr "Salgschef"

#~ msgid "C-level"
#~ msgstr "C-niveau"

#~ msgid "Select your job title"
#~ msgstr "Vælg din jobtitel"

#~ msgid "New message received. Check your inbox in the top right corner."
#~ msgstr "Ny besked modtaget. Se din indbakke i øverste højre hjørne."

#~ msgid "Keyword successfully scheduled for import"
#~ msgid_plural "Keywords successfully scheduled for import"
#~ msgstr[0] "Søgeordet sat i kø til import"
#~ msgstr[1] "Søgeordene sat i kø til import"

#~ msgid "Adding keywords to "
#~ msgstr "Tilføjer søgeord til "

#~ msgid ""
#~ "Sorry, we couldn't find any results for your search. This could be due to "
#~ "your active filters."
#~ msgstr ""
#~ "Beklager, vi kunne ikke finde nogen resultater for din søgning. Det kan "
#~ "skyldes dine aktive filtre."

#~ msgid ""
#~ "Sorry, we couldn't find any results for your search. Please try again "
#~ "with a different or less specific search."
#~ msgstr ""
#~ "Beklager, vi kunne ikke finde nogen resultater for din søgning. Prøv igen "
#~ "med en anden eller mindre specifik søgning."

#~ msgid "Dynamic Tagging Rules"
#~ msgstr "Dynamiske Tagging Regler"

#~ msgid "Apply tags to your keyword base dynamically."
#~ msgstr "Tilføj tags til dine søgeord dynamisk."

#~ msgid ""
#~ "Moved! Dynamic tags are now created and edited from the Tag Cloud tab"
#~ msgstr ""
#~ "Flyttet! Dynamiske tags kan nu oprettes og redigeres fra fanen Tag Cloud"

#~ msgid "Refresh in progress, please wait."
#~ msgstr "Opdatering er i gang. Vent venligst."

#~ msgid "Refreshing domain, please wait."
#~ msgstr "Opdaterer domæne. Vent venligst."

#~ msgid ""
#~ "[h5:New Tag Cloud released!][textsm:Introducing folders and new, deeper "
#~ "tag insights.[br]Visit the Tag Cloud tab and start organizing your tags, "
#~ "or learn more [link:here.]]"
#~ msgstr ""
#~ "[h5:Ny Tag Cloud udgivet!][textsm:Vi introducerer mapper og ny, dybere "
#~ "tag-indsigt.[br]Besøg Tag Cloud-fanen og begynd at organisere dine tags, "
#~ "eller lær mere [link:her.]]"

#~ msgid ""
#~ "[h5:Major overhaul of Keyword Discovery!][textsm:You can now discover "
#~ "keywords related to any domain[br]or your competitor sites.[br]We've also "
#~ "introduced Quick Actions, enabling you to[br]effortlessly filter and "
#~ "refine the discovered keywords.[br]Click 'Discovery' from the main "
#~ "navigation menu or[br]learn more [link:here.]]"
#~ msgstr ""
#~ "[h5:Stor opdatering af Keyword Discovery!][textsm:Du kan nu finde søgeord "
#~ "relateret til ethvert domæne eller dine konkurrenters sider.[br]Vi har "
#~ "også introduceret 'Hurtige Handlinger', der gør det muligt for dig "
#~ "ubesværet[br]at filtrere og afgrænse de foreslåede søgeord.[br]Klik på "
#~ "'Discovery' i hovedmenuen eller læs mere [link:her.]]"

#~ msgid "NEW"
#~ msgstr "NY"

#~ msgid "Unable to disconnect from Facebook"
#~ msgstr "Kunne ikke afbryde forbindelsen til Facebook"

#~ msgid "Disconnected from Facebook"
#~ msgstr "Forbindelsen til Facebook afbrudt"

#~ msgid "Disconnect from Facebook"
#~ msgstr "Afbryd forbindelsen til Facebook"

#~ msgid "Connect with Facebook"
#~ msgstr "Forbind med Facebook"

#~ msgid "Unable to disconnect from Google"
#~ msgstr "Kunne ikke afbryde forbindelsen til Google"

#~ msgid "Sign Up"
#~ msgstr "Opret konto"

#~ msgid "total keywords"
#~ msgstr "total søgeord"

#~ msgid "Click to view the affected keywords."
#~ msgstr "Klik for at se de berørte søgeord."

#~ msgid ""
#~ "[header: Read Only][br]This is a demo domain[br]You cannot make any "
#~ "changes to these settings"
#~ msgstr ""
#~ "[header: Skrivebeskyttet][br]Dette er et demo domæne[br]Du kan ikke ændre "
#~ "indstillingerne"

#~ msgid "Enable ranking of your tweets."
#~ msgstr "Aktiver placeringer af dine tweets."

#~ msgid "You are adding a YouTube channel"
#~ msgstr "Du er ved at tilføje en YouTube-kanal"

#~ msgid ""
#~ "When you are adding a YouTube channel we use the videos on the channel to "
#~ "rank."
#~ msgstr ""
#~ "Når du tilføjer en YouTube-kanal, bruger vi videoerne på kanalen til at "
#~ "rangere."

#~ msgid "You are trying to add a YouTube video"
#~ msgstr "Du prøver at tilføje en YouTube video"

#~ msgid ""
#~ "If you add the channel instead then we will track and rank on all the "
#~ "videos within the channel. Hence, you do not need to add each individual "
#~ "video."
#~ msgstr ""
#~ "Hvis du tilføjer en kanal i stedet overvåger vi alle videoerne i kanalen. "
#~ "Du behøver derfor ikke at tilføje hver enkelt video selv."

#~ msgid ""
#~ "No http:// or www. You can enter a path that must be found e.g. example."
#~ "com/path. Search results must then begin with the entered path to match."
#~ msgstr ""
#~ "Uden http:// og www. Du kan indtaste en sti der skal findes. F.eks. "
#~ "eksempel.dk/sti. Resultatet skal i så fald begynde med stien for at "
#~ "matche."

#~ msgid ""
#~ "No http:// or www. Enter a YouTube channel url e.g. youtube.com/channel/"
#~ "UCv8uTVRcwNF1JkZ1Z0d7WUg"
#~ msgstr ""
#~ "Ingen http:// eller www. Indtast en YouTube-kanal URL f.eks. youtube.com/"
#~ "channel/UCv8uTVRcwNF1JkZ1Z0d7WUg"

#~ msgid "If set this will display instead of the domain name."
#~ msgstr "Hvis sat viser vi dette navn i stedet for domænenavnet."

#~ msgid "If set this will display instead of the channel name."
#~ msgstr "Hvis sat viser vi dette navn i stedet for kanalnavnet."

#~ msgid "Default search engine, locale and location"
#~ msgstr "Standard søgemaskine, område og lokation"

#~ msgid "Once paused you will be charged on %s keywords"
#~ msgstr "Når pause er slået til bruger du %s søgeord"

#~ msgid ""
#~ "You cannot import more than %s keywords at a time. Use the filters to "
#~ "narrow down your search"
#~ msgstr ""
#~ "Du kan ikke importe mere end %s søgeord. Brug filtrene til at begrænse "
#~ "din søgning"

#~ msgid "Import [keywordsCount] keywords"
#~ msgstr "Importer [keywordsCount] søgeord"

#~ msgid ""
#~ "Keyword Discovery results are always based on Google desktop searches"
#~ msgstr ""
#~ "Resultaterne fra Keyword Discovery er altid baseret på Google Desktop-"
#~ "søgninger"

#~ msgid " At: "
#~ msgstr " Ved: "

#~ msgid "All untagged keywords"
#~ msgstr "Alle ikke-taggede søgeord"

#~ msgid ""
#~ "Dynamic tags can be removed by deleting the rule, which is found in the "
#~ "Settings tab."
#~ msgstr ""
#~ "Dynamiske tags kan slettes ved at slette den tilhørende regel. Denne "
#~ "regel findes under indstillinger."

#~ msgid ""
#~ "[h5:Local Search Volume][textsm:Now available for your local searches!"
#~ "[br]Visit your account and/or domain settings to change your preferences, "
#~ "or learn more [link:here.]]"
#~ msgstr ""
#~ "[h5:Lokal Søgevolumen][textsm:For lokale søgninger er det nu muligt at se "
#~ "lokal søgevolumen![br]Besøg din konto og/eller domæneindstillinger for at "
#~ "ændre dine præferencer, eller læs mere [link:her.]]"

#~ msgid "New dynamic tag was added!"
#~ msgstr "Nyt dynamisk tag tilføjet!"

#~ msgid "Delete dynamic rule?"
#~ msgstr "Slet dynamisk filter?"

#~ msgid ""
#~ "The dynamic rule will be permanently deleted and the tags included will "
#~ "be removed from the keywords."
#~ msgstr ""
#~ "Den dynamiske regel bliver slettet permanent og de tilhørende tags bliver "
#~ "fjernet fra søgeordene."

#~ msgid "Delete dynamic rule"
#~ msgstr "Slet dynamisk regel"

#~ msgid "Failed to delete "
#~ msgstr "Kunne ikke slette "

#~ msgid "Updated At"
#~ msgstr "Opdateret"

#~ msgid "There are currently no dynamic tags"
#~ msgstr "Der er, på nuværende tidspunkt, ingen dynamiske tags"

#~ msgid ""
#~ "We recently have merged dynamic tag rulesets with the same tag into a "
#~ "single dynamic tag and removed the option to have multiple tags for the "
#~ "same ruleset. Don't worry! Your dynamic tags work just like before."
#~ msgstr ""
#~ "Vi har for nyligt merged dynamiske tag regelsæt med det samme tag til ét "
#~ "enkelt dynamisk tag, og fjernet muligheden for at have flere tags for det "
#~ "samme regelsæt. Bare rolig - dine dynamiske tags virker præcis som før."

#~ msgid "Add new rule"
#~ msgstr "Opret ny regel"

#~ msgid "Leader"
#~ msgstr "Leder"

#~ msgid "Keyword count (Rank 1-3)"
#~ msgstr "Antal søgeord (placering 1-3)"

#~ msgid "Keyword count (Rank 4-10)"
#~ msgstr "Antal søgeord (placering 4-10)"

#~ msgid "Keyword count (Rank 11-20)"
#~ msgstr "Antal søgeord (placering 11-20)"

#~ msgid "Keyword count (Rank 21-50)"
#~ msgstr "Antal søgeord (placering 21-50)"

#~ msgid "Keyword count (Rank 51-100)"
#~ msgstr "Antal søgeord (placering 51-100)"

#~ msgid "Keyword count (Unranked)"
#~ msgstr "Antal søgeord (Uden placering)"

#~ msgid "Average rank of keywords for this tag"
#~ msgstr "Gennemsnitlig søgeordsplacering for dette tag"

#~ msgid "Change in average rank of keywords between the two dates"
#~ msgstr ""
#~ "Ændring i gennemsnitlig søgeordsplacering mellem de to sammenlignede "
#~ "datoer"

#~ msgid "AI Share of Voice change"
#~ msgstr "Ændring i AI synlighed"

#~ msgid "Weighted avg. CPC:"
#~ msgstr "Vægtet gns. CPC:"

#~ msgid "Est. visits"
#~ msgstr "Est. besøg"

#~ msgid "Est. Visits"
#~ msgstr "Est. Besøg"

#~ msgid "Estimated Visits"
#~ msgstr "Estimeret Antal Besøgende"

#~ msgid "Estimated visitors for this competitor"
#~ msgstr "Estimerede antal besøgende for denne konkurrent"

#~ msgid "No Est. visits for this date"
#~ msgstr "Intet estimeret antal besøgende for denne dato"

#~ msgid "Change in estimated visits between the two compared dates"
#~ msgstr ""
#~ "Ændring i estimeret antal besøgende mellem de to sammenlignede datoer"

#~ msgid "Estimated visits"
#~ msgstr "Estimerede besøg"

#~ msgid "Est. visits +/-"
#~ msgstr "Est. besøg +/-"

#~ msgid "Sort by estimated visits"
#~ msgstr "Sorter efter estimeret antal besøgende"

#~ msgid "Filter by estimated visits…"
#~ msgstr "Filtrer efter estimeret antal besøgende…"

#~ msgid "Sort by est. visits"
#~ msgstr "Sorter efter est. besøgende"

#~ msgid "Sort by change in est. visits"
#~ msgstr "Sorter efter ændring i est. besøgende"

#~ msgid "Filter est. visits…"
#~ msgstr "Filtrer est. besøgende…"

#~ msgid "Estimated visits change"
#~ msgstr "Ændring af estimeret antal besøgende"

#~ msgid "Competitive position"
#~ msgstr "Konkurrenceposition"

#~ msgid "% of keywords with SERP"
#~ msgstr "% af søgeord med SERP"

#~ msgid "Click on a column to view the historical progress."
#~ msgstr "Klik på en kolonne for at se den historiske udvikling."

#~ msgid "type"
#~ msgstr "type"

#, fuzzy
#~| msgid "Select folder(s)"
#~ msgid "Select folder for %s selected"
#~ msgstr "Vælg mappe(r)"

#~ msgid "Move to"
#~ msgstr "Flyt til"

#, fuzzy
#~| msgid "Select domain to move or copy"
#~ msgid "Select folder to move %s selected to"
#~ msgstr "Vælg domæne der skal flyttes eller kopieres"

#~ msgid "Drag and drop to organize your folders and tags"
#~ msgstr "Træk og slip for at organisere dine mapper og tags"

#~ msgid "Research"
#~ msgstr "Analyse"

#~ msgid "SERP feature"
#~ msgstr "SERP funktion"

#~ msgid "Available for import"
#~ msgstr "Tilgængelig til import"

#~ msgid "Remove filter"
#~ msgstr "Fjern filter"

#~ msgid "Reset segment"
#~ msgstr "Nulstil segment"

#, fuzzy
#~| msgid "Edit Domain"
#~ msgid "Filter Domain"
#~ msgstr "Rediger domæne"

#~ msgid "See competitors"
#~ msgstr "Se konkurrenter"

#, fuzzy
#~| msgid "Edit note"
#~ msgid "Edit tag"
#~ msgstr "Rediger notat"

#, fuzzy
#~| msgid "Top %s"
#~ msgid "Top 1-3 %s"
#~ msgstr "Top %s"

#, fuzzy
#~| msgid "Top %s"
#~ msgid "Top 4-10 %s"
#~ msgstr "Top %s"

#, fuzzy
#~| msgid "Top %s"
#~ msgid "Top 11-20 %s"
#~ msgstr "Top %s"

#, fuzzy
#~| msgid "Top %s"
#~ msgid "Top 21-50 %s"
#~ msgstr "Top %s"

#, fuzzy
#~| msgid "51-100"
#~ msgid "Top 51-100 %s"
#~ msgstr "51-100"

#, fuzzy
#~| msgid "Not in top 100"
#~ msgid "Not in top 100 %s"
#~ msgstr "Ikke i top 100"

#~ msgid "New"
#~ msgstr "Nyt"

#~ msgid "Edit dynamic tag ruleset"
#~ msgstr "Rediger dynamisk tag regelsæt"

#~ msgid "Add dynamic tag ruleset"
#~ msgstr "Tilføj dynamisk tags regelsæt"

#~ msgid "Dynamic ruleset name"
#~ msgstr "Dynamisk regelsæt navn"

#~ msgid "Name (e.g. \"My awesome tag ruleset\")"
#~ msgstr "Navn (f.eks. \"Mit fantastiske tag regelsæt\")"

#~ msgid "Select existing or create one or more new tags for this ruleset"
#~ msgstr ""
#~ "Vælg eksisterende eller lav et eller flere nye tags for dette regelsæt"

#~ msgid "Dynamic rule"
#~ msgstr "Dynamisk regel"

#~ msgid "Tags included"
#~ msgstr "Inkluderede tags"

#~ msgid "Upgrade my plan [icon]"
#~ msgstr "Opgrader min konto [icon]"

#~ msgid "Report"
#~ msgstr "Rapport"

#~ msgid "To clipboard"
#~ msgstr "Til udklipsholder"

#~ msgid "By adding additional keywords, you accept AccuRanker's"
#~ msgstr "Når du tilføjer yderligere søgeord, accepterer du AccuRankers"

#~ msgid "Upgraded 1 domain"
#~ msgstr "Opgraderede 1 domæne"

#~ msgid "Upgraded [n] domains"
#~ msgstr "Opgraderede [n] domæner"

#~ msgid "Automatic upgrade to Google Analytics 4"
#~ msgstr "Automatisk opgradering til Google Analytics 4"

#~ msgid ""
#~ "Google is phasing out UA (Universal Analytics) and replacing it with GA4 "
#~ "(Google Analytics 4) - read more about it [link:here]."
#~ msgstr ""
#~ "Google udfaser UA (Universal Analytics) og erstatter det med GA4 (Google "
#~ "Analytics 4) - læs mere om det [link:her]."

#~ msgid ""
#~ "Upgrading your existing Analytics connections is a straightforward "
#~ "process. If your domains are included in the list below, we can upgrade "
#~ "them automatically for you. Simply select the domains you wish to upgrade "
#~ "and click on \"Upgrade\"."
#~ msgstr ""
#~ "Opgradering af dine eksisterende Analytics-forbindelser er ligetil. Hvis "
#~ "dine domæner er inkluderet i listen nedenfor, kan vi opgradere dem "
#~ "automatisk for dig. Vælg blot de domæner, du ønsker at opgradere, og klik "
#~ "på \"Opgrader\"."

#~ msgid ""
#~ "If you don't find a domain listed, it's possible we may not be able to "
#~ "identify the appropriate GA4 property to associate with your current UA "
#~ "connection. To manually upgrade domain, go to its overview page and click "
#~ "on \"Upgrade to GA4\"."
#~ msgstr ""
#~ "Hvis du ikke finder et domæne på listen, kan det være, at vi ikke er i "
#~ "stand til at identificere den passende GA4-ejendom, der skal associeres "
#~ "med din nuværende UA-forbindelse. For at opgradere domænet manuelt, skal "
#~ "du gå til dets oversigtsside og klikke på \"Opgrader til GA4\"."

#~ msgid "Current UA"
#~ msgstr "Nuværende UA"

#~ msgid "Suggested GA4"
#~ msgstr "Foreslået GA4"

#~ msgid "Upgrading 1 domain"
#~ msgstr "Opgraderer 1 domæne"

#~ msgid "Upgrade 1 domain"
#~ msgstr "Opgrader 1 domæne"

#~ msgid "Upgrade [n] domains"
#~ msgstr "Opgrader [n] domæner"

#~ msgid "[b:Google Analytics 4 (GA4)] Integration is now available"
#~ msgstr "[b:Google Analytics 4 (GA4)] Integration er nu tilgængelig"

#~ msgid "Learn more"
#~ msgstr "Lær mere"

#~ msgid "Upgrade"
#~ msgstr "Opgrader"

#~ msgid "Search settings"
#~ msgstr "Søgeindstillinger"

#, fuzzy
#~| msgid "Enable locale debug"
#~ msgid "Enable local search"
#~ msgstr "Slå locale debugging til"

#~ msgid "Fully Operational"
#~ msgstr "Fuldt funktionsdygtigt"

#~ msgid "Major Outage"
#~ msgstr "Større nedbrug"

#~ msgid "Systems are fully operational and should work as usual."
#~ msgstr "Alt kører som det skal."

#~ msgid ""
#~ "We are working on getting the system fully operational as fast as "
#~ "possible. We apologize for any inconvenience caused by this."
#~ msgstr ""
#~ "Vi arbejder på højtryk for at få alting til at virke igen. Vi beklager de "
#~ "gener det måtte medføre."

#~ msgid ""
#~ "This settings controls if \"Not in top 100\" rank keywords are included "
#~ "in the average rank calculation. If you leave it as \"Account Default\", "
#~ "the account setting will decide if they are included and their rank "
#~ "value. You can also set it to \"Yes\" or \"No\" to override the account "
#~ "setting for this domain."
#~ msgstr ""
#~ "Denne indstilling styrer, om \"Ikke i top 100\"-placeringssøgeord "
#~ "medregnes i den gennemsnitlige placeringsberegning. Hvis du lader den "
#~ "være på \"Kontobaseret standard\", vil kontoinstillingen beslutte, om de "
#~ "inkluderes samt deres placeringsværdi. Du kan også sætte den til \"Ja\" "
#~ "eller \"Nej\" for at overskrive kontoinstillingen for dette domæne."

#, fuzzy
#~| msgid ""
#~| "No http:// or www. You can enter a path that must be found e.g. example."
#~| "com/path. Search results must then begin with the entered path to match."
#~ msgid ""
#~ "You can enter a path that must be found e.g. example.com/path. Search "
#~ "results must then begin with the entered path to match."
#~ msgstr ""
#~ "Uden http:// og www. Du kan indtaste en sti der skal findes. F.eks. "
#~ "eksempel.dk/sti. Resultatet skal i så fald begynde med stien for at "
#~ "matche."

#~ msgid "Rank value controlled by account settings"
#~ msgstr "Placeringsværdi styres af kontoindstillinger"

#~ msgid "Failed to accept multi account request."
#~ msgstr "Kunne ikke acceptere underkontoadgangsforespørgsel."

#~ msgid "Failed to decline multi account request."
#~ msgstr "Kunne ikke afvise forespørgsel om adgang til konto."

#~ msgid "Failed to accept request, link is not valid."
#~ msgstr "Kunne ikke acceptere forespørgsel, ugyldigt link."

#~ msgid "Failed to decline request, link is not valid."
#~ msgstr "Kunnne ikke afvise forespørgsel, linket er ikke gyldigt."

#~ msgid "Updated CPC currency"
#~ msgstr "Opdaterede CPC valuta"

#~ msgid "Login"
#~ msgstr "Log ind"

#~ msgid "Oops! Something went wrong."
#~ msgstr "Ups! Noget gik galt."

#~ msgid "Updated account"
#~ msgstr "Opdaterede konto"

#~ msgid "Please select the Google Analytics property that you wish to use."
#~ msgstr "Vælg venligst den Google Analytics ejendom du vil bruge."

#~ msgid "Major update to the overview page"
#~ msgstr "Ny overbliksside!"

#~ msgid "Dynamic Tagging"
#~ msgstr "Dynamisk tagging"

#~ msgid "Releases"
#~ msgstr "Versioner"

#~ msgid "Ask me later"
#~ msgstr "Spørg mig senere"

#~ msgid "🎉 Thank you for your feedback! 🎉"
#~ msgstr "🎉 Tak for din feedback! 🎉"

#~ msgid "Please leave a review on [link:Trustpilot]"
#~ msgstr "Vil du skrive en anmeldelse på [link:Trustpilot]?"

#~ msgid "Admin User"
#~ msgstr "Admin-bruger"

#~ msgid "Thank you for you feedback!"
#~ msgstr "Tak for din feedback!"

#~ msgid "Please share your feedback here."
#~ msgstr "Del din feedback her."

#~ msgid "Please be as specific as possible"
#~ msgstr "Vær venligst så specifik som muligt"

#~ msgid "Send"
#~ msgstr "Send"

#~ msgid "Share your feedback"
#~ msgstr "Del din feedback"

#~ msgid "Ranks for "
#~ msgstr "Placeringer for "

#~ msgid "Search Date"
#~ msgstr "Søgedato"

#~ msgid "Protocol"
#~ msgstr "Protokol"

#~ msgid "Update Crm Deal"
#~ msgstr "Opdater CRM deal"

#~ msgid ""
#~ "Please note that reports are queued up and can take a few minutes to "
#~ "complete"
#~ msgstr ""
#~ "Bemærk at rapporter sættes i kø og kan tage et par minutter om at blive "
#~ "klar"

#~ msgid "Create Organic Site Explorer Report"
#~ msgstr "Opret organisk Site Explorer rapport"

#~ msgid "Failed to generate report, please try again."
#~ msgstr "Vi kunne ikke generere rapporten, prøv venligst igen."

#~ msgid "Drag your mouse and click the option"
#~ msgstr "Træk med musen og vælg en mulighed"

#~ msgid "Copy Domain"
#~ msgstr "Kopier domæne"

#~ msgid "How to map columns with fields"
#~ msgstr "Sådan forbindes kolonner med felter"

#~ msgid "SERP Historical"
#~ msgstr "SERP historisk"

#~ msgid "Importers"
#~ msgstr "Importerer"

#~ msgid "Redeem"
#~ msgstr "Indløs"

#~ msgid "Import from third party"
#~ msgstr "Importer fra tredjepart"

#~ msgid ""
#~ "Before you found AccuRanker you might have had an account with another "
#~ "rank tracker. To ease your transition to AccuRanker, we have created "
#~ "several importers."
#~ msgstr ""
#~ "Før du fandt AccuRanker har du måske brugt en anden rank tracker. For at "
#~ "lette skiftet til AccuRanker har vi lavet importmuligheder."

#~ msgid ""
#~ "Depending on the integration with the third party, our importer can "
#~ "either import all your data or for a single domain."
#~ msgstr ""
#~ "Afhængigt at integrationen med tredjeparten kan vi importere alle data "
#~ "eller data for et enkelt domæne."

#~ msgid ""
#~ "Please contact us if you wish to import data. If we're missing a third "
#~ "party in the list it doesn't mean we can't import data from there. "
#~ "Contact us for more info."
#~ msgstr ""
#~ "Kontakt os hvis du ønsker at importere data. Hvis vi mangler en "
#~ "tredjepart i listen betyder det ikke at vi ikke kan importere data "
#~ "derfra. Kontakt os for mere info."

#~ msgid "Switch to Old AccuRanker"
#~ msgstr "Skift til Gammel AccuRanker"

#~ msgid "Please tell us why you prefer the old version."
#~ msgstr "Fortæl os venligst hvorfor du foretrækker den gamle version."

#~ msgid "Please be as specific as possible."
#~ msgstr "Vær venligst så specifik som muligt."

#~ msgid "Create invoice for %s"
#~ msgstr "Opret faktura for %s"

#~ msgid "Please enter and add vouchers."
#~ msgstr "Indtast og tilføjer kuponer."

#~ msgid "Enter voucher"
#~ msgstr "Indtast voucher"

#~ msgid "Click to remove voucher"
#~ msgstr "Klik for at fjerne voucher"

#~ msgid "Lifetime plan with %s keywords"
#~ msgstr "Livslangt abonnement med %s søgeord"

#~ msgid "Add %s additional keywords for each voucher redeemed"
#~ msgstr "Tilføj yderligere %s søgeord for hver voucher, der er indløst"

#~ msgid "We will reply as fast as possible."
#~ msgstr "Vi svarer så hurtigt som muligt."

#~ msgid ""
#~ "We are thrilled to have you here and can't wait for you to experience the "
#~ "power of our AccuRanker over the next 14 days."
#~ msgstr ""
#~ "Vi er begejstrede for at have dig om bord, og kan ikke vente med at du "
#~ "oplever AccuRanker over de næste 14 dage."

#~ msgid ""
#~ "You will have access to track up to 100 keywords, and all the features "
#~ "AccuRanker has to offer."
#~ msgstr ""
#~ "Du vil have adgang til at overvåge op til 100 søgeord og alle de "
#~ "funktioner, som AccuRanker har at tilbyde."

#~ msgid ""
#~ "So get started today and see for yourself how AccuRanker can help you "
#~ "stay ahead of the competition and drive more traffic to your website."
#~ msgstr ""
#~ "Så kom i gang i dag og se selv, hvordan AccuRanker kan hjælpe dig med at "
#~ "holde dig et skridt foran konkurrenterne og øge trafikken til din "
#~ "hjemmeside."

#~ msgid "What now?"
#~ msgstr "Hvad nu?"

#~ msgid "We offer three different ways for you to begin your journey with us."
#~ msgstr "Begynd din rejse med os. Vælg den vej, der passer dig bedst."

#~ msgid ""
#~ "Thanks for signing up to AccuRanker. We hope you´ll enjoy every second of "
#~ "using AccuRanker."
#~ msgstr ""
#~ "Tak fordi du oprettede en AccuRanker konto. Vi håber du nyder hvert "
#~ "sekund af at bruge AccuRanker."

#~ msgid ""
#~ "We´ve provided some demo domains for you, so you can get a sense of how "
#~ "AccuRanker looks with historic data, feel free to delete these anytime "
#~ "you want."
#~ msgstr ""
#~ "Vi har tilføjet nogle demo domæner til dig, så du kan få en bedre "
#~ "fornemmelse af hvordan AccuRanker ser ud med historisk data. Du er "
#~ "velkommen til at slette dem når som helst."

#~ msgid ""
#~ "To get familiar with our tool, we recommend you watch the video below."
#~ msgstr ""
#~ "For at blive fortrolig med værktøjet, anbefaler vi at du ser nedenstående "
#~ "video."

#~ msgid "You can also seek guidance in our help center:"
#~ msgstr "Du kan også søge hjælp i vores hjælpe center:"

#~ msgid ""
#~ "If you prefer a more a personal approach, we will be happy to provide a "
#~ "30 minute best practice session where we can show you how to get the most "
#~ "out of AccuRanker (Don´t worry, it´s completely free!)."
#~ msgstr ""
#~ "Hvis du foretrækker en mere personlig tilgang, så vil vi med glæde "
#~ "tilbyde dig en halv times best practice session, hvor vi viser dig "
#~ "hvordan du får mest ud af AccuRanker (bare rolig - det er helt gratis!)."

#~ msgid "First %s days free"
#~ msgstr "De første %s dage gratis"

#, fuzzy
#~| msgid ""
#~| "There is currently no data to display, this could be due to your active "
#~| "filters. Clear your filters and try again."
#~ msgid ""
#~ "We found no domains on your account, this could be due to your active "
#~ "filters. Clear your filters and try again"
#~ msgstr ""
#~ "Der er ingen data at vise. Det kan skyldes dine aktive filtre. Nulstil "
#~ "filtrene og prøv igen."

#~ msgid "Competitor added"
#~ msgstr "Konkurrent tilføjet"

#~ msgid "notes"
#~ msgstr "notater"

#~ msgid ""
#~ "Select your custom currency that fits your market. This will reflect on "
#~ "your CPC & traffic values across AccuRanker."
#~ msgstr ""
#~ "Vælg din tilpassede valuta, som passer til dit marked. Dette vil "
#~ "afspejles på dine CPC & trafikværdier på tværs af AccuRanker."

#~ msgid ""
#~ "Account CPC currency will change those domains that have their domain CPC "
#~ "currency set to 'Account Default'."
#~ msgstr ""
#~ "Konto CPC valuta vil påvirke de domæner, som har deres domæne CPC valuta "
#~ "sat til 'Konto Standard'."

#~ msgid "Keyword Exceedings"
#~ msgstr "Merforbrug for Søgeord"

#~ msgid "Domain settings"
#~ msgstr "Domæneindstillinger"

#~ msgid "Dynamic rules"
#~ msgstr "Dynamiske regler"

#~ msgid "#[id] ([file])"
#~ msgstr "#[id] ([file])"

#~ msgid "Validation is completed."
#~ msgstr "Validering er gennemført."

#~ msgid "Import is completed."
#~ msgstr "Importering er færdig."

#~ msgid "Row [row]: [error]"
#~ msgstr "Række [row]: [error]"

#~ msgid "Missing required fields for keyword import"
#~ msgstr "Udfyld alle påkrævede felter for importering af søgeord"

#~ msgid "Remove column"
#~ msgstr "Slet kolonne"

#~ msgid "No columns mapped"
#~ msgstr "Ingen kolonner forbundet"

#~ msgid "Please map columns with fields to view the preview of your import."
#~ msgstr ""
#~ "Forbind kolonner med felter for at få vist forhåndsvisning af din import."

#~ msgid "Create new preferred URL page \"%s\""
#~ msgstr "Opret ny foretrukken URL \"%s\""

#~ msgid "test"
#~ msgstr "test"

#~ msgid "$%s monthly"
#~ msgstr "$%s månedligt"

#~ msgid "link"
#~ msgstr "link"

#~ msgid ""
#~ "The file name is too long (%s characters)! The max length is 200 "
#~ "characters!"
#~ msgstr ""
#~ "Filnavnet er for langt (%s karakterer)! Længden kan højest være på 200 "
#~ "karakterer!"

#~ msgid "emails"
#~ msgstr "e-mails"

#~ msgid "Paid Yearly"
#~ msgstr "Betalt årligt"

#~ msgid "It is not possible to save fields with non-identical settings"
#~ msgstr "Det er ikke muligt at gemme felter med ikke-identiske indstillinger"

#~ msgid "History for %s"
#~ msgstr "Historik for %s"

#~ msgid ""
#~ "Rankings for this search engine will come at a slower rate then Google "
#~ "and Bing."
#~ msgstr ""
#~ "Placeringer for denne søgemaskine kommer ind lidt langsommere end Google "
#~ "og Bing."

#~ msgid "~1 hour or less"
#~ msgstr "~1 time eller mindre"

#~ msgid "Waiting for ranking data."
#~ msgstr "Venter på placeringsdata."

#~ msgid "Time Ago"
#~ msgstr "Tid siden"

#~ msgid "Organic traffic (Analytics)"
#~ msgstr "Organisk trafik (Analytics)"

#~ msgid "Keywords tracking"
#~ msgstr "Søgeordsovervågning"

#~ msgid "Drag file or click here to upload logo"
#~ msgstr "Træk og slip en fil her for at uploade et logo"

#~ msgid "Account Logo"
#~ msgstr "Kontologo"

#~ msgid "Uploading:"
#~ msgstr "Uploader:"

#~ msgid "Select a file on disk"
#~ msgstr "Vælg en fil fra disken"

#~ msgid ""
#~ "Wrong extension: \"%s\" , please provide files with following extensions: "
#~ "%s"
#~ msgstr "Forkert filendelse: \"%s\", prøv venligst med en fil af typen: %s"

#~ msgid "URL copied to clipboard"
#~ msgstr "URL kopieret til udklipsholder"

#~ msgid "Link copied to clipboard"
#~ msgstr "Link kopieret til udklipsholder"

#~ msgid "Loading URL…"
#~ msgstr "Henter URL…"

#~ msgid ""
#~ "Please note that the data shown here might not be what you see in the "
#~ "generated report, this is a visual preview - not a data preview."
#~ msgstr ""
#~ "Bemærk venligst, at de data, der vises her, muligvis ikke er det, du ser "
#~ "i den genererede rapport. Dette er en visuel forhåndsvisning - ikke en "
#~ "forhåndsvisning af data."

#~ msgid "Average CPC:"
#~ msgstr "Gennemsnitlig CPC:"

#~ msgid "Account updated"
#~ msgstr "Konto opdateret"

#~ msgid "Reset zoom"
#~ msgstr "Nulstil zoom"

#~ msgid "No average rank data for the selected period"
#~ msgstr "Ingen gennemsnit placeringsdata for den valgte periode"

#~ msgid "No visitor data for the selected period"
#~ msgstr "Ingen besøgende-data for den valgte periode"

#~ msgid "Goals"
#~ msgstr "Mål"

#~ msgid "No revenue data for the selected period"
#~ msgstr "Ingen omsætningsdata for den valgte periode"

#~ msgid "New rank"
#~ msgstr "Ny placering"

#~ msgid "notifications"
#~ msgstr "notifikationer"

#~ msgid "Waiting for ranking data"
#~ msgstr "Venter på placeringsdata"

#~ msgid "The average rank for all your keywords on the given date."
#~ msgstr ""
#~ "Den gennemsnitlige placering for alle dine søgeord på en given dato."

#~ msgid ""
#~ "These numbers are pulled from the Google Analytics (or Adobe Analytics) "
#~ "account connected to this domain. The numbers are updated daily at "
#~ "midnight in the timezone specified in Google Analytics."
#~ msgstr ""
#~ "Disse tal hentes fra den Google Analytics (eller Adobe Analytics) konto "
#~ "der er koblet på domænet. Tallene hentes ved midnat i den tidszone der er "
#~ "angivet i Google Analytics."

#~ msgid "Shows the most occurring competitors in top 10 for your keywords."
#~ msgstr ""
#~ "Viser de konkurrenter der går igen flest gange i top 10 for dine søgeord."

#~ msgid ""
#~ "This is a demo domain, you can therefore not edit or add any keywords. To "
#~ "add keywords add your own domain on the Dashboard."
#~ msgstr ""
#~ "Du forsøger at ændre et demo domæne. Hvis du vil tilføje søgeord skal du "
#~ "oprette et nyt domæne."

#~ msgid ""
#~ "Automatic notifications are generated for big or significant movements on "
#~ "star marked keywords. A keyword moving from rank 2 to 1 is an important "
#~ "change, and will generate a notification. A move from 75 to 150 is not "
#~ "considered a significant change and you will not receive a notification."
#~ msgstr ""
#~ "Automatiske notifikationer bliver lavet for stjernemarkerede søgeord der "
#~ "oplever signifikante ændringer. En ændring fra plads 2 til 1 er "
#~ "signifikant, og vil lave en notifikation. En ændring fra plads 75 til 150 "
#~ "er ikke signifikant, og vil ikke lave en notifikation."

#~ msgid "No organic traffic data for the selected period"
#~ msgstr "Ingen organisk trafikdata for den valgte periode"

#~ msgid "Organic Traffic By Week"
#~ msgstr "Organisk trafik per uge"

#~ msgid ""
#~ "This is the organic traffic from Google Analytics (or Adobe Analytics). "
#~ "If you chose a date range greater than 30 days, the graph will show "
#~ "organic traffic per week instead of per day."
#~ msgstr ""
#~ "Dette er den organiske trafik fra Google Analytics (eller Adobe "
#~ "Analytics). Hvis der vælges et dato-interval større end 30 dage bliver "
#~ "trafikken summeret på ugeniveau."

#~ msgid "No ranking distribution data for the selected period"
#~ msgstr "Ingen placeringsfordelingsdata for den valgte periode"

#~ msgid ""
#~ "In this graph, the rankings are split into six different groups according "
#~ "to range - for example range 1 - 3 . This enables you to quickly see if "
#~ "your keywords ranked in these particular ranges are going up or down."
#~ msgstr ""
#~ "I denne graf er placeringerne inddelt i seks grupper. F.eks. placering 1 "
#~ "til 3. Det gør dig i stand til hurtigt at se om dine placeringer i disse "
#~ "grupper flytter sig op eller ned."

#~ msgid "Latest"
#~ msgstr "Seneste"

#~ msgid "No Share of Voice data for the selected period"
#~ msgstr "Ingen synlighedsdata for den valgte periode"

#~ msgid ""
#~ "Share of Voice is an indicator of how your most important keywords are "
#~ "performing. All keywords that rank between positions 1 to 20 are used for "
#~ "the calculation. The average CTR for the position is multiplied by the "
#~ "search volume of each keyword, allowing you to see if a high traffic "
#~ "keyword is losing rank."
#~ msgstr ""
#~ "Synlighed er en indikation af hvordan det går for de vigtigste søgeord. "
#~ "Alle søgeord med en placering mellem 1 og 20 bliver brugt i udregningen. "
#~ "Gennemsnit CTR for placeringen bliver ganget med søgevolumen for hvert "
#~ "søgeord, så du kan se om højtrafik søgeord mister placeringer."

#~ msgid "Untracked"
#~ msgstr "Ikke tracked"

#~ msgid "Share of Voice Competitors"
#~ msgstr "Synlighed for konkurrenter"

#~ msgid ""
#~ "Shows both your and your competitors share of the market and the "
#~ "potential available."
#~ msgstr ""
#~ "Viser din og dine konkurrenters synlighed samt den maksimalt opnåelig "
#~ "synlighed."

#~ msgid "Share of Voice Landing Pages"
#~ msgstr "Landingssider synlighed"

#~ msgid ""
#~ "See Share of Voice figures for the combined total of keywords pointing to "
#~ "your Landing Pages ."
#~ msgstr ""
#~ "Se synlighedstal for det samlede antal søgeord der peger på dine "
#~ "landingssider."

#~ msgid "Share of Voice Tags"
#~ msgstr "Synlighed tags"

#~ msgid ""
#~ "Shows Share of Voice figures for the combined totals of all the keywords "
#~ "with tags applied in your account. "
#~ msgstr ""
#~ "Viser synlighedstal for det samlede total af alle søgeord med tags på din "
#~ "konto. "

#~ msgid "Dynamic Competitors"
#~ msgstr "Dynamiske konkurrenter"

#~ msgid "Weight"
#~ msgstr "Vægt"

#~ msgid "Select at least one competitor"
#~ msgstr "Vælg mindst én konkurrent"

#~ msgid "domain1.com"
#~ msgstr "domæne1.com"

#~ msgid "No data for selected period"
#~ msgstr "Ingen data for den valgte periode"

#~ msgid "Your rank"
#~ msgstr "Din placering"

#~ msgid "Diff +/-"
#~ msgstr "Forskel +/-"

#~ msgid "Competitor rankings"
#~ msgstr "Konkurrentplaceringer"

#~ msgid "Google analytics"
#~ msgstr "Google Analytics"

#~ msgid "Initial rank"
#~ msgstr "Oprindelig placering"

#~ msgid "Current rank"
#~ msgstr "Nuværende placering"

#~ msgid "Start date rank"
#~ msgstr "Start dato placering"

#~ msgid "Change +/-"
#~ msgstr "Ændring +/-"

#~ msgid "21-25"
#~ msgstr "21-25"

#~ msgid "Keywords summary"
#~ msgstr "Søgeordsresumé"

#~ msgid "Keywords summary by %s"
#~ msgstr "Søgeordsresumé per %s"

#~ msgid "Public report link:"
#~ msgstr "Link til offentlig rapport:"

#~ msgid "No share of voice data for the selected period"
#~ msgstr "Ingen synlighedsdata for den valgte periode"

#~ msgid "(untagged)"
#~ msgstr "(ikke tagget)"

#~ msgid "no URL"
#~ msgstr "ingen URL"

#~ msgid "no search engine"
#~ msgstr "ingen søgemaskine"

#~ msgid "Type: %s is not implemented, yet…"
#~ msgstr "Type: %s er ikke implementeret endnu…"

#~ msgid "GSC (previous year)"
#~ msgstr "GSC (sidste år)"

#~ msgid "GA (previous year)"
#~ msgstr "GA (sidste år)"

#~ msgid "Share of voice gain"
#~ msgstr "Synlighedsvækst"

#~ msgid "Share of voice loss"
#~ msgstr "Synlighedstab"

#~ msgid ""
#~ "No data found.[br]Getting data from the provider might take a few minutes"
#~ msgstr ""
#~ "Ingen data fundet.[br]Det kan tage et par minutter at hente dataen fra GA/"
#~ "GSC"

#, fuzzy
#~| msgid "Estimated Visits"
#~ msgid "Estimated Visists"
#~ msgstr "Estimeret Antal Besøgende"

#~ msgid "No competitor rank data for the selected period"
#~ msgstr "Ingen konkurrentdata for den valgte periode"

#~ msgid "No tag cloud data for the selected period"
#~ msgstr "Ingen tag cloud data for den valgte periode"

#, fuzzy
#~| msgid "Monthly"
#~ msgid "Month"
#~ msgstr "Månedlig"

#, fuzzy
#~| msgid "Total"
#~ msgid "Total:"
#~ msgstr "Total"

#~ msgid ""
#~ "In the preview below you can see how how the final import file will look "
#~ "like."
#~ msgstr ""
#~ "I eksemplet nedenfor kan du se, hvordan den endelige importfil vil se ud."

#~ msgid "Errors in some of your columns"
#~ msgstr "Fejl i nogle af dine kolonner"

#~ msgid "[column] has invalid values on the following rows: [rows]"
#~ msgstr "[column] har ugyldige værdier i følgende rækker: [rows]"

#~ msgid "Other errors"
#~ msgstr "Andre fejl"

#~ msgid "Initial setting not identical for the selected keywords"
#~ msgstr "Nuværende instilling ikke ens for de valgte keywords"

#~ msgid "Search intent just launched"
#~ msgstr "Så er der Søgeintention"

#~ msgid ""
#~ "See who your competitors are [bold: based on the keywords that you are "
#~ "actively tracking on this landing page].\n"
#~ "    [br][br]\n"
#~ "    By default, the [italic: numbers] in the table are based on all "
#~ "keywords, both tracked and discovered. This\n"
#~ "    can be changed with the switch in the top right of this page.\n"
#~ "    [br][br]\n"
#~ "    Click on a number in the \"Shared\" column to see keywords you track "
#~ "where your competitor's landing page appears in\n"
#~ "    the SERP.\n"
#~ "    [br][br]\n"
#~ "    Click on the number in the \"Disc.\" column to see untracked keywords "
#~ "where your competitor's landing page\n"
#~ "    appears in the SERP.\n"
#~ "    "
#~ msgstr ""
#~ "Se hvem dine konkurrenter er [bold: baseret på de søgeord du aktivt "
#~ "tracker på denne side]\n"
#~ "[br][br]\n"
#~ "Som udgangspunkt er [italic: tallene] i tabellen baseret på alle søgeord, "
#~ "både trackede og opdagede. Dette kan ændres med knappen i øverste højre "
#~ "hjørne af denne side.\n"
#~ "[br][br]\n"
#~ "Klik på et tal i \"Delt\" kolonnen for at se søgeord som du tracker hvor "
#~ "din konkurrents side findes.\n"
#~ "[br][br]\n"
#~ "Klik på et tal i \"Opd.\" kolonnen for at se opdagede søgeord hvor din "
#~ "konkurrents side findes.\n"
#~ "    "

#, fuzzy
#~| msgid "Dynamic competitors"
#~ msgid "No dynamic competitor landing pages available"
#~ msgstr "Dynamiske konkurrenter"

#~ msgid "Est. V"
#~ msgstr "Est. B"

#~ msgid "$$$"
#~ msgstr "$$$"

#~ msgid "Shared"
#~ msgstr "Delt"

#, fuzzy
#~| msgid "Share of Voice for this landing page"
#~ msgid "Statistics including subpages"
#~ msgstr "Synlighed for denne landingsside"

#, fuzzy
#~| msgid "Keyword"
#~ msgid "Keyw."
#~ msgstr "Søgeord"

#, fuzzy
#~| msgid "USD:"
#~ msgid "USD"
#~ msgstr "USD:"

#, fuzzy
#~| msgid "EUR:"
#~ msgid "EUR"
#~ msgstr "EUR:"

#, fuzzy
#~| msgid "DKK:"
#~ msgid "DKK"
#~ msgstr "DKK:"

#~ msgid "Duplicate keywords"
#~ msgstr "Dupliker søgeord"

#~ msgid "Select an invoice to fetch payments."
#~ msgstr "Vælg en faktura for at hente betalinger."

#~ msgid "Failed to load data for unmatched payments table."
#~ msgstr "Kunne ikke indlæse data til tabellen."

#~ msgid "Contact"
#~ msgstr "Kontakt"

#~ msgid "Failed to load data for unmatched invoices table."
#~ msgstr "Kunne ikke indlæse data til tabellen."

#~ msgid "Due Date"
#~ msgstr "Forfaldsdato"

#~ msgid "Reconcile payment?"
#~ msgstr "Afstem betaling?"

#~ msgid "Unable to reconcile"
#~ msgstr "Kunne ikke afstemme"

#~ msgid "Unable to reconcile payment"
#~ msgstr "Kunne ikke afstemme betaling"

#~ msgid "Share of Voice for this keyword"
#~ msgstr "Synlighed for dette søgeord"

#~ msgid "SoV $"
#~ msgstr "Synl. $"

#~ msgid "Total value of your SoV (CPC * SoV)"
#~ msgstr "Total værdi af din synlighed (CPC * Synlighed)"

#~ msgid "Select visible keywords"
#~ msgstr "Vælg synlige søgeord"

#~ msgid "Estimated Monthly Visitors"
#~ msgstr "Estimeret månedlig trafik"

#~ msgid "Impersonate organization"
#~ msgstr "Efterlign organisation"

#~ msgid "tags"
#~ msgstr "tags"

#~ msgid "Showing %s for %s"
#~ msgstr "Viser %s for %s"

#~ msgid "Joined"
#~ msgstr "Tiltrådte"

#~ msgid "Prev. MRR"
#~ msgstr "Forrige MRR"

#~ msgid "Prev. ARR"
#~ msgstr "Forrige ARR"

#~ msgid "MRR Change"
#~ msgstr "MRR ændring"

#~ msgid "Cancellation request sent"
#~ msgstr "Opsigelsesforespørgsel sendt"

#~ msgid "Why do you want to cancel your AccuRanker account?"
#~ msgstr "Hvorfor ønsker du at opsige din AccuRanker konto?"

#~ msgid "Select reason"
#~ msgstr "Vælg årsag"

#~ msgid "I no longer need to track my organic growth"
#~ msgstr "Jeg har ikke længere behov for at følge min organiske vækst"

#~ msgid "I am missing a feature"
#~ msgstr "Jeg mangler en funktion"

#~ msgid "I am not getting the most out of AccuRanker"
#~ msgstr "Jeg får ikke så meget ud af AccuRanker"

#~ msgid "I have sold my company"
#~ msgstr "Jeg har solgt min virksomhed"

#~ msgid "It is another reason"
#~ msgstr "Det er en anden årsag"

#~ msgid "Please elaborate"
#~ msgstr "Uddyb venligst"

#~ msgid "Who should we contact regarding the cancellation?"
#~ msgstr "Hvem skal vi kontakte omkring annulleringen?"

#~ msgid "Select contact"
#~ msgstr "Vælg kontakt"

#~ msgid ""
#~ "I understand that my account is not cancelled until I have been in "
#~ "contact with AccuRanker"
#~ msgstr ""
#~ "Jeg er indforstået med at min konto ikke er opsagt indtil jeg har været i "
#~ "kontakt med AccuRanker"

#~ msgid "Begin cancellation"
#~ msgstr "Begynd opsigelse"

#~ msgid "We’re sorry to hear that you want to cancel your AccuRanker account"
#~ msgstr "Vi er kede af at du ønsker at opsige dit AccuRanker abonnement"

#~ msgid ""
#~ "Click next to follow the process on how to cancel your AccuRanker account."
#~ msgstr ""
#~ "Klik næste for at følge processen til at annullere dit AccuRanker "
#~ "abonnement."

#~ msgid ""
#~ "Please note that an account needs to be cancelled 14 days before the "
#~ "subscription is set for renewal."
#~ msgstr ""
#~ "Vær opmærksom på at abonnementet skal annulleres 14 dage inden det bliver "
#~ "fornyet."

#~ msgid ""
#~ "We will note the date when you submit the form below and contact you as "
#~ "soon as possible."
#~ msgstr ""
#~ "Vi noterer datoen for indsendelsen og kontakter dig så hurtigt som muligt."

#~ msgid "Unable to clear landing page"
#~ msgstr "Kunne ikke nulstille foretrukket URL"

#~ msgid "Jan"
#~ msgstr "Jan"

#~ msgid "Feb"
#~ msgstr "Feb"

#~ msgid "Mar"
#~ msgstr "Mar"

#~ msgid "Apr"
#~ msgstr "Apr"

#~ msgid "May"
#~ msgstr "Maj"

#~ msgid "Jun"
#~ msgstr "Jun"

#~ msgid "Jul"
#~ msgstr "Jul"

#~ msgid "Aug"
#~ msgstr "Aug"

#~ msgid "Sep"
#~ msgstr "Sep"

#~ msgid "Oct"
#~ msgstr "Okt"

#~ msgid "Nov"
#~ msgstr "Nov"

#~ msgid "Dec"
#~ msgstr "Dec"

#~ msgid "Keywords used for calculation for:"
#~ msgstr "Søgeord brugt til udregning af:"

#~ msgid "%s%% completed"
#~ msgstr "%s%% færdig"

#~ msgid "Could not refresh keyword"
#~ msgstr "Kunne ikke opdatere søgeord"

#~ msgid "This keyword is currently being refreshed."
#~ msgstr "Dette søgeord er i gang med at blive opdateret."

#~ msgid "Refresh this keyword"
#~ msgstr "Opdater dette søgeord"

#~ msgid "Tags:"
#~ msgstr "Tags:"

#~ msgid "Sent to"
#~ msgstr "Sendt til"

#~ msgid "User type"
#~ msgstr "Brugertype"

#~ msgid "Off"
#~ msgstr "Fra"

#~ msgid "Shows the most occuring competitors in top 10 for your keywords."
#~ msgstr ""
#~ "Viser de konkurrenter der går igen flest gange i top 10 for dine søgeord."

#~ msgid "Unchanged"
#~ msgstr "Uændret"

#~ msgid ""
#~ "Winners & Losers shows you the number of keywords that have moved up, "
#~ "down or remained unchanged. To be included a keyword has to have been "
#~ "tracked on both of the dates that you are comparing."
#~ msgstr ""
#~ "Vindere og tabere viser dig antallet af søgeordet der har bevæget sig op, "
#~ "ned eller har stået stille. For at indgå skal et søgeord have været "
#~ "indtastet i systemet på begge de dage du sammenligner."

#~ msgid "Total Keywords"
#~ msgstr "Antal søgeord"

#~ msgid "Share of Voice increased"
#~ msgstr "Synlighed forøget"

#~ msgid "Share of Voice decreased"
#~ msgstr "Synlighed formindsket"

#~ msgid "Could not star keywords"
#~ msgstr "Kunne ikke stjernemarkere søgeord"

#~ msgid "Could not refresh keywords"
#~ msgstr "Kunne ikke opdatere søgeord"

#~ msgid "Edit…"
#~ msgstr "Rediger…"

#~ msgid "Could not remove star from keywords"
#~ msgstr "Kunne ikke fjerne stjernemarkeringer fra søgeord"

#~ msgid "not changed"
#~ msgstr "uændret"

#~ msgid "decreased"
#~ msgstr "faldet"

#~ msgid "increased"
#~ msgstr "forøget"

#~ msgid "Percentage of total %s share of voice"
#~ msgstr "Procent af total %s synlighed"

#~ msgid "Total %s share of voice"
#~ msgstr "Total %s synlighed"

#~ msgid "Share of Voice %s"
#~ msgstr "Synlighed %s"

#~ msgid "Untracked SoV"
#~ msgstr "Ikke overvåget synlighed"

#~ msgid "Total Competitors SoV"
#~ msgstr "Total konkurrent synlighed"

#~ msgid "Total Landing Pages SoV"
#~ msgstr "Total Synlighed for landingssider"

#~ msgid "Total Tagged SoV"
#~ msgstr "Total tagged Synlighed"

#~ msgid "Refund policy"
#~ msgstr "Returret"

#~ msgid "Privacy policy"
#~ msgstr "Privatlivspolitik"

#~ msgid "Contact us"
#~ msgstr "Kontakt os"

#~ msgid "Developer API"
#~ msgstr "Udvikler API"

#~ msgid ""
#~ "Easily track your rankings with @AccuRanker in 55 seconds. Sign up using "
#~ "my link and receive $%s in credit: "
#~ msgstr ""
#~ "Hold let øje med dine organiske placeringer med @AccuRanker. Meld dig til "
#~ "via mit link og få $%s i rabat: "

#~ msgid ""
#~ "Everyone you refer gets $%s in credit. Once they've spent $%s with us, "
#~ "you'll get $%s. There is no limit to the amount of credit you can earn "
#~ "through referrals."
#~ msgstr ""
#~ "Alle du henviser får $%s i kredit. Når de har brugt $%s hos os, får du $"
#~ "%s. Der er ingen grænse for den kredit du kan optjene via dine "
#~ "henvisninger."

#~ msgid "Your Referral Link"
#~ msgstr "Dit henvisningslink"

#~ msgid ""
#~ "Copy your personal referral link and share it with your friends and "
#~ "followers."
#~ msgstr "Kopier dit henvisningslink og del det med dine venner og følgere."

#~ msgid "Share via Twitter"
#~ msgstr "Del på Twitter"

#~ msgid "Number of times your link has been clicked."
#~ msgstr "Antal gange dit link er blevet besøgt."

#~ msgid "Number of trials created from your link."
#~ msgstr "Antal prøvekontoer oprettet via dit link."

#~ msgid "Amount you stand to earn when your referrals have spent $%s"
#~ msgstr "Beløb du står til at tjene når dine henvisninger har brugt $%s"

#~ msgid "Earned"
#~ msgstr "Optjent"

#~ msgid "Amount that has been applied to your wallet."
#~ msgstr "Beløb der er blevet tilføjet til din tegnebog."

#~ msgid "Your available credit."
#~ msgstr "Beløb til rådighed."

#~ msgid "View your credit history here."
#~ msgstr "Se din kredithistorik her."

#~ msgid "Give $%s, Get $%s"
#~ msgstr "Giv %s$, Få %s$"

#~ msgid "Referral Stats"
#~ msgstr "Henvisningsstatistik"

#~ msgid "Average Rank Graph"
#~ msgstr "Graf over gns. placering"

#~ msgid "Insert a graph with the average ranks for the period."
#~ msgstr "Indsæt en graf med gennemsnitsplaceringer for perioden."

#~ msgid "Google Analytics summary"
#~ msgstr "Google Analytics resumé"

#~ msgid "Insert pie charts with a summary of Google Analytics."
#~ msgstr "Indsæt lagkagediagram med Google Analytics oversigt."

#~ msgid "Show the report header. Can include your logo"
#~ msgstr "Vis rapportoverskriften. Kan indeholde dit logo"

#~ msgid "Show your logo"
#~ msgstr "Vis dit logo"

#~ msgid "Headline"
#~ msgstr "Overskrift"

#~ msgid "Insert a custom headline"
#~ msgstr "Indsæt en valgfri overskrift"

#~ msgid "No grouping"
#~ msgstr "Ingen gruppering"

#~ msgid "Show visits and potential"
#~ msgstr "Vis besøgende og potentiale"

#~ msgid "Show rank features"
#~ msgstr "Vis placeringsfunktioner"

#~ msgid "Show start date rank"
#~ msgstr "Vis placering for start dato"

#~ msgid "Show initial rank"
#~ msgstr "Vis oprindelig placering"

#~ msgid "Group by"
#~ msgstr "Gruppér efter"

#~ msgid "Keyword Summary"
#~ msgstr "Søgeordsresumé"

#~ msgid "Show a summarization the keywords."
#~ msgstr "Vis en oversigt over søgeordene."

#~ msgid "Page Break"
#~ msgstr "Sideskift"

#~ msgid "Insert a page break."
#~ msgstr "Indsæt sideskift."

#~ msgid "Ranking Distribution Graph"
#~ msgstr "Placeringsfordelingsgraf"

#~ msgid "Insert a graph with the ranking distribution for the period."
#~ msgstr "Indsæt en graf med placeringsfordelinger for perioden."

#~ msgid "Text"
#~ msgstr "Tekst"

#~ msgid "Insert a custom text"
#~ msgstr "Indsæt en valgfri tekst"

#~ msgid "Including subpages:"
#~ msgstr "Inklusiv landingssider:"

#~ msgid "Tracked search volume:"
#~ msgstr "Overvåget søgevolumen:"

#~ msgid "Keyword discovery search volume:"
#~ msgstr "Keyword discovery søgevolumen:"

#~ msgid "landing pages"
#~ msgstr "landingssider"

#~ msgid "No landing page data for the selected period"
#~ msgstr "Ingen landingsside data for den valgte periode"

#~ msgid "New message received"
#~ msgstr "Ny besked modtaget"

#~ msgid "Change the version of the keywords overview."
#~ msgstr "Skift versionen af søgeordsoverblikket."

#~ msgid "Change the filter for device type"
#~ msgstr "Skift filter for enhedstype"

#~ msgid ""
#~ "See who your competitors are for the keywords that you are actively "
#~ "tracking on this landing page.[br][br][tracked: Tracked:] The more "
#~ "keywords you are actively tracking, the more precise data you will see[br]"
#~ "[keydis: Discovered keywords:] See which keywords your competitors pages "
#~ "are indexed for that you are not tracking"
#~ msgstr ""
#~ "Se hvem dine konkurrenter er for søgeord du aktivt overvåger på denne "
#~ "landingside.[br][br][tracked:Overvåget:] Jo flere søgeord du aktivt "
#~ "overvåger, jo mere præcise data vil du se[br][keydis:Opdagede søgeord:] "
#~ "Se hvilke søgeord du ikke overvåget som dine konkurrenters sider er "
#~ "indekseret for"

#~ msgid "Competitor URL"
#~ msgstr "Konkurrent URL"

#~ msgid "Edit Rule set"
#~ msgstr "Rediger regelsæt"

#~ msgid "Add Rule Set"
#~ msgstr "Tilføj regelsæt"

#~ msgid "Select Tags"
#~ msgstr "Vælg tags"

#~ msgid "Discovered[br]Keywords"
#~ msgstr "Opdagede[br] søgeord"

#, fuzzy
#~| msgid "Including subpages"
#~ msgid "Including %s subpage"
#~ msgid_plural "Including %s subpages"
#~ msgstr[0] "Inklusiv undersider"
#~ msgstr[1] "Inklusiv undersider"

#~ msgid "Change which nodes are shown in the sitemap"
#~ msgstr "Skift hvilke noder der vises på grafen"

#~ msgid "Tracked pages only"
#~ msgstr "Kun overvågede sider"

#~ msgid "Quick add"
#~ msgstr "Hurtig tilføj"

#~ msgid "Could not delete import."
#~ msgstr "Kunne ikke slette importeringen."

#~ msgid "Import deleted"
#~ msgstr "Importering slettet"

#~ msgid "SoV $ +/-"
#~ msgstr "Synl. $ +/-"

#~ msgid "Change in SoV $ between the two compared dates"
#~ msgstr "Ændring i synlighed $ mellem de to sammenlignede datoer"

#~ msgid "Promotion"
#~ msgstr "Tilbud"

#~ msgid "Upgrade your current plan, for FREE, for up to 3 months!"
#~ msgstr "Opgrader dit nuværende abonnement - GRATIS - i op til 3 måneder!"

#~ msgid ""
#~ "After this, continue with your amazing new plan at its usual price, or "
#~ "manually downgrade to return to your old one."
#~ msgstr ""
#~ "Efter dette kan du enten fortsætte på dit nye abonnement til normalprisen "
#~ "eller manuelt nedgradere til dit gamle abonnement."

#~ msgid ""
#~ "Our current customers can upgrade to the next plan only once within the "
#~ "campaign period. This deal is only available for customers with the "
#~ "current plans ranging from 500 - 10,000 keywords."
#~ msgstr ""
#~ "Vores nuværende kunder kan kun opgradere til en større plan mens "
#~ "kampagnen løber. Tilbuddet gælder kun kunder der har et nuværende "
#~ "abonnement på mellem 500 og 10.000 søgeord."

#~ msgid "change"
#~ msgstr "ændring"

#~ msgid ""
#~ "All keywords are automatically selected. Use the filters to remove "
#~ "unwanted keywords from the list."
#~ msgstr ""
#~ "Alle søgeord er valgt automatisk. Brug et filter til at fjerne uønskede "
#~ "søgeord fra listen."

#, javascript-format
#~ msgid "Selected all %s keywords."
#~ msgstr "Vælg alle %s søgeord."

#, javascript-format
#~ msgid "Moved up more than %s"
#~ msgstr "Rykket op mere end %s"

#, javascript-format
#~ msgid "Moved down %s or more"
#~ msgstr "Rykket ned %s eller mere"

#~ msgid "Estimated number of monthly visitors"
#~ msgstr "Estimeret antal månedlige besøgende"

#~ msgid "Traffic potential for this keyword"
#~ msgstr "Trafikpotentiale for dette søgeord"

#~ msgid "NET"
#~ msgstr "NET"

#~ msgid "The scheduled report will no longer be generated"
#~ msgstr "Den planlagte rapport vil ikke længere blive lavet"

#~ msgid ""
#~ "Tagging has always been a time consuming and static process.[br]With "
#~ "Dynamic Tagging we have made it painless to keep an updated and "
#~ "consistent tagbase among multiple users.[br]Easily create rules based on "
#~ "multiple inputs, and mix those rules with AND & OR statements.[br][br]To "
#~ "get started click [addNewRule:\"Add new rule\"] below."
#~ msgstr ""
#~ "Tagging har altid været en tidskrævende og statisk proces.[br]Med "
#~ "Dynamisk Tagging har vi gjort det nemt at opretholde en opdateret og "
#~ "ensartet tagbase mellem flere brugere.[br]Du kan nemt oprette regler "
#~ "baseret på flere input, og supplere disse regler med OG & ELLER-udsagn."
#~ "[br][br]For at komme i gang skal du klikke på [addNewRule:\"Opret ny regel"
#~ "\"] nedenfor."

#~ msgid ""
#~ "Which keywords are your site indexed for? [br]With Keyword Discovery you "
#~ "will discover new key phrases and learn how your visitors are searching "
#~ "organically. You can use the filtering options to narrow down your search "
#~ "in order to find the golden keywords. Import the keywords you want to "
#~ "track on a daily basis to get valuable insights.[br][readMore: Read more]"
#~ msgstr ""
#~ "Hvilke søgeord er dit websted indekseret for? [br]Med Keyword Discovery "
#~ "vil du opdage nye nøglesætninger og lære, hvordan dine besøgende søger "
#~ "organisk. Du kan bruge filtreringsmulighederne til at indsnævre din "
#~ "søgning og finde de gyldne søgeord. For at skabe værdifuld indsigt kan du "
#~ "importere de søgeord, du vil overvåge på daglig basis. [br][readMore: Læs "
#~ "mere]"

#~ msgid "Cost Value"
#~ msgstr "Købsværdi"

#~ msgid "Dynamic columns: Move them as you please"
#~ msgstr "Dynamiske kolonner: Flyt dem som det passer dig"

#~ msgid ""
#~ "Have you ever missed an easy overview of your entire site?[br]Organic "
#~ "Site Explorer lets you easily navigate your site to see which keywords "
#~ "you are tracking and - more importantly - which keywords you are not "
#~ "tracking with AccuRanker’s Keyword Discovery.[br]Choosing which "
#~ "competitors to track is always difficult because the competitor landscape "
#~ "changes between each product, category etc. With Organic Site Explorer "
#~ "you can see exactly which competitors you are up against on each page, "
#~ "and which keywords they are found for in Keyword Discovery.[br][readMore: "
#~ "Read more]"
#~ msgstr ""
#~ "Har du savnet en nem oversigt over hele dit website?[br]Organisk Site "
#~ "Explorer lader dig navigere dit website for at se, hvilke søgeord du "
#~ "overvåger og - endnu vigtigere - hvilke søgeord du ikke overvåger med "
#~ "AccuRankers Keyword Discovery.[br] Det er altid svært at vælge, hvilke "
#~ "konkurrenter der skal overvåges, fordi konkurrentlandskabet skifter "
#~ "mellem hvert produkt, kategori osv. Med Organisk Site Explorer kan du se "
#~ "de konkurrenter du er oppe imod på hver side, og hvilke søgeord de "
#~ "overvåger, i Keyword Discovery.[ br][readMore: Læs mere]"
