import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Box } from '@mantine/core';
import { LineChart } from 'Components/Chart/LineChart';
import { TooltipConfiguration, chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import { ChartNote } from 'Components/Chart/Notes';
import linkToKeywordListFromChart from 'Components/Filters/LinkToKeywordListFromChart';
import { useHistoryChartsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { MissingDataOverlay } from 'Pages/Keywords/Overview/MissingDataOverlay';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer/index';
import { getSovChartSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/ShareOfVoice/helpers';
import { useFakeData } from 'Pages/Keywords/Overview/hooks/useFakeData';
import { useGroupViewInfo } from 'Pages/Keywords/Overview/hooks/useGroupViewInfo';
import { WithDragDetails } from 'Pages/Keywords/Overview/support/types';
import { FilterAttribute } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import { useAddKeywordsModal } from '../support/util';

type SovChartProps = {
  domainId: string | undefined;
  enabledHistoryCharts: string[];
  notes?: ChartNote[];
  notesLoading?: boolean;
  showPercentage?: boolean;
} & WithDragDetails;

export const SovChart = (props: SovChartProps) => {
  const filters = useFilters();
  const { isEmptyGroup, isGroupView } = useGroupViewInfo(filters);
  const navigate = useNavigate();
  const { SovChartFakeData } = useFakeData();
  const { data, loading, refetch } = useHistoryChartsQuery({
    variables: { filters, name: 'historyCharts', charts: props.enabledHistoryCharts },
    skip: isEmptyGroup,
  }); // use all history chart names to reuse query
  const addKeywordsModal = useAddKeywordsModal(props.domainId, refetch);
  const chartRef = useRef<HTMLDivElement>(null);

  const extraOptions = useMemo(
    () => ({
      id: 'sovGraphOverview',
      navigator: { series: { color: '#f27a10', lineColor: '#f89634' } },
      plotOptions: {
        areaspline: { fillColor: '#f5d8c2' },
        series: {
          point: {
            events: {
              click: (event) => {
                if (event.point.category) {
                  navigate(
                    linkToKeywordListFromChart(
                      event.point.category,
                      FilterAttribute.SHARE_OF_VOICE,
                    ),
                  );
                }
              },
            },
          },
        },
      },
    }),
    [],
  );

  const chartSeries = getSovChartSeries(data?.graphs?.overviewHistoryGraphs, props.showPercentage);

  const tooltipConfig: TooltipConfiguration = {
    type: chartTooltipTypes.STOCK,
    showPercentage: props.showPercentage,
    title: t('Share of Voice'),
    bottomText: t('Click to see keywords with SoV greater than 0'),
  };

  const filtersAdded = filters.length > 3;
  const showFakeData = !filtersAdded && !data?.graphs?.overviewHistoryGraphs?.length;

  const tooltip = [
    t('Share of Voice (SoV) is an indicator of how your most important keywords are performing.'),
    t(
      'All keywords that rank between positions 1 to 20 are used for the calculation. The average CTR for the position is multiplied by the search volume of each keyword to get the SoV.',
    ),
    t(
      'In this chart, you can see how the total SoV for your keywords in the current filtering has changed over time.',
    ),
  ];

  return (
    <ChartContainer
      title={t('Share of Voice')}
      dragRef={props.dragRef}
      boardControls={props.boardControls}
      chartSeries={chartSeries}
      chartRef={chartRef}
      tooltip={tooltip}
    >
      <Box pos="relative" ref={chartRef}>
        {!loading && showFakeData && (
          <MissingDataOverlay
            type="missingKeywords"
            onClick={addKeywordsModal}
            isGroupView={isGroupView}
            isEmptyGroup={isEmptyGroup}
          />
        )}
        <LineChart
          series={showFakeData ? SovChartFakeData : chartSeries}
          tooltip={tooltipConfig}
          chartType="area"
          isLoading={loading || props.notesLoading}
          extraOptions={extraOptions}
          notes={props.notes}
          showPercentage={props.showPercentage}
          loaderType="line"
          enableRangeSelector
          disableLegend
          exportButton={false}
          notesConfig={{ showCtrNotes: false, showSearchVolumeNotes: true }}
        />
      </Box>
    </ChartContainer>
  );
};
