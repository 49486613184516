import cn from 'classnames';
import { observer } from 'mobx-react';
import { AccTableHeaderCell } from 'Components/AccTableHeaderCell';
import { QueryOrder } from 'Components/DataTable/constants';
import { tableClassName } from 'Components/DataTable/helpers';
import { useContextTableStore } from 'Components/DataTable/store/components/TableStoreContext';
import { ColumnFilter } from 'Components/DataTable/types';
import { Ordering } from 'Types/Sort';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { CheckboxHeaderCell } from './components/CheckboxHeaderCell';
import { getIsFilterElement } from './helpers';
import './header-cell.scss';

interface HeaderCellBuiltinProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const defaultCell = {
  checkbox: CheckboxHeaderCell,
};

interface HeaderCellProps {
  render?: React.ReactNode;
  tooltip?: string;
  filter?: ColumnFilter;
  ordering?: Ordering;
  type?: keyof typeof defaultCell;
  reverseDirection?: boolean;
  disabledMenu?: boolean;
  menuTitle?: string;
  CustomMenuItem?: () => JSX.Element;
}

export const HeaderCell: React.FC<
  React.PropsWithChildren<HeaderCellProps & HeaderCellBuiltinProps>
> = observer(
  ({
    type,
    children,
    tooltip,
    className,
    style,
    render,
    filter,
    ordering,
    reverseDirection,
    disabledMenu,
    menuTitle,
    CustomMenuItem,
  }) => {
    const tableStore = useContextTableStore();
    const trackEvent = useMixpanel();
    const hasOrdering = !!ordering?.orderBy;
    const hasFilter = !!filter;
    const defaultOrdering = tableStore?.defaultOrdering;
    const activeOrdering = tableStore?.ordering?.orderBy === ordering?.orderBy;
    const order = activeOrdering ? tableStore?.ordering?.order : ordering?.defaultOrder;
    const isDesc = order === QueryOrder.DESC;

    const onOrderChange = (
      event: React.MouseEvent<HTMLButtonElement>,
      specificOrder?: QueryOrder,
      resetToDefault?: boolean,
    ) => {
      if (getIsFilterElement(event.currentTarget)) {
        return;
      }

      trackEvent(EventName.TableSorting, {
        'Table Name': tableStore?.tableName ?? '',
        'Sort Field': ordering?.orderBy ?? '',
        'Is Infinite Table': false,
      });

      if (specificOrder) {
        tableStore?.setOrdering({
          order: specificOrder,
          orderBy: ordering!.orderBy,
        });
        return;
      }

      if (resetToDefault) {
        defaultOrdering && tableStore?.setOrdering(defaultOrdering);
        return;
      }

      const sortingOrder = activeOrdering
        ? isDesc
          ? QueryOrder.ASC
          : QueryOrder.DESC
        : (ordering?.defaultOrder as QueryOrder) || QueryOrder.DESC;

      return tableStore?.setOrdering({
        order: sortingOrder,
        orderBy: ordering!.orderBy,
      });
    };

    let content = render ? render : <span className="label">{children}</span>;

    // TODO once we will have more default cells update logic
    let customClassName = '';
    if (type && defaultCell[type]) {
      const DefaultCell: any = defaultCell[type];
      customClassName = tableClassName('header-cell-container--checkbox');
      content = <DefaultCell tableStore={tableStore} />;
    }

    const resultClassName = cn(className, {
      [tableClassName('header-cell-ordering')]: hasOrdering || hasFilter,
      [tableClassName('header-cell-ordering--active')]: activeOrdering,
      [tableClassName(`header-cell-ordering--${isDesc ? 'desc' : 'asc'}`)]: hasOrdering,
    });

    return (
      <AccTableHeaderCell
        activeOrdering={activeOrdering}
        order={order}
        onOrderChange={onOrderChange}
        headerContent={content}
        tooltip={tooltip}
        filter={filter}
        ordering={ordering}
        reverseDirection={reverseDirection}
        disabledMenu={disabledMenu}
        menuTitle={menuTitle}
        CustomMenuItem={CustomMenuItem}
        classNames={{
          root: resultClassName,
          container: cn(tableClassName('header-cell-container'), customClassName),
          content: 'header-cell-content',
          text: 'text',
          popover: 'header-popover',
          iconWrapper: 'context-icon',
          filterIconWrapper: 'filter-icon',
          dropdownMenu: 'context-menu',
        }}
        style={style}
      />
    );
  },
);
