import { useCallback, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import groupBy from 'lodash/groupBy';
// import transform from 'lodash/transform';
import moment from 'moment';
import { AccFastIcon } from 'Components/AccFastIcon';
import { ColumnType, QueryOrder } from 'Components/DataTable';
import { ModalTypes } from 'Components/Modal/Content';
import ActionsCell from 'Components/Table/TableRow/ActionsCell';
import { SORTING_TYPE } from 'Constants';
import {
  AdobeConnectionFragment,
  BigQueryConnectionFragment,
  ConnectedAccountsDocument,
  ConnectedAccountsQuery,
  ConnectedAccountsQueryVariables,
  ErrorFragment,
  GoogleConnectionFragment,
  useConnectedAccountsDomainsListLazyQuery,
  useRemoveAdobeAccessMutation,
  useRemoveBigQueryAccessMutation,
  useRemoveGoogleAccessMutation,
} from 'Ghql';
import toast from 'Hooks/useToast';
import { ModalPayloadConfig } from 'Reducers/ModalReducer';
import { Integrations } from 'Types/Integration';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import styleVariables from 'css/base/variables.module.scss';
import IconEdit from 'icons/IconEdit.svg';
import { IconTrash } from 'icons/tag-cloud';
import { ColumnIDs } from './ColumnIDs';

const ADOBE_ACCOUNT = Integrations.ADOBE.name;
const GOOGLE_ACCOUNT = Integrations.GOOGLE_ACCOUNT.name;
const BIGQUERY_ACCOUNT = Integrations.BIGQUERY.name;

type NodeFragment = GoogleConnectionFragment | AdobeConnectionFragment | BigQueryConnectionFragment;

const modalTypes: Record<keyof typeof Integrations, ModalTypes> = {
  [ADOBE_ACCOUNT]: 'EditAdobeAccount',
  [GOOGLE_ACCOUNT]: 'EditAccount',
  [BIGQUERY_ACCOUNT]: 'EditBigQueryAccount',
};

export const useFetchConnectedAccounts = () => {
  const client = useApolloClient();

  return async () => {
    return client
      .query<ConnectedAccountsQuery, ConnectedAccountsQueryVariables>({
        query: ConnectedAccountsDocument,
      })
      .then((response) => {
        const organization = response.data.user?.organization;

        const { googleConnections, adobeMarketingConnections, googleBigqueryConnections } =
          organization || {};

        // const integrationsMap = transform(
        //   Integrations,
        //   (acc, item) => {
        //     acc[item.type] = item.name;
        //     return acc;
        //   },
        //   {},
        // );

        //Label accounts with the correct type name - eg. adobe accounts are labeled "Adobe"
        const googleAccounts =
          googleConnections?.map((connection) => ({
            ...connection,
            type: GOOGLE_ACCOUNT,
          })) || [];

        const adobeAccounts =
          adobeMarketingConnections?.map((connection) => ({
            ...connection,
            type: ADOBE_ACCOUNT,
          })) || [];

        const bigqueryAccounts =
          googleBigqueryConnections?.map((connection) => ({
            ...connection,
            type: BIGQUERY_ACCOUNT,
          })) || [];

        const accounts = [...googleAccounts, ...adobeAccounts, ...bigqueryAccounts];

        return { data: accounts, length: accounts.length };
      });
  };
};

export const useGroups = (requireRefetch: boolean) => {
  const [loadGroups, { called, data: accountsData }] = useConnectedAccountsDomainsListLazyQuery();

  (requireRefetch || !called) && loadGroups();

  const domainsList = accountsData?.domainsList || [];

  // Group the different accounts by type
  const gaGroup = domainsList.length
    ? groupBy(domainsList, 'googleOauthConnectionGa.id')
    : undefined;
  const gscGroup = domainsList.length
    ? groupBy(domainsList, 'googleOauthConnectionGsc.id')
    : undefined;
  const adobeGroup = domainsList.length
    ? groupBy(domainsList, 'adobeMarketingConnection.id')
    : undefined;
  const bigQueryGroup = domainsList.length
    ? groupBy(domainsList, 'googleBigqueryConnection.id')
    : undefined;

  return {
    gaGroup,
    gscGroup,
    adobeGroup,
    bigQueryGroup,
  };
};

type AnalyticsGroup = ReturnType<typeof useGroups>['gaGroup'];

type useConnectedAccountsTableColumnsProps = {
  handleUpdateTable: () => void;
  showModal: (config: ModalPayloadConfig | undefined) => void;
  adobeGroup: AnalyticsGroup;
  gaGroup: AnalyticsGroup;
  gscGroup: AnalyticsGroup;
  bigQueryGroup: AnalyticsGroup;
};

export const useConnectedAccountsTableColumns = ({
  showModal,
  handleUpdateTable,
  adobeGroup,
  gaGroup,
  gscGroup,
  bigQueryGroup,
}: useConnectedAccountsTableColumnsProps) => {
  const [removeAdobeAccess] = useRemoveAdobeAccessMutation();
  const [removeGoogleAccess] = useRemoveGoogleAccessMutation();
  const [removeBigQueryAccess] = useRemoveBigQueryAccessMutation();

  const onRemoveClick = useCallback(
    (id: string | undefined, type: string | undefined) => {
      const deleteInput = {
        variables: {
          input: {
            id: id || '',
            description: '~~DELETED~~',
            delete: true,
          },
        },
      };

      const actionCb = (errors: ErrorFragment[]) => {
        if (errors.length) {
          toast.error(t('Unable to remove account'));
        } else {
          toast.success(t('Account removed'));
          handleUpdateTable();
        }
      };

      if (type === ADOBE_ACCOUNT) {
        removeAdobeAccess(deleteInput).then(({ data }) => {
          const errors = data?.updateAdobeMarketingConnection?.errors?.filter(notEmpty) || [];
          return actionCb(errors);
        });
      } else if (type === GOOGLE_ACCOUNT) {
        removeGoogleAccess(deleteInput).then(({ data }) => {
          const errors = data?.updateGoogleConnection?.errors?.filter(notEmpty) || [];
          return actionCb(errors);
        });
      } else if (type === BIGQUERY_ACCOUNT) {
        removeBigQueryAccess(deleteInput).then(({ data }) => {
          const errors = data?.updateGoogleBigqueryConnection?.errors?.filter(notEmpty) || [];
          return actionCb(errors);
        });
      }
    },
    [handleUpdateTable, removeAdobeAccess, removeGoogleAccess, removeBigQueryAccess],
  );

  const handleEdit = useCallback(
    (account: NodeFragment & { type: string }) => {
      const modalType = modalTypes[account.type] || modalTypes.GOOGLE_ACCOUNT;
      showModal({
        modalType,
        modalTheme: 'light',
        modalProps: {
          accountName: account.type,
          accountId: account.id,
          refresh: () => handleUpdateTable(),
        },
      });
    },
    [handleUpdateTable, showModal],
  );

  const handleDelete = useCallback(
    (account: NodeFragment & { type: string }) =>
      showModal({
        modalType: 'Confirmation',
        modalProps: {
          cancelLabel: t('Cancel'),
          confirmLabel: t('Delete account'),
          lockDuration: 3,
          title: t('Delete Connected Account?'),
          description: t('Any domain using this account will be disconnected as well.'),
          action: () => onRemoveClick(account.id ?? undefined, account.type ?? undefined),
        },
      }),
    [onRemoveClick, showModal],
  );

  const renderGroup = (
    account: NodeFragment & { type: string },
    type: string,
    group: AnalyticsGroup,
  ): number | string => {
    if (account.id && type && group) {
      return account.type === type ? (group[account.id] && group[account.id].length) || 0 : '-';
    }

    throw new Error('Error in renderGroup - ConnectedAccountsTable');
  };

  const columns: ColumnType<NodeFragment & { type: string }, {}>[] = useMemo(
    () => [
      {
        id: ColumnIDs.DESCRIPTION,
        title: t('Description'),
        width: 180,
        cellRenderer: (props) => (
          <span>{props.record && props.record.description ? props.record.description : ''}</span>
        ),
        onHeaderCell: () => ({
          ordering: {
            defaultOrder: QueryOrder.ASC,
            orderBy: ColumnIDs.DESCRIPTION,
            sortingKey: SORTING_TYPE.alphabetical,
          },
        }),
      },
      {
        id: ColumnIDs.TYPE,
        title: t('Type'),
        width: 100,
        cellRenderer: (props) =>
          props.record &&
          props.record.type && <span>{props.record.type ? props.record.type : ''}</span>,
        onHeaderCell: () => ({
          ordering: {
            defaultOrder: QueryOrder.ASC,
            orderBy: ColumnIDs.TYPE,
            sortingKey: SORTING_TYPE.alphabetical,
          },
        }),
      },
      {
        id: ColumnIDs.ADDED_BY,
        title: t('Added By'),
        width: 100,
        cellRenderer: (props) => (
          <span>{props.record.createdBy ? props.record.createdBy : ''}</span>
        ),
        onHeaderCell: () => ({
          ordering: {
            defaultOrder: QueryOrder.ASC,
            orderBy: 'createdBy',
            sortingKey: SORTING_TYPE.alphabetical,
          },
        }),
      },
      {
        id: ColumnIDs.LINKED_AT,
        title: t('Linked At'),
        width: 120,
        cellRenderer: (props) => (
          <span>{props.record.createdAt ? moment(props.record.createdAt).format('lll') : ''}</span>
        ),
        onHeaderCell: () => ({
          ordering: {
            defaultOrder: QueryOrder.ASC,
            orderBy: 'createdAt',
            sortingKey: SORTING_TYPE.alphabetical,
          },
        }),
      },
      {
        id: ColumnIDs.ADOBE_MARKETING,
        title: t('no. uses - Adobe Marketing'),
        width: 190,
        cellRenderer: (props) => (
          <span>
            {props.record && adobeGroup
              ? renderGroup(props.record, ADOBE_ACCOUNT, adobeGroup)
              : '-'}
          </span>
        ),
      },
      {
        id: ColumnIDs.GOOGLE_ANALYTICS,
        title: t('no. uses - Google Analytics'),
        width: 180,

        cellRenderer: (props) => (
          <span>
            {props.record && gaGroup ? renderGroup(props.record, GOOGLE_ACCOUNT, gaGroup) : '-'}
          </span>
        ),
      },
      {
        id: ColumnIDs.GOOGLE_SEARCH_CONSOLE,
        title: t('no. uses - GSC'),
        width: 140,
        cellRenderer: (props) => (
          <div>
            {props.record && gscGroup ? renderGroup(props.record, GOOGLE_ACCOUNT, gscGroup) : '-'}
          </div>
        ),
      },
      {
        id: ColumnIDs.BIGQUERY,
        title: t('no. uses - BigQuery'),
        width: 160,
        cellRenderer: (props) => (
          <div>
            {props.record && bigQueryGroup
              ? renderGroup(props.record, BIGQUERY_ACCOUNT, bigQueryGroup)
              : '-'}
          </div>
        ),
      },
      {
        id: ColumnIDs.ACTIONS,
        title: t('Actions'),
        width: 75,
        cellRenderer: (props) => {
          const { snorlax4: iconColor } = styleVariables;
          return (
            <ActionsCell
              shouldUpdateIndicator={props.record}
              actions={[
                {
                  onSelect: () => handleEdit(props.record),
                  label: t('Rename'),
                  fastIcon: <AccFastIcon src={IconEdit} size={20} color={iconColor} />,
                },
                {
                  onSelect: () => handleDelete(props.record),
                  label: t('Delete'),
                  fastIcon: <AccFastIcon src={IconTrash} size={20} color={iconColor} />,
                },
              ]}
            />
          );
        },
        className: 'no-border',
      },
    ],
    [adobeGroup, gaGroup, gscGroup, handleDelete, handleEdit],
  );

  return columns;
};
