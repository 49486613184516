import { Flex, Grid } from '@mantine/core';
import SearchTypeSwitch from 'Pages/Keywords/Overview/components/SearchTypeSwitch';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { AIOverviewTable } from './components/AIOverviewTable/AIOverviewTable';
import { HistoricalKeywordCountChart } from './components/HistoricalKeywordCountChart/HistoricalKeywordCount';
import { TopDomainsChart } from './components/TopDomainsChart/TopDomainsChart';
import { TopUrlsChart } from './components/TopUrlsChart/TopUrlsChart';
import { UrlCountChart } from './components/UrlCountChart/UrlCountChart';

export const AIOverviewPage = () => (
  <>
    <ActionbarContainer>
      <SearchTypeSwitch />
    </ActionbarContainer>
    <Flex direction="column" gap="xl">
      <Grid>
        <Grid.Col span={{ lg: 6, md: 12 }}>
          <HistoricalKeywordCountChart />
        </Grid.Col>
        <Grid.Col span={{ lg: 6, md: 12 }}>
          <UrlCountChart />
        </Grid.Col>
        <Grid.Col span={{ lg: 6, md: 12 }}>
          <TopUrlsChart />
        </Grid.Col>
        <Grid.Col span={{ lg: 6, md: 12 }}>
          <TopDomainsChart />
        </Grid.Col>
      </Grid>
      <AIOverviewTable />
    </Flex>
  </>
);
