import { DataTable, QueryOrder } from 'Components/DataTable';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { KeywordColumnID } from 'Pages/Keywords/Table/support/constants';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { useAIOverviewConfig } from './support/useAIOverviewConfig';
import { useFetchAIOverviewData } from './support/useFetchAIOverviewData';

const DEFAULT_AI_OVERVIEW_ORDERING = {
  order: QueryOrder.ASC,
  orderBy: KeywordColumnID.KEYWORD,
};

export const AIOverviewTable = () => {
  const { displayCurrency } = useDisplayCurrency(true);
  const { isDemoDomain, domainInfo } = useQueryDomainInfo();
  const fetchKeywords = useFetchAIOverviewData({ displayCurrency });
  const { columns } = useAIOverviewConfig({
    isDemoDomain,
    displayCurrency,
    domain: domainInfo?.domain,
  });

  return (
    <DataTable
      fetchData={fetchKeywords}
      columns={columns}
      tableId={TableIDs.AI_OVERVIEW}
      defaultOrdering={DEFAULT_AI_OVERVIEW_ORDERING}
      pagination={true}
      pageSize={20}
      emptyOptions={{
        title: t('No Data'),
        subTitle: t('Currently, no keywords feature the AI Overview SERP feature.'),
      }}
    />
  );
};
