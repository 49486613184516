import { useState } from 'react';
import { Box, Group, Pagination, Skeleton } from '@mantine/core';
import { DomainFavicon } from 'Components/DomainFavicon';
import AccText from 'Components/Text/AccText';
import { MissingDataOverlay } from 'Pages/Keywords/Overview/MissingDataOverlay';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer';
import { t } from 'Utilities/i18n';
import { getTopUrlsCsvData } from './GetTopUrlsCsvData';
import styles from './urlChart.module.scss';

type LinkWrapperProps = {
  url: string | undefined;
  isOwnDomain: boolean;
  children: JSX.Element;
};

const LinkWrapper = ({ url, isOwnDomain, children }: LinkWrapperProps) => {
  if (!url) {
    return <>{children}</>;
  }
  return (
    <a
      className={styles.href}
      href={url}
      data-is-own-domain={isOwnDomain || undefined}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

const PAGE_SIZE = 10;

export type UrlListItemData = {
  url?: string | null;
  domain?: string | null;
  count?: number | null;
  isOwnDomain?: boolean | null;
};

type UrlListProps = {
  dataSeries: UrlListItemData[];
  activePage: number;
  loading?: boolean;
};

const UrlList = ({ dataSeries, activePage, loading }: UrlListProps) => {
  const data: UrlListItemData[] = dataSeries?.length
    ? dataSeries
    : Array.from({ length: 10 }, (_, index) => ({
        url: 'placeholder',
        count: index + 1,
      }));

  return (
    <div>
      <Group gap="md" px={8} className={styles.header} w="100%" mb="xs">
        <AccText c="gray.6" miw={20} size="xs" fw={600}>
          #
        </AccText>
        <AccText c="gray.6" ml={28} size="xs" fw={600}>
          {t('URL')}
        </AccText>
        <AccText c="gray.6" size="xs" ml="auto" fw={600}>
          {t('Count')}
        </AccText>
      </Group>
      <ul className={styles.list}>
        {data.map((item, index) => (
          <li key={`${item.domain || item.url}-${item.count}`} className={styles.listItem}>
            <Group gap="sm" px={8} wrap="nowrap" w="100%">
              {item.url === 'placeholder' || loading ? (
                <>
                  <Skeleton animate={loading} width={20} height={15} my={12} mr={20} />
                  <div className={styles.hrefLoader}>
                    <Skeleton animate={loading} width={15} height={15} />
                    <Skeleton animate={loading} width="100%" height={15} />
                  </div>
                  <Skeleton animate={loading} width={16} height={15} my={12} px={10} ml="auto" />
                </>
              ) : (
                <>
                  <AccText c="gray.9" size="xs" miw={40}>
                    {index + 1 + (activePage - 1) * PAGE_SIZE}
                  </AccText>

                  <LinkWrapper url={item.url || undefined} isOwnDomain={!!item.isOwnDomain}>
                    <Group gap="xs" wrap="nowrap">
                      <DomainFavicon domain={item.url || item.domain} />
                      <p
                        className={item.url ? '' : styles.domainText}
                        data-is-own-domain={item.isOwnDomain || undefined}
                      >
                        {item.domain || item.url}
                      </p>
                    </Group>
                  </LinkWrapper>
                  <AccText c="gray.9" size="xs" px={10} fw={600} className={styles.count}>
                    {item.count}
                  </AccText>
                </>
              )}
            </Group>
          </li>
        ))}
      </ul>
    </div>
  );
};

type UrlChartProps = {
  data: UrlListItemData[];
  loading: boolean;
  chartTitle: string;
  chartTitleTooltip: string;
  showMissingDataOverlay: boolean;
};

export const UrlChart = ({
  data,
  loading,
  chartTitle,
  chartTitleTooltip,
  showMissingDataOverlay,
}: UrlChartProps) => {
  const [activePage, setPage] = useState(1);

  const totalDomains = data.length;
  const dataSeries = data.slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE) || [];
  const paginationPages = Math.ceil(totalDomains / PAGE_SIZE);

  return (
    <ChartContainer
      title={chartTitle}
      chartSeries={data}
      getCsvData={getTopUrlsCsvData}
      boardControls={{
        removeChart: null,
        disabledDnd: true,
      }}
      minHeight={520}
      style={{ display: 'flex', flexDirection: 'column' }}
      tooltip={[chartTitleTooltip]}
    >
      <Box pos="relative" flex="1" mih="calc(100% - 60px)">
        {showMissingDataOverlay && (
          <MissingDataOverlay extraBlurry type="noAIOverviews" isEmptyGroup={false} />
        )}
        <UrlList dataSeries={dataSeries} activePage={activePage} loading={loading} />
      </Box>
      <Pagination
        ml="auto"
        w="fit-content"
        color="snorlax"
        value={activePage}
        onChange={setPage}
        total={paginationPages}
        size="sm"
        disabled={loading}
        gap={0}
        classNames={{
          root: styles.paginationRoot,
          control: styles.paginationControl,
        }}
      />
    </ChartContainer>
  );
};
